const interpolate = (data) => {
    let index = 0

    while (index < data.length) {

        while(!data[index]) {
            index++
            if (index == data.length) {
                return data//all null case
            }
        }
    
        // iterate index to find first null
        while(data[index]) {
            index++
            if (index == data.length) {
                return data// all complete case
            }
        }
        const startValIndex = index-1
        // find next non null
        while(!data[index]) {
            index++
            if (index == data.length) {
                return data
            }
        }
        const startVal = data[startValIndex]
        const endVal = data[index]
        const diff = endVal-startVal
        const increment = diff / (index-startValIndex)
        let accumulator = increment + startVal

        for (let i = startValIndex+1; i < index; i++) {
            data[i] = Math.floor(accumulator)
            accumulator += increment
        }
    }
    return data
}

export default interpolate