import { useState, useEffect } from 'react'

import Item from './item'
import Chart from './chart'
import mapper from '../lib/mapping'

const Spotlight = ({ spotlightedItem, getItemData }) => {
    const [show,setShow] = useState(false)
    const [enlarge,setEnlarge] = useState(false)
    const [searchTerm,setSearchTerm] = useState('')
    const [showResults,setShowResults] = useState(false)
    const [refreshing,setRefreshing] = useState(false)
    const [interval, setInterval] = useState('5m')
    const intervals = ['5m','1h','6h','24h']

    useEffect(()=>{
        if (spotlightedItem) {
            setSearchTerm(spotlightedItem.name)
            setShow(true)
        }
    },[spotlightedItem])

    const handleChange = (event) => {
        const { value } = event.target
        setSearchTerm(value)
    }

    const handleSelect = async (item) => {
        setRefreshing(true)
        setSearchTerm(item.name)
        setShow(true)
        await getItemData(item.id)
        setRefreshing(false)
        
    }

    const handleShowToggle = () => {
        setShow(!show)
    }

    const handleEnlrageToggle = () => {
        setEnlarge(!enlarge)

    }

    const handleHideResults = () => {
        setTimeout(()=>{
            setShowResults(false)
        },100)
    }
    const handleShowResults = () => {
        setShowResults(true)
    }

    const handleIntervalChange = (interval) => {
        setInterval(interval)
    }
    


    return (
        <div className='spotlight'>
            <div className='spotlight-input-container'>
                <div className='sicinner'>
                    {spotlightedItem && <button className='default-button' onClick={handleShowToggle}>{show ? `Hide` : 'Show'}</button>}
                    <input 
                        className='spotlight-input'
                        type='text'
                        value={searchTerm}
                        onChange={handleChange}
                        onBlur={handleHideResults}
                        onFocus={handleShowResults}
                        placeholder='Search for an item'>
                    </input>
                </div>
                {searchTerm.length >= 3 && showResults &&
                    <ul className='found-items'>
                        {mapper.map((item)=>{
                            if (item.name.toLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                                return <li onClick={()=>handleSelect(item)} key={`iow45g45${item.id}`} className='found-item'>{item.name}</li>
                            }
                        })}
                    </ul>
                    }
            </div>

            {show &&
                <div className={`spotlight-item${enlarge? ' large' : ''}`}>
                    {spotlightedItem && 
                    <>
                    <div className='spotlight-button-container'>
                        <button className={`modal-refresh${refreshing ? ' refreshing' : ''}`} onClick={()=>handleSelect(spotlightedItem)}></button>
                        <button className='modal-enlarge' onClick={handleEnlrageToggle}></button>
                        <button className='modal-close' onClick={handleShowToggle}></button>
                    </div>
                    {!enlarge&& <Item item={spotlightedItem} getItemData={null}></Item>}


                    <Chart spotlightedItem={spotlightedItem} interval={interval} enlarge={enlarge}></Chart>
                    <div className='chart-ui'>
                        {intervals.map((i)=>{
                            return <button onClick={()=>handleIntervalChange(i)} key={`intbtn${i}`} className='default-button'>{i}</button> 
                        })}
                    </div>

                    </>
                    }
                </div>
            }   
        </div>
    )
}

export default Spotlight