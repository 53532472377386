import './App.css';
import { useState, useEffect } from 'react'
import sorters from './lib/Sorters'

import Loader from './components/loader';
import Header from './components/header'
import Spotlight from './components/spotlight'
import Sets from  './components/sets'
import Items from './components/items'

const App = () => {
  const [data,setData] = useState(null)
  const [filteredData,setFilteredData] = useState(null)
  const [filters,setFilters] = useState({
    maxPrice:500,
    minVolume:0,
    freeToPlayOnly:false,
  })
  const [currentTab,setCurrentTab] = useState('items')
  const [spotlightedItem,setSpotlightedItem] = useState(null)
  const [sortFunction,setSortFunction] = useState('Recommended')
  const [errorMessage,setErrorMessage] = useState('')
  const tabTitles = [{tabName:'Items',dataName:'items'},{tabName:'Sets',dataName:'sets'},{tabName:'Barrows Repair',dataName:'barrowsRepair'}]

  const getAllData = async () => {
    const response = await fetch('/items')
    if (response.status == 200) {
      const resData = await response.json()
      if (resData.success) {
        setData(resData)
        }
    }
  }

  const getItemData = async (id) => {
    const response = await fetch(`/items/${id}`)
    if (response.status == 200) {
      const resData = await response.json()
      if (resData.success) {
        setSpotlightedItem(resData.item)
      }
    }
  }


  useEffect(()=>{
    getAllData()
    setTimeout(getAllData,30000)
  },[])

  
  useEffect(()=>{
    if (data) {
      const newFilteredData = {}
      tabTitles.forEach((tab) => {
        newFilteredData[tab.dataName] = data[tab.dataName].filter((item)=>{
          const conditions = [
            item.high < filters.maxPrice*1000000,
            item.highPriceVolume > filters.minVolume,
            item.lowPriceVolume > filters.minVolume,
            !filters.freeToPlayOnly || (filters.freeToPlayOnly && !item.members)
          ]
          return conditions.every((e)=>e)
        })
      })
      newFilteredData.items.sort(sorters[sortFunction])

      setFilteredData(newFilteredData)
    }
  },[data,filters,sortFunction])


  



  return (
    <div className="App">
      <Header currentTab={currentTab} setCurrentTab={setCurrentTab} getAllData={getAllData}></Header>
      <Spotlight spotlightedItem={spotlightedItem} getItemData={getItemData}></Spotlight>
      <section className='content'>
      {filteredData ? (<>
          {currentTab == 'items' && <Items items={filteredData.items.slice(0,42)} getItemData={getItemData} filtersAndSorters={{'filters':filters,'setFilters':setFilters,'sortFunction':sortFunction,'setSortFunction':setSortFunction}}></Items>}
          {currentTab == 'sets' && <Sets sets={data.sets} tab={currentTab} getItemData={getItemData}></Sets>}
          {currentTab == 'barrowsRepair' && <Sets sets={data.barrowsRepair} tab={currentTab} getItemData={getItemData}></Sets>}
        </>)
        :
        <Loader size={'lg'}></Loader>
      }
      </section>

    </div>
  );
}

export default App;
