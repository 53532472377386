import { useState } from "react"

import filterRightImg from '../lib/icons/filter_right.png'
import filterLeftImg from '../lib/icons/filter_left.png'
import sorters from "../lib/Sorters"

const FilterSort = ({ filtersAndSorters }) => {

    const sorterFunctionOptions = Object.keys(sorters)
    const sorterFunctionFields = {}
    sorterFunctionOptions.forEach((option)=>{
        sorterFunctionFields[option] = false
    })
    sorterFunctionFields[filtersAndSorters.sortFunction] = true


    const [show,setShow] = useState(false)

    const [fields,setFields] = useState({
        ...filtersAndSorters.filters,
        ...sorterFunctionFields,
        selectedSortedFunctionName:filtersAndSorters.sortFunction
    })

    const handleShow = () => {
        setShow(!show)
    }

    const handleFilterChange = (event) => {
        const { name, value } = event.target
        setFields({
          ...fields,
          [name]: value
        })
    }

    const handleFilterCheck = (event) => {
        const { name, checked } = event.target
        setFields({
          ...fields,
          [name]: checked
        })
    }


    const handleSorterCheck = (event) => {
        const newFields = {}
        sorterFunctionOptions.forEach((option)=>{
            newFields[option] = false
        })
        const { name } = event.target
        setFields({
            ...fields,
            ...newFields,
            [name]: true,
            selectedSortedFunctionName:name
        })
        filtersAndSorters.setSortFunction(name)

    }


    const handleApplyFilters = () => {
        filtersAndSorters.setFilters({
            maxPrice:fields.maxPrice,
            minVolume:fields.minVolume,
            freeToPlayOnly:fields.freeToPlayOnly,
        })
    }



    return (
        <div className='filter-sort-container'>
            <button onClick={handleShow} className='marg-0 filter-button'><img className='icon' src={show ? filterLeftImg : filterRightImg}></img></button> 
            <div className={`revealer${show ? '' : ' hide'}`}>

                <div className='sort-container'>
                    <h1>Sort Results</h1>
                    {sorterFunctionOptions.map((optionName)=>{
                        return (
                            <div  className='input-label-container' key={`filteroptionkey${optionName}`}>
                                <label className='label-wide' htmlFor={optionName}>{optionName}</label>
                                <input name={optionName} id={optionName} type='checkbox' checked={fields[optionName]} onChange={handleSorterCheck}></input>
                            </div>
                        )
                    })}

                </div>
                <div className='filter-container'>
                    <h1>Filter Results</h1>

                    <div className='filter-list'>
                        <div className='input-label-container'>
                        <label className='label-wide' htmlFor='maxPrice'>Max Price (million)</label>
                        <input className='input-narrow' name='maxPrice' id='maxPrice' type='number' value={fields.maxPrice} onChange={handleFilterChange}></input>
                        </div>
                        <div className='input-label-container'>
                        <label className='label-wide' htmlFor='minVolume'>Min trade Volume (hr)</label>
                        <input className='input-narrow' name='minVolume' id='minVolume' type='number' value={fields.minVolume} onChange={handleFilterChange}></input>
                        </div>
                        <div className='input-label-container'>
                        <label className='label-wide' htmlFor='freeToPlayOnly'>F2P Only</label>
                        <input name='freeToPlayOnly' id='freeToPlayOnly' type='checkbox' checked={fields.freeToPlayOnly} onChange={handleFilterCheck}></input>
                        </div>
                        <button onClick={handleApplyFilters} className='default-button marg-0'>Apply Filters</button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default FilterSort