const sorters = {
    'Recommended': (a,b) => {
        // Follows an MCA process
        const weights = {
            latestMargin:3,
            latestMarginPercent:1,
            hourlyMargin:3,
            hourlyMarginPercent:1,
            volume:2,
            ratio:1,
        }

        const getScores = (item) => {
            const latestMarginSc = (sCurve(item.latestMargin,60000,120000) || 0) * weights.latestMargin
            const latestMarginPercentSc = (sCurve(item.latestMarginPercent,0.2,0.9) || 0) * weights.latestMarginPercent
            const hourlyMarginSc = (sCurve(item.hourlyMargin,60000,120000) || 0) * weights.hourlyMargin
            const hourlyMarginPercentSc = (sCurve(item.hourlyMarginPercent,0.2,0.9) || 0) * weights.hourlyMarginPercent
            const volumeSc = (sCurve(Math.min(item.lowPriceVolume,item.highPriceVolume),9,20) || 0) * weights.volume
            const ratioSc = 1 * weights.ratio
            
            return latestMarginSc + latestMarginPercentSc + hourlyMarginSc + hourlyMarginPercentSc + volumeSc + ratioSc 


        }

        const sCurve = (x,steepness,midpoint) => {
            if (x < 0) {
                return 0
            }
            return 1 / (1 + Math.exp(-1/steepness * (x - midpoint)))
        }

        const aScore = getScores(a)
        const bScore = getScores(b)

        return bScore - aScore
        
    },
    'Latest Margin': (a,b) => {
        return b.latestMargin - a.latestMargin
    },
    'Hourly Margin': (a,b) => {
        return b.hourlyMargin - a.hourlyMargin
    },
    'Latest Margin Percent': (a,b) => {
        return b.latestMarginPercent - a.latestMarginPercent
    },
    'Hourly Margin Percent': (a,b) => {
        return b.hourlyMarginPercent - a.hourlyMarginPercent
    },
    
}

export default sorters