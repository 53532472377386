import { useState, useEffect } from 'react'
import ReactECharts from 'echarts-for-react';

import interpolate from '../lib/interpolate';

const Chart = ({ spotlightedItem, interval, enlarge }) => {


    const [chartPriceOptions,setChartPriceOptions] = useState({})
    const [chartVolumeOptions,setChartVolumeOptions] = useState({})

    useEffect(()=>{
        const avgHighPriceSeries = interpolate(spotlightedItem.timeSeriesData[interval].map((e)=>e.avgHighPrice))
        const avgLowPriceSeries = interpolate(spotlightedItem.timeSeriesData[interval].map((e)=>e.avgLowPrice))
        const avgHighPriceLessTaxSeries = avgHighPriceSeries.map((e)=>e ? e - (Math.min(5000000,Math.floor(e*0.01))):null)
        const highVolumeSeries = spotlightedItem.timeSeriesData[interval].map((e)=>e.highPriceVolume)
        const lowVolumeSeries = spotlightedItem.timeSeriesData[interval].map((e)=>e.lowPriceVolume)

        const newChartPriceOptions = {
            title:{
                text:`${spotlightedItem.name} - ${interval}`,
                left:'center'
            },
            xAxis: {
                type: 'category',
                data: spotlightedItem.timeSeriesData[interval].map((e)=>e.timestampRepr),
                snap:true,
            },
            yAxis: {
                type: 'value',
                min: Math.floor(Math.min(...avgLowPriceSeries.filter((p)=>p),...avgHighPriceSeries.filter((p)=>p))*0.995),
                max: Math.ceil(Math.max(...avgHighPriceSeries,...avgLowPriceSeries)*1.005),
                offset:0,
            },
            series: [
                {
                    data: avgHighPriceSeries,
                    type: 'line',
                    name: 'High',
                    color:'rgb(31, 43, 59)',
                },
                {
                    data: avgHighPriceLessTaxSeries,
                    type: 'line',
                    lineStyle:{
                        width:1
                    },
                    name: 'Less Tax',
                    color:'rgb(164, 187, 200)',
                },
                {
                    data: avgLowPriceSeries,
                    type: 'line',
                    name: 'Low',
                    color:'orange',
                }
            ],
            tooltip: {
                axisPointer: {
                    snap:true,
                    type:'cross',
                },
                trigger:'axis',
                position: (point, params, dom, rect, size) => {
                    return [point[0]-200, point[1]-100];
                }
            },
            legend: {
                data: ['High','Low','Less Tax'],
                padding:0,
                margin:0,
                top:40,
            },
            grid: {
                right:30,
            }
        };
        const newChartVolumeOptions = {
            xAxis: {
                type: 'category',
                data: spotlightedItem.timeSeriesData[interval].map((e)=>e.timestampRepr),
                snap:true,
            },
            yAxis: {
                type: 'value',
            },
            series: [
                {
                    data: highVolumeSeries,
                    type: 'bar',
                    name: 'High',
                    color:'rgb(31, 43, 59)'
                },
                {
                    data: lowVolumeSeries,
                    type: 'bar',
                    name: 'Low',
                    color:'orange'

                }
            ],
            tooltip: {
                axisPointer: {
                    snap:true,
                    type:'cross',
                },
                trigger:'axis',
                position: (point, params, dom, rect, size) => {
                    return [point[0]-200, point[1]-100];
                }
            },
            grid: {
                right:30,
            }
        };
        setChartPriceOptions(newChartPriceOptions)
        setChartVolumeOptions(newChartVolumeOptions)
    },[spotlightedItem,interval])

    


    

    return (
        <div className='chart-container'>
            <ReactECharts option={chartPriceOptions} />
            {enlarge && <ReactECharts option={chartVolumeOptions}></ReactECharts>}
        </div>
    )
}

export default Chart