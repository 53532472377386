import ProfitMatrix from './profitMatrix'
import Item from './item'
const sets = ({ sets, tab, getItemData }) => {


    return (
        <ul className='sets-display-container'>
            {sets.map((set)=>{
                return (
                <li className='set-container' key={`set-${set.id}`}>
                    <Item item={set} getItemData={getItemData}></Item>
                    <ProfitMatrix set={set} tab={tab}></ProfitMatrix>
                    <div className='flipped'>
                        <div className='component-container flipped2'>
                        {set.components.map((component)=>{
                            return <Item key={`set34g${set.id}${component.id}`} item={component} getItemData={getItemData}></Item>
                        })}
                        </div>
                    </div>
                </li>
                )
            })}

        </ul>
    )
}

export default sets