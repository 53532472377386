import { useState } from 'react'

import Loader from './loader'

const Header = ({ currentTab, setCurrentTab, getAllData }) => {
    const [refreshing, setRefreshing] = useState(false)
    const tabTitles = [{tabName:'Items',dataName:'items'},{tabName:'Sets',dataName:'sets'},{tabName:'Barrows Repair',dataName:'barrowsRepair'}]
    
    const refresh = async () => {
        setRefreshing(true)
        const fetched = await getAllData()
        setRefreshing(false)
    }
    
    return (
        <header>
            <div className='tab-container'>
                {tabTitles.map((tab)=>{
                    return (
                        <div key={`tab-title${tab.tabName}`} onClick={()=>setCurrentTab(tab.dataName)} className={`tab${currentTab === tab.dataName ? ' selected' : ''}`}>
                            <span className='tab-name'>{tab.tabName}</span>
                        </div>
                    )
                })}
                <button className='default-button wide' disabled={refreshing} onClick={refresh}>{refreshing ? <Loader size='sm'></Loader> : 'Refresh Data'}</button>
            </div>

        </header>
    )
}

export default Header