const Item = ({ item, getItemData }) => {
    return (
        <div className='item-container'>
            <div className='item-header'>
                <h1>
                    <span className={`item-name${getItemData !== null ? ' clickable' : ' dark'}`} onClick={getItemData ? ()=>getItemData(item.id) : ()=>{}}>{item.name + (item.count > 1 ? ' x ' + item.count : '')}</span>
                    <span className='item-subtext'> 
                        <span className='item-id'>ID: {item.id}</span>
                        {item.limit && <span className='item-limit'> limit: {item.limit}</span>}
                        <span className='super-text'><a className='link' href={`https://oldschool.runescape.wiki/w/${item.name.replaceAll(' ','_')}`} target='_blank'>Wiki &#x2197;&#xFE0E;</a></span>
                    </span>
                </h1>
            </div>
            
            <div className='item-info-container'>
                <img className='item-thumb' src={`https://oldschool.runescape.wiki/images/thumb/${item.name.replaceAll(" ","_") + (item.id == 27616 ? '_500':'')}_detail.png/150px-${item.name.replaceAll(" ","_")}_detail.png`}></img>
                <table className='item-table'>
                    <tbody>
                        <tr>
                            <th>High</th>
                            <td>{item.highRepr}<span className='super-text'>({item.highTimeRepr})</span></td>
                            <th>Hourly High</th>
                            <td>{item.avgHighPriceRepr}</td>
                        </tr>
                        <tr>
                            <th>Low</th>
                            <td>{item.lowRepr}<span className='super-text'>({item.lowTimeRepr})</span></td>
                            <th>Hourly Low</th>
                            <td>{item.avgLowPriceRepr}</td>
                        </tr>
                        <tr>
                            <th>Tax</th>
                            <td>-{item.taxRepr}</td>
                            <th>High Volume</th>
                            <td>{item.highPriceVolume}</td>
                        </tr>
                        <tr>
                            <th></th>
                            <td></td>
                            <th>Low Volume</th>
                            <td>{item.lowPriceVolume}</td>

                        </tr>
                        <tr>
                            <th>Profit</th>
                            <td>{item.latestMarginRepr}<span className='super-text'>({item.latestMarginPercentRepr})</span></td>
                            <th>Hourly Profit</th>
                            <td>{item.hourlyMarginRepr}<span className='super-text'>({item.hourlyMarginPercentRepr})</span></td>

                        </tr>

                    </tbody>


                </table>
            </div>



        </div>
    )
}

export default Item
