const Loader = ({size}) => {
    

    return (
        <div className="loader-c">
            <div className={`loader ${size}`}>
            </div>
        </div>
    )
} 


export default Loader