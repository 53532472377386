

const ProfitMatrix = ({ set, tab }) => {

    return (
        <div className='profit-matrix-container'>
            <div>
                <h3 className='profit-matrix-header'>Buy Components</h3>
                <table className={`profit-matrix${set.margins.bhslSellSetLatest > 0 ? ' highlight' : ''}`}>
                    <tbody>
                        <tr>
                            <th></th>
                            <th>Buy Low</th>
                            <th>Buy High</th>
                        </tr>
                        <tr>
                            <th>Sell High</th>
                            <td>{set.margins.blshSellSetLatestRepr}</td>
                            <td>{set.margins.bhshSellSetLatestRepr}</td>
                        </tr>
                        <tr>
                            <th>Sell Low</th>
                            <td>{set.margins.blslSellSetLatestRepr}</td>
                            <td>{set.margins.bhslSellSetLatestRepr}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {tab != 'barrowsRepair' &&
            <div>
                <h3 className='profit-matrix-header'>Buy Set</h3>
                <table className={`profit-matrix${set.margins.bhslSellComponentsLatest > 0 ? ' highlight' : ''}`}>
                    <tbody>
                        <tr>
                            <th></th>
                            <th>Buy Low</th>
                            <th>Buy High</th>
                        </tr>
                        <tr>
                            <th>Sell High</th>
                            <td>{set.margins.blshSellComponentsLatestRepr}</td>
                            <td>{set.margins.bhshSellComponentsLatestRepr}</td>
                        </tr>
                        <tr>
                            <th>Sell Low</th>
                            <td>{set.margins.blslSellComponentsLatestRepr}</td>
                            <td>{set.margins.bhslSellComponentsLatestRepr}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            }
        </div>
    )
}

export default ProfitMatrix