const mapper = [
{
"examine": "Fabulously ancient mage protection enchanted in the 3rd Age.",
"id": 10344,
"members": true,
"lowalch": 20200,
"limit": 8,
"value": 50500,
"highalch": 30300,
"icon": "3rd age amulet.png",
"name": "3rd age amulet"
},
{
"examine": "A beautifully crafted axe, shaped by ancient smiths.",
"id": 20011,
"members": true,
"lowalch": 22000,
"limit": 40,
"value": 55000,
"highalch": 33000,
"icon": "3rd age axe.png",
"name": "3rd age axe"
},
{
"examine": "A beautifully crafted bow carved by ancient archers.",
"id": 12424,
"members": true,
"lowalch": 60000,
"limit": 8,
"value": 150000,
"highalch": 90000,
"icon": "3rd age bow.png",
"name": "3rd age bow"
},
{
"examine": "A beautiful cloak woven by ancient tailors.",
"id": 12437,
"members": true,
"lowalch": 34000,
"limit": 8,
"value": 85000,
"highalch": 51000,
"icon": "3rd age cloak.png",
"name": "3rd age cloak"
},
{
"examine": "A fabulously ancient vine cloak as worn by the druids of old.",
"id": 23345,
"members": true,
"lowalch": 80000,
"limit": 8,
"value": 200000,
"highalch": 120000,
"icon": "3rd age druidic cloak.png",
"name": "3rd age druidic cloak"
},
{
"examine": "A fabulously ancient woven robe as worn by the druids of old.",
"id": 23339,
"members": true,
"lowalch": 80000,
"limit": 4,
"value": 200000,
"highalch": 120000,
"icon": "3rd age druidic robe bottoms.png",
"name": "3rd age druidic robe bottoms"
},
{
"examine": "A fabulously ancient woven robe top as worn by the druids of old.",
"id": 23336,
"members": true,
"lowalch": 80000,
"limit": 4,
"value": 200000,
"highalch": 120000,
"icon": "3rd age druidic robe top.png",
"name": "3rd age druidic robe top"
},
{
"examine": "A fabulously ancient gnarled staff as used by the druids of old.",
"id": 23342,
"members": true,
"lowalch": 80000,
"limit": 8,
"value": 200000,
"highalch": 120000,
"icon": "3rd age druidic staff.png",
"name": "3rd age druidic staff"
},
{
"highalch": 33000,
"members": true,
"name": "3rd age felling axe",
"examine": "A beautifully crafted axe, shaped by ancient smiths.",
"id": 28226,
"value": 55000,
"icon": "3rd age felling axe.png",
"lowalch": 22000
},
{
"examine": "Fabulously ancient armour beaten from magical silver.",
"id": 10350,
"members": true,
"lowalch": 36000,
"limit": 8,
"value": 90000,
"highalch": 54000,
"icon": "3rd age full helmet.png",
"name": "3rd age full helmet"
},
{
"examine": "Fabulously ancient armour beaten from magical silver.",
"id": 10352,
"members": true,
"lowalch": 72000,
"limit": 8,
"value": 180000,
"highalch": 108000,
"icon": "3rd age kiteshield.png",
"name": "3rd age kiteshield"
},
{
"examine": "A beautifully crafted sword forged by ancient blacksmiths.",
"id": 12426,
"members": true,
"lowalch": 60000,
"limit": 8,
"value": 150000,
"highalch": 90000,
"icon": "3rd age longsword.png",
"name": "3rd age longsword"
},
{
"examine": "Fabulously ancient mage protection enchanted in the 3rd Age.",
"id": 10342,
"members": true,
"lowalch": 20160,
"limit": 8,
"value": 50400,
"highalch": 30240,
"icon": "3rd age mage hat.png",
"name": "3rd age mage hat"
},
{
"examine": "A beautifully crafted pickaxe, shaped by ancient smiths.",
"id": 20014,
"members": true,
"lowalch": 39180,
"limit": 40,
"value": 97950,
"highalch": 58770,
"icon": "3rd age pickaxe.png",
"name": "3rd age pickaxe"
},
{
"examine": "Fabulously ancient armour beaten from magical silver.",
"id": 10348,
"members": true,
"lowalch": 80000,
"limit": 8,
"value": 200000,
"highalch": 120000,
"icon": "3rd age platebody.png",
"name": "3rd age platebody"
},
{
"examine": "Fabulously ancient armour beaten from magical silver.",
"id": 10346,
"members": true,
"lowalch": 80000,
"limit": 8,
"value": 200000,
"highalch": 120000,
"icon": "3rd age platelegs.png",
"name": "3rd age platelegs"
},
{
"examine": "Fabulously ancient armour beaten from magical silver.",
"id": 23242,
"members": true,
"lowalch": 80000,
"limit": 8,
"value": 200000,
"highalch": 120000,
"icon": "3rd age plateskirt.png",
"name": "3rd age plateskirt"
},
{
"examine": "Fabulously ancient range protection crafted from white dragonhide.",
"id": 10334,
"members": true,
"lowalch": 20160,
"limit": 8,
"value": 50400,
"highalch": 30240,
"icon": "3rd age range coif.png",
"name": "3rd age range coif"
},
{
"examine": "Fabulously ancient range protection crafted from white dragonhide.",
"id": 10332,
"members": true,
"lowalch": 20240,
"limit": 8,
"value": 50600,
"highalch": 30360,
"icon": "3rd age range legs.png",
"name": "3rd age range legs"
},
{
"examine": "Fabulously ancient range protection crafted from white dragonhide.",
"id": 10330,
"members": true,
"lowalch": 20320,
"limit": 8,
"value": 50800,
"highalch": 30480,
"icon": "3rd age range top.png",
"name": "3rd age range top"
},
{
"examine": "Fabulously ancient mage protection enchanted in the 3rd Age.",
"id": 10340,
"members": true,
"lowalch": 20240,
"limit": 8,
"value": 50600,
"highalch": 30360,
"icon": "3rd age robe.png",
"name": "3rd age robe"
},
{
"examine": "Fabulously ancient mage protection enchanted in the 3rd Age.",
"id": 10338,
"members": true,
"lowalch": 20320,
"limit": 8,
"value": 50800,
"highalch": 30480,
"icon": "3rd age robe top.png",
"name": "3rd age robe top"
},
{
"examine": "Fabulously ancient range protection crafted from white dragonhide.",
"id": 10336,
"members": true,
"lowalch": 20200,
"limit": 8,
"value": 50500,
"highalch": 30300,
"icon": "3rd age vambraces.png",
"name": "3rd age vambraces"
},
{
"examine": "A beautifully crafted wand infused by ancient wizards.",
"id": 12422,
"members": true,
"lowalch": 60000,
"limit": 8,
"value": 150000,
"highalch": 90000,
"icon": "3rd age wand.png",
"name": "3rd age wand"
},
{
"examine": "A big do about nothing.",
"id": 10392,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "A powdered wig.png",
"name": "A powdered wig"
},
{
"examine": "A heap of ashes.",
"id": 25775,
"members": true,
"lowalch": 1,
"limit": 7500,
"value": 1,
"highalch": 1,
"icon": "Abyssal ashes.png",
"name": "Abyssal ashes"
},
{
"examine": "Elements of deceased Abyssal Sires have been fused together.",
"id": 13263,
"members": true,
"lowalch": 104000,
"limit": 8,
"value": 260000,
"highalch": 156000,
"icon": "Abyssal bludgeon.png",
"name": "Abyssal bludgeon"
},
{
"examine": "Makes using the Abyss just slightly safer.",
"id": 11095,
"members": true,
"lowalch": 1680,
"limit": 10000,
"value": 4200,
"highalch": 2520,
"icon": "Abyssal bracelet.png",
"name": "Abyssal bracelet(5)"
},
{
"examine": "Something sharp from the body of a defeated Abyssal Sire.",
"id": 13265,
"members": true,
"lowalch": 46000,
"limit": 8,
"value": 115001,
"highalch": 69000,
"icon": "Abyssal dagger.png",
"name": "Abyssal dagger"
},
{
"examine": "Something sharp and poisonous from the body of a defeated Abyssal Sire.",
"id": 13267,
"members": true,
"lowalch": 46000,
"limit": 8,
"value": 115002,
"highalch": 69001,
"icon": "Abyssal dagger (p).png",
"name": "Abyssal dagger (p)"
},
{
"examine": "Something sharp and very poisonous from the body of a defeated Abyssal Sire.",
"id": 13269,
"members": true,
"lowalch": 46001,
"limit": 8,
"value": 115003,
"highalch": 69001,
"icon": "Abyssal dagger (p+).png",
"name": "Abyssal dagger (p+)"
},
{
"examine": "Something sharp and extremely poisonous from the body of a defeated Abyssal Sire.",
"id": 13271,
"members": true,
"lowalch": 46001,
"limit": 8,
"value": 115004,
"highalch": 69002,
"icon": "Abyssal dagger (p++).png",
"name": "Abyssal dagger (p++)"
},
{
"examine": "A weapon from the Abyss.",
"id": 4151,
"members": true,
"lowalch": 48000,
"limit": 70,
"value": 120001,
"highalch": 72000,
"icon": "Abyssal whip.png",
"name": "Abyssal whip"
},
{
"highalch": 1,
"members": true,
"name": "Accursed sceptre (au)",
"examine": "A mighty sceptre used in long forgotten battles.",
"id": 27676,
"value": 1,
"icon": "Accursed sceptre (au).png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Accursed sceptre (u)",
"examine": "A mighty sceptre from long forgotten battles, corrupted by the power of Vet'ion.",
"id": 27662,
"value": 1,
"icon": "Accursed sceptre (u).png",
"lowalch": 1
},
{
"examine": "These logs are longer than normal.",
"id": 2862,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 4,
"highalch": 2,
"icon": "Achey tree logs.png",
"name": "Achey tree logs"
},
{
"examine": "Plant this in a plantpot of soil to grow a sapling.",
"id": 5312,
"members": true,
"lowalch": 2,
"limit": 200,
"value": 6,
"highalch": 3,
"icon": "Acorn 5.png",
"name": "Acorn"
},
{
"examine": "A two handed sword.",
"id": 1317,
"members": false,
"lowalch": 2560,
"limit": 125,
"value": 6400,
"highalch": 3840,
"icon": "Adamant 2h sword.png",
"name": "Adamant 2h sword"
},
{
"examine": "Arrows with adamant heads.",
"id": 890,
"members": false,
"lowalch": 32,
"limit": 11000,
"value": 80,
"highalch": 48,
"icon": "Adamant arrow 5.png",
"name": "Adamant arrow"
},
{
"examine": "Venomous-looking arrows.",
"id": 891,
"members": true,
"lowalch": 32,
"limit": 11000,
"value": 80,
"highalch": 48,
"icon": "Adamant arrow(p) 5.png",
"name": "Adamant arrow(p)"
},
{
"examine": "Venomous-looking arrows.",
"id": 5620,
"members": true,
"lowalch": 32,
"limit": 11000,
"value": 80,
"highalch": 48,
"icon": "Adamant arrow(p+) 5.png",
"name": "Adamant arrow(p+)"
},
{
"examine": "Venomous-looking arrows.",
"id": 5626,
"members": true,
"lowalch": 32,
"limit": 11000,
"value": 80,
"highalch": 48,
"icon": "Adamant arrow(p++) 5.png",
"name": "Adamant arrow(p++)"
},
{
"examine": "I can make some arrows with these.",
"id": 43,
"members": true,
"lowalch": 16,
"limit": 10000,
"value": 40,
"highalch": 24,
"icon": "Adamant arrowtips.png",
"name": "Adamant arrowtips"
},
{
"examine": "A powerful axe.",
"id": 1357,
"members": false,
"lowalch": 512,
"limit": 40,
"value": 1280,
"highalch": 768,
"icon": "Adamant axe.png",
"name": "Adamant axe"
},
{
"examine": "A vicious looking axe.",
"id": 1371,
"members": false,
"lowalch": 1664,
"limit": 125,
"value": 4160,
"highalch": 2496,
"icon": "Adamant battleaxe.png",
"name": "Adamant battleaxe"
},
{
"examine": "Adamantite crossbow bolts.",
"id": 9143,
"members": true,
"lowalch": 23,
"limit": 11000,
"value": 58,
"highalch": 34,
"icon": "Adamant bolts 5.png",
"name": "Adamant bolts"
},
{
"examine": "Some poisoned adamantite bolts.",
"id": 9290,
"members": true,
"lowalch": 23,
"limit": 11000,
"value": 58,
"highalch": 34,
"icon": "Adamant bolts (p) 5.png",
"name": "Adamant bolts (p)"
},
{
"examine": "Some poisoned adamantite bolts.",
"id": 9297,
"members": true,
"lowalch": 23,
"limit": 11000,
"value": 58,
"highalch": 34,
"icon": "Adamant bolts (p+) 5.png",
"name": "Adamant bolts (p+)"
},
{
"examine": "Super poisoned adamantite bolts.",
"id": 9304,
"members": true,
"lowalch": 23,
"limit": 11000,
"value": 58,
"highalch": 34,
"icon": "Adamant bolts (p++) 5.png",
"name": "Adamant bolts (p++)"
},
{
"examine": "Unfeathered adamantite crossbow bolts.",
"id": 9380,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Adamant bolts(unf) 5.png",
"name": "Adamant bolts(unf)"
},
{
"examine": "These will protect my feet.",
"id": 4129,
"members": true,
"lowalch": 768,
"limit": 125,
"value": 1920,
"highalch": 1152,
"icon": "Adamant boots.png",
"name": "Adamant boots"
},
{
"examine": "Blunt adamantite arrow... ouch.",
"id": 4798,
"members": true,
"lowalch": 38,
"limit": 11000,
"value": 95,
"highalch": 57,
"icon": "Adamant brutal 5.png",
"name": "Adamant brutal"
},
{
"examine": "A diamond topped cane.",
"id": 12377,
"members": true,
"lowalch": 576,
"limit": 4,
"value": 1440,
"highalch": 864,
"icon": "Adamant cane.png",
"name": "Adamant cane"
},
{
"examine": "A series of connected metal rings.",
"id": 1111,
"members": false,
"lowalch": 1920,
"limit": 125,
"value": 4800,
"highalch": 2880,
"icon": "Adamant chainbody.png",
"name": "Adamant chainbody"
},
{
"examine": "A set of fighting claws.",
"id": 3100,
"members": true,
"lowalch": 480,
"limit": 125,
"value": 1200,
"highalch": 720,
"icon": "Adamant claws.png",
"name": "Adamant claws"
},
{
"examine": "An adamantite crossbow.",
"id": 9183,
"members": true,
"lowalch": 706,
"limit": 70,
"value": 1767,
"highalch": 1060,
"icon": "Adamant crossbow.png",
"name": "Adamant crossbow"
},
{
"examine": "An unstrung adamantite crossbow.",
"id": 9463,
"members": true,
"lowalch": 693,
"limit": 10000,
"value": 1733,
"highalch": 1039,
"icon": "Adamant crossbow (u).png",
"name": "Adamant crossbow (u)"
},
{
"examine": "Short and deadly.",
"id": 1211,
"members": false,
"lowalch": 320,
"limit": 125,
"value": 800,
"highalch": 480,
"icon": "Adamant dagger.png",
"name": "Adamant dagger"
},
{
"examine": "A very dangerous poisoned dagger.",
"id": 1227,
"members": true,
"lowalch": 320,
"limit": 125,
"value": 800,
"highalch": 480,
"icon": "Adamant dagger(p).png",
"name": "Adamant dagger(p)"
},
{
"examine": "A very dangerous poisoned dagger.",
"id": 5676,
"members": true,
"lowalch": 320,
"limit": 125,
"value": 800,
"highalch": 480,
"icon": "Adamant dagger(p+).png",
"name": "Adamant dagger(p+)"
},
{
"examine": "A very dangerous poisoned dagger.",
"id": 5694,
"members": true,
"lowalch": 320,
"limit": 125,
"value": 800,
"highalch": 480,
"icon": "Adamant dagger(p++).png",
"name": "Adamant dagger(p++)"
},
{
"examine": "A deadly throwing dart with an adamant tip.",
"id": 810,
"members": true,
"lowalch": 26,
"limit": 11000,
"value": 65,
"highalch": 39,
"icon": "Adamant dart.png",
"name": "Adamant dart"
},
{
"examine": "A deadly-looking dart tip made of adamant - needs feathers for flight.",
"id": 823,
"members": true,
"lowalch": 14,
"limit": 20000,
"value": 36,
"highalch": 21,
"icon": "Adamant dart tip.png",
"name": "Adamant dart tip"
},
{
"examine": "A deadly poisoned dart with an adamant tip.",
"id": 816,
"members": true,
"lowalch": 26,
"limit": 11000,
"value": 65,
"highalch": 39,
"icon": "Adamant dart(p).png",
"name": "Adamant dart(p)"
},
{
"examine": "A deadly poisoned dart with an adamant tip.",
"id": 5633,
"members": true,
"lowalch": 26,
"limit": 11000,
"value": 65,
"highalch": 39,
"icon": "Adamant dart(p+).png",
"name": "Adamant dart(p+)"
},
{
"examine": "A deadly poisoned dart with an adamant tip.",
"id": 5640,
"members": true,
"lowalch": 26,
"limit": 11000,
"value": 65,
"highalch": 39,
"icon": "Adamant dart(p++).png",
"name": "Adamant dart(p++)"
},
{
"examine": "Do I look scary?",
"id": 23270,
"members": true,
"lowalch": 4000,
"limit": 4,
"value": 10000,
"highalch": 6000,
"icon": "Adamant dragon mask.png",
"name": "Adamant dragon mask"
},
{
"highalch": 768,
"members": true,
"name": "Adamant felling axe",
"examine": "A woodcutter's axe.",
"id": 28211,
"value": 1280,
"icon": "Adamant felling axe.png",
"lowalch": 512
},
{
"examine": "A full face helmet.",
"id": 1161,
"members": false,
"lowalch": 1408,
"limit": 125,
"value": 3520,
"highalch": 2112,
"icon": "Adamant full helm.png",
"name": "Adamant full helm"
},
{
"examine": "Adamant full helmet with gold trim.",
"id": 2613,
"members": false,
"lowalch": 1408,
"limit": 8,
"value": 3520,
"highalch": 2112,
"icon": "Adamant full helm (g).png",
"name": "Adamant full helm (g)"
},
{
"examine": "Adamant full helmet with trim.",
"id": 2605,
"members": false,
"lowalch": 1408,
"limit": 8,
"value": 3520,
"highalch": 2112,
"icon": "Adamant full helm (t).png",
"name": "Adamant full helm (t)"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 13020,
"members": false,
"lowalch": 3200,
"limit": 8,
"value": 8000,
"highalch": 4800,
"icon": "Adamant gold-trimmed set (lg).png",
"name": "Adamant gold-trimmed set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 13022,
"members": false,
"lowalch": 3200,
"limit": 8,
"value": 8000,
"highalch": 4800,
"icon": "Adamant gold-trimmed set (sk).png",
"name": "Adamant gold-trimmed set (sk)"
},
{
"examine": "An adamant halberd.",
"id": 3200,
"members": true,
"lowalch": 2560,
"limit": 125,
"value": 6400,
"highalch": 3840,
"icon": "Adamant halberd.png",
"name": "Adamant halberd"
},
{
"examine": "An adamant-tipped, one-handed hasta.",
"id": 11375,
"members": true,
"lowalch": 832,
"limit": 125,
"value": 2080,
"highalch": 1248,
"icon": "Adamant hasta.png",
"name": "Adamant hasta"
},
{
"examine": "A poison-tipped, one-handed adamantite hasta.",
"id": 11407,
"members": true,
"lowalch": 832,
"limit": 125,
"value": 2080,
"highalch": 1248,
"icon": "Adamant hasta(p).png",
"name": "Adamant hasta(p)"
},
{
"examine": "A poison-tipped, one-handed adamantite hasta.",
"id": 11410,
"members": true,
"lowalch": 832,
"limit": 125,
"value": 2080,
"highalch": 1248,
"icon": "Adamant hasta(p+).png",
"name": "Adamant hasta(p+)"
},
{
"examine": "A poison-tipped, one-handed adamantite hasta.",
"id": 11412,
"members": true,
"lowalch": 832,
"limit": 125,
"value": 2080,
"highalch": 1248,
"icon": "Adamant hasta(p++).png",
"name": "Adamant hasta(p++)"
},
{
"examine": "An adamant helmet with a heraldic design.",
"id": 10296,
"members": false,
"lowalch": 1408,
"limit": 70,
"value": 3520,
"highalch": 2112,
"icon": "Adamant helm (h1).png",
"name": "Adamant helm (h1)"
},
{
"examine": "An adamant helmet with a heraldic design.",
"id": 10298,
"members": false,
"lowalch": 1408,
"limit": 70,
"value": 3520,
"highalch": 2112,
"icon": "Adamant helm (h2).png",
"name": "Adamant helm (h2)"
},
{
"examine": "An adamant helmet with a heraldic design.",
"id": 10300,
"members": false,
"lowalch": 1408,
"limit": 70,
"value": 3520,
"highalch": 2112,
"icon": "Adamant helm (h3).png",
"name": "Adamant helm (h3)"
},
{
"examine": "An adamant helmet with a heraldic design.",
"id": 10302,
"members": false,
"lowalch": 1408,
"limit": 70,
"value": 3520,
"highalch": 2112,
"icon": "Adamant helm (h4).png",
"name": "Adamant helm (h4)"
},
{
"examine": "An adamant helmet with a heraldic design.",
"id": 10304,
"members": false,
"lowalch": 1408,
"limit": 70,
"value": 3520,
"highalch": 2112,
"icon": "Adamant helm (h5).png",
"name": "Adamant helm (h5)"
},
{
"examine": "An adamant tipped javelin.",
"id": 829,
"members": true,
"lowalch": 64,
"limit": 11000,
"value": 160,
"highalch": 96,
"icon": "Adamant javelin.png",
"name": "Adamant javelin"
},
{
"examine": "Needs a shaft.",
"id": 19578,
"members": true,
"lowalch": 176,
"limit": 10000,
"value": 440,
"highalch": 264,
"icon": "Adamant javelin heads 5.png",
"name": "Adamant javelin heads"
},
{
"examine": "An adamant tipped javelin.",
"id": 835,
"members": true,
"lowalch": 64,
"limit": 11000,
"value": 160,
"highalch": 96,
"icon": "Adamant javelin(p).png",
"name": "Adamant javelin(p)"
},
{
"examine": "An adamant tipped javelin.",
"id": 5646,
"members": true,
"lowalch": 64,
"limit": 11000,
"value": 160,
"highalch": 96,
"icon": "Adamant javelin(p+).png",
"name": "Adamant javelin(p+)"
},
{
"examine": "An adamant tipped javelin.",
"id": 5652,
"members": true,
"lowalch": 64,
"limit": 11000,
"value": 160,
"highalch": 96,
"icon": "Adamant javelin(p++).png",
"name": "Adamant javelin(p++)"
},
{
"examine": "A large metal shield.",
"id": 1199,
"members": false,
"lowalch": 2176,
"limit": 125,
"value": 5440,
"highalch": 3264,
"icon": "Adamant kiteshield.png",
"name": "Adamant kiteshield"
},
{
"examine": "Adamant kiteshield with gold trim.",
"id": 2611,
"members": false,
"lowalch": 2176,
"limit": 8,
"value": 5440,
"highalch": 3264,
"icon": "Adamant kiteshield (g).png",
"name": "Adamant kiteshield (g)"
},
{
"examine": "Adamant kiteshield with trim.",
"id": 2603,
"members": false,
"lowalch": 2176,
"limit": 8,
"value": 5440,
"highalch": 3264,
"icon": "Adamant kiteshield (t).png",
"name": "Adamant kiteshield (t)"
},
{
"examine": "A finely balanced throwing knife.",
"id": 867,
"members": true,
"lowalch": 26,
"limit": 11000,
"value": 66,
"highalch": 39,
"icon": "Adamant knife.png",
"name": "Adamant knife"
},
{
"examine": "A finely balanced throwing knife.",
"id": 875,
"members": true,
"lowalch": 26,
"limit": 11000,
"value": 66,
"highalch": 39,
"icon": "Adamant knife(p).png",
"name": "Adamant knife(p)"
},
{
"examine": "A finely balanced throwing knife.",
"id": 5659,
"members": true,
"lowalch": 26,
"limit": 7000,
"value": 66,
"highalch": 39,
"icon": "Adamant knife(p+).png",
"name": "Adamant knife(p+)"
},
{
"examine": "A finely balanced throwing knife.",
"id": 5666,
"members": true,
"lowalch": 26,
"limit": 7000,
"value": 66,
"highalch": 39,
"icon": "Adamant knife(p++).png",
"name": "Adamant knife(p++)"
},
{
"examine": "A razor sharp longsword.",
"id": 1301,
"members": false,
"lowalch": 1280,
"limit": 125,
"value": 3200,
"highalch": 1920,
"icon": "Adamant longsword.png",
"name": "Adamant longsword"
},
{
"examine": "A spiky mace.",
"id": 1430,
"members": false,
"lowalch": 576,
"limit": 125,
"value": 1440,
"highalch": 864,
"icon": "Adamant mace.png",
"name": "Adamant mace"
},
{
"examine": "A medium sized helmet.",
"id": 1145,
"members": false,
"lowalch": 768,
"limit": 125,
"value": 1920,
"highalch": 1152,
"icon": "Adamant med helm.png",
"name": "Adamant med helm"
},
{
"examine": "Used for mining.",
"id": 1271,
"members": false,
"lowalch": 1280,
"limit": 40,
"value": 3200,
"highalch": 1920,
"icon": "Adamant pickaxe.png",
"name": "Adamant pickaxe"
},
{
"examine": "Provides excellent protection.",
"id": 1123,
"members": false,
"lowalch": 6656,
"limit": 125,
"value": 16640,
"highalch": 9984,
"icon": "Adamant platebody.png",
"name": "Adamant platebody"
},
{
"examine": "Adamant platebody with gold trim.",
"id": 2607,
"members": false,
"lowalch": 6656,
"limit": 8,
"value": 16640,
"highalch": 9984,
"icon": "Adamant platebody (g).png",
"name": "Adamant platebody (g)"
},
{
"examine": "Adamant platebody with a heraldic design.",
"id": 23392,
"members": false,
"lowalch": 6656,
"limit": 8,
"value": 16640,
"highalch": 9984,
"icon": "Adamant platebody (h1).png",
"name": "Adamant platebody (h1)"
},
{
"examine": "Adamant platebody with a heraldic design.",
"id": 23395,
"members": false,
"lowalch": 6656,
"limit": 8,
"value": 16640,
"highalch": 9984,
"icon": "Adamant platebody (h2).png",
"name": "Adamant platebody (h2)"
},
{
"examine": "Adamant platebody with a heraldic design.",
"id": 23398,
"members": false,
"lowalch": 6656,
"limit": 8,
"value": 16640,
"highalch": 9984,
"icon": "Adamant platebody (h3).png",
"name": "Adamant platebody (h3)"
},
{
"examine": "Adamant platebody with a heraldic design.",
"id": 23401,
"members": false,
"lowalch": 6656,
"limit": 8,
"value": 16640,
"highalch": 9984,
"icon": "Adamant platebody (h4).png",
"name": "Adamant platebody (h4)"
},
{
"examine": "Adamant platebody with a heraldic design.",
"id": 23404,
"members": false,
"lowalch": 6656,
"limit": 8,
"value": 16640,
"highalch": 9984,
"icon": "Adamant platebody (h5).png",
"name": "Adamant platebody (h5)"
},
{
"examine": "Adamant platebody with trim.",
"id": 2599,
"members": false,
"lowalch": 6656,
"limit": 8,
"value": 16640,
"highalch": 9984,
"icon": "Adamant platebody (t).png",
"name": "Adamant platebody (t)"
},
{
"examine": "These look pretty heavy.",
"id": 1073,
"members": false,
"lowalch": 2560,
"limit": 125,
"value": 6400,
"highalch": 3840,
"icon": "Adamant platelegs.png",
"name": "Adamant platelegs"
},
{
"examine": "Adamant platelegs with gold trim.",
"id": 2609,
"members": false,
"lowalch": 2560,
"limit": 8,
"value": 6400,
"highalch": 3840,
"icon": "Adamant platelegs (g).png",
"name": "Adamant platelegs (g)"
},
{
"examine": "Adamant platelegs with trim.",
"id": 2601,
"members": false,
"lowalch": 2560,
"limit": 8,
"value": 6400,
"highalch": 3840,
"icon": "Adamant platelegs (t).png",
"name": "Adamant platelegs (t)"
},
{
"examine": "Designer leg protection.",
"id": 1091,
"members": false,
"lowalch": 2560,
"limit": 125,
"value": 6400,
"highalch": 3840,
"icon": "Adamant plateskirt.png",
"name": "Adamant plateskirt"
},
{
"examine": "Adamant plateskirt with gold trim.",
"id": 3475,
"members": false,
"lowalch": 2560,
"limit": 8,
"value": 6400,
"highalch": 3840,
"icon": "Adamant plateskirt (g).png",
"name": "Adamant plateskirt (g)"
},
{
"examine": "Adamant plateskirt with trim.",
"id": 3474,
"members": false,
"lowalch": 2560,
"limit": 8,
"value": 6400,
"highalch": 3840,
"icon": "Adamant plateskirt (t).png",
"name": "Adamant plateskirt (t)"
},
{
"examine": "A vicious, curved sword.",
"id": 1331,
"members": false,
"lowalch": 1024,
"limit": 125,
"value": 2560,
"highalch": 1536,
"icon": "Adamant scimitar.png",
"name": "Adamant scimitar"
},
{
"highalch": 1,
"members": true,
"name": "Adamant seeds",
"examine": "Magical seeds in an adamant case.",
"id": 29458,
"value": 1,
"icon": "Adamant seeds.png",
"lowalch": 1
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 13012,
"members": false,
"lowalch": 3200,
"limit": 8,
"value": 8000,
"highalch": 4800,
"icon": "Adamant set (lg).png",
"name": "Adamant set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 13014,
"members": false,
"lowalch": 3200,
"limit": 8,
"value": 8000,
"highalch": 4800,
"icon": "Adamant set (sk).png",
"name": "Adamant set (sk)"
},
{
"examine": "A shield with a heraldic design",
"id": 7334,
"members": false,
"lowalch": 2176,
"limit": 70,
"value": 5440,
"highalch": 3264,
"icon": "Adamant shield (h1).png",
"name": "Adamant shield (h1)"
},
{
"examine": "A shield with a heraldic design",
"id": 7340,
"members": false,
"lowalch": 2176,
"limit": 70,
"value": 5440,
"highalch": 3264,
"icon": "Adamant shield (h2).png",
"name": "Adamant shield (h2)"
},
{
"examine": "A shield with a heraldic design",
"id": 7346,
"members": false,
"lowalch": 2176,
"limit": 70,
"value": 5440,
"highalch": 3264,
"icon": "Adamant shield (h3).png",
"name": "Adamant shield (h3)"
},
{
"examine": "A shield with a heraldic design",
"id": 7352,
"members": false,
"lowalch": 2176,
"limit": 70,
"value": 5440,
"highalch": 3264,
"icon": "Adamant shield (h4).png",
"name": "Adamant shield (h4)"
},
{
"examine": "A shield with a heraldic design",
"id": 7358,
"members": false,
"lowalch": 2176,
"limit": 70,
"value": 5440,
"highalch": 3264,
"icon": "Adamant shield (h5).png",
"name": "Adamant shield (h5)"
},
{
"examine": "An adamantite tipped spear.",
"id": 1245,
"members": true,
"lowalch": 832,
"limit": 125,
"value": 2080,
"highalch": 1248,
"icon": "Adamant spear.png",
"name": "Adamant spear"
},
{
"examine": "A poisoned adamantite tipped spear.",
"id": 1259,
"members": true,
"lowalch": 832,
"limit": 125,
"value": 2080,
"highalch": 1248,
"icon": "Adamant spear(p).png",
"name": "Adamant spear(p)"
},
{
"examine": "A poisoned adamantite tipped spear.",
"id": 5712,
"members": true,
"lowalch": 832,
"limit": 125,
"value": 2080,
"highalch": 1248,
"icon": "Adamant spear(p+).png",
"name": "Adamant spear(p+)"
},
{
"examine": "A poisoned adamantite tipped spear.",
"id": 5726,
"members": true,
"lowalch": 832,
"limit": 125,
"value": 2080,
"highalch": 1248,
"icon": "Adamant spear(p++).png",
"name": "Adamant spear(p++)"
},
{
"examine": "A medium square shield.",
"id": 1183,
"members": false,
"lowalch": 1536,
"limit": 125,
"value": 3840,
"highalch": 2304,
"icon": "Adamant sq shield.png",
"name": "Adamant sq shield"
},
{
"examine": "A razor sharp sword.",
"id": 1287,
"members": false,
"lowalch": 832,
"limit": 125,
"value": 2080,
"highalch": 1248,
"icon": "Adamant sword.png",
"name": "Adamant sword"
},
{
"examine": "A finely balanced throwing axe.",
"id": 804,
"members": true,
"lowalch": 70,
"limit": 11000,
"value": 176,
"highalch": 105,
"icon": "Adamant thrownaxe.png",
"name": "Adamant thrownaxe"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 13016,
"members": false,
"lowalch": 3200,
"limit": 8,
"value": 8000,
"highalch": 4800,
"icon": "Adamant trimmed set (lg).png",
"name": "Adamant trimmed set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 13018,
"members": false,
"lowalch": 3200,
"limit": 8,
"value": 8000,
"highalch": 4800,
"icon": "Adamant trimmed set (sk).png",
"name": "Adamant trimmed set (sk)"
},
{
"examine": "I don't think it's intended for joinery.",
"id": 1345,
"members": false,
"lowalch": 1648,
"limit": 125,
"value": 4120,
"highalch": 2472,
"icon": "Adamant warhammer.png",
"name": "Adamant warhammer"
},
{
"examine": "It's a bar of adamantite.",
"id": 2361,
"members": false,
"lowalch": 256,
"limit": 10000,
"value": 640,
"highalch": 384,
"icon": "Adamantite bar.png",
"name": "Adamantite bar"
},
{
"examine": "A pair of adamantite crossbow limbs.",
"id": 9429,
"members": true,
"lowalch": 640,
"limit": 10000,
"value": 1600,
"highalch": 960,
"icon": "Adamantite limbs.png",
"name": "Adamantite limbs"
},
{
"examine": "Keeps things in place fairly permanently.",
"id": 4823,
"members": true,
"lowalch": 18,
"limit": 13000,
"value": 45,
"highalch": 27,
"icon": "Adamantite nails.png",
"name": "Adamantite nails"
},
{
"examine": "This needs refining.",
"id": 449,
"members": false,
"lowalch": 160,
"limit": 4500,
"value": 400,
"highalch": 240,
"icon": "Adamantite ore.png",
"name": "Adamantite ore"
},
{
"examine": "Much tastier than a normal fish pie.",
"id": 7198,
"members": true,
"lowalch": 124,
"limit": 10000,
"value": 310,
"highalch": 186,
"icon": "Admiral pie.png",
"name": "Admiral pie"
},
{
"examine": "Wild!",
"id": 12430,
"members": true,
"lowalch": 273,
"limit": 4,
"value": 684,
"highalch": 410,
"icon": "Afro.png",
"name": "Afro"
},
{
"examine": "One dose of fishy Agility potion.",
"id": 11463,
"members": true,
"lowalch": 20,
"limit": 2000,
"value": 50,
"highalch": 30,
"icon": "Agility mix(1).png",
"name": "Agility mix(1)"
},
{
"examine": "Two doses of fishy Agility potion.",
"id": 11461,
"members": true,
"lowalch": 40,
"limit": 2000,
"value": 100,
"highalch": 60,
"icon": "Agility mix(2).png",
"name": "Agility mix(2)"
},
{
"examine": "1 dose of Agility potion.",
"id": 3038,
"members": true,
"lowalch": 20,
"limit": 2000,
"value": 50,
"highalch": 30,
"icon": "Agility potion(1).png",
"name": "Agility potion(1)"
},
{
"examine": "2 doses of Agility potion.",
"id": 3036,
"members": true,
"lowalch": 40,
"limit": 2000,
"value": 100,
"highalch": 60,
"icon": "Agility potion(2).png",
"name": "Agility potion(2)"
},
{
"examine": "3 doses of Agility potion.",
"id": 3034,
"members": true,
"lowalch": 60,
"limit": 2000,
"value": 150,
"highalch": 90,
"icon": "Agility potion(3).png",
"name": "Agility potion(3)"
},
{
"examine": "4 doses of Agility potion.",
"id": 3032,
"members": true,
"lowalch": 80,
"limit": 2000,
"value": 200,
"highalch": 120,
"icon": "Agility potion(4).png",
"name": "Agility potion(4)"
},
{
"examine": "A set containing an Ahrim's hood, robetop, robeskirt and staff.",
"id": 12881,
"members": true,
"lowalch": 80000,
"limit": 8,
"value": 200000,
"highalch": 120000,
"icon": "Ahrim's armour set.png",
"name": "Ahrim's armour set"
},
{
"examine": "Ahrim the Blighted's leather hood.",
"id": 4708,
"members": true,
"lowalch": 5200,
"limit": 15,
"value": 13000,
"highalch": 7800,
"icon": "Ahrim's hood.png",
"name": "Ahrim's hood"
},
{
"examine": "Ahrim the Blighted's leather hood.",
"id": 4860,
"members": true,
"lowalch": 5200,
"limit": 15,
"value": 13000,
"highalch": 7800,
"icon": "Ahrim's hood 0.png",
"name": "Ahrim's hood 0"
},
{
"examine": "Ahrim the Blighted's armoured robe skirt.",
"id": 4714,
"members": true,
"lowalch": 18800,
"limit": 15,
"value": 47000,
"highalch": 28200,
"icon": "Ahrim's robeskirt.png",
"name": "Ahrim's robeskirt"
},
{
"examine": "Ahrim the Blighted's armoured robe skirt.",
"id": 4878,
"members": true,
"lowalch": 18800,
"limit": 15,
"value": 47000,
"highalch": 28200,
"icon": "Ahrim's robeskirt 0.png",
"name": "Ahrim's robeskirt 0"
},
{
"examine": "Ahrim the Blighted's armoured robe top.",
"id": 4712,
"members": true,
"lowalch": 20000,
"limit": 15,
"value": 50000,
"highalch": 30000,
"icon": "Ahrim's robetop.png",
"name": "Ahrim's robetop"
},
{
"examine": "Ahrim the Blighted's armoured robe top.",
"id": 4872,
"members": true,
"lowalch": 20000,
"limit": 15,
"value": 50000,
"highalch": 30000,
"icon": "Ahrim's robetop 0.png",
"name": "Ahrim's robetop 0"
},
{
"examine": "Ahrim the Blighted's quarterstaff.",
"id": 4710,
"members": true,
"lowalch": 34000,
"limit": 15,
"value": 85000,
"highalch": 51000,
"icon": "Ahrim's staff.png",
"name": "Ahrim's staff"
},
{
"examine": "Ahrim the Blighted's quarterstaff.",
"id": 4866,
"members": true,
"lowalch": 34000,
"limit": 15,
"value": 85000,
"highalch": 51000,
"icon": "Ahrim's staff 0.png",
"name": "Ahrim's staff 0"
},
{
"examine": "It's a slightly magical stick.",
"id": 1397,
"members": true,
"lowalch": 6200,
"limit": 18000,
"value": 15500,
"highalch": 9300,
"icon": "Air battlestaff.png",
"name": "Air battlestaff"
},
{
"examine": "A magic glowing orb.",
"id": 573,
"members": true,
"lowalch": 120,
"limit": 11000,
"value": 300,
"highalch": 180,
"icon": "Air orb.png",
"name": "Air orb"
},
{
"examine": "One of the 4 basic elemental Runes.",
"id": 556,
"members": false,
"lowalch": 1,
"limit": 50000,
"value": 4,
"highalch": 2,
"icon": "Air rune.png",
"name": "Air rune"
},
{
"examine": "A mysterious power emanates from the talisman...",
"id": 1438,
"members": false,
"lowalch": 1,
"limit": 11000,
"value": 4,
"highalch": 2,
"icon": "Air talisman.png",
"name": "Air talisman"
},
{
"examine": "A tiara infused with the properties of air.",
"id": 5527,
"members": false,
"lowalch": 40,
"limit": 40,
"value": 100,
"highalch": 60,
"icon": "Air tiara.png",
"name": "Air tiara"
},
{
"examine": "This is pretty well sealed.",
"id": 4436,
"members": true,
"lowalch": 4,
"limit": 15,
"value": 10,
"highalch": 6,
"icon": "Airtight pot.png",
"name": "Airtight pot"
},
{
"examine": "Ale of the gods.",
"id": 20056,
"members": true,
"lowalch": 340,
"limit": 4,
"value": 850,
"highalch": 510,
"icon": "Ale of the gods.png",
"name": "Ale of the gods"
},
{
"examine": "A pot filled with ale yeast.",
"id": 5767,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 1,
"highalch": 1,
"icon": "Ale yeast.png",
"name": "Ale yeast"
},
{
"examine": "Maybe I can make something with this.",
"id": 21347,
"members": true,
"lowalch": 1560,
"limit": 13000,
"value": 3900,
"highalch": 2340,
"icon": "Amethyst.png",
"name": "Amethyst"
},
{
"examine": "Arrows with amethyst heads.",
"id": 21326,
"members": true,
"lowalch": 196,
"limit": 11000,
"value": 490,
"highalch": 294,
"icon": "Amethyst arrow 5.png",
"name": "Amethyst arrow"
},
{
"examine": "Venomous-looking arrows.",
"id": 21332,
"members": true,
"lowalch": 196,
"limit": 11000,
"value": 490,
"highalch": 294,
"icon": "Amethyst arrow(p) 5.png",
"name": "Amethyst arrow(p)"
},
{
"examine": "Venomous-looking arrows.",
"id": 21334,
"members": true,
"lowalch": 196,
"limit": 11000,
"value": 490,
"highalch": 294,
"icon": "Amethyst arrow(p+) 5.png",
"name": "Amethyst arrow(p+)"
},
{
"examine": "Venomous-looking arrows.",
"id": 21336,
"members": true,
"lowalch": 196,
"limit": 11000,
"value": 490,
"highalch": 294,
"icon": "Amethyst arrow(p++) 5.png",
"name": "Amethyst arrow(p++)"
},
{
"examine": "I can make some arrows with these.",
"id": 21350,
"members": true,
"lowalch": 98,
"limit": 10000,
"value": 245,
"highalch": 147,
"icon": "Amethyst arrowtips.png",
"name": "Amethyst arrowtips"
},
{
"examine": "Amethyst bolt tips.",
"id": 21338,
"members": true,
"lowalch": 64,
"limit": 11000,
"value": 160,
"highalch": 96,
"icon": "Amethyst bolt tips 5.png",
"name": "Amethyst bolt tips"
},
{
"examine": "Crossbow bolts with amethyst broad tips.",
"id": 21316,
"members": true,
"lowalch": 96,
"limit": 11000,
"value": 240,
"highalch": 144,
"icon": "Amethyst broad bolts 5.png",
"name": "Amethyst broad bolts"
},
{
"examine": "A deadly throwing dart with an amethyst tip.",
"id": 25849,
"members": true,
"lowalch": 160,
"limit": 11000,
"value": 400,
"highalch": 240,
"icon": "Amethyst dart.png",
"name": "Amethyst dart"
},
{
"examine": "A deadly-looking dart tip made of amethyst - needs feathers for flight.",
"id": 25853,
"members": true,
"lowalch": 80,
"limit": 11000,
"value": 200,
"highalch": 120,
"icon": "Amethyst dart tip.png",
"name": "Amethyst dart tip"
},
{
"examine": "A deadly poisoned dart with an amethyst tip.",
"id": 25851,
"members": true,
"lowalch": 160,
"limit": 11000,
"value": 400,
"highalch": 240,
"icon": "Amethyst dart(p).png",
"name": "Amethyst dart(p)"
},
{
"examine": "A deadly poisoned dart with an amethyst tip.",
"id": 25855,
"members": true,
"lowalch": 160,
"limit": 11000,
"value": 400,
"highalch": 240,
"icon": "Amethyst dart(p+).png",
"name": "Amethyst dart(p+)"
},
{
"examine": "A deadly poisoned dart with an amethyst tip.",
"id": 25857,
"members": true,
"lowalch": 160,
"limit": 11000,
"value": 400,
"highalch": 240,
"icon": "Amethyst dart(p++).png",
"name": "Amethyst dart(p++)"
},
{
"examine": "An amethyst tipped javelin.",
"id": 21318,
"members": true,
"lowalch": 168,
"limit": 11000,
"value": 420,
"highalch": 252,
"icon": "Amethyst javelin.png",
"name": "Amethyst javelin"
},
{
"examine": "Needs a shaft.",
"id": 21352,
"members": true,
"lowalch": 608,
"limit": 10000,
"value": 1520,
"highalch": 912,
"icon": "Amethyst javelin heads 5.png",
"name": "Amethyst javelin heads"
},
{
"examine": "An amethyst tipped javelin.",
"id": 21320,
"members": true,
"lowalch": 168,
"limit": 7000,
"value": 420,
"highalch": 252,
"icon": "Amethyst javelin(p).png",
"name": "Amethyst javelin(p)"
},
{
"examine": "An amethyst tipped javelin.",
"id": 21322,
"members": true,
"lowalch": 168,
"limit": 7000,
"value": 420,
"highalch": 252,
"icon": "Amethyst javelin(p+).png",
"name": "Amethyst javelin(p+)"
},
{
"examine": "An amethyst tipped javelin.",
"id": 21324,
"members": true,
"lowalch": 168,
"limit": 7000,
"value": 420,
"highalch": 252,
"icon": "Amethyst javelin(p++).png",
"name": "Amethyst javelin(p++)"
},
{
"examine": "Used to make amulets.",
"id": 1595,
"members": false,
"lowalch": 2,
"limit": 40,
"value": 5,
"highalch": 3,
"icon": "Amulet mould.png",
"name": "Amulet mould"
},
{
"examine": "It increases my aim.",
"id": 1478,
"members": false,
"lowalch": 40,
"limit": 5,
"value": 100,
"highalch": 60,
"icon": "Amulet of accuracy.png",
"name": "Amulet of accuracy"
},
{
"examine": "A hauntingly beautiful amulet bearing the shape of a skull.",
"id": 22557,
"members": true,
"lowalch": 18000,
"limit": 8,
"value": 45000,
"highalch": 27000,
"icon": "Amulet of avarice.png",
"name": "Amulet of avarice"
},
{
"examine": "Saves seeds when planting in allotments.",
"id": 21160,
"members": true,
"lowalch": 360,
"limit": 10000,
"value": 900,
"highalch": 540,
"icon": "Amulet of bounty.png",
"name": "Amulet of bounty"
},
{
"examine": "Useful when making potions - has the chance of giving an extra dose.",
"id": 21163,
"members": true,
"lowalch": 420,
"limit": 10000,
"value": 1050,
"highalch": 630,
"icon": "Amulet of chemistry.png",
"name": "Amulet of chemistry"
},
{
"examine": "An enchanted emerald amulet of protection.",
"id": 1729,
"members": false,
"lowalch": 510,
"limit": 125,
"value": 1275,
"highalch": 765,
"icon": "Amulet of defence.png",
"name": "Amulet of defence"
},
{
"examine": "An enchanted emerald amulet of protection that looks good.",
"id": 23309,
"members": false,
"lowalch": 510,
"limit": 4,
"value": 1275,
"highalch": 765,
"icon": "Amulet of defence (t).png",
"name": "Amulet of defence (t)"
},
{
"examine": "A dragonstone amulet with an unlimited supply of magic charges.",
"id": 19707,
"members": true,
"lowalch": 7050,
"limit": 5,
"value": 17625,
"highalch": 10575,
"icon": "Amulet of eternal glory.png",
"name": "Amulet of eternal glory"
},
{
"examine": "A very powerful onyx amulet.",
"id": 6585,
"members": true,
"lowalch": 80800,
"limit": 8,
"value": 202000,
"highalch": 121200,
"icon": "Amulet of fury.png",
"name": "Amulet of fury"
},
{
"examine": "A very powerful dragonstone amulet.",
"id": 1704,
"members": true,
"lowalch": 7050,
"limit": 10000,
"value": 17625,
"highalch": 10575,
"icon": "Amulet of glory.png",
"name": "Amulet of glory"
},
{
"examine": "A very powerful dragonstone amulet.",
"id": 10362,
"members": true,
"lowalch": 7050,
"limit": 5,
"value": 17625,
"highalch": 10575,
"icon": "Amulet of glory (t).png",
"name": "Amulet of glory (t)"
},
{
"examine": "A very powerful dragonstone amulet.",
"id": 10354,
"members": true,
"lowalch": 7050,
"limit": 5,
"value": 17625,
"highalch": 10575,
"icon": "Amulet of glory (t4).png",
"name": "Amulet of glory (t4)"
},
{
"examine": "A very powerful dragonstone amulet.",
"id": 11964,
"members": true,
"lowalch": 7050,
"limit": 5,
"value": 17625,
"highalch": 10575,
"icon": "Amulet of glory (t6).png",
"name": "Amulet of glory (t6)"
},
{
"examine": "A dragonstone amulet with 4 magic charges.",
"id": 1712,
"members": true,
"lowalch": 7050,
"limit": 10000,
"value": 17625,
"highalch": 10575,
"icon": "Amulet of glory(4).png",
"name": "Amulet of glory(4)"
},
{
"examine": "A dragonstone amulet with 6 magic charges.",
"id": 11978,
"members": true,
"lowalch": 7050,
"limit": 10000,
"value": 17625,
"highalch": 10575,
"icon": "Amulet of glory(6).png",
"name": "Amulet of glory(6)"
},
{
"examine": "An enchanted sapphire amulet of magic.",
"id": 1727,
"members": false,
"lowalch": 360,
"limit": 125,
"value": 900,
"highalch": 540,
"icon": "Amulet of magic.png",
"name": "Amulet of magic"
},
{
"examine": "An enchanted sapphire amulet of magic.",
"id": 10366,
"members": false,
"lowalch": 360,
"limit": 5,
"value": 900,
"highalch": 540,
"icon": "Amulet of magic (t).png",
"name": "Amulet of magic (t)"
},
{
"examine": "An enchanted diamond amulet of power.",
"id": 1731,
"members": false,
"lowalch": 1410,
"limit": 125,
"value": 3525,
"highalch": 2115,
"icon": "Amulet of power.png",
"name": "Amulet of power"
},
{
"examine": "An enchanted diamond amulet that looks good.",
"id": 23354,
"members": false,
"lowalch": 1610,
"limit": 5,
"value": 4025,
"highalch": 2415,
"icon": "Amulet of power (t).png",
"name": "Amulet of power (t)"
},
{
"examine": "An enchanted ruby amulet.",
"id": 1725,
"members": false,
"lowalch": 810,
"limit": 125,
"value": 2025,
"highalch": 1215,
"icon": "Amulet of strength.png",
"name": "Amulet of strength"
},
{
"examine": "Heirloom of an ancient family of Morytania.",
"id": 12851,
"members": true,
"lowalch": 23200,
"limit": 8,
"value": 58001,
"highalch": 34800,
"icon": "Amulet of the damned (full).png",
"name": "Amulet of the damned (full)"
},
{
"examine": "A deep sense of torture burns within this powerful amulet.",
"id": 19553,
"members": true,
"lowalch": 80800,
"limit": 8,
"value": 202000,
"highalch": 121200,
"icon": "Amulet of torture.png",
"name": "Amulet of torture"
},
{
"examine": "Good for enhancing a super energy potion.",
"id": 12640,
"members": true,
"lowalch": 8,
"limit": 11000,
"value": 20,
"highalch": 12,
"icon": "Amylase crystal 5.png",
"name": "Amylase crystal"
},
{
"examine": "The hat of a powerful sorceress from a bygone era.",
"id": 21018,
"members": true,
"lowalch": 18000,
"limit": 8,
"value": 45000,
"highalch": 27000,
"icon": "Ancestral hat.png",
"name": "Ancestral hat"
},
{
"examine": "The robe bottoms of a powerful sorceress from a bygone era.",
"id": 21024,
"members": true,
"lowalch": 32000,
"limit": 8,
"value": 80000,
"highalch": 48000,
"icon": "Ancestral robe bottom.png",
"name": "Ancestral robe bottom"
},
{
"examine": "The robe top of a powerful sorceress from a bygone era.",
"id": 21021,
"members": true,
"lowalch": 48000,
"limit": 8,
"value": 120000,
"highalch": 72000,
"icon": "Ancestral robe top.png",
"name": "Ancestral robe top"
},
{
"examine": "A set containing an Ancestral hat, robe top and robe bottom.",
"id": 21049,
"members": true,
"lowalch": 58000,
"limit": 5,
"value": 145000,
"highalch": 87000,
"icon": "Ancestral robes set.png",
"name": "Ancestral robes set"
},
{
"examine": "Some nicely cooked anchovies.",
"id": 319,
"members": false,
"lowalch": 6,
"limit": 15000,
"value": 15,
"highalch": 9,
"icon": "Anchovies.png",
"name": "Anchovies"
},
{
"examine": "A pizza with anchovies.",
"id": 2297,
"members": false,
"lowalch": 24,
"limit": 10000,
"value": 60,
"highalch": 36,
"icon": "Anchovy pizza.png",
"name": "Anchovy pizza"
},
{
"examine": "The misery that is now upon us is but the passing of greed, the bitterness of those who fear the way of progress.",
"id": 20235,
"members": true,
"lowalch": 32,
"limit": 5,
"value": 80,
"highalch": 48,
"icon": "Ancient blessing.png",
"name": "Ancient blessing"
},
{
"examine": "Ancient blessed dragonhide vambraces.",
"id": 12490,
"members": true,
"lowalch": 1600,
"limit": 8,
"value": 4000,
"highalch": 2400,
"icon": "Ancient bracers.png",
"name": "Ancient bracers"
},
{
"examine": "1 dose of ancient brew.",
"id": 26346,
"members": true,
"lowalch": 50,
"limit": 2000,
"value": 125,
"highalch": 75,
"icon": "Ancient brew(1).png",
"name": "Ancient brew(1)"
},
{
"examine": "2 doses of ancient brew.",
"id": 26344,
"members": true,
"lowalch": 60,
"limit": 2000,
"value": 150,
"highalch": 90,
"icon": "Ancient brew(2).png",
"name": "Ancient brew(2)"
},
{
"examine": "3 doses of ancient brew.",
"id": 26342,
"members": true,
"lowalch": 70,
"limit": 2000,
"value": 175,
"highalch": 105,
"icon": "Ancient brew(3).png",
"name": "Ancient brew(3)"
},
{
"examine": "4 doses of ancient brew.",
"id": 26340,
"members": true,
"lowalch": 80,
"limit": 2000,
"value": 200,
"highalch": 120,
"icon": "Ancient brew(4).png",
"name": "Ancient brew(4)"
},
{
"examine": "Some ceremonial boots dedicated to an ancient god.",
"id": 26229,
"members": true,
"lowalch": 16000,
"limit": 8,
"value": 40000,
"highalch": 24000,
"icon": "Ancient ceremonial boots.png",
"name": "Ancient ceremonial boots"
},
{
"examine": "Some ceremonial gloves dedicated to an ancient god.",
"id": 26227,
"members": true,
"lowalch": 16000,
"limit": 8,
"value": 40000,
"highalch": 24000,
"icon": "Ancient ceremonial gloves.png",
"name": "Ancient ceremonial gloves"
},
{
"examine": "Some ceremonial robes dedicated to an ancient god.",
"id": 26223,
"members": true,
"lowalch": 32000,
"limit": 8,
"value": 80000,
"highalch": 48000,
"icon": "Ancient ceremonial legs.png",
"name": "Ancient ceremonial legs"
},
{
"examine": "A ceremonial mask dedicated to an ancient god.",
"id": 26225,
"members": true,
"lowalch": 20000,
"limit": 8,
"value": 50000,
"highalch": 30000,
"icon": "Ancient ceremonial mask.png",
"name": "Ancient ceremonial mask"
},
{
"examine": "Some ceremonial robes dedicated to an ancient god.",
"id": 26221,
"members": true,
"lowalch": 40000,
"limit": 8,
"value": 100000,
"highalch": 60000,
"icon": "Ancient ceremonial top.png",
"name": "Ancient ceremonial top"
},
{
"examine": "Ancient blessed dragonhide chaps.",
"id": 12494,
"members": true,
"lowalch": 2400,
"limit": 8,
"value": 6000,
"highalch": 3600,
"icon": "Ancient chaps.png",
"name": "Ancient chaps"
},
{
"examine": "An Ancient cloak.",
"id": 12197,
"members": true,
"lowalch": 800,
"limit": 8,
"value": 2000,
"highalch": 1200,
"icon": "Ancient cloak.png",
"name": "Ancient cloak"
},
{
"examine": "Ancient blessed dragonhide coif.",
"id": 12496,
"members": true,
"lowalch": 800,
"limit": 8,
"value": 2000,
"highalch": 1200,
"icon": "Ancient coif.png",
"name": "Ancient coif"
},
{
"examine": "An Ancient crozier.",
"id": 12199,
"members": true,
"lowalch": 2000,
"limit": 8,
"value": 5000,
"highalch": 3000,
"icon": "Ancient crozier.png",
"name": "Ancient crozier"
},
{
"examine": "An ancient crystal containing magical energy.",
"id": 21804,
"members": true,
"lowalch": 100000,
"limit": 250,
"value": 250000,
"highalch": 150000,
"icon": "Ancient crystal.png",
"name": "Ancient crystal"
},
{
"examine": "Ancient blessed dragonhide body armour.",
"id": 12492,
"members": true,
"lowalch": 5200,
"limit": 8,
"value": 13000,
"highalch": 7800,
"icon": "Ancient d'hide body.png",
"name": "Ancient d'hide body"
},
{
"examine": "Ancient blessed dragonhide boots.",
"id": 19921,
"members": true,
"lowalch": 3720,
"limit": 8,
"value": 9300,
"highalch": 5580,
"icon": "Ancient d'hide boots.png",
"name": "Ancient d'hide boots"
},
{
"examine": "Ancient blessed wooden shield covered in dragon leather.",
"id": 23197,
"members": true,
"lowalch": 11333,
"limit": 8,
"value": 28334,
"highalch": 17000,
"icon": "Ancient d'hide shield.png",
"name": "Ancient d'hide shield"
},
{
"examine": "A set containing an ancient dragonhide coif, body, chaps and bracers.",
"id": 13171,
"members": true,
"lowalch": 3800,
"limit": 8,
"value": 9500,
"highalch": 5700,
"icon": "Ancient dragonhide set.png",
"name": "Ancient dragonhide set"
},
{
"examine": "A mysterious artifact of ancient times, very precious to emblem traders.",
"id": 22302,
"members": true,
"lowalch": 3200000,
"limit": 250,
"value": 8000000,
"highalch": 4800000,
"icon": "Ancient effigy.png",
"name": "Ancient effigy"
},
{
"examine": "A mysterious artifact of ancient times, of some value to emblem traders.",
"id": 21807,
"members": true,
"lowalch": 200000,
"limit": 250,
"value": 500000,
"highalch": 300000,
"icon": "Ancient emblem.png",
"name": "Ancient emblem"
},
{
"examine": "Pure magical energy filled with a dark power.",
"id": 27616,
"members": true,
"lowalch": 1,
"limit": 300000,
"value": 1,
"highalch": 1,
"icon": "Ancient essence 500.png",
"name": "Ancient essence"
},
{
"examine": "Rune full helmet in the colours of a long-forgotten god.",
"id": 12466,
"members": false,
"lowalch": 14080,
"limit": 8,
"value": 35200,
"highalch": 21120,
"icon": "Ancient full helm.png",
"name": "Ancient full helm"
},
{
"examine": "A mysterious, heavy sword.",
"id": 26233,
"members": true,
"lowalch": 500000,
"limit": 8,
"value": 1250000,
"highalch": 750000,
"icon": "Ancient godsword.png",
"name": "Ancient godsword"
},
{
"highalch": 300000,
"members": true,
"name": "Ancient hilt",
"examine": "Brimming with potential.",
"id": 26370,
"value": 500000,
"icon": "Ancient hilt.png",
"lowalch": 200000
},
{
"examine": "Rune kiteshield in the colours of a long-forgotten god.",
"id": 12468,
"members": false,
"lowalch": 21760,
"limit": 8,
"value": 54400,
"highalch": 32640,
"icon": "Ancient kiteshield.png",
"name": "Ancient kiteshield"
},
{
"examine": "It has ancient goblin symbols on it.",
"id": 11061,
"members": true,
"lowalch": 400,
"limit": 8,
"value": 1000,
"highalch": 600,
"icon": "Ancient mace.png",
"name": "Ancient mace"
},
{
"examine": "A mysterious artifact of ancient times, precious to emblem traders.",
"id": 22299,
"members": true,
"lowalch": 1600000,
"limit": 250,
"value": 4000000,
"highalch": 2400000,
"icon": "Ancient medallion.png",
"name": "Ancient medallion"
},
{
"examine": "An Ancient mitre.",
"id": 12203,
"members": true,
"lowalch": 2000,
"limit": 8,
"value": 5000,
"highalch": 3000,
"icon": "Ancient mitre.png",
"name": "Ancient mitre"
},
{
"highalch": 75,
"members": true,
"name": "Ancient mix(1)",
"examine": "One dose of fishy ancient brew.",
"id": 26353,
"value": 125,
"icon": "Ancient mix(1).png",
"lowalch": 50
},
{
"highalch": 90,
"members": true,
"name": "Ancient mix(2)",
"examine": "Two doses of fishy ancient brew.",
"id": 26350,
"value": 150,
"icon": "Ancient mix(2).png",
"lowalch": 60
},
{
"examine": "This seems to have been torn from a book...",
"id": 12621,
"members": true,
"lowalch": 160,
"limit": 5,
"value": 400,
"highalch": 240,
"icon": "Ancient page 1.png",
"name": "Ancient page 1"
},
{
"examine": "This seems to have been torn from a book...",
"id": 12622,
"members": true,
"lowalch": 160,
"limit": 5,
"value": 400,
"highalch": 240,
"icon": "Ancient page 2.png",
"name": "Ancient page 2"
},
{
"examine": "This seems to have been torn from a book...",
"id": 12623,
"members": true,
"lowalch": 160,
"limit": 5,
"value": 400,
"highalch": 240,
"icon": "Ancient page 3.png",
"name": "Ancient page 3"
},
{
"examine": "This seems to have been torn from a book...",
"id": 12624,
"members": true,
"lowalch": 160,
"limit": 5,
"value": 400,
"highalch": 240,
"icon": "Ancient page 4.png",
"name": "Ancient page 4"
},
{
"examine": "Rune platebody in the colours of a long-forgotten god.",
"id": 12460,
"members": false,
"lowalch": 26000,
"limit": 8,
"value": 65000,
"highalch": 39000,
"icon": "Ancient platebody.png",
"name": "Ancient platebody"
},
{
"examine": "Rune platelegs in the colours of a long-forgotten god.",
"id": 12462,
"members": false,
"lowalch": 25600,
"limit": 8,
"value": 64000,
"highalch": 38400,
"icon": "Ancient platelegs.png",
"name": "Ancient platelegs"
},
{
"examine": "Rune plateskirt in the colours of a long-forgotten god.",
"id": 12464,
"members": false,
"lowalch": 25600,
"limit": 8,
"value": 64000,
"highalch": 38400,
"icon": "Ancient plateskirt.png",
"name": "Ancient plateskirt"
},
{
"examine": "A mysterious artifact of ancient times, emblem traders will pay you extremely handsomely for this.",
"id": 22305,
"members": true,
"lowalch": 6400000,
"limit": 250,
"value": 16000000,
"highalch": 9600000,
"icon": "Ancient relic.png",
"name": "Ancient relic"
},
{
"examine": "Leggings from the Ancient Vestments.",
"id": 12195,
"members": true,
"lowalch": 2800,
"limit": 8,
"value": 7000,
"highalch": 4200,
"icon": "Ancient robe legs.png",
"name": "Ancient robe legs"
},
{
"examine": "Ancient Vestments.",
"id": 12193,
"members": true,
"lowalch": 2800,
"limit": 8,
"value": 7000,
"highalch": 4200,
"icon": "Ancient robe top.png",
"name": "Ancient robe top"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 13060,
"members": false,
"lowalch": 32000,
"limit": 8,
"value": 80000,
"highalch": 48000,
"icon": "Ancient rune armour set (lg).png",
"name": "Ancient rune armour set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 13062,
"members": false,
"lowalch": 32000,
"limit": 8,
"value": 80000,
"highalch": 48000,
"icon": "Ancient rune armour set (sk).png",
"name": "Ancient rune armour set (sk)"
},
{
"examine": "A magical staff of ancient origin...",
"id": 4675,
"members": true,
"lowalch": 40000,
"limit": 70,
"value": 100000,
"highalch": 60000,
"icon": "Ancient staff.png",
"name": "Ancient staff"
},
{
"examine": "A mysterious artifact of ancient times, of substantial value to emblem traders.",
"id": 21813,
"members": true,
"lowalch": 800000,
"limit": 250,
"value": 2000000,
"highalch": 1200000,
"icon": "Ancient statuette.png",
"name": "Ancient statuette"
},
{
"examine": "An Ancient stole.",
"id": 12201,
"members": true,
"lowalch": 1000,
"limit": 8,
"value": 2500,
"highalch": 1500,
"icon": "Ancient stole.png",
"name": "Ancient stole"
},
{
"examine": "A mysterious artifact of ancient times, of reasonable value to emblem traders.",
"id": 21810,
"members": true,
"lowalch": 400000,
"limit": 250,
"value": 1000000,
"highalch": 600000,
"icon": "Ancient totem.png",
"name": "Ancient totem"
},
{
"examine": "A magical shield with a wyvern visage.",
"id": 21634,
"members": true,
"lowalch": 800000,
"limit": 8,
"value": 2000000,
"highalch": 1200000,
"icon": "Ancient wyvern shield (uncharged).png",
"name": "Ancient wyvern shield"
},
{
"examine": "I hope this tastes better than it looks.",
"id": 13441,
"members": true,
"lowalch": 180,
"limit": 10000,
"value": 450,
"highalch": 270,
"icon": "Anglerfish.png",
"name": "Anglerfish"
},
{
"examine": "Use on a necklace of anguish to make it look fancier!",
"id": 22246,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Anguish ornament kit.png",
"name": "Anguish ornament kit"
},
{
"examine": "These gloves will make your flesh transparent.",
"id": 20101,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Ankou gloves.png",
"name": "Ankou gloves"
},
{
"examine": "This mask will make your flesh transparent.",
"id": 20095,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Ankou mask.png",
"name": "Ankou mask"
},
{
"examine": "These socks will make your flesh transparent.",
"id": 20107,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Ankou socks.png",
"name": "Ankou socks"
},
{
"examine": "This top will make your flesh transparent.",
"id": 20098,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Ankou top.png",
"name": "Ankou top"
},
{
"examine": "These leggings will make your flesh transparent.",
"id": 20104,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Ankou's leggings.png",
"name": "Ankou's leggings"
},
{
"examine": "A teleport to Annakarl, in level 46 Wilderness.",
"id": 12775,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Annakarl teleport (tablet).png",
"name": "Annakarl teleport (tablet)"
},
{
"examine": "This provides partial protection from dragonbreath attacks.",
"id": 1540,
"members": false,
"lowalch": 8,
"limit": 125,
"value": 20,
"highalch": 12,
"icon": "Anti-dragon shield.png",
"name": "Anti-dragon shield"
},
{
"examine": "One dose of fishy super antipoison potion.",
"id": 11475,
"members": true,
"lowalch": 57,
"limit": 2000,
"value": 144,
"highalch": 86,
"icon": "Anti-poison supermix(1).png",
"name": "Anti-poison supermix(1)"
},
{
"examine": "Two doses of fishy super antipoison potion.",
"id": 11473,
"members": true,
"lowalch": 86,
"limit": 2000,
"value": 216,
"highalch": 129,
"icon": "Anti-poison supermix(2).png",
"name": "Anti-poison supermix(2)"
},
{
"examine": "1 dose of antivenom potion.",
"id": 12911,
"members": true,
"lowalch": 44,
"limit": 2000,
"value": 110,
"highalch": 66,
"icon": "Anti-venom(1).png",
"name": "Anti-venom(1)"
},
{
"examine": "2 doses of antivenom potion.",
"id": 12909,
"members": true,
"lowalch": 88,
"limit": 2000,
"value": 220,
"highalch": 132,
"icon": "Anti-venom(2).png",
"name": "Anti-venom(2)"
},
{
"examine": "3 doses of antivenom potion.",
"id": 12907,
"members": true,
"lowalch": 132,
"limit": 2000,
"value": 330,
"highalch": 198,
"icon": "Anti-venom(3).png",
"name": "Anti-venom(3)"
},
{
"examine": "4 doses of antivenom potion.",
"id": 12905,
"members": true,
"lowalch": 176,
"limit": 2000,
"value": 440,
"highalch": 264,
"icon": "Anti-venom(4).png",
"name": "Anti-venom(4)"
},
{
"examine": "1 dose of super antivenom potion.",
"id": 12919,
"members": true,
"lowalch": 44,
"limit": 2000,
"value": 111,
"highalch": 66,
"icon": "Anti-venom+(1).png",
"name": "Anti-venom+(1)"
},
{
"examine": "2 doses of super antivenom potion.",
"id": 12917,
"members": true,
"lowalch": 88,
"limit": 2000,
"value": 222,
"highalch": 133,
"icon": "Anti-venom+(2).png",
"name": "Anti-venom+(2)"
},
{
"examine": "3 doses of super antivenom potion.",
"id": 12915,
"members": true,
"lowalch": 133,
"limit": 2000,
"value": 333,
"highalch": 199,
"icon": "Anti-venom+(3).png",
"name": "Anti-venom+(3)"
},
{
"examine": "4 doses of super antivenom potion.",
"id": 12913,
"members": true,
"lowalch": 177,
"limit": 2000,
"value": 444,
"highalch": 266,
"icon": "Anti-venom+(4).png",
"name": "Anti-venom+(4)"
},
{
"examine": "One dose of fishy extra strength antidote potion.",
"id": 11503,
"members": true,
"lowalch": 57,
"limit": 2000,
"value": 144,
"highalch": 86,
"icon": "Antidote+ mix(1).png",
"name": "Antidote+ mix(1)"
},
{
"examine": "Two doses of fishy extra strength antidote potion.",
"id": 11501,
"members": true,
"lowalch": 86,
"limit": 2000,
"value": 216,
"highalch": 129,
"icon": "Antidote+ mix(2).png",
"name": "Antidote+ mix(2)"
},
{
"examine": "1 dose of extra strong antipoison potion.",
"id": 5949,
"members": true,
"lowalch": 57,
"limit": 4000,
"value": 144,
"highalch": 86,
"icon": "Antidote+(1).png",
"name": "Antidote+(1)"
},
{
"examine": "2 doses of extra strong antipoison potion.",
"id": 5947,
"members": true,
"lowalch": 86,
"limit": 4000,
"value": 216,
"highalch": 129,
"icon": "Antidote+(2).png",
"name": "Antidote+(2)"
},
{
"examine": "3 doses of extra strong antipoison potion.",
"id": 5945,
"members": true,
"lowalch": 115,
"limit": 4000,
"value": 288,
"highalch": 172,
"icon": "Antidote+(3).png",
"name": "Antidote+(3)"
},
{
"examine": "4 doses of extra strong antipoison potion.",
"id": 5943,
"members": true,
"lowalch": 144,
"limit": 4000,
"value": 360,
"highalch": 216,
"icon": "Antidote+(4).png",
"name": "Antidote+(4)"
},
{
"examine": "1 dose of super strong antipoison potion.",
"id": 5958,
"members": true,
"lowalch": 57,
"limit": 4000,
"value": 144,
"highalch": 86,
"icon": "Antidote++(1).png",
"name": "Antidote++(1)"
},
{
"examine": "2 doses of super strong antipoison potion.",
"id": 5956,
"members": true,
"lowalch": 86,
"limit": 4000,
"value": 216,
"highalch": 129,
"icon": "Antidote++(2).png",
"name": "Antidote++(2)"
},
{
"examine": "3 doses of super strong antipoison potion.",
"id": 5954,
"members": true,
"lowalch": 115,
"limit": 4000,
"value": 288,
"highalch": 172,
"icon": "Antidote++(3).png",
"name": "Antidote++(3)"
},
{
"examine": "4 doses of super strong antipoison potion.",
"id": 5952,
"members": true,
"lowalch": 144,
"limit": 4000,
"value": 360,
"highalch": 216,
"icon": "Antidote++(4).png",
"name": "Antidote++(4)"
},
{
"examine": "One dose of fishy anti-firebreath potion.",
"id": 11507,
"members": true,
"lowalch": 52,
"limit": 2000,
"value": 132,
"highalch": 79,
"icon": "Antifire mix(1).png",
"name": "Antifire mix(1)"
},
{
"examine": "Two doses of fishy anti-firebreath potion.",
"id": 11505,
"members": true,
"lowalch": 79,
"limit": 2000,
"value": 198,
"highalch": 118,
"icon": "Antifire mix(2).png",
"name": "Antifire mix(2)"
},
{
"examine": "1 dose of anti-firebreath potion.",
"id": 2458,
"members": true,
"lowalch": 52,
"limit": 2000,
"value": 132,
"highalch": 79,
"icon": "Antifire potion(1).png",
"name": "Antifire potion(1)"
},
{
"examine": "2 doses of anti-firebreath potion.",
"id": 2456,
"members": true,
"lowalch": 79,
"limit": 2000,
"value": 198,
"highalch": 118,
"icon": "Antifire potion(2).png",
"name": "Antifire potion(2)"
},
{
"examine": "3 doses of anti-firebreath potion.",
"id": 2454,
"members": true,
"lowalch": 105,
"limit": 2000,
"value": 264,
"highalch": 158,
"icon": "Antifire potion(3).png",
"name": "Antifire potion(3)"
},
{
"examine": "4 doses of anti-firebreath potion.",
"id": 2452,
"members": true,
"lowalch": 132,
"limit": 2000,
"value": 330,
"highalch": 198,
"icon": "Antifire potion(4).png",
"name": "Antifire potion(4)"
},
{
"examine": "One dose of fishy antipoison potion.",
"id": 11435,
"members": true,
"lowalch": 57,
"limit": 2000,
"value": 144,
"highalch": 86,
"icon": "Antipoison mix(1).png",
"name": "Antipoison mix(1)"
},
{
"examine": "Two doses of fishy antipoison potion.",
"id": 11433,
"members": true,
"lowalch": 86,
"limit": 2000,
"value": 216,
"highalch": 129,
"icon": "Antipoison mix(2).png",
"name": "Antipoison mix(2)"
},
{
"examine": "1 dose of antipoison potion.",
"id": 179,
"members": false,
"lowalch": 57,
"limit": 2000,
"value": 144,
"highalch": 86,
"icon": "Antipoison(1).png",
"name": "Antipoison(1)"
},
{
"examine": "2 doses of antipoison potion.",
"id": 177,
"members": false,
"lowalch": 86,
"limit": 2000,
"value": 216,
"highalch": 129,
"icon": "Antipoison(2).png",
"name": "Antipoison(2)"
},
{
"examine": "3 doses of antipoison potion.",
"id": 175,
"members": false,
"lowalch": 115,
"limit": 2000,
"value": 288,
"highalch": 172,
"icon": "Antipoison(3).png",
"name": "Antipoison(3)"
},
{
"examine": "4 doses of antipoison potion.",
"id": 2446,
"members": false,
"lowalch": 144,
"limit": 2000,
"value": 360,
"highalch": 216,
"icon": "Antipoison(4).png",
"name": "Antipoison(4)"
},
{
"examine": "A teleport to Ape Atoll.",
"id": 19631,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Ape atoll teleport (tablet).png",
"name": "Ape atoll teleport (tablet)"
},
{
"examine": "A bucket of apple mush.",
"id": 5992,
"members": true,
"lowalch": 5,
"limit": 11000,
"value": 13,
"highalch": 7,
"icon": "Apple mush.png",
"name": "Apple mush"
},
{
"examine": "Mmm Apple pie.",
"id": 2323,
"members": false,
"lowalch": 12,
"limit": 10000,
"value": 30,
"highalch": 18,
"icon": "Apple pie.png",
"name": "Apple pie"
},
{
"examine": "This sapling is ready to be replanted in a fruit tree patch.",
"id": 5496,
"members": true,
"lowalch": 1,
"limit": 200,
"value": 1,
"highalch": 1,
"icon": "Apple sapling.png",
"name": "Apple sapling"
},
{
"examine": "Plant in a plantpot of soil to grow a sapling.",
"id": 5283,
"members": true,
"lowalch": 5,
"limit": 200,
"value": 13,
"highalch": 7,
"icon": "Apple tree seed 5.png",
"name": "Apple tree seed"
},
{
"examine": "A fruit basket filled with apples.",
"id": 5386,
"members": true,
"lowalch": 1,
"limit": 600,
"value": 1,
"highalch": 1,
"icon": "Apples(5).png",
"name": "Apples(5)"
},
{
"examine": "An apprentice level wand.",
"id": 6910,
"members": true,
"lowalch": 600,
"limit": 125,
"value": 1500,
"highalch": 900,
"icon": "Apprentice wand.png",
"name": "Apprentice wand"
},
{
"examine": "Scrawled words that can invoke the power of the gods.",
"id": 21079,
"members": true,
"lowalch": 32000,
"limit": 5,
"value": 80000,
"highalch": 48000,
"icon": "Arcane prayer scroll.png",
"name": "Arcane prayer scroll"
},
{
"examine": "A sigil in the shape of an arcane symbol.",
"id": 12827,
"members": true,
"lowalch": 300000,
"limit": 5,
"value": 750000,
"highalch": 450000,
"icon": "Arcane sigil.png",
"name": "Arcane sigil"
},
{
"examine": "An ethereal shield with an arcane sigil attached to it.",
"id": 12825,
"members": true,
"lowalch": 800000,
"limit": 8,
"value": 2000000,
"highalch": 1200000,
"icon": "Arcane spirit shield.png",
"name": "Arcane spirit shield"
},
{
"examine": "An ancient banner baring the mark of the Arceuus Elders.",
"id": 20251,
"members": true,
"lowalch": 28,
"limit": 4,
"value": 70,
"highalch": 42,
"icon": "Arceuus banner.png",
"name": "Arceuus banner"
},
{
"examine": "A rare hood from Arceuus.",
"id": 20113,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Arceuus hood.png",
"name": "Arceuus hood"
},
{
"examine": "A teleport to the Arceuus Library",
"id": 19613,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Arceuus library teleport (tablet).png",
"name": "Arceuus library teleport (tablet)"
},
{
"examine": "A blue scarf adorned with a dark crystal.",
"id": 19943,
"members": true,
"lowalch": 4000,
"limit": 4,
"value": 10000,
"highalch": 6000,
"icon": "Arceuus scarf.png",
"name": "Arceuus scarf"
},
{
"examine": "This helmet is worn by archers.",
"id": 3749,
"members": true,
"lowalch": 24000,
"limit": 70,
"value": 60000,
"highalch": 36000,
"icon": "Archer helm.png",
"name": "Archer helm"
},
{
"highalch": 1,
"members": true,
"name": "Archer icon",
"examine": "The icon of an archer.",
"id": 28298,
"value": 1,
"icon": "Archer icon.png",
"lowalch": 1
},
{
"examine": "A fabled ring that improves the wearer's skill with a bow...",
"id": 6733,
"members": true,
"lowalch": 4000,
"limit": 8,
"value": 10000,
"highalch": 6000,
"icon": "Archers ring.png",
"name": "Archers ring"
},
{
"examine": "I can exchange this for equipment.",
"id": 1464,
"members": true,
"lowalch": 10,
"limit": 18000,
"value": 25,
"highalch": 15,
"icon": "Archery ticket.png",
"name": "Archery ticket"
},
{
"examine": "Log cut from an arctic pine.",
"id": 10810,
"members": true,
"lowalch": 14,
"limit": 11000,
"value": 35,
"highalch": 21,
"icon": "Arctic pine logs.png",
"name": "Arctic pine logs"
},
{
"examine": "Arctic pine logs prepared with sacred oil for a funeral pyre.",
"id": 10808,
"members": true,
"lowalch": 80,
"limit": 11000,
"value": 200,
"highalch": 120,
"icon": "Arctic pyre logs.png",
"name": "Arctic pyre logs"
},
{
"examine": "A teleport to East Ardougne.",
"id": 8011,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Ardougne teleport (tablet).png",
"name": "Ardougne teleport (tablet)"
},
{
"examine": "A book about the Training Arena.",
"id": 6891,
"members": true,
"lowalch": 1,
"limit": 15,
"value": 1,
"highalch": 1,
"icon": "Arena book.png",
"name": "Arena book"
},
{
"examine": "Armadyl blessed dragonhide vambraces.",
"id": 12506,
"members": true,
"lowalch": 1600,
"limit": 8,
"value": 4000,
"highalch": 2400,
"icon": "Armadyl bracers.png",
"name": "Armadyl bracers"
},
{
"examine": "A chainskirt of great craftsmanship.",
"id": 11830,
"members": true,
"lowalch": 115960,
"limit": 8,
"value": 289900,
"highalch": 173940,
"icon": "Armadyl chainskirt.png",
"name": "Armadyl chainskirt"
},
{
"examine": "Armadyl blessed dragonhide chaps.",
"id": 12510,
"members": true,
"lowalch": 2400,
"limit": 8,
"value": 6000,
"highalch": 3600,
"icon": "Armadyl chaps.png",
"name": "Armadyl chaps"
},
{
"examine": "Armour of great craftsmanship.",
"id": 11828,
"members": true,
"lowalch": 116000,
"limit": 8,
"value": 290000,
"highalch": 174000,
"icon": "Armadyl chestplate.png",
"name": "Armadyl chestplate"
},
{
"examine": "An Armadyl cloak.",
"id": 12261,
"members": true,
"lowalch": 800,
"limit": 8,
"value": 2000,
"highalch": 1200,
"icon": "Armadyl cloak.png",
"name": "Armadyl cloak"
},
{
"examine": "Armadyl blessed dragonhide coif.",
"id": 12512,
"members": true,
"lowalch": 800,
"limit": 8,
"value": 2000,
"highalch": 1200,
"icon": "Armadyl coif.png",
"name": "Armadyl coif"
},
{
"examine": "A weapon originally developed for Armadyl's forces.",
"id": 11785,
"members": true,
"lowalch": 396000,
"limit": 8,
"value": 990000,
"highalch": 594000,
"icon": "Armadyl crossbow.png",
"name": "Armadyl crossbow"
},
{
"examine": "An Armadyl crozier.",
"id": 12263,
"members": true,
"lowalch": 2000,
"limit": 8,
"value": 5000,
"highalch": 3000,
"icon": "Armadyl crozier.png",
"name": "Armadyl crozier"
},
{
"examine": "Armadyl blessed dragonhide body armour.",
"id": 12508,
"members": true,
"lowalch": 5200,
"limit": 8,
"value": 13000,
"highalch": 7800,
"icon": "Armadyl d'hide body.png",
"name": "Armadyl d'hide body"
},
{
"examine": "Armadyl blessed dragonhide boots.",
"id": 19930,
"members": true,
"lowalch": 3720,
"limit": 8,
"value": 9300,
"highalch": 5580,
"icon": "Armadyl d'hide boots.png",
"name": "Armadyl d'hide boots"
},
{
"examine": "Armadyl blessed wooden shield covered in dragon leather.",
"id": 23200,
"members": true,
"lowalch": 11333,
"limit": 8,
"value": 28334,
"highalch": 17000,
"icon": "Armadyl d'hide shield.png",
"name": "Armadyl d'hide shield"
},
{
"examine": "A set containing an Armadyl dragonhide coif, body, chaps and bracers.",
"id": 13169,
"members": true,
"lowalch": 3800,
"limit": 8,
"value": 9500,
"highalch": 5700,
"icon": "Armadyl dragonhide set.png",
"name": "Armadyl dragonhide set"
},
{
"examine": "Rune full helmet in the colours of Armadyl.",
"id": 12476,
"members": false,
"lowalch": 14080,
"limit": 8,
"value": 35200,
"highalch": 21120,
"icon": "Armadyl full helm.png",
"name": "Armadyl full helm"
},
{
"examine": "A beautiful, heavy sword.",
"id": 11802,
"members": true,
"lowalch": 500000,
"limit": 8,
"value": 1250000,
"highalch": 750000,
"icon": "Armadyl godsword.png",
"name": "Armadyl godsword"
},
{
"examine": "Use on an Armadyl godsword to make it look fancier!",
"id": 20068,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Armadyl godsword ornament kit.png",
"name": "Armadyl godsword ornament kit"
},
{
"examine": "A helmet of great craftsmanship.",
"id": 11826,
"members": true,
"lowalch": 115600,
"limit": 8,
"value": 289000,
"highalch": 173400,
"icon": "Armadyl helmet.png",
"name": "Armadyl helmet"
},
{
"examine": "Brimming with potential.",
"id": 11810,
"members": true,
"lowalch": 200000,
"limit": 10000,
"value": 500000,
"highalch": 300000,
"icon": "Armadyl hilt.png",
"name": "Armadyl hilt"
},
{
"examine": "Rune kiteshield in the colours of Armadyl.",
"id": 12478,
"members": false,
"lowalch": 21760,
"limit": 8,
"value": 54400,
"highalch": 32640,
"icon": "Armadyl kiteshield.png",
"name": "Armadyl kiteshield"
},
{
"examine": "An Armadyl mitre.",
"id": 12259,
"members": true,
"lowalch": 2000,
"limit": 8,
"value": 5000,
"highalch": 3000,
"icon": "Armadyl mitre.png",
"name": "Armadyl mitre"
},
{
"examine": "This seems to have been torn from a book...",
"id": 12617,
"members": true,
"lowalch": 160,
"limit": 5,
"value": 400,
"highalch": 240,
"icon": "Armadyl page 1.png",
"name": "Armadyl page 1"
},
{
"examine": "This seems to have been torn from a book...",
"id": 12618,
"members": true,
"lowalch": 160,
"limit": 5,
"value": 400,
"highalch": 240,
"icon": "Armadyl page 2.png",
"name": "Armadyl page 2"
},
{
"examine": "This seems to have been torn from a book...",
"id": 12619,
"members": true,
"lowalch": 160,
"limit": 5,
"value": 400,
"highalch": 240,
"icon": "Armadyl page 3.png",
"name": "Armadyl page 3"
},
{
"examine": "This seems to have been torn from a book...",
"id": 12620,
"members": true,
"lowalch": 160,
"limit": 5,
"value": 400,
"highalch": 240,
"icon": "Armadyl page 4.png",
"name": "Armadyl page 4"
},
{
"examine": "Rune platebody in the colours of Armadyl.",
"id": 12470,
"members": false,
"lowalch": 26000,
"limit": 8,
"value": 65000,
"highalch": 39000,
"icon": "Armadyl platebody.png",
"name": "Armadyl platebody"
},
{
"examine": "Rune platelegs in the colours of Armadyl.",
"id": 12472,
"members": false,
"lowalch": 25600,
"limit": 8,
"value": 64000,
"highalch": 38400,
"icon": "Armadyl platelegs.png",
"name": "Armadyl platelegs"
},
{
"examine": "Rune plateskirt in the colours of Armadyl.",
"id": 12474,
"members": false,
"lowalch": 25600,
"limit": 8,
"value": 64000,
"highalch": 38400,
"icon": "Armadyl plateskirt.png",
"name": "Armadyl plateskirt"
},
{
"examine": "Leggings from the Armadyl Vestments.",
"id": 12255,
"members": true,
"lowalch": 2800,
"limit": 8,
"value": 7000,
"highalch": 4200,
"icon": "Armadyl robe legs.png",
"name": "Armadyl robe legs"
},
{
"examine": "Armadyl Vestments.",
"id": 12253,
"members": true,
"lowalch": 2800,
"limit": 8,
"value": 7000,
"highalch": 4200,
"icon": "Armadyl robe top.png",
"name": "Armadyl robe top"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 13052,
"members": false,
"lowalch": 32000,
"limit": 8,
"value": 80000,
"highalch": 48000,
"icon": "Armadyl rune armour set (lg).png",
"name": "Armadyl rune armour set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 13054,
"members": false,
"lowalch": 32000,
"limit": 8,
"value": 80000,
"highalch": 48000,
"icon": "Armadyl rune armour set (sk).png",
"name": "Armadyl rune armour set (sk)"
},
{
"examine": "An Armadyl stole.",
"id": 12257,
"members": true,
"lowalch": 1000,
"limit": 8,
"value": 2500,
"highalch": 1500,
"icon": "Armadyl stole.png",
"name": "Armadyl stole"
},
{
"highalch": 1,
"members": true,
"name": "Armadylean plate",
"examine": "High grade material.",
"id": 27269,
"value": 1,
"icon": "Armadylean plate.png",
"lowalch": 1
},
{
"examine": "A wooden arrow shaft.",
"id": 52,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 1,
"highalch": 1,
"icon": "Arrow shaft 5.png",
"name": "Arrow shaft"
},
{
"examine": "Probably the finest ale in Asgarnia.",
"id": 1905,
"members": false,
"lowalch": 1,
"limit": 2000,
"value": 2,
"highalch": 1,
"icon": "Asgarnian ale.png",
"name": "Asgarnian ale"
},
{
"examine": "How does it all fit in there?",
"id": 8520,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Asgarnian ale (flatpack).png",
"name": "Asgarnian ale (flatpack)"
},
{
"examine": "This keg contains 4 pints of Asgarnian Ale.",
"id": 5785,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Asgarnian ale(4).png",
"name": "Asgarnian ale(4)"
},
{
"examine": "This looks a good deal stronger than normal Asgarnian Ale.",
"id": 5739,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 2,
"highalch": 1,
"icon": "Asgarnian ale(m).png",
"name": "Asgarnian ale(m)"
},
{
"examine": "This keg contains 4 pints of mature Asgarnian Ale.",
"id": 5865,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Asgarnian ale(m4).png",
"name": "Asgarnian ale(m4)"
},
{
"examine": "A handful of Asgarnian Hops.",
"id": 5996,
"members": true,
"lowalch": 2,
"limit": 11000,
"value": 5,
"highalch": 3,
"icon": "Asgarnian hops.png",
"name": "Asgarnian hops"
},
{
"examine": "An Asgarnian hop seed - plant in a hops patch.",
"id": 5308,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 3,
"highalch": 1,
"icon": "Asgarnian seed 5.png",
"name": "Asgarnian seed"
},
{
"examine": "A heap of ashes.",
"id": 592,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Ashes.png",
"name": "Ashes"
},
{
"examine": "A posy of flowers.",
"id": 2460,
"members": true,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Assorted flowers.png",
"name": "Assorted flowers"
},
{
"examine": "Used for Lunar spells.",
"id": 9075,
"members": true,
"lowalch": 20,
"limit": 15000,
"value": 50,
"highalch": 30,
"icon": "Astral rune.png",
"name": "Astral rune"
},
{
"examine": "The remains of a deadly shade.",
"id": 3402,
"members": true,
"lowalch": 1,
"limit": 7500,
"value": 1,
"highalch": 1,
"icon": "Asyn remains.png",
"name": "Asyn remains"
},
{
"examine": "Quite big for a dart.",
"id": 28991,
"members": true,
"lowalch": 196,
"limit": 11000,
"value": 490,
"highalch": 294,
"icon": "Atlatl dart.png",
"name": "Atlatl dart"
},
{
"examine": "One dose of fishy Attack potion.",
"id": 11431,
"members": true,
"lowalch": 2,
"limit": 2000,
"value": 6,
"highalch": 3,
"icon": "Attack mix(1).png",
"name": "Attack mix(1)"
},
{
"examine": "Two doses of fishy Attack potion.",
"id": 11429,
"members": true,
"lowalch": 3,
"limit": 2000,
"value": 9,
"highalch": 5,
"icon": "Attack mix(2).png",
"name": "Attack mix(2)"
},
{
"examine": "1 dose of Attack potion.",
"id": 125,
"members": true,
"lowalch": 2,
"limit": 2000,
"value": 6,
"highalch": 3,
"icon": "Attack potion(1).png",
"name": "Attack potion(1)"
},
{
"examine": "2 doses of Attack potion.",
"id": 123,
"members": true,
"lowalch": 3,
"limit": 2000,
"value": 9,
"highalch": 5,
"icon": "Attack potion(2).png",
"name": "Attack potion(2)"
},
{
"examine": "3 doses of Attack potion.",
"id": 121,
"members": true,
"lowalch": 4,
"limit": 2000,
"value": 12,
"highalch": 7,
"icon": "Attack potion(3).png",
"name": "Attack potion(3)"
},
{
"examine": "4 doses of Attack potion.",
"id": 2428,
"members": true,
"lowalch": 6,
"limit": 2000,
"value": 15,
"highalch": 9,
"icon": "Attack potion(4).png",
"name": "Attack potion(4)"
},
{
"examine": "A useful herb.",
"id": 261,
"members": true,
"lowalch": 19,
"limit": 13000,
"value": 48,
"highalch": 28,
"icon": "Avantoe.png",
"name": "Avantoe"
},
{
"examine": "I need another ingredient to finish this Avantoe potion.",
"id": 103,
"members": true,
"lowalch": 19,
"limit": 10000,
"value": 48,
"highalch": 28,
"icon": "Avantoe potion (unf).png",
"name": "Avantoe potion (unf)"
},
{
"examine": "An avantoe seed - plant in a herb patch.",
"id": 5298,
"members": true,
"lowalch": 3,
"limit": 200,
"value": 8,
"highalch": 4,
"icon": "Avantoe seed 5.png",
"name": "Avantoe seed"
},
{
"examine": "Perhaps this could be used to create a defensive weapon.",
"id": 22477,
"members": true,
"lowalch": 1000000,
"limit": 8,
"value": 2500000,
"highalch": 1500000,
"icon": "Avernic defender hilt.png",
"name": "Avernic defender hilt"
},
{
"examine": "A mysterious orb of unknown power. What could it do?",
"id": 28334,
"members": true,
"lowalch": 1,
"limit": 50,
"value": 1,
"highalch": 1,
"icon": "Awakener's orb.png",
"name": "Awakener's orb"
},
{
"examine": "This might help me chop harder.",
"id": 5751,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 2,
"highalch": 1,
"icon": "Axeman's folly.png",
"name": "Axeman's folly"
},
{
"examine": "This keg contains 4 pints of Axeman's Folly.",
"id": 5825,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Axeman's folly(4).png",
"name": "Axeman's folly(4)"
},
{
"examine": "This looks a good deal stronger than normal Axeman's Folly.",
"id": 5753,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 2,
"highalch": 1,
"icon": "Axeman's folly(m).png",
"name": "Axeman's folly(m)"
},
{
"examine": "This keg contains 4 pints of mature Axeman's Folly.",
"id": 5905,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Axeman's folly(m4).png",
"name": "Axeman's folly(m4)"
},
{
"examine": "Baby impling in a jar. That's a bit cruel.",
"id": 11238,
"members": true,
"lowalch": 20,
"limit": 18000,
"value": 50,
"highalch": 30,
"icon": "Baby impling jar.png",
"name": "Baby impling jar"
},
{
"examine": "Ew it's a pile of bones.",
"id": 534,
"members": true,
"lowalch": 1,
"limit": 3000,
"value": 1,
"highalch": 1,
"icon": "Babydragon bones.png",
"name": "Babydragon bones"
},
{
"examine": "A bag of salt.",
"id": 4161,
"members": true,
"lowalch": 4,
"limit": 8000,
"value": 10,
"highalch": 6,
"icon": "Bag of salt.png",
"name": "Bag of salt"
},
{
"examine": "You can plant this in your garden.",
"id": 8455,
"members": true,
"lowalch": 6000,
"limit": 10000,
"value": 15000,
"highalch": 9000,
"icon": "Bagged bluebells.png",
"name": "Bagged bluebells"
},
{
"examine": "You can plant this in your garden.",
"id": 8453,
"members": true,
"lowalch": 4000,
"limit": 10000,
"value": 10000,
"highalch": 6000,
"icon": "Bagged daffodils.png",
"name": "Bagged daffodils"
},
{
"examine": "You can plant this in your garden.",
"id": 8417,
"members": true,
"lowalch": 400,
"limit": 10000,
"value": 1000,
"highalch": 600,
"icon": "Bagged dead tree.png",
"name": "Bagged dead tree"
},
{
"examine": "You can plant this in your garden.",
"id": 8451,
"members": true,
"lowalch": 2000,
"limit": 10000,
"value": 5000,
"highalch": 3000,
"icon": "Bagged flower.png",
"name": "Bagged flower"
},
{
"examine": "You can plant this in your garden.",
"id": 8429,
"members": true,
"lowalch": 20000,
"limit": 10000,
"value": 50000,
"highalch": 30000,
"icon": "Bagged magic tree.png",
"name": "Bagged magic tree"
},
{
"examine": "You can plant this in your garden.",
"id": 8425,
"members": true,
"lowalch": 6000,
"limit": 10000,
"value": 15000,
"highalch": 9000,
"icon": "Bagged maple tree.png",
"name": "Bagged maple tree"
},
{
"examine": "You can plant this in your garden.",
"id": 8459,
"members": true,
"lowalch": 4000,
"limit": 10000,
"value": 10000,
"highalch": 6000,
"icon": "Bagged marigolds.png",
"name": "Bagged marigolds"
},
{
"examine": "You can plant this in your garden.",
"id": 8419,
"members": true,
"lowalch": 800,
"limit": 10000,
"value": 2000,
"highalch": 1200,
"icon": "Bagged nice tree.png",
"name": "Bagged nice tree"
},
{
"examine": "You can plant this in your garden.",
"id": 8421,
"members": true,
"lowalch": 2000,
"limit": 10000,
"value": 5000,
"highalch": 3000,
"icon": "Bagged oak tree.png",
"name": "Bagged oak tree"
},
{
"examine": "You can plant this in your garden.",
"id": 8431,
"members": true,
"lowalch": 400,
"limit": 10000,
"value": 1000,
"highalch": 600,
"icon": "Bagged plant 1.png",
"name": "Bagged plant 1"
},
{
"examine": "You can plant this in your garden.",
"id": 8433,
"members": true,
"lowalch": 2000,
"limit": 10000,
"value": 5000,
"highalch": 3000,
"icon": "Bagged plant 2.png",
"name": "Bagged plant 2"
},
{
"examine": "You can plant this in your garden.",
"id": 8435,
"members": true,
"lowalch": 4000,
"limit": 10000,
"value": 10000,
"highalch": 6000,
"icon": "Bagged plant 3.png",
"name": "Bagged plant 3"
},
{
"examine": "You can plant this in your garden.",
"id": 8461,
"members": true,
"lowalch": 6000,
"limit": 10000,
"value": 15000,
"highalch": 9000,
"icon": "Bagged roses.png",
"name": "Bagged roses"
},
{
"examine": "You can plant this in your garden.",
"id": 8457,
"members": true,
"lowalch": 2000,
"limit": 10000,
"value": 5000,
"highalch": 3000,
"icon": "Bagged sunflower.png",
"name": "Bagged sunflower"
},
{
"examine": "You can plant this in your garden.",
"id": 8423,
"members": true,
"lowalch": 4000,
"limit": 10000,
"value": 10000,
"highalch": 6000,
"icon": "Bagged willow tree.png",
"name": "Bagged willow tree"
},
{
"examine": "You can plant this in your garden.",
"id": 8427,
"members": true,
"lowalch": 8000,
"limit": 10000,
"value": 20000,
"highalch": 12000,
"icon": "Bagged yew tree.png",
"name": "Bagged yew tree"
},
{
"examine": "It'd taste even better with some toppings.",
"id": 6701,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 4,
"highalch": 2,
"icon": "Baked potato.png",
"name": "Baked potato"
},
{
"examine": "Spun from sheeps' wool.",
"id": 1759,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Ball of wool.png",
"name": "Ball of wool"
},
{
"examine": "Sturdy struts.",
"id": 19592,
"members": true,
"lowalch": 20000,
"limit": 8,
"value": 50000,
"highalch": 30000,
"icon": "Ballista limbs.png",
"name": "Ballista limbs"
},
{
"examine": "A spring-loaded mechanism.",
"id": 19601,
"members": true,
"lowalch": 20000,
"limit": 8,
"value": 50000,
"highalch": 30000,
"icon": "Ballista spring.png",
"name": "Ballista spring"
},
{
"examine": "Mmm this looks tasty.",
"id": 1963,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Banana.png",
"name": "Banana"
},
{
"examine": "This sapling is ready to be replanted in a fruit tree patch.",
"id": 5497,
"members": true,
"lowalch": 1,
"limit": 200,
"value": 1,
"highalch": 1,
"icon": "Banana sapling.png",
"name": "Banana sapling"
},
{
"examine": "It's a bowl full of mushy banana.",
"id": 4016,
"members": true,
"lowalch": 120,
"limit": 6000,
"value": 300,
"highalch": 180,
"icon": "Banana stew.png",
"name": "Banana stew"
},
{
"examine": "Plant in a plantpot of soil to grow a sapling.",
"id": 5284,
"members": true,
"lowalch": 8,
"limit": 200,
"value": 20,
"highalch": 12,
"icon": "Banana tree seed 5.png",
"name": "Banana tree seed"
},
{
"examine": "A fruit basket filled with bananas.",
"id": 5416,
"members": true,
"lowalch": 1,
"limit": 600,
"value": 1,
"highalch": 1,
"icon": "Bananas(5).png",
"name": "Bananas(5)"
},
{
"examine": "Essential pirate wear.",
"id": 8926,
"members": true,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Bandana eyepatch (blue).png",
"name": "Bandana eyepatch (blue)"
},
{
"examine": "Essential pirate wear.",
"id": 8927,
"members": true,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Bandana eyepatch (brown).png",
"name": "Bandana eyepatch (brown)"
},
{
"examine": "Essential pirate wear.",
"id": 8925,
"members": true,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Bandana eyepatch (red).png",
"name": "Bandana eyepatch (red)"
},
{
"examine": "Essential pirate wear.",
"id": 8924,
"members": true,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Bandana eyepatch (white).png",
"name": "Bandana eyepatch (white)"
},
{
"examine": "A cheeky little lager.",
"id": 4627,
"members": true,
"lowalch": 200,
"limit": 2000,
"value": 500,
"highalch": 300,
"icon": "Bandit's brew.png",
"name": "Bandit's brew"
},
{
"examine": "Some sturdy boots.",
"id": 11836,
"members": true,
"lowalch": 115604,
"limit": 8,
"value": 289010,
"highalch": 173406,
"icon": "Bandos boots.png",
"name": "Bandos boots"
},
{
"examine": "Bandos blessed dragonhide vambraces.",
"id": 12498,
"members": true,
"lowalch": 1600,
"limit": 8,
"value": 4000,
"highalch": 2400,
"icon": "Bandos bracers.png",
"name": "Bandos bracers"
},
{
"examine": "Bandos blessed dragonhide chaps.",
"id": 12502,
"members": true,
"lowalch": 2400,
"limit": 8,
"value": 6000,
"highalch": 3600,
"icon": "Bandos chaps.png",
"name": "Bandos chaps"
},
{
"examine": "A sturdy chestplate.",
"id": 11832,
"members": true,
"lowalch": 116004,
"limit": 8,
"value": 290010,
"highalch": 174006,
"icon": "Bandos chestplate.png",
"name": "Bandos chestplate"
},
{
"examine": "A Bandos cloak.",
"id": 12273,
"members": true,
"lowalch": 800,
"limit": 8,
"value": 2000,
"highalch": 1200,
"icon": "Bandos cloak.png",
"name": "Bandos cloak"
},
{
"examine": "Bandos blessed dragonhide coif.",
"id": 12504,
"members": true,
"lowalch": 800,
"limit": 8,
"value": 2000,
"highalch": 1200,
"icon": "Bandos coif.png",
"name": "Bandos coif"
},
{
"examine": "A Bandos crozier.",
"id": 12275,
"members": true,
"lowalch": 2000,
"limit": 8,
"value": 5000,
"highalch": 3000,
"icon": "Bandos crozier.png",
"name": "Bandos crozier"
},
{
"examine": "Bandos blessed dragonhide body armour.",
"id": 12500,
"members": true,
"lowalch": 5200,
"limit": 8,
"value": 13000,
"highalch": 7800,
"icon": "Bandos d'hide body.png",
"name": "Bandos d'hide body"
},
{
"examine": "Bandos blessed dragonhide boots.",
"id": 19924,
"members": true,
"lowalch": 3720,
"limit": 8,
"value": 9300,
"highalch": 5580,
"icon": "Bandos d'hide boots.png",
"name": "Bandos d'hide boots"
},
{
"examine": "Bandos blessed wooden shield covered in dragon leather.",
"id": 23203,
"members": true,
"lowalch": 11333,
"limit": 8,
"value": 28334,
"highalch": 17000,
"icon": "Bandos d'hide shield.png",
"name": "Bandos d'hide shield"
},
{
"examine": "A set containing a Bandos dragonhide coif, body, chaps and bracers.",
"id": 13167,
"members": true,
"lowalch": 3800,
"limit": 8,
"value": 9500,
"highalch": 5700,
"icon": "Bandos dragonhide set.png",
"name": "Bandos dragonhide set"
},
{
"examine": "Rune full helmet in the colours of Bandos.",
"id": 12486,
"members": false,
"lowalch": 14080,
"limit": 8,
"value": 35200,
"highalch": 21120,
"icon": "Bandos full helm.png",
"name": "Bandos full helm"
},
{
"examine": "A brutally heavy sword.",
"id": 11804,
"members": true,
"lowalch": 500000,
"limit": 8,
"value": 1250000,
"highalch": 750000,
"icon": "Bandos godsword.png",
"name": "Bandos godsword"
},
{
"examine": "Use on a Bandos godsword to make it look fancier!",
"id": 20071,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Bandos godsword ornament kit.png",
"name": "Bandos godsword ornament kit"
},
{
"examine": "Brimming with potential.",
"id": 11812,
"members": true,
"lowalch": 200000,
"limit": 10000,
"value": 500000,
"highalch": 300000,
"icon": "Bandos hilt.png",
"name": "Bandos hilt"
},
{
"examine": "Rune kiteshield in the colours of Bandos.",
"id": 12488,
"members": false,
"lowalch": 21760,
"limit": 8,
"value": 54400,
"highalch": 32640,
"icon": "Bandos kiteshield.png",
"name": "Bandos kiteshield"
},
{
"examine": "A Bandos mitre.",
"id": 12271,
"members": true,
"lowalch": 2000,
"limit": 8,
"value": 5000,
"highalch": 3000,
"icon": "Bandos mitre.png",
"name": "Bandos mitre"
},
{
"examine": "This seems to have been torn from a book...",
"id": 12613,
"members": true,
"lowalch": 160,
"limit": 5,
"value": 400,
"highalch": 240,
"icon": "Bandos page 1.png",
"name": "Bandos page 1"
},
{
"examine": "This seems to have been torn from a book...",
"id": 12614,
"members": true,
"lowalch": 160,
"limit": 5,
"value": 400,
"highalch": 240,
"icon": "Bandos page 2.png",
"name": "Bandos page 2"
},
{
"examine": "This seems to have been torn from a book...",
"id": 12615,
"members": true,
"lowalch": 160,
"limit": 5,
"value": 400,
"highalch": 240,
"icon": "Bandos page 3.png",
"name": "Bandos page 3"
},
{
"examine": "This seems to have been torn from a book...",
"id": 12616,
"members": true,
"lowalch": 160,
"limit": 5,
"value": 400,
"highalch": 240,
"icon": "Bandos page 4.png",
"name": "Bandos page 4"
},
{
"examine": "Rune platebody in the colours of Bandos.",
"id": 12480,
"members": false,
"lowalch": 26000,
"limit": 8,
"value": 65000,
"highalch": 39000,
"icon": "Bandos platebody.png",
"name": "Bandos platebody"
},
{
"examine": "Rune platelegs in the colours of Bandos.",
"id": 12482,
"members": false,
"lowalch": 25600,
"limit": 8,
"value": 64000,
"highalch": 38400,
"icon": "Bandos platelegs.png",
"name": "Bandos platelegs"
},
{
"examine": "Rune plateskirt in the colours of Bandos.",
"id": 12484,
"members": false,
"lowalch": 25600,
"limit": 8,
"value": 64000,
"highalch": 38400,
"icon": "Bandos plateskirt.png",
"name": "Bandos plateskirt"
},
{
"examine": "Leggings from the Bandos Vestments.",
"id": 12267,
"members": true,
"lowalch": 2800,
"limit": 8,
"value": 7000,
"highalch": 4200,
"icon": "Bandos robe legs.png",
"name": "Bandos robe legs"
},
{
"examine": "Bandos Vestments.",
"id": 12265,
"members": true,
"lowalch": 2800,
"limit": 8,
"value": 7000,
"highalch": 4200,
"icon": "Bandos robe top.png",
"name": "Bandos robe top"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 13056,
"members": false,
"lowalch": 32000,
"limit": 8,
"value": 80000,
"highalch": 48000,
"icon": "Bandos rune armour set (lg).png",
"name": "Bandos rune armour set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 13058,
"members": false,
"lowalch": 32000,
"limit": 8,
"value": 80000,
"highalch": 48000,
"icon": "Bandos rune armour set (sk).png",
"name": "Bandos rune armour set (sk)"
},
{
"examine": "A Bandos stole.",
"id": 12269,
"members": true,
"lowalch": 1000,
"limit": 8,
"value": 2500,
"highalch": 1500,
"icon": "Bandos stole.png",
"name": "Bandos stole"
},
{
"examine": "A sturdy pair of tassets.",
"id": 11834,
"members": true,
"lowalch": 115964,
"limit": 8,
"value": 289910,
"highalch": 173946,
"icon": "Bandos tassets.png",
"name": "Bandos tassets"
},
{
"highalch": 1,
"members": true,
"name": "Bandosian components",
"examine": "High grade material.",
"id": 26394,
"value": 1,
"icon": "Bandosian components.png",
"lowalch": 1
},
{
"examine": "I can make bolts with these.",
"id": 47,
"members": true,
"lowalch": 38,
"limit": 11000,
"value": 95,
"highalch": 57,
"icon": "Barb bolttips.png",
"name": "Barb bolttips"
},
{
"examine": "A mighty Hunter weapon. One previous owner.",
"id": 10129,
"members": true,
"lowalch": 14,
"limit": 40,
"value": 36,
"highalch": 21,
"icon": "Barb-tail harpoon.png",
"name": "Barb-tail harpoon"
},
{
"examine": "A teleport to Barbarian Outpost.",
"id": 24955,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Barbarian teleport (tablet).png",
"name": "Barbarian teleport (tablet)"
},
{
"examine": "Great if you have a crossbow!",
"id": 881,
"members": true,
"lowalch": 80,
"limit": 7000,
"value": 200,
"highalch": 120,
"icon": "Barbed bolts 5.png",
"name": "Barbed bolts"
},
{
"examine": "Bark from a hollow tree.",
"id": 3239,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Bark.png",
"name": "Bark"
},
{
"examine": "A handful of Barley.",
"id": 6006,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 4,
"highalch": 2,
"icon": "Barley.png",
"name": "Barley"
},
{
"examine": "A handful of barley malt.",
"id": 6008,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 4,
"highalch": 2,
"icon": "Barley malt.png",
"name": "Barley malt"
},
{
"examine": "A barley seed - plant in a hops patch.",
"id": 5305,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 2,
"highalch": 1,
"icon": "Barley seed 5.png",
"name": "Barley seed"
},
{
"examine": "An empty barrel.",
"id": 3216,
"members": true,
"lowalch": 1,
"limit": 15,
"value": 1,
"highalch": 1,
"icon": "Barrel.png",
"name": "Barrel"
},
{
"examine": "A teleport to the Barrows.",
"id": 19629,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Barrows teleport (tablet).png",
"name": "Barrows teleport (tablet)"
},
{
"examine": "The crushed rock looks like it may react to other materials.",
"id": 22603,
"members": true,
"lowalch": 12,
"limit": 10000,
"value": 30,
"highalch": 18,
"icon": "Basalt.png",
"name": "Basalt"
},
{
"highalch": 300000,
"members": true,
"name": "Basilisk jaw",
"examine": "The jaw of a Basilisk Knight.",
"id": 24268,
"value": 500000,
"icon": "Basilisk jaw.png",
"lowalch": 200000
},
{
"examine": "An empty fruit basket.",
"id": 5376,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Basket.png",
"name": "Basket"
},
{
"examine": "Wow, this is a big fish.",
"id": 365,
"members": true,
"lowalch": 16,
"limit": 6000,
"value": 40,
"highalch": 24,
"icon": "Bass.png",
"name": "Bass"
},
{
"examine": "1 dose of Bastion potion.",
"id": 22470,
"members": true,
"lowalch": 36,
"limit": 2000,
"value": 90,
"highalch": 54,
"icon": "Bastion potion(1).png",
"name": "Bastion potion(1)"
},
{
"examine": "2 doses of Bastion potion.",
"id": 22467,
"members": true,
"lowalch": 72,
"limit": 2000,
"value": 180,
"highalch": 108,
"icon": "Bastion potion(2).png",
"name": "Bastion potion(2)"
},
{
"examine": "3 doses of Bastion potion.",
"id": 22464,
"members": true,
"lowalch": 108,
"limit": 2000,
"value": 270,
"highalch": 162,
"icon": "Bastion potion(3).png",
"name": "Bastion potion(3)"
},
{
"examine": "4 doses of Bastion potion.",
"id": 22461,
"members": true,
"lowalch": 144,
"limit": 2000,
"value": 360,
"highalch": 216,
"icon": "Bastion potion(4).png",
"name": "Bastion potion(4)"
},
{
"examine": "Ew it's a pile of bones.",
"id": 530,
"members": true,
"lowalch": 1,
"limit": 3000,
"value": 1,
"highalch": 1,
"icon": "Bat bones.png",
"name": "Bat bones"
},
{
"examine": "A deep tin used for baking gnome battas in.",
"id": 2164,
"members": true,
"lowalch": 4,
"limit": 40,
"value": 10,
"highalch": 6,
"icon": "Batta tin.png",
"name": "Batta tin"
},
{
"examine": "A teleport to the Kebos Battlefront.",
"id": 22949,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Battlefront teleport (tablet).png",
"name": "Battlefront teleport (tablet)"
},
{
"examine": "1 dose of Battlemage potion.",
"id": 22458,
"members": true,
"lowalch": 36,
"limit": 2000,
"value": 90,
"highalch": 54,
"icon": "Battlemage potion(1).png",
"name": "Battlemage potion(1)"
},
{
"examine": "2 doses of Battlemage potion.",
"id": 22455,
"members": true,
"lowalch": 72,
"limit": 2000,
"value": 180,
"highalch": 108,
"icon": "Battlemage potion(2).png",
"name": "Battlemage potion(2)"
},
{
"examine": "3 doses of Battlemage potion.",
"id": 22452,
"members": true,
"lowalch": 108,
"limit": 2000,
"value": 270,
"highalch": 162,
"icon": "Battlemage potion(3).png",
"name": "Battlemage potion(3)"
},
{
"examine": "4 dose of Battlemage potion.",
"id": 22449,
"members": true,
"lowalch": 144,
"limit": 2000,
"value": 360,
"highalch": 216,
"icon": "Battlemage potion(4).png",
"name": "Battlemage potion(4)"
},
{
"examine": "It's a slightly magical stick.",
"id": 1391,
"members": true,
"lowalch": 2800,
"limit": 11000,
"value": 7000,
"highalch": 4200,
"icon": "Battlestaff.png",
"name": "Battlestaff"
},
{
"examine": "Weeeeeee!",
"id": 12245,
"members": true,
"lowalch": 240,
"limit": 4,
"value": 600,
"highalch": 360,
"icon": "Beanie.png",
"name": "Beanie"
},
{
"examine": "Vicious bear slippers.",
"id": 23291,
"members": false,
"lowalch": 400,
"limit": 4,
"value": 1000,
"highalch": 600,
"icon": "Bear feet.png",
"name": "Bear feet"
},
{
"examine": "This would make warm clothing.",
"id": 948,
"members": false,
"lowalch": 4,
"limit": 18000,
"value": 10,
"highalch": 6,
"icon": "Bear fur.png",
"name": "Bear fur"
},
{
"examine": "A glass of frothy ale.",
"id": 1917,
"members": false,
"lowalch": 1,
"limit": 2000,
"value": 2,
"highalch": 1,
"icon": "Beer.png",
"name": "Beer"
},
{
"examine": "How does it all fit in there?",
"id": 8516,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Beer barrel (flatpack).png",
"name": "Beer barrel (flatpack)"
},
{
"examine": "I need to fill this with beer.",
"id": 1919,
"members": false,
"lowalch": 1,
"limit": 10000,
"value": 2,
"highalch": 1,
"icon": "Beer glass.png",
"name": "Beer glass"
},
{
"examine": "Frothy and alcoholic.",
"id": 3803,
"members": true,
"lowalch": 8,
"limit": 2000,
"value": 20,
"highalch": 12,
"icon": "Beer tankard.png",
"name": "Beer tankard"
},
{
"examine": "A beginner level wand.",
"id": 6908,
"members": true,
"lowalch": 480,
"limit": 125,
"value": 1200,
"highalch": 720,
"icon": "Beginner wand.png",
"name": "Beginner wand"
},
{
"examine": "Also known as Deadly Nightshade - plant in a belladonna patch.",
"id": 5281,
"members": true,
"lowalch": 70,
"limit": 200,
"value": 177,
"highalch": 106,
"icon": "Belladonna seed 5.png",
"name": "Belladonna seed"
},
{
"highalch": 1,
"members": true,
"name": "Bellator ring",
"examine": "An occult ring that grants immense slicing power.",
"id": 28316,
"value": 1,
"icon": "Bellator ring.png",
"lowalch": 1
},
{
"examine": "This helmet is worn by berserkers.",
"id": 3751,
"members": true,
"lowalch": 24000,
"limit": 70,
"value": 60000,
"highalch": 36000,
"icon": "Berserker helm.png",
"name": "Berserker helm"
},
{
"highalch": 1,
"members": true,
"name": "Berserker icon",
"examine": "The icon of a berserker.",
"id": 28295,
"value": 1,
"icon": "Berserker icon.png",
"lowalch": 1
},
{
"examine": "Makes obsidian weapons even stronger!",
"id": 11128,
"members": true,
"lowalch": 80800,
"limit": 70,
"value": 202000,
"highalch": 121200,
"icon": "Berserker necklace.png",
"name": "Berserker necklace"
},
{
"examine": "Use on the Berserker necklace to trim it with gold.",
"id": 23237,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Berserker necklace ornament kit.png",
"name": "Berserker necklace ornament kit"
},
{
"examine": "A ring reputed to bring out a berserk fury in its wearer.",
"id": 6737,
"members": true,
"lowalch": 4000,
"limit": 8,
"value": 10000,
"highalch": 6000,
"icon": "Berserker ring.png",
"name": "Berserker ring"
},
{
"examine": "Ew it's a pile of bones.",
"id": 532,
"members": false,
"lowalch": 1,
"limit": 3000,
"value": 1,
"highalch": 1,
"icon": "Big bones.png",
"name": "Big bones"
},
{
"examine": "Useful for catching lots of fish.",
"id": 305,
"members": true,
"lowalch": 8,
"limit": 40,
"value": 20,
"highalch": 12,
"icon": "Big fishing net.png",
"name": "Big fishing net"
},
{
"examine": "Yarrr!",
"id": 12355,
"members": true,
"lowalch": 600,
"limit": 4,
"value": 1500,
"highalch": 900,
"icon": "Big pirate hat.png",
"name": "Big pirate hat"
},
{
"examine": "A necklace embedded with mystical power.",
"id": 5521,
"members": true,
"lowalch": 570,
"limit": 10000,
"value": 1425,
"highalch": 855,
"icon": "Binding necklace.png",
"name": "Binding necklace"
},
{
"examine": "Feed and catch the birds by placing this in the right spot.",
"id": 21512,
"members": true,
"lowalch": 2,
"limit": 250,
"value": 5,
"highalch": 3,
"icon": "Bird house.png",
"name": "Bird house"
},
{
"examine": "It's an empty bird's nest.",
"id": 5075,
"members": true,
"lowalch": 181,
"limit": 11000,
"value": 453,
"highalch": 271,
"icon": "Bird nest (empty).png",
"name": "Bird nest (empty)"
},
{
"examine": "A simple bird catcher.",
"id": 10006,
"members": true,
"lowalch": 2,
"limit": 250,
"value": 5,
"highalch": 3,
"icon": "Bird snare.png",
"name": "Bird snare"
},
{
"examine": "A two handed sword.",
"id": 1313,
"members": false,
"lowalch": 768,
"limit": 125,
"value": 1920,
"highalch": 1152,
"icon": "Black 2h sword.png",
"name": "Black 2h sword"
},
{
"examine": "A sinister looking axe.",
"id": 1361,
"members": false,
"lowalch": 153,
"limit": 40,
"value": 384,
"highalch": 230,
"icon": "Black axe.png",
"name": "Black axe"
},
{
"examine": "A vicious looking axe.",
"id": 1367,
"members": false,
"lowalch": 499,
"limit": 125,
"value": 1248,
"highalch": 748,
"icon": "Black battleaxe.png",
"name": "Black battleaxe"
},
{
"examine": "A small round black bead.",
"id": 1474,
"members": false,
"lowalch": 1,
"limit": 11000,
"value": 4,
"highalch": 2,
"icon": "Black bead.png",
"name": "Black bead"
},
{
"examine": "Parlez-vous francais?",
"id": 2635,
"members": true,
"lowalch": 32,
"limit": 4,
"value": 80,
"highalch": 48,
"icon": "Black beret.png",
"name": "Black beret"
},
{
"examine": "Stylish!",
"id": 7327,
"members": true,
"lowalch": 90,
"limit": 4,
"value": 225,
"highalch": 135,
"icon": "Black boater.png",
"name": "Black boater"
},
{
"examine": "These will protect my feet.",
"id": 4125,
"members": true,
"lowalch": 230,
"limit": 125,
"value": 576,
"highalch": 345,
"icon": "Black boots.png",
"name": "Black boots"
},
{
"examine": "Blunt black arrow... ouch.",
"id": 4788,
"members": true,
"lowalch": 14,
"limit": 11000,
"value": 35,
"highalch": 21,
"icon": "Black brutal 5.png",
"name": "Black brutal"
},
{
"examine": "A ruby topped cane.",
"id": 12375,
"members": true,
"lowalch": 240,
"limit": 4,
"value": 600,
"highalch": 360,
"icon": "Black cane.png",
"name": "Black cane"
},
{
"examine": "A warm black cape.",
"id": 1019,
"members": false,
"lowalch": 2,
"limit": 150,
"value": 7,
"highalch": 4,
"icon": "Black cape.png",
"name": "Black cape"
},
{
"examine": "All for one and one for all!",
"id": 2643,
"members": true,
"lowalch": 80,
"limit": 4,
"value": 200,
"highalch": 120,
"icon": "Black cavalier.png",
"name": "Black cavalier"
},
{
"examine": "A series of connected metal rings.",
"id": 1107,
"members": false,
"lowalch": 576,
"limit": 125,
"value": 1440,
"highalch": 864,
"icon": "Black chainbody.png",
"name": "Black chainbody"
},
{
"examine": "Just plain nasty.",
"id": 11959,
"members": true,
"lowalch": 96,
"limit": 11000,
"value": 240,
"highalch": 144,
"icon": "Black chinchompa.png",
"name": "Black chinchompa"
},
{
"examine": "A set of fighting claws.",
"id": 3098,
"members": true,
"lowalch": 144,
"limit": 125,
"value": 360,
"highalch": 216,
"icon": "Black claws.png",
"name": "Black claws"
},
{
"examine": "Made from 100% real dragonhide.",
"id": 2503,
"members": true,
"lowalch": 5392,
"limit": 70,
"value": 13480,
"highalch": 8088,
"icon": "Black d'hide body.png",
"name": "Black d'hide body"
},
{
"examine": "Made from 100% real dragonhide. With colourful trim!",
"id": 12381,
"members": true,
"lowalch": 5392,
"limit": 8,
"value": 13480,
"highalch": 8088,
"icon": "Black d'hide body (g).png",
"name": "Black d'hide body (g)"
},
{
"examine": "Made from 100% real dragonhide. With colourful trim!",
"id": 12385,
"members": true,
"lowalch": 5392,
"limit": 8,
"value": 13480,
"highalch": 8088,
"icon": "Black d'hide body (t).png",
"name": "Black d'hide body (t)"
},
{
"examine": "Made from 100% real dragonhide.",
"id": 2497,
"members": true,
"lowalch": 2488,
"limit": 70,
"value": 6220,
"highalch": 3732,
"icon": "Black d'hide chaps.png",
"name": "Black d'hide chaps"
},
{
"examine": "Made from 100% real dragonhide. With colourful trim!",
"id": 12383,
"members": true,
"lowalch": 2488,
"limit": 8,
"value": 6220,
"highalch": 3732,
"icon": "Black d'hide chaps (g).png",
"name": "Black d'hide chaps (g)"
},
{
"examine": "Made from 100% real dragonhide. With colourful trim!",
"id": 12387,
"members": true,
"lowalch": 2488,
"limit": 8,
"value": 6220,
"highalch": 3732,
"icon": "Black d'hide chaps (t).png",
"name": "Black d'hide chaps (t)"
},
{
"examine": "A solid redwood shield covered in black dragon leather.",
"id": 22284,
"members": true,
"lowalch": 11333,
"limit": 125,
"value": 28334,
"highalch": 17000,
"icon": "Black d'hide shield.png",
"name": "Black d'hide shield"
},
{
"examine": "Vambraces made from 100% real dragonhide.",
"id": 2491,
"members": true,
"lowalch": 1728,
"limit": 70,
"value": 4320,
"highalch": 2592,
"icon": "Black d'hide vambraces.png",
"name": "Black d'hide vambraces"
},
{
"examine": "A vicious black dagger.",
"id": 1217,
"members": false,
"lowalch": 96,
"limit": 125,
"value": 240,
"highalch": 144,
"icon": "Black dagger.png",
"name": "Black dagger"
},
{
"examine": "This dagger is poisoned.",
"id": 1233,
"members": true,
"lowalch": 96,
"limit": 125,
"value": 240,
"highalch": 144,
"icon": "Black dagger(p).png",
"name": "Black dagger(p)"
},
{
"examine": "This dagger is poisoned.",
"id": 5682,
"members": true,
"lowalch": 96,
"limit": 125,
"value": 240,
"highalch": 144,
"icon": "Black dagger(p+).png",
"name": "Black dagger(p+)"
},
{
"examine": "This dagger is poisoned.",
"id": 5700,
"members": true,
"lowalch": 96,
"limit": 125,
"value": 240,
"highalch": 144,
"icon": "Black dagger(p++).png",
"name": "Black dagger(p++)"
},
{
"examine": "A deadly throwing dart with a black tip.",
"id": 3093,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 1,
"highalch": 1,
"icon": "Black dart.png",
"name": "Black dart"
},
{
"examine": "A deadly poisoned dart with a black tip.",
"id": 3094,
"members": true,
"lowalch": 7,
"limit": 7000,
"value": 18,
"highalch": 10,
"icon": "Black dart(p).png",
"name": "Black dart(p)"
},
{
"examine": "A deadly poisoned dart with a black tip.",
"id": 5631,
"members": true,
"lowalch": 7,
"limit": 7000,
"value": 18,
"highalch": 10,
"icon": "Black dart(p+).png",
"name": "Black dart(p+)"
},
{
"examine": "A deadly poisoned dart with a black tip.",
"id": 5638,
"members": true,
"lowalch": 7,
"limit": 7000,
"value": 18,
"highalch": 10,
"icon": "Black dart(p++).png",
"name": "Black dart(p++)"
},
{
"examine": "Evil.",
"id": 20026,
"members": true,
"lowalch": 1600,
"limit": 4,
"value": 4000,
"highalch": 2400,
"icon": "Black demon mask.png",
"name": "Black demon mask"
},
{
"examine": "A desert robe stained black.",
"id": 6752,
"members": true,
"lowalch": 12,
"limit": 15,
"value": 30,
"highalch": 18,
"icon": "Black desert robe.png",
"name": "Black desert robe"
},
{
"examine": "A desert shirt stained black.",
"id": 6750,
"members": true,
"lowalch": 16,
"limit": 15,
"value": 40,
"highalch": 24,
"icon": "Black desert shirt.png",
"name": "Black desert shirt"
},
{
"examine": "It's a piece of prepared black dragonhide.",
"id": 2509,
"members": true,
"lowalch": 44,
"limit": 11000,
"value": 110,
"highalch": 66,
"icon": "Black dragon leather.png",
"name": "Black dragon leather"
},
{
"examine": "Do I look scary?",
"id": 12524,
"members": true,
"lowalch": 4000,
"limit": 4,
"value": 10000,
"highalch": 6000,
"icon": "Black dragon mask.png",
"name": "Black dragon mask"
},
{
"examine": "The scaly rough hide from a Black Dragon.",
"id": 1747,
"members": true,
"lowalch": 60,
"limit": 11000,
"value": 150,
"highalch": 90,
"icon": "Black dragonhide.png",
"name": "Black dragonhide"
},
{
"examine": "A set containing a black dragonhide body, chaps and vambraces.",
"id": 12871,
"members": true,
"lowalch": 2000,
"limit": 70,
"value": 5000,
"highalch": 3000,
"icon": "Black dragonhide set.png",
"name": "Black dragonhide set"
},
{
"examine": "A rather elegant pair of men's black pantaloons.",
"id": 10402,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Black elegant legs.png",
"name": "Black elegant legs"
},
{
"examine": "A well made elegant men's black shirt.",
"id": 10400,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Black elegant shirt.png",
"name": "Black elegant shirt"
},
{
"highalch": 230,
"members": true,
"name": "Black felling axe",
"examine": "A woodcutter's axe.",
"id": 28205,
"value": 384,
"icon": "Black felling axe.png",
"lowalch": 153
},
{
"examine": "A posy of flowers.",
"id": 2476,
"members": true,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Black flowers.png",
"name": "Black flowers"
},
{
"examine": "A full face helmet.",
"id": 1165,
"members": false,
"lowalch": 422,
"limit": 125,
"value": 1056,
"highalch": 633,
"icon": "Black full helm.png",
"name": "Black full helm"
},
{
"examine": "Black full helmet with gold trim.",
"id": 2595,
"members": false,
"lowalch": 422,
"limit": 8,
"value": 1056,
"highalch": 633,
"icon": "Black full helm (g).png",
"name": "Black full helm (g)"
},
{
"examine": "Black full helmet with trim.",
"id": 2587,
"members": false,
"lowalch": 422,
"limit": 8,
"value": 1056,
"highalch": 633,
"icon": "Black full helm (t).png",
"name": "Black full helm (t)"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 12996,
"members": false,
"lowalch": 1800,
"limit": 8,
"value": 4500,
"highalch": 2700,
"icon": "Black gold-trimmed set (lg).png",
"name": "Black gold-trimmed set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 12998,
"members": false,
"lowalch": 1800,
"limit": 8,
"value": 4500,
"highalch": 2700,
"icon": "Black gold-trimmed set (sk).png",
"name": "Black gold-trimmed set (sk)"
},
{
"examine": "A black halberd.",
"id": 3196,
"members": true,
"lowalch": 768,
"limit": 125,
"value": 1920,
"highalch": 1152,
"icon": "Black halberd.png",
"name": "Black halberd"
},
{
"examine": "A minimalist's hat.",
"id": 2647,
"members": true,
"lowalch": 16,
"limit": 4,
"value": 40,
"highalch": 24,
"icon": "Black headband.png",
"name": "Black headband"
},
{
"examine": "A black helmet with a heraldic design.",
"id": 10306,
"members": false,
"lowalch": 422,
"limit": 70,
"value": 1056,
"highalch": 633,
"icon": "Black helm (h1).png",
"name": "Black helm (h1)"
},
{
"examine": "A black helmet with a heraldic design.",
"id": 10308,
"members": false,
"lowalch": 422,
"limit": 70,
"value": 1056,
"highalch": 633,
"icon": "Black helm (h2).png",
"name": "Black helm (h2)"
},
{
"examine": "A black helmet with a heraldic design.",
"id": 10310,
"members": false,
"lowalch": 422,
"limit": 70,
"value": 1056,
"highalch": 633,
"icon": "Black helm (h3).png",
"name": "Black helm (h3)"
},
{
"examine": "A black helmet with a heraldic design.",
"id": 10312,
"members": false,
"lowalch": 422,
"limit": 70,
"value": 1056,
"highalch": 633,
"icon": "Black helm (h4).png",
"name": "Black helm (h4)"
},
{
"examine": "A black helmet with a heraldic design.",
"id": 10314,
"members": false,
"lowalch": 422,
"limit": 70,
"value": 1056,
"highalch": 633,
"icon": "Black helm (h5).png",
"name": "Black helm (h5)"
},
{
"examine": "A large metal shield.",
"id": 1195,
"members": false,
"lowalch": 652,
"limit": 125,
"value": 1632,
"highalch": 979,
"icon": "Black kiteshield.png",
"name": "Black kiteshield"
},
{
"examine": "Black kiteshield with gold trim.",
"id": 2597,
"members": false,
"lowalch": 652,
"limit": 8,
"value": 1632,
"highalch": 979,
"icon": "Black kiteshield (g).png",
"name": "Black kiteshield (g)"
},
{
"examine": "Black kiteshield with trim.",
"id": 2589,
"members": false,
"lowalch": 652,
"limit": 8,
"value": 1632,
"highalch": 979,
"icon": "Black kiteshield (t).png",
"name": "Black kiteshield (t)"
},
{
"examine": "A finely balanced throwing knife.",
"id": 869,
"members": true,
"lowalch": 7,
"limit": 7000,
"value": 19,
"highalch": 11,
"icon": "Black knife.png",
"name": "Black knife"
},
{
"examine": "A finely balanced throwing knife.",
"id": 874,
"members": true,
"lowalch": 7,
"limit": 7000,
"value": 18,
"highalch": 10,
"icon": "Black knife(p).png",
"name": "Black knife(p)"
},
{
"examine": "A finely balanced throwing knife.",
"id": 5658,
"members": true,
"lowalch": 7,
"limit": 7000,
"value": 18,
"highalch": 10,
"icon": "Black knife(p+).png",
"name": "Black knife(p+)"
},
{
"examine": "A finely balanced throwing knife.",
"id": 5665,
"members": true,
"lowalch": 7,
"limit": 7000,
"value": 18,
"highalch": 10,
"icon": "Black knife(p++).png",
"name": "Black knife(p++)"
},
{
"examine": "Try as they will, and try as they might, who steals me gold won't live through the night.",
"id": 20246,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Black leprechaun hat.png",
"name": "Black leprechaun hat"
},
{
"examine": "Perhaps someone can do something with this.",
"id": 25448,
"members": true,
"lowalch": 1280,
"limit": 8,
"value": 3200,
"highalch": 1920,
"icon": "Black locks.png",
"name": "Black locks"
},
{
"examine": "A razor sharp longsword.",
"id": 1297,
"members": false,
"lowalch": 384,
"limit": 125,
"value": 960,
"highalch": 576,
"icon": "Black longsword.png",
"name": "Black longsword"
},
{
"examine": "A spiky mace.",
"id": 1426,
"members": false,
"lowalch": 172,
"limit": 125,
"value": 432,
"highalch": 259,
"icon": "Black mace.png",
"name": "Black mace"
},
{
"examine": "An inert-seeming cave horror mask.",
"id": 8921,
"members": true,
"lowalch": 1200,
"limit": 70,
"value": 3000,
"highalch": 1800,
"icon": "Black mask.png",
"name": "Black mask"
},
{
"examine": "A magic cave horror mask.",
"id": 8901,
"members": true,
"lowalch": 1200,
"limit": 70,
"value": 3000,
"highalch": 1800,
"icon": "Black mask (10).png",
"name": "Black mask (10)"
},
{
"examine": "A medium sized helmet.",
"id": 1151,
"members": false,
"lowalch": 230,
"limit": 125,
"value": 576,
"highalch": 345,
"icon": "Black med helm.png",
"name": "Black med helm"
},
{
"examine": "Keeps things in place fairly permanently.",
"id": 4821,
"members": true,
"lowalch": 4,
"limit": 13000,
"value": 12,
"highalch": 7,
"icon": "Black nails.png",
"name": "Black nails"
},
{
"examine": "Used for mining.",
"id": 12297,
"members": false,
"lowalch": 58,
"limit": 4,
"value": 145,
"highalch": 87,
"icon": "Black pickaxe.png",
"name": "Black pickaxe"
},
{
"examine": "Provides excellent protection.",
"id": 1125,
"members": false,
"lowalch": 1536,
"limit": 125,
"value": 3840,
"highalch": 2304,
"icon": "Black platebody.png",
"name": "Black platebody"
},
{
"examine": "Black platebody with gold trim.",
"id": 2591,
"members": false,
"lowalch": 1536,
"limit": 8,
"value": 3840,
"highalch": 2304,
"icon": "Black platebody (g).png",
"name": "Black platebody (g)"
},
{
"examine": "Black plate body with a heraldic design.",
"id": 23366,
"members": false,
"lowalch": 1536,
"limit": 8,
"value": 3840,
"highalch": 2304,
"icon": "Black platebody (h1).png",
"name": "Black platebody (h1)"
},
{
"examine": "Black plate body with a heraldic design.",
"id": 23369,
"members": false,
"lowalch": 1536,
"limit": 8,
"value": 3840,
"highalch": 2304,
"icon": "Black platebody (h2).png",
"name": "Black platebody (h2)"
},
{
"examine": "Black plate body with a heraldic design.",
"id": 23372,
"members": false,
"lowalch": 1536,
"limit": 8,
"value": 3840,
"highalch": 2304,
"icon": "Black platebody (h3).png",
"name": "Black platebody (h3)"
},
{
"examine": "Black plate body with a heraldic design.",
"id": 23375,
"members": false,
"lowalch": 1536,
"limit": 8,
"value": 3840,
"highalch": 2304,
"icon": "Black platebody (h4).png",
"name": "Black platebody (h4)"
},
{
"examine": "Black plate body with a heraldic design.",
"id": 23378,
"members": false,
"lowalch": 1536,
"limit": 8,
"value": 3840,
"highalch": 2304,
"icon": "Black platebody (h5).png",
"name": "Black platebody (h5)"
},
{
"examine": "Black platebody with trim.",
"id": 2583,
"members": false,
"lowalch": 1536,
"limit": 8,
"value": 3840,
"highalch": 2304,
"icon": "Black platebody (t).png",
"name": "Black platebody (t)"
},
{
"examine": "Big, black and heavy looking.",
"id": 1077,
"members": false,
"lowalch": 768,
"limit": 125,
"value": 1920,
"highalch": 1152,
"icon": "Black platelegs.png",
"name": "Black platelegs"
},
{
"examine": "Black platelegs with gold trim.",
"id": 2593,
"members": false,
"lowalch": 768,
"limit": 8,
"value": 1920,
"highalch": 1152,
"icon": "Black platelegs (g).png",
"name": "Black platelegs (g)"
},
{
"examine": "Black platelegs with trim.",
"id": 2585,
"members": false,
"lowalch": 768,
"limit": 8,
"value": 1920,
"highalch": 1152,
"icon": "Black platelegs (t).png",
"name": "Black platelegs (t)"
},
{
"examine": "Big, black and heavy looking.",
"id": 1089,
"members": false,
"lowalch": 768,
"limit": 125,
"value": 1920,
"highalch": 1152,
"icon": "Black plateskirt.png",
"name": "Black plateskirt"
},
{
"examine": "Black plateskirt with gold trim.",
"id": 3473,
"members": false,
"lowalch": 768,
"limit": 8,
"value": 1920,
"highalch": 1152,
"icon": "Black plateskirt (g).png",
"name": "Black plateskirt (g)"
},
{
"examine": "Black plateskirt with trim.",
"id": 3472,
"members": false,
"lowalch": 768,
"limit": 8,
"value": 1920,
"highalch": 1152,
"icon": "Black plateskirt (t).png",
"name": "Black plateskirt (t)"
},
{
"examine": "I can do magic better in this.",
"id": 581,
"members": false,
"lowalch": 5,
"limit": 125,
"value": 13,
"highalch": 7,
"icon": "Black robe.png",
"name": "Black robe"
},
{
"examine": "Slightly slimy and somewhat menacing.",
"id": 10148,
"members": true,
"lowalch": 80,
"limit": 125,
"value": 200,
"highalch": 120,
"icon": "Black salamander.png",
"name": "Black salamander"
},
{
"examine": "A vicious, curved sword.",
"id": 1327,
"members": false,
"lowalch": 307,
"limit": 125,
"value": 768,
"highalch": 460,
"icon": "Black scimitar.png",
"name": "Black scimitar"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 12988,
"members": false,
"lowalch": 1800,
"limit": 8,
"value": 4500,
"highalch": 2700,
"icon": "Black set (lg).png",
"name": "Black set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 12990,
"members": false,
"lowalch": 1800,
"limit": 8,
"value": 4500,
"highalch": 2700,
"icon": "Black set (sk).png",
"name": "Black set (sk)"
},
{
"examine": "A shield with a heraldic design",
"id": 7332,
"members": false,
"lowalch": 652,
"limit": 70,
"value": 1632,
"highalch": 979,
"icon": "Black shield (h1).png",
"name": "Black shield (h1)"
},
{
"examine": "A shield with a heraldic design",
"id": 7338,
"members": false,
"lowalch": 652,
"limit": 70,
"value": 1632,
"highalch": 979,
"icon": "Black shield (h2).png",
"name": "Black shield (h2)"
},
{
"examine": "A shield with a heraldic design",
"id": 7344,
"members": false,
"lowalch": 652,
"limit": 70,
"value": 1632,
"highalch": 979,
"icon": "Black shield (h3).png",
"name": "Black shield (h3)"
},
{
"examine": "A shield with a heraldic design",
"id": 7350,
"members": false,
"lowalch": 652,
"limit": 70,
"value": 1632,
"highalch": 979,
"icon": "Black shield (h4).png",
"name": "Black shield (h4)"
},
{
"examine": "A shield with a heraldic design",
"id": 7356,
"members": false,
"lowalch": 652,
"limit": 70,
"value": 1632,
"highalch": 979,
"icon": "Black shield (h5).png",
"name": "Black shield (h5)"
},
{
"examine": "Clothing favoured by women and dark wizards.",
"id": 1015,
"members": false,
"lowalch": 1,
"limit": 150,
"value": 2,
"highalch": 1,
"icon": "Black skirt.png",
"name": "Black skirt"
},
{
"examine": "Leg covering favoured by women and wizards. With a colourful trim!",
"id": 12445,
"members": false,
"lowalch": 1,
"limit": 4,
"value": 2,
"highalch": 1,
"icon": "Black skirt (g).png",
"name": "Black skirt (g)"
},
{
"examine": "Leg covering favoured by women and wizards. With a colourful trim!",
"id": 12447,
"members": false,
"lowalch": 1,
"limit": 4,
"value": 2,
"highalch": 1,
"icon": "Black skirt (t).png",
"name": "Black skirt (t)"
},
{
"examine": "A black tipped spear.",
"id": 4580,
"members": true,
"lowalch": 260,
"limit": 8,
"value": 650,
"highalch": 390,
"icon": "Black spear.png",
"name": "Black spear"
},
{
"examine": "A poisoned black tipped spear.",
"id": 4582,
"members": true,
"lowalch": 300,
"limit": 8,
"value": 750,
"highalch": 450,
"icon": "Black spear(p).png",
"name": "Black spear(p)"
},
{
"examine": "A poisoned black tipped spear.",
"id": 5734,
"members": true,
"lowalch": 300,
"limit": 8,
"value": 750,
"highalch": 450,
"icon": "Black spear(p+).png",
"name": "Black spear(p+)"
},
{
"examine": "A poisoned black tipped spear.",
"id": 5736,
"members": true,
"lowalch": 300,
"limit": 8,
"value": 750,
"highalch": 450,
"icon": "Black spear(p++).png",
"name": "Black spear(p++)"
},
{
"examine": "Vambraces made from 100% real dragonhide. Now with added spikiness.",
"id": 10085,
"members": true,
"lowalch": 1728,
"limit": 125,
"value": 4320,
"highalch": 2592,
"icon": "Black spiky vambraces.png",
"name": "Black spiky vambraces"
},
{
"examine": "A medium square shield.",
"id": 1179,
"members": false,
"lowalch": 460,
"limit": 125,
"value": 1152,
"highalch": 691,
"icon": "Black sq shield.png",
"name": "Black sq shield"
},
{
"examine": "A razor sharp sword.",
"id": 1283,
"members": false,
"lowalch": 249,
"limit": 125,
"value": 624,
"highalch": 374,
"icon": "Black sword.png",
"name": "Black sword"
},
{
"examine": "The blackened heart of the Grotesque Guardians.",
"id": 21730,
"members": true,
"lowalch": 16600,
"limit": 5,
"value": 41500,
"highalch": 24900,
"icon": "Black tourmaline core.png",
"name": "Black tourmaline core"
},
{
"examine": "A black toy horse.",
"id": 2524,
"members": false,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Black toy horsey.png",
"name": "Black toy horsey"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 12992,
"members": false,
"lowalch": 1800,
"limit": 8,
"value": 4500,
"highalch": 2700,
"icon": "Black trimmed set (lg).png",
"name": "Black trimmed set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 12994,
"members": false,
"lowalch": 1800,
"limit": 8,
"value": 4500,
"highalch": 2700,
"icon": "Black trimmed set (sk).png",
"name": "Black trimmed set (sk)"
},
{
"examine": "Pointy shadows.",
"id": 20266,
"members": true,
"lowalch": 400,
"limit": 4,
"value": 1000,
"highalch": 600,
"icon": "Black unicorn mask.png",
"name": "Black unicorn mask"
},
{
"examine": "I don't think it's intended for joinery.",
"id": 1341,
"members": false,
"lowalch": 392,
"limit": 125,
"value": 980,
"highalch": 588,
"icon": "Black warhammer.png",
"name": "Black warhammer"
},
{
"examine": "There's a black warlock butterfly in here.",
"id": 10014,
"members": true,
"lowalch": 28,
"limit": 125,
"value": 70,
"highalch": 42,
"icon": "Black warlock (item).png",
"name": "Black warlock (item)"
},
{
"highalch": 42,
"members": true,
"name": "Black warlock mix (1)",
"examine": "There's a black warlock butterfly in here.",
"id": 29207,
"value": 70,
"icon": "Black warlock mix (1).png",
"lowalch": 28
},
{
"highalch": 42,
"members": true,
"name": "Black warlock mix (2)",
"examine": "There's a black warlock butterfly in here.",
"id": 29189,
"value": 70,
"icon": "Black warlock mix (2).png",
"lowalch": 28
},
{
"examine": "A silly pointed hat, with colourful trim.",
"id": 12453,
"members": false,
"lowalch": 1,
"limit": 4,
"value": 2,
"highalch": 1,
"icon": "Black wizard hat (g).png",
"name": "Black wizard hat (g)"
},
{
"examine": "A silly pointed hat, with colourful trim.",
"id": 12455,
"members": false,
"lowalch": 1,
"limit": 4,
"value": 2,
"highalch": 1,
"icon": "Black wizard hat (t).png",
"name": "Black wizard hat (t)"
},
{
"examine": "I can do magic better in this.",
"id": 12449,
"members": false,
"lowalch": 6,
"limit": 4,
"value": 15,
"highalch": 9,
"icon": "Black wizard robe (g).png",
"name": "Black wizard robe (g)"
},
{
"examine": "I can do magic better in this.",
"id": 12451,
"members": false,
"lowalch": 6,
"limit": 4,
"value": 15,
"highalch": 9,
"icon": "Black wizard robe (t).png",
"name": "Black wizard robe (t)"
},
{
"examine": "A helm worn by blacksmiths.",
"id": 19988,
"members": true,
"lowalch": 1200,
"limit": 125,
"value": 3000,
"highalch": 1800,
"icon": "Blacksmith's helm.png",
"name": "Blacksmith's helm"
},
{
"examine": "A magical elven sword.",
"id": 23997,
"members": true,
"lowalch": 2000000,
"limit": 8,
"value": 5000000,
"highalch": 3000000,
"icon": "Blade of saeldor (inactive).png",
"name": "Blade of saeldor (inactive)"
},
{
"examine": "A large bark coloured blamish snail shell, looks protective.",
"id": 3353,
"members": true,
"lowalch": 60,
"limit": 13000,
"value": 150,
"highalch": 90,
"icon": "Blamish bark shell.png",
"name": "Blamish bark shell"
},
{
"examine": "A large blue coloured blamish snail shell, looks protective.",
"id": 3361,
"members": true,
"lowalch": 60,
"limit": 13000,
"value": 150,
"highalch": 90,
"icon": "Blamish blue shell (pointed).png",
"name": "Blamish blue shell (pointed)"
},
{
"examine": "A large blue coloured blamish snail shell, looks protective.",
"id": 3351,
"members": true,
"lowalch": 60,
"limit": 13000,
"value": 150,
"highalch": 90,
"icon": "Blamish blue shell (round).png",
"name": "Blamish blue shell (round)"
},
{
"examine": "A large 'Myre' coloured blamish snail shell, looks protective.",
"id": 3355,
"members": true,
"lowalch": 60,
"limit": 13000,
"value": 150,
"highalch": 90,
"icon": "Blamish myre shell (pointed).png",
"name": "Blamish myre shell (pointed)"
},
{
"examine": "A large 'Myre' coloured blamish snail shell, looks protective.",
"id": 3345,
"members": true,
"lowalch": 60,
"limit": 13000,
"value": 150,
"highalch": 90,
"icon": "Blamish myre shell (round).png",
"name": "Blamish myre shell (round)"
},
{
"examine": "A large ochre coloured blamish snail shell, looks protective.",
"id": 3359,
"members": true,
"lowalch": 60,
"limit": 13000,
"value": 150,
"highalch": 90,
"icon": "Blamish ochre shell (pointed).png",
"name": "Blamish ochre shell (pointed)"
},
{
"examine": "A large ochre coloured blamish snail shell, looks protective.",
"id": 3349,
"members": true,
"lowalch": 60,
"limit": 13000,
"value": 150,
"highalch": 90,
"icon": "Blamish ochre shell (round).png",
"name": "Blamish ochre shell (round)"
},
{
"examine": "A large red and black blamish snail shell, looks protective.",
"id": 3357,
"members": true,
"lowalch": 60,
"limit": 13000,
"value": 150,
"highalch": 90,
"icon": "Blamish red shell (pointed).png",
"name": "Blamish red shell (pointed)"
},
{
"examine": "A large red and black blamish snail shell, looks protective.",
"id": 3347,
"members": true,
"lowalch": 60,
"limit": 13000,
"value": 150,
"highalch": 90,
"icon": "Blamish red shell (round).png",
"name": "Blamish red shell (round)"
},
{
"examine": "An ethereal shield that has been blessed with holy powers.",
"id": 12831,
"members": true,
"lowalch": 480000,
"limit": 8,
"value": 1200000,
"highalch": 720000,
"icon": "Blessed spirit shield.png",
"name": "Blessed spirit shield"
},
{
"examine": "Enough power for any Ice spell, blighted so it can be used only in the Wilderness.",
"id": 24607,
"members": true,
"lowalch": 1,
"limit": 15000,
"value": 1,
"highalch": 1,
"icon": "Blighted ancient ice sack.png",
"name": "Blighted ancient ice sack"
},
{
"examine": "An unappetising fish, now blighted so it can be eaten only in the Wilderness.",
"id": 24592,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Blighted anglerfish.png",
"name": "Blighted anglerfish"
},
{
"examine": "Enough power for a Bind, blighted so it can be used only in the Wilderness.",
"id": 24609,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Blighted bind sack.png",
"name": "Blighted bind sack"
},
{
"examine": "Enough power for an Entangle, blighted so it can be used only in the Wilderness.",
"id": 24613,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Blighted entangle sack.png",
"name": "Blighted entangle sack"
},
{
"examine": "A nutritious octopus, now blighted so it can be eaten only in the Wilderness.",
"id": 24595,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Blighted karambwan.png",
"name": "Blighted karambwan"
},
{
"examine": "A rare catch, now blighted so it can be eaten only in the Wilderness.",
"id": 24589,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Blighted manta ray.png",
"name": "Blighted manta ray"
},
{
"examine": "Enough power for a Snare, blighted so it can be used only in the Wilderness.",
"id": 24611,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Blighted snare sack.png",
"name": "Blighted snare sack"
},
{
"examine": "4 doses of super restore potion, blighted so it can be used only in the Wilderness.",
"id": 24598,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Blighted super restore(4).png",
"name": "Blighted super restore(4)"
},
{
"examine": "Enough power for a Teleblock or a Teleport to Target, blighted so it can be used only in the Wilderness.",
"id": 24615,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Blighted teleport spell sack.png",
"name": "Blighted teleport spell sack"
},
{
"examine": "Enough power for a Vengeance, blighted so it can be used only in the Wilderness.",
"id": 24621,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Blighted vengeance sack.png",
"name": "Blighted vengeance sack"
},
{
"examine": "A blood-filled essence with magical properties.",
"id": 26390,
"members": true,
"lowalch": 40000,
"limit": 500,
"value": 100000,
"highalch": 60000,
"icon": "Blood essence.png",
"name": "Blood essence"
},
{
"highalch": 174006,
"members": true,
"name": "Blood moon chestplate",
"examine": "The chestplate of the Blood Moon.",
"id": 29022,
"value": 290010,
"icon": "Blood moon chestplate.png",
"lowalch": 116004
},
{
"highalch": 174006,
"members": true,
"name": "Blood moon chestplate (broken)",
"examine": "The chestplate of the Blood Moon.",
"id": 29067,
"value": 290010,
"icon": "Blood moon chestplate (broken).png",
"lowalch": 116004
},
{
"highalch": 61800,
"members": true,
"name": "Blood moon helm",
"examine": "The helm of the Blood Moon.",
"id": 29028,
"value": 103000,
"icon": "Blood moon helm.png",
"lowalch": 41200
},
{
"highalch": 61800,
"members": true,
"name": "Blood moon helm (broken)",
"examine": "The helm of the Blood Moon.",
"id": 29073,
"value": 103000,
"icon": "Blood moon helm (broken).png",
"lowalch": 41200
},
{
"highalch": 173946,
"members": true,
"name": "Blood moon tassets",
"examine": "The tassets of the Blood Moon.",
"id": 29025,
"value": 289910,
"icon": "Blood moon tassets.png",
"lowalch": 115964
},
{
"highalch": 173946,
"members": true,
"name": "Blood moon tassets (broken)",
"examine": "The tassets of the Blood Moon.",
"id": 29070,
"value": 289910,
"icon": "Blood moon tassets (broken).png",
"lowalch": 115964
},
{
"examine": "It's literally a pint of blood.",
"id": 24774,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Blood pint.png",
"name": "Blood pint"
},
{
"examine": "Used for high level missile spells.",
"id": 565,
"members": true,
"lowalch": 160,
"limit": 25000,
"value": 400,
"highalch": 240,
"icon": "Blood rune.png",
"name": "Blood rune"
},
{
"examine": "A magical shard filled with a dark power.",
"id": 24777,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 1,
"highalch": 1,
"icon": "Blood shard.png",
"name": "Blood shard"
},
{
"highalch": 1,
"members": true,
"name": "Blood talisman",
"examine": "A mysterious power emanates from the talisman...",
"id": 1450,
"value": 1,
"icon": "Blood talisman.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Blood tiara",
"examine": "A tiara infused with the properties of blood.",
"id": 5549,
"value": 1,
"icon": "Blood tiara.png",
"lowalch": 1
},
{
"examine": "A red and black pointed snail shell helmet.",
"id": 3339,
"members": true,
"lowalch": 120,
"limit": 150,
"value": 300,
"highalch": 180,
"icon": "Blood'n'tar snelm (pointed).png",
"name": "Blood'n'tar snelm (pointed)"
},
{
"examine": "A red and black Snail shell helmet.",
"id": 3329,
"members": true,
"lowalch": 120,
"limit": 150,
"value": 300,
"highalch": 180,
"icon": "Blood'n'tar snelm (round).png",
"name": "Blood'n'tar snelm (round)"
},
{
"examine": "Keeps my chest protected.",
"id": 25404,
"members": true,
"lowalch": 1,
"limit": 70,
"value": 1,
"highalch": 1,
"icon": "Bloodbark body.png",
"name": "Bloodbark body"
},
{
"highalch": 1,
"members": true,
"name": "Bloodbark boots",
"examine": "Great foot protection.",
"id": 25410,
"value": 1,
"icon": "Bloodbark boots.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Bloodbark gauntlets",
"examine": "Thse should protect my hands.",
"id": 25407,
"value": 1,
"icon": "Bloodbark gauntlets.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Bloodbark helm",
"examine": "A rather interesting smelling helmet.",
"id": 25413,
"value": 1,
"icon": "Bloodbark helm.png",
"lowalch": 1
},
{
"examine": "Strong leg protection.",
"id": 25416,
"members": true,
"lowalch": 1,
"limit": 70,
"value": 1,
"highalch": 1,
"icon": "Bloodbark legs.png",
"name": "Bloodbark legs"
},
{
"examine": "A coagulated concoction.",
"id": 22430,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 2,
"highalch": 1,
"icon": "Bloody bracer.png",
"name": "Bloody bracer"
},
{
"examine": "Parlez-vous francais?",
"id": 2633,
"members": true,
"lowalch": 32,
"limit": 4,
"value": 80,
"highalch": 48,
"icon": "Blue beret.png",
"name": "Blue beret"
},
{
"examine": "Stylish!",
"id": 7325,
"members": true,
"lowalch": 90,
"limit": 4,
"value": 225,
"highalch": 135,
"icon": "Blue boater.png",
"name": "Blue boater"
},
{
"examine": "They're soft, silky and blue.",
"id": 630,
"members": true,
"lowalch": 80,
"limit": 150,
"value": 200,
"highalch": 120,
"icon": "Blue boots.png",
"name": "Blue boots"
},
{
"examine": "A thick blue cape.",
"id": 1021,
"members": false,
"lowalch": 12,
"limit": 150,
"value": 32,
"highalch": 19,
"icon": "Blue cape.png",
"name": "Blue cape"
},
{
"examine": "Made from 100% real dragonhide.",
"id": 2499,
"members": true,
"lowalch": 3744,
"limit": 125,
"value": 9360,
"highalch": 5616,
"icon": "Blue d'hide body.png",
"name": "Blue d'hide body"
},
{
"examine": "Made from 100% real dragonhide. With colourful trim!",
"id": 7374,
"members": true,
"lowalch": 3744,
"limit": 8,
"value": 9360,
"highalch": 5616,
"icon": "Blue d'hide body (g).png",
"name": "Blue d'hide body (g)"
},
{
"examine": "Made from 100% real dragonhide. With colourful trim!",
"id": 7376,
"members": true,
"lowalch": 3744,
"limit": 8,
"value": 9360,
"highalch": 5616,
"icon": "Blue d'hide body (t).png",
"name": "Blue d'hide body (t)"
},
{
"examine": "Made from 100% real dragonhide.",
"id": 2493,
"members": true,
"lowalch": 1728,
"limit": 125,
"value": 4320,
"highalch": 2592,
"icon": "Blue d'hide chaps.png",
"name": "Blue d'hide chaps"
},
{
"examine": "Made from 100% real dragonhide. With colourful trim!",
"id": 7382,
"members": true,
"lowalch": 1728,
"limit": 8,
"value": 4320,
"highalch": 2592,
"icon": "Blue d'hide chaps (g).png",
"name": "Blue d'hide chaps (g)"
},
{
"examine": "Made from 100% real dragonhide. With colourful trim!",
"id": 7384,
"members": true,
"lowalch": 1728,
"limit": 8,
"value": 4320,
"highalch": 2592,
"icon": "Blue d'hide chaps (t).png",
"name": "Blue d'hide chaps (t)"
},
{
"examine": "A solid yew shield covered in blue dragon leather.",
"id": 22278,
"members": true,
"lowalch": 3000,
"limit": 125,
"value": 7500,
"highalch": 4500,
"icon": "Blue d'hide shield.png",
"name": "Blue d'hide shield"
},
{
"examine": "Vambraces made from 100% real dragonhide.",
"id": 2487,
"members": true,
"lowalch": 1200,
"limit": 125,
"value": 3000,
"highalch": 1800,
"icon": "Blue d'hide vambraces.png",
"name": "Blue d'hide vambraces"
},
{
"examine": "Paints things blue!",
"id": 12757,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Blue dark bow paint.png",
"name": "Blue dark bow paint"
},
{
"examine": "It's a piece of prepared blue dragonhide.",
"id": 2505,
"members": true,
"lowalch": 28,
"limit": 13000,
"value": 70,
"highalch": 42,
"icon": "Blue dragon leather.png",
"name": "Blue dragon leather"
},
{
"examine": "Do I look scary?",
"id": 12520,
"members": true,
"lowalch": 4000,
"limit": 4,
"value": 10000,
"highalch": 6000,
"icon": "Blue dragon mask.png",
"name": "Blue dragon mask"
},
{
"examine": "A large shiny scale.",
"id": 243,
"members": true,
"lowalch": 20,
"limit": 13000,
"value": 50,
"highalch": 30,
"icon": "Blue dragon scale.png",
"name": "Blue dragon scale"
},
{
"examine": "The scaly rough hide from a Blue Dragon.",
"id": 1751,
"members": true,
"lowalch": 56,
"limit": 13000,
"value": 140,
"highalch": 84,
"icon": "Blue dragonhide.png",
"name": "Blue dragonhide"
},
{
"examine": "A set containing a blue dragonhide body, chaps and vambraces.",
"id": 12867,
"members": true,
"lowalch": 2000,
"limit": 125,
"value": 5000,
"highalch": 3000,
"icon": "Blue dragonhide set.png",
"name": "Blue dragonhide set"
},
{
"examine": "A little bottle of blue dye.",
"id": 1767,
"members": false,
"lowalch": 2,
"limit": 150,
"value": 5,
"highalch": 3,
"icon": "Blue dye.png",
"name": "Blue dye"
},
{
"examine": "A well made elegant ladies' blue blouse.",
"id": 10428,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Blue elegant blouse.png",
"name": "Blue elegant blouse"
},
{
"examine": "A rather elegant pair of men's blue pantaloons.",
"id": 10410,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Blue elegant legs.png",
"name": "Blue elegant legs"
},
{
"examine": "A well made elegant men's blue shirt.",
"id": 10408,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Blue elegant shirt.png",
"name": "Blue elegant shirt"
},
{
"examine": "A rather elegant blue skirt.",
"id": 10430,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Blue elegant skirt.png",
"name": "Blue elegant skirt"
},
{
"examine": "A cool blue feather.",
"id": 10089,
"members": true,
"lowalch": 6,
"limit": 8000,
"value": 16,
"highalch": 9,
"icon": "Blue feather.png",
"name": "Blue feather"
},
{
"examine": "Makes firelighting a lot easier.",
"id": 7331,
"members": true,
"lowalch": 6,
"limit": 250,
"value": 15,
"highalch": 9,
"icon": "Blue firelighter.png",
"name": "Blue firelighter"
},
{
"examine": "A posy of flowers.",
"id": 2464,
"members": true,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Blue flowers.png",
"name": "Blue flowers"
},
{
"examine": "Aaaarrrghhh ... I'm a monster.",
"id": 1055,
"members": false,
"lowalch": 6,
"limit": 5,
"value": 15,
"highalch": 9,
"icon": "Blue halloween mask.png",
"name": "Blue halloween mask"
},
{
"examine": "A silly blue pointed hat.",
"id": 660,
"members": true,
"lowalch": 64,
"limit": 150,
"value": 160,
"highalch": 96,
"icon": "Blue hat.png",
"name": "Blue hat"
},
{
"examine": "A minimalist's hat.",
"id": 12301,
"members": true,
"lowalch": 16,
"limit": 4,
"value": 40,
"highalch": 24,
"icon": "Blue headband.png",
"name": "Blue headband"
},
{
"highalch": 174006,
"members": true,
"name": "Blue moon chestplate",
"examine": "The chestplate of the Blue Moon.",
"id": 29013,
"value": 290010,
"icon": "Blue moon chestplate.png",
"lowalch": 116004
},
{
"highalch": 174006,
"members": true,
"name": "Blue moon chestplate (broken)",
"examine": "The chestplate of the Blue Moon.",
"id": 29058,
"value": 290010,
"icon": "Blue moon chestplate (broken).png",
"lowalch": 116004
},
{
"highalch": 61800,
"members": true,
"name": "Blue moon helm",
"examine": "The helm of the Blue Moon.",
"id": 29019,
"value": 103000,
"icon": "Blue moon helm.png",
"lowalch": 41200
},
{
"highalch": 61800,
"members": true,
"name": "Blue moon helm (broken)",
"examine": "The helm of the Blue Moon.",
"id": 29064,
"value": 103000,
"icon": "Blue moon helm (broken).png",
"lowalch": 41200
},
{
"highalch": 60000,
"members": true,
"name": "Blue moon spear",
"examine": "A magical staff of ancient origin...",
"id": 28988,
"value": 100000,
"icon": "Blue moon spear.png",
"lowalch": 40000
},
{
"highalch": 173946,
"members": true,
"name": "Blue moon tassets",
"examine": "The tassets of the Blue Moon.",
"id": 29016,
"value": 289910,
"icon": "Blue moon tassets.png",
"lowalch": 115964
},
{
"highalch": 173946,
"members": true,
"name": "Blue moon tassets (broken)",
"examine": "The tassets of the Blue Moon.",
"id": 29061,
"value": 289910,
"icon": "Blue moon tassets (broken).png",
"lowalch": 115964
},
{
"examine": "A nice hat from a cracker.",
"id": 1042,
"members": false,
"lowalch": 1,
"limit": 5,
"value": 1,
"highalch": 1,
"icon": "Blue partyhat.png",
"name": "Blue partyhat"
},
{
"examine": "Made by Tree Gnomes with a thing for blue.",
"id": 650,
"members": true,
"lowalch": 72,
"limit": 150,
"value": 180,
"highalch": 108,
"icon": "Blue robe bottoms.png",
"name": "Blue robe bottoms"
},
{
"examine": "The ultimate in gnome design, now in blue!",
"id": 640,
"members": true,
"lowalch": 72,
"limit": 150,
"value": 180,
"highalch": 108,
"icon": "Blue robe top.png",
"name": "Blue robe top"
},
{
"examine": "Leg covering favoured by women and wizards.",
"id": 1011,
"members": false,
"lowalch": 1,
"limit": 150,
"value": 2,
"highalch": 1,
"icon": "Blue skirt.png",
"name": "Blue skirt"
},
{
"examine": "Leg covering favoured by women and wizards. With a colourful trim!",
"id": 7386,
"members": false,
"lowalch": 1,
"limit": 4,
"value": 2,
"highalch": 1,
"icon": "Blue skirt (g).png",
"name": "Blue skirt (g)"
},
{
"examine": "Leg covering favoured by women and wizards. With a colourful trim!",
"id": 7388,
"members": false,
"lowalch": 1,
"limit": 4,
"value": 2,
"highalch": 1,
"icon": "Blue skirt (t).png",
"name": "Blue skirt (t)"
},
{
"examine": "Vambraces made from 100% real dragonhide. Now with added spikiness.",
"id": 10081,
"members": true,
"lowalch": 1200,
"limit": 125,
"value": 3000,
"highalch": 1800,
"icon": "Blue spiky vambraces.png",
"name": "Blue spiky vambraces"
},
{
"examine": "A silly pointed hat.",
"id": 579,
"members": false,
"lowalch": 1,
"limit": 125,
"value": 2,
"highalch": 1,
"icon": "Blue wizard hat.png",
"name": "Blue wizard hat"
},
{
"examine": "A silly pointed hat, with colourful trim.",
"id": 7394,
"members": false,
"lowalch": 1,
"limit": 4,
"value": 2,
"highalch": 1,
"icon": "Blue wizard hat (g).png",
"name": "Blue wizard hat (g)"
},
{
"examine": "A silly pointed hat, with colourful trim.",
"id": 7396,
"members": false,
"lowalch": 1,
"limit": 4,
"value": 2,
"highalch": 1,
"icon": "Blue wizard hat (t).png",
"name": "Blue wizard hat (t)"
},
{
"examine": "I can do magic better in this.",
"id": 577,
"members": false,
"lowalch": 6,
"limit": 125,
"value": 15,
"highalch": 9,
"icon": "Blue wizard robe.png",
"name": "Blue wizard robe"
},
{
"examine": "I can do magic better in this.",
"id": 7390,
"members": false,
"lowalch": 6,
"limit": 4,
"value": 15,
"highalch": 9,
"icon": "Blue wizard robe (g).png",
"name": "Blue wizard robe (g)"
},
{
"examine": "I can do magic better in this.",
"id": 7392,
"members": false,
"lowalch": 6,
"limit": 4,
"value": 15,
"highalch": 9,
"icon": "Blue wizard robe (t).png",
"name": "Blue wizard robe (t)"
},
{
"examine": "A large Bluegill.",
"id": 22826,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 3,
"highalch": 1,
"icon": "Bluegill.png",
"name": "Bluegill"
},
{
"examine": "Looks good... smells strong.",
"id": 2064,
"members": true,
"lowalch": 12,
"limit": 2000,
"value": 30,
"highalch": 18,
"icon": "Blurberry special.png",
"name": "Blurberry special"
},
{
"examine": "'Bob says: A bank pin will keep your items secure.'",
"id": 10322,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 3,
"highalch": 1,
"icon": "Bob's black shirt.png",
"name": "Bob's black shirt"
},
{
"examine": "'Bob says: Always check the second trade screen.'",
"id": 10318,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 3,
"highalch": 1,
"icon": "Bob's blue shirt.png",
"name": "Bob's blue shirt"
},
{
"examine": "'Bob says: Never trade in the wilderness!'",
"id": 10320,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 3,
"highalch": 1,
"icon": "Bob's green shirt.png",
"name": "Bob's green shirt"
},
{
"examine": "'Bob says: Keep your computer keylogger free and virus scanned.'",
"id": 10324,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 3,
"highalch": 1,
"icon": "Bob's purple shirt.png",
"name": "Bob's purple shirt"
},
{
"examine": "'Bob says: Never give your password out to anyone.'",
"id": 10316,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 3,
"highalch": 1,
"icon": "Bob's red shirt.png",
"name": "Bob's red shirt"
},
{
"examine": "Used for curse spells.",
"id": 559,
"members": false,
"lowalch": 1,
"limit": 18000,
"value": 3,
"highalch": 1,
"icon": "Body rune.png",
"name": "Body rune"
},
{
"examine": "A mysterious power emanates from the talisman...",
"id": 1446,
"members": false,
"lowalch": 1,
"limit": 11000,
"value": 4,
"highalch": 2,
"icon": "Body talisman.png",
"name": "Body talisman"
},
{
"examine": "A tiara infused with the properties of the body.",
"id": 5533,
"members": false,
"lowalch": 40,
"limit": 40,
"value": 100,
"highalch": 60,
"icon": "Body tiara.png",
"name": "Body tiara"
},
{
"examine": "A mould for creating silver crossbow bolts.",
"id": 9434,
"members": true,
"lowalch": 10,
"limit": 40,
"value": 25,
"highalch": 15,
"icon": "Bolt mould.png",
"name": "Bolt mould"
},
{
"examine": "A bolt of ordinary cloth.",
"id": 8790,
"members": true,
"lowalch": 200,
"limit": 13000,
"value": 500,
"highalch": 300,
"icon": "Bolt of cloth.png",
"name": "Bolt of cloth"
},
{
"examine": "Must need a special type of crossbow to use this.",
"id": 4740,
"members": true,
"lowalch": 20,
"limit": 11000,
"value": 50,
"highalch": 30,
"icon": "Bolt rack 5.png",
"name": "Bolt rack"
},
{
"examine": "Good if you have a bone crossbow!",
"id": 8882,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 3,
"highalch": 1,
"icon": "Bone bolts 5.png",
"name": "Bone bolts"
},
{
"examine": "Basic but brutal!",
"id": 5018,
"members": true,
"lowalch": 240,
"limit": 4,
"value": 600,
"highalch": 360,
"icon": "Bone club.png",
"name": "Bone club"
},
{
"examine": "A powerful dagger.",
"id": 8872,
"members": true,
"lowalch": 800,
"limit": 70,
"value": 2000,
"highalch": 1200,
"icon": "Bone dagger.png",
"name": "Bone dagger"
},
{
"examine": "A powerful dagger.",
"id": 8874,
"members": true,
"lowalch": 800,
"limit": 70,
"value": 2000,
"highalch": 1200,
"icon": "Bone dagger (p).png",
"name": "Bone dagger (p)"
},
{
"examine": "A powerful dagger.",
"id": 8876,
"members": true,
"lowalch": 800,
"limit": 70,
"value": 2000,
"highalch": 1200,
"icon": "Bone dagger (p+).png",
"name": "Bone dagger (p+)"
},
{
"examine": "A powerful dagger.",
"id": 8878,
"members": true,
"lowalch": 800,
"limit": 70,
"value": 2000,
"highalch": 1200,
"icon": "Bone dagger (p++).png",
"name": "Bone dagger (p++)"
},
{
"examine": "Ground down bone fragments.",
"id": 25139,
"members": false,
"lowalch": 1,
"limit": 100,
"value": 1,
"highalch": 1,
"icon": "Bone fragments 5.png",
"name": "Bone fragments"
},
{
"examine": "Basic but brutal!",
"id": 5016,
"members": true,
"lowalch": 240,
"limit": 4,
"value": 600,
"highalch": 360,
"icon": "Bone spear.png",
"name": "Bone spear"
},
{
"examine": "Bones are for burying!",
"id": 526,
"members": false,
"lowalch": 1,
"limit": 3000,
"value": 1,
"highalch": 1,
"icon": "Bones.png",
"name": "Bones"
},
{
"examine": "A tablet containing a magic spell.",
"id": 8014,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Bones to bananas (tablet).png",
"name": "Bones to bananas (tablet)"
},
{
"examine": "A tablet containing a magic spell.",
"id": 8015,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Bones to peaches (tablet).png",
"name": "Bones to peaches (tablet)"
},
{
"examine": "A set containing the four pages of Guthix's Book of Balance.",
"id": 13153,
"members": true,
"lowalch": 2800,
"limit": 5,
"value": 7000,
"highalch": 4200,
"icon": "Book of balance page set.png",
"name": "Book of balance page set"
},
{
"examine": "A set containing the four pages of the Ancient Book of Darkness.",
"id": 13159,
"members": true,
"lowalch": 3000,
"limit": 5,
"value": 7500,
"highalch": 4500,
"icon": "Book of darkness page set.png",
"name": "Book of darkness page set"
},
{
"examine": "A set containing the four pages of Armadyl's Book of Law.",
"id": 13157,
"members": true,
"lowalch": 3000,
"limit": 5,
"value": 7500,
"highalch": 4500,
"icon": "Book of law page set.png",
"name": "Book of law page set"
},
{
"examine": "A set containing the four pages of Bandos' Book of War.",
"id": 13155,
"members": true,
"lowalch": 3000,
"limit": 5,
"value": 7500,
"highalch": 4500,
"icon": "Book of war page set.png",
"name": "Book of war page set"
},
{
"examine": "How does it all fit in there?",
"id": 8510,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Bookcase (flatpack).png",
"name": "Bookcase (flatpack)"
},
{
"examine": "A pair of heat resistant boots charged with the claw of the ferocious Drake.",
"id": 22951,
"members": true,
"lowalch": 8000,
"limit": 70,
"value": 20000,
"highalch": 12000,
"icon": "Boots of brimstone.png",
"name": "Boots of brimstone"
},
{
"examine": "A dark power is woven into these boots.",
"id": 20140,
"members": true,
"lowalch": 4000,
"limit": 70,
"value": 10000,
"highalch": 6000,
"icon": "Boots of darkness.png",
"name": "Boots of darkness"
},
{
"examine": "A pair of heat resistant boots with rocky soles.",
"id": 23037,
"members": true,
"lowalch": 80,
"limit": 125,
"value": 200,
"highalch": 120,
"icon": "Boots of stone.png",
"name": "Boots of stone"
},
{
"examine": "Mmm botanical pie.",
"id": 19662,
"members": true,
"lowalch": 12,
"limit": 10000,
"value": 30,
"highalch": 18,
"icon": "Botanical pie.png",
"name": "Botanical pie"
},
{
"examine": "A very good vintage.",
"id": 7919,
"members": true,
"lowalch": 200,
"limit": 10000,
"value": 500,
"highalch": 300,
"icon": "Bottle of wine.png",
"name": "Bottle of wine"
},
{
"examine": "Several dragonfruit were squeezed into this vial and it has been powered by the fires of Mount Karuulm.",
"id": 23002,
"members": true,
"lowalch": 200,
"limit": 50,
"value": 500,
"highalch": 300,
"icon": "Bottled dragonbreath.png",
"name": "Bottled dragonbreath"
},
{
"examine": "Several dragonfruit were squeezed into this vial.",
"id": 22999,
"members": true,
"lowalch": 200,
"limit": 50,
"value": 500,
"highalch": 300,
"icon": "Bottled dragonbreath (unpowered).png",
"name": "Bottled dragonbreath (unpowered)"
},
{
"examine": "It's a bucket that can hold a lot of compost at once.",
"id": 22994,
"members": true,
"lowalch": 18000,
"limit": 5,
"value": 45000,
"highalch": 27000,
"icon": "Bottomless compost bucket.png",
"name": "Bottomless compost bucket"
},
{
"highalch": 3000000,
"members": true,
"name": "Bow of faerdhinen (inactive)",
"examine": "A magical elven bow.",
"id": 25862,
"value": 5000000,
"icon": "Bow of faerdhinen (inactive).png",
"lowalch": 2000000
},
{
"examine": "I need a bow stave to attach this to.",
"id": 1777,
"members": true,
"lowalch": 4,
"limit": 13000,
"value": 10,
"highalch": 6,
"icon": "Bow string.png",
"name": "Bow string"
},
{
"examine": "Useful for mixing things.",
"id": 1923,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 4,
"highalch": 2,
"icon": "Bowl.png",
"name": "Bowl"
},
{
"examine": "It's a bowl of hot water.",
"id": 4456,
"members": true,
"lowalch": 4,
"limit": 13000,
"value": 10,
"highalch": 6,
"icon": "Bowl of hot water.png",
"name": "Bowl of hot water"
},
{
"examine": "It's a bowl of water.",
"id": 1921,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 4,
"highalch": 2,
"icon": "Bowl of water.png",
"name": "Bowl of water"
},
{
"examine": "The bowl wig.",
"id": 20110,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Bowl wig.png",
"name": "Bowl wig"
},
{
"examine": "If a creature goes inside, the box should then slam shut.",
"id": 10008,
"members": true,
"lowalch": 12,
"limit": 250,
"value": 32,
"highalch": 19,
"icon": "Box trap.png",
"name": "Box trap"
},
{
"examine": "Used to make gold bracelets.",
"id": 11065,
"members": true,
"lowalch": 2,
"limit": 40,
"value": 5,
"highalch": 3,
"icon": "Bracelet mould.png",
"name": "Bracelet mould"
},
{
"examine": "Now I can become a potter.",
"id": 11074,
"members": true,
"lowalch": 506,
"limit": 10000,
"value": 1265,
"highalch": 759,
"icon": "Bracelet of clay.png",
"name": "Bracelet of clay"
},
{
"examine": "The bracelet is dull and powerless.",
"id": 21817,
"members": true,
"lowalch": 28800,
"limit": 10000,
"value": 72000,
"highalch": 43200,
"icon": "Bracelet of ethereum.png",
"name": "Bracelet of ethereum (uncharged)"
},
{
"examine": "Occasionally prevents slayer kill count being decremented.",
"id": 21183,
"members": true,
"lowalch": 1000,
"limit": 10000,
"value": 2500,
"highalch": 1500,
"icon": "Bracelet of slaughter.png",
"name": "Bracelet of slaughter"
},
{
"examine": "A strong spirit best served in a large glass.",
"id": 2021,
"members": true,
"lowalch": 2,
"limit": 13000,
"value": 5,
"highalch": 3,
"icon": "Brandy.png",
"name": "Brandy"
},
{
"examine": "Opens a door that leads into a dungeon.",
"id": 983,
"members": false,
"lowalch": 1,
"limit": 15,
"value": 1,
"highalch": 1,
"icon": "Brass key.png",
"name": "Brass key"
},
{
"examine": "I'd prefer a gold one.",
"id": 1009,
"members": false,
"lowalch": 12,
"limit": 150,
"value": 30,
"highalch": 18,
"icon": "Brass necklace.png",
"name": "Brass necklace"
},
{
"examine": "Nice crispy bread.",
"id": 2309,
"members": false,
"lowalch": 4,
"limit": 6000,
"value": 12,
"highalch": 7,
"icon": "Bread.png",
"name": "Bread"
},
{
"examine": "Some uncooked dough.",
"id": 2307,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 4,
"highalch": 2,
"icon": "Bread dough.png",
"name": "Bread dough"
},
{
"examine": "For your first day in the big city.",
"id": 12335,
"members": true,
"lowalch": 266,
"limit": 4,
"value": 666,
"highalch": 399,
"icon": "Briefcase.png",
"name": "Briefcase"
},
{
"examine": "A well balanced ring that aids in piercing magic defence.",
"id": 22975,
"members": true,
"lowalch": 4000,
"limit": 8,
"value": 10000,
"highalch": 6000,
"icon": "Brimstone ring.png",
"name": "Brimstone ring"
},
{
"examine": "A salty sword.",
"id": 11037,
"members": true,
"lowalch": 10400,
"limit": 8,
"value": 26000,
"highalch": 15600,
"icon": "Brine sabre.png",
"name": "Brine sabre"
},
{
"examine": "Arrowheads with broad tips.",
"id": 11874,
"members": true,
"lowalch": 22,
"limit": 7000,
"value": 55,
"highalch": 33,
"icon": "Broad arrowheads 5.png",
"name": "Broad arrowheads"
},
{
"examine": "Crossbow bolts with broad tips.",
"id": 11875,
"members": true,
"lowalch": 22,
"limit": 7000,
"value": 55,
"highalch": 33,
"icon": "Broad bolts 5.png",
"name": "Broad bolts"
},
{
"examine": "An orange and bark coloured snail shell helmet.",
"id": 3335,
"members": true,
"lowalch": 120,
"limit": 150,
"value": 300,
"highalch": 180,
"icon": "Broken bark snelm.png",
"name": "Broken bark snelm"
},
{
"examine": "The item to make into a hasta.",
"id": 22963,
"members": true,
"lowalch": 24960,
"limit": 70,
"value": 62400,
"highalch": 37440,
"icon": "Broken dragon hasta.png",
"name": "Broken dragon hasta"
},
{
"highalch": 1,
"members": true,
"name": "Broken zombie axe",
"examine": "An old rusty axe. It needs repairing before it can be used.",
"id": 28813,
"value": 1,
"icon": "Broken zombie axe.png",
"lowalch": 1
},
{
"examine": "A two handed sword.",
"id": 1307,
"members": false,
"lowalch": 32,
"limit": 125,
"value": 80,
"highalch": 48,
"icon": "Bronze 2h sword.png",
"name": "Bronze 2h sword"
},
{
"examine": "Arrows with bronze heads.",
"id": 882,
"members": false,
"lowalch": 1,
"limit": 7000,
"value": 1,
"highalch": 1,
"icon": "Bronze arrow 5.png",
"name": "Bronze arrow"
},
{
"examine": "Venomous-looking arrows.",
"id": 883,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 1,
"highalch": 1,
"icon": "Bronze arrow(p) 5.png",
"name": "Bronze arrow(p)"
},
{
"examine": "Venomous-looking arrows.",
"id": 5616,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 1,
"highalch": 1,
"icon": "Bronze arrow(p+) 5.png",
"name": "Bronze arrow(p+)"
},
{
"examine": "Venomous-looking arrows.",
"id": 5622,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 1,
"highalch": 1,
"icon": "Bronze arrow(p++) 5.png",
"name": "Bronze arrow(p++)"
},
{
"examine": "I can make some arrows with these.",
"id": 39,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Bronze arrowtips.png",
"name": "Bronze arrowtips"
},
{
"examine": "A woodcutter's axe.",
"id": 1351,
"members": false,
"lowalch": 6,
"limit": 40,
"value": 16,
"highalch": 9,
"icon": "Bronze axe.png",
"name": "Bronze axe"
},
{
"examine": "It's a bar of bronze.",
"id": 2349,
"members": false,
"lowalch": 3,
"limit": 10000,
"value": 8,
"highalch": 4,
"icon": "Bronze bar.png",
"name": "Bronze bar"
},
{
"examine": "A vicious looking axe.",
"id": 1375,
"members": false,
"lowalch": 20,
"limit": 125,
"value": 52,
"highalch": 31,
"icon": "Bronze battleaxe.png",
"name": "Bronze battleaxe"
},
{
"examine": "Bronze crossbow bolts.",
"id": 877,
"members": false,
"lowalch": 1,
"limit": 7000,
"value": 1,
"highalch": 1,
"icon": "Bronze bolts 5.png",
"name": "Bronze bolts"
},
{
"examine": "Some poisoned bronze bolts.",
"id": 878,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 1,
"highalch": 1,
"icon": "Bronze bolts (p) 5.png",
"name": "Bronze bolts (p)"
},
{
"examine": "Some poisoned bronze bolts.",
"id": 6061,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 1,
"highalch": 1,
"icon": "Bronze bolts (p+) 5.png",
"name": "Bronze bolts (p+)"
},
{
"examine": "Super poisoned bronze bolts.",
"id": 6062,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 1,
"highalch": 1,
"icon": "Bronze bolts (p++) 5.png",
"name": "Bronze bolts (p++)"
},
{
"examine": "Unfeathered bronze crossbow bolts.",
"id": 9375,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Bronze bolts (unf) 5.png",
"name": "Bronze bolts (unf)"
},
{
"examine": "These will protect my feet.",
"id": 4119,
"members": true,
"lowalch": 9,
"limit": 125,
"value": 24,
"highalch": 14,
"icon": "Bronze boots.png",
"name": "Bronze boots"
},
{
"examine": "Blunt bronze arrow... ouch.",
"id": 4773,
"members": true,
"lowalch": 2,
"limit": 11000,
"value": 5,
"highalch": 3,
"icon": "Bronze brutal 5.png",
"name": "Bronze brutal"
},
{
"examine": "A series of connected metal rings.",
"id": 1103,
"members": false,
"lowalch": 24,
"limit": 125,
"value": 60,
"highalch": 36,
"icon": "Bronze chainbody.png",
"name": "Bronze chainbody"
},
{
"examine": "A set of fighting claws.",
"id": 3095,
"members": true,
"lowalch": 6,
"limit": 125,
"value": 15,
"highalch": 9,
"icon": "Bronze claws.png",
"name": "Bronze claws"
},
{
"examine": "A bronze crossbow.",
"id": 9174,
"members": true,
"lowalch": 29,
"limit": 125,
"value": 73,
"highalch": 43,
"icon": "Bronze crossbow.png",
"name": "Bronze crossbow"
},
{
"examine": "An unstrung bronze crossbow.",
"id": 9454,
"members": true,
"lowalch": 16,
"limit": 10000,
"value": 40,
"highalch": 24,
"icon": "Bronze crossbow (u).png",
"name": "Bronze crossbow (u)"
},
{
"examine": "Short but pointy.",
"id": 1205,
"members": false,
"lowalch": 4,
"limit": 125,
"value": 10,
"highalch": 6,
"icon": "Bronze dagger.png",
"name": "Bronze dagger"
},
{
"examine": "This dagger is poisoned.",
"id": 1221,
"members": true,
"lowalch": 4,
"limit": 125,
"value": 10,
"highalch": 6,
"icon": "Bronze dagger(p).png",
"name": "Bronze dagger(p)"
},
{
"examine": "This dagger is poisoned.",
"id": 5670,
"members": true,
"lowalch": 4,
"limit": 125,
"value": 10,
"highalch": 6,
"icon": "Bronze dagger(p+).png",
"name": "Bronze dagger(p+)"
},
{
"examine": "This dagger is poisoned.",
"id": 5688,
"members": true,
"lowalch": 4,
"limit": 125,
"value": 10,
"highalch": 6,
"icon": "Bronze dagger(p++).png",
"name": "Bronze dagger(p++)"
},
{
"examine": "A deadly throwing dart with a bronze tip.",
"id": 806,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 1,
"highalch": 1,
"icon": "Bronze dart.png",
"name": "Bronze dart"
},
{
"examine": "A deadly looking dart tip made of bronze - needs feathers for flight.",
"id": 819,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Bronze dart tip.png",
"name": "Bronze dart tip"
},
{
"examine": "A deadly poisoned dart with a bronze tip.",
"id": 812,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 1,
"highalch": 1,
"icon": "Bronze dart(p).png",
"name": "Bronze dart(p)"
},
{
"examine": "A deadly poisoned dart with a bronze tip.",
"id": 5628,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 1,
"highalch": 1,
"icon": "Bronze dart(p+).png",
"name": "Bronze dart(p+)"
},
{
"examine": "A deadly poisoned dart with a bronze tip.",
"id": 5635,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 1,
"highalch": 1,
"icon": "Bronze dart(p++).png",
"name": "Bronze dart(p++)"
},
{
"examine": "Do I look scary?",
"id": 12363,
"members": true,
"lowalch": 4000,
"limit": 4,
"value": 10000,
"highalch": 6000,
"icon": "Bronze dragon mask.png",
"name": "Bronze dragon mask"
},
{
"highalch": 9,
"members": true,
"name": "Bronze felling axe",
"examine": "A woodcutter's axe.",
"id": 28196,
"value": 16,
"icon": "Bronze felling axe.png",
"lowalch": 6
},
{
"examine": "A full face helmet.",
"id": 1155,
"members": false,
"lowalch": 17,
"limit": 125,
"value": 44,
"highalch": 26,
"icon": "Bronze full helm.png",
"name": "Bronze full helm"
},
{
"examine": "Bronze full helm with gold trim.",
"id": 12211,
"members": false,
"lowalch": 19,
"limit": 4,
"value": 48,
"highalch": 28,
"icon": "Bronze full helm (g).png",
"name": "Bronze full helm (g)"
},
{
"examine": "Bronze full helm with trim.",
"id": 12221,
"members": false,
"lowalch": 19,
"limit": 4,
"value": 48,
"highalch": 28,
"icon": "Bronze full helm (t).png",
"name": "Bronze full helm (t)"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 12968,
"members": false,
"lowalch": 80,
"limit": 8,
"value": 200,
"highalch": 120,
"icon": "Bronze gold-trimmed set (lg).png",
"name": "Bronze gold-trimmed set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 12970,
"members": false,
"lowalch": 80,
"limit": 8,
"value": 200,
"highalch": 120,
"icon": "Bronze gold-trimmed set (sk).png",
"name": "Bronze gold-trimmed set (sk)"
},
{
"examine": "A bronze halberd.",
"id": 3190,
"members": true,
"lowalch": 32,
"limit": 125,
"value": 80,
"highalch": 48,
"icon": "Bronze halberd.png",
"name": "Bronze halberd"
},
{
"examine": "A bronze-tipped, one-handed hasta.",
"id": 11367,
"members": true,
"lowalch": 10,
"limit": 125,
"value": 26,
"highalch": 15,
"icon": "Bronze hasta.png",
"name": "Bronze hasta"
},
{
"examine": "A poison tipped, one-handed bronze hasta.",
"id": 11379,
"members": true,
"lowalch": 10,
"limit": 125,
"value": 26,
"highalch": 15,
"icon": "Bronze hasta(p).png",
"name": "Bronze hasta(p)"
},
{
"examine": "A poison tipped, one-handed bronze hasta.",
"id": 11382,
"members": true,
"lowalch": 10,
"limit": 125,
"value": 26,
"highalch": 15,
"icon": "Bronze hasta(p+).png",
"name": "Bronze hasta(p+)"
},
{
"examine": "A poison-tipped, one-handed bronze hasta.",
"id": 11384,
"members": true,
"lowalch": 10,
"limit": 125,
"value": 26,
"highalch": 15,
"icon": "Bronze hasta(p++).png",
"name": "Bronze hasta(p++)"
},
{
"examine": "A bronze tipped javelin.",
"id": 825,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 4,
"highalch": 2,
"icon": "Bronze javelin.png",
"name": "Bronze javelin"
},
{
"examine": "Needs a shaft.",
"id": 19570,
"members": true,
"lowalch": 10,
"limit": 10000,
"value": 25,
"highalch": 15,
"icon": "Bronze javelin heads 5.png",
"name": "Bronze javelin heads"
},
{
"examine": "A bronze tipped javelin.",
"id": 831,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 4,
"highalch": 2,
"icon": "Bronze javelin(p).png",
"name": "Bronze javelin(p)"
},
{
"examine": "A bronze tipped javelin.",
"id": 5642,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 4,
"highalch": 2,
"icon": "Bronze javelin(p+).png",
"name": "Bronze javelin(p+)"
},
{
"examine": "A bronze tipped javelin.",
"id": 5648,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 4,
"highalch": 2,
"icon": "Bronze javelin(p++).png",
"name": "Bronze javelin(p++)"
},
{
"examine": "A large metal shield.",
"id": 1189,
"members": false,
"lowalch": 27,
"limit": 125,
"value": 68,
"highalch": 40,
"icon": "Bronze kiteshield.png",
"name": "Bronze kiteshield"
},
{
"examine": "Bronze kiteshield with gold trim.",
"id": 12213,
"members": false,
"lowalch": 25,
"limit": 4,
"value": 64,
"highalch": 38,
"icon": "Bronze kiteshield (g).png",
"name": "Bronze kiteshield (g)"
},
{
"examine": "Bronze kiteshield with trim.",
"id": 12223,
"members": false,
"lowalch": 25,
"limit": 4,
"value": 64,
"highalch": 38,
"icon": "Bronze kiteshield (t).png",
"name": "Bronze kiteshield (t)"
},
{
"examine": "A finely balanced throwing knife.",
"id": 864,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 1,
"highalch": 1,
"icon": "Bronze knife.png",
"name": "Bronze knife"
},
{
"examine": "A finely balanced throwing knife.",
"id": 870,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 1,
"highalch": 1,
"icon": "Bronze knife(p).png",
"name": "Bronze knife(p)"
},
{
"examine": "A finely balanced throwing knife.",
"id": 5654,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 1,
"highalch": 1,
"icon": "Bronze knife(p+).png",
"name": "Bronze knife(p+)"
},
{
"examine": "A finely balanced throwing knife.",
"id": 5661,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 1,
"highalch": 1,
"icon": "Bronze knife(p++).png",
"name": "Bronze knife(p++)"
},
{
"examine": "A pair of bronze crossbow limbs.",
"id": 9420,
"members": true,
"lowalch": 8,
"limit": 10000,
"value": 20,
"highalch": 12,
"icon": "Bronze limbs.png",
"name": "Bronze limbs"
},
{
"examine": "Perhaps someone can do something with this.",
"id": 25442,
"members": true,
"lowalch": 320,
"limit": 8,
"value": 800,
"highalch": 480,
"icon": "Bronze locks.png",
"name": "Bronze locks"
},
{
"examine": "A razor sharp longsword.",
"id": 1291,
"members": false,
"lowalch": 16,
"limit": 125,
"value": 40,
"highalch": 24,
"icon": "Bronze longsword.png",
"name": "Bronze longsword"
},
{
"examine": "A spiky mace.",
"id": 1422,
"members": false,
"lowalch": 7,
"limit": 125,
"value": 18,
"highalch": 10,
"icon": "Bronze mace.png",
"name": "Bronze mace"
},
{
"examine": "A medium sized helmet.",
"id": 1139,
"members": false,
"lowalch": 9,
"limit": 125,
"value": 24,
"highalch": 14,
"icon": "Bronze med helm.png",
"name": "Bronze med helm"
},
{
"examine": "Keeps things in place fairly permanently.",
"id": 4819,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Bronze nails.png",
"name": "Bronze nails"
},
{
"examine": "Used for mining.",
"id": 1265,
"members": false,
"lowalch": 1,
"limit": 40,
"value": 1,
"highalch": 1,
"icon": "Bronze pickaxe.png",
"name": "Bronze pickaxe"
},
{
"examine": "Provides excellent protection.",
"id": 1117,
"members": false,
"lowalch": 64,
"limit": 125,
"value": 160,
"highalch": 96,
"icon": "Bronze platebody.png",
"name": "Bronze platebody"
},
{
"examine": "Bronze platebody with gold trim.",
"id": 12205,
"members": false,
"lowalch": 64,
"limit": 4,
"value": 160,
"highalch": 96,
"icon": "Bronze platebody (g).png",
"name": "Bronze platebody (g)"
},
{
"examine": "Bronze platebody with trim.",
"id": 12215,
"members": false,
"lowalch": 64,
"limit": 4,
"value": 160,
"highalch": 96,
"icon": "Bronze platebody (t).png",
"name": "Bronze platebody (t)"
},
{
"examine": "These look pretty heavy.",
"id": 1075,
"members": false,
"lowalch": 32,
"limit": 125,
"value": 80,
"highalch": 48,
"icon": "Bronze platelegs.png",
"name": "Bronze platelegs"
},
{
"examine": "Bronze platelegs with gold trim.",
"id": 12207,
"members": false,
"lowalch": 32,
"limit": 4,
"value": 80,
"highalch": 48,
"icon": "Bronze platelegs (g).png",
"name": "Bronze platelegs (g)"
},
{
"examine": "Bronze platelegs with trim.",
"id": 12217,
"members": false,
"lowalch": 32,
"limit": 4,
"value": 80,
"highalch": 48,
"icon": "Bronze platelegs (t).png",
"name": "Bronze platelegs (t)"
},
{
"examine": "Designer leg protection.",
"id": 1087,
"members": false,
"lowalch": 32,
"limit": 125,
"value": 80,
"highalch": 48,
"icon": "Bronze plateskirt.png",
"name": "Bronze plateskirt"
},
{
"examine": "Bronze plateskirt with gold trim.",
"id": 12209,
"members": false,
"lowalch": 32,
"limit": 4,
"value": 80,
"highalch": 48,
"icon": "Bronze plateskirt (g).png",
"name": "Bronze plateskirt (g)"
},
{
"examine": "Bronze plateskirt with trim.",
"id": 12219,
"members": false,
"lowalch": 32,
"limit": 4,
"value": 80,
"highalch": 48,
"icon": "Bronze plateskirt (t).png",
"name": "Bronze plateskirt (t)"
},
{
"examine": "A vicious, curved sword.",
"id": 1321,
"members": false,
"lowalch": 12,
"limit": 125,
"value": 32,
"highalch": 19,
"icon": "Bronze scimitar.png",
"name": "Bronze scimitar"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 12960,
"members": false,
"lowalch": 80,
"limit": 8,
"value": 200,
"highalch": 120,
"icon": "Bronze set (lg).png",
"name": "Bronze set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 12962,
"members": false,
"lowalch": 80,
"limit": 8,
"value": 200,
"highalch": 120,
"icon": "Bronze set (sk).png",
"name": "Bronze set (sk)"
},
{
"examine": "A bronze tipped spear.",
"id": 1237,
"members": true,
"lowalch": 10,
"limit": 125,
"value": 26,
"highalch": 15,
"icon": "Bronze spear.png",
"name": "Bronze spear"
},
{
"examine": "A poisoned bronze tipped spear.",
"id": 1251,
"members": true,
"lowalch": 10,
"limit": 125,
"value": 26,
"highalch": 15,
"icon": "Bronze spear(p).png",
"name": "Bronze spear(p)"
},
{
"examine": "A poisoned bronze tipped spear.",
"id": 5704,
"members": true,
"lowalch": 10,
"limit": 125,
"value": 26,
"highalch": 15,
"icon": "Bronze spear(p+).png",
"name": "Bronze spear(p+)"
},
{
"examine": "A poisoned bronze tipped spear.",
"id": 5718,
"members": true,
"lowalch": 10,
"limit": 125,
"value": 26,
"highalch": 15,
"icon": "Bronze spear(p++).png",
"name": "Bronze spear(p++)"
},
{
"examine": "A medium square shield.",
"id": 1173,
"members": false,
"lowalch": 19,
"limit": 125,
"value": 48,
"highalch": 28,
"icon": "Bronze sq shield.png",
"name": "Bronze sq shield"
},
{
"examine": "A razor sharp sword.",
"id": 1277,
"members": false,
"lowalch": 10,
"limit": 125,
"value": 26,
"highalch": 15,
"icon": "Bronze sword.png",
"name": "Bronze sword"
},
{
"examine": "A finely balanced throwing axe.",
"id": 800,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 3,
"highalch": 1,
"icon": "Bronze thrownaxe.png",
"name": "Bronze thrownaxe"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 12964,
"members": false,
"lowalch": 80,
"limit": 8,
"value": 200,
"highalch": 120,
"icon": "Bronze trimmed set (lg).png",
"name": "Bronze trimmed set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 12966,
"members": false,
"lowalch": 80,
"limit": 8,
"value": 200,
"highalch": 120,
"icon": "Bronze trimmed set (sk).png",
"name": "Bronze trimmed set (sk)"
},
{
"examine": "I don't think it's intended for joinery.",
"id": 1337,
"members": false,
"lowalch": 18,
"limit": 125,
"value": 47,
"highalch": 28,
"icon": "Bronze warhammer.png",
"name": "Bronze warhammer"
},
{
"examine": "Useful for Crafting items.",
"id": 1794,
"members": true,
"lowalch": 8,
"limit": 15,
"value": 20,
"highalch": 12,
"icon": "Bronze wire.png",
"name": "Bronze wire"
},
{
"examine": "A scary broodoo shield.",
"id": 6259,
"members": true,
"lowalch": 1200,
"limit": 125,
"value": 3000,
"highalch": 1800,
"icon": "Broodoo shield (combat).png",
"name": "Broodoo shield (10) (combat)"
},
{
"examine": "A scary broodoo shield.",
"id": 6237,
"members": true,
"lowalch": 1200,
"limit": 125,
"value": 3000,
"highalch": 1800,
"icon": "Broodoo shield (disease).png",
"name": "Broodoo shield (10) (disease)"
},
{
"examine": "A scary broodoo shield.",
"id": 6215,
"members": true,
"lowalch": 1200,
"limit": 125,
"value": 3000,
"highalch": 1800,
"icon": "Broodoo shield (poison).png",
"name": "Broodoo shield (10) (poison)"
},
{
"examine": "A scary broodoo shield.",
"id": 6279,
"members": true,
"lowalch": 1200,
"limit": 125,
"value": 3000,
"highalch": 1800,
"icon": "Broodoo shield (combat).png",
"name": "Broodoo shield (combat)"
},
{
"examine": "A scary broodoo shield.",
"id": 6257,
"members": true,
"lowalch": 1200,
"limit": 125,
"value": 3000,
"highalch": 1800,
"icon": "Broodoo shield (disease).png",
"name": "Broodoo shield (disease)"
},
{
"examine": "A scary broodoo shield.",
"id": 6235,
"members": true,
"lowalch": 1200,
"limit": 125,
"value": 3000,
"highalch": 1800,
"icon": "Broodoo shield (poison).png",
"name": "Broodoo shield (poison)"
},
{
"examine": "A mostly clean apron.",
"id": 1757,
"members": false,
"lowalch": 1,
"limit": 40,
"value": 2,
"highalch": 1,
"icon": "Brown apron.png",
"name": "Brown apron"
},
{
"examine": "A minimalist's hat.",
"id": 2649,
"members": true,
"lowalch": 16,
"limit": 4,
"value": 40,
"highalch": 24,
"icon": "Brown headband.png",
"name": "Brown headband"
},
{
"examine": "A brown toy horse.",
"id": 2520,
"members": false,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Brown toy horsey.png",
"name": "Brown toy horsey"
},
{
"examine": "A moody blue pointed snail shell helmet.",
"id": 3343,
"members": true,
"lowalch": 120,
"limit": 150,
"value": 300,
"highalch": 180,
"icon": "Bruise blue snelm (pointed).png",
"name": "Bruise blue snelm (pointed)"
},
{
"examine": "A moody blue snail shell helmet.",
"id": 3333,
"members": true,
"lowalch": 120,
"limit": 150,
"value": 300,
"highalch": 180,
"icon": "Bruise blue snelm (round).png",
"name": "Bruise blue snelm (round)"
},
{
"examine": "Ready to infuse a Battlestaff with the power of Nature.",
"id": 22372,
"members": false,
"lowalch": 2800,
"limit": 100,
"value": 7000,
"highalch": 4200,
"icon": "Bryophyta's essence.png",
"name": "Bryophyta's essence"
},
{
"examine": "It's a slightly magical stick. All natural, of course.",
"id": 22368,
"members": false,
"lowalch": 26000,
"limit": 8,
"value": 65000,
"highalch": 39000,
"icon": "Bryophyta's staff (uncharged).png",
"name": "Bryophyta's staff (uncharged)"
},
{
"examine": "It's a wooden bucket.",
"id": 1925,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Bucket.png",
"name": "Bucket"
},
{
"examine": "A helm made from a bucket.",
"id": 19991,
"members": true,
"lowalch": 5600,
"limit": 4,
"value": 14000,
"highalch": 8400,
"icon": "Bucket helm.png",
"name": "Bucket helm"
},
{
"examine": "A helm made from a golden bucket.",
"id": 20059,
"members": true,
"lowalch": 5600,
"limit": 4,
"value": 14000,
"highalch": 8400,
"icon": "Bucket helm (g).png",
"name": "Bucket helm (g)"
},
{
"examine": "It's a bucket of milk.",
"id": 1927,
"members": false,
"lowalch": 2,
"limit": 13000,
"value": 6,
"highalch": 3,
"icon": "Bucket of milk.png",
"name": "Bucket of milk"
},
{
"examine": "One of the ingredients for making glass.",
"id": 1783,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Bucket of sand.png",
"name": "Bucket of sand"
},
{
"examine": "It's a bucket of sap.",
"id": 4687,
"members": true,
"lowalch": 12,
"limit": 15,
"value": 30,
"highalch": 18,
"icon": "Bucket of sap.png",
"name": "Bucket of sap"
},
{
"examine": "It's a bucket of water.",
"id": 1929,
"members": false,
"lowalch": 2,
"limit": 13000,
"value": 6,
"highalch": 3,
"icon": "Bucket of water.png",
"name": "Bucket of water"
},
{
"examine": "It's a bucket of wax.",
"id": 30,
"members": true,
"lowalch": 2,
"limit": 15,
"value": 6,
"highalch": 3,
"icon": "Bucket of wax.png",
"name": "Bucket of wax"
},
{
"examine": "A sturdy steel lantern.",
"id": 4548,
"members": true,
"lowalch": 168,
"limit": 10000,
"value": 420,
"highalch": 252,
"icon": "Bullseye lantern.png",
"name": "Bullseye lantern"
},
{
"examine": "You need to add lamp oil before you can use it.",
"id": 4546,
"members": true,
"lowalch": 160,
"limit": 10000,
"value": 400,
"highalch": 240,
"icon": "Bullseye lantern (empty).png",
"name": "Bullseye lantern (empty)"
},
{
"examine": "You need to add a lens before you can use it.",
"id": 4544,
"members": true,
"lowalch": 120,
"limit": 10000,
"value": 300,
"highalch": 180,
"icon": "Bullseye lantern (unf).png",
"name": "Bullseye lantern (unf)"
},
{
"examine": "Useful teleports around the wilderness.",
"id": 21166,
"members": true,
"lowalch": 500,
"limit": 10000,
"value": 1250,
"highalch": 750,
"icon": "Burning amulet.png",
"name": "Burning amulet(5)"
},
{
"examine": "Bones are for burying!",
"id": 528,
"members": false,
"lowalch": 1,
"limit": 3000,
"value": 1,
"highalch": 1,
"icon": "Burnt bones.png",
"name": "Burnt bones"
},
{
"examine": "It's still warm to the touch.",
"id": 20718,
"members": true,
"lowalch": 80,
"limit": 11000,
"value": 200,
"highalch": 120,
"icon": "Burnt page.png",
"name": "Burnt page"
},
{
"examine": "It's got little holes in the top.",
"id": 10012,
"members": true,
"lowalch": 1,
"limit": 40,
"value": 1,
"highalch": 1,
"icon": "Butterfly jar.png",
"name": "Butterfly jar"
},
{
"examine": "For catching butterflies...",
"id": 10010,
"members": true,
"lowalch": 8,
"limit": 125,
"value": 20,
"highalch": 12,
"icon": "Butterfly net.png",
"name": "Butterfly net"
},
{
"examine": "Yuck I don't like cabbage.",
"id": 1965,
"members": false,
"lowalch": 1,
"limit": 6000,
"value": 1,
"highalch": 1,
"icon": "Cabbage.png",
"name": "Cabbage"
},
{
"examine": "An adamant shield shaped like a cabbage.",
"id": 20272,
"members": true,
"lowalch": 1200,
"limit": 125,
"value": 3000,
"highalch": 1800,
"icon": "Cabbage round shield.png",
"name": "Cabbage round shield"
},
{
"examine": "A cabbage seed - plant in an allotment.",
"id": 5324,
"members": true,
"lowalch": 1,
"limit": 600,
"value": 3,
"highalch": 1,
"icon": "Cabbage seed 5.png",
"name": "Cabbage seed"
},
{
"examine": "There are 10 cabbages in this sack.",
"id": 5478,
"members": true,
"lowalch": 1,
"limit": 600,
"value": 1,
"highalch": 1,
"icon": "Cabbages(10).png",
"name": "Cabbages(10)"
},
{
"examine": "A Cactus seed - plant in a cactus patch.",
"id": 5280,
"members": true,
"lowalch": 39,
"limit": 200,
"value": 99,
"highalch": 59,
"icon": "Cactus seed 5.png",
"name": "Cactus seed"
},
{
"examine": "Don't prick yourself with this.",
"id": 6016,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 1,
"highalch": 1,
"icon": "Cactus spine.png",
"name": "Cactus spine"
},
{
"examine": "A powerful herb.",
"id": 265,
"members": true,
"lowalch": 26,
"limit": 11000,
"value": 65,
"highalch": 39,
"icon": "Cadantine.png",
"name": "Cadantine"
},
{
"examine": "I need another ingredient to finish this blood potion.",
"id": 22443,
"members": true,
"lowalch": 66,
"limit": 10000,
"value": 165,
"highalch": 99,
"icon": "Cadantine blood potion (unf).png",
"name": "Cadantine blood potion (unf)"
},
{
"examine": "I need another ingredient to finish this Cadantine potion.",
"id": 107,
"members": true,
"lowalch": 26,
"limit": 10000,
"value": 65,
"highalch": 39,
"icon": "Cadantine potion (unf).png",
"name": "Cadantine potion (unf)"
},
{
"examine": "A cadantine seed - plant in a herb patch.",
"id": 5301,
"members": true,
"lowalch": 2,
"limit": 200,
"value": 7,
"highalch": 4,
"icon": "Cadantine seed 5.png",
"name": "Cadantine seed"
},
{
"examine": "Poisonous berries.",
"id": 753,
"members": false,
"lowalch": 1,
"limit": 600,
"value": 1,
"highalch": 1,
"icon": "Cadava berries.png",
"name": "Cadava berries"
},
{
"examine": "A cadavaberry bush seed - plant in a bush patch.",
"id": 5102,
"members": true,
"lowalch": 3,
"limit": 600,
"value": 9,
"highalch": 5,
"icon": "Cadavaberry seed 5.png",
"name": "Cadavaberry seed"
},
{
"examine": "A plain sponge cake.",
"id": 1891,
"members": false,
"lowalch": 20,
"limit": 6000,
"value": 50,
"highalch": 30,
"icon": "Cake.png",
"name": "Cake"
},
{
"examine": "Useful for baking cakes.",
"id": 1887,
"members": false,
"lowalch": 4,
"limit": 40,
"value": 10,
"highalch": 6,
"icon": "Cake tin.png",
"name": "Cake tin"
},
{
"members": true,
"name": "Calcified moth",
"examine": "A fossilised moth infused with dwarven magic.",
"id": 29090,
"value": 10000,
"icon": "Calcified moth.png",
"limit": 100
},
{
"examine": "A large crystal of calcium carbonate.",
"id": 21543,
"members": true,
"lowalch": 12,
"limit": 13000,
"value": 30,
"highalch": 18,
"icon": "Calcite.png",
"name": "Calcite"
},
{
"examine": "This is the largest fruit I've ever seen.",
"id": 5980,
"members": true,
"lowalch": 21,
"limit": 11000,
"value": 54,
"highalch": 32,
"icon": "Calquat fruit.png",
"name": "Calquat fruit"
},
{
"examine": "Sliced and hollowed out to form a keg.",
"id": 5769,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Calquat keg.png",
"name": "Calquat keg"
},
{
"examine": "This sapling is ready to be replanted in a Calquat Tree patch.",
"id": 5503,
"members": true,
"lowalch": 1,
"limit": 200,
"value": 1,
"highalch": 1,
"icon": "Calquat sapling.png",
"name": "Calquat sapling"
},
{
"examine": "Plant in a plantpot of soil to grow a sapling.",
"id": 5290,
"members": true,
"lowalch": 136,
"limit": 200,
"value": 340,
"highalch": 204,
"icon": "Calquat tree seed 5.png",
"name": "Calquat tree seed"
},
{
"examine": "A teleport to Camelot.",
"id": 8010,
"members": true,
"lowalch": 1,
"limit": 15000,
"value": 1,
"highalch": 1,
"icon": "Camelot teleport (tablet).png",
"name": "Camelot teleport (tablet)"
},
{
"examine": "A candle.",
"id": 36,
"members": true,
"lowalch": 1,
"limit": 40,
"value": 3,
"highalch": 1,
"icon": "Candle.png",
"name": "Candle"
},
{
"examine": "A candle in a glass cage.",
"id": 4532,
"members": true,
"lowalch": 6,
"limit": 100,
"value": 15,
"highalch": 9,
"icon": "Candle lantern (black).png",
"name": "Candle lantern (black)"
},
{
"examine": "A candle in a glass cage.",
"id": 4529,
"members": true,
"lowalch": 6,
"limit": 40,
"value": 15,
"highalch": 9,
"icon": "Candle lantern (white).png",
"name": "Candle lantern (white)"
},
{
"examine": "The barrels of the multicannon.",
"id": 10,
"members": true,
"lowalch": 75000,
"limit": 70,
"value": 187500,
"highalch": 112500,
"icon": "Cannon barrels.png",
"name": "Cannon barrels"
},
{
"examine": "The cannon is built on this.",
"id": 6,
"members": true,
"lowalch": 75000,
"limit": 70,
"value": 187500,
"highalch": 112500,
"icon": "Cannon base.png",
"name": "Cannon base"
},
{
"examine": "This powers the multicannon.",
"id": 12,
"members": true,
"lowalch": 75000,
"limit": 70,
"value": 187500,
"highalch": 112500,
"icon": "Cannon furnace.png",
"name": "Cannon furnace"
},
{
"examine": "The mounting for the multicannon.",
"id": 8,
"members": true,
"lowalch": 75000,
"limit": 70,
"value": 187500,
"highalch": 112500,
"icon": "Cannon stand.png",
"name": "Cannon stand"
},
{
"examine": "Ammo for the Dwarf Cannon.",
"id": 2,
"members": true,
"lowalch": 2,
"limit": 11000,
"value": 5,
"highalch": 3,
"icon": "Cannonball.png",
"name": "Cannonball"
},
{
"examine": "Lets the person behind you know that you mean business.",
"id": 23351,
"members": true,
"lowalch": 2000,
"limit": 8,
"value": 5000,
"highalch": 3000,
"icon": "Cape of skulls.png",
"name": "Cape of skulls"
},
{
"examine": "A teleport to Carrallanger, in level 19 Wilderness.",
"id": 12776,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Carrallanger teleport (tablet).png",
"name": "Carrallanger teleport (tablet)"
},
{
"examine": "How does it all fit in there?",
"id": 8566,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Carved oak bench (flatpack).png",
"name": "Carved oak bench (flatpack)"
},
{
"examine": "Carved oak magic wardrobe.",
"id": 9853,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Carved oak magic wardrobe (flatpack).png",
"name": "Carved oak magic wardrobe (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8552,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Carved oak table (flatpack).png",
"name": "Carved oak table (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8570,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Carved teak bench (flatpack).png",
"name": "Carved teak bench (flatpack)"
},
{
"examine": "Carved teak magic wardrobe.",
"id": 9855,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Carved teak magic wardrobe (flatpack).png",
"name": "Carved teak magic wardrobe (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8556,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Carved teak table (flatpack).png",
"name": "Carved teak table (flatpack)"
},
{
"examine": "I hope there's treasure in it.",
"id": 405,
"members": true,
"lowalch": 20,
"limit": 50,
"value": 50,
"highalch": 30,
"icon": "Casket.png",
"name": "Casket"
},
{
"examine": "Must be worn as you enter the game to receive the bonus for that game.",
"id": 11079,
"members": false,
"lowalch": 670,
"limit": 10000,
"value": 1675,
"highalch": 1005,
"icon": "Castle wars bracelet.png",
"name": "Castle wars bracelet(3)"
},
{
"examine": "Miaow!",
"id": 12361,
"members": true,
"lowalch": 960,
"limit": 4,
"value": 2400,
"highalch": 1440,
"icon": "Cat mask.png",
"name": "Cat mask"
},
{
"examine": "A mysterious power emanates from the talisman...",
"id": 26798,
"members": true,
"lowalch": 1,
"limit": 40,
"value": 1,
"highalch": 1,
"icon": "Catalytic talisman.png",
"name": "Catalytic talisman"
},
{
"examine": "A tiara infused with the properties of every catalytic rune.",
"id": 26801,
"members": true,
"lowalch": 1,
"limit": 40,
"value": 1,
"highalch": 1,
"icon": "Catalytic tiara.png",
"name": "Catalytic tiara"
},
{
"examine": "A teleport to Catherby.",
"id": 24961,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Catherby teleport (tablet).png",
"name": "Catherby teleport (tablet)"
},
{
"examine": "I hope I don't meet any roundheads...",
"id": 11280,
"members": true,
"lowalch": 80,
"limit": 150,
"value": 200,
"highalch": 120,
"icon": "Cavalier mask.png",
"name": "Cavalier mask"
},
{
"examine": "It's a bit slimy.",
"id": 5003,
"members": true,
"lowalch": 6,
"limit": 10000,
"value": 15,
"highalch": 9,
"icon": "Cave eel.png",
"name": "Cave eel"
},
{
"examine": "Wire found in Dorgesh-Kaan.",
"id": 10981,
"members": true,
"lowalch": 8,
"limit": 11000,
"value": 20,
"highalch": 12,
"icon": "Cave goblin wire.png",
"name": "Cave goblin wire"
},
{
"examine": "Caviar, or expensive fishy eggs.",
"id": 11326,
"members": true,
"lowalch": 20,
"limit": 13000,
"value": 50,
"highalch": 30,
"icon": "Caviar.png",
"name": "Caviar"
},
{
"examine": "A length of celastrus wood, ready for fletching.",
"id": 22935,
"members": true,
"lowalch": 180,
"limit": 12000,
"value": 450,
"highalch": 270,
"icon": "Celastrus bark.png",
"name": "Celastrus bark"
},
{
"examine": "This sapling is ready to be replanted in a Celastrus patch.",
"id": 22856,
"members": true,
"lowalch": 1,
"limit": 100,
"value": 1,
"highalch": 1,
"icon": "Celastrus sapling.png",
"name": "Celastrus sapling"
},
{
"examine": "For growing a Celastrus tree.",
"id": 22869,
"members": true,
"lowalch": 216,
"limit": 200,
"value": 540,
"highalch": 324,
"icon": "Celastrus seed 5.png",
"name": "Celastrus seed"
},
{
"examine": "A teleport to the Cemetery in level 31 Wilderness.",
"id": 19627,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Cemetery teleport (tablet).png",
"name": "Cemetery teleport (tablet)"
},
{
"examine": "Used for low level missile spells.",
"id": 562,
"members": false,
"lowalch": 36,
"limit": 18000,
"value": 90,
"highalch": 54,
"icon": "Chaos rune.png",
"name": "Chaos rune"
},
{
"examine": "A mysterious power emanates from the talisman...",
"id": 1452,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 4,
"highalch": 2,
"icon": "Chaos talisman.png",
"name": "Chaos talisman"
},
{
"examine": "A tiara infused with the properties of chaos.",
"id": 5543,
"members": true,
"lowalch": 40,
"limit": 40,
"value": 100,
"highalch": 60,
"icon": "Chaos tiara.png",
"name": "Chaos tiara"
},
{
"examine": "A lump of charcoal.",
"id": 973,
"members": false,
"lowalch": 18,
"limit": 13000,
"value": 45,
"highalch": 27,
"icon": "Charcoal.png",
"name": "Charcoal"
},
{
"examine": "A scroll for charging dragonstone jewellery.",
"id": 20238,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Charge dragonstone jewellery scroll.png",
"name": "Charge dragonstone jewellery scroll"
},
{
"examine": "It's got holes in it.",
"id": 1985,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 4,
"highalch": 2,
"icon": "Cheese.png",
"name": "Cheese"
},
{
"examine": "This smells really good.",
"id": 2259,
"members": true,
"lowalch": 1,
"limit": 6000,
"value": 2,
"highalch": 1,
"icon": "Cheese+tom batta.png",
"name": "Cheese+tom batta"
},
{
"examine": "A fruity, full-bodied ale.",
"id": 5755,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 2,
"highalch": 1,
"icon": "Chef's delight.png",
"name": "Chef's delight"
},
{
"examine": "How does it all fit in there?",
"id": 8526,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Chef's delight (flatpack).png",
"name": "Chef's delight (flatpack)"
},
{
"examine": "This keg contains 4 pints of Chef's Delight.",
"id": 5833,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Chef's delight(4).png",
"name": "Chef's delight(4)"
},
{
"examine": "This looks a good deal stronger than normal Chef's Delight.",
"id": 5757,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 2,
"highalch": 1,
"icon": "Chef's delight(m).png",
"name": "Chef's delight(m)"
},
{
"examine": "This keg contains 4 pints of mature Chef's Delight.",
"id": 5913,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Chef's delight(m4).png",
"name": "Chef's delight(m4)"
},
{
"examine": "What a silly hat.",
"id": 1949,
"members": false,
"lowalch": 1,
"limit": 150,
"value": 2,
"highalch": 1,
"icon": "Chef's hat.png",
"name": "Chef's hat"
},
{
"examine": "A bowl of meat in chilli-con-carne sauce.",
"id": 7062,
"members": true,
"lowalch": 5,
"limit": 13000,
"value": 13,
"highalch": 7,
"icon": "Chilli con carne.png",
"name": "Chilli con carne"
},
{
"examine": "A baked potato with chilli con carne.",
"id": 7054,
"members": true,
"lowalch": 5,
"limit": 13000,
"value": 13,
"highalch": 7,
"icon": "Chilli potato.png",
"name": "Chilli potato"
},
{
"examine": "Handle with care.",
"id": 10033,
"members": true,
"lowalch": 56,
"limit": 7000,
"value": 140,
"highalch": 84,
"icon": "Chinchompa.png",
"name": "Chinchompa"
},
{
"examine": "Good for detailed Crafting.",
"id": 1755,
"members": false,
"lowalch": 1,
"limit": 40,
"value": 1,
"highalch": 1,
"icon": "Chisel.png",
"name": "Chisel"
},
{
"examine": "A warm creamy alcoholic beverage.",
"id": 2074,
"members": true,
"lowalch": 12,
"limit": 2000,
"value": 30,
"highalch": 18,
"icon": "Choc saturday.png",
"name": "Choc saturday"
},
{
"examine": "Better eat this before it melts.",
"id": 6794,
"members": true,
"lowalch": 12,
"limit": 6000,
"value": 30,
"highalch": 18,
"icon": "Choc-ice.png",
"name": "Choc-ice"
},
{
"examine": "Yum... smells good.",
"id": 2209,
"members": true,
"lowalch": 1,
"limit": 6000,
"value": 2,
"highalch": 1,
"icon": "Chocchip crunchies.png",
"name": "Chocchip crunchies"
},
{
"examine": "Mmmmmmm chocolate.",
"id": 1973,
"members": false,
"lowalch": 4,
"limit": 13000,
"value": 10,
"highalch": 6,
"icon": "Chocolate bar.png",
"name": "Chocolate bar"
},
{
"examine": "Full of creamy, chocolately goodness.",
"id": 2185,
"members": true,
"lowalch": 1,
"limit": 6000,
"value": 2,
"highalch": 1,
"icon": "Chocolate bomb.png",
"name": "Chocolate bomb"
},
{
"examine": "This looks very tasty.",
"id": 1897,
"members": false,
"lowalch": 28,
"limit": 6000,
"value": 70,
"highalch": 42,
"icon": "Chocolate cake.png",
"name": "Chocolate cake"
},
{
"examine": "It's ground up chocolate.",
"id": 1975,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Chocolate dust.png",
"name": "Chocolate dust"
},
{
"examine": "A bowl of chopped garlic.",
"id": 7074,
"members": true,
"lowalch": 2,
"limit": 13000,
"value": 7,
"highalch": 4,
"icon": "Chopped garlic.png",
"name": "Chopped garlic"
},
{
"examine": "A mixture of onions in a bowl.",
"id": 1871,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 3,
"highalch": 1,
"icon": "Chopped onion.png",
"name": "Chopped onion"
},
{
"examine": "A mixture of tomatoes in a bowl.",
"id": 1869,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 3,
"highalch": 1,
"icon": "Chopped tomato.png",
"name": "Chopped tomato"
},
{
"examine": "A bowl of finely chopped tuna.",
"id": 7086,
"members": true,
"lowalch": 41,
"limit": 13000,
"value": 104,
"highalch": 62,
"icon": "Chopped tuna.png",
"name": "Chopped tuna"
},
{
"examine": "Strips of ugthanki meat in a bowl.",
"id": 1873,
"members": true,
"lowalch": 2,
"limit": 11000,
"value": 5,
"highalch": 3,
"icon": "Chopped ugthanki.png",
"name": "Chopped ugthanki"
},
{
"examine": "I need to pull this.",
"id": 962,
"members": false,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Christmas cracker.png",
"name": "Christmas cracker"
},
{
"examine": "A dense clump of corroded metal.",
"id": 28276,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 1,
"highalch": 1,
"icon": "Chromium ingot.png",
"name": "Chromium ingot"
},
{
"examine": "A glass of cider.",
"id": 5763,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 2,
"highalch": 1,
"icon": "Cider.png",
"name": "Cider"
},
{
"examine": "How does it all fit in there?",
"id": 8518,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Cider barrel (flatpack).png",
"name": "Cider barrel (flatpack)"
},
{
"examine": "This keg contains 4 pints of Cider.",
"id": 5849,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Cider(4).png",
"name": "Cider(4)"
},
{
"examine": "This keg contains 4 pints of mature Cider.",
"id": 5929,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Cider(m4).png",
"name": "Cider(m4)"
},
{
"examine": "A toughened chunk of dagannoth hide.",
"id": 6169,
"members": true,
"lowalch": 24,
"limit": 11000,
"value": 60,
"highalch": 36,
"icon": "Circular hide.png",
"name": "Circular hide"
},
{
"examine": "Ghetto disguise!",
"id": 9644,
"members": true,
"lowalch": 2,
"limit": 150,
"value": 5,
"highalch": 3,
"icon": "Citizen shoes.png",
"name": "Citizen shoes"
},
{
"examine": "Ghetto disguise!",
"id": 9640,
"members": true,
"lowalch": 2,
"limit": 150,
"value": 5,
"highalch": 3,
"icon": "Citizen top.png",
"name": "Citizen top"
},
{
"examine": "Ghetto disguise!",
"id": 9642,
"members": true,
"lowalch": 2,
"limit": 150,
"value": 5,
"highalch": 3,
"icon": "Citizen trousers.png",
"name": "Citizen trousers"
},
{
"highalch": 1,
"members": true,
"name": "Civitas illa fortis teleport",
"examine": "A teleport to Civitas illa Fortis.",
"id": 28824,
"value": 1,
"icon": "Civitas illa fortis teleport.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Claws of callisto",
"examine": "The claws of a great bear. They seem to maintain some of his power, somehow.",
"id": 27667,
"value": 1,
"icon": "Claws of callisto.png",
"lowalch": 1
},
{
"examine": "Some hard dry clay.",
"id": 434,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Clay.png",
"name": "Clay"
},
{
"examine": "A spirit soaked piece of silk which can be used to remove poison.",
"id": 3188,
"members": true,
"lowalch": 24,
"limit": 40,
"value": 60,
"highalch": 36,
"icon": "Cleaning cloth.png",
"name": "Cleaning cloth"
},
{
"examine": "An effective tool for chopping tough meat.",
"id": 7451,
"members": true,
"lowalch": 10240,
"limit": 50,
"value": 25600,
"highalch": 15360,
"icon": "Cleaver.png",
"name": "Cleaver"
},
{
"examine": "Boots made for climbing.",
"id": 3105,
"members": true,
"lowalch": 4,
"limit": 200,
"value": 12,
"highalch": 7,
"icon": "Climbing boots.png",
"name": "Climbing boots"
},
{
"examine": "Boots made for climbing. Nice trim!",
"id": 23413,
"members": true,
"lowalch": 30000,
"limit": 4,
"value": 75000,
"highalch": 45000,
"icon": "Climbing boots (g).png",
"name": "Climbing boots (g)"
},
{
"examine": "A clockwork mechanism.",
"id": 8792,
"members": true,
"lowalch": 200,
"limit": 11000,
"value": 500,
"highalch": 300,
"icon": "Clockwork.png",
"name": "Clockwork"
},
{
"highalch": 1,
"members": true,
"name": "Clothes pouch",
"examine": "A pouch for your dirty work clothes!",
"id": 28163,
"value": 1,
"icon": "Clothes pouch.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Clothes pouch blueprint",
"examine": "A step-by-step guide to crafting your very own clothes pouch!",
"id": 28166,
"value": 1,
"icon": "Clothes pouch blueprint.png",
"lowalch": 1
},
{
"examine": "Protects your clue scroll if you die.",
"id": 12789,
"members": true,
"lowalch": 4,
"limit": 50,
"value": 10,
"highalch": 6,
"icon": "Clue box.png",
"name": "Clue box"
},
{
"examine": "Hmm a non-renewable energy source!",
"id": 453,
"members": false,
"lowalch": 18,
"limit": 13000,
"value": 45,
"highalch": 27,
"icon": "Coal.png",
"name": "Coal"
},
{
"examine": "For sipping cocktails.",
"id": 2026,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Cocktail glass.png",
"name": "Cocktail glass"
},
{
"examine": "A book on tree gnome cocktails.",
"id": 2023,
"members": true,
"lowalch": 1,
"limit": 15,
"value": 2,
"highalch": 1,
"icon": "Cocktail guide.png",
"name": "Cocktail guide"
},
{
"examine": "Used for mixing cocktails.",
"id": 2025,
"members": true,
"lowalch": 1,
"limit": 40,
"value": 2,
"highalch": 1,
"icon": "Cocktail shaker.png",
"name": "Cocktail shaker"
},
{
"examine": "It's a coconut.",
"id": 5974,
"members": true,
"lowalch": 34,
"limit": 11000,
"value": 87,
"highalch": 52,
"icon": "Coconut.png",
"name": "Coconut"
},
{
"examine": "A vial filled with coconut milk",
"id": 5935,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 1,
"highalch": 1,
"icon": "Coconut milk.png",
"name": "Coconut milk"
},
{
"examine": "Some nicely cooked cod.",
"id": 339,
"members": true,
"lowalch": 4,
"limit": 6000,
"value": 10,
"highalch": 6,
"icon": "Cod.png",
"name": "Cod"
},
{
"examine": "Light weight head protection.",
"id": 1169,
"members": false,
"lowalch": 80,
"limit": 125,
"value": 200,
"highalch": 120,
"icon": "Coif.png",
"name": "Coif"
},
{
"examine": "You will need to recharge the bracelet at the Legends Guild.",
"id": 11126,
"members": true,
"lowalch": 8416,
"limit": 10000,
"value": 21040,
"highalch": 12624,
"icon": "Combat bracelet.png",
"name": "Combat bracelet"
},
{
"examine": "A handy way to get around.",
"id": 11118,
"members": true,
"lowalch": 8416,
"limit": 10000,
"value": 21040,
"highalch": 12624,
"icon": "Combat bracelet(4).png",
"name": "Combat bracelet(4)"
},
{
"examine": "A handy way to get around.",
"id": 11972,
"members": true,
"lowalch": 8416,
"limit": 10000,
"value": 21040,
"highalch": 12624,
"icon": "Combat bracelet(6).png",
"name": "Combat bracelet(6)"
},
{
"examine": "One dose of fishy combat potion.",
"id": 11447,
"members": true,
"lowalch": 20,
"limit": 2000,
"value": 52,
"highalch": 31,
"icon": "Combat mix(1).png",
"name": "Combat mix(1)"
},
{
"examine": "Two doses of fishy combat potion.",
"id": 11445,
"members": true,
"lowalch": 42,
"limit": 2000,
"value": 105,
"highalch": 63,
"icon": "Combat mix(2).png",
"name": "Combat mix(2)"
},
{
"examine": "A set containing 4-dose vials of Attack, Strength and Defence potions.",
"id": 13064,
"members": true,
"lowalch": 28,
"limit": 2000,
"value": 70,
"highalch": 42,
"icon": "Combat potion set.png",
"name": "Combat potion set"
},
{
"examine": "1 dose of combat potion.",
"id": 9745,
"members": true,
"lowalch": 20,
"limit": 2000,
"value": 52,
"highalch": 31,
"icon": "Combat potion(1).png",
"name": "Combat potion(1)"
},
{
"examine": "2 doses of combat potion.",
"id": 9743,
"members": true,
"lowalch": 42,
"limit": 2000,
"value": 105,
"highalch": 63,
"icon": "Combat potion(2).png",
"name": "Combat potion(2)"
},
{
"examine": "3 doses of combat potion.",
"id": 9741,
"members": true,
"lowalch": 64,
"limit": 2000,
"value": 160,
"highalch": 96,
"icon": "Combat potion(3).png",
"name": "Combat potion(3)"
},
{
"examine": "4 doses of combat potion.",
"id": 9739,
"members": true,
"lowalch": 86,
"limit": 2000,
"value": 215,
"highalch": 129,
"icon": "Combat potion(4).png",
"name": "Combat potion(4)"
},
{
"examine": "Common fur from a common kebbit.",
"id": 10121,
"members": true,
"lowalch": 4,
"limit": 10000,
"value": 12,
"highalch": 7,
"icon": "Common kebbit fur.png",
"name": "Common kebbit fur"
},
{
"examine": "The most common of the tench.",
"id": 22829,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 3,
"highalch": 1,
"icon": "Common tench.png",
"name": "Common tench"
},
{
"examine": "A composite ogre bow.",
"id": 4827,
"members": true,
"lowalch": 72,
"limit": 70,
"value": 180,
"highalch": 108,
"icon": "Comp ogre bow.png",
"name": "Comp ogre bow"
},
{
"examine": "Good for plants, helps them grow.",
"id": 6032,
"members": true,
"lowalch": 8,
"limit": 2000,
"value": 20,
"highalch": 12,
"icon": "Compost.png",
"name": "Compost"
},
{
"examine": "Pour this on compost to turn it into super-compost.",
"id": 6476,
"members": true,
"lowalch": 12,
"limit": 50,
"value": 30,
"highalch": 18,
"icon": "Compost potion(1).png",
"name": "Compost potion(1)"
},
{
"examine": "Pour this on compost to turn it into super-compost.",
"id": 6474,
"members": true,
"lowalch": 24,
"limit": 50,
"value": 60,
"highalch": 36,
"icon": "Compost potion(2).png",
"name": "Compost potion(2)"
},
{
"examine": "Pour this on compost to turn it into super-compost.",
"id": 6472,
"members": true,
"lowalch": 36,
"limit": 50,
"value": 90,
"highalch": 54,
"icon": "Compost potion(3).png",
"name": "Compost potion(3)"
},
{
"examine": "Pour this on compost to turn it into super-compost.",
"id": 6470,
"members": true,
"lowalch": 48,
"limit": 50,
"value": 120,
"highalch": 72,
"icon": "Compost potion(4).png",
"name": "Compost potion(4)"
},
{
"highalch": 4800000,
"members": true,
"name": "Condensed gold",
"examine": "The gold standard of gold.",
"id": 26266,
"value": 8000000,
"icon": "Condensed gold.png",
"lowalch": 3200000
},
{
"highalch": 2,
"members": true,
"name": "Cooked barb-tailed kebbit",
"examine": "Mmm, this looks tasty.",
"id": 29131,
"value": 4,
"icon": "Cooked barb-tailed kebbit.png",
"lowalch": 1
},
{
"examine": "Mmm this looks tasty.",
"id": 2140,
"members": false,
"lowalch": 1,
"limit": 6000,
"value": 4,
"highalch": 2,
"icon": "Cooked chicken.png",
"name": "Cooked chicken"
},
{
"examine": "It might look delicious to an ogre.",
"id": 2878,
"members": true,
"lowalch": 52,
"limit": 6000,
"value": 130,
"highalch": 78,
"icon": "Cooked chompy.png",
"name": "Cooked chompy"
},
{
"examine": "Roasted chompy bird.",
"id": 7228,
"members": true,
"lowalch": 34,
"limit": 100,
"value": 85,
"highalch": 51,
"icon": "Cooked chompy (roasted).png",
"name": "Cooked chompy (roasted)"
},
{
"examine": "Nice and tasty!",
"id": 7521,
"members": true,
"lowalch": 20,
"limit": 6000,
"value": 50,
"highalch": 30,
"icon": "Cooked crab meat 5.png",
"name": "Cooked crab meat"
},
{
"highalch": 2,
"members": true,
"name": "Cooked dashing kebbit",
"examine": "I should eat this quickly.",
"id": 29134,
"value": 4,
"icon": "Cooked dashing kebbit.png",
"lowalch": 1
},
{
"highalch": 2,
"members": true,
"name": "Cooked graahk",
"examine": "Mmm, this looks tasty.",
"id": 29149,
"value": 4,
"icon": "Cooked graahk.png",
"lowalch": 1
},
{
"examine": "Lovely Jubbly!",
"id": 7568,
"members": true,
"lowalch": 60,
"limit": 10000,
"value": 150,
"highalch": 90,
"icon": "Cooked jubbly.png",
"name": "Cooked jubbly"
},
{
"examine": "Cooked octopus. It looks very nutritious.",
"id": 3144,
"members": true,
"lowalch": 100,
"limit": 10000,
"value": 250,
"highalch": 150,
"icon": "Cooked karambwan.png",
"name": "Cooked karambwan"
},
{
"highalch": 2,
"members": true,
"name": "Cooked kyatt",
"examine": "Mmm, this looks tasty.",
"id": 29152,
"value": 4,
"icon": "Cooked kyatt.png",
"lowalch": 1
},
{
"highalch": 2,
"members": true,
"name": "Cooked larupia",
"examine": "Mmm, this looks tasty.",
"id": 29146,
"value": 4,
"icon": "Cooked larupia.png",
"lowalch": 1
},
{
"examine": "Mmm this looks tasty.",
"id": 2142,
"members": false,
"lowalch": 1,
"limit": 6000,
"value": 4,
"highalch": 2,
"icon": "Cooked meat.png",
"name": "Cooked meat"
},
{
"examine": "Mmm, this looks tasty.",
"id": 29143,
"members": true,
"lowalch": 1,
"limit": 6000,
"value": 4,
"highalch": 2,
"icon": "Cooked moonlight antelope.png",
"name": "Cooked moonlight antelope"
},
{
"highalch": 1,
"members": true,
"name": "Cooked mystery meat",
"examine": "I don't want to think about what kind of meat it is.",
"id": 24785,
"value": 1,
"icon": "Cooked mystery meat.png",
"lowalch": 1
},
{
"examine": "Deliciously cooked oomlie meat in a palm leaf pouch.",
"id": 2343,
"members": true,
"lowalch": 14,
"limit": 15,
"value": 35,
"highalch": 21,
"icon": "Cooked oomlie wrap.png",
"name": "Cooked oomlie wrap"
},
{
"highalch": 2,
"members": true,
"name": "Cooked pyre fox",
"examine": "Mmm, this looks tasty.",
"id": 29137,
"value": 4,
"icon": "Cooked pyre fox.png",
"lowalch": 1
},
{
"examine": "Mmm this looks tasty.",
"id": 3228,
"members": true,
"lowalch": 1,
"limit": 6000,
"value": 4,
"highalch": 2,
"icon": "Cooked rabbit.png",
"name": "Cooked rabbit"
},
{
"examine": "A cooked slimy eel - not delicious, but pretty nutritious.",
"id": 3381,
"members": true,
"lowalch": 1,
"limit": 6000,
"value": 1,
"highalch": 1,
"icon": "Cooked slimy eel.png",
"name": "Cooked slimy eel"
},
{
"highalch": 2,
"members": true,
"name": "Cooked sunlight antelope",
"examine": "Mmm, this looks tasty.",
"id": 29140,
"value": 4,
"icon": "Cooked sunlight antelope.png",
"lowalch": 1
},
{
"examine": "Delicious cooked sweetcorn.",
"id": 5988,
"members": true,
"lowalch": 3,
"limit": 11000,
"value": 9,
"highalch": 5,
"icon": "Cooked sweetcorn.png",
"name": "Cooked sweetcorn"
},
{
"highalch": 2,
"members": true,
"name": "Cooked wild kebbit",
"examine": "Mmm, this looks tasty.",
"id": 29128,
"value": 4,
"icon": "Cooked wild kebbit.png",
"lowalch": 1
},
{
"examine": "Keeps the doctor away.",
"id": 1955,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Cooking apple.png",
"name": "Cooking apple"
},
{
"examine": "This needs refining.",
"id": 436,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 3,
"highalch": 1,
"icon": "Copper ore.png",
"name": "Copper ore"
},
{
"examine": "It's cornflour.",
"id": 7466,
"members": false,
"lowalch": 1,
"limit": 15,
"value": 1,
"highalch": 1,
"icon": "Cornflour.png",
"name": "Cornflour"
},
{
"highalch": 1,
"members": true,
"name": "Corrupted armadyl godsword",
"examine": "A beautiful, heavy sword.",
"id": 28537,
"value": 1,
"icon": "Corrupted armadyl godsword.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Corrupted dragon claws",
"examine": "A set of fighting claws.",
"id": 28534,
"value": 1,
"icon": "Corrupted dragon claws.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Corrupted scythe of vitur (uncharged)",
"examine": "A powerful scythe. It is currently uncharged.",
"id": 28545,
"value": 1,
"icon": "Corrupted scythe of vitur (uncharged).png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Corrupted tumeken's shadow (uncharged)",
"examine": "An ancient staff created using a higher power.",
"id": 28549,
"value": 1,
"icon": "Corrupted tumeken's shadow (uncharged).png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Corrupted twisted bow",
"examine": "A mystical bow carved from the twisted remains of the Great Olm.",
"id": 28540,
"value": 1,
"icon": "Corrupted twisted bow.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Corrupted voidwaker",
"examine": "A truly fearsome weapon.",
"id": 28531,
"value": 1,
"icon": "Corrupted voidwaker.png",
"lowalch": 1
},
{
"examine": "Used for enchant spells.",
"id": 564,
"members": false,
"lowalch": 20,
"limit": 18000,
"value": 50,
"highalch": 30,
"icon": "Cosmic rune.png",
"name": "Cosmic rune"
},
{
"examine": "A mysterious power emanates from the talisman...",
"id": 1454,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 4,
"highalch": 2,
"icon": "Cosmic talisman.png",
"name": "Cosmic talisman"
},
{
"examine": "A tiara infused with the properties of the cosmos.",
"id": 5539,
"members": true,
"lowalch": 40,
"limit": 40,
"value": 100,
"highalch": 60,
"icon": "Cosmic tiara.png",
"name": "Cosmic tiara"
},
{
"examine": "I should take this to the tannery.",
"id": 1739,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Cowhide.png",
"name": "Cowhide"
},
{
"examine": "This bow once belonged to a formidable follower of Armadyl.",
"id": 22547,
"members": true,
"lowalch": 48000,
"limit": 8,
"value": 120000,
"highalch": 72000,
"icon": "Craw's bow (u).png",
"name": "Craw's bow (u)"
},
{
"examine": "They're soft, silky and cream.",
"id": 632,
"members": true,
"lowalch": 80,
"limit": 150,
"value": 200,
"highalch": 120,
"icon": "Cream boots.png",
"name": "Cream boots"
},
{
"examine": "A silly cream pointed hat.",
"id": 662,
"members": true,
"lowalch": 64,
"limit": 150,
"value": 160,
"highalch": 96,
"icon": "Cream hat.png",
"name": "Cream hat"
},
{
"examine": "Made by Tree Gnomes with a thing for cream.",
"id": 652,
"members": true,
"lowalch": 72,
"limit": 150,
"value": 180,
"highalch": 108,
"icon": "Cream robe bottoms.png",
"name": "Cream robe bottoms"
},
{
"examine": "The ultimate in gnome design, now in cream!",
"id": 642,
"members": true,
"lowalch": 72,
"limit": 150,
"value": 180,
"highalch": 108,
"icon": "Cream robe top.png",
"name": "Cream robe top"
},
{
"examine": "For whom?",
"id": 20243,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Crier bell.png",
"name": "Crier bell"
},
{
"examine": "Don't shoot the messenger!",
"id": 20240,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Crier coat.png",
"name": "Crier coat"
},
{
"examine": "Hear ye! Hear ye!",
"id": 12319,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Crier hat.png",
"name": "Crier hat"
},
{
"examine": "This fires crossbow bolts.",
"id": 837,
"members": false,
"lowalch": 28,
"limit": 125,
"value": 70,
"highalch": 42,
"icon": "Crossbow.png",
"name": "Crossbow"
},
{
"examine": "A string for a crossbow.",
"id": 9438,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 1,
"highalch": 1,
"icon": "Crossbow string.png",
"name": "Crossbow string"
},
{
"examine": "How does it all fit in there?",
"id": 8496,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Crude chair (flatpack).png",
"name": "Crude chair (flatpack)"
},
{
"examine": "A shallow tray used for baking crunchies in.",
"id": 2165,
"members": true,
"lowalch": 4,
"limit": 40,
"value": 10,
"highalch": 6,
"icon": "Crunchy tray.png",
"name": "Crunchy tray"
},
{
"examine": "A crushed bird's nest.",
"id": 6693,
"members": true,
"lowalch": 80,
"limit": 11000,
"value": 200,
"highalch": 120,
"icon": "Crushed nest.png",
"name": "Crushed nest"
},
{
"examine": "Ground up superior dragon bones.",
"id": 21975,
"members": true,
"lowalch": 20,
"limit": 11000,
"value": 50,
"highalch": 30,
"icon": "Crushed superior dragon bones.png",
"name": "Crushed superior dragon bones"
},
{
"highalch": 1,
"members": false,
"name": "Crystal 2h axe",
"examine": "A woodcutter's axe.",
"id": 28220,
"value": 1,
"icon": "Crystal 2h axe.png",
"lowalch": 1
},
{
"highalch": 1,
"members": false,
"name": "Crystal 2h axe (inactive)",
"examine": "A woodcutter's axe.",
"id": 28223,
"value": 1,
"icon": "Crystal 2h axe (inactive).png",
"lowalch": 1
},
{
"examine": "A seed to be sung into the finest crystal armour.",
"id": 23956,
"members": true,
"lowalch": 200000,
"limit": 100,
"value": 500000,
"highalch": 300000,
"icon": "Crystal armour seed.png",
"name": "Crystal armour seed"
},
{
"examine": "How does it all fit in there?",
"id": 8624,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Crystal ball (flatpack).png",
"name": "Crystal ball (flatpack)"
},
{
"highalch": 18000,
"members": true,
"name": "Crystal grail",
"examine": "Smells of victory.",
"id": 24000,
"value": 30000,
"icon": "Crystal grail.png",
"lowalch": 12000
},
{
"examine": "A mysterious key for a mysterious chest.",
"id": 989,
"members": true,
"lowalch": 60,
"limit": 11000,
"value": 150,
"highalch": 90,
"icon": "Crystal key.png",
"name": "Crystal key"
},
{
"examine": "How does it all fit in there?",
"id": 8628,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Crystal of power (flatpack).png",
"name": "Crystal of power (flatpack)"
},
{
"examine": "A seed to be sung into the best tools of the craft.",
"id": 23953,
"members": true,
"lowalch": 200000,
"limit": 100,
"value": 500000,
"highalch": 300000,
"icon": "Crystal tool seed.png",
"name": "Crystal tool seed"
},
{
"examine": "A seed to be sung into the finest crystal weapons.",
"id": 4207,
"members": true,
"lowalch": 1,
"limit": 70,
"value": 1,
"highalch": 1,
"icon": "Crystal weapon seed.png",
"name": "Crystal weapon seed"
},
{
"examine": "It's hot!",
"id": 4460,
"members": true,
"lowalch": 4,
"limit": 13000,
"value": 10,
"highalch": 6,
"icon": "Cup of hot water.png",
"name": "Cup of hot water"
},
{
"examine": "A nice cup of tea.",
"id": 1978,
"members": true,
"lowalch": 4,
"limit": 2000,
"value": 10,
"highalch": 6,
"icon": "Cup of tea.png",
"name": "Cup of tea"
},
{
"examine": "A cup of water.",
"id": 4458,
"members": true,
"lowalch": 4,
"limit": 13000,
"value": 10,
"highalch": 6,
"icon": "Cup of water.png",
"name": "Cup of water"
},
{
"examine": "Ready to be cut and sewn into armour.",
"id": 10820,
"members": true,
"lowalch": 40,
"limit": 13000,
"value": 100,
"highalch": 60,
"icon": "Cured yak-hide.png",
"name": "Cured yak-hide"
},
{
"examine": "It's a spicy hot curry.",
"id": 2011,
"members": true,
"lowalch": 8,
"limit": 10000,
"value": 20,
"highalch": 12,
"icon": "Curry.png",
"name": "Curry"
},
{
"examine": "I could make a spicy curry with this.",
"id": 5970,
"members": true,
"lowalch": 7,
"limit": 11000,
"value": 19,
"highalch": 11,
"icon": "Curry leaf.png",
"name": "Curry leaf"
},
{
"examine": "This sapling is ready to be replanted in a fruit tree patch.",
"id": 5499,
"members": true,
"lowalch": 1,
"limit": 200,
"value": 1,
"highalch": 1,
"icon": "Curry sapling.png",
"name": "Curry sapling"
},
{
"examine": "Plant in a plantpot of soil to grow a sapling.",
"id": 5286,
"members": true,
"lowalch": 15,
"limit": 200,
"value": 39,
"highalch": 23,
"icon": "Curry tree seed 5.png",
"name": "Curry tree seed"
},
{
"highalch": 1,
"members": false,
"name": "Cursed amulet of magic",
"examine": "An cursed amulet of magic.",
"id": 29486,
"value": 1,
"icon": "Cursed amulet of magic.png",
"lowalch": 1
},
{
"examine": "You'd expect this to interfere with your 3D vision.",
"id": 19915,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Cyclops head.png",
"name": "Cyclops head"
},
{
"examine": "These would feed a dogfish for months!",
"id": 6729,
"members": true,
"lowalch": 60,
"limit": 7500,
"value": 150,
"highalch": 90,
"icon": "Dagannoth bones.png",
"name": "Dagannoth bones"
},
{
"examine": "A sturdy piece of dagannoth hide.",
"id": 6155,
"members": true,
"lowalch": 24,
"limit": 11000,
"value": 60,
"highalch": 36,
"icon": "Dagannoth hide.png",
"name": "Dagannoth hide"
},
{
"highalch": 10200,
"members": true,
"name": "Dagon'hai hat",
"examine": "A hat worn by members of the Dagon'hai.",
"id": 24288,
"value": 17000,
"icon": "Dagon'hai hat.png",
"lowalch": 6800
},
{
"highalch": 54000,
"members": true,
"name": "Dagon'hai robe bottom",
"examine": "A robe worn by members of the Dagon'hai.",
"id": 24294,
"value": 90000,
"icon": "Dagon'hai robe bottom.png",
"lowalch": 36000
},
{
"highalch": 84000,
"members": true,
"name": "Dagon'hai robe top",
"examine": "A robe worn by members of the Dagon'hai.",
"id": 24291,
"value": 140000,
"icon": "Dagon'hai robe top.png",
"lowalch": 56000
},
{
"highalch": 130200,
"members": true,
"name": "Dagon'hai robes set",
"examine": "A set containing a Dagon'hai hat, robe top and robe bottom.",
"id": 24333,
"value": 217000,
"icon": "Dagon'hai robes set.png",
"lowalch": 86800
},
{
"examine": "Smells like the north end of a southbound monkey, because it is.",
"id": 19665,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Damaged monkey tail.png",
"name": "Damaged monkey tail"
},
{
"examine": "A teleport to Dareeyak, in level 23 Wilderness.",
"id": 12777,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Dareeyak teleport (tablet).png",
"name": "Dareeyak teleport (tablet)"
},
{
"examine": "A bow from a darker dimension.",
"id": 11235,
"members": true,
"lowalch": 48000,
"limit": 8,
"value": 120002,
"highalch": 72001,
"icon": "Dark bow.png",
"name": "Dark bow"
},
{
"examine": "Bow ties are cool.",
"id": 19970,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Dark bow tie.png",
"name": "Dark bow tie"
},
{
"examine": "All for one and one for all!",
"id": 2641,
"members": true,
"lowalch": 80,
"limit": 4,
"value": 200,
"highalch": 120,
"icon": "Dark cavalier.png",
"name": "Dark cavalier"
},
{
"examine": "This looks tricky to eat.",
"id": 11936,
"members": true,
"lowalch": 128,
"limit": 10000,
"value": 320,
"highalch": 192,
"icon": "Dark crab.png",
"name": "Dark crab"
},
{
"examine": "What on earth would eat this?",
"id": 11940,
"members": true,
"lowalch": 1,
"limit": 8000,
"value": 3,
"highalch": 1,
"icon": "Dark fishing bait.png",
"name": "Dark fishing bait"
},
{
"examine": "Use on infinity pieces to recolour them!",
"id": 12528,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Dark infinity colour kit.png",
"name": "Dark infinity colour kit"
},
{
"examine": "Sleek, silent and furry.",
"id": 10115,
"members": true,
"lowalch": 84,
"limit": 10000,
"value": 210,
"highalch": 126,
"icon": "Dark kebbit fur.png",
"name": "Dark kebbit fur"
},
{
"examine": "Dark tuxedo trousers with white stockings.",
"id": 19964,
"members": true,
"lowalch": 2400,
"limit": 4,
"value": 6000,
"highalch": 3600,
"icon": "Dark trousers.png",
"name": "Dark trousers"
},
{
"examine": "Linked tuxedo cuffs with a dark trim.",
"id": 19961,
"members": true,
"lowalch": 1200,
"limit": 4,
"value": 3000,
"highalch": 1800,
"icon": "Dark tuxedo cuffs.png",
"name": "Dark tuxedo cuffs"
},
{
"examine": "A dark tuxedo jacket with a white shirt.",
"id": 19958,
"members": true,
"lowalch": 4000,
"limit": 4,
"value": 10000,
"highalch": 6000,
"icon": "Dark tuxedo jacket.png",
"name": "Dark tuxedo jacket"
},
{
"examine": "Dark shoes to match your dark tuxedo.",
"id": 19967,
"members": true,
"lowalch": 1200,
"limit": 4,
"value": 3000,
"highalch": 1800,
"icon": "Dark tuxedo shoes.png",
"name": "Dark tuxedo shoes"
},
{
"examine": "Nature's equivalent of go-faster stripes.",
"id": 10127,
"members": true,
"lowalch": 108,
"limit": 10000,
"value": 270,
"highalch": 162,
"icon": "Dashing kebbit fur.png",
"name": "Dashing kebbit fur"
},
{
"examine": "Used for medium level missile spells.",
"id": 560,
"members": false,
"lowalch": 72,
"limit": 25000,
"value": 180,
"highalch": 108,
"icon": "Death rune.png",
"name": "Death rune"
},
{
"examine": "A mysterious power emanates from the talisman...",
"id": 1456,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 4,
"highalch": 2,
"icon": "Death talisman.png",
"name": "Death talisman"
},
{
"examine": "A tiara infused with the properties of death.",
"id": 5547,
"members": true,
"lowalch": 40,
"limit": 40,
"value": 100,
"highalch": 60,
"icon": "Death tiara.png",
"name": "Death tiara"
},
{
"examine": "Elementary!",
"id": 12540,
"members": true,
"lowalch": 920,
"limit": 4,
"value": 2300,
"highalch": 1380,
"icon": "Deerstalker.png",
"name": "Deerstalker"
},
{
"examine": "One dose of fishy Defence potion.",
"id": 11459,
"members": true,
"lowalch": 24,
"limit": 2000,
"value": 60,
"highalch": 36,
"icon": "Defence mix(1).png",
"name": "Defence mix(1)"
},
{
"examine": "Two doses of fishy Defence potion.",
"id": 11457,
"members": true,
"lowalch": 36,
"limit": 2000,
"value": 90,
"highalch": 54,
"icon": "Defence mix(2).png",
"name": "Defence mix(2)"
},
{
"examine": "1 dose of Defence potion.",
"id": 137,
"members": true,
"lowalch": 24,
"limit": 2000,
"value": 60,
"highalch": 36,
"icon": "Defence potion(1).png",
"name": "Defence potion(1)"
},
{
"examine": "2 doses of Defence potion.",
"id": 135,
"members": true,
"lowalch": 36,
"limit": 2000,
"value": 90,
"highalch": 54,
"icon": "Defence potion(2).png",
"name": "Defence potion(2)"
},
{
"examine": "3 doses of Defence potion.",
"id": 133,
"members": true,
"lowalch": 48,
"limit": 2000,
"value": 120,
"highalch": 72,
"icon": "Defence potion(3).png",
"name": "Defence potion(3)"
},
{
"examine": "4 doses of Defence potion.",
"id": 2432,
"members": true,
"lowalch": 60,
"limit": 2000,
"value": 150,
"highalch": 90,
"icon": "Defence potion(4).png",
"name": "Defence potion(4)"
},
{
"examine": "Vicious demon slippers.",
"id": 23294,
"members": false,
"lowalch": 400,
"limit": 4,
"value": 1000,
"highalch": 600,
"icon": "Demon feet.png",
"name": "Demon feet"
},
{
"examine": "Comfortable desert shoes.",
"id": 1837,
"members": true,
"lowalch": 8,
"limit": 150,
"value": 20,
"highalch": 12,
"icon": "Desert boots.png",
"name": "Desert boots"
},
{
"highalch": 12,
"members": true,
"name": "Desert camo legs",
"examine": "These should make me harder to spot in desert areas.",
"id": 10063,
"value": 20,
"icon": "Desert camo legs.png",
"lowalch": 8
},
{
"highalch": 12,
"members": true,
"name": "Desert camo legs (equipped)",
"examine": "These should make me harder to spot in desert areas.",
"id": 28854,
"value": 20,
"icon": "Desert camo legs.png",
"lowalch": 8
},
{
"highalch": 12,
"members": true,
"name": "Desert camo top",
"examine": "This should make me harder to spot in desert areas.",
"id": 10061,
"value": 20,
"icon": "Desert camo top.png",
"lowalch": 8
},
{
"highalch": 12,
"members": true,
"name": "Desert camo top (equipped)",
"examine": "This should make me harder to spot in desert areas.",
"id": 28851,
"value": 20,
"icon": "Desert camo top.png",
"lowalch": 8
},
{
"examine": "Sandy coloured kebbit fur.",
"id": 10123,
"members": true,
"lowalch": 6,
"limit": 10000,
"value": 17,
"highalch": 10,
"icon": "Desert devil fur.png",
"name": "Desert devil fur"
},
{
"examine": "Not much good for blowing.",
"id": 9735,
"members": true,
"lowalch": 4,
"limit": 13000,
"value": 12,
"highalch": 7,
"icon": "Desert goat horn.png",
"name": "Desert goat horn"
},
{
"examine": "Better than factor 50 sun cream.",
"id": 6390,
"members": true,
"lowalch": 10,
"limit": 150,
"value": 25,
"highalch": 15,
"icon": "Desert legs.png",
"name": "Desert legs"
},
{
"examine": "A cool, light desert robe.",
"id": 1835,
"members": true,
"lowalch": 16,
"limit": 150,
"value": 40,
"highalch": 24,
"icon": "Desert robe.png",
"name": "Desert robe"
},
{
"examine": "Has a coarse hard wearing texture.",
"id": 6386,
"members": true,
"lowalch": 10,
"limit": 150,
"value": 25,
"highalch": 15,
"icon": "Desert robes.png",
"name": "Desert robes"
},
{
"examine": "A cool, light desert shirt.",
"id": 1833,
"members": true,
"lowalch": 16,
"limit": 150,
"value": 40,
"highalch": 24,
"icon": "Desert shirt.png",
"name": "Desert shirt"
},
{
"examine": "A bit itchy.",
"id": 6384,
"members": true,
"lowalch": 6,
"limit": 150,
"value": 15,
"highalch": 9,
"icon": "Desert top.png",
"name": "Desert top"
},
{
"examine": "Good for those cold desert nights.",
"id": 6388,
"members": true,
"lowalch": 14,
"limit": 150,
"value": 35,
"highalch": 21,
"icon": "Desert top (overcoat).png",
"name": "Desert top (overcoat)"
},
{
"examine": "A pair of Devout Boots.",
"id": 22954,
"members": true,
"lowalch": 8000,
"limit": 70,
"value": 20000,
"highalch": 12000,
"icon": "Devout boots.png",
"name": "Devout boots"
},
{
"examine": "Scrawled words that can invoke the power of the gods.",
"id": 21034,
"members": true,
"lowalch": 32000,
"limit": 5,
"value": 80000,
"highalch": 48000,
"icon": "Dexterous prayer scroll.png",
"name": "Dexterous prayer scroll"
},
{
"examine": "A set containing a Dharok's helm, platelegs, platebody and greataxe.",
"id": 12877,
"members": true,
"lowalch": 160000,
"limit": 8,
"value": 400000,
"highalch": 240000,
"icon": "Dharok's armour set.png",
"name": "Dharok's armour set"
},
{
"examine": "Dharok the Wretched's greataxe.",
"id": 4718,
"members": true,
"lowalch": 83200,
"limit": 15,
"value": 208000,
"highalch": 124800,
"icon": "Dharok's greataxe.png",
"name": "Dharok's greataxe"
},
{
"examine": "Dharok the Wretched's greataxe.",
"id": 4890,
"members": true,
"lowalch": 83200,
"limit": 15,
"value": 208000,
"highalch": 124800,
"icon": "Dharok's greataxe 0.png",
"name": "Dharok's greataxe 0"
},
{
"examine": "Dharok the Wretched's helm.",
"id": 4716,
"members": true,
"lowalch": 41200,
"limit": 15,
"value": 103000,
"highalch": 61800,
"icon": "Dharok's helm.png",
"name": "Dharok's helm"
},
{
"examine": "Dharok the Wretched's helm.",
"id": 4884,
"members": true,
"lowalch": 41200,
"limit": 15,
"value": 103000,
"highalch": 61800,
"icon": "Dharok's helm 0.png",
"name": "Dharok's helm 0"
},
{
"examine": "Dharok the Wretched's platebody armour.",
"id": 4720,
"members": true,
"lowalch": 112000,
"limit": 15,
"value": 280000,
"highalch": 168000,
"icon": "Dharok's platebody.png",
"name": "Dharok's platebody"
},
{
"examine": "Dharok the Wretched's platebody armour.",
"id": 4896,
"members": true,
"lowalch": 112000,
"limit": 15,
"value": 280000,
"highalch": 168000,
"icon": "Dharok's platebody 0.png",
"name": "Dharok's platebody 0"
},
{
"examine": "Dharok the Wretched's plate leg armour.",
"id": 4722,
"members": true,
"lowalch": 110000,
"limit": 15,
"value": 275000,
"highalch": 165000,
"icon": "Dharok's platelegs.png",
"name": "Dharok's platelegs"
},
{
"examine": "Dharok the Wretched's plate leg armour.",
"id": 4902,
"members": true,
"lowalch": 110000,
"limit": 15,
"value": 275000,
"highalch": 165000,
"icon": "Dharok's platelegs 0.png",
"name": "Dharok's platelegs 0"
},
{
"examine": "This looks valuable.",
"id": 1601,
"members": false,
"lowalch": 800,
"limit": 11000,
"value": 2000,
"highalch": 1200,
"icon": "Diamond.png",
"name": "Diamond"
},
{
"examine": "I wonder if I can get this enchanted.",
"id": 1700,
"members": false,
"lowalch": 1410,
"limit": 10000,
"value": 3525,
"highalch": 2115,
"icon": "Diamond amulet.png",
"name": "Diamond amulet"
},
{
"examine": "It needs a string so I can wear it.",
"id": 1681,
"members": false,
"lowalch": 1410,
"limit": 10000,
"value": 3525,
"highalch": 2115,
"icon": "Diamond amulet (u).png",
"name": "Diamond amulet (u)"
},
{
"examine": "Diamond bolt tips.",
"id": 9192,
"members": true,
"lowalch": 53,
"limit": 11000,
"value": 133,
"highalch": 79,
"icon": "Diamond bolt tips 5.png",
"name": "Diamond bolt tips"
},
{
"examine": "Diamond tipped Adamantite crossbow bolts.",
"id": 9340,
"members": true,
"lowalch": 76,
"limit": 11000,
"value": 192,
"highalch": 115,
"icon": "Diamond bolts 5.png",
"name": "Diamond bolts"
},
{
"examine": "Enchanted Diamond tipped Adamantite Crossbow Bolts.",
"id": 9243,
"members": true,
"lowalch": 84,
"limit": 11000,
"value": 211,
"highalch": 126,
"icon": "Diamond bolts (e) 5.png",
"name": "Diamond bolts (e)"
},
{
"examine": "I wonder if this is valuable.",
"id": 11092,
"members": true,
"lowalch": 1530,
"limit": 10000,
"value": 3825,
"highalch": 2295,
"icon": "Diamond bracelet.png",
"name": "Diamond bracelet"
},
{
"examine": "Dragon crossbow bolts, tipped with diamond.",
"id": 21969,
"members": true,
"lowalch": 340,
"limit": 11000,
"value": 850,
"highalch": 510,
"icon": "Diamond dragon bolts 5.png",
"name": "Diamond dragon bolts"
},
{
"examine": "Enchanted Dragon crossbow bolts, tipped with diamond.",
"id": 21946,
"members": true,
"lowalch": 352,
"limit": 11000,
"value": 880,
"highalch": 528,
"icon": "Diamond dragon bolts (e) 5.png",
"name": "Diamond dragon bolts (e)"
},
{
"examine": "I wonder if this is valuable.",
"id": 1662,
"members": false,
"lowalch": 1470,
"limit": 18000,
"value": 3675,
"highalch": 2205,
"icon": "Diamond necklace.png",
"name": "Diamond necklace"
},
{
"examine": "A valuable ring.",
"id": 1643,
"members": false,
"lowalch": 1410,
"limit": 10000,
"value": 3525,
"highalch": 2115,
"icon": "Diamond ring.png",
"name": "Diamond ring"
},
{
"examine": "Teleports you to the Digsite.",
"id": 12403,
"members": true,
"lowalch": 4,
"limit": 10000,
"value": 10,
"highalch": 6,
"icon": "Digsite teleport.png",
"name": "Digsite teleport"
},
{
"examine": "Is it... looking at me?",
"id": 21015,
"members": true,
"lowalch": 224000,
"limit": 8,
"value": 560000,
"highalch": 336000,
"icon": "Dinh's bulwark.png",
"name": "Dinh's bulwark"
},
{
"examine": "Used to get out of Thordur's blackhole.",
"id": 981,
"members": false,
"lowalch": 48,
"limit": 50,
"value": 120,
"highalch": 72,
"icon": "Disk of returning.png",
"name": "Disk of returning"
},
{
"examine": "1 dose of divine bastion potion.",
"id": 24644,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Divine bastion potion(1).png",
"name": "Divine bastion potion(1)"
},
{
"examine": "2 doses of divine bastion potion.",
"id": 24641,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Divine bastion potion(2).png",
"name": "Divine bastion potion(2)"
},
{
"examine": "3 doses of divine bastion potion.",
"id": 24638,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Divine bastion potion(3).png",
"name": "Divine bastion potion(3)"
},
{
"examine": "4 doses of divine bastion potion.",
"id": 24635,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Divine bastion potion(4).png",
"name": "Divine bastion potion(4)"
},
{
"examine": "1 dose of divine battlemage potion.",
"id": 24632,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Divine battlemage potion(1).png",
"name": "Divine battlemage potion(1)"
},
{
"examine": "2 doses of divine battlemage potion.",
"id": 24629,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Divine battlemage potion(2).png",
"name": "Divine battlemage potion(2)"
},
{
"examine": "3 doses of divine battlemage potion.",
"id": 24626,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Divine battlemage potion(3).png",
"name": "Divine battlemage potion(3)"
},
{
"examine": "4 doses of divine battlemage potion.",
"id": 24623,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Divine battlemage potion(4).png",
"name": "Divine battlemage potion(4)"
},
{
"examine": "1 dose of divine magic potion.",
"id": 23754,
"members": true,
"lowalch": 16,
"limit": 2000,
"value": 40,
"highalch": 24,
"icon": "Divine magic potion(1).png",
"name": "Divine magic potion(1)"
},
{
"examine": "2 doses of divine magic potion.",
"id": 23751,
"members": true,
"lowalch": 36,
"limit": 2000,
"value": 90,
"highalch": 54,
"icon": "Divine magic potion(2).png",
"name": "Divine magic potion(2)"
},
{
"examine": "3 doses of divine magic potion.",
"id": 23748,
"members": true,
"lowalch": 68,
"limit": 2000,
"value": 170,
"highalch": 102,
"icon": "Divine magic potion(3).png",
"name": "Divine magic potion(3)"
},
{
"examine": "4 doses of divine magic potion.",
"id": 23745,
"members": true,
"lowalch": 100,
"limit": 2000,
"value": 250,
"highalch": 150,
"icon": "Divine magic potion(4).png",
"name": "Divine magic potion(4)"
},
{
"examine": "1 dose of divine ranging potion.",
"id": 23742,
"members": true,
"lowalch": 16,
"limit": 2000,
"value": 40,
"highalch": 24,
"icon": "Divine ranging potion(1).png",
"name": "Divine ranging potion(1)"
},
{
"examine": "2 doses of divine ranging potion.",
"id": 23739,
"members": true,
"lowalch": 36,
"limit": 2000,
"value": 90,
"highalch": 54,
"icon": "Divine ranging potion(2).png",
"name": "Divine ranging potion(2)"
},
{
"examine": "3 doses of divine ranging potion.",
"id": 23736,
"members": true,
"lowalch": 68,
"limit": 2000,
"value": 170,
"highalch": 102,
"icon": "Divine ranging potion(3).png",
"name": "Divine ranging potion(3)"
},
{
"examine": "4 doses of divine ranging potion.",
"id": 23733,
"members": true,
"lowalch": 100,
"limit": 2000,
"value": 250,
"highalch": 150,
"icon": "Divine ranging potion(4).png",
"name": "Divine ranging potion(4)"
},
{
"examine": "1 dose of divine super attack potion.",
"id": 23706,
"members": true,
"lowalch": 16,
"limit": 2000,
"value": 40,
"highalch": 24,
"icon": "Divine super attack potion(1).png",
"name": "Divine super attack potion(1)"
},
{
"examine": "2 doses of divine super attack potion.",
"id": 23703,
"members": true,
"lowalch": 36,
"limit": 2000,
"value": 90,
"highalch": 54,
"icon": "Divine super attack potion(2).png",
"name": "Divine super attack potion(2)"
},
{
"examine": "3 doses of divine super attack potion.",
"id": 23700,
"members": true,
"lowalch": 68,
"limit": 2000,
"value": 170,
"highalch": 102,
"icon": "Divine super attack potion(3).png",
"name": "Divine super attack potion(3)"
},
{
"examine": "4 doses of divine super attack potion.",
"id": 23697,
"members": true,
"lowalch": 100,
"limit": 2000,
"value": 250,
"highalch": 150,
"icon": "Divine super attack potion(4).png",
"name": "Divine super attack potion(4)"
},
{
"examine": "1 dose of divine super combat potion.",
"id": 23694,
"members": true,
"lowalch": 16,
"limit": 2000,
"value": 40,
"highalch": 24,
"icon": "Divine super combat potion(1).png",
"name": "Divine super combat potion(1)"
},
{
"examine": "2 doses of divine super combat potion.",
"id": 23691,
"members": true,
"lowalch": 36,
"limit": 2000,
"value": 90,
"highalch": 54,
"icon": "Divine super combat potion(2).png",
"name": "Divine super combat potion(2)"
},
{
"examine": "3 doses of divine super combat potion.",
"id": 23688,
"members": true,
"lowalch": 68,
"limit": 2000,
"value": 170,
"highalch": 102,
"icon": "Divine super combat potion(3).png",
"name": "Divine super combat potion(3)"
},
{
"examine": "4 doses of divine super combat potion.",
"id": 23685,
"members": true,
"lowalch": 100,
"limit": 2000,
"value": 250,
"highalch": 150,
"icon": "Divine super combat potion(4).png",
"name": "Divine super combat potion(4)"
},
{
"examine": "1 dose of divine super defence potion.",
"id": 23730,
"members": true,
"lowalch": 16,
"limit": 2000,
"value": 40,
"highalch": 24,
"icon": "Divine super defence potion(1).png",
"name": "Divine super defence potion(1)"
},
{
"examine": "2 doses of divine super defence potion.",
"id": 23727,
"members": true,
"lowalch": 36,
"limit": 2000,
"value": 90,
"highalch": 54,
"icon": "Divine super defence potion(2).png",
"name": "Divine super defence potion(2)"
},
{
"examine": "3 doses of divine super defence potion.",
"id": 23724,
"members": true,
"lowalch": 68,
"limit": 2000,
"value": 170,
"highalch": 102,
"icon": "Divine super defence potion(3).png",
"name": "Divine super defence potion(3)"
},
{
"examine": "4 doses of divine super defence potion.",
"id": 23721,
"members": true,
"lowalch": 100,
"limit": 2000,
"value": 250,
"highalch": 150,
"icon": "Divine super defence potion(4).png",
"name": "Divine super defence potion(4)"
},
{
"examine": "1 dose of divine super strength potion.",
"id": 23718,
"members": true,
"lowalch": 16,
"limit": 2000,
"value": 40,
"highalch": 24,
"icon": "Divine super strength potion(1).png",
"name": "Divine super strength potion(1)"
},
{
"examine": "2 doses of divine super strength potion.",
"id": 23715,
"members": true,
"lowalch": 36,
"limit": 2000,
"value": 90,
"highalch": 54,
"icon": "Divine super strength potion(2).png",
"name": "Divine super strength potion(2)"
},
{
"examine": "3 doses of divine super strength potion.",
"id": 23712,
"members": true,
"lowalch": 68,
"limit": 2000,
"value": 170,
"highalch": 102,
"icon": "Divine super strength potion(3).png",
"name": "Divine super strength potion(3)"
},
{
"examine": "4 doses of divine super strength potion.",
"id": 23709,
"members": true,
"lowalch": 100,
"limit": 2000,
"value": 250,
"highalch": 150,
"icon": "Divine super strength potion(4).png",
"name": "Divine super strength potion(4)"
},
{
"examine": "Lets you get away with it - sometimes.",
"id": 21143,
"members": true,
"lowalch": 420,
"limit": 10000,
"value": 1050,
"highalch": 630,
"icon": "Dodgy necklace.png",
"name": "Dodgy necklace"
},
{
"examine": "A tasty herb, good for seasoning.",
"id": 1573,
"members": true,
"lowalch": 1,
"limit": 15,
"value": 2,
"highalch": 1,
"icon": "Doogle leaves.png",
"name": "Doogle leaves"
},
{
"examine": "This fires crossbow bolts.",
"id": 8880,
"members": true,
"lowalch": 800,
"limit": 70,
"value": 2000,
"highalch": 1200,
"icon": "Dorgeshuun crossbow.png",
"name": "Dorgeshuun crossbow"
},
{
"examine": "A black piece of cloth on a string.",
"id": 19727,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 2,
"highalch": 1,
"icon": "Double eye patch.png",
"name": "Double eye patch"
},
{
"examine": "It looks like this could be attached to a shield somehow.",
"id": 11286,
"members": true,
"lowalch": 300000,
"limit": 5,
"value": 750000,
"highalch": 450000,
"icon": "Draconic visage.png",
"name": "Draconic visage"
},
{
"examine": "A two-handed Dragon Sword.",
"id": 7158,
"members": true,
"lowalch": 88000,
"limit": 8,
"value": 220000,
"highalch": 132000,
"icon": "Dragon 2h sword.png",
"name": "Dragon 2h sword"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 21882,
"members": true,
"lowalch": 200000,
"limit": 8,
"value": 500000,
"highalch": 300000,
"icon": "Dragon armour set (lg).png",
"name": "Dragon armour set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 21885,
"members": true,
"lowalch": 200000,
"limit": 8,
"value": 500000,
"highalch": 300000,
"icon": "Dragon armour set (sk).png",
"name": "Dragon armour set (sk)"
},
{
"examine": "An arrow made using a dragon's talon.",
"id": 11212,
"members": true,
"lowalch": 320,
"limit": 11000,
"value": 800,
"highalch": 480,
"icon": "Dragon arrow 5.png",
"name": "Dragon arrow"
},
{
"examine": "Venomous-looking arrows.",
"id": 11227,
"members": true,
"lowalch": 320,
"limit": 11000,
"value": 800,
"highalch": 480,
"icon": "Dragon arrow(p) 5.png",
"name": "Dragon arrow(p)"
},
{
"examine": "Venomous-looking arrows.",
"id": 11228,
"members": true,
"lowalch": 320,
"limit": 11000,
"value": 800,
"highalch": 480,
"icon": "Dragon arrow(p+) 5.png",
"name": "Dragon arrow(p+)"
},
{
"examine": "Venomous-looking arrows.",
"id": 11229,
"members": true,
"lowalch": 320,
"limit": 11000,
"value": 800,
"highalch": 480,
"icon": "Dragon arrow(p++) 5.png",
"name": "Dragon arrow(p++)"
},
{
"examine": "Dragon talons, usable as arrowheads.",
"id": 11237,
"members": true,
"lowalch": 200,
"limit": 10000,
"value": 500,
"highalch": 300,
"icon": "Dragon arrowtips.png",
"name": "Dragon arrowtips"
},
{
"examine": "A very powerful axe.",
"id": 6739,
"members": true,
"lowalch": 22000,
"limit": 40,
"value": 55000,
"highalch": 33000,
"icon": "Dragon axe.png",
"name": "Dragon axe"
},
{
"examine": "A vicious looking axe.",
"id": 1377,
"members": true,
"lowalch": 80000,
"limit": 70,
"value": 200000,
"highalch": 120000,
"icon": "Dragon battleaxe.png",
"name": "Dragon battleaxe"
},
{
"examine": "A glass of bitter.",
"id": 1911,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 2,
"highalch": 1,
"icon": "Dragon bitter.png",
"name": "Dragon bitter"
},
{
"examine": "How does it all fit in there?",
"id": 8524,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Dragon bitter (flatpack).png",
"name": "Dragon bitter (flatpack)"
},
{
"examine": "This keg contains 4 pints of Dragon Bitter.",
"id": 5809,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Dragon bitter(4).png",
"name": "Dragon bitter(4)"
},
{
"examine": "This looks a good deal stronger than normal Dragon Bitter.",
"id": 5745,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 2,
"highalch": 1,
"icon": "Dragon bitter(m).png",
"name": "Dragon bitter(m)"
},
{
"examine": "This keg contains 4 pints of mature Dragon Bitter.",
"id": 5889,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Dragon bitter(m4).png",
"name": "Dragon bitter(m4)"
},
{
"examine": "Dragon crossbow bolts.",
"id": 21905,
"members": true,
"lowalch": 170,
"limit": 11000,
"value": 425,
"highalch": 255,
"icon": "Dragon bolts 5.png",
"name": "Dragon bolts"
},
{
"examine": "Some poisoned dragon bolts.",
"id": 21924,
"members": true,
"lowalch": 172,
"limit": 11000,
"value": 430,
"highalch": 258,
"icon": "Dragon bolts (p) 5.png",
"name": "Dragon bolts (p)"
},
{
"examine": "Some poisoned dragon bolts.",
"id": 21926,
"members": true,
"lowalch": 174,
"limit": 11000,
"value": 435,
"highalch": 261,
"icon": "Dragon bolts (p+) 5.png",
"name": "Dragon bolts (p+)"
},
{
"examine": "Some poisoned dragon bolts.",
"id": 21928,
"members": true,
"lowalch": 176,
"limit": 11000,
"value": 440,
"highalch": 264,
"icon": "Dragon bolts (p++) 5.png",
"name": "Dragon bolts (p++)"
},
{
"examine": "Unfeathered dragon crossbow bolts.",
"id": 21930,
"members": true,
"lowalch": 56,
"limit": 13000,
"value": 141,
"highalch": 84,
"icon": "Dragon bolts (unf) 5.png",
"name": "Dragon bolts (unf)"
},
{
"examine": "These would feed a dog for months!",
"id": 536,
"members": true,
"lowalch": 64,
"limit": 7500,
"value": 160,
"highalch": 96,
"icon": "Dragon bones.png",
"name": "Dragon bones"
},
{
"examine": "These will protect my feet.",
"id": 11840,
"members": true,
"lowalch": 8000,
"limit": 70,
"value": 20000,
"highalch": 12000,
"icon": "Dragon boots.png",
"name": "Dragon boots"
},
{
"examine": "Use on some dragon boots to make them look fancier!",
"id": 22231,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Dragon boots ornament kit.png",
"name": "Dragon boots ornament kit"
},
{
"examine": "An onyx topped cane.",
"id": 12373,
"members": true,
"lowalch": 12000,
"limit": 4,
"value": 30000,
"highalch": 18000,
"icon": "Dragon cane.png",
"name": "Dragon cane"
},
{
"examine": "A series of connected metal rings.",
"id": 3140,
"members": true,
"lowalch": 100000,
"limit": 70,
"value": 250000,
"highalch": 150000,
"icon": "Dragon chainbody.png",
"name": "Dragon chainbody"
},
{
"examine": "Use on a dragon chainbody to make it look fancier!",
"id": 12534,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Dragon chainbody ornament kit.png",
"name": "Dragon chainbody ornament kit"
},
{
"examine": "A set of fighting claws.",
"id": 13652,
"members": true,
"lowalch": 82000,
"limit": 8,
"value": 205000,
"highalch": 123000,
"icon": "Dragon claws.png",
"name": "Dragon claws"
},
{
"examine": "A dragon crossbow.",
"id": 21902,
"members": true,
"lowalch": 21600,
"limit": 70,
"value": 54000,
"highalch": 32400,
"icon": "Dragon crossbow.png",
"name": "Dragon crossbow"
},
{
"examine": "An unstrung dragon crossbow.",
"id": 21921,
"members": true,
"lowalch": 12000,
"limit": 10000,
"value": 30000,
"highalch": 18000,
"icon": "Dragon crossbow (u).png",
"name": "Dragon crossbow (u)"
},
{
"examine": "A powerful dagger.",
"id": 1215,
"members": true,
"lowalch": 12000,
"limit": 70,
"value": 30000,
"highalch": 18000,
"icon": "Dragon dagger.png",
"name": "Dragon dagger"
},
{
"examine": "A powerful dagger.",
"id": 1231,
"members": true,
"lowalch": 9600,
"limit": 70,
"value": 24000,
"highalch": 14400,
"icon": "Dragon dagger(p).png",
"name": "Dragon dagger(p)"
},
{
"examine": "A powerful dagger.",
"id": 5680,
"members": true,
"lowalch": 9600,
"limit": 70,
"value": 24000,
"highalch": 14400,
"icon": "Dragon dagger(p+).png",
"name": "Dragon dagger(p+)"
},
{
"examine": "A powerful dagger.",
"id": 5698,
"members": true,
"lowalch": 9600,
"limit": 70,
"value": 24000,
"highalch": 14400,
"icon": "Dragon dagger(p++).png",
"name": "Dragon dagger(p++)"
},
{
"examine": "A deadly throwing dart with a dragon tip.",
"id": 11230,
"members": true,
"lowalch": 200,
"limit": 11000,
"value": 500,
"highalch": 300,
"icon": "Dragon dart.png",
"name": "Dragon dart"
},
{
"examine": "A deadly looking dragon dart tip - needs feathers for flight.",
"id": 11232,
"members": true,
"lowalch": 100,
"limit": 11000,
"value": 250,
"highalch": 150,
"icon": "Dragon dart tip.png",
"name": "Dragon dart tip"
},
{
"examine": "A deadly poisoned dart with a dragon tip.",
"id": 11231,
"members": true,
"lowalch": 200,
"limit": 11000,
"value": 500,
"highalch": 300,
"icon": "Dragon dart(p).png",
"name": "Dragon dart(p)"
},
{
"examine": "A deadly poisoned dart with a dragon tip.",
"id": 11233,
"members": true,
"lowalch": 200,
"limit": 11000,
"value": 500,
"highalch": 300,
"icon": "Dragon dart(p+).png",
"name": "Dragon dart(p+)"
},
{
"examine": "A deadly poisoned dart with a dragon tip.",
"id": 11234,
"members": true,
"lowalch": 200,
"limit": 11000,
"value": 500,
"highalch": 300,
"icon": "Dragon dart(p++).png",
"name": "Dragon dart(p++)"
},
{
"examine": "Use on the dragon defender to trim it with gold.",
"id": 20143,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Dragon defender ornament kit.png",
"name": "Dragon defender ornament kit"
},
{
"highalch": 33000,
"members": true,
"name": "Dragon felling axe",
"examine": "A woodcutter's axe.",
"id": 28217,
"value": 55000,
"icon": "Dragon felling axe.png",
"lowalch": 22000
},
{
"examine": "Protects your head and looks impressive too.",
"id": 11335,
"members": true,
"lowalch": 60000,
"limit": 8,
"value": 150000,
"highalch": 90000,
"icon": "Dragon full helm.png",
"name": "Dragon full helm"
},
{
"examine": "Use on a dragon full helm to make it look fancier!",
"id": 12538,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Dragon full helm ornament kit.png",
"name": "Dragon full helm ornament kit"
},
{
"examine": "A dragon halberd.",
"id": 3204,
"members": true,
"lowalch": 100000,
"limit": 70,
"value": 250000,
"highalch": 150000,
"icon": "Dragon halberd.png",
"name": "Dragon halberd"
},
{
"examine": "A very powerful harpoon.",
"id": 21028,
"members": true,
"lowalch": 22000,
"limit": 5,
"value": 55000,
"highalch": 33000,
"icon": "Dragon harpoon.png",
"name": "Dragon harpoon"
},
{
"examine": "A one-handed dragon hasta.",
"id": 22731,
"members": true,
"lowalch": 24960,
"limit": 70,
"value": 62400,
"highalch": 37440,
"icon": "Dragon hasta.png",
"name": "Dragon hasta"
},
{
"examine": "A poison-tipped, one-handed dragon hasta",
"id": 22734,
"members": true,
"lowalch": 24960,
"limit": 70,
"value": 62400,
"highalch": 37440,
"icon": "Dragon hasta(p).png",
"name": "Dragon hasta(p)"
},
{
"examine": "A poison-tipped, one-handed dragon hasta",
"id": 22737,
"members": true,
"lowalch": 24960,
"limit": 70,
"value": 62400,
"highalch": 37440,
"icon": "Dragon hasta(p+).png",
"name": "Dragon hasta(p+)"
},
{
"examine": "A poison-tipped, one-handed dragon hasta",
"id": 22740,
"members": true,
"lowalch": 24960,
"limit": 70,
"value": 62400,
"highalch": 37440,
"icon": "Dragon hasta(p++).png",
"name": "Dragon hasta(p++)"
},
{
"examine": "A crossbow used for dragon hunting.",
"id": 21012,
"members": true,
"lowalch": 32000,
"limit": 8,
"value": 80000,
"highalch": 48000,
"icon": "Dragon hunter crossbow.png",
"name": "Dragon hunter crossbow"
},
{
"examine": "A lance that is exceptionally good at killing dragons and their ilk.",
"id": 22978,
"members": true,
"lowalch": 120000,
"limit": 8,
"value": 300000,
"highalch": 180000,
"icon": "Dragon hunter lance.png",
"name": "Dragon hunter lance"
},
{
"examine": "Dragon impling in a jar.",
"id": 11256,
"members": true,
"lowalch": 20,
"limit": 18000,
"value": 50,
"highalch": 30,
"icon": "Dragon impling jar.png",
"name": "Dragon impling jar"
},
{
"examine": "A dragon tipped javelin.",
"id": 19484,
"members": true,
"lowalch": 180,
"limit": 11000,
"value": 450,
"highalch": 270,
"icon": "Dragon javelin.png",
"name": "Dragon javelin"
},
{
"examine": "Needs a shaft.",
"id": 19582,
"members": true,
"lowalch": 780,
"limit": 10000,
"value": 1950,
"highalch": 1170,
"icon": "Dragon javelin heads 5.png",
"name": "Dragon javelin heads"
},
{
"examine": "A dragon tipped javelin.",
"id": 19486,
"members": true,
"lowalch": 180,
"limit": 11000,
"value": 450,
"highalch": 270,
"icon": "Dragon javelin(p).png",
"name": "Dragon javelin(p)"
},
{
"examine": "A dragon tipped javelin.",
"id": 19488,
"members": true,
"lowalch": 180,
"limit": 11000,
"value": 450,
"highalch": 270,
"icon": "Dragon javelin(p+).png",
"name": "Dragon javelin(p+)"
},
{
"examine": "A dragon tipped javelin.",
"id": 19490,
"members": true,
"lowalch": 180,
"limit": 11000,
"value": 450,
"highalch": 270,
"icon": "Dragon javelin(p++).png",
"name": "Dragon javelin(p++)"
},
{
"examine": "An ancient and powerful looking Dragon Kiteshield.",
"id": 21895,
"members": true,
"lowalch": 640000,
"limit": 70,
"value": 1600000,
"highalch": 960000,
"icon": "Dragon kiteshield.png",
"name": "Dragon kiteshield"
},
{
"examine": "Use on a dragon kiteshield to make it look fancier!",
"id": 22239,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Dragon kiteshield ornament kit.png",
"name": "Dragon kiteshield ornament kit"
},
{
"examine": "A finely balanced and powerful throwing knife.",
"id": 22804,
"members": true,
"lowalch": 66,
"limit": 11000,
"value": 167,
"highalch": 100,
"icon": "Dragon knife.png",
"name": "Dragon knife"
},
{
"examine": "A finely balanced and powerful throwing knife.",
"id": 22806,
"members": true,
"lowalch": 66,
"limit": 7000,
"value": 166,
"highalch": 99,
"icon": "Dragon knife(p).png",
"name": "Dragon knife(p)"
},
{
"examine": "A finely balanced and powerful throwing knife.",
"id": 22808,
"members": true,
"lowalch": 66,
"limit": 7000,
"value": 166,
"highalch": 99,
"icon": "Dragon knife(p+).png",
"name": "Dragon knife(p+)"
},
{
"examine": "A finely balanced and powerful throwing knife.",
"id": 22810,
"members": true,
"lowalch": 66,
"limit": 7000,
"value": 166,
"highalch": 99,
"icon": "Dragon knife(p++).png",
"name": "Dragon knife(p++)"
},
{
"examine": "Use on dragon platelegs or plateskirt to make them look fancier!",
"id": 12536,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Dragon legs+skirt ornament kit.png",
"name": "Dragon legs/skirt ornament kit"
},
{
"examine": "A pair of dragon crossbow limbs.",
"id": 21918,
"members": true,
"lowalch": 14000,
"limit": 10000,
"value": 35000,
"highalch": 21000,
"icon": "Dragon limbs.png",
"name": "Dragon limbs"
},
{
"examine": "A very powerful sword.",
"id": 1305,
"members": true,
"lowalch": 40000,
"limit": 70,
"value": 100000,
"highalch": 60000,
"icon": "Dragon longsword.png",
"name": "Dragon longsword"
},
{
"examine": "A spiky mace.",
"id": 1434,
"members": true,
"lowalch": 20000,
"limit": 70,
"value": 50000,
"highalch": 30000,
"icon": "Dragon mace.png",
"name": "Dragon mace"
},
{
"examine": "Makes the wearer pretty intimidating.",
"id": 1149,
"members": true,
"lowalch": 40000,
"limit": 8,
"value": 100000,
"highalch": 60000,
"icon": "Dragon med helm.png",
"name": "Dragon med helm"
},
{
"examine": "A badly damaged lump of dragon metal.",
"id": 22103,
"members": true,
"lowalch": 60000,
"limit": 50,
"value": 150000,
"highalch": 90000,
"icon": "Dragon metal lump.png",
"name": "Dragon metal lump"
},
{
"examine": "A badly damaged shard of dragon metal.",
"id": 22097,
"members": true,
"lowalch": 480000,
"limit": 50,
"value": 1200000,
"highalch": 720000,
"icon": "Dragon metal shard.png",
"name": "Dragon metal shard"
},
{
"examine": "A badly damaged slice of dragon metal.",
"id": 22100,
"members": true,
"lowalch": 40000,
"limit": 50,
"value": 100000,
"highalch": 60000,
"icon": "Dragon metal slice.png",
"name": "Dragon metal slice"
},
{
"examine": "I wonder if this is valuable.",
"id": 1664,
"members": true,
"lowalch": 7350,
"limit": 10000,
"value": 18375,
"highalch": 11025,
"icon": "Dragon necklace.png",
"name": "Dragon necklace"
},
{
"examine": "If rocks could feel fear, they'd fear this.",
"id": 11920,
"members": true,
"lowalch": 39180,
"limit": 40,
"value": 97950,
"highalch": 58770,
"icon": "Dragon pickaxe.png",
"name": "Dragon pickaxe"
},
{
"examine": "Makes a dragon pickaxe more beautiful.",
"id": 12800,
"members": true,
"lowalch": 640,
"limit": 50,
"value": 1600,
"highalch": 960,
"icon": "Dragon pickaxe upgrade kit.png",
"name": "Dragon pickaxe upgrade kit"
},
{
"examine": "Provides excellent protection.",
"id": 21892,
"members": true,
"lowalch": 800000,
"limit": 70,
"value": 2000000,
"highalch": 1200000,
"icon": "Dragon platebody.png",
"name": "Dragon platebody"
},
{
"examine": "Use on a dragon platebody to make it look fancier!",
"id": 22236,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Dragon platebody ornament kit.png",
"name": "Dragon platebody ornament kit"
},
{
"examine": "These look pretty heavy.",
"id": 4087,
"members": true,
"lowalch": 108000,
"limit": 70,
"value": 270000,
"highalch": 162000,
"icon": "Dragon platelegs.png",
"name": "Dragon platelegs"
},
{
"examine": "This looks pretty heavy.",
"id": 4585,
"members": true,
"lowalch": 108000,
"limit": 70,
"value": 270000,
"highalch": 162000,
"icon": "Dragon plateskirt.png",
"name": "Dragon plateskirt"
},
{
"examine": "Finely ground scale of Dragon.",
"id": 241,
"members": true,
"lowalch": 20,
"limit": 13000,
"value": 52,
"highalch": 31,
"icon": "Dragon scale dust.png",
"name": "Dragon scale dust"
},
{
"examine": "A vicious, curved sword.",
"id": 4587,
"members": true,
"lowalch": 40000,
"limit": 70,
"value": 100000,
"highalch": 60000,
"icon": "Dragon scimitar.png",
"name": "Dragon scimitar"
},
{
"examine": "Use on the dragon scimitar to trim it with gold.",
"id": 20002,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Dragon scimitar ornament kit.png",
"name": "Dragon scimitar ornament kit"
},
{
"examine": "A dragon tipped spear.",
"id": 1249,
"members": true,
"lowalch": 24960,
"limit": 70,
"value": 62400,
"highalch": 37440,
"icon": "Dragon spear.png",
"name": "Dragon spear"
},
{
"examine": "A poisoned dragon tipped spear.",
"id": 1263,
"members": true,
"lowalch": 24960,
"limit": 70,
"value": 62400,
"highalch": 37440,
"icon": "Dragon spear(p).png",
"name": "Dragon spear(p)"
},
{
"examine": "A poisoned dragon tipped spear.",
"id": 5716,
"members": true,
"lowalch": 24960,
"limit": 70,
"value": 62400,
"highalch": 37440,
"icon": "Dragon spear(p+).png",
"name": "Dragon spear(p+)"
},
{
"examine": "A poisoned dragon tipped spear.",
"id": 5730,
"members": true,
"lowalch": 24960,
"limit": 70,
"value": 62400,
"highalch": 37440,
"icon": "Dragon spear(p++).png",
"name": "Dragon spear(p++)"
},
{
"examine": "An ancient and powerful looking Dragon Square shield.",
"id": 1187,
"members": true,
"lowalch": 200000,
"limit": 70,
"value": 500000,
"highalch": 300000,
"icon": "Dragon sq shield.png",
"name": "Dragon sq shield"
},
{
"examine": "Use on a dragon square shield to make it look fancier!",
"id": 12532,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Dragon sq shield ornament kit.png",
"name": "Dragon sq shield ornament kit"
},
{
"examine": "A razor sharp sword.",
"id": 21009,
"members": true,
"lowalch": 28800,
"limit": 70,
"value": 72001,
"highalch": 43200,
"icon": "Dragon sword.png",
"name": "Dragon sword"
},
{
"examine": "A razor sharp throwing axe.",
"id": 20849,
"members": true,
"lowalch": 300,
"limit": 11000,
"value": 750,
"highalch": 450,
"icon": "Dragon thrownaxe.png",
"name": "Dragon thrownaxe"
},
{
"examine": "Spikey, close combat weapon.",
"id": 13576,
"members": true,
"lowalch": 48000,
"limit": 8,
"value": 120000,
"highalch": 72000,
"icon": "Dragon warhammer.png",
"name": "Dragon warhammer"
},
{
"examine": "A necklace made out of dragon bones.",
"id": 22111,
"members": true,
"lowalch": 32000,
"limit": 8,
"value": 80000,
"highalch": 48000,
"icon": "Dragonbone necklace.png",
"name": "Dragonbone necklace"
},
{
"examine": "A heavy shield with a snarling, draconic visage.",
"id": 11284,
"members": true,
"lowalch": 800000,
"limit": 8,
"value": 2000000,
"highalch": 1200000,
"icon": "Dragonfire shield (uncharged).png",
"name": "Dragonfire shield"
},
{
"examine": "A light shield with a haunting, skeletal visage.",
"id": 22003,
"members": true,
"lowalch": 800000,
"limit": 8,
"value": 2000000,
"highalch": 1200000,
"icon": "Dragonfire ward (uncharged).png",
"name": "Dragonfire ward"
},
{
"examine": "A large ripe dragonfruit.",
"id": 22929,
"members": true,
"lowalch": 38,
"limit": 11000,
"value": 95,
"highalch": 57,
"icon": "Dragonfruit.png",
"name": "Dragonfruit"
},
{
"examine": "Mmm dragonfruit pie.",
"id": 22795,
"members": true,
"lowalch": 28,
"limit": 10000,
"value": 70,
"highalch": 42,
"icon": "Dragonfruit pie.png",
"name": "Dragonfruit pie"
},
{
"examine": "This sapling is ready to be replanted in a fruit tree patch.",
"id": 22866,
"members": true,
"lowalch": 1,
"limit": 200,
"value": 1,
"highalch": 1,
"icon": "Dragonfruit sapling.png",
"name": "Dragonfruit sapling"
},
{
"examine": "Plant in a plantpot of soil to grow a sapling.",
"id": 22877,
"members": true,
"lowalch": 172,
"limit": 200,
"value": 430,
"highalch": 258,
"icon": "Dragonfruit tree seed 5.png",
"name": "Dragonfruit tree seed"
},
{
"examine": "This looks valuable.",
"id": 1615,
"members": true,
"lowalch": 4000,
"limit": 11000,
"value": 10000,
"highalch": 6000,
"icon": "Dragonstone.png",
"name": "Dragonstone"
},
{
"examine": "I wonder if I can get this enchanted.",
"id": 1702,
"members": true,
"lowalch": 7050,
"limit": 10000,
"value": 17625,
"highalch": 10575,
"icon": "Dragonstone amulet.png",
"name": "Dragonstone amulet"
},
{
"examine": "It needs a string so I can wear it.",
"id": 1683,
"members": true,
"lowalch": 7050,
"limit": 10000,
"value": 17625,
"highalch": 10575,
"icon": "Dragonstone amulet (u).png",
"name": "Dragonstone amulet (u)"
},
{
"highalch": 48000,
"members": true,
"name": "Dragonstone armour set",
"examine": "A set containing dragonstone helmet, platebody, platelegs, boots and gauntlets.",
"id": 23667,
"value": 80000,
"icon": "Dragonstone armour set.png",
"lowalch": 32000
},
{
"examine": "Dragonstone bolt tips.",
"id": 9193,
"members": true,
"lowalch": 266,
"limit": 11000,
"value": 667,
"highalch": 400,
"icon": "Dragonstone bolt tips 5.png",
"name": "Dragonstone bolt tips"
},
{
"examine": "Dragonstone tipped Runite crossbow bolts.",
"id": 9341,
"members": true,
"lowalch": 386,
"limit": 11000,
"value": 967,
"highalch": 580,
"icon": "Dragonstone bolts 5.png",
"name": "Dragonstone bolts"
},
{
"examine": "Enchanted Dragonstone tipped Runite Crossbow Bolts.",
"id": 9244,
"members": true,
"lowalch": 425,
"limit": 11000,
"value": 1063,
"highalch": 637,
"icon": "Dragonstone bolts (e) 5.png",
"name": "Dragonstone bolts (e)"
},
{
"highalch": 7500,
"members": true,
"name": "Dragonstone boots",
"examine": "A stylish pair of rune boots inlaid with dragonstones.",
"id": 24043,
"value": 12500,
"icon": "Dragonstone boots.png",
"lowalch": 5000
},
{
"examine": "I wonder if this is valuable.",
"id": 11115,
"members": true,
"lowalch": 7650,
"limit": 10000,
"value": 19125,
"highalch": 11475,
"icon": "Dragonstone bracelet.png",
"name": "Dragonstone bracelet"
},
{
"examine": "Dragon crossbow bolts, tipped with dragonstone. Double dragon!",
"id": 21971,
"members": true,
"lowalch": 398,
"limit": 11000,
"value": 995,
"highalch": 597,
"icon": "Dragonstone dragon bolts 5.png",
"name": "Dragonstone dragon bolts"
},
{
"examine": "Enchanted Dragon crossbow bolts, tipped with dragonstone. Double dragon!",
"id": 21948,
"members": true,
"lowalch": 460,
"limit": 11000,
"value": 1150,
"highalch": 690,
"icon": "Dragonstone dragon bolts (e) 5.png",
"name": "Dragonstone dragon bolts (e)"
},
{
"highalch": 21120,
"members": false,
"name": "Dragonstone full helm",
"examine": "A stylish rune full helm inlaid with dragonstones.",
"id": 24034,
"value": 35200,
"icon": "Dragonstone full helm.png",
"lowalch": 14080
},
{
"highalch": 7500,
"members": true,
"name": "Dragonstone gauntlets",
"examine": "A stylish pair of rune gauntlets inlaid with dragonstones.",
"id": 24046,
"value": 12500,
"icon": "Dragonstone gauntlets.png",
"lowalch": 5000
},
{
"highalch": 39000,
"members": false,
"name": "Dragonstone platebody",
"examine": "A stylish rune platebody inlaid with dragonstones.",
"id": 24037,
"value": 65000,
"icon": "Dragonstone platebody.png",
"lowalch": 26000
},
{
"highalch": 38400,
"members": false,
"name": "Dragonstone platelegs",
"examine": "A stylish pair of rune platelegs inlaid with dragonstones.",
"id": 24040,
"value": 64000,
"icon": "Dragonstone platelegs.png",
"lowalch": 25600
},
{
"examine": "A valuable ring.",
"id": 1645,
"members": true,
"lowalch": 7050,
"limit": 10000,
"value": 17625,
"highalch": 10575,
"icon": "Dragonstone ring.png",
"name": "Dragonstone ring"
},
{
"examine": "The bones of a ferocious Drake.",
"id": 22783,
"members": true,
"lowalch": 64,
"limit": 7500,
"value": 160,
"highalch": 96,
"icon": "Drake bones.png",
"name": "Drake bones"
},
{
"examine": "The claw of a ferocious drake. They could be combined with the boots of stone.",
"id": 22957,
"members": true,
"lowalch": 40000,
"limit": 15,
"value": 100000,
"highalch": 60000,
"icon": "Drake's claw.png",
"name": "Drake's claw"
},
{
"examine": "The tooth of a ferocious drake. They could be combined with holy sandals.",
"id": 22960,
"members": true,
"lowalch": 40000,
"limit": 70,
"value": 100000,
"highalch": 60000,
"icon": "Drake's tooth.png",
"name": "Drake's tooth"
},
{
"examine": "A teleport to Draynor Manor.",
"id": 19615,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Draynor manor teleport (tablet).png",
"name": "Draynor manor teleport (tablet)"
},
{
"examine": "A very large net, too big to be used by hand.",
"id": 21652,
"members": true,
"lowalch": 8,
"limit": 600,
"value": 20,
"highalch": 12,
"icon": "Drift net.png",
"name": "Drift net"
},
{
"examine": "Keeps a druid's knees nice and warm.",
"id": 538,
"members": true,
"lowalch": 12,
"limit": 125,
"value": 30,
"highalch": 18,
"icon": "Druid's robe.png",
"name": "Druid's robe"
},
{
"examine": "I feel closer to the gods when I am wearing this.",
"id": 540,
"members": true,
"lowalch": 16,
"limit": 125,
"value": 40,
"highalch": 24,
"icon": "Druid's robe top.png",
"name": "Druid's robe top"
},
{
"examine": "A warm creamy alcoholic beverage.",
"id": 2092,
"members": true,
"lowalch": 12,
"limit": 2000,
"value": 30,
"highalch": 18,
"icon": "Drunk dragon.png",
"name": "Drunk dragon"
},
{
"highalch": 174006,
"members": true,
"name": "Dual macuahuitl",
"examine": "A brutal pair.",
"id": 28997,
"value": 290010,
"icon": "Dual macuahuitl.png",
"lowalch": 116004
},
{
"examine": "A pair of aggressive Sai.",
"id": 23206,
"members": true,
"lowalch": 2400,
"limit": 8,
"value": 6000,
"highalch": 3600,
"icon": "Dual sai.png",
"name": "Dual sai"
},
{
"examine": "It's a slightly magical stick.",
"id": 20736,
"members": true,
"lowalch": 6200,
"limit": 8,
"value": 15500,
"highalch": 9300,
"icon": "Dust battlestaff.png",
"name": "Dust battlestaff"
},
{
"examine": "A combined Air and Earth Rune.",
"id": 4696,
"members": true,
"lowalch": 8,
"limit": 18000,
"value": 20,
"highalch": 12,
"icon": "Dust rune.png",
"name": "Dust rune"
},
{
"examine": "A set containing the four parts of the Dwarf Multicannon.",
"id": 12863,
"members": true,
"lowalch": 240000,
"limit": 40,
"value": 600000,
"highalch": 360000,
"icon": "Dwarf cannon set.png",
"name": "Dwarf cannon set"
},
{
"examine": "A powerful herb.",
"id": 267,
"members": true,
"lowalch": 28,
"limit": 11000,
"value": 70,
"highalch": 42,
"icon": "Dwarf weed.png",
"name": "Dwarf weed"
},
{
"examine": "I need another ingredient to finish this Dwarf Weed potion.",
"id": 109,
"members": true,
"lowalch": 28,
"limit": 10000,
"value": 70,
"highalch": 42,
"icon": "Dwarf weed potion (unf).png",
"name": "Dwarf weed potion (unf)"
},
{
"examine": "A dwarf weed seed - plant in a herb patch.",
"id": 5303,
"members": true,
"lowalch": 2,
"limit": 200,
"value": 6,
"highalch": 3,
"icon": "Dwarf weed seed 5.png",
"name": "Dwarf weed seed"
},
{
"examine": "A sturdy helmet that belonged to Rupert the Beard.",
"id": 11200,
"members": true,
"lowalch": 24000,
"limit": 50,
"value": 60000,
"highalch": 36000,
"icon": "Dwarven helmet.png",
"name": "Dwarven helmet"
},
{
"examine": "A pint of thick dark beer.",
"id": 1913,
"members": false,
"lowalch": 1,
"limit": 2000,
"value": 2,
"highalch": 1,
"icon": "Dwarven stout.png",
"name": "Dwarven stout"
},
{
"examine": "This keg contains 4 pints of Dwarven Stout.",
"id": 5777,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Dwarven stout(4).png",
"name": "Dwarven stout(4)"
},
{
"examine": "This looks a good deal stronger than normal Dwarven Stout.",
"id": 5747,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 2,
"highalch": 1,
"icon": "Dwarven stout(m).png",
"name": "Dwarven stout(m)"
},
{
"examine": "This keg contains 4 pints of mature Dwarven Stout.",
"id": 5857,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Dwarven stout(m4).png",
"name": "Dwarven stout(m4)"
},
{
"examine": "Some rather pretty blue berries.",
"id": 2126,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 4,
"highalch": 2,
"icon": "Dwellberries.png",
"name": "Dwellberries"
},
{
"examine": "A dwellberry bush seed - plant in a bush patch.",
"id": 5103,
"members": true,
"lowalch": 10,
"limit": 600,
"value": 25,
"highalch": 15,
"icon": "Dwellberry seed 5.png",
"name": "Dwellberry seed"
},
{
"examine": "A pot of dynamite for blast mining, with a fuse ready to light.",
"id": 13573,
"members": true,
"lowalch": 20,
"limit": 13000,
"value": 50,
"highalch": 30,
"icon": "Dynamite.png",
"name": "Dynamite"
},
{
"examine": "These will protect my ears from loud noise.",
"id": 4166,
"members": true,
"lowalch": 80,
"limit": 70,
"value": 200,
"highalch": 120,
"icon": "Earmuffs.png",
"name": "Earmuffs"
},
{
"examine": "It's a slightly magical stick.",
"id": 1399,
"members": true,
"lowalch": 6200,
"limit": 18000,
"value": 15500,
"highalch": 9300,
"icon": "Earth battlestaff.png",
"name": "Earth battlestaff"
},
{
"examine": "Earth impling in a jar.",
"id": 11244,
"members": true,
"lowalch": 20,
"limit": 18000,
"value": 50,
"highalch": 30,
"icon": "Earth impling jar.png",
"name": "Earth impling jar"
},
{
"examine": "A magic glowing orb.",
"id": 575,
"members": true,
"lowalch": 120,
"limit": 11000,
"value": 300,
"highalch": 180,
"icon": "Earth orb.png",
"name": "Earth orb"
},
{
"examine": "One of the 4 basic elemental Runes.",
"id": 557,
"members": false,
"lowalch": 1,
"limit": 50000,
"value": 4,
"highalch": 2,
"icon": "Earth rune.png",
"name": "Earth rune"
},
{
"examine": "A mysterious power emanates from the talisman...",
"id": 1440,
"members": false,
"lowalch": 1,
"limit": 11000,
"value": 4,
"highalch": 2,
"icon": "Earth talisman.png",
"name": "Earth talisman"
},
{
"examine": "A tiara infused with the properties of the earth.",
"id": 5535,
"members": false,
"lowalch": 40,
"limit": 40,
"value": 100,
"highalch": 60,
"icon": "Earth tiara.png",
"name": "Earth tiara"
},
{
"examine": "Happy Easter.",
"id": 1961,
"members": false,
"lowalch": 4,
"limit": 50,
"value": 10,
"highalch": 6,
"icon": "Easter egg.png",
"name": "Easter egg"
},
{
"highalch": 300,
"members": true,
"name": "Echo crystal",
"examine": "A crystal of rebounding potential.",
"id": 28942,
"value": 500,
"icon": "Echo crystal.png",
"lowalch": 200
},
{
"examine": "Eclectic impling in a jar.",
"id": 11248,
"members": true,
"lowalch": 20,
"limit": 18000,
"value": 50,
"highalch": 30,
"icon": "Eclectic impling jar.png",
"name": "Eclectic impling jar"
},
{
"examine": "A powerful thrown weapon forged from an eclipse.",
"id": 29000,
"members": true,
"lowalch": 64000,
"limit": 15,
"value": 160000,
"highalch": 96000,
"icon": "Eclipse atlatl.png",
"name": "Eclipse atlatl"
},
{
"highalch": 174006,
"members": true,
"name": "Eclipse moon chestplate",
"examine": "The chestplate of the Eclipse Moon.",
"id": 29004,
"value": 290010,
"icon": "Eclipse moon chestplate.png",
"lowalch": 116004
},
{
"highalch": 174006,
"members": true,
"name": "Eclipse moon chestplate (broken)",
"examine": "The chestplate of the Eclipse Moon.",
"id": 29049,
"value": 290010,
"icon": "Eclipse moon chestplate (broken).png",
"lowalch": 116004
},
{
"highalch": 61800,
"members": true,
"name": "Eclipse moon helm",
"examine": "The helm of the Eclipse Moon.",
"id": 29010,
"value": 103000,
"icon": "Eclipse moon helm.png",
"lowalch": 41200
},
{
"highalch": 61800,
"members": true,
"name": "Eclipse moon helm (broken)",
"examine": "The helm of the Eclipse Moon.",
"id": 29055,
"value": 103000,
"icon": "Eclipse moon helm (broken).png",
"lowalch": 41200
},
{
"highalch": 173946,
"members": true,
"name": "Eclipse moon tassets",
"examine": "The tassets of the Eclipse Moon.",
"id": 29007,
"value": 289910,
"icon": "Eclipse moon tassets.png",
"lowalch": 115964
},
{
"highalch": 173946,
"members": true,
"name": "Eclipse moon tassets (broken)",
"examine": "The tassets of the Eclipse Moon.",
"id": 29052,
"value": 289910,
"icon": "Eclipse moon tassets (broken).png",
"lowalch": 115964
},
{
"highalch": 600,
"members": true,
"name": "Eclipse wine",
"examine": "The finest Aldarin Red available.",
"id": 29415,
"value": 1000,
"icon": "Eclipse wine.png",
"lowalch": 400
},
{
"examine": "Slightly damp seaweed.",
"id": 403,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Edible seaweed.png",
"name": "Edible seaweed"
},
{
"examine": "Aids the user against vampyres.",
"id": 21140,
"members": true,
"lowalch": 570,
"limit": 10000,
"value": 1425,
"highalch": 855,
"icon": "Efaritay's aid.png",
"name": "Efaritay's aid"
},
{
"examine": "A blue salt, that when burned with other salts provides various benefits.",
"id": 22595,
"members": true,
"lowalch": 4,
"limit": 10000,
"value": 11,
"highalch": 6,
"icon": "Efh salt.png",
"name": "Efh salt"
},
{
"examine": "A nice fresh egg.",
"id": 1944,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 4,
"highalch": 2,
"icon": "Egg.png",
"name": "Egg"
},
{
"examine": "A bowl of scrambled eggs and tomato.",
"id": 7064,
"members": true,
"lowalch": 4,
"limit": 13000,
"value": 12,
"highalch": 7,
"icon": "Egg and tomato.png",
"name": "Egg and tomato"
},
{
"examine": "A baked potato with egg and tomato.",
"id": 7056,
"members": true,
"lowalch": 4,
"limit": 13000,
"value": 12,
"highalch": 7,
"icon": "Egg potato.png",
"name": "Egg potato"
},
{
"examine": "A large whisk of death.",
"id": 7435,
"members": true,
"lowalch": 20,
"limit": 50,
"value": 50,
"highalch": 30,
"icon": "Egg whisk.png",
"name": "Egg whisk"
},
{
"examine": "The hood worn by the dangerous elder druids.",
"id": 20595,
"members": true,
"lowalch": 200,
"limit": 8,
"value": 500,
"highalch": 300,
"icon": "Elder chaos hood.png",
"name": "Elder chaos hood"
},
{
"examine": "The robes worn by the dangerous elder druids.",
"id": 20520,
"members": true,
"lowalch": 280,
"limit": 8,
"value": 700,
"highalch": 420,
"icon": "Elder chaos robe.png",
"name": "Elder chaos robe"
},
{
"examine": "The robes worn by the dangerous elder druids.",
"id": 20517,
"members": true,
"lowalch": 320,
"limit": 8,
"value": 800,
"highalch": 480,
"icon": "Elder chaos top.png",
"name": "Elder chaos top"
},
{
"examine": "A maul crafted from the component parts of Tekton.",
"id": 21003,
"members": true,
"lowalch": 200004,
"limit": 8,
"value": 500010,
"highalch": 300006,
"icon": "Elder maul.png",
"name": "Elder maul"
},
{
"examine": "An ancient magical orb, corrupted by darkness.",
"id": 24517,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 1,
"highalch": 1,
"icon": "Eldritch orb.png",
"name": "Eldritch orb"
},
{
"examine": "A magic helmet.",
"id": 9729,
"members": true,
"lowalch": 8,
"limit": 70,
"value": 20,
"highalch": 12,
"icon": "Elemental helmet.png",
"name": "Elemental helmet"
},
{
"examine": "A magic shield.",
"id": 2890,
"members": true,
"lowalch": 8,
"limit": 70,
"value": 20,
"highalch": 12,
"icon": "Elemental shield.png",
"name": "Elemental shield"
},
{
"examine": "How does it all fit in there?",
"id": 8626,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Elemental sphere (flatpack).png",
"name": "Elemental sphere (flatpack)"
},
{
"examine": "A mysterious power emanates from the talisman...",
"id": 5516,
"members": true,
"lowalch": 200,
"limit": 40,
"value": 500,
"highalch": 300,
"icon": "Elemental talisman.png",
"name": "Elemental talisman"
},
{
"highalch": 1,
"members": true,
"name": "Elemental tiara",
"examine": "A tiara infused with the properties of every elemental rune.",
"id": 26804,
"value": 1,
"icon": "Elemental tiara.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Elidinis' ward",
"examine": "A ward once used by Elidinis herself.",
"id": 25985,
"value": 1,
"icon": "Elidinis' ward.png",
"lowalch": 1
},
{
"examine": "Decorative elven boots.",
"id": 24003,
"members": true,
"lowalch": 4000,
"limit": 125,
"value": 10000,
"highalch": 6000,
"icon": "Elven boots.png",
"name": "Elven boots"
},
{
"highalch": 3,
"members": true,
"name": "Elven dawn",
"examine": "It seems to have a little sparkle to it.",
"id": 23948,
"value": 6,
"icon": "Elven dawn.png",
"lowalch": 2
},
{
"highalch": 6000,
"members": true,
"name": "Elven gloves",
"examine": "Decorative elven gloves.",
"id": 24006,
"value": 10000,
"icon": "Elven gloves.png",
"lowalch": 4000
},
{
"highalch": 3000,
"members": false,
"name": "Elven legwear",
"examine": "Clothing from the elven city of Prifddinas.",
"id": 24024,
"value": 5000,
"icon": "Elven legwear.png",
"lowalch": 2000
},
{
"examine": "A signet ring with some elven markings.",
"id": 23943,
"members": true,
"lowalch": 12000,
"limit": 5,
"value": 30000,
"highalch": 18000,
"icon": "Elven signet.png",
"name": "Elven signet"
},
{
"highalch": 3000,
"members": false,
"name": "Elven skirt (white)",
"examine": "Clothing from the elven city of Prifddinas.",
"id": 24018,
"value": 5000,
"icon": "Elven skirt (white).png",
"lowalch": 2000
},
{
"highalch": 3000,
"members": false,
"name": "Elven skirt (yellow)",
"examine": "Clothing from the elven city of Prifddinas.",
"id": 24012,
"value": 5000,
"icon": "Elven skirt (yellow).png",
"lowalch": 2000
},
{
"highalch": 3000,
"members": false,
"name": "Elven top (white vest)",
"examine": "Clothing from the elven city of Prifddinas.",
"id": 24027,
"value": 5000,
"icon": "Elven top (white vest).png",
"lowalch": 2000
},
{
"highalch": 3000,
"members": false,
"name": "Elven top (white)",
"examine": "Clothing from the elven city of Prifddinas.",
"id": 24015,
"value": 5000,
"icon": "Elven top (white).png",
"lowalch": 2000
},
{
"highalch": 3000,
"members": false,
"name": "Elven top (yellow vest)",
"examine": "Clothing from the elven city of Prifddinas.",
"id": 24021,
"value": 5000,
"icon": "Elven top (yellow vest).png",
"lowalch": 2000
},
{
"highalch": 3000,
"members": false,
"name": "Elven top (yellow)",
"examine": "Clothing from the elven city of Prifddinas.",
"id": 24009,
"value": 5000,
"icon": "Elven top (yellow).png",
"lowalch": 2000
},
{
"examine": "A sigil in the shape of an elysian symbol.",
"id": 12819,
"members": true,
"lowalch": 300000,
"limit": 5,
"value": 750000,
"highalch": 450000,
"icon": "Elysian sigil.png",
"name": "Elysian sigil"
},
{
"examine": "An ethereal shield with an elysian sigil attached to it.",
"id": 12817,
"members": true,
"lowalch": 800000,
"limit": 8,
"value": 2000000,
"highalch": 1200000,
"icon": "Elysian spirit shield.png",
"name": "Elysian spirit shield"
},
{
"examine": "This looks valuable.",
"id": 1605,
"members": false,
"lowalch": 200,
"limit": 13000,
"value": 500,
"highalch": 300,
"icon": "Emerald.png",
"name": "Emerald"
},
{
"examine": "I wonder if I can get this enchanted.",
"id": 1696,
"members": false,
"lowalch": 510,
"limit": 10000,
"value": 1275,
"highalch": 765,
"icon": "Emerald amulet.png",
"name": "Emerald amulet"
},
{
"examine": "It needs a string so I can wear it.",
"id": 1677,
"members": false,
"lowalch": 510,
"limit": 10000,
"value": 1275,
"highalch": 765,
"icon": "Emerald amulet (u).png",
"name": "Emerald amulet (u)"
},
{
"examine": "Emerald bolt tips.",
"id": 9190,
"members": true,
"lowalch": 13,
"limit": 11000,
"value": 33,
"highalch": 19,
"icon": "Emerald bolt tips 5.png",
"name": "Emerald bolt tips"
},
{
"examine": "Emerald tipped Mithril crossbow bolts.",
"id": 9338,
"members": true,
"lowalch": 21,
"limit": 11000,
"value": 53,
"highalch": 31,
"icon": "Emerald bolts 5.png",
"name": "Emerald bolts"
},
{
"examine": "Enchanted Emerald tipped Mithril Crossbow Bolts.",
"id": 9241,
"members": true,
"lowalch": 23,
"limit": 11000,
"value": 59,
"highalch": 35,
"icon": "Emerald bolts (e) 5.png",
"name": "Emerald bolts (e)"
},
{
"examine": "I wonder if this is valuable.",
"id": 11076,
"members": true,
"lowalch": 610,
"limit": 10000,
"value": 1525,
"highalch": 915,
"icon": "Emerald bracelet.png",
"name": "Emerald bracelet"
},
{
"examine": "Dragon crossbow bolts, tipped with emerald.",
"id": 21965,
"members": true,
"lowalch": 320,
"limit": 11000,
"value": 800,
"highalch": 480,
"icon": "Emerald dragon bolts 5.png",
"name": "Emerald dragon bolts"
},
{
"examine": "Enchanted Dragon crossbow bolts, tipped with emerald.",
"id": 21942,
"members": true,
"lowalch": 332,
"limit": 11000,
"value": 830,
"highalch": 498,
"icon": "Emerald dragon bolts (e) 5.png",
"name": "Emerald dragon bolts (e)"
},
{
"examine": "I wonder if this is valuable.",
"id": 1658,
"members": false,
"lowalch": 570,
"limit": 18000,
"value": 1425,
"highalch": 855,
"icon": "Emerald necklace.png",
"name": "Emerald necklace"
},
{
"examine": "A valuable ring.",
"id": 1639,
"members": false,
"lowalch": 510,
"limit": 10000,
"value": 1275,
"highalch": 765,
"icon": "Emerald ring.png",
"name": "Emerald ring"
},
{
"examine": "Put a candle in to complete it.",
"id": 4527,
"members": true,
"lowalch": 4,
"limit": 10000,
"value": 10,
"highalch": 6,
"icon": "Empty candle lantern.png",
"name": "Empty candle lantern"
},
{
"examine": "An empty cup.",
"id": 1980,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Empty cup.png",
"name": "Empty cup"
},
{
"examine": "An empty fishbowl.",
"id": 6667,
"members": true,
"lowalch": 1,
"limit": 100,
"value": 1,
"highalch": 1,
"icon": "Empty fishbowl.png",
"name": "Empty fishbowl"
},
{
"examine": "An oil lamp with no oil in it.",
"id": 4525,
"members": true,
"lowalch": 10,
"limit": 10000,
"value": 25,
"highalch": 15,
"icon": "Empty oil lamp.png",
"name": "Empty oil lamp"
},
{
"examine": "Put oil in to complete it.",
"id": 4535,
"members": true,
"lowalch": 48,
"limit": 10000,
"value": 120,
"highalch": 72,
"icon": "Empty oil lantern.png",
"name": "Empty oil lantern"
},
{
"examine": "An empty plant pot.",
"id": 5350,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Empty plant pot.png",
"name": "Empty plant pot"
},
{
"examine": "An empty vegetable sack.",
"id": 5418,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Empty sack.png",
"name": "Empty sack"
},
{
"examine": "A tablet containing a magic spell.",
"id": 8019,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Enchant diamond.png",
"name": "Enchant diamond"
},
{
"examine": "A tablet containing a magic spell.",
"id": 8020,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Enchant dragonstone.png",
"name": "Enchant dragonstone"
},
{
"examine": "A tablet containing a magic spell.",
"id": 8017,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Enchant emerald or jade.png",
"name": "Enchant emerald or jade"
},
{
"examine": "A tablet containing a magic spell.",
"id": 8021,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Enchant onyx.png",
"name": "Enchant onyx"
},
{
"examine": "A tablet containing a magic spell.",
"id": 8018,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Enchant ruby or topaz.png",
"name": "Enchant ruby or topaz"
},
{
"examine": "A tablet containing a magic spell.",
"id": 8016,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Enchant sapphire or opal.png",
"name": "Enchant sapphire or opal"
},
{
"examine": "A three pointed hat of magic.",
"id": 7400,
"members": true,
"lowalch": 6000,
"limit": 8,
"value": 15000,
"highalch": 9000,
"icon": "Enchanted hat.png",
"name": "Enchanted hat"
},
{
"examine": "Enchanted Wizards robes.",
"id": 7398,
"members": true,
"lowalch": 32000,
"limit": 8,
"value": 80000,
"highalch": 48000,
"icon": "Enchanted robe.png",
"name": "Enchanted robe"
},
{
"examine": "Enchanted Wizards robes.",
"id": 7399,
"members": true,
"lowalch": 48000,
"limit": 8,
"value": 120000,
"highalch": 72000,
"icon": "Enchanted top.png",
"name": "Enchanted top"
},
{
"examine": "One dose of fishy energy potion.",
"id": 11455,
"members": true,
"lowalch": 14,
"limit": 2000,
"value": 36,
"highalch": 21,
"icon": "Energy mix(1).png",
"name": "Energy mix(1)"
},
{
"examine": "Two doses of fishy energy potion.",
"id": 11453,
"members": true,
"lowalch": 28,
"limit": 2000,
"value": 72,
"highalch": 43,
"icon": "Energy mix(2).png",
"name": "Energy mix(2)"
},
{
"examine": "1 dose of energy potion.",
"id": 3014,
"members": false,
"lowalch": 14,
"limit": 2000,
"value": 36,
"highalch": 21,
"icon": "Energy potion(1).png",
"name": "Energy potion(1)"
},
{
"examine": "2 doses of energy potion.",
"id": 3012,
"members": false,
"lowalch": 28,
"limit": 2000,
"value": 72,
"highalch": 43,
"icon": "Energy potion(2).png",
"name": "Energy potion(2)"
},
{
"examine": "3 doses of energy potion.",
"id": 3010,
"members": false,
"lowalch": 44,
"limit": 2000,
"value": 110,
"highalch": 66,
"icon": "Energy potion(3).png",
"name": "Energy potion(3)"
},
{
"examine": "4 doses of energy potion.",
"id": 3008,
"members": false,
"lowalch": 58,
"limit": 2000,
"value": 146,
"highalch": 87,
"icon": "Energy potion(4).png",
"name": "Energy potion(4)"
},
{
"examine": "A seed to be sung into a infinite teleport crystal.",
"id": 23959,
"members": true,
"lowalch": 40000,
"limit": 100,
"value": 100000,
"highalch": 60000,
"icon": "Enhanced crystal teleport seed.png",
"name": "Enhanced crystal teleport seed"
},
{
"examine": "A seed to be sung into the most powerful crystal weaponry.",
"id": 25859,
"members": true,
"lowalch": 200000,
"limit": 70,
"value": 500000,
"highalch": 300000,
"icon": "Enhanced crystal weapon seed.png",
"name": "Enhanced crystal weapon seed"
},
{
"highalch": 3000,
"members": true,
"name": "Enhanced quetzal whistle blueprint",
"examine": "An enhanced blueprint for a quetzal whistle.",
"id": 29253,
"value": 5000,
"icon": "Enhanced quetzal whistle blueprint.png",
"lowalch": 2000
},
{
"examine": "The creature's soul is still in here.",
"id": 13508,
"members": true,
"lowalch": 234,
"limit": 3000,
"value": 585,
"highalch": 351,
"icon": "Ensouled abyssal head.png",
"name": "Ensouled abyssal head"
},
{
"examine": "The creature's soul is still in here.",
"id": 13505,
"members": true,
"lowalch": 221,
"limit": 7500,
"value": 553,
"highalch": 331,
"icon": "Ensouled aviansie head.png",
"name": "Ensouled aviansie head"
},
{
"examine": "The creature's soul is still in here.",
"id": 13463,
"members": true,
"lowalch": 104,
"limit": 3000,
"value": 260,
"highalch": 156,
"icon": "Ensouled bear head.png",
"name": "Ensouled bear head"
},
{
"examine": "The creature's soul is still in here.",
"id": 13496,
"members": true,
"lowalch": 197,
"limit": 3000,
"value": 494,
"highalch": 296,
"icon": "Ensouled bloodveld head.png",
"name": "Ensouled bloodveld head"
},
{
"examine": "The creature's soul is still in here.",
"id": 13472,
"members": true,
"lowalch": 119,
"limit": 3000,
"value": 299,
"highalch": 179,
"icon": "Ensouled chaos druid head.png",
"name": "Ensouled chaos druid head"
},
{
"examine": "The creature's soul is still in here.",
"id": 13493,
"members": true,
"lowalch": 182,
"limit": 7500,
"value": 455,
"highalch": 273,
"icon": "Ensouled dagannoth head.png",
"name": "Ensouled dagannoth head"
},
{
"examine": "The creature's soul is still in here.",
"id": 13502,
"members": true,
"lowalch": 210,
"limit": 7500,
"value": 527,
"highalch": 316,
"icon": "Ensouled demon head.png",
"name": "Ensouled demon head"
},
{
"examine": "The creature's soul is still in here.",
"id": 13469,
"members": true,
"lowalch": 114,
"limit": 3000,
"value": 286,
"highalch": 171,
"icon": "Ensouled dog head.png",
"name": "Ensouled dog head"
},
{
"examine": "The creature's soul is still in here.",
"id": 13511,
"members": true,
"lowalch": 260,
"limit": 7500,
"value": 650,
"highalch": 390,
"icon": "Ensouled dragon head.png",
"name": "Ensouled dragon head"
},
{
"examine": "The creature's soul is still in here.",
"id": 13481,
"members": true,
"lowalch": 143,
"limit": 7500,
"value": 358,
"highalch": 214,
"icon": "Ensouled elf head.png",
"name": "Ensouled elf head"
},
{
"examine": "The creature's soul is still in here.",
"id": 13475,
"members": true,
"lowalch": 127,
"limit": 3000,
"value": 319,
"highalch": 191,
"icon": "Ensouled giant head.png",
"name": "Ensouled giant head"
},
{
"examine": "The creature's soul is still in here.",
"id": 13448,
"members": true,
"lowalch": 20,
"limit": 3000,
"value": 52,
"highalch": 31,
"icon": "Ensouled goblin head.png",
"name": "Ensouled goblin head"
},
{
"highalch": 1,
"members": true,
"name": "Ensouled hellhound head",
"examine": "The creature's soul is still in here. I could reanimate it with Expert Reanimation.",
"id": 26997,
"value": 1,
"icon": "Ensouled hellhound head.png",
"lowalch": 1
},
{
"examine": "The creature's soul is still in here.",
"id": 13487,
"members": true,
"lowalch": 161,
"limit": 7500,
"value": 403,
"highalch": 241,
"icon": "Ensouled horror head.png",
"name": "Ensouled horror head"
},
{
"examine": "The creature's soul is still in here.",
"id": 13454,
"members": true,
"lowalch": 62,
"limit": 3000,
"value": 156,
"highalch": 93,
"icon": "Ensouled imp head.png",
"name": "Ensouled imp head"
},
{
"examine": "The creature's soul is still in here.",
"id": 13490,
"members": true,
"lowalch": 173,
"limit": 7500,
"value": 433,
"highalch": 259,
"icon": "Ensouled kalphite head.png",
"name": "Ensouled kalphite head"
},
{
"examine": "The creature's soul is still in here.",
"id": 13457,
"members": true,
"lowalch": 91,
"limit": 3000,
"value": 228,
"highalch": 136,
"icon": "Ensouled minotaur head.png",
"name": "Ensouled minotaur head"
},
{
"examine": "The creature's soul is still in here.",
"id": 13451,
"members": true,
"lowalch": 41,
"limit": 3000,
"value": 104,
"highalch": 62,
"icon": "Ensouled monkey head.png",
"name": "Ensouled monkey head"
},
{
"examine": "The creature's soul is still in here.",
"id": 13478,
"members": true,
"lowalch": 135,
"limit": 3000,
"value": 338,
"highalch": 202,
"icon": "Ensouled ogre head.png",
"name": "Ensouled ogre head"
},
{
"examine": "The creature's soul is still in here.",
"id": 13460,
"members": true,
"lowalch": 98,
"limit": 3000,
"value": 247,
"highalch": 148,
"icon": "Ensouled scorpion head.png",
"name": "Ensouled scorpion head"
},
{
"examine": "The creature's soul is still in here.",
"id": 13484,
"members": true,
"lowalch": 153,
"limit": 7500,
"value": 384,
"highalch": 230,
"icon": "Ensouled troll head.png",
"name": "Ensouled troll head"
},
{
"examine": "The creature's soul is still in here.",
"id": 13499,
"members": true,
"lowalch": 202,
"limit": 3000,
"value": 507,
"highalch": 304,
"icon": "Ensouled tzhaar head.png",
"name": "Ensouled tzhaar head"
},
{
"examine": "The creature's soul is still in here.",
"id": 13466,
"members": true,
"lowalch": 106,
"limit": 3000,
"value": 267,
"highalch": 160,
"icon": "Ensouled unicorn head.png",
"name": "Ensouled unicorn head"
},
{
"examine": "Small sweet smelling leaves.",
"id": 2128,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Equa leaves.png",
"name": "Equa leaves"
},
{
"examine": "Essence impling in a jar.",
"id": 11246,
"members": true,
"lowalch": 20,
"limit": 18000,
"value": 50,
"highalch": 30,
"icon": "Essence impling jar.png",
"name": "Essence impling jar"
},
{
"examine": "A pair of upgraded infinity boots.",
"id": 13235,
"members": true,
"lowalch": 30000,
"limit": 15,
"value": 75000,
"highalch": 45000,
"icon": "Eternal boots.png",
"name": "Eternal boots"
},
{
"examine": "A powerful crystal of magic.",
"id": 13227,
"members": true,
"lowalch": 18000,
"limit": 15,
"value": 45000,
"highalch": 27000,
"icon": "Eternal crystal.png",
"name": "Eternal crystal"
},
{
"examine": "Like the enchanted gem sold by Slayer Masters, only with an eternal charge.",
"id": 21270,
"members": true,
"lowalch": 3800,
"limit": 8,
"value": 9500,
"highalch": 5700,
"icon": "Eternal gem.png",
"name": "Eternal gem"
},
{
"examine": "Perfect for crossing the road in an evil manner.",
"id": 20433,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Evil chicken feet.png",
"name": "Evil chicken feet"
},
{
"examine": "Cock-a-doodle-do!",
"id": 20439,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Evil chicken head.png",
"name": "Evil chicken head"
},
{
"examine": "Evil legs for an evil human wanting to dress like an evil chicken.",
"id": 20442,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Evil chicken legs.png",
"name": "Evil chicken legs"
},
{
"examine": "I look evil and fowl in this.",
"id": 20436,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Evil chicken wings.png",
"name": "Evil chicken wings"
},
{
"examine": "Allows one slayer kill to count as two.",
"id": 21177,
"members": true,
"lowalch": 460,
"limit": 10000,
"value": 1150,
"highalch": 690,
"icon": "Expeditious bracelet.png",
"name": "Expeditious bracelet"
},
{
"examine": "I'm the Backpack loaded up with things and knick knacks too.",
"id": 12514,
"members": true,
"lowalch": 620,
"limit": 4,
"value": 1550,
"highalch": 930,
"icon": "Explorer backpack.png",
"name": "Explorer backpack"
},
{
"examine": "One dose of fishy extended anti-firebreath potion.",
"id": 11962,
"members": true,
"lowalch": 64,
"limit": 2000,
"value": 160,
"highalch": 96,
"icon": "Extended antifire mix(1).png",
"name": "Extended antifire mix(1)"
},
{
"examine": "Two doses of fishy extended anti-firebreath potion.",
"id": 11960,
"members": true,
"lowalch": 96,
"limit": 2000,
"value": 240,
"highalch": 144,
"icon": "Extended antifire mix(2).png",
"name": "Extended antifire mix(2)"
},
{
"examine": "1 dose of extended anti-firebreath potion.",
"id": 11957,
"members": true,
"lowalch": 44,
"limit": 2000,
"value": 110,
"highalch": 66,
"icon": "Extended antifire(1).png",
"name": "Extended antifire(1)"
},
{
"examine": "2 doses of extended anti-firebreath potion.",
"id": 11955,
"members": true,
"lowalch": 88,
"limit": 2000,
"value": 220,
"highalch": 132,
"icon": "Extended antifire(2).png",
"name": "Extended antifire(2)"
},
{
"examine": "3 doses of extended anti-firebreath potion.",
"id": 11953,
"members": true,
"lowalch": 132,
"limit": 2000,
"value": 330,
"highalch": 198,
"icon": "Extended antifire(3).png",
"name": "Extended antifire(3)"
},
{
"examine": "4 doses of extended anti-firebreath potion.",
"id": 11951,
"members": true,
"lowalch": 176,
"limit": 2000,
"value": 440,
"highalch": 264,
"icon": "Extended antifire(4).png",
"name": "Extended antifire(4)"
},
{
"examine": "One dose of fishy extended super anti-firebreath potion.",
"id": 22224,
"members": true,
"lowalch": 64,
"limit": 2000,
"value": 160,
"highalch": 96,
"icon": "Extended super antifire mix(1).png",
"name": "Extended super antifire mix(1)"
},
{
"examine": "Two doses of fishy extended super anti-firebreath potion.",
"id": 22221,
"members": true,
"lowalch": 96,
"limit": 2000,
"value": 240,
"highalch": 144,
"icon": "Extended super antifire mix(2).png",
"name": "Extended super antifire mix(2)"
},
{
"examine": "1 dose of extended super anti-firebreath potion.",
"id": 22218,
"members": true,
"lowalch": 272,
"limit": 2000,
"value": 680,
"highalch": 408,
"icon": "Extended super antifire(1).png",
"name": "Extended super antifire(1)"
},
{
"examine": "2 doses of extended super anti-firebreath potion.",
"id": 22215,
"members": true,
"lowalch": 288,
"limit": 2000,
"value": 720,
"highalch": 432,
"icon": "Extended super antifire(2).png",
"name": "Extended super antifire(2)"
},
{
"examine": "3 doses of extended super anti-firebreath potion.",
"id": 22212,
"members": true,
"lowalch": 304,
"limit": 2000,
"value": 760,
"highalch": 456,
"icon": "Extended super antifire(3).png",
"name": "Extended super antifire(3)"
},
{
"examine": "4 doses of extended super anti-firebreath potion.",
"id": 22209,
"members": true,
"lowalch": 320,
"limit": 2000,
"value": 800,
"highalch": 480,
"icon": "Extended super antifire(4).png",
"name": "Extended super antifire(4)"
},
{
"examine": "It seems to be looking at me.",
"id": 221,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 3,
"highalch": 1,
"icon": "Eye of newt.png",
"name": "Eye of newt"
},
{
"examine": "Stops me breathing in too much dust.",
"id": 4164,
"members": true,
"lowalch": 80,
"limit": 70,
"value": 200,
"highalch": 120,
"icon": "Facemask.png",
"name": "Facemask"
},
{
"examine": "Makes me itch.",
"id": 4593,
"members": true,
"lowalch": 1,
"limit": 15,
"value": 1,
"highalch": 1,
"icon": "Fake beard.png",
"name": "Fake beard"
},
{
"examine": "A teleport to Falador.",
"id": 8009,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Falador teleport (tablet).png",
"name": "Falador teleport (tablet)"
},
{
"examine": "You can plant this in your garden.",
"id": 8445,
"members": true,
"lowalch": 10000,
"limit": 10000,
"value": 25000,
"highalch": 15000,
"icon": "Fancy hedge (bagged).png",
"name": "Fancy hedge (bagged)"
},
{
"examine": "How does it all fit in there?",
"id": 8604,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Fancy teak dresser (flatpack).png",
"name": "Fancy teak dresser (flatpack)"
},
{
"examine": "Call me princess.",
"id": 20008,
"members": true,
"lowalch": 3200,
"limit": 4,
"value": 8000,
"highalch": 4800,
"icon": "Fancy tiara.png",
"name": "Fancy tiara"
},
{
"examine": "The fangs of a matriarch spider. They seem to maintain a sense of her power.",
"id": 27670,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 1,
"highalch": 1,
"icon": "Fangs of venenatis.png",
"name": "Fangs of venenatis"
},
{
"examine": "This helmet is worn by farseers.",
"id": 3755,
"members": true,
"lowalch": 24000,
"limit": 70,
"value": 60000,
"highalch": 36000,
"icon": "Farseer helm.png",
"name": "Farseer helm"
},
{
"examine": "The fat, slimy corpse of a deceased giant snail.",
"id": 3367,
"members": true,
"lowalch": 6,
"limit": 13000,
"value": 15,
"highalch": 9,
"icon": "Fat snail.png",
"name": "Fat snail"
},
{
"examine": "A succulently slimy slice of sumptuous snail.",
"id": 3373,
"members": true,
"lowalch": 12,
"limit": 6000,
"value": 30,
"highalch": 18,
"icon": "Fat snail meat.png",
"name": "Fat snail meat"
},
{
"examine": "Ancient ogre bones from the ogre burial tomb.",
"id": 4830,
"members": true,
"lowalch": 1,
"limit": 7500,
"value": 1,
"highalch": 1,
"icon": "Fayrg bones.png",
"name": "Fayrg bones"
},
{
"examine": "Used for fly fishing.",
"id": 314,
"members": false,
"lowalch": 1,
"limit": 30000,
"value": 2,
"highalch": 1,
"icon": "Feather.png",
"name": "Feather"
},
{
"examine": "Makes you look fedorable.",
"id": 11990,
"members": true,
"lowalch": 120,
"limit": 4,
"value": 300,
"highalch": 180,
"icon": "Fedora.png",
"name": "Fedora"
},
{
"examine": "Teleports you to Feldip hills.",
"id": 12404,
"members": true,
"lowalch": 4,
"limit": 10000,
"value": 10,
"highalch": 6,
"icon": "Feldip hills teleport.png",
"name": "Feldip hills teleport"
},
{
"examine": "Not actually from a weasel, but it is, at least, furry.",
"id": 10119,
"members": true,
"lowalch": 5,
"limit": 10000,
"value": 14,
"highalch": 8,
"icon": "Feldip weasel fur.png",
"name": "Feldip weasel fur"
},
{
"highalch": 1,
"members": true,
"name": "Felling axe handle",
"examine": "A large handle for turning an axe into a felling axe.",
"id": 28177,
"value": 1,
"icon": "Felling axe handle.png",
"lowalch": 1
},
{
"examine": "A teleport to Fenkenstrain's Castle.",
"id": 19621,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Fenkenstrain's castle teleport (tablet).png",
"name": "Fenkenstrain's castle teleport (tablet)"
},
{
"examine": "A Fez hat. Juss like that.",
"id": 6382,
"members": true,
"lowalch": 8,
"limit": 150,
"value": 20,
"highalch": 12,
"icon": "Fez.png",
"name": "Fez"
},
{
"examine": "An interesting looking bone shard.",
"id": 6167,
"members": true,
"lowalch": 24,
"limit": 11000,
"value": 60,
"highalch": 36,
"icon": "Fibula piece.png",
"name": "Fibula piece"
},
{
"highalch": 1,
"members": false,
"name": "Fiendish ashes",
"examine": "A heap of ashes.",
"id": 25766,
"value": 1,
"icon": "Fiendish ashes.png",
"lowalch": 1
},
{
"examine": "A plant pot filled with soil.",
"id": 5354,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Filled plant pot.png",
"name": "Filled plant pot"
},
{
"examine": "Amazingly untouched by time.",
"id": 3470,
"members": true,
"lowalch": 200,
"limit": 11000,
"value": 500,
"highalch": 300,
"icon": "Fine cloth.png",
"name": "Fine cloth"
},
{
"examine": "It's a slightly magical stick.",
"id": 1393,
"members": true,
"lowalch": 6200,
"limit": 18000,
"value": 15500,
"highalch": 9300,
"icon": "Fire battlestaff.png",
"name": "Fire battlestaff"
},
{
"examine": "A magic glowing orb.",
"id": 569,
"members": true,
"lowalch": 120,
"limit": 11000,
"value": 300,
"highalch": 180,
"icon": "Fire orb.png",
"name": "Fire orb"
},
{
"examine": "One of the 4 basic elemental Runes.",
"id": 554,
"members": false,
"lowalch": 1,
"limit": 50000,
"value": 4,
"highalch": 2,
"icon": "Fire rune.png",
"name": "Fire rune"
},
{
"examine": "A mysterious power emanates from the talisman...",
"id": 1442,
"members": false,
"lowalch": 1,
"limit": 11000,
"value": 4,
"highalch": 2,
"icon": "Fire talisman.png",
"name": "Fire talisman"
},
{
"examine": "A tiara infused with the properties of fire.",
"id": 5537,
"members": false,
"lowalch": 40,
"limit": 40,
"value": 100,
"highalch": 60,
"icon": "Fire tiara.png",
"name": "Fire tiara"
},
{
"examine": "Apparently Cormorants like to eat this stuff...",
"id": 22818,
"members": true,
"lowalch": 8,
"limit": 13000,
"value": 20,
"highalch": 12,
"icon": "Fish chunks.png",
"name": "Fish chunks"
},
{
"examine": "Keeps your pet fish strong and healthy.",
"id": 272,
"members": false,
"lowalch": 1,
"limit": 15,
"value": 1,
"highalch": 1,
"icon": "Fish food.png",
"name": "Fish food"
},
{
"examine": "Slices of inedible fish.",
"id": 11334,
"members": true,
"lowalch": 1,
"limit": 8000,
"value": 1,
"highalch": 1,
"icon": "Fish offcuts.png",
"name": "Fish offcuts"
},
{
"examine": "Bounty of the sea.",
"id": 7188,
"members": true,
"lowalch": 40,
"limit": 10000,
"value": 100,
"highalch": 60,
"icon": "Fish pie.png",
"name": "Fish pie"
},
{
"examine": "For use with a fishing rod.",
"id": 313,
"members": false,
"lowalch": 1,
"limit": 8000,
"value": 3,
"highalch": 1,
"icon": "Fishing bait.png",
"name": "Fishing bait"
},
{
"examine": "A teleport to the Fishing Guild.",
"id": 24959,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Fishing guild teleport (tablet).png",
"name": "Fishing guild teleport (tablet)"
},
{
"examine": "One dose of fishy Fishing potion.",
"id": 11479,
"members": true,
"lowalch": 40,
"limit": 2000,
"value": 100,
"highalch": 60,
"icon": "Fishing mix(1).png",
"name": "Fishing mix(1)"
},
{
"examine": "Two doses of fishy Fishing potion.",
"id": 11477,
"members": true,
"lowalch": 60,
"limit": 2000,
"value": 150,
"highalch": 90,
"icon": "Fishing mix(2).png",
"name": "Fishing mix(2)"
},
{
"examine": "1 dose of Fishing potion.",
"id": 155,
"members": true,
"lowalch": 40,
"limit": 2000,
"value": 100,
"highalch": 60,
"icon": "Fishing potion(1).png",
"name": "Fishing potion(1)"
},
{
"examine": "2 doses of Fishing potion.",
"id": 153,
"members": true,
"lowalch": 60,
"limit": 2000,
"value": 150,
"highalch": 90,
"icon": "Fishing potion(2).png",
"name": "Fishing potion(2)"
},
{
"examine": "3 doses of Fishing potion.",
"id": 151,
"members": true,
"lowalch": 80,
"limit": 2000,
"value": 200,
"highalch": 120,
"icon": "Fishing potion(3).png",
"name": "Fishing potion(3)"
},
{
"examine": "4 doses of Fishing potion.",
"id": 2438,
"members": true,
"lowalch": 100,
"limit": 2000,
"value": 250,
"highalch": 150,
"icon": "Fishing potion(4).png",
"name": "Fishing potion(4)"
},
{
"examine": "Useful for catching sardine or herring.",
"id": 307,
"members": false,
"lowalch": 2,
"limit": 40,
"value": 5,
"highalch": 3,
"icon": "Fishing rod.png",
"name": "Fishing rod"
},
{
"examine": "The remains of a deadly shade.",
"id": 3404,
"members": true,
"lowalch": 1,
"limit": 7500,
"value": 1,
"highalch": 1,
"icon": "Fiyr remains.png",
"name": "Fiyr remains"
},
{
"examine": "Helps when building the Shades of Mort'ton temple.",
"id": 21180,
"members": true,
"lowalch": 520,
"limit": 10000,
"value": 1300,
"highalch": 780,
"icon": "Flamtaer bracelet.png",
"name": "Flamtaer bracelet"
},
{
"examine": "An exquisitely shaped tool specially designed for fixing temples.",
"id": 3678,
"members": true,
"lowalch": 4000,
"limit": 40,
"value": 10000,
"highalch": 6000,
"icon": "Flamtaer hammer.png",
"name": "Flamtaer hammer"
},
{
"examine": "These'll help me stay alive.",
"id": 10394,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Flared trousers.png",
"name": "Flared trousers"
},
{
"examine": "A tattered chunk of dagannoth hide.",
"id": 6171,
"members": true,
"lowalch": 24,
"limit": 11000,
"value": 60,
"highalch": 36,
"icon": "Flattened hide.png",
"name": "Flattened hide"
},
{
"examine": "I should use this with a spinning wheel.",
"id": 1779,
"members": true,
"lowalch": 2,
"limit": 13000,
"value": 5,
"highalch": 3,
"icon": "Flax.png",
"name": "Flax"
},
{
"examine": "A wooden arrow shaft with four flights attached.",
"id": 2865,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 1,
"highalch": 1,
"icon": "Flighted ogre arrow 5.png",
"name": "Flighted ogre arrow"
},
{
"examine": "Useful for catching salmon or trout.",
"id": 309,
"members": false,
"lowalch": 2,
"limit": 40,
"value": 5,
"highalch": 3,
"icon": "Fly fishing rod.png",
"name": "Fly fishing rod"
},
{
"examine": "Mmm this looks tasty.",
"id": 28157,
"members": true,
"lowalch": 1,
"limit": 6000,
"value": 1,
"highalch": 1,
"icon": "Forester's ration.png",
"name": "Forester's ration"
},
{
"highalch": 1,
"members": true,
"name": "Forgotten brew(1)",
"examine": "1 dose of forgotten brew.",
"id": 27638,
"value": 1,
"icon": "Forgotten brew(1).png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Forgotten brew(2)",
"examine": "2 doses of forgotten brew.",
"id": 27635,
"value": 1,
"icon": "Forgotten brew(2).png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Forgotten brew(3)",
"examine": "3 doses of forgotten brew.",
"id": 27632,
"value": 1,
"icon": "Forgotten brew(3).png",
"lowalch": 1
},
{
"examine": "4 doses of forgotten brew.",
"id": 27629,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Forgotten brew(4).png",
"name": "Forgotten brew(4)"
},
{
"examine": "How does it all fit in there?",
"id": 8586,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Four-poster bed (flatpack).png",
"name": "Four-poster bed (flatpack)"
},
{
"highalch": 6,
"members": true,
"name": "Fox fur",
"examine": "This would make warm clothing.",
"id": 29163,
"value": 10,
"icon": "Fox fur.png",
"lowalch": 4
},
{
"examine": "The latest in Fremennik fashion.",
"id": 3771,
"members": true,
"lowalch": 100,
"limit": 150,
"value": 250,
"highalch": 150,
"icon": "Fremennik beige shirt.png",
"name": "Fremennik beige shirt"
},
{
"examine": "The latest fashion in Rellekka.",
"id": 3789,
"members": true,
"lowalch": 100,
"limit": 150,
"value": 250,
"highalch": 150,
"icon": "Fremennik black cloak.png",
"name": "Fremennik black cloak"
},
{
"examine": "The latest fashion in Rellekka.",
"id": 3763,
"members": true,
"lowalch": 100,
"limit": 150,
"value": 250,
"highalch": 150,
"icon": "Fremennik blue cloak.png",
"name": "Fremennik blue cloak"
},
{
"examine": "The latest in Fremennik fashion.",
"id": 3775,
"members": true,
"lowalch": 100,
"limit": 150,
"value": 250,
"highalch": 150,
"icon": "Fremennik blue shirt.png",
"name": "Fremennik blue shirt"
},
{
"examine": "Very stylish!",
"id": 3791,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Fremennik boots.png",
"name": "Fremennik boots"
},
{
"examine": "The latest fashion in Rellekka.",
"id": 3761,
"members": true,
"lowalch": 100,
"limit": 150,
"value": 250,
"highalch": 150,
"icon": "Fremennik brown cloak.png",
"name": "Fremennik brown cloak"
},
{
"examine": "The latest in Fremennik fashion.",
"id": 3767,
"members": true,
"lowalch": 100,
"limit": 150,
"value": 250,
"highalch": 150,
"icon": "Fremennik brown shirt.png",
"name": "Fremennik brown shirt"
},
{
"examine": "The latest fashion in Rellekka.",
"id": 3759,
"members": true,
"lowalch": 100,
"limit": 150,
"value": 250,
"highalch": 150,
"icon": "Fremennik cyan cloak.png",
"name": "Fremennik cyan cloak"
},
{
"examine": "These will keep my hands warm!",
"id": 3799,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Fremennik gloves.png",
"name": "Fremennik gloves"
},
{
"examine": "The latest fashion in Rellekka.",
"id": 3765,
"members": true,
"lowalch": 100,
"limit": 150,
"value": 250,
"highalch": 150,
"icon": "Fremennik green cloak.png",
"name": "Fremennik green cloak"
},
{
"examine": "The latest fashion in Rellekka.",
"id": 3779,
"members": true,
"lowalch": 100,
"limit": 150,
"value": 250,
"highalch": 150,
"icon": "Fremennik grey cloak.png",
"name": "Fremennik grey cloak"
},
{
"examine": "The latest in Fremennik fashion.",
"id": 3769,
"members": true,
"lowalch": 100,
"limit": 150,
"value": 250,
"highalch": 150,
"icon": "Fremennik grey shirt.png",
"name": "Fremennik grey shirt"
},
{
"examine": "A silly pointed hat.",
"id": 3797,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Fremennik hat.png",
"name": "Fremennik hat"
},
{
"examine": "A kilt worn by the mightiest of Fremennik warriors.",
"id": 23246,
"members": true,
"lowalch": 24000,
"limit": 8,
"value": 60000,
"highalch": 36000,
"icon": "Fremennik kilt.png",
"name": "Fremennik kilt"
},
{
"examine": "The latest fashion in Rellekka.",
"id": 3787,
"members": true,
"lowalch": 100,
"limit": 150,
"value": 250,
"highalch": 150,
"icon": "Fremennik pink cloak.png",
"name": "Fremennik pink cloak"
},
{
"examine": "The latest fashion in Rellekka.",
"id": 3785,
"members": true,
"lowalch": 100,
"limit": 150,
"value": 250,
"highalch": 150,
"icon": "Fremennik purple cloak.png",
"name": "Fremennik purple cloak"
},
{
"examine": "The latest fashion in Rellekka.",
"id": 3777,
"members": true,
"lowalch": 100,
"limit": 150,
"value": 250,
"highalch": 150,
"icon": "Fremennik red cloak.png",
"name": "Fremennik red cloak"
},
{
"examine": "The latest in Fremennik fashion.",
"id": 3773,
"members": true,
"lowalch": 100,
"limit": 150,
"value": 250,
"highalch": 150,
"icon": "Fremennik red shirt.png",
"name": "Fremennik red shirt"
},
{
"examine": "The latest fashion in Rellekka.",
"id": 3793,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Fremennik robe.png",
"name": "Fremennik robe"
},
{
"examine": "The latest fashion in Rellekka.",
"id": 3795,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Fremennik skirt.png",
"name": "Fremennik skirt"
},
{
"examine": "The latest fashion in Rellekka.",
"id": 3783,
"members": true,
"lowalch": 100,
"limit": 150,
"value": 250,
"highalch": 150,
"icon": "Fremennik teal cloak.png",
"name": "Fremennik teal cloak"
},
{
"examine": "The latest fashion in Rellekka.",
"id": 3781,
"members": true,
"lowalch": 100,
"limit": 150,
"value": 250,
"highalch": 150,
"icon": "Fremennik yellow cloak.png",
"name": "Fremennik yellow cloak"
},
{
"examine": "A bowl of fried Bittercap mushrooms.",
"id": 7082,
"members": true,
"lowalch": 16,
"limit": 13000,
"value": 42,
"highalch": 25,
"icon": "Fried mushrooms.png",
"name": "Fried mushrooms"
},
{
"examine": "A bowl of sliced, fried onions.",
"id": 7084,
"members": true,
"lowalch": 2,
"limit": 13000,
"value": 7,
"highalch": 4,
"icon": "Fried onions.png",
"name": "Fried onions"
},
{
"examine": "Cute frog slippers.",
"id": 23288,
"members": false,
"lowalch": 400,
"limit": 4,
"value": 1000,
"highalch": 600,
"icon": "Frog slippers.png",
"name": "Frog slippers"
},
{
"examine": "Armour made out of frog hide.",
"id": 10954,
"members": true,
"lowalch": 400,
"limit": 70,
"value": 1000,
"highalch": 600,
"icon": "Frog-leather body.png",
"name": "Frog-leather body"
},
{
"examine": "Boots made out of frog hide.",
"id": 10958,
"members": true,
"lowalch": 80,
"limit": 70,
"value": 200,
"highalch": 120,
"icon": "Frog-leather boots.png",
"name": "Frog-leather boots"
},
{
"examine": "Chaps made out of frog hide.",
"id": 10956,
"members": true,
"lowalch": 360,
"limit": 70,
"value": 900,
"highalch": 540,
"icon": "Frog-leather chaps.png",
"name": "Frog-leather chaps"
},
{
"examine": "Cold to the touch.",
"id": 12769,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Frozen whip mix.png",
"name": "Frozen whip mix"
},
{
"examine": "It actually smells quite good.",
"id": 2277,
"members": true,
"lowalch": 1,
"limit": 6000,
"value": 2,
"highalch": 1,
"icon": "Fruit batta.png",
"name": "Fruit batta"
},
{
"examine": "A cool refreshing fruit mix.",
"id": 2084,
"members": true,
"lowalch": 12,
"limit": 2000,
"value": 30,
"highalch": 18,
"icon": "Fruit blast.png",
"name": "Fruit blast"
},
{
"examine": "Looks like it's non-stick too!",
"id": 7441,
"members": true,
"lowalch": 664,
"limit": 50,
"value": 1660,
"highalch": 996,
"icon": "Frying pan.png",
"name": "Frying pan"
},
{
"examine": "This would make warm clothing.",
"id": 6814,
"members": false,
"lowalch": 4,
"limit": 18000,
"value": 10,
"highalch": 6,
"icon": "Fur.png",
"name": "Fur"
},
{
"examine": "Use on an amulet of fury to make it look fancier!",
"id": 12526,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Fury ornament kit.png",
"name": "Fury ornament kit"
},
{
"examine": "A specially crafted hammer with strange markings on it.",
"id": 7668,
"members": true,
"lowalch": 1200,
"limit": 8,
"value": 3000,
"highalch": 1800,
"icon": "Gadderhammer.png",
"name": "Gadderhammer"
},
{
"examine": "An enchanted necklace.",
"id": 3853,
"members": true,
"lowalch": 420,
"limit": 10000,
"value": 1050,
"highalch": 630,
"icon": "Games necklace(8).png",
"name": "Games necklace(8)"
},
{
"examine": "What I wouldn't give for a good steak about now...",
"id": 7178,
"members": true,
"lowalch": 9,
"limit": 10000,
"value": 24,
"highalch": 14,
"icon": "Garden pie.png",
"name": "Garden pie"
},
{
"examine": "A pair of gardening boots.",
"id": 5345,
"members": true,
"lowalch": 10,
"limit": 150,
"value": 25,
"highalch": 15,
"icon": "Gardening boots.png",
"name": "Gardening boots"
},
{
"examine": "Not suitable for archaeological digs.",
"id": 5325,
"members": true,
"lowalch": 4,
"limit": 40,
"value": 12,
"highalch": 7,
"icon": "Gardening trowel.png",
"name": "Gardening trowel"
},
{
"examine": "A clove of garlic.",
"id": 1550,
"members": false,
"lowalch": 1,
"limit": 11000,
"value": 3,
"highalch": 1,
"icon": "Garlic.png",
"name": "Garlic"
},
{
"examine": "Finely ground garlic powder.",
"id": 4668,
"members": true,
"lowalch": 4,
"limit": 15,
"value": 10,
"highalch": 6,
"icon": "Garlic powder.png",
"name": "Garlic powder"
},
{
"examine": "A teleport to Ghorrock, in level 45 Wilderness.",
"id": 12778,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Ghorrock teleport (tablet).png",
"name": "Ghorrock teleport (tablet)"
},
{
"examine": "A razor sharp rapier, smeared with vampyric blood.",
"id": 22324,
"members": true,
"lowalch": 2000000,
"limit": 8,
"value": 5000000,
"highalch": 3000000,
"icon": "Ghrazi rapier.png",
"name": "Ghrazi rapier"
},
{
"examine": "It's made from a secret recipe.",
"id": 2171,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Gianne dough.png",
"name": "Gianne dough"
},
{
"examine": "Aluft Gianne's favorite dishes.",
"id": 2167,
"members": true,
"lowalch": 1,
"limit": 15,
"value": 2,
"highalch": 1,
"icon": "Gianne's cook book.png",
"name": "Gianne's cook book"
},
{
"examine": "Worn on head. Warning: Smelly.",
"id": 23252,
"members": true,
"lowalch": 5600,
"limit": 4,
"value": 14000,
"highalch": 8400,
"icon": "Giant boot.png",
"name": "Giant boot"
},
{
"examine": "A giant sac of red spider eggs, hopefully none of them hatch.",
"id": 23517,
"members": true,
"lowalch": 4,
"limit": 100,
"value": 10,
"highalch": 6,
"icon": "Giant egg sac(full).png",
"name": "Giant egg sac(full)"
},
{
"examine": "This could feed a family of gnomes for a week!",
"id": 4517,
"members": true,
"lowalch": 40,
"limit": 6000,
"value": 100,
"highalch": 60,
"icon": "Giant frog legs.png",
"name": "Giant frog legs"
},
{
"examine": "Seaweed of large size.",
"id": 21504,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 2,
"highalch": 1,
"icon": "Giant seaweed.png",
"name": "Giant seaweed"
},
{
"examine": "A two handed sword with gold plate.",
"id": 20155,
"members": false,
"lowalch": 25600,
"limit": 70,
"value": 64000,
"highalch": 38400,
"icon": "Gilded 2h sword.png",
"name": "Gilded 2h sword"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 13036,
"members": false,
"lowalch": 32000,
"limit": 8,
"value": 80000,
"highalch": 48000,
"icon": "Gilded armour set (lg).png",
"name": "Gilded armour set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 13038,
"members": false,
"lowalch": 32000,
"limit": 8,
"value": 80000,
"highalch": 48000,
"icon": "Gilded armour set (sk).png",
"name": "Gilded armour set (sk)"
},
{
"examine": "A powerful axe.",
"id": 23279,
"members": false,
"lowalch": 14000,
"limit": 4,
"value": 35000,
"highalch": 21000,
"icon": "Gilded axe.png",
"name": "Gilded axe"
},
{
"examine": "How does it all fit in there?",
"id": 8574,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Gilded bench (flatpack).png",
"name": "Gilded bench (flatpack)"
},
{
"examine": "Rune boots with gold plate.",
"id": 12391,
"members": true,
"lowalch": 5000,
"limit": 8,
"value": 12500,
"highalch": 7500,
"icon": "Gilded boots.png",
"name": "Gilded boots"
},
{
"examine": "How does it all fit in there?",
"id": 9846,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Gilded cape rack (flatpack).png",
"name": "Gilded cape rack (flatpack)"
},
{
"examine": "A series of connected metal rings plated in gold.",
"id": 20149,
"members": false,
"lowalch": 20000,
"limit": 70,
"value": 50000,
"highalch": 30000,
"icon": "Gilded chainbody.png",
"name": "Gilded chainbody"
},
{
"examine": "How does it all fit in there?",
"id": 8594,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Gilded clock (flatpack).png",
"name": "Gilded clock (flatpack)"
},
{
"examine": "Made with 100% golden dragonhide.",
"id": 23258,
"members": true,
"lowalch": 800,
"limit": 8,
"value": 2000,
"highalch": 1200,
"icon": "Gilded coif.png",
"name": "Gilded coif"
},
{
"examine": "Made with 100% golden dragonhide.",
"id": 23264,
"members": false,
"lowalch": 20000,
"limit": 8,
"value": 50000,
"highalch": 30000,
"icon": "Gilded d'hide body.png",
"name": "Gilded d'hide body"
},
{
"examine": "Made with 100% golden dragonhide.",
"id": 23267,
"members": false,
"lowalch": 16000,
"limit": 8,
"value": 40000,
"highalch": 24000,
"icon": "Gilded d'hide chaps.png",
"name": "Gilded d'hide chaps"
},
{
"examine": "Made with 100% golden dragonhide.",
"id": 23261,
"members": false,
"lowalch": 16000,
"limit": 8,
"value": 40000,
"highalch": 24000,
"icon": "Gilded d'hide vambraces.png",
"name": "Gilded d'hide vambraces"
},
{
"examine": "A set containing a gilded dragonhide body, chaps and vambraces.",
"id": 23124,
"members": false,
"lowalch": 5000,
"limit": 8,
"value": 12500,
"highalch": 7500,
"icon": "Gilded dragonhide set.png",
"name": "Gilded dragonhide set"
},
{
"examine": "How does it all fit in there?",
"id": 8608,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Gilded dresser (flatpack).png",
"name": "Gilded dresser (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8588,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Gilded four-poster (flatpack).png",
"name": "Gilded four-poster (flatpack)"
},
{
"examine": "Rune full helmet with gold plate.",
"id": 3486,
"members": false,
"lowalch": 14080,
"limit": 8,
"value": 35200,
"highalch": 21120,
"icon": "Gilded full helm.png",
"name": "Gilded full helm"
},
{
"examine": "A rune-tipped, one-handed hasta, with gold plate.",
"id": 20161,
"members": true,
"lowalch": 8320,
"limit": 70,
"value": 20800,
"highalch": 12480,
"icon": "Gilded hasta.png",
"name": "Gilded hasta"
},
{
"examine": "Rune kiteshield with gold plate.",
"id": 3488,
"members": false,
"lowalch": 21760,
"limit": 8,
"value": 54400,
"highalch": 32640,
"icon": "Gilded kiteshield.png",
"name": "Gilded kiteshield"
},
{
"examine": "Gilded mahogany magic wardrobe.",
"id": 9857,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Gilded magic wardrobe (flatpack).png",
"name": "Gilded magic wardrobe (flatpack)"
},
{
"examine": "A medium sized helmet with gold plate.",
"id": 20146,
"members": false,
"lowalch": 7680,
"limit": 70,
"value": 19200,
"highalch": 11520,
"icon": "Gilded med helm.png",
"name": "Gilded med helm"
},
{
"examine": "Used for mining and very stylish.",
"id": 23276,
"members": false,
"lowalch": 20000,
"limit": 4,
"value": 50000,
"highalch": 30000,
"icon": "Gilded pickaxe.png",
"name": "Gilded pickaxe"
},
{
"examine": "Rune platebody with gold plate.",
"id": 3481,
"members": false,
"lowalch": 26000,
"limit": 8,
"value": 65000,
"highalch": 39000,
"icon": "Gilded platebody.png",
"name": "Gilded platebody"
},
{
"examine": "Rune platelegs with gold plate.",
"id": 3483,
"members": false,
"lowalch": 25600,
"limit": 8,
"value": 64000,
"highalch": 38400,
"icon": "Gilded platelegs.png",
"name": "Gilded platelegs"
},
{
"examine": "Rune plateskirt with gold plate.",
"id": 3485,
"members": false,
"lowalch": 25600,
"limit": 8,
"value": 64000,
"highalch": 38400,
"icon": "Gilded plateskirt.png",
"name": "Gilded plateskirt"
},
{
"examine": "Rune scimitar with gold plate.",
"id": 12389,
"members": false,
"lowalch": 10240,
"limit": 8,
"value": 25600,
"highalch": 15360,
"icon": "Gilded scimitar.png",
"name": "Gilded scimitar"
},
{
"examine": "Oh, I'm a gold digger?",
"id": 23282,
"members": false,
"lowalch": 400,
"limit": 4,
"value": 1000,
"highalch": 600,
"icon": "Gilded spade.png",
"name": "Gilded spade"
},
{
"examine": "A rune tipped spear with gold plate.",
"id": 20158,
"members": true,
"lowalch": 8320,
"limit": 70,
"value": 20800,
"highalch": 12480,
"icon": "Gilded spear.png",
"name": "Gilded spear"
},
{
"examine": "A medium square shield in gold plate.",
"id": 20152,
"members": false,
"lowalch": 15360,
"limit": 70,
"value": 38400,
"highalch": 23040,
"icon": "Gilded sq shield.png",
"name": "Gilded sq shield"
},
{
"examine": "How does it all fit in there?",
"id": 8622,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Gilded wardrobe (flatpack).png",
"name": "Gilded wardrobe (flatpack)"
},
{
"examine": "A strong spirit that tastes of Juniper.",
"id": 2019,
"members": true,
"lowalch": 2,
"limit": 13000,
"value": 5,
"highalch": 3,
"icon": "Gin.png",
"name": "Gin"
},
{
"examine": "Used to form molten glass into useful items.",
"id": 1785,
"members": true,
"lowalch": 1,
"limit": 40,
"value": 2,
"highalch": 1,
"icon": "Glassblowing pipe.png",
"name": "Glassblowing pipe"
},
{
"examine": "A dark power is woven into these gloves.",
"id": 20134,
"members": true,
"lowalch": 4000,
"limit": 70,
"value": 10000,
"highalch": 6000,
"icon": "Gloves of darkness.png",
"name": "Gloves of darkness"
},
{
"examine": "Made from dark kebbit fur, these are perfect for tasks of a stealthier nature.",
"id": 10075,
"members": true,
"lowalch": 240,
"limit": 8000,
"value": 600,
"highalch": 360,
"icon": "Gloves of silence.png",
"name": "Gloves of silence"
},
{
"examine": "Tally Ho!",
"id": 9472,
"members": true,
"lowalch": 400,
"limit": 4,
"value": 1000,
"highalch": 600,
"icon": "Gnome goggles.png",
"name": "Gnome goggles"
},
{
"examine": "A scarf. You feel your upper lip stiffening.",
"id": 9470,
"members": true,
"lowalch": 400,
"limit": 4,
"value": 1000,
"highalch": 600,
"icon": "Gnome scarf.png",
"name": "Gnome scarf"
},
{
"examine": "It's Aluft Gianne's secret mix of spices.",
"id": 2169,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Gnome spice.png",
"name": "Gnome spice"
},
{
"examine": "A ball used in Gnomeball.",
"id": 751,
"members": true,
"lowalch": 1,
"limit": 150,
"value": 1,
"highalch": 1,
"icon": "Gnomeball.png",
"name": "Gnomeball"
},
{
"examine": "A large ovenproof bowl.",
"id": 2166,
"members": true,
"lowalch": 4,
"limit": 40,
"value": 10,
"highalch": 6,
"icon": "Gnomebowl mould.png",
"name": "Gnomebowl mould"
},
{
"examine": "Fill it with firelighters to make pretty fire.",
"id": 20275,
"members": true,
"lowalch": 400,
"limit": 40,
"value": 1000,
"highalch": 600,
"icon": "Gnomish firelighter.png",
"name": "Gnomish firelighter"
},
{
"examine": "Finely ground desert goat horn.",
"id": 9736,
"members": true,
"lowalch": 4,
"limit": 13000,
"value": 12,
"highalch": 7,
"icon": "Goat horn dust.png",
"name": "Goat horn dust"
},
{
"examine": "A tattered goblin holy book.",
"id": 10999,
"members": true,
"lowalch": 1,
"limit": 15,
"value": 1,
"highalch": 1,
"icon": "Goblin book.png",
"name": "Goblin book"
},
{
"examine": "Some brown armour designed to fit goblins.",
"id": 288,
"members": false,
"lowalch": 16,
"limit": 15,
"value": 40,
"highalch": 24,
"icon": "Goblin mail.png",
"name": "Goblin mail"
},
{
"examine": "Let's start a flash mob!",
"id": 12251,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Goblin mask.png",
"name": "Goblin mask"
},
{
"examine": "The blade for the ultimate weapon.",
"id": 11798,
"members": true,
"lowalch": 300000,
"limit": 10000,
"value": 750000,
"highalch": 450000,
"icon": "Godsword blade.png",
"name": "Godsword blade"
},
{
"examine": "A part of the Godsword blade.",
"id": 11818,
"members": true,
"lowalch": 100000,
"limit": 11000,
"value": 250000,
"highalch": 150000,
"icon": "Godsword shard 1.png",
"name": "Godsword shard 1"
},
{
"examine": "A part of the Godsword blade.",
"id": 11820,
"members": true,
"lowalch": 100000,
"limit": 11000,
"value": 250000,
"highalch": 150000,
"icon": "Godsword shard 2.png",
"name": "Godsword shard 2"
},
{
"examine": "A part of the Godsword blade.",
"id": 11822,
"members": true,
"lowalch": 100000,
"limit": 11000,
"value": 250000,
"highalch": 150000,
"icon": "Godsword shard 3.png",
"name": "Godsword shard 3"
},
{
"examine": "A plain gold amulet.",
"id": 1692,
"members": false,
"lowalch": 140,
"limit": 18000,
"value": 350,
"highalch": 210,
"icon": "Gold amulet.png",
"name": "Gold amulet"
},
{
"examine": "It needs a string so I can wear it.",
"id": 1673,
"members": false,
"lowalch": 140,
"limit": 18000,
"value": 350,
"highalch": 210,
"icon": "Gold amulet (u).png",
"name": "Gold amulet (u)"
},
{
"examine": "It's a bar of gold.",
"id": 2357,
"members": false,
"lowalch": 120,
"limit": 10000,
"value": 300,
"highalch": 180,
"icon": "Gold bar.png",
"name": "Gold bar"
},
{
"examine": "I wonder if this is valuable.",
"id": 11069,
"members": true,
"lowalch": 220,
"limit": 18000,
"value": 550,
"highalch": 330,
"icon": "Gold bracelet.png",
"name": "Gold bracelet"
},
{
"examine": "A well made elegant ladies' gold blouse.",
"id": 12343,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Gold elegant blouse.png",
"name": "Gold elegant blouse"
},
{
"examine": "A rather elegant pair of men's gold pantaloons.",
"id": 12349,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Gold elegant legs.png",
"name": "Gold elegant legs"
},
{
"examine": "A well made elegant men's gold shirt.",
"id": 12347,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Gold elegant shirt.png",
"name": "Gold elegant shirt"
},
{
"examine": "A rather elegant gold skirt.",
"id": 12345,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Gold elegant skirt.png",
"name": "Gold elegant skirt"
},
{
"examine": "A minimalist's hat.",
"id": 12303,
"members": true,
"lowalch": 16,
"limit": 4,
"value": 40,
"highalch": 24,
"icon": "Gold headband.png",
"name": "Gold headband"
},
{
"examine": "A very delicate sheet of gold.",
"id": 8784,
"members": true,
"lowalch": 40000,
"limit": 11000,
"value": 100000,
"highalch": 60000,
"icon": "Gold leaf.png",
"name": "Gold leaf"
},
{
"examine": "Perhaps someone can do something with this.",
"id": 25454,
"members": true,
"lowalch": 12800,
"limit": 8,
"value": 32000,
"highalch": 19200,
"icon": "Gold locks.png",
"name": "Gold locks"
},
{
"examine": "I wonder if this is valuable.",
"id": 1654,
"members": false,
"lowalch": 180,
"limit": 18000,
"value": 450,
"highalch": 270,
"icon": "Gold necklace.png",
"name": "Gold necklace"
},
{
"examine": "This needs refining.",
"id": 444,
"members": false,
"lowalch": 60,
"limit": 30000,
"value": 150,
"highalch": 90,
"icon": "Gold ore.png",
"name": "Gold ore"
},
{
"examine": "A valuable ring.",
"id": 1635,
"members": false,
"lowalch": 140,
"limit": 18000,
"value": 350,
"highalch": 210,
"icon": "Gold ring.png",
"name": "Gold ring"
},
{
"examine": "A seal. It's gold.",
"id": 9040,
"members": true,
"lowalch": 12,
"limit": 13000,
"value": 30,
"highalch": 18,
"icon": "Gold seal.png",
"name": "Gold seal"
},
{
"examine": "Makes me feel like a wealthy Princess.",
"id": 26788,
"members": true,
"lowalch": 40,
"limit": 10000,
"value": 100,
"highalch": 60,
"icon": "Gold tiara.png",
"name": "Gold tiara"
},
{
"examine": "An astonishingly gold apron.",
"id": 20208,
"members": false,
"lowalch": 1,
"limit": 4,
"value": 2,
"highalch": 1,
"icon": "Golden apron.png",
"name": "Golden apron"
},
{
"examine": "What a perfectly reasonable hat.",
"id": 20205,
"members": false,
"lowalch": 1,
"limit": 4,
"value": 2,
"highalch": 1,
"icon": "Golden chef's hat.png",
"name": "Golden chef's hat"
},
{
"examine": "Little ornament in the shape of a scarab.",
"id": 9028,
"members": true,
"lowalch": 12,
"limit": 13000,
"value": 30,
"highalch": 18,
"icon": "Golden scarab.png",
"name": "Golden scarab"
},
{
"examine": "A small golden statuette.",
"id": 9034,
"members": true,
"lowalch": 12,
"limit": 13000,
"value": 30,
"highalch": 18,
"icon": "Golden statuette.png",
"name": "Golden statuette"
},
{
"examine": "The top of a Golovanova fruit - edible once cooked and full of Vitamin G.",
"id": 19653,
"members": true,
"lowalch": 1,
"limit": 100,
"value": 3,
"highalch": 1,
"icon": "Golovanova fruit top.png",
"name": "Golovanova fruit top"
},
{
"examine": "A gourmet impling in a jar.",
"id": 11242,
"members": true,
"lowalch": 20,
"limit": 18000,
"value": 50,
"highalch": 30,
"icon": "Gourmet impling jar.png",
"name": "Gourmet impling jar"
},
{
"examine": "Plant this in a herb patch to grow Goutweed.",
"id": 6311,
"members": true,
"lowalch": 80,
"limit": 5,
"value": 200,
"highalch": 120,
"icon": "Gout tuber.png",
"name": "Gout tuber"
},
{
"examine": "It's a perfect-looking graahk fur.",
"id": 10099,
"members": true,
"lowalch": 48,
"limit": 10000,
"value": 120,
"highalch": 72,
"icon": "Graahk fur.png",
"name": "Graahk fur"
},
{
"examine": "This should make me harder to spot in desert areas.",
"id": 10051,
"members": true,
"lowalch": 300,
"limit": 150,
"value": 750,
"highalch": 450,
"icon": "Graahk headdress.png",
"name": "Graahk headdress"
},
{
"examine": "These should make me harder to spot in desert areas.",
"id": 10047,
"members": true,
"lowalch": 60,
"limit": 150,
"value": 150,
"highalch": 90,
"icon": "Graahk legs.png",
"name": "Graahk legs"
},
{
"examine": "This should make me harder to spot in desert areas.",
"id": 10049,
"members": true,
"lowalch": 60,
"limit": 150,
"value": 150,
"highalch": 90,
"icon": "Graahk top.png",
"name": "Graahk top"
},
{
"examine": "Some wheat heads.",
"id": 1947,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Grain.png",
"name": "Grain"
},
{
"examine": "A seed pod of the Grand Tree.",
"id": 9469,
"members": true,
"lowalch": 100,
"limit": 15,
"value": 250,
"highalch": 150,
"icon": "Grand seed pod.png",
"name": "Grand seed pod"
},
{
"examine": "A small chunk of granite.",
"id": 6981,
"members": true,
"lowalch": 1,
"limit": 100,
"value": 1,
"highalch": 1,
"icon": "Granite (2kg).png",
"name": "Granite (2kg)"
},
{
"examine": "A tiny chunk of granite.",
"id": 6979,
"members": true,
"lowalch": 1,
"limit": 100,
"value": 1,
"highalch": 1,
"icon": "Granite (500g).png",
"name": "Granite (500g)"
},
{
"examine": "A medium-sized chunk of granite.",
"id": 6983,
"members": true,
"lowalch": 1,
"limit": 100,
"value": 1,
"highalch": 1,
"icon": "Granite (5kg).png",
"name": "Granite (5kg)"
},
{
"examine": "Provides excellent protection.",
"id": 10564,
"members": true,
"lowalch": 32000,
"limit": 70,
"value": 80000,
"highalch": 48000,
"icon": "Granite body.png",
"name": "Granite body"
},
{
"examine": "These will protect my feet, but are a bit heavy.",
"id": 21643,
"members": true,
"lowalch": 5000,
"limit": 70,
"value": 12500,
"highalch": 7500,
"icon": "Granite boots.png",
"name": "Granite boots"
},
{
"examine": "A strong clamp, used for hardening granite maul blocks.",
"id": 12849,
"members": true,
"lowalch": 2000,
"limit": 50,
"value": 5000,
"highalch": 3000,
"icon": "Granite clamp.png",
"name": "Granite clamp"
},
{
"examine": "Never take these for granite.",
"id": 21736,
"members": true,
"lowalch": 13600,
"limit": 8,
"value": 34000,
"highalch": 20400,
"icon": "Granite gloves.png",
"name": "Granite gloves"
},
{
"examine": "Hammer time.",
"id": 21742,
"members": true,
"lowalch": 25600,
"limit": 8,
"value": 64000,
"highalch": 38400,
"icon": "Granite hammer.png",
"name": "Granite hammer"
},
{
"examine": "A stone helmet.",
"id": 10589,
"members": true,
"lowalch": 18400,
"limit": 70,
"value": 46000,
"highalch": 27600,
"icon": "Granite helm.png",
"name": "Granite helm"
},
{
"examine": "These look pretty heavy.",
"id": 6809,
"members": true,
"lowalch": 26400,
"limit": 70,
"value": 66000,
"highalch": 39600,
"icon": "Granite legs.png",
"name": "Granite legs"
},
{
"examine": "A razor sharp longsword made of stone.",
"id": 21646,
"members": true,
"lowalch": 12800,
"limit": 70,
"value": 32000,
"highalch": 19200,
"icon": "Granite longsword.png",
"name": "Granite longsword"
},
{
"examine": "Simplicity is the best weapon.",
"id": 4153,
"members": true,
"lowalch": 20000,
"limit": 70,
"value": 50000,
"highalch": 30000,
"icon": "Granite maul.png",
"name": "Granite maul"
},
{
"examine": "A solid ring.",
"id": 21739,
"members": true,
"lowalch": 6000,
"limit": 70,
"value": 15000,
"highalch": 9000,
"icon": "Granite ring.png",
"name": "Granite ring"
},
{
"examine": "A solid stone shield.",
"id": 3122,
"members": true,
"lowalch": 22400,
"limit": 70,
"value": 56000,
"highalch": 33600,
"icon": "Granite shield.png",
"name": "Granite shield"
},
{
"examine": "A grape seed for growing in a vinery.",
"id": 13657,
"members": true,
"lowalch": 29,
"limit": 200,
"value": 74,
"highalch": 44,
"icon": "Grape seed 5.png",
"name": "Grape seed"
},
{
"examine": "Good grapes for wine making.",
"id": 1987,
"members": false,
"lowalch": 1,
"limit": 20000,
"value": 1,
"highalch": 1,
"icon": "Grapes.png",
"name": "Grapes"
},
{
"examine": "Evil.",
"id": 20023,
"members": true,
"lowalch": 1600,
"limit": 4,
"value": 4000,
"highalch": 2400,
"icon": "Greater demon mask.png",
"name": "Greater demon mask"
},
{
"examine": "A descendant of the salamander family.",
"id": 22835,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 3,
"highalch": 1,
"icon": "Greater siren.png",
"name": "Greater siren"
},
{
"examine": "Stylish!",
"id": 7323,
"members": true,
"lowalch": 90,
"limit": 4,
"value": 225,
"highalch": 135,
"icon": "Green boater.png",
"name": "Green boater"
},
{
"examine": "They're soft, silky and green.",
"id": 628,
"members": true,
"lowalch": 80,
"limit": 150,
"value": 200,
"highalch": 120,
"icon": "Green boots.png",
"name": "Green boots"
},
{
"examine": "A thick green cape.",
"id": 1027,
"members": false,
"lowalch": 12,
"limit": 150,
"value": 32,
"highalch": 19,
"icon": "Green cape.png",
"name": "Green cape"
},
{
"examine": "Made from 100% real dragonhide.",
"id": 1135,
"members": false,
"lowalch": 3120,
"limit": 125,
"value": 7800,
"highalch": 4680,
"icon": "Green d'hide body.png",
"name": "Green d'hide body"
},
{
"examine": "Made from 100% real dragonhide. With colourful trim!",
"id": 7370,
"members": false,
"lowalch": 3120,
"limit": 8,
"value": 7800,
"highalch": 4680,
"icon": "Green d'hide body (g).png",
"name": "Green d'hide body (g)"
},
{
"examine": "Made from 100% real dragonhide. With colourful trim!",
"id": 7372,
"members": false,
"lowalch": 3120,
"limit": 8,
"value": 7800,
"highalch": 4680,
"icon": "Green d'hide body (t).png",
"name": "Green d'hide body (t)"
},
{
"examine": "Made from 100% real dragonhide.",
"id": 1099,
"members": false,
"lowalch": 1560,
"limit": 125,
"value": 3900,
"highalch": 2340,
"icon": "Green d'hide chaps.png",
"name": "Green d'hide chaps"
},
{
"examine": "Made from 100% real dragonhide. With colourful trim!",
"id": 7378,
"members": false,
"lowalch": 1560,
"limit": 8,
"value": 3900,
"highalch": 2340,
"icon": "Green d'hide chaps (g).png",
"name": "Green d'hide chaps (g)"
},
{
"examine": "Made from 100% real dragonhide. With colourful trim!",
"id": 7380,
"members": false,
"lowalch": 1560,
"limit": 8,
"value": 3900,
"highalch": 2340,
"icon": "Green d'hide chaps (t).png",
"name": "Green d'hide chaps (t)"
},
{
"examine": "A solid maple shield covered in green dragon leather.",
"id": 22275,
"members": true,
"lowalch": 2200,
"limit": 125,
"value": 5500,
"highalch": 3300,
"icon": "Green d'hide shield.png",
"name": "Green d'hide shield"
},
{
"examine": "Vambraces made from 100% real dragonhide.",
"id": 1065,
"members": false,
"lowalch": 1000,
"limit": 125,
"value": 2500,
"highalch": 1500,
"icon": "Green d'hide vambraces.png",
"name": "Green d'hide vambraces"
},
{
"examine": "Paints things green!",
"id": 12759,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Green dark bow paint.png",
"name": "Green dark bow paint"
},
{
"examine": "It's a piece of prepared green dragonhide.",
"id": 1745,
"members": true,
"lowalch": 20,
"limit": 13000,
"value": 50,
"highalch": 30,
"icon": "Green dragon leather.png",
"name": "Green dragon leather"
},
{
"examine": "Do I look scary?",
"id": 12518,
"members": true,
"lowalch": 4000,
"limit": 4,
"value": 10000,
"highalch": 6000,
"icon": "Green dragon mask.png",
"name": "Green dragon mask"
},
{
"examine": "The scaly rough hide from a Green Dragon.",
"id": 1753,
"members": true,
"lowalch": 54,
"limit": 13000,
"value": 135,
"highalch": 81,
"icon": "Green dragonhide.png",
"name": "Green dragonhide"
},
{
"examine": "A set containing a green dragonhide body, chaps and vambraces.",
"id": 12865,
"members": false,
"lowalch": 2000,
"limit": 125,
"value": 5000,
"highalch": 3000,
"icon": "Green dragonhide set.png",
"name": "Green dragonhide set"
},
{
"examine": "A little bottle of green dye.",
"id": 1771,
"members": false,
"lowalch": 2,
"limit": 150,
"value": 5,
"highalch": 3,
"icon": "Green dye.png",
"name": "Green dye"
},
{
"examine": "A well made elegant ladies' green blouse.",
"id": 10432,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Green elegant blouse.png",
"name": "Green elegant blouse"
},
{
"examine": "A rather elegant pair of men's green pantaloons.",
"id": 10414,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Green elegant legs.png",
"name": "Green elegant legs"
},
{
"examine": "A well made elegant men's green shirt.",
"id": 10412,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Green elegant shirt.png",
"name": "Green elegant shirt"
},
{
"examine": "A rather elegant green skirt.",
"id": 10434,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Green elegant skirt.png",
"name": "Green elegant skirt"
},
{
"examine": "Makes firelighting a lot easier.",
"id": 7330,
"members": true,
"lowalch": 6,
"limit": 250,
"value": 15,
"highalch": 9,
"icon": "Green firelighter.png",
"name": "Green firelighter"
},
{
"examine": "Aaaarrrghhh ... I'm a monster.",
"id": 1053,
"members": false,
"lowalch": 6,
"limit": 5,
"value": 15,
"highalch": 9,
"icon": "Green halloween mask.png",
"name": "Green halloween mask"
},
{
"examine": "A silly green pointed hat.",
"id": 658,
"members": true,
"lowalch": 64,
"limit": 150,
"value": 160,
"highalch": 96,
"icon": "Green hat.png",
"name": "Green hat"
},
{
"examine": "A minimalist's hat.",
"id": 12307,
"members": true,
"lowalch": 16,
"limit": 4,
"value": 40,
"highalch": 24,
"icon": "Green headband.png",
"name": "Green headband"
},
{
"examine": "A nice hat from a cracker.",
"id": 1044,
"members": false,
"lowalch": 1,
"limit": 5,
"value": 1,
"highalch": 1,
"icon": "Green partyhat.png",
"name": "Green partyhat"
},
{
"examine": "Made by Tree Gnomes with a thing for green.",
"id": 648,
"members": true,
"lowalch": 72,
"limit": 150,
"value": 180,
"highalch": 108,
"icon": "Green robe bottoms.png",
"name": "Green robe bottoms"
},
{
"examine": "The ultimate in gnome design, now in green!",
"id": 638,
"members": true,
"lowalch": 72,
"limit": 150,
"value": 180,
"highalch": 108,
"icon": "Green robe top.png",
"name": "Green robe top"
},
{
"examine": "Vambraces made from 100% real dragonhide. Now with added spikiness.",
"id": 10079,
"members": true,
"lowalch": 1000,
"limit": 125,
"value": 2500,
"highalch": 1500,
"icon": "Green spiky vambraces.png",
"name": "Green spiky vambraces"
},
{
"examine": "A glass of frothy ale.",
"id": 1909,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 2,
"highalch": 1,
"icon": "Greenman's ale.png",
"name": "Greenman's ale"
},
{
"examine": "How does it all fit in there?",
"id": 8522,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Greenman's ale (flatpack).png",
"name": "Greenman's ale (flatpack)"
},
{
"examine": "This looks a good deal stronger than normal Greenman's Ale.",
"id": 5743,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 2,
"highalch": 1,
"icon": "Greenman's ale(m).png",
"name": "Greenman's ale(m)"
},
{
"examine": "This keg contains 4 pints of Greenmans Ale.",
"id": 5793,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Greenmans ale(4).png",
"name": "Greenmans ale(4)"
},
{
"examine": "This keg contains 4 pints of mature Greenmans Ale.",
"id": 5873,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Greenmans ale(m4).png",
"name": "Greenmans ale(m4)"
},
{
"examine": "Very stylish!",
"id": 2894,
"members": false,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Grey boots.png",
"name": "Grey boots"
},
{
"examine": "These will keep my hands warm!",
"id": 2902,
"members": false,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Grey gloves.png",
"name": "Grey gloves"
},
{
"examine": "A silly pointed hat.",
"id": 2900,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Grey hat.png",
"name": "Grey hat"
},
{
"examine": "Made by werewolves for werewolves.",
"id": 2898,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Grey robe bottoms.png",
"name": "Grey robe bottoms"
},
{
"examine": "Some fine werewolf clothing.",
"id": 2896,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Grey robe top.png",
"name": "Grey robe top"
},
{
"examine": "A grey toy horse.",
"id": 2526,
"members": false,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Grey toy horsey.png",
"name": "Grey toy horsey"
},
{
"examine": "This would make warm clothing.",
"id": 958,
"members": true,
"lowalch": 20,
"limit": 18000,
"value": 50,
"highalch": 30,
"icon": "Grey wolf fur.png",
"name": "Grey wolf fur"
},
{
"examine": "It needs cleaning.",
"id": 211,
"members": true,
"lowalch": 7,
"limit": 13000,
"value": 18,
"highalch": 10,
"icon": "Grimy avantoe.png",
"name": "Grimy avantoe"
},
{
"examine": "It needs cleaning.",
"id": 215,
"members": true,
"lowalch": 8,
"limit": 11000,
"value": 22,
"highalch": 13,
"icon": "Grimy cadantine.png",
"name": "Grimy cadantine"
},
{
"examine": "It needs cleaning.",
"id": 217,
"members": true,
"lowalch": 9,
"limit": 11000,
"value": 24,
"highalch": 14,
"icon": "Grimy dwarf weed.png",
"name": "Grimy dwarf weed"
},
{
"examine": "It needs cleaning.",
"id": 199,
"members": true,
"lowalch": 5,
"limit": 13000,
"value": 13,
"highalch": 7,
"icon": "Grimy guam leaf.png",
"name": "Grimy guam leaf"
},
{
"examine": "It needs cleaning.",
"id": 205,
"members": true,
"lowalch": 6,
"limit": 13000,
"value": 16,
"highalch": 9,
"icon": "Grimy harralander.png",
"name": "Grimy harralander"
},
{
"examine": "It needs cleaning.",
"id": 209,
"members": true,
"lowalch": 6,
"limit": 13000,
"value": 17,
"highalch": 10,
"icon": "Grimy irit leaf.png",
"name": "Grimy irit leaf"
},
{
"examine": "It needs cleaning.",
"id": 213,
"members": true,
"lowalch": 8,
"limit": 13000,
"value": 20,
"highalch": 12,
"icon": "Grimy kwuarm.png",
"name": "Grimy kwuarm"
},
{
"examine": "It needs cleaning.",
"id": 2485,
"members": true,
"lowalch": 9,
"limit": 11000,
"value": 23,
"highalch": 13,
"icon": "Grimy lantadyme.png",
"name": "Grimy lantadyme"
},
{
"examine": "It needs cleaning.",
"id": 201,
"members": true,
"lowalch": 5,
"limit": 13000,
"value": 14,
"highalch": 8,
"icon": "Grimy marrentill.png",
"name": "Grimy marrentill"
},
{
"examine": "It needs cleaning.",
"id": 207,
"members": true,
"lowalch": 12,
"limit": 11000,
"value": 30,
"highalch": 18,
"icon": "Grimy ranarr weed.png",
"name": "Grimy ranarr weed"
},
{
"examine": "It needs cleaning.",
"id": 3051,
"members": true,
"lowalch": 8,
"limit": 11000,
"value": 21,
"highalch": 12,
"icon": "Grimy snapdragon.png",
"name": "Grimy snapdragon"
},
{
"examine": "It needs cleaning.",
"id": 203,
"members": true,
"lowalch": 6,
"limit": 13000,
"value": 15,
"highalch": 9,
"icon": "Grimy tarromin.png",
"name": "Grimy tarromin"
},
{
"examine": "It needs cleaning.",
"id": 3049,
"members": true,
"lowalch": 7,
"limit": 13000,
"value": 19,
"highalch": 11,
"icon": "Grimy toadflax.png",
"name": "Grimy toadflax"
},
{
"examine": "It needs cleaning.",
"id": 219,
"members": true,
"lowalch": 10,
"limit": 11000,
"value": 25,
"highalch": 15,
"icon": "Grimy torstol.png",
"name": "Grimy torstol"
},
{
"examine": "A murky glass of some sort of drink.",
"id": 1915,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 3,
"highalch": 1,
"icon": "Grog.png",
"name": "Grog"
},
{
"examine": "One of the ingredients for making fish food.",
"id": 6681,
"members": true,
"lowalch": 1,
"limit": 100,
"value": 2,
"highalch": 1,
"icon": "Ground guam.png",
"name": "Ground guam"
},
{
"examine": "It looks like the key to a chest (Used to open the grubby chest in Forthos Dungeon).",
"id": 23499,
"members": true,
"lowalch": 12,
"limit": 11000,
"value": 32,
"highalch": 19,
"icon": "Grubby key.png",
"name": "Grubby key"
},
{
"examine": "A bitter green herb.",
"id": 249,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 3,
"highalch": 1,
"icon": "Guam leaf.png",
"name": "Guam leaf"
},
{
"examine": "I need another ingredient to finish this Guam potion.",
"id": 91,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 3,
"highalch": 1,
"icon": "Guam potion (unf).png",
"name": "Guam potion (unf)"
},
{
"examine": "A guam seed - plant in a herb patch.",
"id": 5291,
"members": true,
"lowalch": 1,
"limit": 600,
"value": 1,
"highalch": 1,
"icon": "Guam seed 5.png",
"name": "Guam seed"
},
{
"examine": "A thick, foul-smelling, tar-like substance with a green tinge.",
"id": 10142,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 3,
"highalch": 1,
"icon": "Guam tar.png",
"name": "Guam tar"
},
{
"examine": "Some sturdy Bandos boots, made sturdier with the blackened tourmaline core of the Grotesque Guardians.",
"id": 21733,
"members": true,
"lowalch": 122000,
"limit": 8,
"value": 305000,
"highalch": 183000,
"icon": "Guardian boots.png",
"name": "Guardian boots"
},
{
"examine": "A set containing a Guthan's helm, chainskirt, platebody and warspear.",
"id": 12873,
"members": true,
"lowalch": 160000,
"limit": 8,
"value": 400000,
"highalch": 240000,
"icon": "Guthan's armour set.png",
"name": "Guthan's armour set"
},
{
"examine": "Guthan the Infested's chainskirt.",
"id": 4730,
"members": true,
"lowalch": 110000,
"limit": 15,
"value": 275000,
"highalch": 165000,
"icon": "Guthan's chainskirt.png",
"name": "Guthan's chainskirt"
},
{
"examine": "Guthan the Infested's chainskirt.",
"id": 4926,
"members": true,
"lowalch": 110000,
"limit": 15,
"value": 275000,
"highalch": 165000,
"icon": "Guthan's chainskirt 0.png",
"name": "Guthan's chainskirt 0"
},
{
"examine": "Guthan the Infested's helm.",
"id": 4724,
"members": true,
"lowalch": 41200,
"limit": 15,
"value": 103000,
"highalch": 61800,
"icon": "Guthan's helm.png",
"name": "Guthan's helm"
},
{
"examine": "Guthan the Infested's helm.",
"id": 4908,
"members": true,
"lowalch": 41200,
"limit": 15,
"value": 103000,
"highalch": 61800,
"icon": "Guthan's helm 0.png",
"name": "Guthan's helm 0"
},
{
"examine": "Guthan the Infested's platebody armour.",
"id": 4728,
"members": true,
"lowalch": 112000,
"limit": 15,
"value": 280000,
"highalch": 168000,
"icon": "Guthan's platebody.png",
"name": "Guthan's platebody"
},
{
"examine": "Guthan the Infested's platebody armour.",
"id": 4920,
"members": true,
"lowalch": 112000,
"limit": 15,
"value": 280000,
"highalch": 168000,
"icon": "Guthan's platebody 0.png",
"name": "Guthan's platebody 0"
},
{
"examine": "Guthan the Infested's warspear.",
"id": 4726,
"members": true,
"lowalch": 40000,
"limit": 15,
"value": 100000,
"highalch": 60000,
"icon": "Guthan's warspear.png",
"name": "Guthan's warspear"
},
{
"examine": "Guthan the Infested's warspear.",
"id": 4914,
"members": true,
"lowalch": 40000,
"limit": 15,
"value": 100000,
"highalch": 60000,
"icon": "Guthan's warspear 0.png",
"name": "Guthan's warspear 0"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 13048,
"members": false,
"lowalch": 32000,
"limit": 8,
"value": 80000,
"highalch": 48000,
"icon": "Guthix armour set (lg).png",
"name": "Guthix armour set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 13050,
"members": false,
"lowalch": 32000,
"limit": 8,
"value": 80000,
"highalch": 48000,
"icon": "Guthix armour set (sk).png",
"name": "Guthix armour set (sk)"
},
{
"examine": "A potion of harralander, red spiders eggs, garlic and silver dust.",
"id": 7666,
"members": true,
"lowalch": 80,
"limit": 2000,
"value": 200,
"highalch": 120,
"icon": "Guthix balance(1).png",
"name": "Guthix balance(1)"
},
{
"examine": "A potion of harralander, red spiders eggs, garlic and silver dust.",
"id": 7664,
"members": true,
"lowalch": 80,
"limit": 2000,
"value": 200,
"highalch": 120,
"icon": "Guthix balance(2).png",
"name": "Guthix balance(2)"
},
{
"examine": "A potion of harralander, red spiders eggs, garlic and silver dust.",
"id": 7662,
"members": true,
"lowalch": 80,
"limit": 2000,
"value": 200,
"highalch": 120,
"icon": "Guthix balance(3).png",
"name": "Guthix balance(3)"
},
{
"examine": "A potion of harralander, red spiders eggs, garlic and silver dust.",
"id": 7660,
"members": true,
"lowalch": 80,
"limit": 2000,
"value": 200,
"highalch": 120,
"icon": "Guthix balance(4).png",
"name": "Guthix balance(4)"
},
{
"examine": "Guthix blessed dragonhide vambraces.",
"id": 10376,
"members": true,
"lowalch": 2400,
"limit": 8,
"value": 6000,
"highalch": 3600,
"icon": "Guthix bracers.png",
"name": "Guthix bracers"
},
{
"examine": "Guthix blessed dragonhide chaps.",
"id": 10380,
"members": true,
"lowalch": 2400,
"limit": 8,
"value": 6000,
"highalch": 3600,
"icon": "Guthix chaps.png",
"name": "Guthix chaps"
},
{
"examine": "A Guthix cloak.",
"id": 10448,
"members": true,
"lowalch": 800,
"limit": 8,
"value": 2000,
"highalch": 1200,
"icon": "Guthix cloak.png",
"name": "Guthix cloak"
},
{
"examine": "Guthix blessed dragonhide coif.",
"id": 10382,
"members": true,
"lowalch": 800,
"limit": 8,
"value": 2000,
"highalch": 1200,
"icon": "Guthix coif.png",
"name": "Guthix coif"
},
{
"examine": "A Guthix crozier.",
"id": 10442,
"members": true,
"lowalch": 2000,
"limit": 8,
"value": 5000,
"highalch": 3000,
"icon": "Guthix crozier.png",
"name": "Guthix crozier"
},
{
"examine": "Guthix blessed dragonhide body armour.",
"id": 10378,
"members": true,
"lowalch": 5200,
"limit": 8,
"value": 13000,
"highalch": 7800,
"icon": "Guthix d'hide body.png",
"name": "Guthix d'hide body"
},
{
"examine": "Guthix blessed dragonhide boots.",
"id": 19927,
"members": true,
"lowalch": 3720,
"limit": 8,
"value": 9300,
"highalch": 5580,
"icon": "Guthix d'hide boots.png",
"name": "Guthix d'hide boots"
},
{
"examine": "Guthix blessed wooden shield covered in dragon leather.",
"id": 23188,
"members": true,
"lowalch": 11333,
"limit": 8,
"value": 28334,
"highalch": 17000,
"icon": "Guthix d'hide shield.png",
"name": "Guthix d'hide shield"
},
{
"examine": "A set containing a Guthix dragonhide coif, body, chaps and vambraces.",
"id": 13165,
"members": true,
"lowalch": 3600,
"limit": 8,
"value": 9000,
"highalch": 5400,
"icon": "Guthix dragonhide set.png",
"name": "Guthix dragonhide set"
},
{
"examine": "A rune full face helmet in the colours of Guthix.",
"id": 2673,
"members": false,
"lowalch": 14080,
"limit": 8,
"value": 35200,
"highalch": 21120,
"icon": "Guthix full helm.png",
"name": "Guthix full helm"
},
{
"examine": "Rune kiteshield in the colours of Guthix.",
"id": 2675,
"members": false,
"lowalch": 21760,
"limit": 8,
"value": 54400,
"highalch": 32640,
"icon": "Guthix kiteshield.png",
"name": "Guthix kiteshield"
},
{
"examine": "A Guthix mitre.",
"id": 10454,
"members": true,
"lowalch": 2000,
"limit": 8,
"value": 5000,
"highalch": 3000,
"icon": "Guthix mitre.png",
"name": "Guthix mitre"
},
{
"examine": "A Guthix Mjolnir.",
"id": 6760,
"members": true,
"lowalch": 250,
"limit": 8,
"value": 625,
"highalch": 375,
"icon": "Guthix mjolnir.png",
"name": "Guthix mjolnir"
},
{
"examine": "This seems to have been torn from a book...",
"id": 3835,
"members": true,
"lowalch": 80,
"limit": 5,
"value": 200,
"highalch": 120,
"icon": "Guthix page 1.png",
"name": "Guthix page 1"
},
{
"examine": "This seems to have been torn from a book...",
"id": 3836,
"members": true,
"lowalch": 80,
"limit": 5,
"value": 200,
"highalch": 120,
"icon": "Guthix page 2.png",
"name": "Guthix page 2"
},
{
"examine": "This seems to have been torn from a book...",
"id": 3837,
"members": true,
"lowalch": 80,
"limit": 5,
"value": 200,
"highalch": 120,
"icon": "Guthix page 3.png",
"name": "Guthix page 3"
},
{
"examine": "This seems to have been torn from a book...",
"id": 3838,
"members": true,
"lowalch": 80,
"limit": 5,
"value": 200,
"highalch": 120,
"icon": "Guthix page 4.png",
"name": "Guthix page 4"
},
{
"examine": "Rune platebody in the colours of Guthix.",
"id": 2669,
"members": false,
"lowalch": 26000,
"limit": 8,
"value": 65000,
"highalch": 39000,
"icon": "Guthix platebody.png",
"name": "Guthix platebody"
},
{
"examine": "Rune plate legs in the colours of Guthix.",
"id": 2671,
"members": false,
"lowalch": 25600,
"limit": 8,
"value": 64000,
"highalch": 38400,
"icon": "Guthix platelegs.png",
"name": "Guthix platelegs"
},
{
"examine": "Rune plateskirt in the colours of Guthix.",
"id": 3480,
"members": false,
"lowalch": 25600,
"limit": 8,
"value": 64000,
"highalch": 38400,
"icon": "Guthix plateskirt.png",
"name": "Guthix plateskirt"
},
{
"examine": "A cup of Guthix Rest.",
"id": 4423,
"members": true,
"lowalch": 20,
"limit": 2000,
"value": 50,
"highalch": 30,
"icon": "Guthix rest(1).png",
"name": "Guthix rest(1)"
},
{
"examine": "A cup of Guthix Rest.",
"id": 4421,
"members": true,
"lowalch": 20,
"limit": 2000,
"value": 50,
"highalch": 30,
"icon": "Guthix rest(2).png",
"name": "Guthix rest(2)"
},
{
"examine": "A cup of Guthix Rest.",
"id": 4419,
"members": true,
"lowalch": 20,
"limit": 2000,
"value": 50,
"highalch": 30,
"icon": "Guthix rest(3).png",
"name": "Guthix rest(3)"
},
{
"examine": "A cup of Guthix Rest.",
"id": 4417,
"members": true,
"lowalch": 20,
"limit": 2000,
"value": 50,
"highalch": 30,
"icon": "Guthix rest(4).png",
"name": "Guthix rest(4)"
},
{
"examine": "Leggings from the Guthix Vestments.",
"id": 10466,
"members": true,
"lowalch": 2800,
"limit": 8,
"value": 7000,
"highalch": 4200,
"icon": "Guthix robe legs.png",
"name": "Guthix robe legs"
},
{
"examine": "Guthix Vestments.",
"id": 10462,
"members": true,
"lowalch": 2800,
"limit": 8,
"value": 7000,
"highalch": 4200,
"icon": "Guthix robe top.png",
"name": "Guthix robe top"
},
{
"examine": "A Guthix stole.",
"id": 10472,
"members": true,
"lowalch": 1000,
"limit": 8,
"value": 2500,
"highalch": 1500,
"icon": "Guthix stole.png",
"name": "Guthix stole"
},
{
"examine": "I can spin this into rope.",
"id": 10814,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Hair.png",
"name": "Hair"
},
{
"examine": "An optimist would say it's half full.",
"id": 1989,
"members": false,
"lowalch": 1,
"limit": 50,
"value": 1,
"highalch": 1,
"icon": "Half full wine jug.png",
"name": "Half full wine jug"
},
{
"examine": "The two halfs would surely make a full moon?",
"id": 20053,
"members": true,
"lowalch": 7780,
"limit": 4,
"value": 19450,
"highalch": 11670,
"icon": "Half moon spectacles.png",
"name": "Half moon spectacles"
},
{
"examine": "A set containing red, green and blue Halloween masks.",
"id": 13175,
"members": false,
"lowalch": 3,
"limit": 5,
"value": 8,
"highalch": 4,
"icon": "Halloween mask set.png",
"name": "Halloween mask set"
},
{
"examine": "HAM boots as worn by the Humans Against Monsters group.",
"id": 4310,
"members": true,
"lowalch": 30,
"limit": 15,
"value": 75,
"highalch": 45,
"icon": "Ham boots.png",
"name": "Ham boots"
},
{
"examine": "A HAM cape.",
"id": 4304,
"members": true,
"lowalch": 30,
"limit": 15,
"value": 75,
"highalch": 45,
"icon": "Ham cloak.png",
"name": "Ham cloak"
},
{
"examine": "HAM gloves as worn by the Humans Against Monsters group.",
"id": 4308,
"members": true,
"lowalch": 30,
"limit": 15,
"value": 75,
"highalch": 45,
"icon": "Ham gloves.png",
"name": "Ham gloves"
},
{
"examine": "Light-weight head protection and eye shield.",
"id": 4302,
"members": true,
"lowalch": 30,
"limit": 15,
"value": 75,
"highalch": 45,
"icon": "Ham hood.png",
"name": "Ham hood"
},
{
"examine": "A delicious joint of ham.",
"id": 23360,
"members": false,
"lowalch": 600,
"limit": 100,
"value": 1500,
"highalch": 900,
"icon": "Ham joint.png",
"name": "Ham joint"
},
{
"examine": "A badge for the HAM cult.",
"id": 4306,
"members": true,
"lowalch": 30,
"limit": 15,
"value": 75,
"highalch": 45,
"icon": "Ham logo.png",
"name": "Ham logo"
},
{
"examine": "The label says 'Vivid Crimson', but it looks pink to me!",
"id": 4300,
"members": true,
"lowalch": 30,
"limit": 15,
"value": 75,
"highalch": 45,
"icon": "Ham robe.png",
"name": "Ham robe"
},
{
"examine": "The label says 'Vivid Crimson', but it looks pink to me!",
"id": 4298,
"members": true,
"lowalch": 30,
"limit": 15,
"value": 75,
"highalch": 45,
"icon": "Ham shirt.png",
"name": "Ham shirt"
},
{
"examine": "Good for hitting things!",
"id": 2347,
"members": false,
"lowalch": 1,
"limit": 40,
"value": 1,
"highalch": 1,
"icon": "Hammer.png",
"name": "Hammer"
},
{
"examine": "A handful of Hammerstone Hops.",
"id": 5994,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 4,
"highalch": 2,
"icon": "Hammerstone hops.png",
"name": "Hammerstone hops"
},
{
"examine": "A Hammerstone hop seed - plant in a hops patch.",
"id": 5307,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 2,
"highalch": 1,
"icon": "Hammerstone seed 5.png",
"name": "Hammerstone seed"
},
{
"examine": "It's a piece of hard leather.",
"id": 1743,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Hard leather.png",
"name": "Hard leather"
},
{
"examine": "A solid oaken shield covered in hard leather.",
"id": 22269,
"members": true,
"lowalch": 200,
"limit": 125,
"value": 500,
"highalch": 300,
"icon": "Hard leather shield.png",
"name": "Hard leather shield"
},
{
"examine": "Harder than normal leather.",
"id": 1131,
"members": false,
"lowalch": 68,
"limit": 125,
"value": 170,
"highalch": 102,
"icon": "Hardleather body.png",
"name": "Hardleather body"
},
{
"examine": "An ancient magical orb, corrupted by darkness.",
"id": 24511,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 1,
"highalch": 1,
"icon": "Harmonised orb.png",
"name": "Harmonised orb"
},
{
"examine": "A teleport to Harmony Island.",
"id": 19625,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Harmony island teleport (tablet).png",
"name": "Harmony island teleport (tablet)"
},
{
"examine": "Useful for catching really big fish.",
"id": 311,
"members": false,
"lowalch": 2,
"limit": 40,
"value": 5,
"highalch": 3,
"icon": "Harpoon.png",
"name": "Harpoon"
},
{
"examine": "A useful herb.",
"id": 255,
"members": true,
"lowalch": 8,
"limit": 13000,
"value": 20,
"highalch": 12,
"icon": "Harralander.png",
"name": "Harralander"
},
{
"examine": "I need another ingredient to finish this Harralander potion.",
"id": 97,
"members": true,
"lowalch": 8,
"limit": 10000,
"value": 20,
"highalch": 12,
"icon": "Harralander potion (unf).png",
"name": "Harralander potion (unf)"
},
{
"examine": "A harralander seed - plant in a herb patch.",
"id": 5294,
"members": true,
"lowalch": 1,
"limit": 600,
"value": 4,
"highalch": 2,
"icon": "Harralander seed 5.png",
"name": "Harralander seed"
},
{
"examine": "A dark, thick, foul-smelling, tar-like substance.",
"id": 10145,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 1,
"highalch": 1,
"icon": "Harralander tar.png",
"name": "Harralander tar"
},
{
"examine": "Essential pirate wear.",
"id": 8928,
"members": true,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Hat eyepatch.png",
"name": "Hat eyepatch"
},
{
"examine": "A wooden arrow shaft with flights attached.",
"id": 53,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 1,
"highalch": 1,
"icon": "Headless arrow.png",
"name": "Headless arrow"
},
{
"examine": "A powerful weapon forged from the wreckage of an airship.",
"id": 19481,
"members": true,
"lowalch": 380000,
"limit": 8,
"value": 950000,
"highalch": 570000,
"icon": "Heavy ballista.png",
"name": "Heavy ballista"
},
{
"examine": "A heavy wooden frame.",
"id": 19589,
"members": true,
"lowalch": 20000,
"limit": 8,
"value": 50000,
"highalch": 30000,
"icon": "Heavy frame.png",
"name": "Heavy frame"
},
{
"examine": "A gift from Neitiznot's Burgher.",
"id": 10828,
"members": true,
"lowalch": 20000,
"limit": 70,
"value": 50000,
"highalch": 30000,
"icon": "Helm of neitiznot.png",
"name": "Helm of neitiznot"
},
{
"examine": "Some nicely cooked herring.",
"id": 347,
"members": false,
"lowalch": 4,
"limit": 6000,
"value": 10,
"highalch": 6,
"icon": "Herring.png",
"name": "Herring"
},
{
"examine": "Your money or your life!",
"id": 2631,
"members": true,
"lowalch": 16,
"limit": 4,
"value": 40,
"highalch": 24,
"icon": "Highwayman mask.png",
"name": "Highwayman mask"
},
{
"examine": "A brutal weapon popular among giants.",
"id": 20756,
"members": false,
"lowalch": 24000,
"limit": 125,
"value": 60000,
"highalch": 36000,
"icon": "Hill giant club.png",
"name": "Hill giant club"
},
{
"examine": "The path of the righteous man is beset on all sides by the inequities of the selfish and the tyranny of evil men.",
"id": 20220,
"members": true,
"lowalch": 32,
"limit": 5,
"value": 80,
"highalch": 48,
"icon": "Holy blessing.png",
"name": "Holy blessing"
},
{
"examine": "A set containing the four pages of Saradomin's Holy Book.",
"id": 13149,
"members": true,
"lowalch": 2800,
"limit": 5,
"value": 7000,
"highalch": 4200,
"icon": "Holy book page set.png",
"name": "Holy book page set"
},
{
"examine": "A bottle of holy elixir.",
"id": 12833,
"members": true,
"lowalch": 300000,
"limit": 5,
"value": 750000,
"highalch": 450000,
"icon": "Holy elixir.png",
"name": "Holy elixir"
},
{
"examine": "Used to make holy symbols of Saradomin.",
"id": 1599,
"members": false,
"lowalch": 2,
"limit": 40,
"value": 5,
"highalch": 3,
"icon": "Holy mould.png",
"name": "Holy mould"
},
{
"examine": "Holy footwear!",
"id": 12598,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Holy sandals.png",
"name": "Holy sandals"
},
{
"examine": "A blessed holy symbol of Saradomin.",
"id": 1718,
"members": false,
"lowalch": 120,
"limit": 125,
"value": 300,
"highalch": 180,
"icon": "Holy symbol.png",
"name": "Holy symbol"
},
{
"examine": "Holy handwear!",
"id": 19997,
"members": true,
"lowalch": 3840,
"limit": 4,
"value": 9600,
"highalch": 5760,
"icon": "Holy wraps.png",
"name": "Holy wraps"
},
{
"examine": "Law is not law if it violates the principles of eternal justice.",
"id": 20229,
"members": true,
"lowalch": 32,
"limit": 5,
"value": 80,
"highalch": 48,
"icon": "Honourable blessing.png",
"name": "Honourable blessing"
},
{
"examine": "A dark power is woven into this hood.",
"id": 20128,
"members": true,
"lowalch": 4000,
"limit": 70,
"value": 10000,
"highalch": 6000,
"icon": "Hood of darkness.png",
"name": "Hood of darkness"
},
{
"examine": "A banner made of redwood baring the sigil of House Hosidius.",
"id": 20254,
"members": true,
"lowalch": 28,
"limit": 4,
"value": 70,
"highalch": 42,
"icon": "Hosidius banner.png",
"name": "Hosidius banner"
},
{
"examine": "A rare hood from Hosidius.",
"id": 20116,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Hosidius hood.png",
"name": "Hosidius hood"
},
{
"examine": "A green scarf adorned with an ornamental leaf.",
"id": 19946,
"members": true,
"lowalch": 4000,
"limit": 4,
"value": 10000,
"highalch": 6000,
"icon": "Hosidius scarf.png",
"name": "Hosidius scarf"
},
{
"examine": "1 dose of Hunter potion.",
"id": 10004,
"members": true,
"lowalch": 2,
"limit": 2000,
"value": 6,
"highalch": 3,
"icon": "Hunter potion(1).png",
"name": "Hunter potion(1)"
},
{
"examine": "2 doses of Hunter potion.",
"id": 10002,
"members": true,
"lowalch": 3,
"limit": 2000,
"value": 9,
"highalch": 5,
"icon": "Hunter potion(2).png",
"name": "Hunter potion(2)"
},
{
"examine": "3 doses of Hunter potion.",
"id": 10000,
"members": true,
"lowalch": 4,
"limit": 2000,
"value": 12,
"highalch": 7,
"icon": "Hunter potion(3).png",
"name": "Hunter potion(3)"
},
{
"examine": "4 doses of Hunter potion.",
"id": 9998,
"members": true,
"lowalch": 6,
"limit": 2000,
"value": 15,
"highalch": 9,
"icon": "Hunter potion(4).png",
"name": "Hunter potion(4)"
},
{
"examine": "I can make some spears with these.",
"id": 29311,
"members": true,
"lowalch": 2,
"limit": 10000,
"value": 6,
"highalch": 3,
"icon": "Hunter spear tips.png",
"name": "Hunter spear tips"
},
{
"highalch": 210,
"members": true,
"name": "Hunter's spear",
"examine": "Throw it... Or don't.",
"id": 29305,
"value": 350,
"icon": "Hunter's spear.png",
"lowalch": 140
},
{
"examine": "A weapon made of bone and wood.",
"id": 10156,
"members": true,
"lowalch": 520,
"limit": 70,
"value": 1300,
"highalch": 780,
"icon": "Hunters' crossbow.png",
"name": "Hunters' crossbow"
},
{
"examine": "A weapon made of horn and bone.",
"id": 28869,
"members": true,
"lowalch": 520,
"limit": 70,
"value": 1300,
"highalch": 780,
"icon": "Hunters' sunlight crossbow.png",
"name": "Hunters' sunlight crossbow"
},
{
"examine": "One dose of fishy Hunter potion.",
"id": 11519,
"members": true,
"lowalch": 2,
"limit": 2000,
"value": 6,
"highalch": 3,
"icon": "Hunting mix(1).png",
"name": "Hunting mix(1)"
},
{
"examine": "Two doses of fishy Hunter potion.",
"id": 11517,
"members": true,
"lowalch": 3,
"limit": 2000,
"value": 9,
"highalch": 5,
"icon": "Hunting mix(2).png",
"name": "Hunting mix(2)"
},
{
"examine": "The bones of a hydra.",
"id": 22786,
"members": true,
"lowalch": 64,
"limit": 7500,
"value": 160,
"highalch": 96,
"icon": "Hydra bones.png",
"name": "Hydra bones"
},
{
"examine": "Hide from a ferocious Hydra. It looks pretty tough.",
"id": 22983,
"members": true,
"lowalch": 40000,
"limit": 15,
"value": 100000,
"highalch": 60000,
"icon": "Hydra leather.png",
"name": "Hydra leather"
},
{
"examine": "Tail of a hydra, can be attached to a bonecrusher.",
"id": 22988,
"members": true,
"lowalch": 40000,
"limit": 15,
"value": 100000,
"highalch": 60000,
"icon": "Hydra tail.png",
"name": "Hydra tail"
},
{
"examine": "The claw of a ferocious hydra.",
"id": 22966,
"members": true,
"lowalch": 60000,
"limit": 15,
"value": 150000,
"highalch": 90000,
"icon": "Hydra's claw.png",
"name": "Hydra's claw"
},
{
"examine": "A teleport to the Ice Plateau in level 53 Wilderness.",
"id": 24963,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Ice plateau teleport (tablet).png",
"name": "Ice plateau teleport (tablet)"
},
{
"examine": "The crushed rock seems to glow and is cold to the touch.",
"id": 22599,
"members": true,
"lowalch": 36,
"limit": 10000,
"value": 90,
"highalch": 54,
"icon": "Icy basalt.png",
"name": "Icy basalt"
},
{
"examine": "This heart holds a magical power within.",
"id": 20724,
"members": true,
"lowalch": 200000,
"limit": 8,
"value": 500000,
"highalch": 300000,
"icon": "Imbued heart.png",
"name": "Imbued heart"
},
{
"highalch": 120,
"members": true,
"name": "Immature tecu salamander",
"examine": "A little too small to stuff full of tar.",
"id": 28831,
"value": 200,
"icon": "Immature tecu salamander.png",
"lowalch": 80
},
{
"examine": "What mischief can I get up to with this?",
"id": 12249,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Imp mask.png",
"name": "Imp mask"
},
{
"examine": "It's got little holes in the top.",
"id": 11260,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Impling jar.png",
"name": "Impling jar"
},
{
"examine": "The limbs have been attached to the frame.",
"id": 19598,
"members": true,
"lowalch": 20000,
"limit": 8,
"value": 50000,
"highalch": 30000,
"icon": "Incomplete heavy ballista.png",
"name": "Incomplete heavy ballista"
},
{
"examine": "The limbs have been attached to the frame.",
"id": 19595,
"members": true,
"lowalch": 20000,
"limit": 8,
"value": 50000,
"highalch": 30000,
"icon": "Incomplete light ballista.png",
"name": "Incomplete light ballista"
},
{
"examine": "A heap of ashes.",
"id": 25778,
"members": true,
"lowalch": 1,
"limit": 7500,
"value": 1,
"highalch": 1,
"icon": "Infernal ashes.png",
"name": "Infernal ashes"
},
{
"examine": "Mystical boots.",
"id": 6920,
"members": true,
"lowalch": 4800,
"limit": 125,
"value": 12000,
"highalch": 7200,
"icon": "Infinity boots.png",
"name": "Infinity boots"
},
{
"examine": "Mystical robes.",
"id": 6924,
"members": true,
"lowalch": 36000,
"limit": 125,
"value": 90000,
"highalch": 54000,
"icon": "Infinity bottoms.png",
"name": "Infinity bottoms"
},
{
"examine": "Mystical gloves.",
"id": 6922,
"members": true,
"lowalch": 4800,
"limit": 125,
"value": 12000,
"highalch": 7200,
"icon": "Infinity gloves.png",
"name": "Infinity gloves"
},
{
"examine": "A mystic hat.",
"id": 6918,
"members": true,
"lowalch": 6800,
"limit": 125,
"value": 17000,
"highalch": 10200,
"icon": "Infinity hat.png",
"name": "Infinity hat"
},
{
"examine": "Mystical robes.",
"id": 6916,
"members": true,
"lowalch": 56000,
"limit": 125,
"value": 140000,
"highalch": 84000,
"icon": "Infinity top.png",
"name": "Infinity top"
},
{
"examine": "An initiate Temple Knight's leg armour.",
"id": 5576,
"members": true,
"lowalch": 3200,
"limit": 125,
"value": 8000,
"highalch": 4800,
"icon": "Initiate cuisse.png",
"name": "Initiate cuisse"
},
{
"examine": "Initiate level armour pack.",
"id": 9668,
"members": true,
"lowalch": 8000,
"limit": 70,
"value": 20000,
"highalch": 12000,
"icon": "Initiate harness m.png",
"name": "Initiate harness m"
},
{
"examine": "An initiate Temple Knight's Armour.",
"id": 5575,
"members": true,
"lowalch": 4000,
"limit": 125,
"value": 10000,
"highalch": 6000,
"icon": "Initiate hauberk.png",
"name": "Initiate hauberk"
},
{
"examine": "An initiate Temple Knight's helm.",
"id": 5574,
"members": true,
"lowalch": 2400,
"limit": 125,
"value": 6000,
"highalch": 3600,
"icon": "Initiate sallet.png",
"name": "Initiate sallet"
},
{
"examine": "It eases diseases!",
"id": 11088,
"members": true,
"lowalch": 1024,
"limit": 10000,
"value": 2560,
"highalch": 1536,
"icon": "Inoculation bracelet.png",
"name": "Inoculation bracelet"
},
{
"highalch": 1,
"members": true,
"name": "Inquisitor's armour set",
"examine": "A set containing an Inquisitor's great helm, Inquisitor's hauberk and Inquisitor's plateskirt.",
"id": 24488,
"value": 1,
"icon": "Inquisitor's armour set.png",
"lowalch": 1
},
{
"examine": "The great helmet of the turncloak Justiciar.",
"id": 24419,
"members": true,
"lowalch": 200000,
"limit": 8,
"value": 500000,
"highalch": 300000,
"icon": "Inquisitor's great helm.png",
"name": "Inquisitor's great helm"
},
{
"examine": "The hauberk of the turncloak Justiciar.",
"id": 24420,
"members": true,
"lowalch": 400000,
"limit": 8,
"value": 1000000,
"highalch": 600000,
"icon": "Inquisitor's hauberk.png",
"name": "Inquisitor's hauberk"
},
{
"examine": "A powerful mace once wielded by the turncloak Justiciar.",
"id": 24417,
"members": true,
"lowalch": 2000000,
"limit": 8,
"value": 5000000,
"highalch": 3000000,
"icon": "Inquisitor's mace.png",
"name": "Inquisitor's mace"
},
{
"examine": "The plateskirt of the turncloak Justiciar.",
"id": 24421,
"members": true,
"lowalch": 300000,
"limit": 8,
"value": 750000,
"highalch": 450000,
"icon": "Inquisitor's plateskirt.png",
"name": "Inquisitor's plateskirt"
},
{
"examine": "Drives away all known 6 legged creatures.",
"id": 28,
"members": true,
"lowalch": 1,
"limit": 15,
"value": 3,
"highalch": 1,
"icon": "Insect repellent.png",
"name": "Insect repellent"
},
{
"examine": "They're heavily insulated wellies.",
"id": 7159,
"members": true,
"lowalch": 80,
"limit": 70,
"value": 200,
"highalch": 120,
"icon": "Insulated boots.png",
"name": "Insulated boots"
},
{
"examine": "Teleports you to the Iorwerth Camp.",
"id": 12410,
"members": true,
"lowalch": 4,
"limit": 10000,
"value": 10,
"highalch": 6,
"icon": "Iorwerth camp teleport.png",
"name": "Iorwerth camp teleport"
},
{
"examine": "A useful herb.",
"id": 259,
"members": true,
"lowalch": 16,
"limit": 13000,
"value": 40,
"highalch": 24,
"icon": "Irit leaf.png",
"name": "Irit leaf"
},
{
"examine": "I need another ingredient to finish this Irit potion.",
"id": 101,
"members": true,
"lowalch": 16,
"limit": 10000,
"value": 40,
"highalch": 24,
"icon": "Irit potion (unf).png",
"name": "Irit potion (unf)"
},
{
"examine": "An irit seed - plant in a herb patch.",
"id": 5297,
"members": true,
"lowalch": 2,
"limit": 600,
"value": 5,
"highalch": 3,
"icon": "Irit seed 5.png",
"name": "Irit seed"
},
{
"examine": "A dark, thick, foul-smelling, tar-like substance.",
"id": 28837,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 1,
"highalch": 1,
"icon": "Irit tar.png",
"name": "Irit tar"
},
{
"examine": "A two handed sword.",
"id": 1309,
"members": false,
"lowalch": 112,
"limit": 125,
"value": 280,
"highalch": 168,
"icon": "Iron 2h sword.png",
"name": "Iron 2h sword"
},
{
"examine": "Arrows with iron heads.",
"id": 884,
"members": false,
"lowalch": 1,
"limit": 7000,
"value": 3,
"highalch": 1,
"icon": "Iron arrow 5.png",
"name": "Iron arrow"
},
{
"examine": "Venomous-looking arrows.",
"id": 885,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 3,
"highalch": 1,
"icon": "Iron arrow(p) 5.png",
"name": "Iron arrow(p)"
},
{
"examine": "Venomous-looking arrows.",
"id": 5617,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 3,
"highalch": 1,
"icon": "Iron arrow(p+) 5.png",
"name": "Iron arrow(p+)"
},
{
"examine": "Venomous-looking arrows.",
"id": 5623,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 3,
"highalch": 1,
"icon": "Iron arrow(p++) 5.png",
"name": "Iron arrow(p++)"
},
{
"examine": "I can make some arrows with these.",
"id": 40,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 2,
"highalch": 1,
"icon": "Iron arrowtips.png",
"name": "Iron arrowtips"
},
{
"examine": "A woodcutter's axe.",
"id": 1349,
"members": false,
"lowalch": 22,
"limit": 40,
"value": 56,
"highalch": 33,
"icon": "Iron axe.png",
"name": "Iron axe"
},
{
"examine": "It's a bar of iron.",
"id": 2351,
"members": false,
"lowalch": 11,
"limit": 10000,
"value": 28,
"highalch": 16,
"icon": "Iron bar.png",
"name": "Iron bar"
},
{
"examine": "A vicious looking axe.",
"id": 1363,
"members": false,
"lowalch": 72,
"limit": 125,
"value": 182,
"highalch": 109,
"icon": "Iron battleaxe.png",
"name": "Iron battleaxe"
},
{
"examine": "Iron crossbow bolts.",
"id": 9140,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 2,
"highalch": 1,
"icon": "Iron bolts 5.png",
"name": "Iron bolts"
},
{
"examine": "Some poisoned iron bolts.",
"id": 9287,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 2,
"highalch": 1,
"icon": "Iron bolts (p) 5.png",
"name": "Iron bolts (p)"
},
{
"examine": "Some poisoned iron bolts.",
"id": 9294,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 2,
"highalch": 1,
"icon": "Iron bolts (p+) 5.png",
"name": "Iron bolts (p+)"
},
{
"examine": "Super poisoned iron bolts.",
"id": 9301,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 2,
"highalch": 1,
"icon": "Iron bolts (p++) 5.png",
"name": "Iron bolts (p++)"
},
{
"examine": "Unfeathered iron crossbow bolts.",
"id": 9377,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Iron bolts (unf) 5.png",
"name": "Iron bolts (unf)"
},
{
"examine": "These will protect my feet.",
"id": 4121,
"members": true,
"lowalch": 33,
"limit": 125,
"value": 84,
"highalch": 50,
"icon": "Iron boots.png",
"name": "Iron boots"
},
{
"examine": "Blunt iron arrow... ouch.",
"id": 4778,
"members": true,
"lowalch": 4,
"limit": 11000,
"value": 10,
"highalch": 6,
"icon": "Iron brutal 5.png",
"name": "Iron brutal"
},
{
"examine": "A series of connected metal rings.",
"id": 1101,
"members": false,
"lowalch": 84,
"limit": 125,
"value": 210,
"highalch": 126,
"icon": "Iron chainbody.png",
"name": "Iron chainbody"
},
{
"examine": "A set of fighting claws.",
"id": 3096,
"members": true,
"lowalch": 20,
"limit": 125,
"value": 50,
"highalch": 30,
"icon": "Iron claws.png",
"name": "Iron claws"
},
{
"examine": "An iron crossbow.",
"id": 9177,
"members": true,
"lowalch": 62,
"limit": 125,
"value": 157,
"highalch": 94,
"icon": "Iron crossbow.png",
"name": "Iron crossbow"
},
{
"examine": "An unstrung iron crossbow.",
"id": 9457,
"members": true,
"lowalch": 49,
"limit": 10000,
"value": 123,
"highalch": 73,
"icon": "Iron crossbow (u).png",
"name": "Iron crossbow (u)"
},
{
"examine": "Short but pointy.",
"id": 1203,
"members": false,
"lowalch": 14,
"limit": 125,
"value": 35,
"highalch": 21,
"icon": "Iron dagger.png",
"name": "Iron dagger"
},
{
"examine": "The blade is covered with poison.",
"id": 1219,
"members": true,
"lowalch": 14,
"limit": 125,
"value": 35,
"highalch": 21,
"icon": "Iron dagger(p).png",
"name": "Iron dagger(p)"
},
{
"examine": "The blade is covered with poison.",
"id": 5668,
"members": true,
"lowalch": 14,
"limit": 125,
"value": 35,
"highalch": 21,
"icon": "Iron dagger(p+).png",
"name": "Iron dagger(p+)"
},
{
"examine": "Short but pointy.",
"id": 5686,
"members": true,
"lowalch": 14,
"limit": 125,
"value": 35,
"highalch": 21,
"icon": "Iron dagger(p++).png",
"name": "Iron dagger(p++)"
},
{
"examine": "A deadly throwing dart with an iron tip.",
"id": 807,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 2,
"highalch": 1,
"icon": "Iron dart.png",
"name": "Iron dart"
},
{
"examine": "A deadly poisoned dart with an iron tip.",
"id": 813,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 2,
"highalch": 1,
"icon": "Iron dart (p).png",
"name": "Iron dart (p)"
},
{
"examine": "A deadly looking dart tip made of iron - needs feathers for flight.",
"id": 820,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 3,
"highalch": 1,
"icon": "Iron dart tip.png",
"name": "Iron dart tip"
},
{
"examine": "A deadly poisoned dart with an iron tip.",
"id": 5629,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 2,
"highalch": 1,
"icon": "Iron dart(p+).png",
"name": "Iron dart(p+)"
},
{
"examine": "A deadly poisoned dart with an iron tip.",
"id": 5636,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 2,
"highalch": 1,
"icon": "Iron dart(p++).png",
"name": "Iron dart(p++)"
},
{
"examine": "Do I look scary?",
"id": 12365,
"members": true,
"lowalch": 4000,
"limit": 4,
"value": 10000,
"highalch": 6000,
"icon": "Iron dragon mask.png",
"name": "Iron dragon mask"
},
{
"highalch": 33,
"members": true,
"name": "Iron felling axe",
"examine": "A woodcutter's axe.",
"id": 28199,
"value": 56,
"icon": "Iron felling axe.png",
"lowalch": 22
},
{
"examine": "A full face helmet.",
"id": 1153,
"members": false,
"lowalch": 61,
"limit": 125,
"value": 154,
"highalch": 92,
"icon": "Iron full helm.png",
"name": "Iron full helm"
},
{
"examine": "Iron full helm with gold trim.",
"id": 12241,
"members": false,
"lowalch": 64,
"limit": 4,
"value": 160,
"highalch": 96,
"icon": "Iron full helm (g).png",
"name": "Iron full helm (g)"
},
{
"examine": "Iron full helm with trim.",
"id": 12231,
"members": false,
"lowalch": 64,
"limit": 4,
"value": 160,
"highalch": 96,
"icon": "Iron full helm (t).png",
"name": "Iron full helm (t)"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 12980,
"members": false,
"lowalch": 360,
"limit": 8,
"value": 900,
"highalch": 540,
"icon": "Iron gold-trimmed set (lg).png",
"name": "Iron gold-trimmed set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 12982,
"members": false,
"lowalch": 360,
"limit": 8,
"value": 900,
"highalch": 540,
"icon": "Iron gold-trimmed set (sk).png",
"name": "Iron gold-trimmed set (sk)"
},
{
"examine": "An iron halberd.",
"id": 3192,
"members": true,
"lowalch": 112,
"limit": 125,
"value": 280,
"highalch": 168,
"icon": "Iron halberd.png",
"name": "Iron halberd"
},
{
"examine": "An iron-tipped, one-handed hasta.",
"id": 11369,
"members": true,
"lowalch": 36,
"limit": 125,
"value": 91,
"highalch": 54,
"icon": "Iron hasta.png",
"name": "Iron hasta"
},
{
"examine": "A poison-tipped, one-handed iron hasta.",
"id": 11386,
"members": true,
"lowalch": 36,
"limit": 125,
"value": 91,
"highalch": 54,
"icon": "Iron hasta(p).png",
"name": "Iron hasta(p)"
},
{
"examine": "A poison-tipped, one-handed iron hasta.",
"id": 11389,
"members": true,
"lowalch": 36,
"limit": 125,
"value": 91,
"highalch": 54,
"icon": "Iron hasta(p+).png",
"name": "Iron hasta(p+)"
},
{
"examine": "A poison-tipped, one-handed iron hasta.",
"id": 11391,
"members": true,
"lowalch": 36,
"limit": 125,
"value": 91,
"highalch": 54,
"icon": "Iron hasta(p++).png",
"name": "Iron hasta(p++)"
},
{
"examine": "An iron tipped javelin.",
"id": 826,
"members": true,
"lowalch": 2,
"limit": 7000,
"value": 6,
"highalch": 3,
"icon": "Iron javelin.png",
"name": "Iron javelin"
},
{
"examine": "Needs a shaft.",
"id": 19572,
"members": true,
"lowalch": 14,
"limit": 10000,
"value": 35,
"highalch": 21,
"icon": "Iron javelin heads 5.png",
"name": "Iron javelin heads"
},
{
"examine": "An iron tipped javelin.",
"id": 832,
"members": true,
"lowalch": 2,
"limit": 7000,
"value": 6,
"highalch": 3,
"icon": "Iron javelin(p).png",
"name": "Iron javelin(p)"
},
{
"examine": "An iron tipped javelin.",
"id": 5643,
"members": true,
"lowalch": 2,
"limit": 7000,
"value": 6,
"highalch": 3,
"icon": "Iron javelin(p+).png",
"name": "Iron javelin(p+)"
},
{
"examine": "An iron tipped javelin.",
"id": 5649,
"members": true,
"lowalch": 2,
"limit": 7000,
"value": 6,
"highalch": 3,
"icon": "Iron javelin(p++).png",
"name": "Iron javelin(p++)"
},
{
"examine": "A large metal shield.",
"id": 1191,
"members": false,
"lowalch": 95,
"limit": 125,
"value": 238,
"highalch": 142,
"icon": "Iron kiteshield.png",
"name": "Iron kiteshield"
},
{
"examine": "Iron kiteshield with gold trim.",
"id": 12243,
"members": false,
"lowalch": 96,
"limit": 4,
"value": 240,
"highalch": 144,
"icon": "Iron kiteshield (g).png",
"name": "Iron kiteshield (g)"
},
{
"examine": "Iron kiteshield with trim.",
"id": 12233,
"members": false,
"lowalch": 96,
"limit": 4,
"value": 240,
"highalch": 144,
"icon": "Iron kiteshield (t).png",
"name": "Iron kiteshield (t)"
},
{
"examine": "A finely balanced throwing knife.",
"id": 863,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 3,
"highalch": 1,
"icon": "Iron knife.png",
"name": "Iron knife"
},
{
"examine": "A finely balanced throwing knife.",
"id": 871,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 3,
"highalch": 1,
"icon": "Iron knife(p).png",
"name": "Iron knife(p)"
},
{
"examine": "A finely balanced throwing knife.",
"id": 5655,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 3,
"highalch": 1,
"icon": "Iron knife(p+).png",
"name": "Iron knife(p+)"
},
{
"examine": "A finely balanced throwing knife.",
"id": 5662,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 3,
"highalch": 1,
"icon": "Iron knife(p++).png",
"name": "Iron knife(p++)"
},
{
"examine": "A pair of iron crossbow limbs.",
"id": 9423,
"members": true,
"lowalch": 28,
"limit": 10000,
"value": 70,
"highalch": 42,
"icon": "Iron limbs.png",
"name": "Iron limbs"
},
{
"examine": "A razor sharp longsword.",
"id": 1293,
"members": false,
"lowalch": 56,
"limit": 125,
"value": 140,
"highalch": 84,
"icon": "Iron longsword.png",
"name": "Iron longsword"
},
{
"examine": "A spiky mace.",
"id": 1420,
"members": false,
"lowalch": 25,
"limit": 125,
"value": 63,
"highalch": 37,
"icon": "Iron mace.png",
"name": "Iron mace"
},
{
"examine": "A medium sized helmet.",
"id": 1137,
"members": false,
"lowalch": 33,
"limit": 125,
"value": 84,
"highalch": 50,
"icon": "Iron med helm.png",
"name": "Iron med helm"
},
{
"examine": "Keeps things in place fairly permanently.",
"id": 4820,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 4,
"highalch": 2,
"icon": "Iron nails.png",
"name": "Iron nails"
},
{
"examine": "This needs refining.",
"id": 440,
"members": false,
"lowalch": 6,
"limit": 13000,
"value": 17,
"highalch": 10,
"icon": "Iron ore.png",
"name": "Iron ore"
},
{
"examine": "Used for mining.",
"id": 1267,
"members": false,
"lowalch": 56,
"limit": 40,
"value": 140,
"highalch": 84,
"icon": "Iron pickaxe.png",
"name": "Iron pickaxe"
},
{
"examine": "Provides excellent protection.",
"id": 1115,
"members": false,
"lowalch": 224,
"limit": 125,
"value": 560,
"highalch": 336,
"icon": "Iron platebody.png",
"name": "Iron platebody"
},
{
"examine": "Iron platebody with gold trim.",
"id": 12235,
"members": false,
"lowalch": 224,
"limit": 4,
"value": 560,
"highalch": 336,
"icon": "Iron platebody (g).png",
"name": "Iron platebody (g)"
},
{
"examine": "Iron platebody with trim.",
"id": 12225,
"members": false,
"lowalch": 224,
"limit": 4,
"value": 560,
"highalch": 336,
"icon": "Iron platebody (t).png",
"name": "Iron platebody (t)"
},
{
"examine": "These look pretty heavy.",
"id": 1067,
"members": false,
"lowalch": 112,
"limit": 125,
"value": 280,
"highalch": 168,
"icon": "Iron platelegs.png",
"name": "Iron platelegs"
},
{
"examine": "Iron platelegs with gold trim.",
"id": 12237,
"members": false,
"lowalch": 112,
"limit": 4,
"value": 280,
"highalch": 168,
"icon": "Iron platelegs (g).png",
"name": "Iron platelegs (g)"
},
{
"examine": "Iron platelegs with trim.",
"id": 12227,
"members": false,
"lowalch": 112,
"limit": 4,
"value": 280,
"highalch": 168,
"icon": "Iron platelegs (t).png",
"name": "Iron platelegs (t)"
},
{
"examine": "Designer leg protection.",
"id": 1081,
"members": false,
"lowalch": 112,
"limit": 125,
"value": 280,
"highalch": 168,
"icon": "Iron plateskirt.png",
"name": "Iron plateskirt"
},
{
"examine": "Iron plateskirt with gold trim.",
"id": 12239,
"members": false,
"lowalch": 112,
"limit": 4,
"value": 280,
"highalch": 168,
"icon": "Iron plateskirt (g).png",
"name": "Iron plateskirt (g)"
},
{
"examine": "Iron plateskirt with trim.",
"id": 12229,
"members": false,
"lowalch": 112,
"limit": 4,
"value": 280,
"highalch": 168,
"icon": "Iron plateskirt (t).png",
"name": "Iron plateskirt (t)"
},
{
"examine": "A vicious, curved sword.",
"id": 1323,
"members": false,
"lowalch": 44,
"limit": 125,
"value": 112,
"highalch": 67,
"icon": "Iron scimitar.png",
"name": "Iron scimitar"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 12972,
"members": false,
"lowalch": 360,
"limit": 8,
"value": 900,
"highalch": 540,
"icon": "Iron set (lg).png",
"name": "Iron set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 12974,
"members": false,
"lowalch": 360,
"limit": 8,
"value": 900,
"highalch": 540,
"icon": "Iron set (sk).png",
"name": "Iron set (sk)"
},
{
"examine": "An iron tipped spear.",
"id": 1239,
"members": true,
"lowalch": 36,
"limit": 125,
"value": 91,
"highalch": 54,
"icon": "Iron spear.png",
"name": "Iron spear"
},
{
"examine": "A poisoned iron tipped spear.",
"id": 1253,
"members": true,
"lowalch": 36,
"limit": 125,
"value": 91,
"highalch": 54,
"icon": "Iron spear(p).png",
"name": "Iron spear(p)"
},
{
"examine": "A poisoned iron tipped spear.",
"id": 5706,
"members": true,
"lowalch": 36,
"limit": 125,
"value": 91,
"highalch": 54,
"icon": "Iron spear(p+).png",
"name": "Iron spear(p+)"
},
{
"examine": "A poisoned iron tipped spear.",
"id": 5720,
"members": true,
"lowalch": 36,
"limit": 125,
"value": 91,
"highalch": 54,
"icon": "Iron spear(p++).png",
"name": "Iron spear(p++)"
},
{
"examine": "An iron spit.",
"id": 7225,
"members": true,
"lowalch": 25,
"limit": 11000,
"value": 64,
"highalch": 38,
"icon": "Iron spit.png",
"name": "Iron spit"
},
{
"examine": "A medium square shield.",
"id": 1175,
"members": false,
"lowalch": 67,
"limit": 125,
"value": 168,
"highalch": 100,
"icon": "Iron sq shield.png",
"name": "Iron sq shield"
},
{
"examine": "A razor sharp sword.",
"id": 1279,
"members": false,
"lowalch": 36,
"limit": 125,
"value": 91,
"highalch": 54,
"icon": "Iron sword.png",
"name": "Iron sword"
},
{
"examine": "A finely balanced throwing axe.",
"id": 801,
"members": true,
"lowalch": 2,
"limit": 7000,
"value": 7,
"highalch": 4,
"icon": "Iron thrownaxe.png",
"name": "Iron thrownaxe"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 12976,
"members": false,
"lowalch": 360,
"limit": 8,
"value": 900,
"highalch": 540,
"icon": "Iron trimmed set (lg).png",
"name": "Iron trimmed set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 12978,
"members": false,
"lowalch": 360,
"limit": 8,
"value": 900,
"highalch": 540,
"icon": "Iron trimmed set (sk).png",
"name": "Iron trimmed set (sk)"
},
{
"examine": "I don't think it's intended for joinery.",
"id": 1335,
"members": false,
"lowalch": 69,
"limit": 125,
"value": 173,
"highalch": 103,
"icon": "Iron warhammer.png",
"name": "Iron warhammer"
},
{
"examine": "Gets knots and kinks out of your hair.",
"id": 9026,
"members": true,
"lowalch": 4,
"limit": 13000,
"value": 10,
"highalch": 6,
"icon": "Ivory comb.png",
"name": "Ivory comb"
},
{
"examine": "A semi precious stone.",
"id": 1611,
"members": true,
"lowalch": 60,
"limit": 13000,
"value": 150,
"highalch": 90,
"icon": "Jade.png",
"name": "Jade"
},
{
"examine": "I wonder if I can get this enchanted.",
"id": 21111,
"members": true,
"lowalch": 420,
"limit": 10000,
"value": 1050,
"highalch": 630,
"icon": "Jade amulet.png",
"name": "Jade amulet"
},
{
"examine": "It needs a string so I can wear it.",
"id": 21102,
"members": true,
"lowalch": 420,
"limit": 10000,
"value": 1050,
"highalch": 630,
"icon": "Jade amulet (u).png",
"name": "Jade amulet (u)"
},
{
"examine": "Jade bolt tips.",
"id": 9187,
"members": true,
"lowalch": 4,
"limit": 11000,
"value": 10,
"highalch": 6,
"icon": "Jade bolt tips 5.png",
"name": "Jade bolt tips"
},
{
"examine": "I wonder if this is valuable.",
"id": 21120,
"members": true,
"lowalch": 520,
"limit": 10000,
"value": 1300,
"highalch": 780,
"icon": "Jade bracelet.png",
"name": "Jade bracelet"
},
{
"examine": "Dragon crossbow bolts, tipped with jade.",
"id": 21957,
"members": true,
"lowalch": 240,
"limit": 11000,
"value": 600,
"highalch": 360,
"icon": "Jade dragon bolts 5.png",
"name": "Jade dragon bolts"
},
{
"examine": "Enchanted Dragon crossbow bolts, tipped with jade.",
"id": 21934,
"members": true,
"lowalch": 252,
"limit": 11000,
"value": 630,
"highalch": 378,
"icon": "Jade dragon bolts (e) 5.png",
"name": "Jade dragon bolts (e)"
},
{
"examine": "A jungle specific slashing device.",
"id": 6315,
"members": true,
"lowalch": 400,
"limit": 15,
"value": 1000,
"highalch": 600,
"icon": "Jade machete.png",
"name": "Jade machete"
},
{
"examine": "I wonder if this is valuable.",
"id": 21093,
"members": true,
"lowalch": 510,
"limit": 10000,
"value": 1275,
"highalch": 765,
"icon": "Jade necklace.png",
"name": "Jade necklace"
},
{
"examine": "A valuable ring.",
"id": 21084,
"members": true,
"lowalch": 510,
"limit": 10000,
"value": 1275,
"highalch": 765,
"icon": "Jade ring.png",
"name": "Jade ring"
},
{
"highalch": 48,
"members": true,
"name": "Jaguar fur",
"examine": "This would make nice clothing.",
"id": 29218,
"value": 80,
"icon": "Jaguar fur.png",
"lowalch": 32
},
{
"examine": "They don't look very ripe.",
"id": 247,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Jangerberries.png",
"name": "Jangerberries"
},
{
"examine": "A jangerberry bush seed - plant in a bush patch.",
"id": 5104,
"members": true,
"lowalch": 24,
"limit": 200,
"value": 60,
"highalch": 36,
"icon": "Jangerberry seed 5.png",
"name": "Jangerberry seed"
},
{
"examine": "Looks unstable.",
"id": 23064,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Jar of chemicals.png",
"name": "Jar of chemicals"
},
{
"examine": "It smells like it's been where the sun doesn't shine.",
"id": 19701,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Jar of darkness.png",
"name": "Jar of darkness"
},
{
"examine": "Very healthy.",
"id": 22106,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Jar of decay.png",
"name": "Jar of decay"
},
{
"examine": "It's just a jar of dirt.",
"id": 12007,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Jar of dirt.png",
"name": "Jar of dirt"
},
{
"examine": "With this jar, your dreams can come true!",
"id": 24495,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Jar of dreams.png",
"name": "Jar of dreams"
},
{
"examine": "Stop staring!",
"id": 23525,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Jar of eyes.png",
"name": "Jar of eyes"
},
{
"examine": "It smells abysmal.",
"id": 13277,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Jar of miasma.png",
"name": "Jar of miasma"
},
{
"examine": "It's just a jar of sand.",
"id": 12885,
"members": true,
"lowalch": 1,
"limit": 5,
"value": 1,
"highalch": 1,
"icon": "Jar of sand.png",
"name": "Jar of sand"
},
{
"examine": "Don't breath in.",
"id": 25524,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Jar of smoke.png",
"name": "Jar of smoke"
},
{
"examine": "This may be of use for a certain person.",
"id": 13245,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Jar of souls.png",
"name": "Jar of souls"
},
{
"examine": "How does someone jar this?",
"id": 25521,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Jar of spirits.png",
"name": "Jar of spirits"
},
{
"examine": "Stones aren't very useful in this capacity.",
"id": 21745,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Jar of stone.png",
"name": "Jar of stone"
},
{
"examine": "This is my Swamp!",
"id": 12936,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Jar of swamp.png",
"name": "Jar of swamp"
},
{
"examine": "It's not very strong, but it could be pointy if you put a pointy tip on it.",
"id": 19584,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 1,
"highalch": 1,
"icon": "Javelin shaft 5.png",
"name": "Javelin shaft"
},
{
"highalch": 1,
"members": true,
"name": "Jerboa tail",
"examine": "The long tail of an embertailed jerboa.",
"id": 29166,
"value": 1,
"icon": "Jerboa tail.png",
"lowalch": 1
},
{
"examine": "A jester cape.",
"id": 23297,
"members": false,
"lowalch": 400,
"limit": 4,
"value": 1000,
"highalch": 600,
"icon": "Jester cape.png",
"name": "Jester cape"
},
{
"examine": "Fairly big bones which smell distinctly of Jogre.",
"id": 3125,
"members": true,
"lowalch": 1,
"limit": 3000,
"value": 1,
"highalch": 1,
"icon": "Jogre bones.png",
"name": "Jogre bones"
},
{
"examine": "This jug is empty.",
"id": 1935,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Jug.png",
"name": "Jug"
},
{
"examine": "It's full of water.",
"id": 1937,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Jug of water.png",
"name": "Jug of water"
},
{
"examine": "It's full of wine.",
"id": 1993,
"members": false,
"lowalch": 1,
"limit": 6000,
"value": 1,
"highalch": 1,
"icon": "Jug of wine.png",
"name": "Jug of wine"
},
{
"highalch": 12,
"members": true,
"name": "Jungle camo legs",
"examine": "These should make me harder to spot in jungle areas.",
"id": 10059,
"value": 20,
"icon": "Jungle camo legs.png",
"lowalch": 8
},
{
"highalch": 12,
"members": true,
"name": "Jungle camo legs (equipped)",
"examine": "These should make me harder to spot in jungle areas.",
"id": 28848,
"value": 20,
"icon": "Jungle camo legs.png",
"lowalch": 8
},
{
"highalch": 12,
"members": true,
"name": "Jungle camo top",
"examine": "This should make me harder to spot in jungle areas.",
"id": 10057,
"value": 20,
"icon": "Jungle camo top.png",
"lowalch": 8
},
{
"highalch": 12,
"members": true,
"name": "Jungle camo top (equipped)",
"examine": "This should make me harder to spot in jungle areas.",
"id": 28845,
"value": 20,
"icon": "Jungle camo top.png",
"lowalch": 8
},
{
"examine": "A mask resembling a visage of a Greater Jungle demon.",
"id": 20032,
"members": true,
"lowalch": 1600,
"limit": 4,
"value": 4000,
"highalch": 2400,
"icon": "Jungle demon mask.png",
"name": "Jungle demon mask"
},
{
"examine": "A set containing a Justiciar faceguard, Justiciar chestguard and Justiciar legguards.",
"id": 22438,
"members": true,
"lowalch": 58000,
"limit": 5,
"value": 145000,
"highalch": 87000,
"icon": "Justiciar armour set.png",
"name": "Justiciar armour set"
},
{
"examine": "A Justiciar's chestguard. They once roamed the land casting judgements on wrongdoers, their decisions were never questioned.",
"id": 22327,
"members": true,
"lowalch": 2400000,
"limit": 8,
"value": 6000000,
"highalch": 3600000,
"icon": "Justiciar chestguard.png",
"name": "Justiciar chestguard"
},
{
"examine": "A helm that once belonged to a Justiciar, a loyal zealot of Saradomin.",
"id": 22326,
"members": true,
"lowalch": 800000,
"limit": 8,
"value": 2000000,
"highalch": 1200000,
"icon": "Justiciar faceguard.png",
"name": "Justiciar faceguard"
},
{
"examine": "A Justiciar's platelegs. Their once bright colours have since faded.",
"id": 22328,
"members": true,
"lowalch": 1800000,
"limit": 8,
"value": 4500000,
"highalch": 2700000,
"icon": "Justiciar legguards.png",
"name": "Justiciar legguards"
},
{
"examine": "I can weave this to make sacks.",
"id": 5931,
"members": true,
"lowalch": 2,
"limit": 13000,
"value": 6,
"highalch": 3,
"icon": "Jute fibre.png",
"name": "Jute fibre"
},
{
"examine": "A jute plant seed - plant in a hops patch.",
"id": 5306,
"members": true,
"lowalch": 2,
"limit": 2000,
"value": 5,
"highalch": 3,
"icon": "Jute seed 5.png",
"name": "Jute seed"
},
{
"examine": "A wide bodied and thin necked vessel, encrusted with sea salt.",
"id": 3157,
"members": true,
"lowalch": 2,
"limit": 15,
"value": 5,
"highalch": 3,
"icon": "Karambwan vessel.png",
"name": "Karambwan vessel"
},
{
"examine": "This Karambwan Vessel is loaded with Karambwanji.",
"id": 3159,
"members": true,
"lowalch": 16,
"limit": 15,
"value": 40,
"highalch": 24,
"icon": "Karambwan vessel (baited).png",
"name": "Karambwan vessel (baited)"
},
{
"examine": "Makes my face itch.",
"id": 4595,
"members": true,
"lowalch": 1,
"limit": 15,
"value": 1,
"highalch": 1,
"icon": "Karidian disguise.png",
"name": "Karidian disguise"
},
{
"examine": "A set containing a Karil's coif, leatherskirt, leathertop and crossbow.",
"id": 12883,
"members": true,
"lowalch": 80000,
"limit": 8,
"value": 200000,
"highalch": 120000,
"icon": "Karil's armour set.png",
"name": "Karil's armour set"
},
{
"examine": "Karil the Tainted's coif.",
"id": 4732,
"members": true,
"lowalch": 5200,
"limit": 15,
"value": 13000,
"highalch": 7800,
"icon": "Karil's coif.png",
"name": "Karil's coif"
},
{
"examine": "Karil the Tainted's coif.",
"id": 4932,
"members": true,
"lowalch": 5200,
"limit": 15,
"value": 13000,
"highalch": 7800,
"icon": "Karil's coif 0.png",
"name": "Karil's coif 0"
},
{
"examine": "Karil the Tainted's repeating crossbow.",
"id": 4734,
"members": true,
"lowalch": 64000,
"limit": 15,
"value": 160000,
"highalch": 96000,
"icon": "Karil's crossbow.png",
"name": "Karil's crossbow"
},
{
"examine": "Karil the Tainted's repeating crossbow.",
"id": 4938,
"members": true,
"lowalch": 64000,
"limit": 15,
"value": 160000,
"highalch": 96000,
"icon": "Karil's crossbow 0.png",
"name": "Karil's crossbow 0"
},
{
"examine": "Karil the Tainted's leather skirt.",
"id": 4738,
"members": true,
"lowalch": 18800,
"limit": 15,
"value": 47000,
"highalch": 28200,
"icon": "Karil's leatherskirt.png",
"name": "Karil's leatherskirt"
},
{
"examine": "Karil the Tainted's leather skirt.",
"id": 4950,
"members": true,
"lowalch": 18800,
"limit": 15,
"value": 47000,
"highalch": 28200,
"icon": "Karil's leatherskirt 0.png",
"name": "Karil's leatherskirt 0"
},
{
"examine": "Karil the Tainted's leather body armour.",
"id": 4736,
"members": true,
"lowalch": 20000,
"limit": 15,
"value": 50000,
"highalch": 30000,
"icon": "Karil's leathertop.png",
"name": "Karil's leathertop"
},
{
"examine": "Karil the Tainted's leather body armour.",
"id": 4944,
"members": true,
"lowalch": 20000,
"limit": 15,
"value": 50000,
"highalch": 30000,
"icon": "Karil's leathertop 0.png",
"name": "Karil's leathertop 0"
},
{
"examine": "Created by Hattori Hanzo in the Eastern Lands.",
"id": 12357,
"members": true,
"lowalch": 9600,
"limit": 8,
"value": 24000,
"highalch": 14400,
"icon": "Katana.png",
"name": "Katana"
},
{
"examine": "A meaty kebab.",
"id": 1971,
"members": false,
"lowalch": 1,
"limit": 6000,
"value": 3,
"highalch": 1,
"icon": "Kebab.png",
"name": "Kebab"
},
{
"examine": "A mixture of chopped tomatoes, onions and ugthanki meat in a bowl.",
"id": 1881,
"members": true,
"lowalch": 3,
"limit": 11000,
"value": 9,
"highalch": 5,
"icon": "Kebab mix.png",
"name": "Kebab mix"
},
{
"examine": "Bolts made from the spikes of a prickly kebbit.",
"id": 10158,
"members": true,
"lowalch": 7,
"limit": 11000,
"value": 18,
"highalch": 10,
"icon": "Kebbit bolts.png",
"name": "Kebbit bolts"
},
{
"examine": "More menacing when attached to the owner.",
"id": 10113,
"members": true,
"lowalch": 17,
"limit": 10000,
"value": 43,
"highalch": 25,
"icon": "Kebbit claws.png",
"name": "Kebbit claws"
},
{
"examine": "These bone spikes are both very tough and very sharp.",
"id": 10105,
"members": true,
"lowalch": 36,
"limit": 10000,
"value": 90,
"highalch": 54,
"icon": "Kebbit spike.png",
"name": "Kebbit spike"
},
{
"examine": "A kebbit-sized set of dentures.",
"id": 10109,
"members": true,
"lowalch": 64,
"limit": 10000,
"value": 160,
"highalch": 96,
"icon": "Kebbit teeth.png",
"name": "Kebbit teeth"
},
{
"examine": "Previously a kebbit-sized set of dentures.",
"id": 10111,
"members": true,
"lowalch": 64,
"limit": 10000,
"value": 160,
"highalch": 96,
"icon": "Kebbit teeth dust.png",
"name": "Kebbit teeth dust"
},
{
"examine": "A lot of beer in a barrel.",
"id": 3801,
"members": true,
"lowalch": 100,
"limit": 2000,
"value": 250,
"highalch": 150,
"icon": "Keg of beer.png",
"name": "Keg of beer"
},
{
"examine": "Wear it on your head.",
"id": 4591,
"members": true,
"lowalch": 1,
"limit": 15,
"value": 1,
"highalch": 1,
"icon": "Kharidian headpiece.png",
"name": "Kharidian headpiece"
},
{
"examine": "A teleport to Kharyrll.",
"id": 12779,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Kharyrll teleport (tablet).png",
"name": "Kharyrll teleport (tablet)"
},
{
"examine": "A teleport to Port Khazard.",
"id": 24957,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Khazard teleport (tablet).png",
"name": "Khazard teleport (tablet)"
},
{
"examine": "They're a gnome delicacy apparently.",
"id": 2162,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "King worm.png",
"name": "King worm"
},
{
"examine": "A sharp, dependable knife, for filleting meat.",
"id": 7447,
"members": true,
"lowalch": 3200,
"limit": 50,
"value": 8000,
"highalch": 4800,
"icon": "Kitchen knife.png",
"name": "Kitchen knife"
},
{
"examine": "How does it all fit in there?",
"id": 8528,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Kitchen table (flatpack).png",
"name": "Kitchen table (flatpack)"
},
{
"examine": "A dangerous looking knife.",
"id": 946,
"members": false,
"lowalch": 2,
"limit": 40,
"value": 6,
"highalch": 3,
"icon": "Knife.png",
"name": "Knife"
},
{
"examine": "An insignia representing Kodai, Xeric's fearsome wizards.",
"id": 21043,
"members": true,
"lowalch": 60000,
"limit": 8,
"value": 150000,
"highalch": 90000,
"icon": "Kodai insignia.png",
"name": "Kodai insignia"
},
{
"examine": "A wand of an ancient Kodai mage.",
"id": 21006,
"members": true,
"lowalch": 60000,
"limit": 8,
"value": 150000,
"highalch": 90000,
"icon": "Kodai wand.png",
"name": "Kodai wand"
},
{
"highalch": 1,
"members": true,
"name": "Kourend castle teleport (tablet)",
"examine": "A teleport to Kourend Castle.",
"id": 28790,
"value": 1,
"icon": "Kourend castle teleport (tablet).png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Kovac's grog",
"examine": "Only Kovac knows what's gone into this.",
"id": 27014,
"value": 1,
"icon": "Kovac's grog.png",
"lowalch": 1
},
{
"examine": "Ewwww... it's still sucky.",
"id": 12004,
"members": true,
"lowalch": 33336,
"limit": 70,
"value": 83340,
"highalch": 50004,
"icon": "Kraken tentacle.png",
"name": "Kraken tentacle"
},
{
"examine": "A handful of Krandorian Hops.",
"id": 6000,
"members": true,
"lowalch": 4,
"limit": 11000,
"value": 10,
"highalch": 6,
"icon": "Krandorian hops.png",
"name": "Krandorian hops"
},
{
"examine": "A Krandorian hop seed - plant in a hops patch.",
"id": 5310,
"members": true,
"lowalch": 3,
"limit": 2000,
"value": 8,
"highalch": 4,
"icon": "Krandorian seed 5.png",
"name": "Krandorian seed"
},
{
"examine": "A powerful herb.",
"id": 263,
"members": true,
"lowalch": 21,
"limit": 13000,
"value": 54,
"highalch": 32,
"icon": "Kwuarm.png",
"name": "Kwuarm"
},
{
"examine": "I need another ingredient to finish this Kwuarm potion.",
"id": 105,
"members": true,
"lowalch": 21,
"limit": 10000,
"value": 54,
"highalch": 32,
"icon": "Kwuarm potion (unf).png",
"name": "Kwuarm potion (unf)"
},
{
"examine": "A kwuarm seed - plant in a herb patch.",
"id": 5299,
"members": true,
"lowalch": 4,
"limit": 200,
"value": 11,
"highalch": 6,
"icon": "Kwuarm seed 5.png",
"name": "Kwuarm seed"
},
{
"examine": "It's a perfect-looking kyatt fur.",
"id": 10103,
"members": true,
"lowalch": 64,
"limit": 10000,
"value": 160,
"highalch": 96,
"icon": "Kyatt fur.png",
"name": "Kyatt fur"
},
{
"examine": "This should make me harder to spot in polar areas.",
"id": 10039,
"members": true,
"lowalch": 400,
"limit": 150,
"value": 1000,
"highalch": 600,
"icon": "Kyatt hat.png",
"name": "Kyatt hat"
},
{
"examine": "These should make me harder to spot in polar areas.",
"id": 10035,
"members": true,
"lowalch": 80,
"limit": 150,
"value": 200,
"highalch": 120,
"icon": "Kyatt legs.png",
"name": "Kyatt legs"
},
{
"examine": "This should make me harder to spot in polar areas.",
"id": 10037,
"members": true,
"lowalch": 80,
"limit": 150,
"value": 200,
"highalch": 120,
"icon": "Kyatt top.png",
"name": "Kyatt top"
},
{
"examine": "A powerful herb.",
"id": 2481,
"members": true,
"lowalch": 27,
"limit": 11000,
"value": 68,
"highalch": 40,
"icon": "Lantadyme.png",
"name": "Lantadyme"
},
{
"examine": "I need another ingredient to finish this Lantadyme potion.",
"id": 2483,
"members": true,
"lowalch": 27,
"limit": 10000,
"value": 68,
"highalch": 40,
"icon": "Lantadyme potion (unf).png",
"name": "Lantadyme potion (unf)"
},
{
"examine": "A lantadyme seed - plant in a herb patch.",
"id": 5302,
"members": true,
"lowalch": 3,
"limit": 200,
"value": 9,
"highalch": 5,
"icon": "Lantadyme seed 5.png",
"name": "Lantadyme seed"
},
{
"examine": "A roughly circular disc of glass.",
"id": 4542,
"members": true,
"lowalch": 28,
"limit": 10000,
"value": 70,
"highalch": 42,
"icon": "Lantern lens.png",
"name": "Lantern lens"
},
{
"examine": "How does it all fit in there?",
"id": 8580,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Large oak bed (flatpack).png",
"name": "Large oak bed (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8584,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Large teak bed (flatpack).png",
"name": "Large teak bed (flatpack)"
},
{
"examine": "Opens Larran's chests in the Wilderness.",
"id": 23490,
"members": true,
"lowalch": 1,
"limit": 250,
"value": 1,
"highalch": 1,
"icon": "Larran's key 5.png",
"name": "Larran's key"
},
{
"examine": "It's a perfect-looking larupia fur.",
"id": 10095,
"members": true,
"lowalch": 32,
"limit": 10000,
"value": 80,
"highalch": 48,
"icon": "Larupia fur.png",
"name": "Larupia fur"
},
{
"examine": "This should make me harder to spot in woodland and jungle areas.",
"id": 10045,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Larupia hat.png",
"name": "Larupia hat"
},
{
"examine": "These should make me harder to spot in woodland and jungle areas.",
"id": 10041,
"members": true,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Larupia legs.png",
"name": "Larupia legs"
},
{
"examine": "This should make me harder to spot in woodland and jungle areas.",
"id": 10043,
"members": true,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Larupia top.png",
"name": "Larupia top"
},
{
"examine": "A teleport to Lassar.",
"id": 12780,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Lassar teleport (tablet).png",
"name": "Lassar teleport (tablet)"
},
{
"examine": "It's a slightly magical stick.",
"id": 3053,
"members": true,
"lowalch": 6800,
"limit": 8,
"value": 17000,
"highalch": 10200,
"icon": "Lava battlestaff.png",
"name": "Lava battlestaff"
},
{
"examine": "Bones of a lava dragon.",
"id": 11943,
"members": true,
"lowalch": 60,
"limit": 7500,
"value": 150,
"highalch": 90,
"icon": "Lava dragon bones.png",
"name": "Lava dragon bones"
},
{
"examine": "Do I look scary?",
"id": 12371,
"members": true,
"lowalch": 4000,
"limit": 4,
"value": 10000,
"highalch": 6000,
"icon": "Lava dragon mask.png",
"name": "Lava dragon mask"
},
{
"examine": "A combined Earth and Fire Rune.",
"id": 4699,
"members": true,
"lowalch": 8,
"limit": 18000,
"value": 20,
"highalch": 12,
"icon": "Lava rune.png",
"name": "Lava rune"
},
{
"examine": "A scale from the hide of a lava dragon.",
"id": 11992,
"members": true,
"lowalch": 20,
"limit": 11000,
"value": 50,
"highalch": 30,
"icon": "Lava scale.png",
"name": "Lava scale"
},
{
"examine": "Ground-up lava dragon scale.",
"id": 11994,
"members": true,
"lowalch": 8,
"limit": 11000,
"value": 20,
"highalch": 12,
"icon": "Lava scale shard 5.png",
"name": "Lava scale shard"
},
{
"examine": "Makes a lava battlestaff or mystic lava staff more beautiful.",
"id": 21202,
"members": true,
"lowalch": 600,
"limit": 50,
"value": 1500,
"highalch": 900,
"icon": "Lava staff upgrade kit.png",
"name": "Lava staff upgrade kit"
},
{
"examine": "Used for teleport spells.",
"id": 563,
"members": false,
"lowalch": 96,
"limit": 18000,
"value": 240,
"highalch": 144,
"icon": "Law rune.png",
"name": "Law rune"
},
{
"examine": "A razor-sharp battleaxe.",
"id": 20727,
"members": true,
"lowalch": 38400,
"limit": 70,
"value": 96000,
"highalch": 57600,
"icon": "Leaf-bladed battleaxe.png",
"name": "Leaf-bladed battleaxe"
},
{
"examine": "A razor-sharp sword.",
"id": 11902,
"members": true,
"lowalch": 27200,
"limit": 70,
"value": 68000,
"highalch": 40800,
"icon": "Leaf-bladed sword.png",
"name": "Leaf-bladed sword"
},
{
"examine": "The lean, slimy corpse of a deceased giant snail.",
"id": 3365,
"members": true,
"lowalch": 4,
"limit": 13000,
"value": 10,
"highalch": 6,
"icon": "Lean snail.png",
"name": "Lean snail"
},
{
"examine": "A succulently slimy slice of sumptuous snail.",
"id": 3371,
"members": true,
"lowalch": 8,
"limit": 6000,
"value": 20,
"highalch": 12,
"icon": "Lean snail meat.png",
"name": "Lean snail meat"
},
{
"examine": "Some non-tasty salmon.",
"id": 11330,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 3,
"highalch": 1,
"icon": "Leaping salmon.png",
"name": "Leaping salmon"
},
{
"examine": "A bloated sturgeon.",
"id": 11332,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 4,
"highalch": 2,
"icon": "Leaping sturgeon.png",
"name": "Leaping sturgeon"
},
{
"examine": "A sad-looking trout.",
"id": 11328,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Leaping trout.png",
"name": "Leaping trout"
},
{
"examine": "It's a piece of leather.",
"id": 1741,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Leather.png",
"name": "Leather"
},
{
"examine": "Better than no armour!",
"id": 1129,
"members": false,
"lowalch": 8,
"limit": 125,
"value": 21,
"highalch": 12,
"icon": "Leather body.png",
"name": "Leather body"
},
{
"examine": "Better than no armour! Nice trim too!",
"id": 23381,
"members": false,
"lowalch": 340,
"limit": 8,
"value": 850,
"highalch": 510,
"icon": "Leather body (g).png",
"name": "Leather body (g)"
},
{
"examine": "Comfortable leather boots.",
"id": 1061,
"members": false,
"lowalch": 2,
"limit": 125,
"value": 6,
"highalch": 3,
"icon": "Leather boots.png",
"name": "Leather boots"
},
{
"examine": "Better than no armour!",
"id": 1095,
"members": false,
"lowalch": 8,
"limit": 125,
"value": 20,
"highalch": 12,
"icon": "Leather chaps.png",
"name": "Leather chaps"
},
{
"examine": "Better than no armour! Nice trim too!",
"id": 23384,
"members": false,
"lowalch": 340,
"limit": 8,
"value": 850,
"highalch": 510,
"icon": "Leather chaps (g).png",
"name": "Leather chaps (g)"
},
{
"examine": "Better than no armour!",
"id": 1167,
"members": false,
"lowalch": 9,
"limit": 125,
"value": 24,
"highalch": 14,
"icon": "Leather cowl.png",
"name": "Leather cowl"
},
{
"examine": "These will keep my hands warm!",
"id": 1059,
"members": false,
"lowalch": 2,
"limit": 125,
"value": 6,
"highalch": 3,
"icon": "Leather gloves.png",
"name": "Leather gloves"
},
{
"examine": "Better than no armour!",
"id": 1063,
"members": false,
"lowalch": 7,
"limit": 125,
"value": 18,
"highalch": 10,
"icon": "Leather vambraces.png",
"name": "Leather vambraces"
},
{
"highalch": 1,
"members": true,
"name": "Leaves",
"examine": "A pile of leaves.",
"id": 6020,
"value": 1,
"icon": "Leaves.png",
"lowalch": 1
},
{
"examine": "A black piece of cloth on a string.",
"id": 19724,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 2,
"highalch": 1,
"icon": "Left eye patch.png",
"name": "Left eye patch"
},
{
"examine": "A fresh lemon.",
"id": 2102,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Lemon.png",
"name": "Lemon"
},
{
"examine": "Fresh chunks of lemon.",
"id": 2104,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Lemon chunks.png",
"name": "Lemon chunks"
},
{
"examine": "Fresh lemon slices.",
"id": 2106,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Lemon slices.png",
"name": "Lemon slices"
},
{
"examine": "Top o' the morning!",
"id": 12359,
"members": true,
"lowalch": 400,
"limit": 4,
"value": 1000,
"highalch": 600,
"icon": "Leprechaun hat.png",
"name": "Leprechaun hat"
},
{
"examine": "Evil.",
"id": 20020,
"members": true,
"lowalch": 1600,
"limit": 4,
"value": 4000,
"highalch": 2400,
"icon": "Lesser demon mask.png",
"name": "Lesser demon mask"
},
{
"examine": "A weapon forged from the wreckage of an airship.",
"id": 19478,
"members": true,
"lowalch": 50000,
"limit": 8,
"value": 125000,
"highalch": 75000,
"icon": "Light ballista.png",
"name": "Light ballista"
},
{
"examine": "Bow ties are cool.",
"id": 19985,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Light bow tie.png",
"name": "Light bow tie"
},
{
"examine": "A light wooden frame.",
"id": 19586,
"members": true,
"lowalch": 20000,
"limit": 8,
"value": 50000,
"highalch": 30000,
"icon": "Light frame.png",
"name": "Light frame"
},
{
"examine": "Use on infinity pieces to recolour them!",
"id": 12530,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Light infinity colour kit.png",
"name": "Light infinity colour kit"
},
{
"examine": "A component of cave goblin Magic.",
"id": 10973,
"members": true,
"lowalch": 140,
"limit": 10000,
"value": 350,
"highalch": 210,
"icon": "Light orb.png",
"name": "Light orb"
},
{
"examine": "Light tuxedo trousers with matching stockings.",
"id": 19979,
"members": true,
"lowalch": 2400,
"limit": 4,
"value": 6000,
"highalch": 3600,
"icon": "Light trousers.png",
"name": "Light trousers"
},
{
"examine": "Linked tuxedo cuffs with a gold trim.",
"id": 19976,
"members": true,
"lowalch": 1200,
"limit": 4,
"value": 3000,
"highalch": 1800,
"icon": "Light tuxedo cuffs.png",
"name": "Light tuxedo cuffs"
},
{
"examine": "A light tuxedo jacket with a matching shirt.",
"id": 19973,
"members": true,
"lowalch": 4000,
"limit": 4,
"value": 10000,
"highalch": 6000,
"icon": "Light tuxedo jacket.png",
"name": "Light tuxedo jacket"
},
{
"examine": "Light shoes to match your light tuxedo.",
"id": 19982,
"members": true,
"lowalch": 1200,
"limit": 4,
"value": 3000,
"highalch": 1800,
"icon": "Light tuxedo shoes.png",
"name": "Light tuxedo shoes"
},
{
"examine": "A heavy ring that glimmers with a strange power.",
"id": 25975,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 1,
"highalch": 1,
"icon": "Lightbearer.png",
"name": "Lightbearer"
},
{
"examine": "A beautiful lily.",
"id": 27272,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Lily of the sands.png",
"name": "Lily of the sands"
},
{
"examine": "A fresh lime.",
"id": 2120,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Lime.png",
"name": "Lime"
},
{
"examine": "Fresh chunks of lime.",
"id": 2122,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Lime chunks.png",
"name": "Lime chunks"
},
{
"examine": "Fresh lime slices.",
"id": 2124,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Lime slices.png",
"name": "Lime slices"
},
{
"examine": "Some limestone.",
"id": 3211,
"members": true,
"lowalch": 4,
"limit": 13000,
"value": 10,
"highalch": 6,
"icon": "Limestone.png",
"name": "Limestone"
},
{
"examine": "A well carved limestone brick.",
"id": 3420,
"members": true,
"lowalch": 8,
"limit": 10000,
"value": 20,
"highalch": 12,
"icon": "Limestone brick.png",
"name": "Limestone brick"
},
{
"examine": "The root of a limpwurt plant.",
"id": 225,
"members": false,
"lowalch": 2,
"limit": 13000,
"value": 7,
"highalch": 4,
"icon": "Limpwurt root.png",
"name": "Limpwurt root"
},
{
"examine": "A limpwurt seed - plant in a flower patch.",
"id": 5100,
"members": true,
"lowalch": 4,
"limit": 600,
"value": 12,
"highalch": 7,
"icon": "Limpwurt seed 5.png",
"name": "Limpwurt seed"
},
{
"examine": "One sheet of mummy wrap.",
"id": 4684,
"members": true,
"lowalch": 12,
"limit": 15,
"value": 30,
"highalch": 18,
"icon": "Linen.png",
"name": "Linen"
},
{
"highalch": 12,
"members": true,
"name": "Lizardkicker",
"examine": "A viscous bright-green ale. Smells like a herpetarium.",
"id": 25826,
"value": 20,
"icon": "Lizardkicker.png",
"lowalch": 8
},
{
"examine": "Ripped from the maw of a dying lizardman of Kourend.",
"id": 13391,
"members": true,
"lowalch": 24,
"limit": 13000,
"value": 60,
"highalch": 36,
"icon": "Lizardman fang.png",
"name": "Lizardman fang"
},
{
"examine": "The remains of a deadly shade.",
"id": 3396,
"members": true,
"lowalch": 1,
"limit": 7500,
"value": 1,
"highalch": 1,
"icon": "Loar remains.png",
"name": "Loar remains"
},
{
"examine": "This looks tricky to eat.",
"id": 379,
"members": false,
"lowalch": 28,
"limit": 6000,
"value": 70,
"highalch": 42,
"icon": "Lobster.png",
"name": "Lobster"
},
{
"examine": "Useful for catching lobsters.",
"id": 301,
"members": false,
"lowalch": 8,
"limit": 40,
"value": 20,
"highalch": 12,
"icon": "Lobster pot.png",
"name": "Lobster pot"
},
{
"examine": "For picking tough locks.",
"id": 1523,
"members": true,
"lowalch": 8,
"limit": 250,
"value": 20,
"highalch": 12,
"icon": "Lockpick.png",
"name": "Lockpick"
},
{
"examine": "Delicious and nutritious. Well, nutritious anyway.",
"id": 9052,
"members": true,
"lowalch": 1,
"limit": 6000,
"value": 1,
"highalch": 1,
"icon": "Locust meat.png",
"name": "Locust meat"
},
{
"examine": "With some rope, adamantite bars and steel nails, I can create a sturdy harness.",
"id": 28146,
"members": true,
"lowalch": 1,
"limit": 40,
"value": 1,
"highalch": 1,
"icon": "Log brace.png",
"name": "Log brace"
},
{
"examine": "A number of wooden logs.",
"id": 1511,
"members": false,
"lowalch": 1,
"limit": 15000,
"value": 4,
"highalch": 2,
"icon": "Logs.png",
"name": "Logs"
},
{
"examine": "Bolts made from the spikes of a razor-backed kebbit.",
"id": 10159,
"members": true,
"lowalch": 12,
"limit": 11000,
"value": 31,
"highalch": 18,
"icon": "Long kebbit bolts.png",
"name": "Long kebbit bolts"
},
{
"examine": "These bone spikes are both very tough and very sharp.",
"id": 10107,
"members": true,
"lowalch": 60,
"limit": 10000,
"value": 150,
"highalch": 90,
"icon": "Long kebbit spike.png",
"name": "Long kebbit spike"
},
{
"examine": "A nice sturdy bow.",
"id": 839,
"members": false,
"lowalch": 32,
"limit": 18000,
"value": 80,
"highalch": 48,
"icon": "Longbow.png",
"name": "Longbow"
},
{
"examine": "I need to find a string for this.",
"id": 48,
"members": true,
"lowalch": 24,
"limit": 10000,
"value": 60,
"highalch": 36,
"icon": "Longbow (u).png",
"name": "Longbow (u)"
},
{
"examine": "The loop end of the mysterious Crystal Key. Can you find the other half?",
"id": 987,
"members": true,
"lowalch": 40,
"limit": 11000,
"value": 100,
"highalch": 60,
"icon": "Loop half of key.png",
"name": "Loop half of key"
},
{
"examine": "A note that may be exchanged at a bank for a Looting Bag.",
"id": 24585,
"members": true,
"lowalch": 36,
"limit": 40,
"value": 90,
"highalch": 54,
"icon": "Looting bag note.png",
"name": "Looting bag note"
},
{
"examine": "A lovakite banner baring the sigil of house Lovakengj.",
"id": 20257,
"members": true,
"lowalch": 28,
"limit": 4,
"value": 70,
"highalch": 42,
"icon": "Lovakengj banner.png",
"name": "Lovakengj banner"
},
{
"examine": "A rare hood from Lovakengj .",
"id": 20119,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Lovakengj hood.png",
"name": "Lovakengj hood"
},
{
"examine": "A yellow scarf adorned with an ornamental anvil.",
"id": 19949,
"members": true,
"lowalch": 4000,
"limit": 4,
"value": 10000,
"highalch": 6000,
"icon": "Lovakengj scarf.png",
"name": "Lovakengj scarf"
},
{
"examine": "Teleports you to the Lumberyard.",
"id": 12642,
"members": true,
"lowalch": 4,
"limit": 10000,
"value": 10,
"highalch": 6,
"icon": "Lumberyard teleport.png",
"name": "Lumberyard teleport"
},
{
"examine": "A teleport to Lumbridge.",
"id": 8008,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Lumbridge teleport (tablet).png",
"name": "Lumbridge teleport (tablet)"
},
{
"examine": "Teleports you to Lunar isle.",
"id": 12405,
"members": true,
"lowalch": 4,
"limit": 10000,
"value": 10,
"highalch": 6,
"icon": "Lunar isle teleport.png",
"name": "Lunar isle teleport"
},
{
"examine": "Mahogany treasure chest.",
"id": 9864,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "M. treasure chest (flatpack).png",
"name": "M. treasure chest (flatpack)"
},
{
"examine": "A jungle specific slashing device.",
"id": 975,
"members": true,
"lowalch": 16,
"limit": 15,
"value": 40,
"highalch": 24,
"icon": "Machete.png",
"name": "Machete"
},
{
"examine": "Some nicely cooked mackerel.",
"id": 355,
"members": true,
"lowalch": 6,
"limit": 6000,
"value": 15,
"highalch": 9,
"icon": "Mackerel.png",
"name": "Mackerel"
},
{
"examine": "The magical book of the Mage.",
"id": 6889,
"members": true,
"lowalch": 200,
"limit": 15,
"value": 500,
"highalch": 300,
"icon": "Mage's book.png",
"name": "Mage's book"
},
{
"examine": "Feed and catch the birds by placing this in the right spot.",
"id": 22201,
"members": true,
"lowalch": 2,
"limit": 250,
"value": 5,
"highalch": 3,
"icon": "Magic bird house.png",
"name": "Magic bird house"
},
{
"examine": "A magical catching box.",
"id": 10025,
"members": true,
"lowalch": 240,
"limit": 250,
"value": 600,
"highalch": 360,
"icon": "Magic box.png",
"name": "Magic box"
},
{
"examine": "How does it all fit in there?",
"id": 9848,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Magic cape rack (flatpack).png",
"name": "Magic cape rack (flatpack)"
},
{
"examine": "A powerful bow made from magic wood.",
"id": 10284,
"members": true,
"lowalch": 1000,
"limit": 8,
"value": 2500,
"highalch": 1500,
"icon": "Magic comp bow.png",
"name": "Magic comp bow"
},
{
"examine": "One dose of fishy Magic essence.",
"id": 11491,
"members": true,
"lowalch": 52,
"limit": 2000,
"value": 130,
"highalch": 78,
"icon": "Magic essence mix(1).png",
"name": "Magic essence mix(1)"
},
{
"examine": "Two doses of fishy Magic essence.",
"id": 11489,
"members": true,
"lowalch": 72,
"limit": 2000,
"value": 180,
"highalch": 108,
"icon": "Magic essence mix(2).png",
"name": "Magic essence mix(2)"
},
{
"examine": "This can be attached to a Staff of the Dead or a Trident of the Seas.",
"id": 12932,
"members": true,
"lowalch": 40800,
"limit": 5,
"value": 102000,
"highalch": 61200,
"icon": "Magic fang.png",
"name": "Magic fang"
},
{
"highalch": 1,
"members": true,
"name": "Magic leaves",
"examine": "A pile of Magic tree leaves.",
"id": 6030,
"value": 1,
"icon": "Magic leaves.png",
"lowalch": 1
},
{
"examine": "Logs cut from a magic tree.",
"id": 1513,
"members": true,
"lowalch": 128,
"limit": 12000,
"value": 320,
"highalch": 192,
"icon": "Magic logs.png",
"name": "Magic logs"
},
{
"examine": "A nice sturdy magical bow.",
"id": 859,
"members": true,
"lowalch": 1024,
"limit": 18000,
"value": 2560,
"highalch": 1536,
"icon": "Magic longbow.png",
"name": "Magic longbow"
},
{
"examine": "An unstrung magic longbow; I need a bowstring for this.",
"id": 70,
"members": true,
"lowalch": 512,
"limit": 10000,
"value": 1280,
"highalch": 768,
"icon": "Magic longbow (u).png",
"name": "Magic longbow (u)"
},
{
"examine": "One dose of fishy Magic potion.",
"id": 11515,
"members": true,
"lowalch": 60,
"limit": 2000,
"value": 150,
"highalch": 90,
"icon": "Magic mix(1).png",
"name": "Magic mix(1)"
},
{
"examine": "Two doses of fishy Magic potion.",
"id": 11513,
"members": true,
"lowalch": 80,
"limit": 2000,
"value": 200,
"highalch": 120,
"icon": "Magic mix(2).png",
"name": "Magic mix(2)"
},
{
"examine": "1 dose of Magic potion.",
"id": 3046,
"members": true,
"lowalch": 60,
"limit": 2000,
"value": 150,
"highalch": 90,
"icon": "Magic potion(1).png",
"name": "Magic potion(1)"
},
{
"examine": "2 doses of Magic potion.",
"id": 3044,
"members": true,
"lowalch": 80,
"limit": 2000,
"value": 200,
"highalch": 120,
"icon": "Magic potion(2).png",
"name": "Magic potion(2)"
},
{
"examine": "3 doses of Magic potion.",
"id": 3042,
"members": true,
"lowalch": 100,
"limit": 2000,
"value": 250,
"highalch": 150,
"icon": "Magic potion(3).png",
"name": "Magic potion(3)"
},
{
"examine": "4 doses of Magic potion.",
"id": 3040,
"members": true,
"lowalch": 120,
"limit": 2000,
"value": 300,
"highalch": 180,
"icon": "Magic potion(4).png",
"name": "Magic potion(4)"
},
{
"examine": "Magic logs prepared with sacred oil for a funeral pyre.",
"id": 3448,
"members": true,
"lowalch": 256,
"limit": 11000,
"value": 640,
"highalch": 384,
"icon": "Magic pyre logs.png",
"name": "Magic pyre logs"
},
{
"examine": "The roots of the Magic tree.",
"id": 6051,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 1,
"highalch": 1,
"icon": "Magic roots.png",
"name": "Magic roots"
},
{
"examine": "This sapling is ready to be replanted in a tree patch.",
"id": 5374,
"members": true,
"lowalch": 1,
"limit": 200,
"value": 1,
"highalch": 1,
"icon": "Magic sapling.png",
"name": "Magic sapling"
},
{
"examine": "Plant this in a plantpot of soil to grow a sapling.",
"id": 5316,
"members": true,
"lowalch": 168,
"limit": 200,
"value": 422,
"highalch": 253,
"icon": "Magic seed 5.png",
"name": "Magic seed"
},
{
"examine": "A solid magic wood shield.",
"id": 22263,
"members": true,
"lowalch": 512,
"limit": 125,
"value": 1280,
"highalch": 768,
"icon": "Magic shield.png",
"name": "Magic shield"
},
{
"examine": "Short and magical, but still effective.",
"id": 861,
"members": true,
"lowalch": 640,
"limit": 18000,
"value": 1600,
"highalch": 960,
"icon": "Magic shortbow.png",
"name": "Magic shortbow"
},
{
"examine": "An unstrung magic shortbow; I need a bowstring for this.",
"id": 72,
"members": true,
"lowalch": 320,
"limit": 10000,
"value": 800,
"highalch": 480,
"icon": "Magic shortbow (u).png",
"name": "Magic shortbow (u)"
},
{
"examine": "Use on a Magic shortbow to imbue the bow.",
"id": 12786,
"members": true,
"lowalch": 2000,
"limit": 10000,
"value": 5000,
"highalch": 3000,
"icon": "Magic shortbow scroll.png",
"name": "Magic shortbow scroll"
},
{
"examine": "A Magical staff.",
"id": 1389,
"members": false,
"lowalch": 80,
"limit": 125,
"value": 200,
"highalch": 120,
"icon": "Magic staff.png",
"name": "Magic staff"
},
{
"examine": "A magic crossbow stock.",
"id": 21952,
"members": true,
"lowalch": 112,
"limit": 10000,
"value": 280,
"highalch": 168,
"icon": "Magic stock.png",
"name": "Magic stock"
},
{
"examine": "A magic stone to make high-level furniture.",
"id": 8788,
"members": true,
"lowalch": 300000,
"limit": 11000,
"value": 750000,
"highalch": 450000,
"icon": "Magic stone.png",
"name": "Magic stone"
},
{
"examine": "I could use this to make jewellery.",
"id": 6038,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 1,
"highalch": 1,
"icon": "Magic string.png",
"name": "Magic string"
},
{
"examine": "Magpie impling in a jar.",
"id": 11252,
"members": true,
"lowalch": 20,
"limit": 18000,
"value": 50,
"highalch": 30,
"icon": "Magpie impling jar.png",
"name": "Magpie impling jar"
},
{
"highalch": 1,
"members": true,
"name": "Magus ring",
"examine": "An occult ring that grants immense magical power.",
"id": 28313,
"value": 1,
"icon": "Magus ring.png",
"lowalch": 1
},
{
"examine": "How does it all fit in there?",
"id": 8508,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Mahogany armchair (flatpack).png",
"name": "Mahogany armchair (flatpack)"
},
{
"examine": "Mahogany armour case.",
"id": 9861,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Mahogany armour case (flatpack).png",
"name": "Mahogany armour case (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8572,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Mahogany bench (flatpack).png",
"name": "Mahogany bench (flatpack)"
},
{
"examine": "Feed and catch the birds by placing this in the right spot.",
"id": 22195,
"members": true,
"lowalch": 2,
"limit": 250,
"value": 5,
"highalch": 3,
"icon": "Mahogany bird house.png",
"name": "Mahogany bird house"
},
{
"examine": "How does it all fit in there?",
"id": 8514,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Mahogany bookcase (flatpack).png",
"name": "Mahogany bookcase (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 9845,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Mahogany cape rack (flatpack).png",
"name": "Mahogany cape rack (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8606,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Mahogany dresser (flatpack).png",
"name": "Mahogany dresser (flatpack)"
},
{
"examine": "Mahogany fancy dress box.",
"id": 9867,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Mahogany fancy dress box (flatpack).png",
"name": "Mahogany fancy dress box (flatpack)"
},
{
"examine": "Some well-cut mahogany logs.",
"id": 6332,
"members": true,
"lowalch": 20,
"limit": 11000,
"value": 50,
"highalch": 30,
"icon": "Mahogany logs.png",
"name": "Mahogany logs"
},
{
"examine": "Mahogany magic wardrobe.",
"id": 9856,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Mahogany magic wardrobe (flatpack).png",
"name": "Mahogany magic wardrobe (flatpack)"
},
{
"examine": "A plank of expensive mahogany.",
"id": 8782,
"members": true,
"lowalch": 600,
"limit": 13000,
"value": 1500,
"highalch": 900,
"icon": "Mahogany plank.png",
"name": "Mahogany plank"
},
{
"examine": "Mahogany logs prepared with sacred oil for a funeral pyre.",
"id": 6213,
"members": true,
"lowalch": 80,
"limit": 11000,
"value": 200,
"highalch": 120,
"icon": "Mahogany pyre logs.png",
"name": "Mahogany pyre logs"
},
{
"examine": "This sapling is ready to be replanted in a tree patch.",
"id": 21480,
"members": true,
"lowalch": 1,
"limit": 200,
"value": 1,
"highalch": 1,
"icon": "Mahogany sapling.png",
"name": "Mahogany sapling"
},
{
"examine": "Plant this in a plantpot of soil to grow a sapling.",
"id": 21488,
"members": true,
"lowalch": 19,
"limit": 200,
"value": 48,
"highalch": 28,
"icon": "Mahogany seed 5.png",
"name": "Mahogany seed"
},
{
"examine": "A mahogany crossbow stock.",
"id": 9450,
"members": true,
"lowalch": 53,
"limit": 10000,
"value": 133,
"highalch": 79,
"icon": "Mahogany stock.png",
"name": "Mahogany stock"
},
{
"examine": "How does it all fit in there?",
"id": 8558,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Mahogany table (flatpack).png",
"name": "Mahogany table (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 9851,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Mahogany toy box (flatpack).png",
"name": "Mahogany toy box (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8620,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Mahogany wardrobe (flatpack).png",
"name": "Mahogany wardrobe (flatpack)"
},
{
"examine": "A broken shield piece.",
"id": 11931,
"members": true,
"lowalch": 12400,
"limit": 10000,
"value": 31000,
"highalch": 18600,
"icon": "Malediction shard 1.png",
"name": "Malediction shard 1"
},
{
"examine": "A broken shield piece.",
"id": 11932,
"members": true,
"lowalch": 12400,
"limit": 10000,
"value": 31000,
"highalch": 18600,
"icon": "Malediction shard 2.png",
"name": "Malediction shard 2"
},
{
"examine": "A broken shield piece.",
"id": 11933,
"members": true,
"lowalch": 12400,
"limit": 10000,
"value": 31000,
"highalch": 18600,
"icon": "Malediction shard 3.png",
"name": "Malediction shard 3"
},
{
"examine": "An ancient, evil shield forged by Volcanic heat.",
"id": 11924,
"members": true,
"lowalch": 18000,
"limit": 8,
"value": 45000,
"highalch": 27000,
"icon": "Malediction ward.png",
"name": "Malediction ward"
},
{
"highalch": 1,
"members": true,
"name": "Malicious ashes",
"examine": "A heap of ashes.",
"id": 25772,
"value": 1,
"icon": "Malicious ashes.png",
"lowalch": 1
},
{
"examine": "A rare catch.",
"id": 391,
"members": true,
"lowalch": 80,
"limit": 10000,
"value": 200,
"highalch": 120,
"icon": "Manta ray.png",
"name": "Manta ray"
},
{
"examine": "Feed and catch the birds by placing this in the right spot.",
"id": 22192,
"members": true,
"lowalch": 2,
"limit": 250,
"value": 5,
"highalch": 3,
"icon": "Maple bird house.png",
"name": "Maple bird house"
},
{
"examine": "A solid bit of maple.",
"id": 6416,
"members": true,
"lowalch": 480,
"limit": 125,
"value": 1200,
"highalch": 720,
"icon": "Maple blackjack.png",
"name": "Maple blackjack"
},
{
"examine": "A defensive blackjack.",
"id": 6420,
"members": true,
"lowalch": 640,
"limit": 125,
"value": 1600,
"highalch": 960,
"icon": "Maple blackjack(d).png",
"name": "Maple blackjack(d)"
},
{
"examine": "An offensive blackjack.",
"id": 6418,
"members": true,
"lowalch": 640,
"limit": 125,
"value": 1600,
"highalch": 960,
"icon": "Maple blackjack(o).png",
"name": "Maple blackjack(o)"
},
{
"highalch": 1,
"members": true,
"name": "Maple leaves",
"examine": "A pile of Maple tree leaves.",
"id": 6028,
"value": 1,
"icon": "Maple leaves.png",
"lowalch": 1
},
{
"examine": "Logs cut from a maple tree.",
"id": 1517,
"members": false,
"lowalch": 32,
"limit": 15000,
"value": 80,
"highalch": 48,
"icon": "Maple logs.png",
"name": "Maple logs"
},
{
"examine": "A nice sturdy bow made out of Maple.",
"id": 851,
"members": false,
"lowalch": 256,
"limit": 18000,
"value": 640,
"highalch": 384,
"icon": "Maple longbow.png",
"name": "Maple longbow"
},
{
"examine": "An unstrung maple longbow; I need a bowstring for this.",
"id": 62,
"members": true,
"lowalch": 128,
"limit": 10000,
"value": 320,
"highalch": 192,
"icon": "Maple longbow (u).png",
"name": "Maple longbow (u)"
},
{
"examine": "Maple logs prepared with sacred oil for a funeral pyre.",
"id": 3444,
"members": true,
"lowalch": 64,
"limit": 11000,
"value": 160,
"highalch": 96,
"icon": "Maple pyre logs.png",
"name": "Maple pyre logs"
},
{
"examine": "The roots of the Maple tree.",
"id": 6047,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 1,
"highalch": 1,
"icon": "Maple roots.png",
"name": "Maple roots"
},
{
"examine": "This sapling is ready to be replanted in a tree patch.",
"id": 5372,
"members": true,
"lowalch": 1,
"limit": 200,
"value": 1,
"highalch": 1,
"icon": "Maple sapling.png",
"name": "Maple sapling"
},
{
"examine": "Plant this in a plantpot of soil to grow a sapling.",
"id": 5314,
"members": true,
"lowalch": 19,
"limit": 200,
"value": 48,
"highalch": 28,
"icon": "Maple seed 5.png",
"name": "Maple seed"
},
{
"examine": "A solid maple shield.",
"id": 22257,
"members": true,
"lowalch": 128,
"limit": 125,
"value": 320,
"highalch": 192,
"icon": "Maple shield.png",
"name": "Maple shield"
},
{
"examine": "A shortbow made out of Maple, still effective.",
"id": 853,
"members": false,
"lowalch": 160,
"limit": 18000,
"value": 400,
"highalch": 240,
"icon": "Maple shortbow.png",
"name": "Maple shortbow"
},
{
"examine": "An unstrung maple shortbow; I need a bowstring for this.",
"id": 64,
"members": true,
"lowalch": 80,
"limit": 10000,
"value": 200,
"highalch": 120,
"icon": "Maple shortbow (u).png",
"name": "Maple shortbow (u)"
},
{
"examine": "A maple crossbow stock.",
"id": 9448,
"members": true,
"lowalch": 40,
"limit": 10000,
"value": 100,
"highalch": 60,
"icon": "Maple stock.png",
"name": "Maple stock"
},
{
"examine": "A beautifully carved marble block.",
"id": 8786,
"members": true,
"lowalch": 100000,
"limit": 11000,
"value": 250000,
"highalch": 150000,
"icon": "Marble block.png",
"name": "Marble block"
},
{
"examine": "How does it all fit in there?",
"id": 9847,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Marble cape rack (flatpack).png",
"name": "Marble cape rack (flatpack)"
},
{
"examine": "Marble magic wardrobe.",
"id": 9858,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Marble magic wardrobe (flatpack).png",
"name": "Marble magic wardrobe (flatpack)"
},
{
"examine": "A marigold seed - plant in a flower patch.",
"id": 5096,
"members": true,
"lowalch": 1,
"limit": 600,
"value": 2,
"highalch": 1,
"icon": "Marigold seed 5.png",
"name": "Marigold seed"
},
{
"examine": "A bunch of marigolds.",
"id": 6010,
"members": true,
"lowalch": 1,
"limit": 600,
"value": 1,
"highalch": 1,
"icon": "Marigolds.png",
"name": "Marigolds"
},
{
"examine": "A herb used in poison cures.",
"id": 251,
"members": true,
"lowalch": 2,
"limit": 13000,
"value": 5,
"highalch": 3,
"icon": "Marrentill.png",
"name": "Marrentill"
},
{
"examine": "I need another ingredient to finish this Marrentill potion.",
"id": 93,
"members": true,
"lowalch": 2,
"limit": 10000,
"value": 5,
"highalch": 3,
"icon": "Marrentill potion (unf).png",
"name": "Marrentill potion (unf)"
},
{
"examine": "A marrentill seed - plant in a herb patch.",
"id": 5292,
"members": true,
"lowalch": 1,
"limit": 600,
"value": 2,
"highalch": 1,
"icon": "Marrentill seed 5.png",
"name": "Marrentill seed"
},
{
"examine": "A thick, foul-smelling, tar-like substance with an orange tinge.",
"id": 10143,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 1,
"highalch": 1,
"icon": "Marrentill tar.png",
"name": "Marrentill tar"
},
{
"examine": "A creepy mask worn during a blood moon.",
"id": 23522,
"members": true,
"lowalch": 2000,
"limit": 70,
"value": 5000,
"highalch": 3000,
"icon": "Mask of ranul.png",
"name": "Mask of ranul"
},
{
"highalch": 1,
"members": true,
"name": "Masori armour set (f)",
"examine": "A set containing a Masori mask (f), Masori body (f), Masori chaps (f)",
"id": 27355,
"value": 1,
"icon": "Masori armour set (f).png",
"lowalch": 1
},
{
"examine": "Ancient armour once worn by a powerful ranger.",
"id": 27229,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 1,
"highalch": 1,
"icon": "Masori body.png",
"name": "Masori body"
},
{
"examine": "Fortified armour once worn by a powerful ranger.",
"id": 27238,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 1,
"highalch": 1,
"icon": "Masori body (f).png",
"name": "Masori body (f)"
},
{
"examine": "Ancient armour once worn by a powerful ranger.",
"id": 27232,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 1,
"highalch": 1,
"icon": "Masori chaps.png",
"name": "Masori chaps"
},
{
"examine": "Fortified armour once worn by a powerful ranger.",
"id": 27241,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 1,
"highalch": 1,
"icon": "Masori chaps (f).png",
"name": "Masori chaps (f)"
},
{
"examine": "An ancient mask once worn by a powerful ranger.",
"id": 27226,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 1,
"highalch": 1,
"icon": "Masori mask.png",
"name": "Masori mask"
},
{
"examine": "A fortified mask once worn by a powerful ranger.",
"id": 27235,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 1,
"highalch": 1,
"icon": "Masori mask (f).png",
"name": "Masori mask (f)"
},
{
"examine": "I can store my teleport scrolls in this.",
"id": 21387,
"members": true,
"lowalch": 2000,
"limit": 100,
"value": 5000,
"highalch": 3000,
"icon": "Master scroll book (empty).png",
"name": "Master scroll book (empty)"
},
{
"examine": "A master level wand.",
"id": 6914,
"members": true,
"lowalch": 40000,
"limit": 70,
"value": 100001,
"highalch": 60000,
"icon": "Master wand.png",
"name": "Master wand"
},
{
"examine": "This looks a good deal stronger than normal cider.",
"id": 5765,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 2,
"highalch": 1,
"icon": "Mature cider.png",
"name": "Mature cider"
},
{
"examine": "This looks a good deal stronger than normal Wizards Mind Bomb.",
"id": 5741,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 2,
"highalch": 1,
"icon": "Mature wmb.png",
"name": "Mature wmb"
},
{
"examine": "Not for vegetarians.",
"id": 2327,
"members": false,
"lowalch": 6,
"limit": 10000,
"value": 15,
"highalch": 9,
"icon": "Meat pie.png",
"name": "Meat pie"
},
{
"examine": "A pizza with bits of meat on it.",
"id": 2293,
"members": false,
"lowalch": 20,
"limit": 10000,
"value": 50,
"highalch": 30,
"icon": "Meat pizza.png",
"name": "Meat pizza"
},
{
"examine": "Often used to soften tough meat up.",
"id": 7449,
"members": true,
"lowalch": 16600,
"limit": 50,
"value": 41500,
"highalch": 24900,
"icon": "Meat tenderiser.png",
"name": "Meat tenderiser"
},
{
"examine": "Good for keeping the sun off my neck.",
"id": 6392,
"members": true,
"lowalch": 14,
"limit": 150,
"value": 35,
"highalch": 21,
"icon": "Menaphite purple hat.png",
"name": "Menaphite purple hat"
},
{
"examine": "Look at those nobbily knees.",
"id": 6398,
"members": true,
"lowalch": 8,
"limit": 150,
"value": 20,
"highalch": 12,
"icon": "Menaphite purple kilt.png",
"name": "Menaphite purple kilt"
},
{
"examine": "A cool light Menaphite robe.",
"id": 6396,
"members": true,
"lowalch": 16,
"limit": 150,
"value": 40,
"highalch": 24,
"icon": "Menaphite purple robe.png",
"name": "Menaphite purple robe"
},
{
"examine": "Colourful.",
"id": 6394,
"members": true,
"lowalch": 8,
"limit": 150,
"value": 20,
"highalch": 12,
"icon": "Menaphite purple top.png",
"name": "Menaphite purple top"
},
{
"examine": "Good for keeping the sun off my neck.",
"id": 6400,
"members": true,
"lowalch": 14,
"limit": 150,
"value": 35,
"highalch": 21,
"icon": "Menaphite red hat.png",
"name": "Menaphite red hat"
},
{
"examine": "Look at those nobbily knees.",
"id": 6406,
"members": true,
"lowalch": 8,
"limit": 150,
"value": 20,
"highalch": 12,
"icon": "Menaphite red kilt.png",
"name": "Menaphite red kilt"
},
{
"examine": "A cool light Menaphite robe.",
"id": 6404,
"members": true,
"lowalch": 16,
"limit": 150,
"value": 40,
"highalch": 24,
"icon": "Menaphite red robe.png",
"name": "Menaphite red robe"
},
{
"examine": "Colourful.",
"id": 6402,
"members": true,
"lowalch": 8,
"limit": 150,
"value": 20,
"highalch": 12,
"icon": "Menaphite red top.png",
"name": "Menaphite red top"
},
{
"highalch": 1,
"members": true,
"name": "Menaphite remedy(1)",
"examine": "1 dose of Menaphite remedy.",
"id": 27211,
"value": 1,
"icon": "Menaphite remedy(1).png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Menaphite remedy(2)",
"examine": "2 doses of Menaphite remedy.",
"id": 27208,
"value": 1,
"icon": "Menaphite remedy(2).png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Menaphite remedy(3)",
"examine": "3 doses of Menaphite remedy.",
"id": 27205,
"value": 1,
"icon": "Menaphite remedy(3).png",
"lowalch": 1
},
{
"examine": "4 doses of Menaphite remedy.",
"id": 27202,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Menaphite remedy(4).png",
"name": "Menaphite remedy(4)"
},
{
"examine": "An iron tipped trident.",
"id": 21649,
"members": true,
"lowalch": 160,
"limit": 125,
"value": 400,
"highalch": 240,
"icon": "Merfolk trident.png",
"name": "Merfolk trident"
},
{
"examine": "A bowl of finely minced meat.",
"id": 7070,
"members": true,
"lowalch": 3,
"limit": 13000,
"value": 8,
"highalch": 4,
"icon": "Minced meat.png",
"name": "Minced meat"
},
{
"examine": "A teleport to the Mind Altar.",
"id": 19617,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Mind altar teleport (tablet).png",
"name": "Mind altar teleport (tablet)"
},
{
"examine": "This keg contains 4 pints of Wizards Mind Bomb.",
"id": 5801,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Mind bomb(4).png",
"name": "Mind bomb(4)"
},
{
"examine": "This keg contains 4 pints of mature Wizards Mind Bomb.",
"id": 5881,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Mind bomb(m4).png",
"name": "Mind bomb(m4)"
},
{
"examine": "A magic helmet.",
"id": 9733,
"members": true,
"lowalch": 1200,
"limit": 70,
"value": 3000,
"highalch": 1800,
"icon": "Mind helmet.png",
"name": "Mind helmet"
},
{
"examine": "Used for basic level missile spells.",
"id": 558,
"members": false,
"lowalch": 1,
"limit": 18000,
"value": 3,
"highalch": 1,
"icon": "Mind rune.png",
"name": "Mind rune"
},
{
"examine": "A magic shield.",
"id": 9731,
"members": true,
"lowalch": 1200,
"limit": 70,
"value": 3000,
"highalch": 1800,
"icon": "Mind shield.png",
"name": "Mind shield"
},
{
"examine": "A mysterious power emanates from the talisman...",
"id": 1448,
"members": false,
"lowalch": 1,
"limit": 11000,
"value": 4,
"highalch": 2,
"icon": "Mind talisman.png",
"name": "Mind talisman"
},
{
"examine": "A tiara infused with the properties of the mind.",
"id": 5529,
"members": false,
"lowalch": 40,
"limit": 40,
"value": 100,
"highalch": 60,
"icon": "Mind tiara.png",
"name": "Mind tiara"
},
{
"examine": "A helmet with an unlit lamp on it.",
"id": 5014,
"members": true,
"lowalch": 240,
"limit": 4,
"value": 600,
"highalch": 360,
"icon": "Mining helmet.png",
"name": "Mining helmet"
},
{
"examine": "It looks very minty.",
"id": 9475,
"members": true,
"lowalch": 100,
"limit": 10000,
"value": 250,
"highalch": 150,
"icon": "Mint cake.png",
"name": "Mint cake"
},
{
"examine": "I can just about see things in this shield's reflection.",
"id": 4156,
"members": true,
"lowalch": 2000,
"limit": 40,
"value": 5000,
"highalch": 3000,
"icon": "Mirror shield.png",
"name": "Mirror shield"
},
{
"examine": "It's a slightly magical stick.",
"id": 20730,
"members": true,
"lowalch": 6200,
"limit": 8,
"value": 15500,
"highalch": 9300,
"icon": "Mist battlestaff.png",
"name": "Mist battlestaff"
},
{
"examine": "A combined Air and Water Rune.",
"id": 4695,
"members": true,
"lowalch": 8,
"limit": 18000,
"value": 20,
"highalch": 12,
"icon": "Mist rune.png",
"name": "Mist rune"
},
{
"examine": "A mithril grapple tipped bolt - needs a rope.",
"id": 9419,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 1,
"highalch": 1,
"icon": "Mith grapple.png",
"name": "Mith grapple"
},
{
"examine": "A mithril grapple tipped bolt - needs a rope.",
"id": 9418,
"members": true,
"lowalch": 19,
"limit": 11000,
"value": 48,
"highalch": 28,
"icon": "Mith grapple (unf).png",
"name": "Mith grapple (unf)"
},
{
"examine": "A mithril grapple tip.",
"id": 9416,
"members": true,
"lowalch": 12,
"limit": 11000,
"value": 30,
"highalch": 18,
"icon": "Mith grapple tip.png",
"name": "Mith grapple tip"
},
{
"examine": "A two handed sword.",
"id": 1315,
"members": false,
"lowalch": 1040,
"limit": 125,
"value": 2600,
"highalch": 1560,
"icon": "Mithril 2h sword.png",
"name": "Mithril 2h sword"
},
{
"examine": "Arrows with mithril heads.",
"id": 888,
"members": false,
"lowalch": 12,
"limit": 7000,
"value": 32,
"highalch": 19,
"icon": "Mithril arrow 5.png",
"name": "Mithril arrow"
},
{
"examine": "Venomous-looking arrows.",
"id": 889,
"members": true,
"lowalch": 12,
"limit": 7000,
"value": 32,
"highalch": 19,
"icon": "Mithril arrow(p) 5.png",
"name": "Mithril arrow(p)"
},
{
"examine": "Venomous-looking arrows.",
"id": 5619,
"members": true,
"lowalch": 12,
"limit": 7000,
"value": 32,
"highalch": 19,
"icon": "Mithril arrow(p+) 5.png",
"name": "Mithril arrow(p+)"
},
{
"examine": "Venomous-looking arrows.",
"id": 5625,
"members": true,
"lowalch": 12,
"limit": 7000,
"value": 32,
"highalch": 19,
"icon": "Mithril arrow(p++) 5.png",
"name": "Mithril arrow(p++)"
},
{
"examine": "I can make some arrows with these.",
"id": 42,
"members": true,
"lowalch": 6,
"limit": 10000,
"value": 16,
"highalch": 9,
"icon": "Mithril arrowtips.png",
"name": "Mithril arrowtips"
},
{
"examine": "A powerful axe.",
"id": 1355,
"members": false,
"lowalch": 208,
"limit": 40,
"value": 520,
"highalch": 312,
"icon": "Mithril axe.png",
"name": "Mithril axe"
},
{
"examine": "It's a bar of mithril.",
"id": 2359,
"members": false,
"lowalch": 120,
"limit": 10000,
"value": 300,
"highalch": 180,
"icon": "Mithril bar.png",
"name": "Mithril bar"
},
{
"examine": "A vicious looking axe.",
"id": 1369,
"members": false,
"lowalch": 676,
"limit": 125,
"value": 1690,
"highalch": 1014,
"icon": "Mithril battleaxe.png",
"name": "Mithril battleaxe"
},
{
"examine": "Mithril crossbow bolts.",
"id": 9142,
"members": true,
"lowalch": 8,
"limit": 7000,
"value": 20,
"highalch": 12,
"icon": "Mithril bolts 5.png",
"name": "Mithril bolts"
},
{
"examine": "Some poisoned mithril bolts.",
"id": 9289,
"members": true,
"lowalch": 8,
"limit": 11000,
"value": 20,
"highalch": 12,
"icon": "Mithril bolts (p) 5.png",
"name": "Mithril bolts (p)"
},
{
"examine": "Some poisoned mithril bolts.",
"id": 9296,
"members": true,
"lowalch": 8,
"limit": 11000,
"value": 20,
"highalch": 12,
"icon": "Mithril bolts (p+) 5.png",
"name": "Mithril bolts (p+)"
},
{
"examine": "Super poisoned mithril bolts.",
"id": 9303,
"members": true,
"lowalch": 8,
"limit": 11000,
"value": 20,
"highalch": 12,
"icon": "Mithril bolts (p++) 5.png",
"name": "Mithril bolts (p++)"
},
{
"examine": "Unfeathered mithril crossbow bolts.",
"id": 9379,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Mithril bolts (unf) 5.png",
"name": "Mithril bolts (unf)"
},
{
"examine": "These will protect my feet.",
"id": 4127,
"members": true,
"lowalch": 312,
"limit": 125,
"value": 780,
"highalch": 468,
"icon": "Mithril boots.png",
"name": "Mithril boots"
},
{
"examine": "Blunt mithril arrow... ouch.",
"id": 4793,
"members": true,
"lowalch": 20,
"limit": 11000,
"value": 50,
"highalch": 30,
"icon": "Mithril brutal 5.png",
"name": "Mithril brutal"
},
{
"examine": "A series of connected metal rings.",
"id": 1109,
"members": false,
"lowalch": 780,
"limit": 125,
"value": 1950,
"highalch": 1170,
"icon": "Mithril chainbody.png",
"name": "Mithril chainbody"
},
{
"examine": "A set of fighting claws.",
"id": 3099,
"members": true,
"lowalch": 190,
"limit": 125,
"value": 475,
"highalch": 285,
"icon": "Mithril claws.png",
"name": "Mithril claws"
},
{
"examine": "A mithril crossbow.",
"id": 9181,
"members": true,
"lowalch": 313,
"limit": 125,
"value": 783,
"highalch": 469,
"icon": "Mithril crossbow.png",
"name": "Mithril crossbow"
},
{
"examine": "An unstrung mithril crossbow.",
"id": 9461,
"members": true,
"lowalch": 300,
"limit": 10000,
"value": 750,
"highalch": 450,
"icon": "Mithril crossbow (u).png",
"name": "Mithril crossbow (u)"
},
{
"examine": "A dangerous dagger.",
"id": 1209,
"members": false,
"lowalch": 130,
"limit": 125,
"value": 325,
"highalch": 195,
"icon": "Mithril dagger.png",
"name": "Mithril dagger"
},
{
"examine": "A poisoned Mithril dagger.",
"id": 1225,
"members": true,
"lowalch": 130,
"limit": 125,
"value": 325,
"highalch": 195,
"icon": "Mithril dagger(p).png",
"name": "Mithril dagger(p)"
},
{
"examine": "A poisoned Mithril dagger.",
"id": 5674,
"members": true,
"lowalch": 130,
"limit": 125,
"value": 325,
"highalch": 195,
"icon": "Mithril dagger(p+).png",
"name": "Mithril dagger(p+)"
},
{
"examine": "A poisoned Mithril dagger.",
"id": 5692,
"members": true,
"lowalch": 130,
"limit": 125,
"value": 325,
"highalch": 195,
"icon": "Mithril dagger(p++).png",
"name": "Mithril dagger(p++)"
},
{
"examine": "A deadly throwing dart with a mithril tip.",
"id": 809,
"members": true,
"lowalch": 10,
"limit": 7000,
"value": 25,
"highalch": 15,
"icon": "Mithril dart.png",
"name": "Mithril dart"
},
{
"examine": "A deadly looking dart tip made of mithril - needs feathers for flight.",
"id": 822,
"members": true,
"lowalch": 4,
"limit": 20000,
"value": 12,
"highalch": 7,
"icon": "Mithril dart tip.png",
"name": "Mithril dart tip"
},
{
"examine": "A deadly poisoned dart with a mithril tip.",
"id": 815,
"members": true,
"lowalch": 10,
"limit": 7000,
"value": 25,
"highalch": 15,
"icon": "Mithril dart(p).png",
"name": "Mithril dart(p)"
},
{
"examine": "A deadly poisoned dart with a mithril tip.",
"id": 5632,
"members": true,
"lowalch": 10,
"limit": 7000,
"value": 25,
"highalch": 15,
"icon": "Mithril dart(p+).png",
"name": "Mithril dart(p+)"
},
{
"examine": "A deadly poisoned dart with a mithril tip.",
"id": 5639,
"members": true,
"lowalch": 10,
"limit": 7000,
"value": 25,
"highalch": 15,
"icon": "Mithril dart(p++).png",
"name": "Mithril dart(p++)"
},
{
"examine": "Do I look scary?",
"id": 12369,
"members": true,
"lowalch": 4000,
"limit": 4,
"value": 10000,
"highalch": 6000,
"icon": "Mithril dragon mask.png",
"name": "Mithril dragon mask"
},
{
"highalch": 312,
"members": true,
"name": "Mithril felling axe",
"examine": "A woodcutter's axe.",
"id": 28208,
"value": 520,
"icon": "Mithril felling axe.png",
"lowalch": 208
},
{
"examine": "A full face helmet.",
"id": 1159,
"members": false,
"lowalch": 572,
"limit": 125,
"value": 1430,
"highalch": 858,
"icon": "Mithril full helm.png",
"name": "Mithril full helm"
},
{
"examine": "Mithril full helm with gold trim.",
"id": 12283,
"members": false,
"lowalch": 572,
"limit": 8,
"value": 1430,
"highalch": 858,
"icon": "Mithril full helm (g).png",
"name": "Mithril full helm (g)"
},
{
"examine": "Mithril full helm with trim.",
"id": 12293,
"members": false,
"lowalch": 572,
"limit": 8,
"value": 1430,
"highalch": 858,
"icon": "Mithril full helm (t).png",
"name": "Mithril full helm (t)"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 13008,
"members": false,
"lowalch": 2000,
"limit": 8,
"value": 5000,
"highalch": 3000,
"icon": "Mithril gold-trimmed set (lg).png",
"name": "Mithril gold-trimmed set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 13010,
"members": false,
"lowalch": 2000,
"limit": 8,
"value": 5000,
"highalch": 3000,
"icon": "Mithril gold-trimmed set (sk).png",
"name": "Mithril gold-trimmed set (sk)"
},
{
"examine": "A mithril halberd.",
"id": 3198,
"members": true,
"lowalch": 1040,
"limit": 125,
"value": 2600,
"highalch": 1560,
"icon": "Mithril halberd.png",
"name": "Mithril halberd"
},
{
"examine": "A mithril-tipped, one-handed hasta.",
"id": 11373,
"members": true,
"lowalch": 338,
"limit": 125,
"value": 845,
"highalch": 507,
"icon": "Mithril hasta.png",
"name": "Mithril hasta"
},
{
"examine": "A poison-tipped, one-handed mithril hasta.",
"id": 11400,
"members": true,
"lowalch": 338,
"limit": 125,
"value": 845,
"highalch": 507,
"icon": "Mithril hasta(p).png",
"name": "Mithril hasta(p)"
},
{
"examine": "A poison-tipped, one-handed mithril hasta.",
"id": 11403,
"members": true,
"lowalch": 338,
"limit": 125,
"value": 845,
"highalch": 507,
"icon": "Mithril hasta(p+).png",
"name": "Mithril hasta(p+)"
},
{
"examine": "A poison-tipped, one-handed mithril hasta.",
"id": 11405,
"members": true,
"lowalch": 338,
"limit": 125,
"value": 845,
"highalch": 507,
"icon": "Mithril hasta(p++).png",
"name": "Mithril hasta(p++)"
},
{
"examine": "A mithril tipped javelin.",
"id": 828,
"members": true,
"lowalch": 25,
"limit": 7000,
"value": 64,
"highalch": 38,
"icon": "Mithril javelin.png",
"name": "Mithril javelin"
},
{
"examine": "Needs a shaft.",
"id": 19576,
"members": true,
"lowalch": 66,
"limit": 10000,
"value": 165,
"highalch": 99,
"icon": "Mithril javelin heads 5.png",
"name": "Mithril javelin heads"
},
{
"examine": "A mithril tipped javelin.",
"id": 834,
"members": true,
"lowalch": 25,
"limit": 7000,
"value": 64,
"highalch": 38,
"icon": "Mithril javelin(p).png",
"name": "Mithril javelin(p)"
},
{
"examine": "A mithril tipped javelin.",
"id": 5645,
"members": true,
"lowalch": 25,
"limit": 7000,
"value": 64,
"highalch": 38,
"icon": "Mithril javelin(p+).png",
"name": "Mithril javelin(p+)"
},
{
"examine": "A mithril tipped javelin.",
"id": 5651,
"members": true,
"lowalch": 25,
"limit": 7000,
"value": 64,
"highalch": 38,
"icon": "Mithril javelin(p++).png",
"name": "Mithril javelin(p++)"
},
{
"examine": "A large metal shield.",
"id": 1197,
"members": false,
"lowalch": 884,
"limit": 125,
"value": 2210,
"highalch": 1326,
"icon": "Mithril kiteshield.png",
"name": "Mithril kiteshield"
},
{
"examine": "Mithril kiteshield with gold trim.",
"id": 12281,
"members": false,
"lowalch": 884,
"limit": 8,
"value": 2210,
"highalch": 1326,
"icon": "Mithril kiteshield (g).png",
"name": "Mithril kiteshield (g)"
},
{
"examine": "Mithril kiteshield with trim.",
"id": 12291,
"members": false,
"lowalch": 884,
"limit": 8,
"value": 2210,
"highalch": 1326,
"icon": "Mithril kiteshield (t).png",
"name": "Mithril kiteshield (t)"
},
{
"examine": "A finely balanced throwing knife.",
"id": 866,
"members": true,
"lowalch": 10,
"limit": 7000,
"value": 27,
"highalch": 16,
"icon": "Mithril knife.png",
"name": "Mithril knife"
},
{
"examine": "A finely balanced throwing knife.",
"id": 873,
"members": true,
"lowalch": 10,
"limit": 7000,
"value": 27,
"highalch": 16,
"icon": "Mithril knife(p).png",
"name": "Mithril knife(p)"
},
{
"examine": "A finely balanced throwing knife.",
"id": 5657,
"members": true,
"lowalch": 10,
"limit": 7000,
"value": 27,
"highalch": 16,
"icon": "Mithril knife(p+).png",
"name": "Mithril knife(p+)"
},
{
"examine": "A finely balanced throwing knife.",
"id": 5664,
"members": true,
"lowalch": 10,
"limit": 7000,
"value": 27,
"highalch": 16,
"icon": "Mithril knife(p++).png",
"name": "Mithril knife(p++)"
},
{
"examine": "A pair of mithril crossbow limbs.",
"id": 9427,
"members": true,
"lowalch": 260,
"limit": 10000,
"value": 650,
"highalch": 390,
"icon": "Mithril limbs.png",
"name": "Mithril limbs"
},
{
"examine": "A razor sharp longsword.",
"id": 1299,
"members": false,
"lowalch": 520,
"limit": 125,
"value": 1300,
"highalch": 780,
"icon": "Mithril longsword.png",
"name": "Mithril longsword"
},
{
"examine": "A spiky mace.",
"id": 1428,
"members": false,
"lowalch": 234,
"limit": 125,
"value": 585,
"highalch": 351,
"icon": "Mithril mace.png",
"name": "Mithril mace"
},
{
"examine": "A medium sized helmet.",
"id": 1143,
"members": false,
"lowalch": 312,
"limit": 125,
"value": 780,
"highalch": 468,
"icon": "Mithril med helm.png",
"name": "Mithril med helm"
},
{
"examine": "Keeps things in place fairly permanently.",
"id": 4822,
"members": true,
"lowalch": 7,
"limit": 13000,
"value": 18,
"highalch": 10,
"icon": "Mithril nails.png",
"name": "Mithril nails"
},
{
"examine": "This needs refining.",
"id": 447,
"members": false,
"lowalch": 64,
"limit": 13000,
"value": 162,
"highalch": 97,
"icon": "Mithril ore.png",
"name": "Mithril ore"
},
{
"examine": "Used for mining.",
"id": 1273,
"members": false,
"lowalch": 520,
"limit": 40,
"value": 1300,
"highalch": 780,
"icon": "Mithril pickaxe.png",
"name": "Mithril pickaxe"
},
{
"examine": "Provides excellent protection.",
"id": 1121,
"members": false,
"lowalch": 2080,
"limit": 125,
"value": 5200,
"highalch": 3120,
"icon": "Mithril platebody.png",
"name": "Mithril platebody"
},
{
"examine": "Mithril platebody with gold trim.",
"id": 12277,
"members": false,
"lowalch": 2080,
"limit": 8,
"value": 5200,
"highalch": 3120,
"icon": "Mithril platebody (g).png",
"name": "Mithril platebody (g)"
},
{
"examine": "Mithril platebody with trim.",
"id": 12287,
"members": false,
"lowalch": 2080,
"limit": 8,
"value": 5200,
"highalch": 3120,
"icon": "Mithril platebody (t).png",
"name": "Mithril platebody (t)"
},
{
"examine": "These look pretty heavy.",
"id": 1071,
"members": false,
"lowalch": 1040,
"limit": 125,
"value": 2600,
"highalch": 1560,
"icon": "Mithril platelegs.png",
"name": "Mithril platelegs"
},
{
"examine": "Mithril platelegs with gold trim.",
"id": 12279,
"members": false,
"lowalch": 1040,
"limit": 8,
"value": 2600,
"highalch": 1560,
"icon": "Mithril platelegs (g).png",
"name": "Mithril platelegs (g)"
},
{
"examine": "Mithril platelegs with trim.",
"id": 12289,
"members": false,
"lowalch": 1040,
"limit": 8,
"value": 2600,
"highalch": 1560,
"icon": "Mithril platelegs (t).png",
"name": "Mithril platelegs (t)"
},
{
"examine": "Designer leg protection.",
"id": 1085,
"members": false,
"lowalch": 1040,
"limit": 125,
"value": 2600,
"highalch": 1560,
"icon": "Mithril plateskirt.png",
"name": "Mithril plateskirt"
},
{
"examine": "Mithril plateskirt with gold trim.",
"id": 12285,
"members": false,
"lowalch": 1040,
"limit": 8,
"value": 2600,
"highalch": 1560,
"icon": "Mithril plateskirt (g).png",
"name": "Mithril plateskirt (g)"
},
{
"examine": "Mithril plateskirt with trim.",
"id": 12295,
"members": false,
"lowalch": 1040,
"limit": 8,
"value": 2600,
"highalch": 1560,
"icon": "Mithril plateskirt (t).png",
"name": "Mithril plateskirt (t)"
},
{
"examine": "A vicious, curved sword.",
"id": 1329,
"members": false,
"lowalch": 416,
"limit": 125,
"value": 1040,
"highalch": 624,
"icon": "Mithril scimitar.png",
"name": "Mithril scimitar"
},
{
"examine": "Magical seeds in a mithril case.",
"id": 299,
"members": true,
"lowalch": 80,
"limit": 18000,
"value": 200,
"highalch": 120,
"icon": "Mithril seeds.png",
"name": "Mithril seeds"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 13000,
"members": false,
"lowalch": 2000,
"limit": 8,
"value": 5000,
"highalch": 3000,
"icon": "Mithril set (lg).png",
"name": "Mithril set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 13002,
"members": false,
"lowalch": 2000,
"limit": 8,
"value": 5000,
"highalch": 3000,
"icon": "Mithril set (sk).png",
"name": "Mithril set (sk)"
},
{
"examine": "A mithril tipped spear.",
"id": 1243,
"members": true,
"lowalch": 338,
"limit": 125,
"value": 845,
"highalch": 507,
"icon": "Mithril spear.png",
"name": "Mithril spear"
},
{
"examine": "A poisoned mithril tipped spear.",
"id": 1257,
"members": true,
"lowalch": 338,
"limit": 125,
"value": 845,
"highalch": 507,
"icon": "Mithril spear(p).png",
"name": "Mithril spear(p)"
},
{
"examine": "A poisoned mithril tipped spear.",
"id": 5710,
"members": true,
"lowalch": 338,
"limit": 125,
"value": 845,
"highalch": 507,
"icon": "Mithril spear(p+).png",
"name": "Mithril spear(p+)"
},
{
"examine": "A poisoned mithril tipped spear.",
"id": 5724,
"members": true,
"lowalch": 338,
"limit": 125,
"value": 845,
"highalch": 507,
"icon": "Mithril spear(p++).png",
"name": "Mithril spear(p++)"
},
{
"examine": "A medium square shield.",
"id": 1181,
"members": false,
"lowalch": 624,
"limit": 125,
"value": 1560,
"highalch": 936,
"icon": "Mithril sq shield.png",
"name": "Mithril sq shield"
},
{
"examine": "A razor sharp sword.",
"id": 1285,
"members": false,
"lowalch": 338,
"limit": 125,
"value": 845,
"highalch": 507,
"icon": "Mithril sword.png",
"name": "Mithril sword"
},
{
"examine": "A finely balanced throwing axe.",
"id": 803,
"members": true,
"lowalch": 28,
"limit": 7000,
"value": 70,
"highalch": 42,
"icon": "Mithril thrownaxe.png",
"name": "Mithril thrownaxe"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 13004,
"members": false,
"lowalch": 2000,
"limit": 8,
"value": 5000,
"highalch": 3000,
"icon": "Mithril trimmed set (lg).png",
"name": "Mithril trimmed set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 13006,
"members": false,
"lowalch": 2000,
"limit": 8,
"value": 5000,
"highalch": 3000,
"icon": "Mithril trimmed set (sk).png",
"name": "Mithril trimmed set (sk)"
},
{
"examine": "I don't think it's intended for joinery.",
"id": 1343,
"members": false,
"lowalch": 664,
"limit": 125,
"value": 1660,
"highalch": 996,
"icon": "Mithril warhammer.png",
"name": "Mithril warhammer"
},
{
"examine": "A posy of flowers.",
"id": 2472,
"members": true,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Mixed flowers.png",
"name": "Mixed flowers"
},
{
"highalch": 5400,
"members": true,
"name": "Mixed hide base",
"examine": "I need this to make mixed hide armour.",
"id": 29292,
"value": 9000,
"icon": "Mixed hide base.png",
"lowalch": 3600
},
{
"highalch": 2400,
"members": true,
"name": "Mixed hide boots",
"examine": "Made from the hide of a few different creatures.",
"id": 29286,
"value": 4000,
"icon": "Mixed hide boots.png",
"lowalch": 1600
},
{
"highalch": 2400,
"members": true,
"name": "Mixed hide cape",
"examine": "Made from the hide of a few different creatures.",
"id": 29289,
"value": 4000,
"icon": "Mixed hide cape.png",
"lowalch": 1600
},
{
"highalch": 2400,
"members": true,
"name": "Mixed hide legs",
"examine": "Made from the hide of a few different creatures.",
"id": 29283,
"value": 4000,
"icon": "Mixed hide legs.png",
"lowalch": 1600
},
{
"highalch": 2400,
"members": true,
"name": "Mixed hide top",
"examine": "Made from the hide of a few different creatures.",
"id": 29280,
"value": 4000,
"icon": "Mixed hide top.png",
"lowalch": 1600
},
{
"examine": "A mole claw.",
"id": 7416,
"members": true,
"lowalch": 240,
"limit": 50,
"value": 600,
"highalch": 360,
"icon": "Mole claw.png",
"name": "Mole claw"
},
{
"examine": "The skin of a large mole.",
"id": 7418,
"members": true,
"lowalch": 160,
"limit": 50,
"value": 400,
"highalch": 240,
"icon": "Mole skin.png",
"name": "Mole skin"
},
{
"examine": "Cute mole slippers.",
"id": 23285,
"members": false,
"lowalch": 400,
"limit": 4,
"value": 1000,
"highalch": 600,
"icon": "Mole slippers.png",
"name": "Mole slippers"
},
{
"examine": "Hot glass ready to be blown into useful objects.",
"id": 1775,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 3,
"highalch": 1,
"icon": "Molten glass.png",
"name": "Molten glass"
},
{
"examine": "Keeps a monk's knees nice and warm.",
"id": 542,
"members": false,
"lowalch": 12,
"limit": 125,
"value": 30,
"highalch": 18,
"icon": "Monk's robe.png",
"name": "Monk's robe"
},
{
"examine": "Keeps a monk's knees nice, warm and stylish.",
"id": 20202,
"members": false,
"lowalch": 12,
"limit": 4,
"value": 30,
"highalch": 18,
"icon": "Monk's robe (g).png",
"name": "Monk's robe (g)"
},
{
"examine": "I feel the gods don't enjoy my materialistic obsessions.",
"id": 23306,
"members": false,
"lowalch": 200,
"limit": 4,
"value": 500,
"highalch": 300,
"icon": "Monk's robe (t).png",
"name": "Monk's robe (t)"
},
{
"examine": "I feel closer to the gods when I am wearing this.",
"id": 544,
"members": false,
"lowalch": 16,
"limit": 125,
"value": 40,
"highalch": 24,
"icon": "Monk's robe top.png",
"name": "Monk's robe top"
},
{
"examine": "I feel the gods don't enjoy my materialistic obsessions.",
"id": 20199,
"members": false,
"lowalch": 20,
"limit": 4,
"value": 50,
"highalch": 30,
"icon": "Monk's robe top (g).png",
"name": "Monk's robe top (g)"
},
{
"examine": "I feel the gods don't enjoy my materialistic obsessions.",
"id": 23303,
"members": false,
"lowalch": 200,
"limit": 4,
"value": 500,
"highalch": 300,
"icon": "Monk's robe top (t).png",
"name": "Monk's robe top (t)"
},
{
"examine": "It's a monkey bar. It looks highly nutritious.",
"id": 4014,
"members": true,
"lowalch": 20,
"limit": 6000,
"value": 50,
"highalch": 30,
"icon": "Monkey bar.png",
"name": "Monkey bar"
},
{
"examine": "These are small monkey bones.",
"id": 3183,
"members": false,
"lowalch": 1,
"limit": 3000,
"value": 1,
"highalch": 1,
"icon": "Monkey bones.png",
"name": "Monkey bones"
},
{
"examine": "These are monkey nuts. Yummy.",
"id": 4012,
"members": true,
"lowalch": 1,
"limit": 6000,
"value": 3,
"highalch": 1,
"icon": "Monkey nuts.png",
"name": "Monkey nuts"
},
{
"examine": "Smells like the south end of a northbound monkey, because it is.",
"id": 19610,
"members": true,
"lowalch": 20000,
"limit": 8,
"value": 50000,
"highalch": 30000,
"icon": "Monkey tail.png",
"name": "Monkey tail"
},
{
"examine": "A tasty fish.",
"id": 7946,
"members": true,
"lowalch": 92,
"limit": 13000,
"value": 230,
"highalch": 138,
"icon": "Monkfish.png",
"name": "Monkfish"
},
{
"examine": "Hmm, shallow and pedantic.",
"id": 12353,
"members": true,
"lowalch": 480,
"limit": 4,
"value": 1200,
"highalch": 720,
"icon": "Monocle.png",
"name": "Monocle"
},
{
"highalch": 9,
"members": true,
"name": "Moon-lite",
"examine": "A popular spirit all across Varlamore.",
"id": 29418,
"value": 15,
"icon": "Moon-lite.png",
"lowalch": 6
},
{
"examine": "A teleport to Lunar Isle.",
"id": 24949,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Moonclan teleport (tablet).png",
"name": "Moonclan teleport (tablet)"
},
{
"highalch": 1,
"members": true,
"name": "Moonlight antelope antler",
"examine": "I don't think I can stick it back on.",
"id": 29171,
"value": 1,
"icon": "Moonlight antelope antler.png",
"lowalch": 1
},
{
"highalch": 6,
"members": true,
"name": "Moonlight antelope fur",
"examine": "I can make something out of this.",
"id": 29174,
"value": 10,
"icon": "Moonlight antelope fur.png",
"lowalch": 4
},
{
"examine": "Bolts made from the antlers of a moonlight antelope.",
"id": 28878,
"members": true,
"lowalch": 12,
"limit": 11000,
"value": 31,
"highalch": 18,
"icon": "Moonlight antler bolts.png",
"name": "Moonlight antler bolts"
},
{
"examine": "A foul smelling brew.",
"id": 2955,
"members": true,
"lowalch": 2,
"limit": 2000,
"value": 5,
"highalch": 3,
"icon": "Moonlight mead.png",
"name": "Moonlight mead"
},
{
"examine": "This keg contains 4 pints of Moonlight Mead.",
"id": 5817,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Moonlight mead(4).png",
"name": "Moonlight mead(4)"
},
{
"examine": "This looks a good deal stronger than normal Moonlight Mead.",
"id": 5749,
"members": true,
"lowalch": 2,
"limit": 2000,
"value": 5,
"highalch": 3,
"icon": "Moonlight mead(m).png",
"name": "Moonlight mead(m)"
},
{
"examine": "This keg contains 4 pints of mature Moonlight Mead.",
"id": 5897,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Moonlight mead(m4).png",
"name": "Moonlight mead(m4)"
},
{
"examine": "There's a moonlight moth in here.",
"id": 28893,
"members": true,
"lowalch": 1,
"limit": 125,
"value": 1,
"highalch": 1,
"icon": "Moonlight moth (item).png",
"name": "Moonlight moth (item)"
},
{
"highalch": 18,
"members": true,
"name": "Moonlight moth mix (1)",
"examine": "There's a moonlight moth in here.",
"id": 29213,
"value": 30,
"icon": "Moonlight moth mix (1).png",
"lowalch": 12
},
{
"examine": "There's a moonlight moth in here.",
"id": 29195,
"members": true,
"lowalch": 12,
"limit": 125,
"value": 30,
"highalch": 18,
"icon": "Moonlight moth mix (2).png",
"name": "Moonlight moth mix (2)"
},
{
"examine": "A vicious javelin.",
"id": 22636,
"members": true,
"lowalch": 1600,
"limit": 100,
"value": 4000,
"highalch": 2400,
"icon": "Morrigan's javelin.png",
"name": "Morrigan's javelin"
},
{
"examine": "A vicious throwing axe.",
"id": 22634,
"members": true,
"lowalch": 1600,
"limit": 100,
"value": 4000,
"highalch": 2400,
"icon": "Morrigan's throwing axe.png",
"name": "Morrigan's throwing axe"
},
{
"examine": "A mushroom from the swamps of Mort Myre.",
"id": 2970,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Mort myre fungus.png",
"name": "Mort myre fungus"
},
{
"examine": "A pear picked from a dying bush in Mort Myre.",
"id": 2974,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Mort myre pear.png",
"name": "Mort myre pear"
},
{
"examine": "A cutting from a budding branch.",
"id": 2972,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Mort myre stem.png",
"name": "Mort myre stem"
},
{
"examine": "Teleports you to Mort'ton.",
"id": 12406,
"members": true,
"lowalch": 4,
"limit": 10000,
"value": 10,
"highalch": 6,
"icon": "Mort'ton teleport.png",
"name": "Mort'ton teleport"
},
{
"examine": "Teleports you to Mos Le'Harmless.",
"id": 12411,
"members": true,
"lowalch": 4,
"limit": 10000,
"value": 10,
"highalch": 6,
"icon": "Mos le'harmless teleport.png",
"name": "Mos le'harmless teleport"
},
{
"examine": "A very spotty eel.",
"id": 22832,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 3,
"highalch": 1,
"icon": "Mottled eel.png",
"name": "Mottled eel"
},
{
"examine": "It's a slightly magical stick.",
"id": 6562,
"members": true,
"lowalch": 6800,
"limit": 8,
"value": 17000,
"highalch": 10200,
"icon": "Mud battlestaff.png",
"name": "Mud battlestaff"
},
{
"examine": "All the good of the earth.",
"id": 7170,
"members": true,
"lowalch": 21,
"limit": 11000,
"value": 54,
"highalch": 32,
"icon": "Mud pie.png",
"name": "Mud pie"
},
{
"examine": "A combined Earth and Water Rune.",
"id": 4698,
"members": true,
"lowalch": 8,
"limit": 18000,
"value": 20,
"highalch": 12,
"icon": "Mud rune.png",
"name": "Mud rune"
},
{
"examine": "It looks like the key to a chest.",
"id": 991,
"members": false,
"lowalch": 32,
"limit": 11000,
"value": 80,
"highalch": 48,
"icon": "Muddy key.png",
"name": "Muddy key"
},
{
"examine": "A mummy's body.",
"id": 20083,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Mummy's body.png",
"name": "Mummy's body"
},
{
"examine": "A mummy's feet.",
"id": 20092,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Mummy's feet.png",
"name": "Mummy's feet"
},
{
"examine": "A mummy's hands.",
"id": 20086,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Mummy's hands.png",
"name": "Mummy's hands"
},
{
"examine": "A mummy's head.",
"id": 20080,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Mummy's head.png",
"name": "Mummy's head"
},
{
"examine": "A mummy's legs.",
"id": 20089,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Mummy's legs.png",
"name": "Mummy's legs"
},
{
"examine": "A Bittercap Mushroom",
"id": 6004,
"members": true,
"lowalch": 15,
"limit": 11000,
"value": 38,
"highalch": 22,
"icon": "Mushroom.png",
"name": "Mushroom"
},
{
"examine": "A bowl of fried mushroom and onions.",
"id": 7066,
"members": true,
"lowalch": 18,
"limit": 13000,
"value": 45,
"highalch": 27,
"icon": "Mushroom & onion.png",
"name": "Mushroom & onion"
},
{
"examine": "Mmm mushroom pie.",
"id": 21690,
"members": true,
"lowalch": 12,
"limit": 10000,
"value": 30,
"highalch": 18,
"icon": "Mushroom pie.png",
"name": "Mushroom pie"
},
{
"examine": "A baked potato with mushroom and onions.",
"id": 7058,
"members": true,
"lowalch": 18,
"limit": 13000,
"value": 45,
"highalch": 27,
"icon": "Mushroom potato.png",
"name": "Mushroom potato"
},
{
"examine": "A Bittercap mushroom spore - plant in a mushroom patch.",
"id": 5282,
"members": true,
"lowalch": 34,
"limit": 200,
"value": 86,
"highalch": 51,
"icon": "Mushroom spore 5.png",
"name": "Mushroom spore"
},
{
"examine": "Engarde!",
"id": 12351,
"members": true,
"lowalch": 1400,
"limit": 4,
"value": 3500,
"highalch": 2100,
"icon": "Musketeer hat.png",
"name": "Musketeer hat"
},
{
"examine": "One for all!",
"id": 12443,
"members": true,
"lowalch": 748,
"limit": 4,
"value": 1870,
"highalch": 1122,
"icon": "Musketeer pants.png",
"name": "Musketeer pants"
},
{
"examine": "All for one!",
"id": 12441,
"members": true,
"lowalch": 752,
"limit": 4,
"value": 1880,
"highalch": 1128,
"icon": "Musketeer tabard.png",
"name": "Musketeer tabard"
},
{
"examine": "A swamp coloured pointed snail shell helmet.",
"id": 3337,
"members": true,
"lowalch": 120,
"limit": 150,
"value": 300,
"highalch": 180,
"icon": "Myre snelm (pointed).png",
"name": "Myre snelm (pointed)"
},
{
"examine": "A marshy coloured snail shell helmet.",
"id": 3327,
"members": true,
"lowalch": 120,
"limit": 150,
"value": 300,
"highalch": 180,
"icon": "Myre snelm (round).png",
"name": "Myre snelm (round)"
},
{
"examine": "It's a slightly magical stick.",
"id": 1405,
"members": true,
"lowalch": 17000,
"limit": 18000,
"value": 42500,
"highalch": 25500,
"icon": "Mystic air staff.png",
"name": "Mystic air staff"
},
{
"examine": "Magical boots.",
"id": 4097,
"members": true,
"lowalch": 4000,
"limit": 125,
"value": 10000,
"highalch": 6000,
"icon": "Mystic boots.png",
"name": "Mystic boots"
},
{
"examine": "Dark magical boots.",
"id": 4107,
"members": true,
"lowalch": 4000,
"limit": 70,
"value": 10000,
"highalch": 6000,
"icon": "Mystic boots (dark).png",
"name": "Mystic boots (dark)"
},
{
"examine": "Dusky magical boots.",
"id": 23059,
"members": true,
"lowalch": 4000,
"limit": 70,
"value": 10000,
"highalch": 6000,
"icon": "Mystic boots (dusk).png",
"name": "Mystic boots (dusk)"
},
{
"examine": "Bright magical boots.",
"id": 4117,
"members": true,
"lowalch": 4000,
"limit": 70,
"value": 10000,
"highalch": 6000,
"icon": "Mystic boots (light).png",
"name": "Mystic boots (light)"
},
{
"examine": "It's a slightly more magical stick.",
"id": 20739,
"members": true,
"lowalch": 17000,
"limit": 8,
"value": 42500,
"highalch": 25500,
"icon": "Mystic dust staff.png",
"name": "Mystic dust staff"
},
{
"examine": "It's a slightly magical stick.",
"id": 1407,
"members": true,
"lowalch": 17000,
"limit": 18000,
"value": 42500,
"highalch": 25500,
"icon": "Mystic earth staff.png",
"name": "Mystic earth staff"
},
{
"examine": "It's a slightly magical stick.",
"id": 1401,
"members": true,
"lowalch": 17000,
"limit": 18000,
"value": 42500,
"highalch": 25500,
"icon": "Mystic fire staff.png",
"name": "Mystic fire staff"
},
{
"examine": "Magical gloves.",
"id": 4095,
"members": true,
"lowalch": 4000,
"limit": 125,
"value": 10000,
"highalch": 6000,
"icon": "Mystic gloves.png",
"name": "Mystic gloves"
},
{
"examine": "Dark magical gloves.",
"id": 4105,
"members": true,
"lowalch": 4000,
"limit": 70,
"value": 10000,
"highalch": 6000,
"icon": "Mystic gloves (dark).png",
"name": "Mystic gloves (dark)"
},
{
"examine": "Dusky magical gloves.",
"id": 23056,
"members": true,
"lowalch": 4000,
"limit": 70,
"value": 10000,
"highalch": 6000,
"icon": "Mystic gloves (dusk).png",
"name": "Mystic gloves (dusk)"
},
{
"examine": "Bright magical gloves.",
"id": 4115,
"members": true,
"lowalch": 4000,
"limit": 70,
"value": 10000,
"highalch": 6000,
"icon": "Mystic gloves (light).png",
"name": "Mystic gloves (light)"
},
{
"examine": "A magical hat.",
"id": 4089,
"members": true,
"lowalch": 6000,
"limit": 125,
"value": 15000,
"highalch": 9000,
"icon": "Mystic hat.png",
"name": "Mystic hat"
},
{
"examine": "A dark magical hat.",
"id": 4099,
"members": true,
"lowalch": 6000,
"limit": 70,
"value": 15000,
"highalch": 9000,
"icon": "Mystic hat (dark).png",
"name": "Mystic hat (dark)"
},
{
"examine": "A dusky magical hat.",
"id": 23047,
"members": true,
"lowalch": 6000,
"limit": 70,
"value": 15000,
"highalch": 9000,
"icon": "Mystic hat (dusk).png",
"name": "Mystic hat (dusk)"
},
{
"examine": "A bright magical hat.",
"id": 4109,
"members": true,
"lowalch": 6000,
"limit": 70,
"value": 15000,
"highalch": 9000,
"icon": "Mystic hat (light).png",
"name": "Mystic hat (light)"
},
{
"examine": "It's a slightly magical stick.",
"id": 3054,
"members": true,
"lowalch": 18000,
"limit": 8,
"value": 45000,
"highalch": 27000,
"icon": "Mystic lava staff.png",
"name": "Mystic lava staff"
},
{
"examine": "It's a slightly more magical stick.",
"id": 20733,
"members": true,
"lowalch": 17000,
"limit": 8,
"value": 42500,
"highalch": 25500,
"icon": "Mystic mist staff.png",
"name": "Mystic mist staff"
},
{
"examine": "It's a slightly magical stick.",
"id": 6563,
"members": true,
"lowalch": 18000,
"limit": 8,
"value": 45000,
"highalch": 27000,
"icon": "Mystic mud staff.png",
"name": "Mystic mud staff"
},
{
"examine": "The lower half of a magical robe.",
"id": 4093,
"members": true,
"lowalch": 32000,
"limit": 125,
"value": 80000,
"highalch": 48000,
"icon": "Mystic robe bottom.png",
"name": "Mystic robe bottom"
},
{
"examine": "The lower half of a dark magical robe.",
"id": 4103,
"members": true,
"lowalch": 32000,
"limit": 70,
"value": 80000,
"highalch": 48000,
"icon": "Mystic robe bottom (dark).png",
"name": "Mystic robe bottom (dark)"
},
{
"examine": "The lower half of a dusky magical robe.",
"id": 23053,
"members": true,
"lowalch": 32000,
"limit": 70,
"value": 80000,
"highalch": 48000,
"icon": "Mystic robe bottom (dusk).png",
"name": "Mystic robe bottom (dusk)"
},
{
"examine": "The lower half of a bright magical robe.",
"id": 4113,
"members": true,
"lowalch": 32000,
"limit": 70,
"value": 80000,
"highalch": 48000,
"icon": "Mystic robe bottom (light).png",
"name": "Mystic robe bottom (light)"
},
{
"examine": "The upper half of a magical robe.",
"id": 4091,
"members": true,
"lowalch": 48000,
"limit": 125,
"value": 120000,
"highalch": 72000,
"icon": "Mystic robe top.png",
"name": "Mystic robe top"
},
{
"examine": "The upper half of a dark magical robe.",
"id": 4101,
"members": true,
"lowalch": 48000,
"limit": 70,
"value": 120000,
"highalch": 72000,
"icon": "Mystic robe top (dark).png",
"name": "Mystic robe top (dark)"
},
{
"examine": "The upper half of a dusky magical robe.",
"id": 23050,
"members": true,
"lowalch": 48000,
"limit": 70,
"value": 120000,
"highalch": 72000,
"icon": "Mystic robe top (dusk).png",
"name": "Mystic robe top (dusk)"
},
{
"examine": "The upper half of a bright magical robe.",
"id": 4111,
"members": true,
"lowalch": 48000,
"limit": 70,
"value": 120000,
"highalch": 72000,
"icon": "Mystic robe top (light).png",
"name": "Mystic robe top (light)"
},
{
"examine": "A set containing blue mystic hat, robe top and bottom, gloves and boots.",
"id": 23113,
"members": true,
"lowalch": 94000,
"limit": 70,
"value": 235000,
"highalch": 141000,
"icon": "Mystic set (blue).png",
"name": "Mystic set (blue)"
},
{
"examine": "A set containing dark mystic hat, robe top and bottom, gloves and boots.",
"id": 23116,
"members": true,
"lowalch": 94000,
"limit": 70,
"value": 235000,
"highalch": 141000,
"icon": "Mystic set (dark).png",
"name": "Mystic set (dark)"
},
{
"examine": "A set containing dusk mystic hat, robe top and bottom, gloves and boots.",
"id": 23119,
"members": true,
"lowalch": 94000,
"limit": 70,
"value": 235000,
"highalch": 141000,
"icon": "Mystic set (dusk).png",
"name": "Mystic set (dusk)"
},
{
"examine": "A set containing light mystic hat, robe top and bottom, gloves and boots.",
"id": 23110,
"members": true,
"lowalch": 94000,
"limit": 70,
"value": 235000,
"highalch": 141000,
"icon": "Mystic set (light).png",
"name": "Mystic set (light)"
},
{
"examine": "It gives a 10% accuracy and damage bonus for spells on the standard spellbook.",
"id": 12000,
"members": true,
"lowalch": 17000,
"limit": 8,
"value": 42500,
"highalch": 25500,
"icon": "Mystic smoke staff.png",
"name": "Mystic smoke staff"
},
{
"examine": "It's a slightly magical stick.",
"id": 11789,
"members": true,
"lowalch": 18000,
"limit": 8,
"value": 45000,
"highalch": 27000,
"icon": "Mystic steam staff.png",
"name": "Mystic steam staff"
},
{
"examine": "It's a slightly magical stick.",
"id": 1403,
"members": true,
"lowalch": 17000,
"limit": 18000,
"value": 42500,
"highalch": 25500,
"icon": "Mystic water staff.png",
"name": "Mystic water staff"
},
{
"examine": "The claws from a nail beast.",
"id": 10937,
"members": true,
"lowalch": 120,
"limit": 11000,
"value": 300,
"highalch": 180,
"icon": "Nail beast nails.png",
"name": "Nail beast nails"
},
{
"examine": "Teleports you to Nardah.",
"id": 12402,
"members": true,
"lowalch": 4,
"limit": 10000,
"value": 10,
"highalch": 6,
"icon": "Nardah teleport.png",
"name": "Nardah teleport"
},
{
"examine": "A nasturtium seed - plant in a flower patch.",
"id": 5098,
"members": true,
"lowalch": 4,
"limit": 200,
"value": 11,
"highalch": 6,
"icon": "Nasturtium seed 5.png",
"name": "Nasturtium seed"
},
{
"examine": "A bunch of nasturtiums.",
"id": 6012,
"members": true,
"lowalch": 1,
"limit": 600,
"value": 4,
"highalch": 2,
"icon": "Nasturtiums.png",
"name": "Nasturtiums"
},
{
"examine": "Nature impling in a jar.",
"id": 11250,
"members": true,
"lowalch": 20,
"limit": 18000,
"value": 50,
"highalch": 30,
"icon": "Nature impling jar.png",
"name": "Nature impling jar"
},
{
"highalch": 1,
"members": true,
"name": "Nature offerings",
"examine": "A sacrifice to nature in the hope of an increase of yield in logs.",
"id": 28152,
"value": 1,
"icon": "Nature offerings.png",
"lowalch": 1
},
{
"examine": "Used for alchemy spells.",
"id": 561,
"members": false,
"lowalch": 72,
"limit": 18000,
"value": 180,
"highalch": 108,
"icon": "Nature rune.png",
"name": "Nature rune"
},
{
"examine": "A mysterious power emanates from the talisman...",
"id": 1462,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 4,
"highalch": 2,
"icon": "Nature talisman.png",
"name": "Nature talisman"
},
{
"examine": "A tiara infused with the properties of nature.",
"id": 5541,
"members": true,
"lowalch": 40,
"limit": 40,
"value": 100,
"highalch": 60,
"icon": "Nature tiara.png",
"name": "Nature tiara"
},
{
"examine": "All for one and one for all!",
"id": 12325,
"members": true,
"lowalch": 80,
"limit": 4,
"value": 200,
"highalch": 120,
"icon": "Navy cavalier.png",
"name": "Navy cavalier"
},
{
"examine": "Used to make necklaces.",
"id": 1597,
"members": false,
"lowalch": 2,
"limit": 40,
"value": 5,
"highalch": 3,
"icon": "Necklace mould.png",
"name": "Necklace mould"
},
{
"examine": "A deep sense of anguish burns within this powerful necklace.",
"id": 19547,
"members": true,
"lowalch": 80800,
"limit": 8,
"value": 202000,
"highalch": 121200,
"icon": "Necklace of anguish.png",
"name": "Necklace of anguish"
},
{
"examine": "Can grant me prayer in an emergency.",
"id": 21157,
"members": true,
"lowalch": 570,
"limit": 10000,
"value": 1425,
"highalch": 855,
"icon": "Necklace of faith.png",
"name": "Necklace of faith"
},
{
"examine": "This necklace can really take you places.",
"id": 21146,
"members": true,
"lowalch": 510,
"limit": 10000,
"value": 1275,
"highalch": 765,
"icon": "Necklace of passage.png",
"name": "Necklace of passage(5)"
},
{
"examine": "Used with a thread to make clothes.",
"id": 1733,
"members": false,
"lowalch": 1,
"limit": 40,
"value": 1,
"highalch": 1,
"icon": "Needle.png",
"name": "Needle"
},
{
"examine": "A wooden shield with a rope rim.",
"id": 10826,
"members": true,
"lowalch": 200,
"limit": 70,
"value": 500,
"highalch": 300,
"icon": "Neitiznot shield.png",
"name": "Neitiznot shield"
},
{
"examine": "You can plant this in your garden.",
"id": 8439,
"members": true,
"lowalch": 4000,
"limit": 10000,
"value": 10000,
"highalch": 6000,
"icon": "Nice hedge (bagged).png",
"name": "Nice hedge (bagged)"
},
{
"examine": "An ancient staff corrupted by darkness.",
"id": 24422,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 1,
"highalch": 1,
"icon": "Nightmare staff.png",
"name": "Nightmare staff"
},
{
"highalch": 300,
"members": true,
"name": "Nihil dust",
"examine": "Dust from a crushed magical shard.",
"id": 26368,
"value": 500,
"icon": "Nihil dust.png",
"lowalch": 200
},
{
"highalch": 90000,
"members": true,
"name": "Nihil horn",
"examine": "The horn of a proud nihil.",
"id": 26372,
"value": 150000,
"icon": "Nihil horn.png",
"lowalch": 60000
},
{
"examine": "An ancient magical shard.",
"id": 26231,
"members": true,
"lowalch": 200,
"limit": 30000,
"value": 500,
"highalch": 300,
"icon": "Nihil shard.png",
"name": "Nihil shard"
},
{
"examine": "Ninja impling in a jar.",
"id": 11254,
"members": true,
"lowalch": 20,
"limit": 18000,
"value": 50,
"highalch": 30,
"icon": "Ninja impling jar.png",
"name": "Ninja impling jar"
},
{
"examine": "A noose on a stick.",
"id": 10150,
"members": true,
"lowalch": 1,
"limit": 40,
"value": 4,
"highalch": 2,
"icon": "Noose wand.png",
"name": "Noose wand"
},
{
"examine": "Protects me from any bad smells.",
"id": 4168,
"members": true,
"lowalch": 80,
"limit": 70,
"value": 200,
"highalch": 120,
"icon": "Nose peg.png",
"name": "Nose peg"
},
{
"highalch": 1,
"members": true,
"name": "Not meat",
"examine": "Don't eat this.",
"id": 29098,
"value": 1,
"icon": "Not meat.png",
"lowalch": 1
},
{
"examine": "A form of currency on Fossil Island.",
"id": 21555,
"members": true,
"lowalch": 4,
"limit": 30000,
"value": 10,
"highalch": 6,
"icon": "Numulite 5.png",
"name": "Numulite"
},
{
"examine": "A pair of Nunchaku, smells of pizza.",
"id": 19918,
"members": true,
"lowalch": 2400,
"limit": 8,
"value": 6000,
"highalch": 3600,
"icon": "Nunchaku.png",
"name": "Nunchaku"
},
{
"examine": "How does it all fit in there?",
"id": 8504,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Oak armchair (flatpack).png",
"name": "Oak armchair (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 9859,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Oak armour case (flatpack).png",
"name": "Oak armour case (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8578,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Oak bed (flatpack).png",
"name": "Oak bed (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8564,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Oak bench (flatpack).png",
"name": "Oak bench (flatpack)"
},
{
"examine": "Feed and catch the birds by placing this in the right spot.",
"id": 21515,
"members": true,
"lowalch": 2,
"limit": 250,
"value": 5,
"highalch": 3,
"icon": "Oak bird house.png",
"name": "Oak bird house"
},
{
"examine": "A defensive blackjack.",
"id": 6410,
"members": true,
"lowalch": 160,
"limit": 125,
"value": 400,
"highalch": 240,
"icon": "Oak blackjack(d).png",
"name": "Oak blackjack(d)"
},
{
"examine": "An offensive blackjack.",
"id": 6408,
"members": true,
"lowalch": 160,
"limit": 125,
"value": 400,
"highalch": 240,
"icon": "Oak blackjack(o).png",
"name": "Oak blackjack(o)"
},
{
"examine": "How does it all fit in there?",
"id": 8512,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Oak bookcase (flatpack).png",
"name": "Oak bookcase (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 9843,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Oak cape rack (flatpack).png",
"name": "Oak cape rack (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8502,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Oak chair (flatpack).png",
"name": "Oak chair (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8590,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Oak clock (flatpack).png",
"name": "Oak clock (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8550,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Oak dining table (flatpack).png",
"name": "Oak dining table (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8612,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Oak drawers (flatpack).png",
"name": "Oak drawers (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8600,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Oak dresser (flatpack).png",
"name": "Oak dresser (flatpack)"
},
{
"examine": "Oak fancy dress box.",
"id": 9865,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Oak fancy dress box (flatpack).png",
"name": "Oak fancy dress box (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8530,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Oak kitchen table (flatpack).png",
"name": "Oak kitchen table (flatpack)"
},
{
"highalch": 1,
"members": true,
"name": "Oak leaves",
"examine": "A pile of Oak tree leaves.",
"id": 6022,
"value": 1,
"icon": "Oak leaves.png",
"lowalch": 1
},
{
"examine": "Logs cut from an oak tree.",
"id": 1521,
"members": false,
"lowalch": 8,
"limit": 15000,
"value": 20,
"highalch": 12,
"icon": "Oak logs.png",
"name": "Oak logs"
},
{
"examine": "A nice sturdy bow made out of oak.",
"id": 845,
"members": false,
"lowalch": 64,
"limit": 18000,
"value": 160,
"highalch": 96,
"icon": "Oak longbow.png",
"name": "Oak longbow"
},
{
"examine": "An unstrung oak longbow; I need a bowstring for this.",
"id": 56,
"members": true,
"lowalch": 32,
"limit": 10000,
"value": 80,
"highalch": 48,
"icon": "Oak longbow (u).png",
"name": "Oak longbow (u)"
},
{
"examine": "How does it all fit in there?",
"id": 9852,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Oak magic wardrobe (flatpack).png",
"name": "Oak magic wardrobe (flatpack)"
},
{
"examine": "A plank of sturdy oak.",
"id": 8778,
"members": true,
"lowalch": 100,
"limit": 13000,
"value": 250,
"highalch": 150,
"icon": "Oak plank.png",
"name": "Oak plank"
},
{
"examine": "Oak logs prepared with sacred oil for a funeral pyre.",
"id": 3440,
"members": true,
"lowalch": 16,
"limit": 11000,
"value": 40,
"highalch": 24,
"icon": "Oak pyre logs.png",
"name": "Oak pyre logs"
},
{
"examine": "The roots of the Oak tree.",
"id": 6043,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 1,
"highalch": 1,
"icon": "Oak roots.png",
"name": "Oak roots"
},
{
"examine": "This sapling is ready to be replanted in a tree patch.",
"id": 5370,
"members": true,
"lowalch": 1,
"limit": 200,
"value": 1,
"highalch": 1,
"icon": "Oak sapling.png",
"name": "Oak sapling"
},
{
"examine": "How does it all fit in there?",
"id": 8598,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Oak shaving stand (flatpack).png",
"name": "Oak shaving stand (flatpack)"
},
{
"examine": "A solid oaken shield.",
"id": 22251,
"members": true,
"lowalch": 32,
"limit": 125,
"value": 80,
"highalch": 48,
"icon": "Oak shield.png",
"name": "Oak shield"
},
{
"examine": "A shortbow made out of oak, still effective.",
"id": 843,
"members": false,
"lowalch": 40,
"limit": 18000,
"value": 100,
"highalch": 60,
"icon": "Oak shortbow.png",
"name": "Oak shortbow"
},
{
"examine": "An unstrung oak shortbow; I need a bowstring for this.",
"id": 54,
"members": true,
"lowalch": 20,
"limit": 10000,
"value": 50,
"highalch": 30,
"icon": "Oak shortbow (u).png",
"name": "Oak shortbow (u)"
},
{
"examine": "An oak crossbow stock.",
"id": 9442,
"members": true,
"lowalch": 10,
"limit": 10000,
"value": 27,
"highalch": 16,
"icon": "Oak stock.png",
"name": "Oak stock"
},
{
"examine": "How does it all fit in there?",
"id": 9849,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Oak toy box (flatpack).png",
"name": "Oak toy box (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 9862,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Oak treasure chest (flatpack).png",
"name": "Oak treasure chest (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8614,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Oak wardrobe (flatpack).png",
"name": "Oak wardrobe (flatpack)"
},
{
"examine": "A set containing an Obsidian helmet, platebody and platelegs.",
"id": 21279,
"members": true,
"lowalch": 50400,
"limit": 5,
"value": 126000,
"highalch": 75600,
"icon": "Obsidian armour set.png",
"name": "Obsidian armour set"
},
{
"examine": "A cape of woven obsidian plates.",
"id": 6568,
"members": true,
"lowalch": 24000,
"limit": 70,
"value": 60000,
"highalch": 36000,
"icon": "Obsidian cape.png",
"name": "Obsidian cape"
},
{
"examine": "A cape of woven obsidian plates.",
"id": 20050,
"members": true,
"lowalch": 24000,
"limit": 70,
"value": 60000,
"highalch": 36000,
"icon": "Obsidian cape (r).png",
"name": "Obsidian cape (r)"
},
{
"examine": "Provides excellent protection.",
"id": 21298,
"members": true,
"lowalch": 22528,
"limit": 70,
"value": 56320,
"highalch": 33792,
"icon": "Obsidian helmet.png",
"name": "Obsidian helmet"
},
{
"examine": "Provides excellent protection.",
"id": 21301,
"members": true,
"lowalch": 33600,
"limit": 70,
"value": 84000,
"highalch": 50400,
"icon": "Obsidian platebody.png",
"name": "Obsidian platebody"
},
{
"examine": "Provides excellent protection.",
"id": 21304,
"members": true,
"lowalch": 26800,
"limit": 70,
"value": 67000,
"highalch": 40200,
"icon": "Obsidian platelegs.png",
"name": "Obsidian platelegs"
},
{
"examine": "A smokey evil embodies this amulet.",
"id": 12002,
"members": true,
"lowalch": 18000,
"limit": 8,
"value": 45000,
"highalch": 27000,
"icon": "Occult necklace.png",
"name": "Occult necklace"
},
{
"examine": "Use on an occult necklace to make it look fancier!",
"id": 20065,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Occult ornament kit.png",
"name": "Occult ornament kit"
},
{
"examine": "A muddy yellow coloured pointed snail shell helmet.",
"id": 3341,
"members": true,
"lowalch": 120,
"limit": 150,
"value": 300,
"highalch": 180,
"icon": "Ochre snelm (pointed).png",
"name": "Ochre snelm (pointed)"
},
{
"examine": "A muddy yellow snail shell helmet.",
"id": 3331,
"members": true,
"lowalch": 120,
"limit": 150,
"value": 300,
"highalch": 180,
"icon": "Ochre snelm (round).png",
"name": "Ochre snelm (round)"
},
{
"examine": "A broken shield piece.",
"id": 11928,
"members": true,
"lowalch": 12400,
"limit": 10000,
"value": 31000,
"highalch": 18600,
"icon": "Odium shard 1.png",
"name": "Odium shard 1"
},
{
"examine": "A broken shield piece.",
"id": 11929,
"members": true,
"lowalch": 12400,
"limit": 10000,
"value": 31000,
"highalch": 18600,
"icon": "Odium shard 2.png",
"name": "Odium shard 2"
},
{
"examine": "A broken shield piece.",
"id": 11930,
"members": true,
"lowalch": 12400,
"limit": 10000,
"value": 31000,
"highalch": 18600,
"icon": "Odium shard 3.png",
"name": "Odium shard 3"
},
{
"examine": "An ancient, evil shield forged by Volcanic heat.",
"id": 11926,
"members": true,
"lowalch": 18000,
"limit": 8,
"value": 45000,
"highalch": 27000,
"icon": "Odium ward.png",
"name": "Odium ward"
},
{
"examine": "A large ogre arrow with a bone tip.",
"id": 2866,
"members": true,
"lowalch": 10,
"limit": 7000,
"value": 25,
"highalch": 15,
"icon": "Ogre arrow 5.png",
"name": "Ogre arrow"
},
{
"examine": "A wooden arrow shaft.",
"id": 2864,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 1,
"highalch": 1,
"icon": "Ogre arrow shaft 5.png",
"name": "Ogre arrow shaft"
},
{
"examine": "A key which opens coffins! (Opens the locked coffins in the cave at Jiggig).",
"id": 4850,
"members": true,
"lowalch": 40,
"limit": 3000,
"value": 100,
"highalch": 60,
"icon": "Ogre coffin key.png",
"name": "Ogre coffin key"
},
{
"examine": "Not the genie sort.",
"id": 4522,
"members": true,
"lowalch": 11,
"limit": 40,
"value": 28,
"highalch": 16,
"icon": "Oil lamp.png",
"name": "Oil lamp"
},
{
"examine": "An unlit oil lantern.",
"id": 4537,
"members": true,
"lowalch": 50,
"limit": 40,
"value": 125,
"highalch": 75,
"icon": "Oil lantern.png",
"name": "Oil lantern"
},
{
"examine": "Add the glass to complete.",
"id": 4540,
"members": true,
"lowalch": 36,
"limit": 10000,
"value": 90,
"highalch": 54,
"icon": "Oil lantern frame.png",
"name": "Oil lantern frame"
},
{
"examine": "Lost in time.",
"id": 20029,
"members": true,
"lowalch": 1600,
"limit": 4,
"value": 4000,
"highalch": 2400,
"icon": "Old demon mask.png",
"name": "Old demon mask"
},
{
"members": false,
"name": "Old school bond",
"examine": "This bond can be redeemed for membership.",
"id": 13190,
"value": 2000000,
"icon": "Old school bond.png",
"limit": 100
},
{
"examine": "1 dose of olive oil.",
"id": 3428,
"members": true,
"lowalch": 5,
"limit": 10000,
"value": 14,
"highalch": 8,
"icon": "Olive oil(1).png",
"name": "Olive oil(1)"
},
{
"examine": "2 doses of olive oil.",
"id": 3426,
"members": true,
"lowalch": 6,
"limit": 10000,
"value": 17,
"highalch": 10,
"icon": "Olive oil(2).png",
"name": "Olive oil(2)"
},
{
"examine": "3 doses of olive oil.",
"id": 3424,
"members": true,
"lowalch": 8,
"limit": 10000,
"value": 20,
"highalch": 12,
"icon": "Olive oil(3).png",
"name": "Olive oil(3)"
},
{
"examine": "4 doses of olive oil.",
"id": 3422,
"members": true,
"lowalch": 8,
"limit": 10000,
"value": 22,
"highalch": 13,
"icon": "Olive oil(4).png",
"name": "Olive oil(4)"
},
{
"examine": "A strong smelling onion.",
"id": 1957,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 3,
"highalch": 1,
"icon": "Onion.png",
"name": "Onion"
},
{
"examine": "A mixture of chopped onions and tomatoes in a bowl.",
"id": 1875,
"members": true,
"lowalch": 2,
"limit": 11000,
"value": 5,
"highalch": 3,
"icon": "Onion & tomato.png",
"name": "Onion & tomato"
},
{
"examine": "An onion seed - plant in an allotment.",
"id": 5319,
"members": true,
"lowalch": 1,
"limit": 600,
"value": 3,
"highalch": 1,
"icon": "Onion seed 5.png",
"name": "Onion seed"
},
{
"examine": "There are 10 onions in this sack.",
"id": 5458,
"members": true,
"lowalch": 1,
"limit": 600,
"value": 1,
"highalch": 1,
"icon": "Onions(10).png",
"name": "Onions(10)"
},
{
"examine": "This looks valuable.",
"id": 6573,
"members": true,
"lowalch": 80000,
"limit": 11000,
"value": 200000,
"highalch": 120000,
"icon": "Onyx.png",
"name": "Onyx"
},
{
"examine": "I wonder if I can get this enchanted.",
"id": 6581,
"members": true,
"lowalch": 80400,
"limit": 10000,
"value": 201000,
"highalch": 120600,
"icon": "Onyx amulet.png",
"name": "Onyx amulet"
},
{
"examine": "It needs a string so I can wear it.",
"id": 6579,
"members": true,
"lowalch": 80400,
"limit": 10000,
"value": 201000,
"highalch": 120600,
"icon": "Onyx amulet (u).png",
"name": "Onyx amulet (u)"
},
{
"examine": "Onyx bolt tips.",
"id": 9194,
"members": true,
"lowalch": 400,
"limit": 11000,
"value": 1000,
"highalch": 600,
"icon": "Onyx bolt tips 5.png",
"name": "Onyx bolt tips"
},
{
"examine": "Onyx tipped Runite crossbow bolts.",
"id": 9342,
"members": true,
"lowalch": 5453,
"limit": 11000,
"value": 13633,
"highalch": 8179,
"icon": "Onyx bolts 5.png",
"name": "Onyx bolts"
},
{
"examine": "Enchanted Onyx tipped Runite Crossbow Bolts.",
"id": 9245,
"members": true,
"lowalch": 6000,
"limit": 11000,
"value": 15000,
"highalch": 9000,
"icon": "Onyx bolts (e) 5.png",
"name": "Onyx bolts (e)"
},
{
"examine": "Yep, this is most definitely valuable!",
"id": 11130,
"members": true,
"lowalch": 80400,
"limit": 10000,
"value": 201000,
"highalch": 120600,
"icon": "Onyx bracelet.png",
"name": "Onyx bracelet"
},
{
"examine": "Dragon crossbow bolts, tipped with onyx.",
"id": 21973,
"members": true,
"lowalch": 5560,
"limit": 11000,
"value": 13900,
"highalch": 8340,
"icon": "Onyx dragon bolts 5.png",
"name": "Onyx dragon bolts"
},
{
"examine": "Enchanted Dragon crossbow bolts, tipped with onyx.",
"id": 21950,
"members": true,
"lowalch": 6320,
"limit": 11000,
"value": 15800,
"highalch": 9480,
"icon": "Onyx dragon bolts (e) 5.png",
"name": "Onyx dragon bolts (e)"
},
{
"examine": "I wonder if this is valuable.",
"id": 6577,
"members": true,
"lowalch": 80400,
"limit": 10000,
"value": 201000,
"highalch": 120600,
"icon": "Onyx necklace.png",
"name": "Onyx necklace"
},
{
"examine": "A valuable ring.",
"id": 6575,
"members": true,
"lowalch": 80400,
"limit": 10000,
"value": 201000,
"highalch": 120600,
"icon": "Onyx ring.png",
"name": "Onyx ring"
},
{
"examine": "A semi precious stone.",
"id": 1609,
"members": true,
"lowalch": 40,
"limit": 13000,
"value": 100,
"highalch": 60,
"icon": "Opal.png",
"name": "Opal"
},
{
"examine": "I wonder if I can get this enchanted.",
"id": 21108,
"members": true,
"lowalch": 360,
"limit": 10000,
"value": 900,
"highalch": 540,
"icon": "Opal amulet.png",
"name": "Opal amulet"
},
{
"examine": "It needs a string so I can wear it.",
"id": 21099,
"members": true,
"lowalch": 360,
"limit": 10000,
"value": 900,
"highalch": 540,
"icon": "Opal amulet (u).png",
"name": "Opal amulet (u)"
},
{
"examine": "Opal bolt tips.",
"id": 45,
"members": true,
"lowalch": 2,
"limit": 11000,
"value": 7,
"highalch": 4,
"icon": "Opal bolt tips 5.png",
"name": "Opal bolt tips"
},
{
"examine": "Opal tipped Bronze crossbow bolts.",
"id": 879,
"members": true,
"lowalch": 2,
"limit": 11000,
"value": 7,
"highalch": 4,
"icon": "Opal bolts 5.png",
"name": "Opal bolts"
},
{
"examine": "Enchanted Opal tipped Bronze Crossbow Bolts.",
"id": 9236,
"members": true,
"lowalch": 2,
"limit": 11000,
"value": 7,
"highalch": 4,
"icon": "Opal bolts (e) 5.png",
"name": "Opal bolts (e)"
},
{
"examine": "I wonder if this is valuable.",
"id": 21117,
"members": true,
"lowalch": 460,
"limit": 10000,
"value": 1150,
"highalch": 690,
"icon": "Opal bracelet.png",
"name": "Opal bracelet"
},
{
"examine": "Dragon crossbow bolts, tipped with opal.",
"id": 21955,
"members": true,
"lowalch": 220,
"limit": 11000,
"value": 550,
"highalch": 330,
"icon": "Opal dragon bolts 5.png",
"name": "Opal dragon bolts"
},
{
"examine": "Enchanted Dragon crossbow bolts, tipped with opal.",
"id": 21932,
"members": true,
"lowalch": 232,
"limit": 11000,
"value": 580,
"highalch": 348,
"icon": "Opal dragon bolts (e) 5.png",
"name": "Opal dragon bolts (e)"
},
{
"examine": "A jungle specific slashing device.",
"id": 6313,
"members": true,
"lowalch": 200,
"limit": 15,
"value": 500,
"highalch": 300,
"icon": "Opal machete.png",
"name": "Opal machete"
},
{
"examine": "I wonder if this is valuable.",
"id": 21090,
"members": true,
"lowalch": 420,
"limit": 10000,
"value": 1050,
"highalch": 630,
"icon": "Opal necklace.png",
"name": "Opal necklace"
},
{
"examine": "A valuable ring.",
"id": 21081,
"members": true,
"lowalch": 420,
"limit": 10000,
"value": 1050,
"highalch": 630,
"icon": "Opal ring.png",
"name": "Opal ring"
},
{
"examine": "How does it all fit in there?",
"id": 8560,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Opulent table (flatpack).png",
"name": "Opulent table (flatpack)"
},
{
"examine": "A fresh orange.",
"id": 2108,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Orange.png",
"name": "Orange"
},
{
"examine": "Stylish!",
"id": 7321,
"members": true,
"lowalch": 90,
"limit": 4,
"value": 225,
"highalch": 135,
"icon": "Orange boater.png",
"name": "Orange boater"
},
{
"examine": "A thick orange cape.",
"id": 1031,
"members": false,
"lowalch": 12,
"limit": 150,
"value": 32,
"highalch": 19,
"icon": "Orange cape.png",
"name": "Orange cape"
},
{
"examine": "Fresh chunks of orange.",
"id": 2110,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Orange chunks.png",
"name": "Orange chunks"
},
{
"examine": "A little bottle of orange dye.",
"id": 1769,
"members": false,
"lowalch": 2,
"limit": 150,
"value": 5,
"highalch": 3,
"icon": "Orange dye.png",
"name": "Orange dye"
},
{
"examine": "A fairly plain feather.",
"id": 10091,
"members": true,
"lowalch": 6,
"limit": 8000,
"value": 15,
"highalch": 9,
"icon": "Orange feather.png",
"name": "Orange feather"
},
{
"examine": "A posy of flowers.",
"id": 2470,
"members": true,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Orange flowers.png",
"name": "Orange flowers"
},
{
"examine": "Slightly slimy but kind of cute.",
"id": 10146,
"members": true,
"lowalch": 40,
"limit": 125,
"value": 100,
"highalch": 60,
"icon": "Orange salamander.png",
"name": "Orange salamander"
},
{
"examine": "This sapling is ready to be replanted in a fruit tree patch.",
"id": 5498,
"members": true,
"lowalch": 1,
"limit": 200,
"value": 1,
"highalch": 1,
"icon": "Orange sapling.png",
"name": "Orange sapling"
},
{
"examine": "Fresh orange slices.",
"id": 2112,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Orange slices.png",
"name": "Orange slices"
},
{
"examine": "Plant in a plantpot of soil to grow a sapling.",
"id": 5285,
"members": true,
"lowalch": 12,
"limit": 200,
"value": 31,
"highalch": 18,
"icon": "Orange tree seed 5.png",
"name": "Orange tree seed"
},
{
"examine": "A fruit basket filled with oranges.",
"id": 5396,
"members": true,
"lowalch": 1,
"limit": 600,
"value": 1,
"highalch": 1,
"icon": "Oranges(5).png",
"name": "Oranges(5)"
},
{
"examine": "This handle can be applied to the granite maul.",
"id": 24229,
"members": true,
"lowalch": 1,
"limit": 50,
"value": 1,
"highalch": 1,
"icon": "Ornate maul handle.png",
"name": "Ornate maul handle"
},
{
"examine": "Osman's trade report for Ali Morrisane.",
"id": 26602,
"members": false,
"lowalch": 1,
"limit": 0,
"value": 1,
"highalch": 1,
"icon": "Osman's report.png",
"name": "Osman's report"
},
{
"examine": "A deadly sword created long ago.",
"id": 26219,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 1,
"highalch": 1,
"icon": "Osmumten's fang.png",
"name": "Osmumten's fang"
},
{
"examine": "A teleport to the Ourania cave.",
"id": 24951,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Ourania teleport (tablet).png",
"name": "Ourania teleport (tablet)"
},
{
"examine": "Ancient ogre bones from the ogre burial tomb.",
"id": 4834,
"members": true,
"lowalch": 1,
"limit": 7500,
"value": 1,
"highalch": 1,
"icon": "Ourg bones.png",
"name": "Ourg bones"
},
{
"examine": "Maybe there are pearls inside?",
"id": 407,
"members": true,
"lowalch": 80,
"limit": 11000,
"value": 200,
"highalch": 120,
"icon": "Oyster.png",
"name": "Oyster"
},
{
"examine": "I could work wonders with a chisel on this pearl.",
"id": 411,
"members": true,
"lowalch": 44,
"limit": 11000,
"value": 112,
"highalch": 67,
"icon": "Oyster pearl.png",
"name": "Oyster pearl"
},
{
"examine": "I could work wonders with a chisel on these pearls.",
"id": 413,
"members": true,
"lowalch": 560,
"limit": 11000,
"value": 1400,
"highalch": 840,
"icon": "Oyster pearls.png",
"name": "Oyster pearls"
},
{
"examine": "A teleport to Paddewwa.",
"id": 12781,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Paddewwa teleport (tablet).png",
"name": "Paddewwa teleport (tablet)"
},
{
"examine": "This sapling is ready to be replanted in a fruit tree patch.",
"id": 5502,
"members": true,
"lowalch": 1,
"limit": 200,
"value": 1,
"highalch": 1,
"icon": "Palm sapling.png",
"name": "Palm sapling"
},
{
"examine": "Plant in a plantpot of soil to grow a sapling.",
"id": 5289,
"members": true,
"lowalch": 101,
"limit": 200,
"value": 254,
"highalch": 152,
"icon": "Palm tree seed 5.png",
"name": "Palm tree seed"
},
{
"examine": "Alas, someone has slashed my pantaloons.",
"id": 10396,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Pantaloons.png",
"name": "Pantaloons"
},
{
"examine": "Looks delicious.",
"id": 5972,
"members": true,
"lowalch": 25,
"limit": 11000,
"value": 64,
"highalch": 38,
"icon": "Papaya fruit.png",
"name": "Papaya fruit"
},
{
"examine": "This sapling is ready to be replanted in a fruit tree patch.",
"id": 5501,
"members": true,
"lowalch": 1,
"limit": 200,
"value": 1,
"highalch": 1,
"icon": "Papaya sapling.png",
"name": "Papaya sapling"
},
{
"examine": "Plant in a plantpot of soil to grow a sapling.",
"id": 5288,
"members": true,
"lowalch": 46,
"limit": 200,
"value": 115,
"highalch": 69,
"icon": "Papaya tree seed 5.png",
"name": "Papaya tree seed"
},
{
"examine": "Used for making notes.",
"id": 970,
"members": true,
"lowalch": 4,
"limit": 13000,
"value": 10,
"highalch": 6,
"icon": "Papyrus.png",
"name": "Papyrus"
},
{
"examine": "I have the sudden urge to rob the bank...",
"id": 12399,
"members": true,
"lowalch": 720,
"limit": 4,
"value": 1800,
"highalch": 1080,
"icon": "Partyhat & specs.png",
"name": "Partyhat & specs"
},
{
"examine": "A set containing red, yellow, blue, purple, green and white partyhats.",
"id": 13173,
"members": false,
"lowalch": 4,
"limit": 5,
"value": 10,
"highalch": 6,
"icon": "Partyhat set.png",
"name": "Partyhat set"
},
{
"examine": "Potentially pastry.",
"id": 1953,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Pastry dough.png",
"name": "Pastry dough"
},
{
"examine": "A pat of freshly churned butter.",
"id": 6697,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 4,
"highalch": 2,
"icon": "Pat of butter.png",
"name": "Pat of butter"
},
{
"examine": "Life and death, old and new, day and night. Balance is the key to all.",
"id": 20226,
"members": true,
"lowalch": 32,
"limit": 5,
"value": 80,
"highalch": 48,
"icon": "Peaceful blessing.png",
"name": "Peaceful blessing"
},
{
"examine": "Pearl bolt tips.",
"id": 46,
"members": true,
"lowalch": 4,
"limit": 11000,
"value": 12,
"highalch": 7,
"icon": "Pearl bolt tips 5.png",
"name": "Pearl bolt tips"
},
{
"examine": "Pearl tipped Iron crossbow bolts.",
"id": 880,
"members": true,
"lowalch": 5,
"limit": 11000,
"value": 13,
"highalch": 7,
"icon": "Pearl bolts 5.png",
"name": "Pearl bolts"
},
{
"examine": "Enchanted Pearl tipped Iron Crossbow Bolts.",
"id": 9238,
"members": true,
"lowalch": 6,
"limit": 11000,
"value": 15,
"highalch": 9,
"icon": "Pearl bolts (e) 5.png",
"name": "Pearl bolts (e)"
},
{
"examine": "Dragon crossbow bolts, tipped with pearl.",
"id": 21959,
"members": true,
"lowalch": 260,
"limit": 11000,
"value": 650,
"highalch": 390,
"icon": "Pearl dragon bolts 5.png",
"name": "Pearl dragon bolts"
},
{
"examine": "Enchanted Dragon crossbow bolts, tipped with pearl.",
"id": 21936,
"members": true,
"lowalch": 272,
"limit": 11000,
"value": 680,
"highalch": 408,
"icon": "Pearl dragon bolts (e) 5.png",
"name": "Pearl dragon bolts (e)"
},
{
"examine": "A pair of upgraded ranger boots.",
"id": 13237,
"members": true,
"lowalch": 30000,
"limit": 15,
"value": 75000,
"highalch": 45000,
"icon": "Pegasian boots.png",
"name": "Pegasian boots"
},
{
"examine": "A powerful crystal of flight.",
"id": 13229,
"members": true,
"lowalch": 18000,
"limit": 15,
"value": 45000,
"highalch": 27000,
"icon": "Pegasian crystal.png",
"name": "Pegasian crystal"
},
{
"examine": "Thankfully this doesn't reset every week.",
"id": 12428,
"members": true,
"lowalch": 480,
"limit": 4,
"value": 1200,
"highalch": 720,
"icon": "Penguin mask.png",
"name": "Penguin mask"
},
{
"highalch": 4500,
"members": true,
"name": "Perfected quetzal whistle blueprint",
"examine": "A perfected blueprint for a quetzal whistle.",
"id": 29256,
"value": 7500,
"icon": "Perfected quetzal whistle blueprint.png",
"lowalch": 3000
},
{
"examine": "Teleports you to Pest control.",
"id": 12407,
"members": true,
"lowalch": 4,
"limit": 10000,
"value": 10,
"highalch": 6,
"icon": "Pest control teleport.png",
"name": "Pest control teleport"
},
{
"examine": "I can grind things for potions in this.",
"id": 233,
"members": true,
"lowalch": 1,
"limit": 40,
"value": 4,
"highalch": 2,
"icon": "Pestle and mortar.png",
"name": "Pestle and mortar"
},
{
"highalch": 1,
"members": true,
"name": "Pharaoh's sceptre (uncharged)",
"examine": "This sceptre has no remaining charges.",
"id": 26945,
"value": 1,
"icon": "Pharaoh's sceptre (uncharged).png",
"lowalch": 1
},
{
"examine": "In case of emergency, wear necklace.",
"id": 11090,
"members": true,
"lowalch": 1620,
"limit": 10000,
"value": 4050,
"highalch": 2430,
"icon": "Phoenix necklace.png",
"name": "Phoenix necklace"
},
{
"examine": "The remains of a deadly shade.",
"id": 3398,
"members": true,
"lowalch": 1,
"limit": 7500,
"value": 1,
"highalch": 1,
"icon": "Phrin remains.png",
"name": "Phrin remains"
},
{
"examine": "Deceptively pie shaped.",
"id": 2313,
"members": false,
"lowalch": 1,
"limit": 500,
"value": 3,
"highalch": 1,
"icon": "Pie dish.png",
"name": "Pie dish"
},
{
"examine": "Lots of pie recipes for me to try.",
"id": 7162,
"members": true,
"lowalch": 2,
"limit": 15,
"value": 5,
"highalch": 3,
"icon": "Pie recipe book.png",
"name": "Pie recipe book"
},
{
"examine": "I need to find a filling for this pie.",
"id": 2315,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 4,
"highalch": 2,
"icon": "Pie shell.png",
"name": "Pie shell"
},
{
"examine": "Some nicely cooked pike.",
"id": 351,
"members": false,
"lowalch": 8,
"limit": 6000,
"value": 20,
"highalch": 12,
"icon": "Pike.png",
"name": "Pike"
},
{
"examine": "A little heap of salt.",
"id": 4689,
"members": true,
"lowalch": 8,
"limit": 15,
"value": 20,
"highalch": 12,
"icon": "Pile of salt.png",
"name": "Pile of salt"
},
{
"examine": "It can be cut up into something more manageable with a knife.",
"id": 2114,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Pineapple.png",
"name": "Pineapple"
},
{
"examine": "Fresh chunks of pineapple.",
"id": 2116,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Pineapple chunks.png",
"name": "Pineapple chunks"
},
{
"examine": "A tropicana pizza.",
"id": 2301,
"members": true,
"lowalch": 40,
"limit": 10000,
"value": 100,
"highalch": 60,
"icon": "Pineapple pizza.png",
"name": "Pineapple pizza"
},
{
"examine": "A fresh healthy fruit mix.",
"id": 2048,
"members": true,
"lowalch": 12,
"limit": 2000,
"value": 30,
"highalch": 18,
"icon": "Pineapple punch.png",
"name": "Pineapple punch"
},
{
"examine": "Exotic fruit.",
"id": 2118,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Pineapple ring.png",
"name": "Pineapple ring"
},
{
"examine": "This sapling is ready to be replanted in a fruit tree patch.",
"id": 5500,
"members": true,
"lowalch": 1,
"limit": 200,
"value": 1,
"highalch": 1,
"icon": "Pineapple sapling.png",
"name": "Pineapple sapling"
},
{
"examine": "Plant in a plantpot of soil to grow a sapling.",
"id": 5287,
"members": true,
"lowalch": 29,
"limit": 200,
"value": 74,
"highalch": 44,
"icon": "Pineapple seed 5.png",
"name": "Pineapple seed"
},
{
"examine": "Stylish!",
"id": 12309,
"members": true,
"lowalch": 90,
"limit": 4,
"value": 225,
"highalch": 135,
"icon": "Pink boater.png",
"name": "Pink boater"
},
{
"examine": "They're soft, silky and pink.",
"id": 626,
"members": true,
"lowalch": 80,
"limit": 150,
"value": 200,
"highalch": 120,
"icon": "Pink boots.png",
"name": "Pink boots"
},
{
"examine": "It's a bit pink.",
"id": 6959,
"members": true,
"lowalch": 1,
"limit": 150,
"value": 1,
"highalch": 1,
"icon": "Pink cape.png",
"name": "Pink cape"
},
{
"examine": "A well made elegant ladies' pink blouse.",
"id": 12339,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Pink elegant blouse.png",
"name": "Pink elegant blouse"
},
{
"examine": "A rather elegant pair of men's pink pantaloons.",
"id": 12317,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Pink elegant legs.png",
"name": "Pink elegant legs"
},
{
"examine": "A well made elegant men's pink shirt.",
"id": 12315,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Pink elegant shirt.png",
"name": "Pink elegant shirt"
},
{
"examine": "A rather elegant pink skirt.",
"id": 12341,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Pink elegant skirt.png",
"name": "Pink elegant skirt"
},
{
"examine": "A silly pink pointed hat.",
"id": 656,
"members": true,
"lowalch": 64,
"limit": 150,
"value": 160,
"highalch": 96,
"icon": "Pink hat.png",
"name": "Pink hat"
},
{
"examine": "A minimalist's hat.",
"id": 12305,
"members": true,
"lowalch": 16,
"limit": 4,
"value": 40,
"highalch": 24,
"icon": "Pink headband.png",
"name": "Pink headband"
},
{
"examine": "Made by Tree Gnomes with a thing for pink.",
"id": 646,
"members": true,
"lowalch": 72,
"limit": 150,
"value": 180,
"highalch": 108,
"icon": "Pink robe bottoms.png",
"name": "Pink robe bottoms"
},
{
"examine": "The ultimate in gnome design, now in pink!",
"id": 636,
"members": true,
"lowalch": 72,
"limit": 150,
"value": 180,
"highalch": 108,
"icon": "Pink robe top.png",
"name": "Pink robe top"
},
{
"examine": "A ladies skirt.",
"id": 1013,
"members": false,
"lowalch": 1,
"limit": 150,
"value": 2,
"highalch": 1,
"icon": "Pink skirt.png",
"name": "Pink skirt"
},
{
"examine": "Essential pirate wear.",
"id": 7130,
"members": true,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Pirate bandana (blue).png",
"name": "Pirate bandana (blue)"
},
{
"examine": "Essential pirate wear.",
"id": 7136,
"members": true,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Pirate bandana (brown).png",
"name": "Pirate bandana (brown)"
},
{
"examine": "Essential pirate wear.",
"id": 7124,
"members": true,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Pirate bandana (red).png",
"name": "Pirate bandana (red)"
},
{
"examine": "Essential pirate wear.",
"id": 7112,
"members": true,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Pirate bandana (white).png",
"name": "Pirate bandana (white)"
},
{
"examine": "Not for land lubbers.",
"id": 7114,
"members": true,
"lowalch": 140,
"limit": 150,
"value": 350,
"highalch": 210,
"icon": "Pirate boots.png",
"name": "Pirate boots"
},
{
"examine": "All I need now is a parrot.",
"id": 12412,
"members": true,
"lowalch": 1200,
"limit": 150,
"value": 3000,
"highalch": 1800,
"icon": "Pirate hat & patch.png",
"name": "Pirate hat & patch"
},
{
"examine": "A sea worthy pair of trousers.",
"id": 7116,
"members": true,
"lowalch": 140,
"limit": 150,
"value": 350,
"highalch": 210,
"icon": "Pirate leggings (beige).png",
"name": "Pirate leggings (beige)"
},
{
"examine": "A sea worthy pair of trousers.",
"id": 7132,
"members": true,
"lowalch": 140,
"limit": 150,
"value": 350,
"highalch": 210,
"icon": "Pirate leggings (blue).png",
"name": "Pirate leggings (blue)"
},
{
"examine": "A sea worthy pair of trousers.",
"id": 7138,
"members": true,
"lowalch": 140,
"limit": 150,
"value": 350,
"highalch": 210,
"icon": "Pirate leggings (brown).png",
"name": "Pirate leggings (brown)"
},
{
"examine": "A sea worthy pair of trousers.",
"id": 7126,
"members": true,
"lowalch": 140,
"limit": 150,
"value": 350,
"highalch": 210,
"icon": "Pirate leggings (red).png",
"name": "Pirate leggings (red)"
},
{
"examine": "Shiver me timbers!",
"id": 2651,
"members": true,
"lowalch": 72,
"limit": 4,
"value": 180,
"highalch": 108,
"icon": "Pirate's hat.png",
"name": "Pirate's hat"
},
{
"examine": "You should see the shark...",
"id": 2997,
"members": true,
"lowalch": 35,
"limit": 4,
"value": 89,
"highalch": 53,
"icon": "Pirate's hook.png",
"name": "Pirate's hook"
},
{
"examine": "A stained glass banner displaying the sigil of house Piscarilius.",
"id": 20260,
"members": true,
"lowalch": 28,
"limit": 4,
"value": 70,
"highalch": 42,
"icon": "Piscarilius banner.png",
"name": "Piscarilius banner"
},
{
"examine": "A rare hood from Piscarilius.",
"id": 20122,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Piscarilius hood.png",
"name": "Piscarilius hood"
},
{
"examine": "A blue scarf adorned with a sea shell.",
"id": 19952,
"members": true,
"lowalch": 4000,
"limit": 4,
"value": 10000,
"highalch": 6000,
"icon": "Piscarilius scarf.png",
"name": "Piscarilius scarf"
},
{
"examine": "Teleports you to Piscatoris.",
"id": 12408,
"members": true,
"lowalch": 4,
"limit": 10000,
"value": 10,
"highalch": 6,
"icon": "Piscatoris teleport.png",
"name": "Piscatoris teleport"
},
{
"examine": "Official explorer headgear!",
"id": 12516,
"members": true,
"lowalch": 344,
"limit": 4,
"value": 860,
"highalch": 516,
"icon": "Pith helmet.png",
"name": "Pith helmet"
},
{
"examine": "Nicely baked pitta bread. Needs more ingredients to make a kebab.",
"id": 1865,
"members": true,
"lowalch": 4,
"limit": 11000,
"value": 10,
"highalch": 6,
"icon": "Pitta bread.png",
"name": "Pitta bread"
},
{
"examine": "I need to add some tomato next.",
"id": 2283,
"members": false,
"lowalch": 1,
"limit": 11000,
"value": 4,
"highalch": 2,
"icon": "Pizza base.png",
"name": "Pizza base"
},
{
"examine": "A cheese and tomato pizza.",
"id": 2289,
"members": false,
"lowalch": 16,
"limit": 10000,
"value": 40,
"highalch": 24,
"icon": "Plain pizza.png",
"name": "Plain pizza"
},
{
"examine": "A plank of wood!",
"id": 960,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Plank.png",
"name": "Plank"
},
{
"examine": "Use this on plants to cure disease.",
"id": 6036,
"members": true,
"lowalch": 16,
"limit": 2000,
"value": 40,
"highalch": 24,
"icon": "Plant cure.png",
"name": "Plant cure"
},
{
"examine": "This stuff looks nasty.",
"id": 273,
"members": false,
"lowalch": 1,
"limit": 15,
"value": 1,
"highalch": 1,
"icon": "Poison (item).png",
"name": "Poison (item)"
},
{
"examine": "A cup of a strange brew...",
"id": 197,
"members": true,
"lowalch": 8,
"limit": 2000,
"value": 20,
"highalch": 12,
"icon": "Poison chalice.png",
"name": "Poison chalice"
},
{
"examine": "They look sweet and juicy, but only a fool would eat them.",
"id": 6018,
"members": true,
"lowalch": 26,
"limit": 11000,
"value": 65,
"highalch": 39,
"icon": "Poison ivy berries.png",
"name": "Poison ivy berries"
},
{
"examine": "A poison ivy bush seed - plant in a bush patch.",
"id": 5106,
"members": true,
"lowalch": 66,
"limit": 200,
"value": 166,
"highalch": 99,
"icon": "Poison ivy seed 5.png",
"name": "Poison ivy seed"
},
{
"highalch": 12,
"members": true,
"name": "Polar camo legs",
"examine": "These should make me harder to spot in polar areas.",
"id": 10067,
"value": 20,
"icon": "Polar camo legs.png",
"lowalch": 8
},
{
"highalch": 12,
"members": true,
"name": "Polar camo legs (equipped)",
"examine": "These should make me harder to spot in polar areas.",
"id": 28860,
"value": 20,
"icon": "Polar camo legs.png",
"lowalch": 8
},
{
"highalch": 12,
"members": true,
"name": "Polar camo top",
"examine": "This should make me harder to spot in polar areas.",
"id": 10065,
"value": 20,
"icon": "Polar camo top.png",
"lowalch": 8
},
{
"highalch": 12,
"members": true,
"name": "Polar camo top (equipped)",
"examine": "This should make me harder to spot in polar areas.",
"id": 28857,
"value": 20,
"icon": "Polar camo top.png",
"lowalch": 8
},
{
"examine": "A thick fur for a cold climate.",
"id": 10117,
"members": true,
"lowalch": 4,
"limit": 100,
"value": 10,
"highalch": 6,
"icon": "Polar kebbit fur.png",
"name": "Polar kebbit fur"
},
{
"examine": "Shiny!",
"id": 10496,
"members": true,
"lowalch": 1,
"limit": 15,
"value": 1,
"highalch": 1,
"icon": "Polished buttons.png",
"name": "Polished buttons"
},
{
"examine": "This pot is empty.",
"id": 1931,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Pot.png",
"name": "Pot"
},
{
"examine": "This should fit on a normal-sized pot.",
"id": 4440,
"members": true,
"lowalch": 6,
"limit": 15,
"value": 15,
"highalch": 9,
"icon": "Pot lid.png",
"name": "Pot lid"
},
{
"examine": "It's cornflour in a pot.",
"id": 7468,
"members": true,
"lowalch": 4,
"limit": 15,
"value": 10,
"highalch": 6,
"icon": "Pot of cornflour.png",
"name": "Pot of cornflour"
},
{
"examine": "Fresh cream.",
"id": 2130,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Pot of cream.png",
"name": "Pot of cream"
},
{
"examine": "There is flour in this pot.",
"id": 1933,
"members": false,
"lowalch": 4,
"limit": 13000,
"value": 10,
"highalch": 6,
"icon": "Pot of flour.png",
"name": "Pot of flour"
},
{
"examine": "This could be used to make a good stew.",
"id": 1942,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Potato.png",
"name": "Potato"
},
{
"examine": "How am I supposed to eat that?!",
"id": 3138,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 1,
"highalch": 1,
"icon": "Potato cactus.png",
"name": "Potato cactus"
},
{
"examine": "A Potato cactus seed - plant in a cactus patch.",
"id": 22873,
"members": true,
"lowalch": 56,
"limit": 200,
"value": 140,
"highalch": 84,
"icon": "Potato cactus seed 5.png",
"name": "Potato cactus seed"
},
{
"examine": "A potato seed - plant in an allotment.",
"id": 5318,
"members": true,
"lowalch": 1,
"limit": 600,
"value": 2,
"highalch": 1,
"icon": "Potato seed 5.png",
"name": "Potato seed"
},
{
"examine": "A baked potato with butter.",
"id": 6703,
"members": true,
"lowalch": 3,
"limit": 13000,
"value": 8,
"highalch": 4,
"icon": "Potato with butter.png",
"name": "Potato with butter"
},
{
"examine": "A baked potato with butter and cheese.",
"id": 6705,
"members": true,
"lowalch": 3,
"limit": 13000,
"value": 8,
"highalch": 4,
"icon": "Potato with cheese.png",
"name": "Potato with cheese"
},
{
"examine": "There are 10 potatoes in this sack.",
"id": 5438,
"members": true,
"lowalch": 1,
"limit": 600,
"value": 1,
"highalch": 1,
"icon": "Potatoes(10).png",
"name": "Potatoes(10)"
},
{
"examine": "Little ornament in the shape of a scarab.",
"id": 9032,
"members": true,
"lowalch": 4,
"limit": 13000,
"value": 10,
"highalch": 6,
"icon": "Pottery scarab.png",
"name": "Pottery scarab"
},
{
"examine": "A small statuette.",
"id": 9036,
"members": true,
"lowalch": 4,
"limit": 13000,
"value": 10,
"highalch": 6,
"icon": "Pottery statuette.png",
"name": "Pottery statuette"
},
{
"examine": "One dose of fishy Prayer potion.",
"id": 11467,
"members": true,
"lowalch": 30,
"limit": 2000,
"value": 76,
"highalch": 45,
"icon": "Prayer mix(1).png",
"name": "Prayer mix(1)"
},
{
"examine": "Two doses of fishy Prayer potion.",
"id": 11465,
"members": true,
"lowalch": 45,
"limit": 2000,
"value": 114,
"highalch": 68,
"icon": "Prayer mix(2).png",
"name": "Prayer mix(2)"
},
{
"examine": "1 dose of Prayer restore potion.",
"id": 143,
"members": true,
"lowalch": 30,
"limit": 2000,
"value": 76,
"highalch": 45,
"icon": "Prayer potion(1).png",
"name": "Prayer potion(1)"
},
{
"examine": "2 doses of Prayer restore potion.",
"id": 141,
"members": true,
"lowalch": 45,
"limit": 2000,
"value": 114,
"highalch": 68,
"icon": "Prayer potion(2).png",
"name": "Prayer potion(2)"
},
{
"examine": "3 doses of Prayer restore potion.",
"id": 139,
"members": true,
"lowalch": 60,
"limit": 2000,
"value": 152,
"highalch": 91,
"icon": "Prayer potion(3).png",
"name": "Prayer potion(3)"
},
{
"examine": "4 doses of Prayer restore  potion.",
"id": 2434,
"members": true,
"lowalch": 76,
"limit": 2000,
"value": 190,
"highalch": 114,
"icon": "Prayer potion(4).png",
"name": "Prayer potion(4)"
},
{
"examine": "A premade Blurberry Special",
"id": 2028,
"members": true,
"lowalch": 12,
"limit": 2000,
"value": 30,
"highalch": 18,
"icon": "Premade blurb' sp..png",
"name": "Premade blurb' sp."
},
{
"examine": "A Premade Cheese and Tomato Batta.",
"id": 2223,
"members": true,
"lowalch": 48,
"limit": 6000,
"value": 120,
"highalch": 72,
"icon": "Premade c+t batta.png",
"name": "Premade c+t batta"
},
{
"examine": "Some Premade chocchip crunchies.",
"id": 2239,
"members": true,
"lowalch": 34,
"limit": 6000,
"value": 85,
"highalch": 51,
"icon": "Premade ch' crunch.png",
"name": "Premade ch' crunch"
},
{
"examine": "A premade Chocolate Bomb.",
"id": 2229,
"members": true,
"lowalch": 64,
"limit": 6000,
"value": 160,
"highalch": 96,
"icon": "Premade choc bomb.png",
"name": "Premade choc bomb"
},
{
"examine": "A premade Chocolate Saturday.",
"id": 2030,
"members": true,
"lowalch": 12,
"limit": 2000,
"value": 30,
"highalch": 18,
"icon": "Premade choc s'dy.png",
"name": "Premade choc s'dy"
},
{
"examine": "A premade Drunk Dragon.",
"id": 2032,
"members": true,
"lowalch": 12,
"limit": 2000,
"value": 30,
"highalch": 18,
"icon": "Premade dr' dragon.png",
"name": "Premade dr' dragon"
},
{
"examine": "A premade Fruit Blast.",
"id": 2034,
"members": true,
"lowalch": 12,
"limit": 2000,
"value": 30,
"highalch": 18,
"icon": "Premade fr' blast.png",
"name": "Premade fr' blast"
},
{
"examine": "A premade Fruit Batta.",
"id": 2225,
"members": true,
"lowalch": 48,
"limit": 6000,
"value": 120,
"highalch": 72,
"icon": "Premade fr't batta.png",
"name": "Premade fr't batta"
},
{
"examine": "A premade Pineapple punch.",
"id": 2036,
"members": true,
"lowalch": 12,
"limit": 2000,
"value": 30,
"highalch": 18,
"icon": "Premade p' punch.png",
"name": "Premade p' punch"
},
{
"examine": "Some premade Spicy Crunchies.",
"id": 2241,
"members": true,
"lowalch": 34,
"limit": 6000,
"value": 85,
"highalch": 51,
"icon": "Premade s'y crunch.png",
"name": "Premade s'y crunch"
},
{
"examine": "A premade Short Green Guy.",
"id": 2038,
"members": true,
"lowalch": 12,
"limit": 2000,
"value": 30,
"highalch": 18,
"icon": "Premade sgg.png",
"name": "Premade sgg"
},
{
"examine": "A Premade Toad Batta.",
"id": 2221,
"members": true,
"lowalch": 48,
"limit": 6000,
"value": 120,
"highalch": 72,
"icon": "Premade t'd batta.png",
"name": "Premade t'd batta"
},
{
"examine": "Some premade Toad Crunchies.",
"id": 2243,
"members": true,
"lowalch": 34,
"limit": 6000,
"value": 85,
"highalch": 51,
"icon": "Premade t'd crunch.png",
"name": "Premade t'd crunch"
},
{
"examine": "A premade Tangled Toads Legs.",
"id": 2231,
"members": true,
"lowalch": 64,
"limit": 6000,
"value": 160,
"highalch": 96,
"icon": "Premade ttl.png",
"name": "Premade ttl"
},
{
"examine": "A premade Vegetable Ball.",
"id": 2235,
"members": true,
"lowalch": 60,
"limit": 6000,
"value": 150,
"highalch": 90,
"icon": "Premade veg ball.png",
"name": "Premade veg ball"
},
{
"examine": "A Premade Vegetable Batta.",
"id": 2227,
"members": true,
"lowalch": 48,
"limit": 6000,
"value": 120,
"highalch": 72,
"icon": "Premade veg batta.png",
"name": "Premade veg batta"
},
{
"examine": "A premade Worm Batta.",
"id": 2219,
"members": true,
"lowalch": 48,
"limit": 6000,
"value": 120,
"highalch": 72,
"icon": "Premade w'm batta.png",
"name": "Premade w'm batta"
},
{
"examine": "Some Premade Worm Crunchies.",
"id": 2237,
"members": true,
"lowalch": 34,
"limit": 6000,
"value": 85,
"highalch": 51,
"icon": "Premade w'm crun'.png",
"name": "Premade w'm crun'"
},
{
"examine": "A Premade Wizard Blizzard.",
"id": 2040,
"members": true,
"lowalch": 12,
"limit": 2000,
"value": 30,
"highalch": 18,
"icon": "Premade wiz blz'd.png",
"name": "Premade wiz blz'd"
},
{
"examine": "A premade Worm Hole.",
"id": 2233,
"members": true,
"lowalch": 60,
"limit": 6000,
"value": 150,
"highalch": 90,
"icon": "Premade worm hole.png",
"name": "Premade worm hole"
},
{
"examine": "Bottom half of a priest suit.",
"id": 428,
"members": false,
"lowalch": 2,
"limit": 150,
"value": 5,
"highalch": 3,
"icon": "Priest gown (bottom).png",
"name": "Priest gown (bottom)"
},
{
"examine": "Top half of a priest suit.",
"id": 426,
"members": false,
"lowalch": 2,
"limit": 150,
"value": 5,
"highalch": 3,
"icon": "Priest gown (top).png",
"name": "Priest gown (top)"
},
{
"examine": "A pair of upgraded dragon boots.",
"id": 13239,
"members": true,
"lowalch": 30000,
"limit": 15,
"value": 75000,
"highalch": 45000,
"icon": "Primordial boots.png",
"name": "Primordial boots"
},
{
"examine": "A powerful crystal of strength.",
"id": 13231,
"members": true,
"lowalch": 18000,
"limit": 15,
"value": 45000,
"highalch": 27000,
"icon": "Primordial crystal.png",
"name": "Primordial crystal"
},
{
"examine": "A giant mosquito's proboscis, aerodynamic and sharp!",
"id": 6319,
"members": true,
"lowalch": 2,
"limit": 100,
"value": 5,
"highalch": 3,
"icon": "Proboscis 1000.png",
"name": "Proboscis"
},
{
"examine": "A Proselyte Temple Knight's leg armour.",
"id": 9676,
"members": true,
"lowalch": 4000,
"limit": 70,
"value": 10000,
"highalch": 6000,
"icon": "Proselyte cuisse.png",
"name": "Proselyte cuisse"
},
{
"examine": "Proselyte level armour pack.",
"id": 9670,
"members": true,
"lowalch": 10000,
"limit": 70,
"value": 25000,
"highalch": 15000,
"icon": "Proselyte harness f.png",
"name": "Proselyte harness f"
},
{
"examine": "Proselyte level armour pack.",
"id": 9666,
"members": true,
"lowalch": 10000,
"limit": 70,
"value": 25000,
"highalch": 15000,
"icon": "Proselyte harness m.png",
"name": "Proselyte harness m"
},
{
"examine": "A Proselyte Temple Knight's armour.",
"id": 9674,
"members": true,
"lowalch": 4800,
"limit": 70,
"value": 12000,
"highalch": 7200,
"icon": "Proselyte hauberk.png",
"name": "Proselyte hauberk"
},
{
"examine": "A Proselyte Temple Knight's helm.",
"id": 9672,
"members": true,
"lowalch": 3200,
"limit": 70,
"value": 8000,
"highalch": 4800,
"icon": "Proselyte sallet.png",
"name": "Proselyte sallet"
},
{
"examine": "A Proselyte Temple Knight's leg armour.",
"id": 9678,
"members": true,
"lowalch": 4000,
"limit": 70,
"value": 10000,
"highalch": 6000,
"icon": "Proselyte tasset.png",
"name": "Proselyte tasset"
},
{
"examine": "Happy Halloween.",
"id": 1959,
"members": false,
"lowalch": 12,
"limit": 50,
"value": 30,
"highalch": 18,
"icon": "Pumpkin.png",
"name": "Pumpkin"
},
{
"examine": "An unimbued rune of extra capability.",
"id": 7936,
"members": false,
"lowalch": 1,
"limit": 30000,
"value": 4,
"highalch": 2,
"icon": "Pure essence.png",
"name": "Pure essence"
},
{
"examine": "Stylish!",
"id": 12311,
"members": true,
"lowalch": 90,
"limit": 4,
"value": 225,
"highalch": 135,
"icon": "Purple boater.png",
"name": "Purple boater"
},
{
"examine": "Very stylish!",
"id": 2934,
"members": false,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Purple boots.png",
"name": "Purple boots"
},
{
"examine": "A thick purple cape.",
"id": 1029,
"members": false,
"lowalch": 12,
"limit": 150,
"value": 32,
"highalch": 19,
"icon": "Purple cape.png",
"name": "Purple cape"
},
{
"examine": "A little bottle of purple dye.",
"id": 1773,
"members": false,
"lowalch": 2,
"limit": 150,
"value": 5,
"highalch": 3,
"icon": "Purple dye.png",
"name": "Purple dye"
},
{
"examine": "A well made elegant ladies' purple blouse.",
"id": 10436,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Purple elegant blouse.png",
"name": "Purple elegant blouse"
},
{
"examine": "A rather elegant pair of men's purple pantaloons.",
"id": 10418,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Purple elegant legs.png",
"name": "Purple elegant legs"
},
{
"examine": "A well made elegant men's purple shirt.",
"id": 10416,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Purple elegant shirt.png",
"name": "Purple elegant shirt"
},
{
"examine": "A rather elegant purple skirt.",
"id": 10438,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Purple elegant skirt.png",
"name": "Purple elegant skirt"
},
{
"examine": "Makes firelighting a lot easier.",
"id": 10326,
"members": true,
"lowalch": 6,
"limit": 250,
"value": 15,
"highalch": 9,
"icon": "Purple firelighter.png",
"name": "Purple firelighter"
},
{
"examine": "A posy of flowers.",
"id": 2468,
"members": true,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Purple flowers.png",
"name": "Purple flowers"
},
{
"examine": "These will keep my hands warm!",
"id": 2942,
"members": false,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Purple gloves.png",
"name": "Purple gloves"
},
{
"examine": "A silly pointed hat.",
"id": 2940,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Purple hat.png",
"name": "Purple hat"
},
{
"examine": "A nice hat from a cracker.",
"id": 1046,
"members": false,
"lowalch": 1,
"limit": 5,
"value": 1,
"highalch": 1,
"icon": "Purple partyhat.png",
"name": "Purple partyhat"
},
{
"examine": "Made by werewolves for werewolves.",
"id": 2938,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Purple robe bottoms.png",
"name": "Purple robe bottoms"
},
{
"examine": "Some fine werewolf clothing.",
"id": 2936,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Purple robe top.png",
"name": "Purple robe top"
},
{
"examine": "Remember to brush after eating!",
"id": 10476,
"members": true,
"lowalch": 6,
"limit": 10000,
"value": 15,
"highalch": 9,
"icon": "Purple sweets 5.png",
"name": "Purple sweets"
},
{
"examine": "Logs prepared with sacred oil for a funeral pyre.",
"id": 3438,
"members": true,
"lowalch": 3,
"limit": 11000,
"value": 8,
"highalch": 4,
"icon": "Pyre logs.png",
"name": "Pyre logs"
},
{
"examine": "A phosphate rich mineral dust.",
"id": 21545,
"members": true,
"lowalch": 12,
"limit": 13000,
"value": 30,
"highalch": 18,
"icon": "Pyrophosphite.png",
"name": "Pyrophosphite"
},
{
"examine": "A sack of quetzal feed.",
"id": 29307,
"members": true,
"lowalch": 20,
"limit": 100,
"value": 50,
"highalch": 30,
"icon": "Quetzal feed.png",
"name": "Quetzal feed"
},
{
"examine": "This could be put on a string and worn for luck.",
"id": 10134,
"members": true,
"lowalch": 12,
"limit": 100,
"value": 30,
"highalch": 18,
"icon": "Rabbit foot.png",
"name": "Rabbit foot"
},
{
"examine": "The snare will tighten around animals passing through.",
"id": 10031,
"members": true,
"lowalch": 6,
"limit": 250,
"value": 15,
"highalch": 9,
"icon": "Rabbit snare.png",
"name": "Rabbit snare"
},
{
"examine": "Short but effective and very colourful.",
"id": 23357,
"members": true,
"lowalch": 200,
"limit": 18000,
"value": 500,
"highalch": 300,
"icon": "Rain bow.png",
"name": "Rain bow"
},
{
"examine": "Well, this would certainly add some colour to a meal.",
"id": 10136,
"members": true,
"lowalch": 48,
"limit": 6000,
"value": 120,
"highalch": 72,
"icon": "Rainbow fish.png",
"name": "Rainbow fish"
},
{
"examine": "Use this to clear weeds.",
"id": 5341,
"members": true,
"lowalch": 2,
"limit": 40,
"value": 6,
"highalch": 3,
"icon": "Rake.png",
"name": "Rake"
},
{
"examine": "I need another ingredient to finish this Ranarr potion.",
"id": 99,
"members": true,
"lowalch": 10,
"limit": 10000,
"value": 25,
"highalch": 15,
"icon": "Ranarr potion (unf).png",
"name": "Ranarr potion (unf)"
},
{
"examine": "A ranarr seed - plant in a herb patch.",
"id": 5295,
"members": true,
"lowalch": 20,
"limit": 200,
"value": 50,
"highalch": 30,
"icon": "Ranarr seed 5.png",
"name": "Ranarr seed"
},
{
"examine": "A useful herb.",
"id": 257,
"members": true,
"lowalch": 10,
"limit": 11000,
"value": 25,
"highalch": 15,
"icon": "Ranarr weed.png",
"name": "Ranarr weed"
},
{
"examine": "Lightweight boots ideal for rangers.",
"id": 2577,
"members": true,
"lowalch": 80,
"limit": 8,
"value": 200,
"highalch": 120,
"icon": "Ranger boots.png",
"name": "Ranger boots"
},
{
"examine": "Lightweight gloves ideal for rangers.",
"id": 19994,
"members": true,
"lowalch": 1360,
"limit": 8,
"value": 3400,
"highalch": 2040,
"icon": "Ranger gloves.png",
"name": "Ranger gloves"
},
{
"examine": "Lightweight legwear ideal for rangers.",
"id": 23249,
"members": true,
"lowalch": 8000,
"limit": 8,
"value": 20000,
"highalch": 12000,
"icon": "Rangers' tights.png",
"name": "Rangers' tights"
},
{
"examine": "Lightweight body armour ideal for rangers.",
"id": 12596,
"members": true,
"lowalch": 4800,
"limit": 4,
"value": 12000,
"highalch": 7200,
"icon": "Rangers' tunic.png",
"name": "Rangers' tunic"
},
{
"examine": "One dose of fishy ranging potion.",
"id": 11511,
"members": true,
"lowalch": 57,
"limit": 2000,
"value": 144,
"highalch": 86,
"icon": "Ranging mix(1).png",
"name": "Ranging mix(1)"
},
{
"examine": "Two doses of fishy ranging potion.",
"id": 11509,
"members": true,
"lowalch": 86,
"limit": 2000,
"value": 216,
"highalch": 129,
"icon": "Ranging mix(2).png",
"name": "Ranging mix(2)"
},
{
"examine": "1 dose of ranging potion.",
"id": 173,
"members": true,
"lowalch": 57,
"limit": 2000,
"value": 144,
"highalch": 86,
"icon": "Ranging potion(1).png",
"name": "Ranging potion(1)"
},
{
"examine": "2 doses of ranging potion.",
"id": 171,
"members": true,
"lowalch": 86,
"limit": 2000,
"value": 216,
"highalch": 129,
"icon": "Ranging potion(2).png",
"name": "Ranging potion(2)"
},
{
"examine": "3 doses of ranging potion.",
"id": 169,
"members": true,
"lowalch": 115,
"limit": 2000,
"value": 288,
"highalch": 172,
"icon": "Ranging potion(3).png",
"name": "Ranging potion(3)"
},
{
"examine": "4 doses of ranging potion.",
"id": 2444,
"members": true,
"lowalch": 144,
"limit": 2000,
"value": 360,
"highalch": 216,
"icon": "Ranging potion(4).png",
"name": "Ranging potion(4)"
},
{
"examine": "Ancient ogre bones from the ogre burial tomb.",
"id": 4832,
"members": true,
"lowalch": 1,
"limit": 7500,
"value": 1,
"highalch": 1,
"icon": "Raurg bones.png",
"name": "Raurg bones"
},
{
"examine": "This would taste a lot better cooked.",
"id": 7196,
"members": true,
"lowalch": 62,
"limit": 13000,
"value": 155,
"highalch": 93,
"icon": "Raw admiral pie.png",
"name": "Raw admiral pie"
},
{
"examine": "I should try cooking this.",
"id": 321,
"members": false,
"lowalch": 6,
"limit": 13000,
"value": 15,
"highalch": 9,
"icon": "Raw anchovies.png",
"name": "Raw anchovies"
},
{
"examine": "I should try cooking this.",
"id": 13439,
"members": true,
"lowalch": 180,
"limit": 15000,
"value": 450,
"highalch": 270,
"icon": "Raw anglerfish.png",
"name": "Raw anglerfish"
},
{
"highalch": 1,
"members": true,
"name": "Raw barb-tailed kebbit",
"examine": "Ouch!",
"id": 29101,
"value": 1,
"icon": "Raw barb-tailed kebbit.png",
"lowalch": 1
},
{
"examine": "I should try cooking this.",
"id": 363,
"members": true,
"lowalch": 16,
"limit": 15000,
"value": 40,
"highalch": 24,
"icon": "Raw bass.png",
"name": "Raw bass"
},
{
"examine": "I need to cook this first.",
"id": 2136,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Raw bear meat.png",
"name": "Raw bear meat"
},
{
"examine": "A slab of raw beast meat.",
"id": 9986,
"members": true,
"lowalch": 10,
"limit": 11000,
"value": 25,
"highalch": 15,
"icon": "Raw beast meat.png",
"name": "Raw beast meat"
},
{
"examine": "I need to cook this first.",
"id": 2132,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Raw beef.png",
"name": "Raw beef"
},
{
"examine": "This certainly needs cooking!",
"id": 9978,
"members": true,
"lowalch": 6,
"limit": 11000,
"value": 17,
"highalch": 10,
"icon": "Raw bird meat.png",
"name": "Raw bird meat"
},
{
"highalch": 1,
"members": false,
"name": "Raw boar meat",
"examine": "I need to cook this first.",
"id": 25833,
"value": 1,
"icon": "Raw boar meat.png",
"lowalch": 1
},
{
"examine": "It's incredibly slimy.",
"id": 5001,
"members": true,
"lowalch": 6,
"limit": 13000,
"value": 15,
"highalch": 9,
"icon": "Raw cave eel.png",
"name": "Raw cave eel"
},
{
"examine": "I need to cook this first.",
"id": 2138,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Raw chicken.png",
"name": "Raw chicken"
},
{
"examine": "I need to cook this first.",
"id": 2876,
"members": true,
"lowalch": 34,
"limit": 13000,
"value": 85,
"highalch": 51,
"icon": "Raw chompy.png",
"name": "Raw chompy"
},
{
"examine": "I should try cooking this.",
"id": 341,
"members": true,
"lowalch": 4,
"limit": 15000,
"value": 10,
"highalch": 6,
"icon": "Raw cod.png",
"name": "Raw cod"
},
{
"examine": "I should try cooking this.",
"id": 11934,
"members": true,
"lowalch": 130,
"limit": 15000,
"value": 325,
"highalch": 195,
"icon": "Raw dark crab.png",
"name": "Raw dark crab"
},
{
"highalch": 1,
"members": true,
"name": "Raw dashing kebbit",
"examine": "I should probably cook this first.",
"id": 29107,
"value": 1,
"icon": "Raw dashing kebbit.png",
"lowalch": 1
},
{
"examine": "Raw fish is risky, better cook it.",
"id": 7186,
"members": true,
"lowalch": 20,
"limit": 13000,
"value": 50,
"highalch": 30,
"icon": "Raw fish pie.png",
"name": "Raw fish pie"
},
{
"examine": "Needs cooking before I eat it.",
"id": 7176,
"members": true,
"lowalch": 4,
"limit": 13000,
"value": 12,
"highalch": 7,
"icon": "Raw garden pie.png",
"name": "Raw garden pie"
},
{
"highalch": 1,
"members": true,
"name": "Raw graahk",
"examine": "I should probably cook this first.",
"id": 29119,
"value": 1,
"icon": "Raw graahk.png",
"lowalch": 1
},
{
"examine": "I should try cooking this.",
"id": 345,
"members": false,
"lowalch": 4,
"limit": 15000,
"value": 10,
"highalch": 6,
"icon": "Raw herring.png",
"name": "Raw herring"
},
{
"examine": "The uncooked meat of a Jubbly bird.",
"id": 7566,
"members": true,
"lowalch": 40,
"limit": 11000,
"value": 100,
"highalch": 60,
"icon": "Raw jubbly.png",
"name": "Raw jubbly"
},
{
"examine": "A raw green octopus.",
"id": 3142,
"members": true,
"lowalch": 80,
"limit": 13000,
"value": 200,
"highalch": 120,
"icon": "Raw karambwan.png",
"name": "Raw karambwan"
},
{
"highalch": 1,
"members": true,
"name": "Raw kyatt",
"examine": "I should probably cook this first.",
"id": 29125,
"value": 1,
"icon": "Raw kyatt.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Raw larupia",
"examine": "I should probably cook this first.",
"id": 29122,
"value": 1,
"icon": "Raw larupia.png",
"lowalch": 1
},
{
"examine": "I should try cooking this.",
"id": 377,
"members": false,
"lowalch": 28,
"limit": 15000,
"value": 70,
"highalch": 42,
"icon": "Raw lobster.png",
"name": "Raw lobster"
},
{
"examine": "I should try cooking this.",
"id": 353,
"members": true,
"lowalch": 6,
"limit": 15000,
"value": 15,
"highalch": 9,
"icon": "Raw mackerel.png",
"name": "Raw mackerel"
},
{
"examine": "A rare catch.",
"id": 389,
"members": true,
"lowalch": 80,
"limit": 15000,
"value": 200,
"highalch": 120,
"icon": "Raw manta ray.png",
"name": "Raw manta ray"
},
{
"examine": "I should try cooking this.",
"id": 7944,
"members": true,
"lowalch": 92,
"limit": 13000,
"value": 230,
"highalch": 138,
"icon": "Raw monkfish.png",
"name": "Raw monkfish"
},
{
"highalch": 1,
"members": true,
"name": "Raw moonlight antelope",
"examine": "I should probably cook this first.",
"id": 29113,
"value": 1,
"icon": "Raw moonlight antelope.png",
"lowalch": 1
},
{
"examine": "Needs to be baked before I can use it.",
"id": 7168,
"members": true,
"lowalch": 10,
"limit": 13000,
"value": 27,
"highalch": 16,
"icon": "Raw mud pie.png",
"name": "Raw mud pie"
},
{
"highalch": 1,
"members": true,
"name": "Raw mystery meat",
"examine": "I need to cook this, though I don't know if I want to.",
"id": 24782,
"value": 1,
"icon": "Raw mystery meat.png",
"lowalch": 1
},
{
"examine": "Raw meat from the oomlie bird.",
"id": 2337,
"members": true,
"lowalch": 4,
"limit": 15,
"value": 10,
"highalch": 6,
"icon": "Raw oomlie.png",
"name": "Raw oomlie"
},
{
"examine": "I should try cooking this.",
"id": 349,
"members": false,
"lowalch": 8,
"limit": 15000,
"value": 20,
"highalch": 12,
"icon": "Raw pike.png",
"name": "Raw pike"
},
{
"highalch": 1,
"members": true,
"name": "Raw pyre fox",
"examine": "I should probably cook this first.",
"id": 29110,
"value": 1,
"icon": "Raw pyre fox.png",
"lowalch": 1
},
{
"examine": "Might taste better cooked.",
"id": 3226,
"members": true,
"lowalch": 8,
"limit": 13000,
"value": 20,
"highalch": 12,
"icon": "Raw rabbit.png",
"name": "Raw rabbit"
},
{
"examine": "A colourful fish, attracted to colourful flies.",
"id": 10138,
"members": true,
"lowalch": 48,
"limit": 13000,
"value": 120,
"highalch": 72,
"icon": "Raw rainbow fish.png",
"name": "Raw rainbow fish"
},
{
"examine": "I need to cook this first.",
"id": 2134,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Raw rat meat.png",
"name": "Raw rat meat"
},
{
"examine": "I should try cooking this.",
"id": 331,
"members": false,
"lowalch": 12,
"limit": 15000,
"value": 30,
"highalch": 18,
"icon": "Raw salmon.png",
"name": "Raw salmon"
},
{
"examine": "I should try cooking this.",
"id": 327,
"members": false,
"lowalch": 4,
"limit": 15000,
"value": 10,
"highalch": 6,
"icon": "Raw sardine.png",
"name": "Raw sardine"
},
{
"examine": "A rare catch.",
"id": 395,
"members": true,
"lowalch": 80,
"limit": 15000,
"value": 200,
"highalch": 120,
"icon": "Raw sea turtle.png",
"name": "Raw sea turtle"
},
{
"examine": "I should try cooking this.",
"id": 383,
"members": true,
"lowalch": 68,
"limit": 15000,
"value": 170,
"highalch": 102,
"icon": "Raw shark.png",
"name": "Raw shark"
},
{
"examine": "I should try cooking this.",
"id": 317,
"members": false,
"lowalch": 2,
"limit": 15000,
"value": 5,
"highalch": 3,
"icon": "Raw shrimps.png",
"name": "Raw shrimps"
},
{
"examine": "A slime covered eel - yuck!",
"id": 3379,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Raw slimy eel.png",
"name": "Raw slimy eel"
},
{
"examine": "Fresh fruit may be good for you, but I should really cook this.",
"id": 7216,
"members": true,
"lowalch": 28,
"limit": 13000,
"value": 70,
"highalch": 42,
"icon": "Raw summer pie.png",
"name": "Raw summer pie"
},
{
"highalch": 1,
"members": true,
"name": "Raw sunlight antelope",
"examine": "I should probably cook this first.",
"id": 29116,
"value": 1,
"icon": "Raw sunlight antelope.png",
"lowalch": 1
},
{
"examine": "I should try cooking this.",
"id": 371,
"members": false,
"lowalch": 32,
"limit": 15000,
"value": 80,
"highalch": 48,
"icon": "Raw swordfish.png",
"name": "Raw swordfish"
},
{
"examine": "I should try cooking this.",
"id": 335,
"members": false,
"lowalch": 4,
"limit": 15000,
"value": 10,
"highalch": 6,
"icon": "Raw trout.png",
"name": "Raw trout"
},
{
"examine": "I should try cooking this.",
"id": 359,
"members": false,
"lowalch": 16,
"limit": 15000,
"value": 40,
"highalch": 24,
"icon": "Raw tuna.png",
"name": "Raw tuna"
},
{
"examine": "I need to cook this first.",
"id": 1859,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 2,
"highalch": 1,
"icon": "Raw ugthanki meat.png",
"name": "Raw ugthanki meat"
},
{
"highalch": 1,
"members": true,
"name": "Raw wild kebbit",
"examine": "Better cook this carefully.",
"id": 29104,
"value": 1,
"icon": "Raw wild kebbit.png",
"lowalch": 1
},
{
"examine": "Good as it looks, I'd better cook it.",
"id": 7206,
"members": true,
"lowalch": 36,
"limit": 13000,
"value": 91,
"highalch": 54,
"icon": "Raw wild pie.png",
"name": "Raw wild pie"
},
{
"examine": "I need to cook this.",
"id": 10816,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 2,
"highalch": 1,
"icon": "Raw yak meat.png",
"name": "Raw yak meat"
},
{
"examine": "A small round red bead.",
"id": 1470,
"members": false,
"lowalch": 1,
"limit": 11000,
"value": 4,
"highalch": 2,
"icon": "Red bead.png",
"name": "Red bead"
},
{
"examine": "Parlez-vous francais?",
"id": 12247,
"members": true,
"lowalch": 32,
"limit": 4,
"value": 80,
"highalch": 48,
"icon": "Red beret.png",
"name": "Red beret"
},
{
"examine": "Stylish!",
"id": 7319,
"members": true,
"lowalch": 90,
"limit": 4,
"value": 225,
"highalch": 135,
"icon": "Red boater.png",
"name": "Red boater"
},
{
"examine": "Very stylish!",
"id": 2904,
"members": false,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Red boots.png",
"name": "Red boots"
},
{
"examine": "A bright red cape.",
"id": 1007,
"members": false,
"lowalch": 1,
"limit": 150,
"value": 2,
"highalch": 1,
"icon": "Red cape.png",
"name": "Red cape"
},
{
"examine": "All for one and one for all!",
"id": 12323,
"members": true,
"lowalch": 80,
"limit": 4,
"value": 200,
"highalch": 120,
"icon": "Red cavalier.png",
"name": "Red cavalier"
},
{
"examine": "Even more volatile than its vegetarian counterpart.",
"id": 10034,
"members": true,
"lowalch": 64,
"limit": 7000,
"value": 160,
"highalch": 96,
"icon": "Red chinchompa.png",
"name": "Red chinchompa"
},
{
"examine": "Made from 100% real dragonhide.",
"id": 2501,
"members": true,
"lowalch": 4492,
"limit": 70,
"value": 11230,
"highalch": 6738,
"icon": "Red d'hide body.png",
"name": "Red d'hide body"
},
{
"examine": "Made from 100% real dragonhide. With colourful trim!",
"id": 12327,
"members": true,
"lowalch": 4492,
"limit": 8,
"value": 11230,
"highalch": 6738,
"icon": "Red d'hide body (g).png",
"name": "Red d'hide body (g)"
},
{
"examine": "Made from 100% real dragonhide. With colourful trim!",
"id": 12331,
"members": true,
"lowalch": 4492,
"limit": 8,
"value": 11230,
"highalch": 6738,
"icon": "Red d'hide body (t).png",
"name": "Red d'hide body (t)"
},
{
"examine": "Made from 100% real dragonhide.",
"id": 2495,
"members": true,
"lowalch": 2072,
"limit": 70,
"value": 5180,
"highalch": 3108,
"icon": "Red d'hide chaps.png",
"name": "Red d'hide chaps"
},
{
"examine": "Made from 100% real dragonhide. With colourful trim!",
"id": 12329,
"members": true,
"lowalch": 2072,
"limit": 8,
"value": 5180,
"highalch": 3108,
"icon": "Red d'hide chaps (g).png",
"name": "Red d'hide chaps (g)"
},
{
"examine": "Made from 100% real dragonhide. With colourful trim!",
"id": 12333,
"members": true,
"lowalch": 2072,
"limit": 8,
"value": 5180,
"highalch": 3108,
"icon": "Red d'hide chaps (t).png",
"name": "Red d'hide chaps (t)"
},
{
"examine": "A solid magic wood shield covered in red dragon leather.",
"id": 22281,
"members": true,
"lowalch": 4400,
"limit": 125,
"value": 11000,
"highalch": 6600,
"icon": "Red d'hide shield.png",
"name": "Red d'hide shield"
},
{
"examine": "Vambraces made from 100% real dragonhide.",
"id": 2489,
"members": true,
"lowalch": 1440,
"limit": 70,
"value": 3600,
"highalch": 2160,
"icon": "Red d'hide vambraces.png",
"name": "Red d'hide vambraces"
},
{
"examine": "It's a piece of prepared red dragonhide.",
"id": 2507,
"members": true,
"lowalch": 36,
"limit": 11000,
"value": 90,
"highalch": 54,
"icon": "Red dragon leather.png",
"name": "Red dragon leather"
},
{
"examine": "Do I look scary?",
"id": 12522,
"members": true,
"lowalch": 4000,
"limit": 4,
"value": 10000,
"highalch": 6000,
"icon": "Red dragon mask.png",
"name": "Red dragon mask"
},
{
"examine": "The scaly rough hide from a Red Dragon.",
"id": 1749,
"members": true,
"lowalch": 58,
"limit": 11000,
"value": 145,
"highalch": 87,
"icon": "Red dragonhide.png",
"name": "Red dragonhide"
},
{
"examine": "A set containing a red dragonhide body, chaps and vambraces.",
"id": 12869,
"members": true,
"lowalch": 2000,
"limit": 70,
"value": 5000,
"highalch": 3000,
"icon": "Red dragonhide set.png",
"name": "Red dragonhide set"
},
{
"examine": "A little bottle of red dye.",
"id": 1763,
"members": false,
"lowalch": 2,
"limit": 150,
"value": 5,
"highalch": 3,
"icon": "Red dye.png",
"name": "Red dye"
},
{
"examine": "A well made elegant ladies' red blouse.",
"id": 10424,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Red elegant blouse.png",
"name": "Red elegant blouse"
},
{
"examine": "A rather elegant pair of men's red pantaloons.",
"id": 10406,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Red elegant legs.png",
"name": "Red elegant legs"
},
{
"examine": "A well made elegant men's red shirt.",
"id": 10404,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Red elegant shirt.png",
"name": "Red elegant shirt"
},
{
"examine": "A rather elegant red skirt.",
"id": 10426,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Red elegant skirt.png",
"name": "Red elegant skirt"
},
{
"examine": "A vivid red feather.",
"id": 10088,
"members": true,
"lowalch": 4,
"limit": 8000,
"value": 10,
"highalch": 6,
"icon": "Red feather.png",
"name": "Red feather"
},
{
"examine": "Makes firelighting a lot easier.",
"id": 7329,
"members": true,
"lowalch": 6,
"limit": 250,
"value": 15,
"highalch": 9,
"icon": "Red firelighter.png",
"name": "Red firelighter"
},
{
"examine": "A posy of flowers.",
"id": 2462,
"members": true,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Red flowers.png",
"name": "Red flowers"
},
{
"examine": "These will keep my hands warm!",
"id": 2912,
"members": false,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Red gloves.png",
"name": "Red gloves"
},
{
"examine": "Aaaarrrghhh ... I'm a monster.",
"id": 1057,
"members": false,
"lowalch": 6,
"limit": 5,
"value": 15,
"highalch": 9,
"icon": "Red halloween mask.png",
"name": "Red halloween mask"
},
{
"examine": "A silly pointed hat.",
"id": 2910,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Red hat.png",
"name": "Red hat"
},
{
"examine": "A minimalist's hat.",
"id": 2645,
"members": true,
"lowalch": 16,
"limit": 4,
"value": 40,
"highalch": 24,
"icon": "Red headband.png",
"name": "Red headband"
},
{
"examine": "A nice hat from a cracker.",
"id": 1038,
"members": false,
"lowalch": 1,
"limit": 5,
"value": 1,
"highalch": 1,
"icon": "Red partyhat.png",
"name": "Red partyhat"
},
{
"examine": "Made by werewolves for werewolves.",
"id": 2908,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Red robe bottoms.png",
"name": "Red robe bottoms"
},
{
"examine": "Some fine werewolf clothing.",
"id": 2906,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Red robe top.png",
"name": "Red robe top"
},
{
"examine": "Slightly slimy but certainly striking.",
"id": 10147,
"members": true,
"lowalch": 60,
"limit": 125,
"value": 150,
"highalch": 90,
"icon": "Red salamander.png",
"name": "Red salamander"
},
{
"examine": "Ewww!",
"id": 223,
"members": false,
"lowalch": 2,
"limit": 13000,
"value": 7,
"highalch": 4,
"icon": "Red spiders' eggs.png",
"name": "Red spiders' eggs"
},
{
"examine": "Vambraces made from 100% real dragonhide. Now with added spikiness.",
"id": 10083,
"members": true,
"lowalch": 1440,
"limit": 125,
"value": 3600,
"highalch": 2160,
"icon": "Red spiky vambraces.png",
"name": "Red spiky vambraces"
},
{
"examine": "A semi precious stone.",
"id": 1613,
"members": true,
"lowalch": 80,
"limit": 11000,
"value": 200,
"highalch": 120,
"icon": "Red topaz.png",
"name": "Red topaz"
},
{
"examine": "A jungle specific slashing device.",
"id": 6317,
"members": true,
"lowalch": 800,
"limit": 15,
"value": 2000,
"highalch": 1200,
"icon": "Red topaz machete.png",
"name": "Red topaz machete"
},
{
"examine": "Very bright red berries.",
"id": 1951,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 3,
"highalch": 1,
"icon": "Redberries.png",
"name": "Redberries"
},
{
"examine": "Looks tasty.",
"id": 2325,
"members": false,
"lowalch": 4,
"limit": 15,
"value": 12,
"highalch": 7,
"icon": "Redberry pie.png",
"name": "Redberry pie"
},
{
"examine": "A redberry bush seed - plant in a bush patch.",
"id": 5101,
"members": true,
"lowalch": 1,
"limit": 600,
"value": 4,
"highalch": 2,
"icon": "Redberry seed 5.png",
"name": "Redberry seed"
},
{
"examine": "Feed and catch the birds by placing this in the right spot.",
"id": 22204,
"members": true,
"lowalch": 2,
"limit": 250,
"value": 5,
"highalch": 3,
"icon": "Redwood bird house.png",
"name": "Redwood bird house"
},
{
"examine": "Logs cut from a redwood tree.",
"id": 19669,
"members": true,
"lowalch": 180,
"limit": 12000,
"value": 450,
"highalch": 270,
"icon": "Redwood logs.png",
"name": "Redwood logs"
},
{
"examine": "Redwood logs prepared with sacred oil for a funeral pyre.",
"id": 19672,
"members": true,
"lowalch": 358,
"limit": 11000,
"value": 895,
"highalch": 537,
"icon": "Redwood pyre logs.png",
"name": "Redwood pyre logs"
},
{
"examine": "This sapling is ready to be replanted in a Redwood patch.",
"id": 22859,
"members": true,
"lowalch": 1,
"limit": 200,
"value": 1,
"highalch": 1,
"icon": "Redwood sapling.png",
"name": "Redwood sapling"
},
{
"examine": "A solid redwood shield.",
"id": 22266,
"members": true,
"lowalch": 512,
"limit": 125,
"value": 1280,
"highalch": 768,
"icon": "Redwood shield.png",
"name": "Redwood shield"
},
{
"examine": "Plant this in a plantpot of soil to grow a sapling.",
"id": 22871,
"members": true,
"lowalch": 260,
"limit": 200,
"value": 650,
"highalch": 390,
"icon": "Redwood tree seed 5.png",
"name": "Redwood tree seed"
},
{
"examine": "Helps to restore Hitpoints.",
"id": 11133,
"members": true,
"lowalch": 80800,
"limit": 70,
"value": 202000,
"highalch": 121200,
"icon": "Regen bracelet.png",
"name": "Regen bracelet"
},
{
"examine": "1 dose of Relicym's balm, which helps cure disease.",
"id": 4848,
"members": true,
"lowalch": 30,
"limit": 2000,
"value": 75,
"highalch": 45,
"icon": "Relicym's balm(1).png",
"name": "Relicym's balm(1)"
},
{
"examine": "2 doses of Relicym's balm, which helps cure disease.",
"id": 4846,
"members": true,
"lowalch": 60,
"limit": 2000,
"value": 150,
"highalch": 90,
"icon": "Relicym's balm(2).png",
"name": "Relicym's balm(2)"
},
{
"examine": "3 doses of Relicym's balm, which helps cure disease.",
"id": 4844,
"members": true,
"lowalch": 80,
"limit": 2000,
"value": 200,
"highalch": 120,
"icon": "Relicym's balm(3).png",
"name": "Relicym's balm(3)"
},
{
"examine": "4 doses of Relicym's balm, which helps cure disease.",
"id": 4842,
"members": true,
"lowalch": 90,
"limit": 2000,
"value": 225,
"highalch": 135,
"icon": "Relicym's balm(4).png",
"name": "Relicym's balm(4)"
},
{
"examine": "One dose of fishy Relicym's balm.",
"id": 11439,
"members": true,
"lowalch": 30,
"limit": 2000,
"value": 75,
"highalch": 45,
"icon": "Relicym's mix(1).png",
"name": "Relicym's mix(1)"
},
{
"examine": "Two doses of fishy Relicym's balm.",
"id": 11437,
"members": true,
"lowalch": 60,
"limit": 2000,
"value": 150,
"highalch": 90,
"icon": "Relicym's mix(2).png",
"name": "Relicym's mix(2)"
},
{
"examine": "One dose of fishy restore potion.",
"id": 11451,
"members": true,
"lowalch": 17,
"limit": 2000,
"value": 44,
"highalch": 26,
"icon": "Restore mix(1).png",
"name": "Restore mix(1)"
},
{
"examine": "Two doses of fishy restore potion.",
"id": 11449,
"members": true,
"lowalch": 26,
"limit": 2000,
"value": 66,
"highalch": 39,
"icon": "Restore mix(2).png",
"name": "Restore mix(2)"
},
{
"examine": "1 dose of restore potion.",
"id": 131,
"members": true,
"lowalch": 17,
"limit": 2000,
"value": 44,
"highalch": 26,
"icon": "Restore potion(1).png",
"name": "Restore potion(1)"
},
{
"examine": "2 doses of restore potion.",
"id": 129,
"members": true,
"lowalch": 26,
"limit": 2000,
"value": 66,
"highalch": 39,
"icon": "Restore potion(2).png",
"name": "Restore potion(2)"
},
{
"examine": "3 doses of restore potion.",
"id": 127,
"members": true,
"lowalch": 35,
"limit": 2000,
"value": 88,
"highalch": 52,
"icon": "Restore potion(3).png",
"name": "Restore potion(3)"
},
{
"examine": "4 doses of restore potion.",
"id": 2430,
"members": true,
"lowalch": 44,
"limit": 2000,
"value": 110,
"highalch": 66,
"icon": "Restore potion(4).png",
"name": "Restore potion(4)"
},
{
"examine": "Teleports you to the Revenant cave in the Wilderness.",
"id": 21802,
"members": true,
"lowalch": 4,
"limit": 10000,
"value": 10,
"highalch": 6,
"icon": "Revenant cave teleport.png",
"name": "Revenant cave teleport"
},
{
"examine": "Ethereal essence of the revenants.",
"id": 21820,
"members": true,
"lowalch": 100,
"limit": 30000,
"value": 250,
"highalch": 150,
"icon": "Revenant ether 5.png",
"name": "Revenant ether"
},
{
"examine": "A slightly damaged ribcage.",
"id": 6165,
"members": true,
"lowalch": 24,
"limit": 11000,
"value": 60,
"highalch": 36,
"icon": "Ribcage piece.png",
"name": "Ribcage piece"
},
{
"examine": "A black piece of cloth on a string.",
"id": 1025,
"members": true,
"lowalch": 1,
"limit": 150,
"value": 2,
"highalch": 1,
"icon": "Right eye patch.png",
"name": "Right eye patch"
},
{
"examine": "Used to make rings.",
"id": 1592,
"members": false,
"lowalch": 2,
"limit": 40,
"value": 5,
"highalch": 3,
"icon": "Ring mould.png",
"name": "Ring mould"
},
{
"examine": "Makes you look like something really old, for those moments when you want that.",
"id": 23185,
"members": false,
"lowalch": 4800,
"limit": 4,
"value": 12000,
"highalch": 7200,
"icon": "Ring of 3rd age.png",
"name": "Ring of 3rd age"
},
{
"examine": "A valuable ring.",
"id": 20017,
"members": false,
"lowalch": 3200,
"limit": 4,
"value": 8000,
"highalch": 4800,
"icon": "Ring of coins.png",
"name": "Ring of coins"
},
{
"examine": "An enchanted ring.",
"id": 2552,
"members": true,
"lowalch": 510,
"limit": 15000,
"value": 1275,
"highalch": 765,
"icon": "Ring of dueling(8).png",
"name": "Ring of dueling(8)"
},
{
"highalch": 38400,
"members": true,
"name": "Ring of endurance (uncharged)",
"examine": "I wonder if this ring helps with...",
"id": 24844,
"value": 64000,
"icon": "Ring of endurance (uncharged).png",
"lowalch": 25600
},
{
"examine": "An enchanted ring.",
"id": 2568,
"members": false,
"lowalch": 810,
"limit": 10000,
"value": 2025,
"highalch": 1215,
"icon": "Ring of forging.png",
"name": "Ring of forging"
},
{
"examine": "An enchanted ring.",
"id": 2570,
"members": true,
"lowalch": 1410,
"limit": 10000,
"value": 3525,
"highalch": 2115,
"icon": "Ring of life.png",
"name": "Ring of life"
},
{
"examine": "A ring shaped like the nature rune symbol.",
"id": 20005,
"members": false,
"lowalch": 3200,
"limit": 4,
"value": 8000,
"highalch": 4800,
"icon": "Ring of nature.png",
"name": "Ring of nature"
},
{
"examine": "This ring can reveal a hunter creature's track.",
"id": 21126,
"members": true,
"lowalch": 420,
"limit": 100,
"value": 1050,
"highalch": 630,
"icon": "Ring of pursuit.png",
"name": "Ring of pursuit"
},
{
"examine": "An enchanted ring.",
"id": 2550,
"members": true,
"lowalch": 360,
"limit": 10000,
"value": 900,
"highalch": 540,
"icon": "Ring of recoil.png",
"name": "Ring of recoil"
},
{
"examine": "This ring returns you to your spawn point.",
"id": 21129,
"members": true,
"lowalch": 510,
"limit": 10000,
"value": 1275,
"highalch": 765,
"icon": "Ring of returning.png",
"name": "Ring of returning(5)"
},
{
"examine": "An enchanted ring.",
"id": 6583,
"members": true,
"lowalch": 80800,
"limit": 70,
"value": 202000,
"highalch": 121200,
"icon": "Ring of stone.png",
"name": "Ring of stone"
},
{
"examine": "A deep sense of suffering burns within this powerful ring.",
"id": 19550,
"members": true,
"lowalch": 80400,
"limit": 8,
"value": 201000,
"highalch": 120600,
"icon": "Ring of suffering.png",
"name": "Ring of suffering"
},
{
"highalch": 1,
"members": true,
"name": "Ring of the elements",
"examine": "A mystical ring.",
"id": 26815,
"value": 1,
"icon": "Ring of the elements.png",
"lowalch": 1
},
{
"examine": "An ancient ring said to bring you closer to the Gods.",
"id": 12601,
"members": true,
"lowalch": 20000,
"limit": 8,
"value": 50000,
"highalch": 30000,
"icon": "Ring of the gods.png",
"name": "Ring of the gods"
},
{
"examine": "It can be charged at the Fountain of Rune.",
"id": 2572,
"members": true,
"lowalch": 7050,
"limit": 10000,
"value": 17625,
"highalch": 10575,
"icon": "Ring of wealth.png",
"name": "Ring of wealth"
},
{
"examine": "An enchanted ring with five teleport charges.",
"id": 11980,
"members": true,
"lowalch": 7050,
"limit": 10000,
"value": 17625,
"highalch": 10575,
"icon": "Ring of wealth (5).png",
"name": "Ring of wealth (5)"
},
{
"examine": "Use on a Ring of Wealth to imbue the ring.",
"id": 12783,
"members": true,
"lowalch": 2000,
"limit": 10000,
"value": 5000,
"highalch": 3000,
"icon": "Ring of wealth scroll.png",
"name": "Ring of wealth scroll"
},
{
"highalch": 1,
"members": true,
"name": "Ritual mulch",
"examine": "I can use items with this for nature offerings.",
"id": 28154,
"value": 1,
"icon": "Ritual mulch.png",
"lowalch": 1
},
{
"examine": "The remains of a deadly shade.",
"id": 3400,
"members": true,
"lowalch": 1,
"limit": 7500,
"value": 1,
"highalch": 1,
"icon": "Riyl remains.png",
"name": "Riyl remains"
},
{
"examine": "A delicious looking slab of roast beast.",
"id": 9988,
"members": true,
"lowalch": 10,
"limit": 6000,
"value": 25,
"highalch": 15,
"icon": "Roast beast meat.png",
"name": "Roast beast meat"
},
{
"examine": "A nicely roasted bird.",
"id": 9980,
"members": true,
"lowalch": 6,
"limit": 6000,
"value": 17,
"highalch": 10,
"icon": "Roast bird meat.png",
"name": "Roast bird meat"
},
{
"examine": "A delicious looking piece of roast rabbit.",
"id": 7223,
"members": true,
"lowalch": 8,
"limit": 6000,
"value": 20,
"highalch": 12,
"icon": "Roast rabbit.png",
"name": "Roast rabbit"
},
{
"examine": "A dark power is woven into this robe bottom.",
"id": 20137,
"members": true,
"lowalch": 32000,
"limit": 70,
"value": 80000,
"highalch": 48000,
"icon": "Robe bottom of darkness.png",
"name": "Robe bottom of darkness"
},
{
"examine": "A dark power is woven into this robe top.",
"id": 20131,
"members": true,
"lowalch": 48000,
"limit": 70,
"value": 120000,
"highalch": 72000,
"icon": "Robe top of darkness.png",
"name": "Robe top of darkness"
},
{
"examine": "Endorsed by Robin Hood.",
"id": 2581,
"members": true,
"lowalch": 180,
"limit": 8,
"value": 450,
"highalch": 270,
"icon": "Robin hood hat.png",
"name": "Robin hood hat"
},
{
"examine": "I can even smash stone with this.",
"id": 4162,
"members": true,
"lowalch": 200,
"limit": 40,
"value": 500,
"highalch": 300,
"icon": "Rock hammer.png",
"name": "Rock hammer"
},
{
"examine": "Smash gargoyles from a distance.",
"id": 21754,
"members": true,
"lowalch": 80,
"limit": 11000,
"value": 200,
"highalch": 120,
"icon": "Rock thrownhammer.png",
"name": "Rock thrownhammer"
},
{
"examine": "These boots were made for trolling.",
"id": 2203,
"members": true,
"lowalch": 1,
"limit": 0,
"value": 1,
"highalch": 1,
"icon": "Rock-climbing boots.png",
"name": "Rock-climbing boots"
},
{
"examine": "Some Fremennik boots, made from the shards of a rock crab's shell.",
"id": 6145,
"members": true,
"lowalch": 260,
"limit": 125,
"value": 650,
"highalch": 390,
"icon": "Rock-shell boots.png",
"name": "Rock-shell boots"
},
{
"examine": "A spherical chunk of rock-shell.",
"id": 6157,
"members": true,
"lowalch": 24,
"limit": 11000,
"value": 60,
"highalch": 36,
"icon": "Rock-shell chunk.png",
"name": "Rock-shell chunk"
},
{
"examine": "Fremennik gloves stitched together from rock crab shell shards.",
"id": 6151,
"members": true,
"lowalch": 260,
"limit": 125,
"value": 650,
"highalch": 390,
"icon": "Rock-shell gloves.png",
"name": "Rock-shell gloves"
},
{
"examine": "Protective headwear made from crabs. Better than that sounds.",
"id": 6128,
"members": true,
"lowalch": 14080,
"limit": 70,
"value": 35200,
"highalch": 21120,
"icon": "Rock-shell helm.png",
"name": "Rock-shell helm"
},
{
"examine": "Some tough leggings made from rock crab parts.",
"id": 6130,
"members": true,
"lowalch": 25600,
"limit": 70,
"value": 64000,
"highalch": 38400,
"icon": "Rock-shell legs.png",
"name": "Rock-shell legs"
},
{
"examine": "A sturdy body armour made from rock crab pieces.",
"id": 6129,
"members": true,
"lowalch": 26000,
"limit": 70,
"value": 65000,
"highalch": 39000,
"icon": "Rock-shell plate.png",
"name": "Rock-shell plate"
},
{
"examine": "A curved piece of rock-shell.",
"id": 6159,
"members": true,
"lowalch": 24,
"limit": 11000,
"value": 60,
"highalch": 36,
"icon": "Rock-shell shard.png",
"name": "Rock-shell shard"
},
{
"examine": "A slim piece of rock-shell.",
"id": 6161,
"members": true,
"lowalch": 24,
"limit": 11000,
"value": 60,
"highalch": 36,
"icon": "Rock-shell splinter.png",
"name": "Rock-shell splinter"
},
{
"examine": "How does it all fit in there?",
"id": 8500,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Rocking chair (flatpack).png",
"name": "Rocking chair (flatpack)"
},
{
"examine": "Roe, or cheap fishy eggs.",
"id": 11324,
"members": true,
"lowalch": 8,
"limit": 13000,
"value": 20,
"highalch": 12,
"icon": "Roe.png",
"name": "Roe"
},
{
"examine": "That's how I roll!",
"id": 7445,
"members": true,
"lowalch": 5760,
"limit": 50,
"value": 14400,
"highalch": 8640,
"icon": "Rolling pin.png",
"name": "Rolling pin"
},
{
"examine": "A coil of rope.",
"id": 954,
"members": false,
"lowalch": 7,
"limit": 250,
"value": 18,
"highalch": 10,
"icon": "Rope.png",
"name": "Rope"
},
{
"examine": "Some rosemary.",
"id": 6014,
"members": true,
"lowalch": 1,
"limit": 600,
"value": 2,
"highalch": 1,
"icon": "Rosemary.png",
"name": "Rosemary"
},
{
"examine": "A rosemary seed - plant in a flower patch.",
"id": 5097,
"members": true,
"lowalch": 1,
"limit": 600,
"value": 4,
"highalch": 2,
"icon": "Rosemary seed 5.png",
"name": "Rosemary seed"
},
{
"examine": "Who said I'd never be royal?",
"id": 12397,
"members": true,
"lowalch": 2400,
"limit": 4,
"value": 6000,
"highalch": 3600,
"icon": "Royal crown.png",
"name": "Royal crown"
},
{
"examine": "Feeling distinctly ornate.",
"id": 12395,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Royal gown bottom.png",
"name": "Royal gown bottom"
},
{
"examine": "Feeling distinctly ornate.",
"id": 12393,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Royal gown top.png",
"name": "Royal gown top"
},
{
"examine": "I dub thee...",
"id": 12439,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Royal sceptre.png",
"name": "Royal sceptre"
},
{
"examine": "This looks valuable.",
"id": 1603,
"members": false,
"lowalch": 400,
"limit": 13000,
"value": 1000,
"highalch": 600,
"icon": "Ruby.png",
"name": "Ruby"
},
{
"examine": "I wonder if I can get this enchanted.",
"id": 1698,
"members": false,
"lowalch": 810,
"limit": 10000,
"value": 2025,
"highalch": 1215,
"icon": "Ruby amulet.png",
"name": "Ruby amulet"
},
{
"examine": "It needs a string so I can wear it.",
"id": 1679,
"members": false,
"lowalch": 810,
"limit": 10000,
"value": 2025,
"highalch": 1215,
"icon": "Ruby amulet (u).png",
"name": "Ruby amulet (u)"
},
{
"examine": "Ruby bolt tips.",
"id": 9191,
"members": true,
"lowalch": 26,
"limit": 11000,
"value": 67,
"highalch": 40,
"icon": "Ruby bolt tips 5.png",
"name": "Ruby bolt tips"
},
{
"examine": "Ruby tipped Adamantite crossbow bolts.",
"id": 9339,
"members": true,
"lowalch": 50,
"limit": 11000,
"value": 125,
"highalch": 75,
"icon": "Ruby bolts 5.png",
"name": "Ruby bolts"
},
{
"examine": "Enchanted Ruby tipped Adamantite Crossbow Bolts.",
"id": 9242,
"members": true,
"lowalch": 55,
"limit": 11000,
"value": 138,
"highalch": 82,
"icon": "Ruby bolts (e) 5.png",
"name": "Ruby bolts (e)"
},
{
"examine": "I wonder if this is valuable.",
"id": 11085,
"members": true,
"lowalch": 930,
"limit": 10000,
"value": 2325,
"highalch": 1395,
"icon": "Ruby bracelet.png",
"name": "Ruby bracelet"
},
{
"examine": "Dragon crossbow bolts, tipped with ruby.",
"id": 21967,
"members": true,
"lowalch": 320,
"limit": 11000,
"value": 800,
"highalch": 480,
"icon": "Ruby dragon bolts 5.png",
"name": "Ruby dragon bolts"
},
{
"examine": "Enchanted Dragon crossbow bolts, tipped with ruby.",
"id": 21944,
"members": true,
"lowalch": 332,
"limit": 11000,
"value": 830,
"highalch": 498,
"icon": "Ruby dragon bolts (e) 5.png",
"name": "Ruby dragon bolts (e)"
},
{
"examine": "There's a ruby harvest butterfly in here.",
"id": 10020,
"members": true,
"lowalch": 7,
"limit": 125,
"value": 18,
"highalch": 10,
"icon": "Ruby harvest (item).png",
"name": "Ruby harvest (item)"
},
{
"highalch": 10,
"members": true,
"name": "Ruby harvest mix (1)",
"examine": "There's a ruby harvest butterfly in here.",
"id": 29204,
"value": 18,
"icon": "Ruby harvest mix (1).png",
"lowalch": 7
},
{
"highalch": 10,
"members": true,
"name": "Ruby harvest mix (2)",
"examine": "There's a ruby harvest butterfly in here.",
"id": 29186,
"value": 18,
"icon": "Ruby harvest mix (2).png",
"lowalch": 7
},
{
"examine": "I wonder if this is valuable.",
"id": 1660,
"members": false,
"lowalch": 870,
"limit": 18000,
"value": 2175,
"highalch": 1305,
"icon": "Ruby necklace.png",
"name": "Ruby necklace"
},
{
"examine": "A valuable ring.",
"id": 1641,
"members": false,
"lowalch": 810,
"limit": 10000,
"value": 2025,
"highalch": 1215,
"icon": "Ruby ring.png",
"name": "Ruby ring"
},
{
"highalch": 3,
"members": true,
"name": "Rum",
"examine": "A strong spirit with a bold flavour.",
"id": 28896,
"value": 5,
"icon": "Rum.png",
"lowalch": 2
},
{
"examine": "A two handed sword.",
"id": 1319,
"members": false,
"lowalch": 25600,
"limit": 70,
"value": 64000,
"highalch": 38400,
"icon": "Rune 2h sword.png",
"name": "Rune 2h sword"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 13024,
"members": false,
"lowalch": 30000,
"limit": 8,
"value": 75000,
"highalch": 45000,
"icon": "Rune armour set (lg).png",
"name": "Rune armour set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 13026,
"members": false,
"lowalch": 30000,
"limit": 8,
"value": 75000,
"highalch": 45000,
"icon": "Rune armour set (sk).png",
"name": "Rune armour set (sk)"
},
{
"examine": "Arrows with rune heads.",
"id": 892,
"members": true,
"lowalch": 160,
"limit": 11000,
"value": 400,
"highalch": 240,
"icon": "Rune arrow 5.png",
"name": "Rune arrow"
},
{
"examine": "Venomous-looking arrows.",
"id": 893,
"members": true,
"lowalch": 160,
"limit": 11000,
"value": 400,
"highalch": 240,
"icon": "Rune arrow(p) 5.png",
"name": "Rune arrow(p)"
},
{
"examine": "Venomous-looking arrows.",
"id": 5621,
"members": true,
"lowalch": 160,
"limit": 11000,
"value": 400,
"highalch": 240,
"icon": "Rune arrow(p+) 5.png",
"name": "Rune arrow(p+)"
},
{
"examine": "Venomous-looking arrows.",
"id": 5627,
"members": true,
"lowalch": 160,
"limit": 11000,
"value": 400,
"highalch": 240,
"icon": "Rune arrow(p++) 5.png",
"name": "Rune arrow(p++)"
},
{
"examine": "I can make some arrows with these.",
"id": 44,
"members": true,
"lowalch": 80,
"limit": 10000,
"value": 200,
"highalch": 120,
"icon": "Rune arrowtips.png",
"name": "Rune arrowtips"
},
{
"examine": "A powerful axe.",
"id": 1359,
"members": false,
"lowalch": 5120,
"limit": 40,
"value": 12800,
"highalch": 7680,
"icon": "Rune axe.png",
"name": "Rune axe"
},
{
"examine": "A vicious looking axe.",
"id": 1373,
"members": false,
"lowalch": 16640,
"limit": 70,
"value": 41600,
"highalch": 24960,
"icon": "Rune battleaxe.png",
"name": "Rune battleaxe"
},
{
"examine": "These will protect my feet.",
"id": 4131,
"members": true,
"lowalch": 5000,
"limit": 70,
"value": 12500,
"highalch": 7500,
"icon": "Rune boots.png",
"name": "Rune boots"
},
{
"examine": "Blunt rune arrow... ouch.",
"id": 4803,
"members": true,
"lowalch": 180,
"limit": 11000,
"value": 450,
"highalch": 270,
"icon": "Rune brutal 5.png",
"name": "Rune brutal"
},
{
"examine": "A dragonstone topped cane.",
"id": 12379,
"members": true,
"lowalch": 5760,
"limit": 4,
"value": 14400,
"highalch": 8640,
"icon": "Rune cane.png",
"name": "Rune cane"
},
{
"examine": "A series of connected metal rings.",
"id": 1113,
"members": false,
"lowalch": 20000,
"limit": 70,
"value": 50000,
"highalch": 30000,
"icon": "Rune chainbody.png",
"name": "Rune chainbody"
},
{
"examine": "A set of fighting claws.",
"id": 3101,
"members": true,
"lowalch": 4800,
"limit": 70,
"value": 12000,
"highalch": 7200,
"icon": "Rune claws.png",
"name": "Rune claws"
},
{
"examine": "A runite crossbow.",
"id": 9185,
"members": true,
"lowalch": 6480,
"limit": 70,
"value": 16200,
"highalch": 9720,
"icon": "Rune crossbow.png",
"name": "Rune crossbow"
},
{
"examine": "A powerful dagger.",
"id": 1213,
"members": false,
"lowalch": 3200,
"limit": 70,
"value": 8000,
"highalch": 4800,
"icon": "Rune dagger.png",
"name": "Rune dagger"
},
{
"examine": "The blade is covered with a nasty poison.",
"id": 1229,
"members": true,
"lowalch": 3200,
"limit": 70,
"value": 8000,
"highalch": 4800,
"icon": "Rune dagger(p).png",
"name": "Rune dagger(p)"
},
{
"examine": "The blade is covered with a nasty poison.",
"id": 5678,
"members": true,
"lowalch": 3200,
"limit": 70,
"value": 8000,
"highalch": 4800,
"icon": "Rune dagger(p+).png",
"name": "Rune dagger(p+)"
},
{
"examine": "The blade is covered with a nasty poison.",
"id": 5696,
"members": true,
"lowalch": 3200,
"limit": 70,
"value": 8000,
"highalch": 4800,
"icon": "Rune dagger(p++).png",
"name": "Rune dagger(p++)"
},
{
"examine": "A deadly throwing dart with a rune tip.",
"id": 811,
"members": true,
"lowalch": 140,
"limit": 11000,
"value": 350,
"highalch": 210,
"icon": "Rune dart.png",
"name": "Rune dart"
},
{
"examine": "A deadly-looking dart tip made of runite - needs feathers for flight.",
"id": 824,
"members": true,
"lowalch": 70,
"limit": 11000,
"value": 175,
"highalch": 105,
"icon": "Rune dart tip.png",
"name": "Rune dart tip"
},
{
"examine": "A deadly poisoned dart with a rune tip.",
"id": 817,
"members": true,
"lowalch": 140,
"limit": 11000,
"value": 350,
"highalch": 210,
"icon": "Rune dart(p).png",
"name": "Rune dart(p)"
},
{
"examine": "A deadly poisoned dart with a rune tip.",
"id": 5634,
"members": true,
"lowalch": 140,
"limit": 11000,
"value": 350,
"highalch": 210,
"icon": "Rune dart(p+).png",
"name": "Rune dart(p+)"
},
{
"examine": "A deadly poisoned dart with a rune tip.",
"id": 5641,
"members": true,
"lowalch": 140,
"limit": 11000,
"value": 350,
"highalch": 210,
"icon": "Rune dart(p++).png",
"name": "Rune dart(p++)"
},
{
"examine": "Use on the rune defender to trim it with gold.",
"id": 23227,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Rune defender ornament kit.png",
"name": "Rune defender ornament kit"
},
{
"examine": "Do I look scary?",
"id": 23273,
"members": true,
"lowalch": 4000,
"limit": 4,
"value": 10000,
"highalch": 6000,
"icon": "Rune dragon mask.png",
"name": "Rune dragon mask"
},
{
"examine": "An unimbued rune.",
"id": 1436,
"members": false,
"lowalch": 1,
"limit": 20000,
"value": 4,
"highalch": 2,
"icon": "Rune essence.png",
"name": "Rune essence"
},
{
"highalch": 7680,
"members": true,
"name": "Rune felling axe",
"examine": "A woodcutter's axe.",
"id": 28214,
"value": 12800,
"icon": "Rune felling axe.png",
"lowalch": 5120
},
{
"examine": "A full face helmet.",
"id": 1163,
"members": false,
"lowalch": 14080,
"limit": 70,
"value": 35200,
"highalch": 21120,
"icon": "Rune full helm.png",
"name": "Rune full helm"
},
{
"examine": "Rune full helmet with gold trim.",
"id": 2619,
"members": false,
"lowalch": 14080,
"limit": 8,
"value": 35200,
"highalch": 21120,
"icon": "Rune full helm (g).png",
"name": "Rune full helm (g)"
},
{
"examine": "Rune full helmet with trim.",
"id": 2627,
"members": false,
"lowalch": 14080,
"limit": 8,
"value": 35200,
"highalch": 21120,
"icon": "Rune full helm (t).png",
"name": "Rune full helm (t)"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 13032,
"members": false,
"lowalch": 32000,
"limit": 8,
"value": 80000,
"highalch": 48000,
"icon": "Rune gold-trimmed set (lg).png",
"name": "Rune gold-trimmed set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 13034,
"members": false,
"lowalch": 32000,
"limit": 8,
"value": 80000,
"highalch": 48000,
"icon": "Rune gold-trimmed set (sk).png",
"name": "Rune gold-trimmed set (sk)"
},
{
"examine": "A rune halberd.",
"id": 3202,
"members": true,
"lowalch": 25600,
"limit": 70,
"value": 64000,
"highalch": 38400,
"icon": "Rune halberd.png",
"name": "Rune halberd"
},
{
"examine": "A rune-tipped, one-handed hasta.",
"id": 11377,
"members": true,
"lowalch": 8320,
"limit": 70,
"value": 20800,
"highalch": 12480,
"icon": "Rune hasta.png",
"name": "Rune hasta"
},
{
"examine": "A poison-tipped, one-handed rune hasta.",
"id": 11414,
"members": true,
"lowalch": 8320,
"limit": 70,
"value": 20800,
"highalch": 12480,
"icon": "Rune hasta(p).png",
"name": "Rune hasta(p)"
},
{
"examine": "A poison-tipped, one-handed rune hasta.",
"id": 11417,
"members": true,
"lowalch": 8320,
"limit": 70,
"value": 20800,
"highalch": 12480,
"icon": "Rune hasta(p+).png",
"name": "Rune hasta(p+)"
},
{
"examine": "A poison-tipped, one-handed rune hasta.",
"id": 11419,
"members": true,
"lowalch": 8320,
"limit": 70,
"value": 20800,
"highalch": 12480,
"icon": "Rune hasta(p++).png",
"name": "Rune hasta(p++)"
},
{
"examine": "A rune helmet with a heraldic design.",
"id": 10286,
"members": false,
"lowalch": 14080,
"limit": 70,
"value": 35200,
"highalch": 21120,
"icon": "Rune helm (h1).png",
"name": "Rune helm (h1)"
},
{
"examine": "A rune helmet with a heraldic design.",
"id": 10288,
"members": false,
"lowalch": 14080,
"limit": 70,
"value": 35200,
"highalch": 21120,
"icon": "Rune helm (h2).png",
"name": "Rune helm (h2)"
},
{
"examine": "A rune helmet with a heraldic design.",
"id": 10290,
"members": false,
"lowalch": 14080,
"limit": 70,
"value": 35200,
"highalch": 21120,
"icon": "Rune helm (h3).png",
"name": "Rune helm (h3)"
},
{
"examine": "A rune helmet with a heraldic design.",
"id": 10292,
"members": false,
"lowalch": 14080,
"limit": 70,
"value": 35200,
"highalch": 21120,
"icon": "Rune helm (h4).png",
"name": "Rune helm (h4)"
},
{
"examine": "A rune helmet with a heraldic design.",
"id": 10294,
"members": false,
"lowalch": 14080,
"limit": 70,
"value": 35200,
"highalch": 21120,
"icon": "Rune helm (h5).png",
"name": "Rune helm (h5)"
},
{
"examine": "A rune tipped javelin.",
"id": 830,
"members": true,
"lowalch": 160,
"limit": 11000,
"value": 400,
"highalch": 240,
"icon": "Rune javelin.png",
"name": "Rune javelin"
},
{
"examine": "Needs a shaft.",
"id": 19580,
"members": true,
"lowalch": 540,
"limit": 10000,
"value": 1350,
"highalch": 810,
"icon": "Rune javelin heads 5.png",
"name": "Rune javelin heads"
},
{
"examine": "A rune tipped javelin.",
"id": 836,
"members": true,
"lowalch": 160,
"limit": 7000,
"value": 400,
"highalch": 240,
"icon": "Rune javelin(p).png",
"name": "Rune javelin(p)"
},
{
"examine": "A rune tipped javelin.",
"id": 5647,
"members": true,
"lowalch": 160,
"limit": 11000,
"value": 400,
"highalch": 240,
"icon": "Rune javelin(p+).png",
"name": "Rune javelin(p+)"
},
{
"examine": "A rune tipped javelin.",
"id": 5653,
"members": true,
"lowalch": 160,
"limit": 11000,
"value": 400,
"highalch": 240,
"icon": "Rune javelin(p++).png",
"name": "Rune javelin(p++)"
},
{
"examine": "A large metal shield.",
"id": 1201,
"members": false,
"lowalch": 21760,
"limit": 70,
"value": 54400,
"highalch": 32640,
"icon": "Rune kiteshield.png",
"name": "Rune kiteshield"
},
{
"examine": "Rune kiteshield with gold trim.",
"id": 2621,
"members": false,
"lowalch": 21760,
"limit": 8,
"value": 54400,
"highalch": 32640,
"icon": "Rune kiteshield (g).png",
"name": "Rune kiteshield (g)"
},
{
"examine": "Rune kiteshield with trim.",
"id": 2629,
"members": false,
"lowalch": 21760,
"limit": 8,
"value": 54400,
"highalch": 32640,
"icon": "Rune kiteshield (t).png",
"name": "Rune kiteshield (t)"
},
{
"examine": "A finely balanced throwing knife.",
"id": 868,
"members": true,
"lowalch": 66,
"limit": 11000,
"value": 167,
"highalch": 100,
"icon": "Rune knife.png",
"name": "Rune knife"
},
{
"examine": "A finely balanced throwing knife.",
"id": 876,
"members": true,
"lowalch": 66,
"limit": 7000,
"value": 166,
"highalch": 99,
"icon": "Rune knife(p).png",
"name": "Rune knife(p)"
},
{
"examine": "A finely balanced throwing knife.",
"id": 5660,
"members": true,
"lowalch": 66,
"limit": 7000,
"value": 166,
"highalch": 99,
"icon": "Rune knife(p+).png",
"name": "Rune knife(p+)"
},
{
"examine": "A finely balanced throwing knife.",
"id": 5667,
"members": true,
"lowalch": 66,
"limit": 7000,
"value": 166,
"highalch": 99,
"icon": "Rune knife(p++).png",
"name": "Rune knife(p++)"
},
{
"examine": "A razor sharp longsword.",
"id": 1303,
"members": false,
"lowalch": 12800,
"limit": 70,
"value": 32000,
"highalch": 19200,
"icon": "Rune longsword.png",
"name": "Rune longsword"
},
{
"examine": "A spiky mace.",
"id": 1432,
"members": false,
"lowalch": 5760,
"limit": 70,
"value": 14400,
"highalch": 8640,
"icon": "Rune mace.png",
"name": "Rune mace"
},
{
"examine": "A medium sized helmet.",
"id": 1147,
"members": false,
"lowalch": 7680,
"limit": 70,
"value": 19200,
"highalch": 11520,
"icon": "Rune med helm.png",
"name": "Rune med helm"
},
{
"examine": "Keeps things in place fairly permanently.",
"id": 4824,
"members": true,
"lowalch": 88,
"limit": 13000,
"value": 220,
"highalch": 132,
"icon": "Rune nails.png",
"name": "Rune nails"
},
{
"examine": "Used for mining.",
"id": 1275,
"members": false,
"lowalch": 12800,
"limit": 40,
"value": 32000,
"highalch": 19200,
"icon": "Rune pickaxe.png",
"name": "Rune pickaxe"
},
{
"examine": "Provides excellent protection.",
"id": 1127,
"members": false,
"lowalch": 26000,
"limit": 70,
"value": 65000,
"highalch": 39000,
"icon": "Rune platebody.png",
"name": "Rune platebody"
},
{
"examine": "Rune platebody with gold trim.",
"id": 2615,
"members": false,
"lowalch": 26000,
"limit": 8,
"value": 65000,
"highalch": 39000,
"icon": "Rune platebody (g).png",
"name": "Rune platebody (g)"
},
{
"examine": "Provides excellent protection with a heraldic design.",
"id": 23209,
"members": false,
"lowalch": 26000,
"limit": 8,
"value": 65000,
"highalch": 39000,
"icon": "Rune platebody (h1).png",
"name": "Rune platebody (h1)"
},
{
"examine": "Provides excellent protection with a heraldic design.",
"id": 23212,
"members": false,
"lowalch": 26000,
"limit": 8,
"value": 65000,
"highalch": 39000,
"icon": "Rune platebody (h2).png",
"name": "Rune platebody (h2)"
},
{
"examine": "Provides excellent protection with a heraldic design.",
"id": 23215,
"members": false,
"lowalch": 26000,
"limit": 8,
"value": 65000,
"highalch": 39000,
"icon": "Rune platebody (h3).png",
"name": "Rune platebody (h3)"
},
{
"examine": "Provides excellent protection with a heraldic design.",
"id": 23218,
"members": false,
"lowalch": 26000,
"limit": 8,
"value": 65000,
"highalch": 39000,
"icon": "Rune platebody (h4).png",
"name": "Rune platebody (h4)"
},
{
"examine": "Provides excellent protection with a heraldic design.",
"id": 23221,
"members": false,
"lowalch": 26000,
"limit": 8,
"value": 65000,
"highalch": 39000,
"icon": "Rune platebody (h5).png",
"name": "Rune platebody (h5)"
},
{
"examine": "Rune platebody with trim.",
"id": 2623,
"members": false,
"lowalch": 26000,
"limit": 8,
"value": 65000,
"highalch": 39000,
"icon": "Rune platebody (t).png",
"name": "Rune platebody (t)"
},
{
"examine": "These look pretty heavy.",
"id": 1079,
"members": false,
"lowalch": 25600,
"limit": 70,
"value": 64000,
"highalch": 38400,
"icon": "Rune platelegs.png",
"name": "Rune platelegs"
},
{
"examine": "Rune platelegs with gold trim.",
"id": 2617,
"members": false,
"lowalch": 25600,
"limit": 8,
"value": 64000,
"highalch": 38400,
"icon": "Rune platelegs (g).png",
"name": "Rune platelegs (g)"
},
{
"examine": "Rune platelegs with trim.",
"id": 2625,
"members": false,
"lowalch": 25600,
"limit": 8,
"value": 64000,
"highalch": 38400,
"icon": "Rune platelegs (t).png",
"name": "Rune platelegs (t)"
},
{
"examine": "Designer leg protection.",
"id": 1093,
"members": false,
"lowalch": 25600,
"limit": 70,
"value": 64000,
"highalch": 38400,
"icon": "Rune plateskirt.png",
"name": "Rune plateskirt"
},
{
"examine": "Rune plateskirt with gold trim.",
"id": 3476,
"members": false,
"lowalch": 25600,
"limit": 8,
"value": 64000,
"highalch": 38400,
"icon": "Rune plateskirt (g).png",
"name": "Rune plateskirt (g)"
},
{
"examine": "Rune plateskirt with trim.",
"id": 3477,
"members": false,
"lowalch": 25600,
"limit": 8,
"value": 64000,
"highalch": 38400,
"icon": "Rune plateskirt (t).png",
"name": "Rune plateskirt (t)"
},
{
"examine": "A note that may be exchanged at a bank for a Rune Pouch.",
"id": 24587,
"members": true,
"lowalch": 1,
"limit": 40,
"value": 1,
"highalch": 1,
"icon": "Rune pouch note.png",
"name": "Rune pouch note"
},
{
"examine": "A vicious, curved sword.",
"id": 1333,
"members": false,
"lowalch": 10240,
"limit": 70,
"value": 25600,
"highalch": 15360,
"icon": "Rune scimitar.png",
"name": "Rune scimitar"
},
{
"examine": "Use on a rune scimitar to make it look fancier!",
"id": 23321,
"members": false,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Rune scimitar ornament kit (guthix).png",
"name": "Rune scimitar ornament kit (guthix)"
},
{
"examine": "Use on a rune scimitar to make it look fancier!",
"id": 23324,
"members": false,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Rune scimitar ornament kit (saradomin).png",
"name": "Rune scimitar ornament kit (saradomin)"
},
{
"examine": "Use on a rune scimitar to make it look fancier!",
"id": 23327,
"members": false,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Rune scimitar ornament kit (zamorak).png",
"name": "Rune scimitar ornament kit (zamorak)"
},
{
"examine": "A shield with a heraldic design",
"id": 7336,
"members": false,
"lowalch": 21760,
"limit": 70,
"value": 54400,
"highalch": 32640,
"icon": "Rune shield (h1).png",
"name": "Rune shield (h1)"
},
{
"examine": "A shield with a heraldic design",
"id": 7342,
"members": false,
"lowalch": 21760,
"limit": 70,
"value": 54400,
"highalch": 32640,
"icon": "Rune shield (h2).png",
"name": "Rune shield (h2)"
},
{
"examine": "A shield with a heraldic design",
"id": 7348,
"members": false,
"lowalch": 21760,
"limit": 70,
"value": 54400,
"highalch": 32640,
"icon": "Rune shield (h3).png",
"name": "Rune shield (h3)"
},
{
"examine": "A shield with a heraldic design",
"id": 7354,
"members": false,
"lowalch": 21760,
"limit": 70,
"value": 54400,
"highalch": 32640,
"icon": "Rune shield (h4).png",
"name": "Rune shield (h4)"
},
{
"examine": "A shield with a heraldic design",
"id": 7360,
"members": false,
"lowalch": 21760,
"limit": 70,
"value": 54400,
"highalch": 32640,
"icon": "Rune shield (h5).png",
"name": "Rune shield (h5)"
},
{
"examine": "A rune tipped spear.",
"id": 1247,
"members": true,
"lowalch": 8320,
"limit": 70,
"value": 20800,
"highalch": 12480,
"icon": "Rune spear.png",
"name": "Rune spear"
},
{
"examine": "A poisoned rune tipped spear.",
"id": 1261,
"members": true,
"lowalch": 8320,
"limit": 70,
"value": 20800,
"highalch": 12480,
"icon": "Rune spear(p).png",
"name": "Rune spear(p)"
},
{
"examine": "A poisoned rune tipped spear.",
"id": 5714,
"members": true,
"lowalch": 8320,
"limit": 70,
"value": 20800,
"highalch": 12480,
"icon": "Rune spear(p+).png",
"name": "Rune spear(p+)"
},
{
"examine": "A poisoned rune tipped spear.",
"id": 5728,
"members": true,
"lowalch": 8320,
"limit": 70,
"value": 20800,
"highalch": 12480,
"icon": "Rune spear(p++).png",
"name": "Rune spear(p++)"
},
{
"examine": "A medium square shield.",
"id": 1185,
"members": false,
"lowalch": 15360,
"limit": 70,
"value": 38400,
"highalch": 23040,
"icon": "Rune sq shield.png",
"name": "Rune sq shield"
},
{
"examine": "A razor sharp sword.",
"id": 1289,
"members": false,
"lowalch": 8320,
"limit": 70,
"value": 20800,
"highalch": 12480,
"icon": "Rune sword.png",
"name": "Rune sword"
},
{
"examine": "A finely balanced throwing axe.",
"id": 805,
"members": true,
"lowalch": 176,
"limit": 11000,
"value": 440,
"highalch": 264,
"icon": "Rune thrownaxe.png",
"name": "Rune thrownaxe"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 13028,
"members": false,
"lowalch": 32000,
"limit": 8,
"value": 80000,
"highalch": 48000,
"icon": "Rune trimmed set (lg).png",
"name": "Rune trimmed set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 13030,
"members": false,
"lowalch": 32000,
"limit": 8,
"value": 80000,
"highalch": 48000,
"icon": "Rune trimmed set (sk).png",
"name": "Rune trimmed set (sk)"
},
{
"examine": "I don't think it's intended for joinery.",
"id": 1347,
"members": false,
"lowalch": 16600,
"limit": 70,
"value": 41500,
"highalch": 24900,
"icon": "Rune warhammer.png",
"name": "Rune warhammer"
},
{
"examine": "A rough scroll with faint writing, on a malevolent dark red parchment.",
"id": 25481,
"members": true,
"lowalch": 240,
"limit": 50,
"value": 600,
"highalch": 360,
"icon": "Runescroll of bloodbark.png",
"name": "Runescroll of bloodbark"
},
{
"examine": "A rough scroll with faint writing, on a deep green parchment.",
"id": 25478,
"members": true,
"lowalch": 160,
"limit": 50,
"value": 400,
"highalch": 240,
"icon": "Runescroll of swampbark.png",
"name": "Runescroll of swampbark"
},
{
"examine": "It's a bar of runite.",
"id": 2363,
"members": false,
"lowalch": 2000,
"limit": 10000,
"value": 5000,
"highalch": 3000,
"icon": "Runite bar.png",
"name": "Runite bar"
},
{
"examine": "Runite crossbow bolts.",
"id": 9144,
"members": true,
"lowalch": 120,
"limit": 11000,
"value": 300,
"highalch": 180,
"icon": "Runite bolts 5.png",
"name": "Runite bolts"
},
{
"examine": "Some poisoned runite bolts.",
"id": 9291,
"members": true,
"lowalch": 120,
"limit": 11000,
"value": 300,
"highalch": 180,
"icon": "Runite bolts (p) 5.png",
"name": "Runite bolts (p)"
},
{
"examine": "Some poisoned runite bolts.",
"id": 9298,
"members": true,
"lowalch": 120,
"limit": 11000,
"value": 300,
"highalch": 180,
"icon": "Runite bolts (p+) 5.png",
"name": "Runite bolts (p+)"
},
{
"examine": "Super poisoned runite bolts.",
"id": 9305,
"members": true,
"lowalch": 120,
"limit": 11000,
"value": 300,
"highalch": 180,
"icon": "Runite bolts (p++) 5.png",
"name": "Runite bolts (p++)"
},
{
"examine": "Unfeathered runite crossbow bolts.",
"id": 9381,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Runite bolts (unf) 5.png",
"name": "Runite bolts (unf)"
},
{
"examine": "An unstrung runite crossbow.",
"id": 9465,
"members": true,
"lowalch": 6466,
"limit": 10000,
"value": 16167,
"highalch": 9700,
"icon": "Runite crossbow (u).png",
"name": "Runite crossbow (u)"
},
{
"examine": "A pair of runite crossbow limbs.",
"id": 9431,
"members": true,
"lowalch": 6400,
"limit": 10000,
"value": 16000,
"highalch": 9600,
"icon": "Runite limbs.png",
"name": "Runite limbs"
},
{
"examine": "This needs refining.",
"id": 451,
"members": false,
"lowalch": 1280,
"limit": 4500,
"value": 3200,
"highalch": 1920,
"icon": "Runite ore.png",
"name": "Runite ore"
},
{
"examine": "1 dose of sacred Oil.",
"id": 3436,
"members": true,
"lowalch": 24,
"limit": 2000,
"value": 60,
"highalch": 36,
"icon": "Sacred oil(1).png",
"name": "Sacred oil(1)"
},
{
"examine": "2 doses of sacred Oil.",
"id": 3434,
"members": true,
"lowalch": 30,
"limit": 2000,
"value": 75,
"highalch": 45,
"icon": "Sacred oil(2).png",
"name": "Sacred oil(2)"
},
{
"examine": "3 doses of sacred Oil.",
"id": 3432,
"members": true,
"lowalch": 36,
"limit": 2000,
"value": 90,
"highalch": 54,
"icon": "Sacred oil(3).png",
"name": "Sacred oil(3)"
},
{
"examine": "4 doses of sacred Oil.",
"id": 3430,
"members": true,
"lowalch": 40,
"limit": 2000,
"value": 100,
"highalch": 60,
"icon": "Sacred oil(4).png",
"name": "Sacred oil(4)"
},
{
"examine": "Do they make me look... old?",
"id": 12337,
"members": true,
"lowalch": 560,
"limit": 4,
"value": 1400,
"highalch": 840,
"icon": "Sagacious spectacles.png",
"name": "Sagacious spectacles"
},
{
"examine": "Some nicely cooked salmon.",
"id": 329,
"members": false,
"lowalch": 12,
"limit": 6000,
"value": 30,
"highalch": 18,
"icon": "Salmon.png",
"name": "Salmon"
},
{
"examine": "A power that can be added to compost to make fertiliser.",
"id": 13421,
"members": true,
"lowalch": 2,
"limit": 13000,
"value": 5,
"highalch": 3,
"icon": "Saltpetre.png",
"name": "Saltpetre"
},
{
"examine": "A teleport to the Salve graveyard.",
"id": 19619,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Salve graveyard teleport (tablet).png",
"name": "Salve graveyard teleport (tablet)"
},
{
"examine": "Armoured boots of the Samurai.",
"id": 20047,
"members": true,
"lowalch": 1800,
"limit": 70,
"value": 4500,
"highalch": 2700,
"icon": "Samurai boots.png",
"name": "Samurai boots"
},
{
"examine": "Armoured gloves of the Samurai.",
"id": 20041,
"members": true,
"lowalch": 1280,
"limit": 70,
"value": 3200,
"highalch": 1920,
"icon": "Samurai gloves.png",
"name": "Samurai gloves"
},
{
"examine": "Armoured greaves of the Samurai.",
"id": 20044,
"members": true,
"lowalch": 1280,
"limit": 70,
"value": 3200,
"highalch": 1920,
"icon": "Samurai greaves.png",
"name": "Samurai greaves"
},
{
"examine": "A conical hat.",
"id": 20035,
"members": true,
"lowalch": 1600,
"limit": 70,
"value": 4000,
"highalch": 2400,
"icon": "Samurai kasa.png",
"name": "Samurai kasa"
},
{
"examine": "A type of shirt worn by the Samurai.",
"id": 20038,
"members": true,
"lowalch": 2800,
"limit": 70,
"value": 7000,
"highalch": 4200,
"icon": "Samurai shirt.png",
"name": "Samurai shirt"
},
{
"examine": "A large chunk of sandstone.",
"id": 6977,
"members": true,
"lowalch": 1,
"limit": 100,
"value": 1,
"highalch": 1,
"icon": "Sandstone (10kg).png",
"name": "Sandstone (10kg)"
},
{
"examine": "A tiny chunk of sandstone.",
"id": 6971,
"members": true,
"lowalch": 1,
"limit": 100,
"value": 1,
"highalch": 1,
"icon": "Sandstone (1kg).png",
"name": "Sandstone (1kg)"
},
{
"examine": "A small chunk of sandstone.",
"id": 6973,
"members": true,
"lowalch": 1,
"limit": 100,
"value": 1,
"highalch": 1,
"icon": "Sandstone (2kg).png",
"name": "Sandstone (2kg)"
},
{
"examine": "A medium-sized chunk of sandstone.",
"id": 6975,
"members": true,
"lowalch": 1,
"limit": 100,
"value": 1,
"highalch": 1,
"icon": "Sandstone (5kg).png",
"name": "Sandstone (5kg)"
},
{
"examine": "Bottoms worn by a sandwich lady.",
"id": 23318,
"members": false,
"lowalch": 120,
"limit": 4,
"value": 300,
"highalch": 180,
"icon": "Sandwich lady bottom.png",
"name": "Sandwich lady bottom"
},
{
"examine": "A hat worn by a sandwich lady.",
"id": 23312,
"members": false,
"lowalch": 80,
"limit": 4,
"value": 200,
"highalch": 120,
"icon": "Sandwich lady hat.png",
"name": "Sandwich lady hat"
},
{
"examine": "A top worn by a sandwich lady.",
"id": 23315,
"members": false,
"lowalch": 200,
"limit": 4,
"value": 500,
"highalch": 300,
"icon": "Sandwich lady top.png",
"name": "Sandwich lady top"
},
{
"examine": "Can be used as bait for catching anglerfish.",
"id": 13431,
"members": true,
"lowalch": 36,
"limit": 8000,
"value": 90,
"highalch": 54,
"icon": "Sandworms.png",
"name": "Sandworms"
},
{
"examine": "A 1 dose Sanfew Serum.",
"id": 10931,
"members": true,
"lowalch": 48,
"limit": 2000,
"value": 120,
"highalch": 72,
"icon": "Sanfew serum(1).png",
"name": "Sanfew serum(1)"
},
{
"examine": "A 2 dose Sanfew Serum.",
"id": 10929,
"members": true,
"lowalch": 72,
"limit": 2000,
"value": 180,
"highalch": 108,
"icon": "Sanfew serum(2).png",
"name": "Sanfew serum(2)"
},
{
"examine": "A 3 dose Sanfew Serum.",
"id": 10927,
"members": true,
"lowalch": 96,
"limit": 2000,
"value": 240,
"highalch": 144,
"icon": "Sanfew serum(3).png",
"name": "Sanfew serum(3)"
},
{
"examine": "A 4 dose Sanfew Serum.",
"id": 10925,
"members": true,
"lowalch": 120,
"limit": 2000,
"value": 300,
"highalch": 180,
"icon": "Sanfew serum(4).png",
"name": "Sanfew serum(4)"
},
{
"examine": "A sacred staff imbued with the power of blood. It is currently uncharged.",
"id": 22481,
"members": true,
"lowalch": 2000000,
"limit": 8,
"value": 5000000,
"highalch": 3000000,
"icon": "Sanguinesti staff (uncharged).png",
"name": "Sanguinesti staff (uncharged)"
},
{
"examine": "It's a Santa hat.",
"id": 1050,
"members": false,
"lowalch": 64,
"limit": 5,
"value": 160,
"highalch": 96,
"icon": "Santa hat.png",
"name": "Santa hat"
},
{
"examine": "This looks valuable.",
"id": 1607,
"members": false,
"lowalch": 100,
"limit": 13000,
"value": 250,
"highalch": 150,
"icon": "Sapphire.png",
"name": "Sapphire"
},
{
"examine": "I wonder if I can get this enchanted.",
"id": 1694,
"members": false,
"lowalch": 360,
"limit": 10000,
"value": 900,
"highalch": 540,
"icon": "Sapphire amulet.png",
"name": "Sapphire amulet"
},
{
"examine": "It needs a string so I can wear it.",
"id": 1675,
"members": false,
"lowalch": 360,
"limit": 10000,
"value": 900,
"highalch": 540,
"icon": "Sapphire amulet (u).png",
"name": "Sapphire amulet (u)"
},
{
"examine": "Sapphire bolt tips.",
"id": 9189,
"members": true,
"lowalch": 6,
"limit": 11000,
"value": 17,
"highalch": 10,
"icon": "Sapphire bolt tips 5.png",
"name": "Sapphire bolt tips"
},
{
"examine": "Sapphire tipped Mithril crossbow bolts.",
"id": 9337,
"members": true,
"lowalch": 14,
"limit": 11000,
"value": 37,
"highalch": 22,
"icon": "Sapphire bolts 5.png",
"name": "Sapphire bolts"
},
{
"examine": "Enchanted Sapphire tipped Mithril Crossbow Bolts.",
"id": 9240,
"members": true,
"lowalch": 16,
"limit": 11000,
"value": 40,
"highalch": 24,
"icon": "Sapphire bolts (e) 5.png",
"name": "Sapphire bolts (e)"
},
{
"examine": "I wonder if this is valuable.",
"id": 11072,
"members": true,
"lowalch": 460,
"limit": 10000,
"value": 1150,
"highalch": 690,
"icon": "Sapphire bracelet.png",
"name": "Sapphire bracelet"
},
{
"examine": "Dragon crossbow bolts, tipped with sapphire.",
"id": 21963,
"members": true,
"lowalch": 300,
"limit": 11000,
"value": 750,
"highalch": 450,
"icon": "Sapphire dragon bolts 5.png",
"name": "Sapphire dragon bolts"
},
{
"examine": "Enchanted Dragon crossbow bolts, tipped with sapphire.",
"id": 21940,
"members": true,
"lowalch": 312,
"limit": 11000,
"value": 780,
"highalch": 468,
"icon": "Sapphire dragon bolts (e) 5.png",
"name": "Sapphire dragon bolts (e)"
},
{
"examine": "There's a sapphire glacialis butterfly in here.",
"id": 10018,
"members": true,
"lowalch": 12,
"limit": 125,
"value": 30,
"highalch": 18,
"icon": "Sapphire glacialis (item).png",
"name": "Sapphire glacialis (item)"
},
{
"highalch": 18,
"members": true,
"name": "Sapphire glacialis mix (1)",
"examine": "There's a sapphire glacialis butterfly in here.",
"id": 29198,
"value": 30,
"icon": "Sapphire glacialis mix (1).png",
"lowalch": 12
},
{
"highalch": 18,
"members": true,
"name": "Sapphire glacialis mix (2)",
"examine": "There's a sapphire glacialis butterfly in here.",
"id": 29180,
"value": 30,
"icon": "Sapphire glacialis mix (2).png",
"lowalch": 12
},
{
"examine": "I wonder if this is valuable.",
"id": 1656,
"members": false,
"lowalch": 420,
"limit": 18000,
"value": 1050,
"highalch": 630,
"icon": "Sapphire necklace.png",
"name": "Sapphire necklace"
},
{
"examine": "A valuable ring.",
"id": 1637,
"members": false,
"lowalch": 360,
"limit": 10000,
"value": 900,
"highalch": 540,
"icon": "Sapphire ring.png",
"name": "Sapphire ring"
},
{
"examine": "Not to be confused with a Sarachnis cuddle.",
"id": 23528,
"members": true,
"lowalch": 48000,
"limit": 70,
"value": 120001,
"highalch": 72000,
"icon": "Sarachnis cudgel.png",
"name": "Sarachnis cudgel"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 13040,
"members": false,
"lowalch": 32000,
"limit": 8,
"value": 80000,
"highalch": 48000,
"icon": "Saradomin armour set (lg).png",
"name": "Saradomin armour set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 13042,
"members": false,
"lowalch": 32000,
"limit": 8,
"value": 80000,
"highalch": 48000,
"icon": "Saradomin armour set (sk).png",
"name": "Saradomin armour set (sk)"
},
{
"examine": "Saradomin blessed dragonhide vambraces.",
"id": 10384,
"members": true,
"lowalch": 1600,
"limit": 8,
"value": 4000,
"highalch": 2400,
"icon": "Saradomin bracers.png",
"name": "Saradomin bracers"
},
{
"examine": "1 dose of Saradomin brew.",
"id": 6691,
"members": true,
"lowalch": 50,
"limit": 2000,
"value": 125,
"highalch": 75,
"icon": "Saradomin brew(1).png",
"name": "Saradomin brew(1)"
},
{
"examine": "2 doses of Saradomin brew.",
"id": 6689,
"members": true,
"lowalch": 60,
"limit": 2000,
"value": 150,
"highalch": 90,
"icon": "Saradomin brew(2).png",
"name": "Saradomin brew(2)"
},
{
"examine": "3 doses of Saradomin brew.",
"id": 6687,
"members": true,
"lowalch": 70,
"limit": 2000,
"value": 175,
"highalch": 105,
"icon": "Saradomin brew(3).png",
"name": "Saradomin brew(3)"
},
{
"examine": "4 doses of Saradomin brew.",
"id": 6685,
"members": true,
"lowalch": 80,
"limit": 2000,
"value": 200,
"highalch": 120,
"icon": "Saradomin brew(4).png",
"name": "Saradomin brew(4)"
},
{
"examine": "Saradomin blessed dragonhide chaps.",
"id": 10388,
"members": true,
"lowalch": 2400,
"limit": 8,
"value": 6000,
"highalch": 3600,
"icon": "Saradomin chaps.png",
"name": "Saradomin chaps"
},
{
"examine": "A Saradomin cloak.",
"id": 10446,
"members": true,
"lowalch": 800,
"limit": 8,
"value": 2000,
"highalch": 1200,
"icon": "Saradomin cloak.png",
"name": "Saradomin cloak"
},
{
"examine": "Saradomin blessed dragonhide coif.",
"id": 10390,
"members": true,
"lowalch": 800,
"limit": 8,
"value": 2000,
"highalch": 1200,
"icon": "Saradomin coif.png",
"name": "Saradomin coif"
},
{
"examine": "A Saradomin crozier.",
"id": 10440,
"members": true,
"lowalch": 2000,
"limit": 8,
"value": 5000,
"highalch": 3000,
"icon": "Saradomin crozier.png",
"name": "Saradomin crozier"
},
{
"examine": "Saradomin blessed dragonhide body armour.",
"id": 10386,
"members": true,
"lowalch": 5200,
"limit": 8,
"value": 13000,
"highalch": 7800,
"icon": "Saradomin d'hide body.png",
"name": "Saradomin d'hide body"
},
{
"examine": "Saradomin blessed dragonhide boots.",
"id": 19933,
"members": true,
"lowalch": 3720,
"limit": 8,
"value": 9300,
"highalch": 5580,
"icon": "Saradomin d'hide boots.png",
"name": "Saradomin d'hide boots"
},
{
"examine": "Saradomin blessed wooden shield covered in dragon leather.",
"id": 23191,
"members": true,
"lowalch": 11333,
"limit": 8,
"value": 28334,
"highalch": 17000,
"icon": "Saradomin d'hide shield.png",
"name": "Saradomin d'hide shield"
},
{
"examine": "A set containing a Saradomin dragonhide coif, body, chaps and vambraces.",
"id": 13163,
"members": true,
"lowalch": 3600,
"limit": 8,
"value": 9000,
"highalch": 5400,
"icon": "Saradomin dragonhide set.png",
"name": "Saradomin dragonhide set"
},
{
"examine": "Rune full helmet in the colours of Saradomin.",
"id": 2665,
"members": false,
"lowalch": 14080,
"limit": 8,
"value": 35200,
"highalch": 21120,
"icon": "Saradomin full helm.png",
"name": "Saradomin full helm"
},
{
"examine": "A gracious, heavy sword.",
"id": 11806,
"members": true,
"lowalch": 500000,
"limit": 8,
"value": 1250000,
"highalch": 750000,
"icon": "Saradomin godsword.png",
"name": "Saradomin godsword"
},
{
"examine": "Use on a Saradomin godsword to make it look fancier!",
"id": 20074,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Saradomin godsword ornament kit.png",
"name": "Saradomin godsword ornament kit"
},
{
"examine": "Brimming with potential.",
"id": 11814,
"members": true,
"lowalch": 200000,
"limit": 10000,
"value": 500000,
"highalch": 300000,
"icon": "Saradomin hilt.png",
"name": "Saradomin hilt"
},
{
"examine": "Rune kiteshield in the colours of Saradomin.",
"id": 2667,
"members": false,
"lowalch": 21760,
"limit": 8,
"value": 54400,
"highalch": 32640,
"icon": "Saradomin kiteshield.png",
"name": "Saradomin kiteshield"
},
{
"examine": "A Saradomin mitre.",
"id": 10452,
"members": true,
"lowalch": 2000,
"limit": 8,
"value": 5000,
"highalch": 3000,
"icon": "Saradomin mitre.png",
"name": "Saradomin mitre"
},
{
"examine": "A Saradomin Mjolnir.",
"id": 6762,
"members": true,
"lowalch": 250,
"limit": 8,
"value": 625,
"highalch": 375,
"icon": "Saradomin mjolnir.png",
"name": "Saradomin mjolnir"
},
{
"examine": "This seems to have been torn from a book...",
"id": 3827,
"members": true,
"lowalch": 80,
"limit": 5,
"value": 200,
"highalch": 120,
"icon": "Saradomin page 1.png",
"name": "Saradomin page 1"
},
{
"examine": "This seems to have been torn from a book...",
"id": 3828,
"members": true,
"lowalch": 80,
"limit": 5,
"value": 200,
"highalch": 120,
"icon": "Saradomin page 2.png",
"name": "Saradomin page 2"
},
{
"examine": "This seems to have been torn from a book...",
"id": 3829,
"members": true,
"lowalch": 80,
"limit": 5,
"value": 200,
"highalch": 120,
"icon": "Saradomin page 3.png",
"name": "Saradomin page 3"
},
{
"examine": "This seems to have been torn from a book...",
"id": 3830,
"members": true,
"lowalch": 80,
"limit": 5,
"value": 200,
"highalch": 120,
"icon": "Saradomin page 4.png",
"name": "Saradomin page 4"
},
{
"examine": "Rune platebody in the colours of Saradomin.",
"id": 2661,
"members": false,
"lowalch": 26000,
"limit": 8,
"value": 65000,
"highalch": 39000,
"icon": "Saradomin platebody.png",
"name": "Saradomin platebody"
},
{
"examine": "Rune platelegs in the colours of Saradomin.",
"id": 2663,
"members": false,
"lowalch": 25600,
"limit": 8,
"value": 64000,
"highalch": 38400,
"icon": "Saradomin platelegs.png",
"name": "Saradomin platelegs"
},
{
"examine": "Rune plateskirt in the colours of Saradomin.",
"id": 3479,
"members": false,
"lowalch": 25600,
"limit": 8,
"value": 64000,
"highalch": 38400,
"icon": "Saradomin plateskirt.png",
"name": "Saradomin plateskirt"
},
{
"examine": "Leggings from the Saradomin Vestments.",
"id": 10464,
"members": true,
"lowalch": 2800,
"limit": 8,
"value": 7000,
"highalch": 4200,
"icon": "Saradomin robe legs.png",
"name": "Saradomin robe legs"
},
{
"examine": "Saradomin Vestments.",
"id": 10458,
"members": true,
"lowalch": 2800,
"limit": 8,
"value": 7000,
"highalch": 4200,
"icon": "Saradomin robe top.png",
"name": "Saradomin robe top"
},
{
"examine": "A Saradomin stole.",
"id": 10470,
"members": true,
"lowalch": 1000,
"limit": 8,
"value": 2500,
"highalch": 1500,
"icon": "Saradomin stole.png",
"name": "Saradomin stole"
},
{
"examine": "The incredible blade of an Icyene.",
"id": 11838,
"members": true,
"lowalch": 52000,
"limit": 8,
"value": 130000,
"highalch": 78000,
"icon": "Saradomin sword.png",
"name": "Saradomin sword"
},
{
"examine": "The starlight enlightens your mind to dispel the darkness of Zamorakian evil.",
"id": 13256,
"members": true,
"lowalch": 13840,
"limit": 5,
"value": 34600,
"highalch": 20760,
"icon": "Saradomin's light.png",
"name": "Saradomin's light"
},
{
"examine": "After a terrible battle, Saradomin wept over the wounds of his faithful Zilyana.",
"id": 12804,
"members": true,
"lowalch": 16000,
"limit": 50,
"value": 40000,
"highalch": 24000,
"icon": "Saradomin's tear.png",
"name": "Saradomin's tear"
},
{
"examine": "Some nicely cooked sardines.",
"id": 325,
"members": false,
"lowalch": 4,
"limit": 6000,
"value": 10,
"highalch": 6,
"icon": "Sardine.png",
"name": "Sardine"
},
{
"examine": "This heart holds an ancient magical power within.",
"id": 27641,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 1,
"highalch": 1,
"icon": "Saturated heart.png",
"name": "Saturated heart"
},
{
"examine": "Good for cutting wood.",
"id": 8794,
"members": true,
"lowalch": 4,
"limit": 40,
"value": 10,
"highalch": 6,
"icon": "Saw.png",
"name": "Saw"
},
{
"highalch": 1,
"members": true,
"name": "Sawmill voucher",
"examine": "Used to provide additional planks at the sawmill.",
"id": 28628,
"value": 1,
"icon": "Sawmill voucher.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Scaly blue dragonhide",
"examine": "A scaled blue dragonhide.",
"id": 27897,
"value": 1,
"icon": "Scaly blue dragonhide.png",
"lowalch": 1
},
{
"examine": "A bowl of scrambled egg.",
"id": 7078,
"members": true,
"lowalch": 3,
"limit": 13000,
"value": 8,
"highalch": 4,
"icon": "Scrambled egg.png",
"name": "Scrambled egg"
},
{
"examine": "A powerful scythe. It is currently uncharged.",
"id": 22486,
"members": true,
"lowalch": 1600000,
"limit": 8,
"value": 4000000,
"highalch": 2400000,
"icon": "Scythe of vitur (uncharged).png",
"name": "Scythe of vitur (uncharged)"
},
{
"examine": "Tasty!",
"id": 397,
"members": true,
"lowalch": 80,
"limit": 10000,
"value": 200,
"highalch": 120,
"icon": "Sea turtle.png",
"name": "Sea turtle"
},
{
"highalch": 120,
"members": true,
"name": "Searing page",
"examine": "It's still warm to the touch.",
"id": 28931,
"value": 200,
"icon": "Searing page.png",
"lowalch": 80
},
{
"examine": "Sardine flavoured with doogle leaves.",
"id": 1552,
"members": true,
"lowalch": 4,
"limit": 15,
"value": 10,
"highalch": 6,
"icon": "Seasoned sardine.png",
"name": "Seasoned sardine"
},
{
"examine": "Slightly damp seaweed.",
"id": 401,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Seaweed.png",
"name": "Seaweed"
},
{
"examine": "A seaweed spore - plant in a seaweed patch.",
"id": 21490,
"members": true,
"lowalch": 8,
"limit": 600,
"value": 20,
"highalch": 12,
"icon": "Seaweed spore 5.png",
"name": "Seaweed spore"
},
{
"examine": "Good for pruning away diseased leaves.",
"id": 5329,
"members": true,
"lowalch": 2,
"limit": 40,
"value": 5,
"highalch": 3,
"icon": "Secateurs.png",
"name": "Secateurs"
},
{
"highalch": 1,
"members": true,
"name": "Secateurs attachment",
"examine": "Collects leaves when chopping trees.",
"id": 28161,
"value": 1,
"icon": "Secateurs attachment.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Secateurs blade",
"examine": "An ingredient for the secateurs attachment.",
"id": 28159,
"value": 1,
"icon": "Secateurs blade.png",
"lowalch": 1
},
{
"examine": "WARNING: Contains information which could make your account secure!",
"id": 9003,
"members": false,
"lowalch": 1,
"limit": 15,
"value": 2,
"highalch": 1,
"icon": "Security book.png",
"name": "Security book"
},
{
"examine": "Use this to plant seeds with.",
"id": 5343,
"members": true,
"lowalch": 2,
"limit": 40,
"value": 6,
"highalch": 3,
"icon": "Seed dibber.png",
"name": "Seed dibber"
},
{
"examine": "An ancient Fremennik bow that was once used to battle the Moon Clan.",
"id": 6724,
"members": true,
"lowalch": 3200,
"limit": 8,
"value": 8000,
"highalch": 4800,
"icon": "Seercull.png",
"name": "Seercull"
},
{
"highalch": 1,
"members": true,
"name": "Seers icon",
"examine": "The icon of an archer.",
"id": 28304,
"value": 1,
"icon": "Seers icon.png",
"lowalch": 1
},
{
"examine": "A mysterious ring that can fill the wearer with magical power...",
"id": 6731,
"members": true,
"lowalch": 4000,
"limit": 8,
"value": 10000,
"highalch": 6000,
"icon": "Seers ring.png",
"name": "Seers ring"
},
{
"examine": "A teleport to Senntisten.",
"id": 12782,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Senntisten teleport (tablet).png",
"name": "Senntisten teleport (tablet)"
},
{
"examine": "Needs to be charged with Zulrah's scales.",
"id": 12929,
"members": true,
"lowalch": 44000,
"limit": 8,
"value": 110000,
"highalch": 66000,
"icon": "Serpentine helm (uncharged).png",
"name": "Serpentine helm (uncharged)"
},
{
"examine": "Maybe you could use a chisel to craft this into a helmet.",
"id": 12927,
"members": true,
"lowalch": 42000,
"limit": 5,
"value": 105000,
"highalch": 63000,
"icon": "Serpentine visage.png",
"name": "Serpentine visage"
},
{
"examine": "1 dose serum 207 as described in Herbi Flax's diary.",
"id": 3414,
"members": true,
"lowalch": 4,
"limit": 2000,
"value": 11,
"highalch": 6,
"icon": "Serum 207 (1).png",
"name": "Serum 207 (1)"
},
{
"examine": "2 doses serum 207 as described in Herbi Flax's diary.",
"id": 3412,
"members": true,
"lowalch": 5,
"limit": 2000,
"value": 13,
"highalch": 7,
"icon": "Serum 207 (2).png",
"name": "Serum 207 (2)"
},
{
"examine": "3 doses serum 207 as described in Herbi Flax's diary.",
"id": 3410,
"members": true,
"lowalch": 5,
"limit": 2000,
"value": 13,
"highalch": 7,
"icon": "Serum 207 (3).png",
"name": "Serum 207 (3)"
},
{
"examine": "4 doses serum 207 as described in Herbi Flax's diary.",
"id": 3408,
"members": true,
"lowalch": 5,
"limit": 2000,
"value": 14,
"highalch": 8,
"icon": "Serum 207 (4).png",
"name": "Serum 207 (4)"
},
{
"examine": "If a shade had knees, this would keep them nice and warm.",
"id": 548,
"members": false,
"lowalch": 12,
"limit": 125,
"value": 30,
"highalch": 18,
"icon": "Shade robe.png",
"name": "Shade robe"
},
{
"examine": "I feel closer to the gods when I am wearing this.",
"id": 546,
"members": false,
"lowalch": 16,
"limit": 125,
"value": 40,
"highalch": 24,
"icon": "Shade robe top.png",
"name": "Shade robe top"
},
{
"examine": "Large glistening bones which glow with a pale yellow aura.",
"id": 3123,
"members": true,
"lowalch": 1,
"limit": 7500,
"value": 1,
"highalch": 1,
"icon": "Shaikahan bones.png",
"name": "Shaikahan bones"
},
{
"examine": "The mask worn by ogre shamans.",
"id": 21838,
"members": false,
"lowalch": 3200,
"limit": 125,
"value": 8000,
"highalch": 4800,
"icon": "Shaman mask.png",
"name": "Shaman mask"
},
{
"examine": "Allows you to pass through the Shantay pass into the Kharid Desert.",
"id": 1854,
"members": true,
"lowalch": 2,
"limit": 18000,
"value": 5,
"highalch": 3,
"icon": "Shantay pass (item).png",
"name": "Shantay pass (item)"
},
{
"examine": "I'd better be careful eating this.",
"id": 385,
"members": true,
"lowalch": 68,
"limit": 10000,
"value": 170,
"highalch": 102,
"icon": "Shark.png",
"name": "Shark"
},
{
"highalch": 1,
"members": true,
"name": "Shattered banner",
"examine": "A banner showcasing the colours of Leagues III - Shattered Relics.",
"id": 26424,
"value": 1,
"icon": "Shattered banner.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Shattered boots (t1)",
"examine": "The boots of a Shattered Relic Hunter.",
"id": 26436,
"value": 1,
"icon": "Shattered boots (t1).png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Shattered boots (t2)",
"examine": "The boots of a Shattered Relic Hunter.",
"id": 26448,
"value": 1,
"icon": "Shattered boots (t2).png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Shattered boots (t3)",
"examine": "The boots of a Shattered Relic Hunter.",
"id": 26460,
"value": 1,
"icon": "Shattered boots (t3).png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Shattered cane",
"examine": "The cane of a Shattered relic hunter.",
"id": 26517,
"value": 1,
"icon": "Shattered cane.png",
"lowalch": 1
},
{
"examine": "An ornamental kit for the Dwarf multicannon in the style of Leagues III - Shattered Relics.",
"id": 26528,
"members": true,
"lowalch": 1,
"limit": 5,
"value": 1,
"highalch": 1,
"icon": "Shattered cannon ornament kit.png",
"name": "Shattered cannon ornament kit"
},
{
"highalch": 1,
"members": true,
"name": "Shattered hood (t1)",
"examine": "The headgear of a Shattered Relic Hunter.",
"id": 26427,
"value": 1,
"icon": "Shattered hood (t1).png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Shattered hood (t2)",
"examine": "The headgear of a Shattered Relic Hunter.",
"id": 26439,
"value": 1,
"icon": "Shattered hood (t2).png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Shattered hood (t3)",
"examine": "The headgear of a Shattered Relic Hunter.",
"id": 26451,
"value": 1,
"icon": "Shattered hood (t3).png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Shattered relic hunter (t1) armour set",
"examine": "A set containing Shattered boots (t1), Shattered trousers (t1), Shattered top (t1) and Shattered hood (t1).",
"id": 26554,
"value": 1,
"icon": "Shattered relic hunter (t1) armour set.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Shattered relic hunter (t2) armour set",
"examine": "A set containing Shattered boots (t2), Shattered trousers (t2), Shattered top (t2) and Shattered hood (t2).",
"id": 26557,
"value": 1,
"icon": "Shattered relic hunter (t2) armour set.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Shattered relic hunter (t3) armour set",
"examine": "A set containing Shattered boots (t3), Shattered trousers (t3), Shattered top (t3) and Shattered hood (t3).",
"id": 26560,
"value": 1,
"icon": "Shattered relic hunter (t3) armour set.png",
"lowalch": 1
},
{
"examine": "A kit that can be used to apply a Leagues III - Shattered Relics theme to a blue Mystic robe piece.",
"id": 26541,
"members": true,
"lowalch": 1,
"limit": 5,
"value": 1,
"highalch": 1,
"icon": "Shattered relics mystic ornament kit.png",
"name": "Shattered relics mystic ornament kit"
},
{
"examine": "A kit that can be used to apply a Leagues III - Shattered Relics theme to an Abyssal whip or Tentacle, Rune crossbow or God book piece.",
"id": 26421,
"members": true,
"lowalch": 1,
"limit": 5,
"value": 1,
"highalch": 1,
"icon": "Shattered relics variety ornament kit.png",
"name": "Shattered relics variety ornament kit"
},
{
"examine": "A kit that can be used on void armour to theme it to Leagues III - Shattered Relics.",
"id": 26479,
"members": true,
"lowalch": 1,
"limit": 5,
"value": 1,
"highalch": 1,
"icon": "Shattered relics void ornament kit.png",
"name": "Shattered relics void ornament kit"
},
{
"highalch": 1,
"members": true,
"name": "Shattered teleport scroll",
"examine": "A scroll which unlocks the Shattered Relics home teleport animation.",
"id": 26500,
"value": 1,
"icon": "Shattered teleport scroll.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Shattered top (t1)",
"examine": "The shirt of a Shattered Relic Hunter.",
"id": 26430,
"value": 1,
"icon": "Shattered top (t1).png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Shattered top (t2)",
"examine": "The shirt of a Shattered Relic Hunter.",
"id": 26442,
"value": 1,
"icon": "Shattered top (t2).png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Shattered top (t3)",
"examine": "The shirt of a Shattered Relic Hunter.",
"id": 26454,
"value": 1,
"icon": "Shattered top (t3).png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Shattered trousers (t1)",
"examine": "The trousers of a Shattered Relic Hunter.",
"id": 26433,
"value": 1,
"icon": "Shattered trousers (t1).png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Shattered trousers (t2)",
"examine": "The trousers of a Shattered Relic Hunter.",
"id": 26445,
"value": 1,
"icon": "Shattered trousers (t2).png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Shattered trousers (t3)",
"examine": "The trousers of a Shattered Relic Hunter.",
"id": 26457,
"value": 1,
"icon": "Shattered trousers (t3).png",
"lowalch": 1
},
{
"examine": "How does it all fit in there?",
"id": 8596,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Shaving stand (flatpack).png",
"name": "Shaving stand (flatpack)"
},
{
"examine": "A war torn banner baring the sigil of house Shayzien.",
"id": 20263,
"members": true,
"lowalch": 28,
"limit": 4,
"value": 70,
"highalch": 42,
"icon": "Shayzien banner.png",
"name": "Shayzien banner"
},
{
"examine": "A rare hood from Shayzien.",
"id": 20125,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Shayzien hood.png",
"name": "Shayzien hood"
},
{
"examine": "A red scarf adorned with a skull.",
"id": 19955,
"members": true,
"lowalch": 4000,
"limit": 4,
"value": 10000,
"highalch": 6000,
"icon": "Shayzien scarf.png",
"name": "Shayzien scarf"
},
{
"examine": "For shearing sheep.",
"id": 1735,
"members": false,
"lowalch": 1,
"limit": 40,
"value": 1,
"highalch": 1,
"icon": "Shears.png",
"name": "Shears"
},
{
"examine": "The left half of a dragon square shield.",
"id": 2366,
"members": true,
"lowalch": 44000,
"limit": 50,
"value": 110000,
"highalch": 66000,
"icon": "Shield left half.png",
"name": "Shield left half"
},
{
"examine": "The right half of a dragon square shield.",
"id": 2368,
"members": true,
"lowalch": 200000,
"limit": 50,
"value": 500000,
"highalch": 300000,
"icon": "Shield right half.png",
"name": "Shield right half"
},
{
"examine": "Allows you passage on the 'Lady of the waves' ship.",
"id": 621,
"members": true,
"lowalch": 2,
"limit": 18000,
"value": 5,
"highalch": 3,
"icon": "Ship ticket.png",
"name": "Ship ticket"
},
{
"examine": "Tiny!",
"id": 5030,
"members": true,
"lowalch": 180,
"limit": 150,
"value": 450,
"highalch": 270,
"icon": "Shirt (brown).png",
"name": "Shirt (brown)"
},
{
"examine": "Tiny!",
"id": 5034,
"members": true,
"lowalch": 250,
"limit": 150,
"value": 625,
"highalch": 375,
"icon": "Shirt (lilac).png",
"name": "Shirt (lilac)"
},
{
"examine": "Tiny!",
"id": 5032,
"members": true,
"lowalch": 240,
"limit": 150,
"value": 600,
"highalch": 360,
"icon": "Shirt (yellow).png",
"name": "Shirt (yellow)"
},
{
"examine": "How does it all fit in there?",
"id": 8610,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Shoe box (flatpack).png",
"name": "Shoe box (flatpack)"
},
{
"examine": "A Short Green Guy... looks good.",
"id": 2080,
"members": true,
"lowalch": 12,
"limit": 2000,
"value": 30,
"highalch": 18,
"icon": "Short green guy.png",
"name": "Short green guy"
},
{
"examine": "Short but effective.",
"id": 841,
"members": false,
"lowalch": 20,
"limit": 18000,
"value": 50,
"highalch": 30,
"icon": "Shortbow.png",
"name": "Shortbow"
},
{
"examine": "I need to find a string for this.",
"id": 50,
"members": true,
"lowalch": 9,
"limit": 10000,
"value": 23,
"highalch": 13,
"icon": "Shortbow (u).png",
"name": "Shortbow (u)"
},
{
"examine": "Blue shorts, these would look great on dwarves!",
"id": 5046,
"members": true,
"lowalch": 156,
"limit": 150,
"value": 390,
"highalch": 234,
"icon": "Shorts (blue).png",
"name": "Shorts (blue)"
},
{
"examine": "These look great, on dwarves!",
"id": 5042,
"members": true,
"lowalch": 112,
"limit": 150,
"value": 280,
"highalch": 168,
"icon": "Shorts (brown).png",
"name": "Shorts (brown)"
},
{
"examine": "Yellow shorts. Far too small for you.",
"id": 5044,
"members": true,
"lowalch": 144,
"limit": 150,
"value": 360,
"highalch": 216,
"icon": "Shorts (yellow).png",
"name": "Shorts (yellow)"
},
{
"examine": "Polly want a cracker?",
"id": 23300,
"members": false,
"lowalch": 400,
"limit": 4,
"value": 1000,
"highalch": 600,
"icon": "Shoulder parrot.png",
"name": "Shoulder parrot"
},
{
"examine": "Some nicely cooked shrimp.",
"id": 315,
"members": false,
"lowalch": 2,
"limit": 6000,
"value": 5,
"highalch": 3,
"icon": "Shrimps.png",
"name": "Shrimps"
},
{
"examine": "A shrunk ogleroot! How odd...",
"id": 11205,
"members": true,
"lowalch": 1,
"limit": 15,
"value": 2,
"highalch": 1,
"icon": "Shrunk ogleroot 5.png",
"name": "Shrunk ogleroot"
},
{
"examine": "Used to make sickles.",
"id": 2976,
"members": true,
"lowalch": 4,
"limit": 40,
"value": 10,
"highalch": 6,
"icon": "Sickle mould.png",
"name": "Sickle mould"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26132,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 0,
"highalch": 1,
"icon": "Sigil of aggression.png",
"name": "Sigil of aggression"
},
{
"members": true,
"name": "Sigil of agile fortune",
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 28493,
"value": 10000,
"icon": "Sigil of agile fortune.png"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26009,
"members": true,
"lowalch": 1,
"limit": 10,
"value": 0,
"highalch": 1,
"icon": "Sigil of barrows.png",
"name": "Sigil of barrows"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26066,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 0,
"highalch": 1,
"icon": "Sigil of binding.png",
"name": "Sigil of binding"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 25994,
"members": true,
"lowalch": 1,
"limit": 10,
"value": 0,
"highalch": 1,
"icon": "Sigil of consistency.png",
"name": "Sigil of consistency"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26012,
"members": true,
"lowalch": 1,
"limit": 10,
"value": 0,
"highalch": 1,
"icon": "Sigil of deft strikes.png",
"name": "Sigil of deft strikes"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26099,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 0,
"highalch": 1,
"icon": "Sigil of devotion.png",
"name": "Sigil of devotion"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26018,
"members": true,
"lowalch": 1,
"limit": 10,
"value": 0,
"highalch": 1,
"icon": "Sigil of enhanced harvest.png",
"name": "Sigil of enhanced harvest"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26069,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 0,
"highalch": 1,
"icon": "Sigil of escaping.png",
"name": "Sigil of escaping"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26057,
"members": true,
"lowalch": 1,
"limit": 10,
"value": 0,
"highalch": 1,
"icon": "Sigil of exaggeration.png",
"name": "Sigil of exaggeration"
},
{
"members": true,
"name": "Sigil of faith",
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 28520,
"value": 1,
"icon": "Sigil of faith.png"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26126,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 0,
"highalch": 1,
"icon": "Sigil of finality.png",
"name": "Sigil of finality"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26006,
"members": true,
"lowalch": 1,
"limit": 10,
"value": 0,
"highalch": 1,
"icon": "Sigil of fortification.png",
"name": "Sigil of fortification"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26015,
"members": true,
"lowalch": 1,
"limit": 10,
"value": 0,
"highalch": 1,
"icon": "Sigil of freedom.png",
"name": "Sigil of freedom"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26105,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 0,
"highalch": 1,
"icon": "Sigil of garments.png",
"name": "Sigil of garments"
},
{
"members": true,
"name": "Sigil of hoarding",
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 28481,
"value": 10000,
"icon": "Sigil of hoarding.png"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26144,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 0,
"highalch": 1,
"icon": "Sigil of last recall.png",
"name": "Sigil of last recall"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26054,
"members": true,
"lowalch": 1,
"limit": 10,
"value": 0,
"highalch": 1,
"icon": "Sigil of mobility.png",
"name": "Sigil of mobility"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26096,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 0,
"highalch": 1,
"icon": "Sigil of nature.png",
"name": "Sigil of nature"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26129,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 0,
"highalch": 1,
"icon": "Sigil of pious protection.png",
"name": "Sigil of pious protection"
},
{
"members": true,
"name": "Sigil of precision",
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 28514,
"value": 10000,
"icon": "Sigil of precision.png"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26123,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 0,
"highalch": 1,
"icon": "Sigil of preservation.png",
"name": "Sigil of preservation"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26081,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 0,
"highalch": 1,
"icon": "Sigil of prosperity.png",
"name": "Sigil of prosperity"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26135,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 0,
"highalch": 1,
"icon": "Sigil of rampage.png",
"name": "Sigil of rampage"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26141,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 0,
"highalch": 1,
"icon": "Sigil of remote storage.png",
"name": "Sigil of remote storage"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 25991,
"members": true,
"lowalch": 1,
"limit": 10,
"value": 0,
"highalch": 1,
"icon": "Sigil of resilience.png",
"name": "Sigil of resilience"
},
{
"members": true,
"name": "Sigil of resistance",
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 28490,
"value": 10000,
"icon": "Sigil of resistance.png"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26108,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 0,
"highalch": 1,
"icon": "Sigil of slaughter.png",
"name": "Sigil of slaughter"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26060,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 0,
"highalch": 1,
"icon": "Sigil of specialised strikes.png",
"name": "Sigil of specialised strikes"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26042,
"members": true,
"lowalch": 1,
"limit": 10,
"value": 0,
"highalch": 1,
"icon": "Sigil of stamina.png",
"name": "Sigil of stamina"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26021,
"members": true,
"lowalch": 1,
"limit": 10,
"value": 0,
"highalch": 1,
"icon": "Sigil of storage.png",
"name": "Sigil of storage"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26120,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 0,
"highalch": 1,
"icon": "Sigil of supreme stamina.png",
"name": "Sigil of supreme stamina"
},
{
"members": true,
"name": "Sigil of sustenance",
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 28478,
"value": 10000,
"icon": "Sigil of sustenance.png"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26039,
"members": true,
"lowalch": 1,
"limit": 10,
"value": 0,
"highalch": 1,
"icon": "Sigil of the abyss.png",
"name": "Sigil of the abyss"
},
{
"members": true,
"name": "Sigil of the alchemaniac",
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 28484,
"value": 10000,
"icon": "Sigil of the alchemaniac.png"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26027,
"members": true,
"lowalch": 1,
"limit": 10,
"value": 0,
"highalch": 1,
"icon": "Sigil of the alchemist.png",
"name": "Sigil of the alchemist"
},
{
"members": true,
"name": "Sigil of the augmented thrall",
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 28517,
"value": 10000,
"icon": "Sigil of the augmented thrall.png"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26090,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 0,
"highalch": 1,
"icon": "Sigil of the barbarians.png",
"name": "Sigil of the barbarians"
},
{
"members": true,
"name": "Sigil of the bloodhound",
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 28511,
"value": 10000,
"icon": "Sigil of the bloodhound.png"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26033,
"members": true,
"lowalch": 1,
"limit": 10,
"value": 0,
"highalch": 1,
"icon": "Sigil of the chef.png",
"name": "Sigil of the chef"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26036,
"members": true,
"lowalch": 1,
"limit": 10,
"value": 0,
"highalch": 1,
"icon": "Sigil of the craftsman.png",
"name": "Sigil of the craftsman"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26084,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 0,
"highalch": 1,
"icon": "Sigil of the dwarves.png",
"name": "Sigil of the dwarves"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26087,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 0,
"highalch": 1,
"icon": "Sigil of the elves.png",
"name": "Sigil of the elves"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26048,
"members": true,
"lowalch": 1,
"limit": 10,
"value": 0,
"highalch": 1,
"icon": "Sigil of the eternal jeweller.png",
"name": "Sigil of the eternal jeweller"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26075,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 0,
"highalch": 1,
"icon": "Sigil of the feral fighter.png",
"name": "Sigil of the feral fighter"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26030,
"members": true,
"lowalch": 1,
"limit": 10,
"value": 0,
"highalch": 1,
"icon": "Sigil of the fletcher.png",
"name": "Sigil of the fletcher"
},
{
"members": true,
"name": "Sigil of the food master",
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 28496,
"value": 10000,
"icon": "Sigil of the food master.png"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26102,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 0,
"highalch": 1,
"icon": "Sigil of the forager.png",
"name": "Sigil of the forager"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 25997,
"members": true,
"lowalch": 1,
"limit": 10,
"value": 0,
"highalch": 1,
"icon": "Sigil of the formidable fighter.png",
"name": "Sigil of the formidable fighter"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26111,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 0,
"highalch": 1,
"icon": "Sigil of the fortune farmer.png",
"name": "Sigil of the fortune farmer"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26093,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 0,
"highalch": 1,
"icon": "Sigil of the gnomes.png",
"name": "Sigil of the gnomes"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26147,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 0,
"highalch": 1,
"icon": "Sigil of the guardian angel.png",
"name": "Sigil of the guardian angel"
},
{
"members": true,
"name": "Sigil of the hunter",
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 28487,
"value": 10000,
"icon": "Sigil of the hunter.png"
},
{
"members": true,
"name": "Sigil of the infernal chef",
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 28502,
"value": 10000,
"icon": "Sigil of the infernal chef.png"
},
{
"members": true,
"name": "Sigil of the infernal smith",
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 28505,
"value": 10000,
"icon": "Sigil of the infernal smith.png"
},
{
"members": true,
"name": "Sigil of the lightbearer",
"examine": "A sigil attuned to you with great power.",
"id": 28508,
"value": 10000,
"icon": "Sigil of the lightbearer.png"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26078,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 0,
"highalch": 1,
"icon": "Sigil of the menacing mage.png",
"name": "Sigil of the menacing mage"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26003,
"members": true,
"lowalch": 1,
"limit": 10,
"value": 0,
"highalch": 1,
"icon": "Sigil of the meticulous mage.png",
"name": "Sigil of the meticulous mage"
},
{
"members": true,
"name": "Sigil of the ninja",
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 28526,
"value": 1,
"icon": "Sigil of the ninja.png"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26063,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 0,
"highalch": 1,
"icon": "Sigil of the porcupine.png",
"name": "Sigil of the porcupine"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26045,
"members": true,
"lowalch": 1,
"limit": 10,
"value": 0,
"highalch": 1,
"icon": "Sigil of the potion master.png",
"name": "Sigil of the potion master"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26000,
"members": true,
"lowalch": 1,
"limit": 10,
"value": 0,
"highalch": 1,
"icon": "Sigil of the rigorous ranger.png",
"name": "Sigil of the rigorous ranger"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26072,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 0,
"highalch": 1,
"icon": "Sigil of the ruthless ranger.png",
"name": "Sigil of the ruthless ranger"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26117,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 0,
"highalch": 1,
"icon": "Sigil of the serpent.png",
"name": "Sigil of the serpent"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26138,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 0,
"highalch": 1,
"icon": "Sigil of the skiller.png",
"name": "Sigil of the skiller"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26024,
"members": true,
"lowalch": 1,
"limit": 10,
"value": 0,
"highalch": 1,
"icon": "Sigil of the smith.png",
"name": "Sigil of the smith"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26051,
"members": true,
"lowalch": 1,
"limit": 10,
"value": 0,
"highalch": 1,
"icon": "Sigil of the treasure hunter.png",
"name": "Sigil of the treasure hunter"
},
{
"members": true,
"name": "Sigil of the well-fed",
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 28499,
"value": 10000,
"icon": "Sigil of the well-fed.png"
},
{
"members": true,
"name": "Sigil of titanium",
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 28523,
"value": 1,
"icon": "Sigil of titanium.png"
},
{
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 26114,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 0,
"highalch": 1,
"icon": "Sigil of versatility.png",
"name": "Sigil of versatility"
},
{
"members": true,
"name": "Sigil of woodcraft",
"examine": "A power enhancing sigil not yet attuned to you.",
"id": 28529,
"value": 1,
"icon": "Sigil of woodcraft.png"
},
{
"examine": "It's a sheet of silk.",
"id": 950,
"members": false,
"lowalch": 12,
"limit": 18000,
"value": 30,
"highalch": 18,
"icon": "Silk.png",
"name": "Silk"
},
{
"examine": "It's a bar of silver.",
"id": 2355,
"members": false,
"lowalch": 60,
"limit": 10000,
"value": 150,
"highalch": 90,
"icon": "Silver bar.png",
"name": "Silver bar"
},
{
"examine": "Silver crossbow bolts.",
"id": 9145,
"members": true,
"lowalch": 2,
"limit": 11000,
"value": 5,
"highalch": 3,
"icon": "Silver bolts 5.png",
"name": "Silver bolts"
},
{
"examine": "Some poisoned silver bolts.",
"id": 9292,
"members": true,
"lowalch": 2,
"limit": 11000,
"value": 5,
"highalch": 3,
"icon": "Silver bolts (p) 5.png",
"name": "Silver bolts (p)"
},
{
"examine": "Some poisoned silver bolts.",
"id": 9299,
"members": true,
"lowalch": 2,
"limit": 11000,
"value": 5,
"highalch": 3,
"icon": "Silver bolts (p+) 5.png",
"name": "Silver bolts (p+)"
},
{
"examine": "Some poisoned silver bolts.",
"id": 9306,
"members": true,
"lowalch": 2,
"limit": 11000,
"value": 5,
"highalch": 3,
"icon": "Silver bolts (p++) 5.png",
"name": "Silver bolts (p++)"
},
{
"examine": "Unfeathered silver crossbow bolts.",
"id": 9382,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Silver bolts (unf) 5.png",
"name": "Silver bolts (unf)"
},
{
"examine": "It's ground up silver.",
"id": 7650,
"members": true,
"lowalch": 80,
"limit": 13000,
"value": 200,
"highalch": 120,
"icon": "Silver dust.png",
"name": "Silver dust"
},
{
"examine": "Perhaps someone can do something with this.",
"id": 25451,
"members": true,
"lowalch": 7680,
"limit": 8,
"value": 19200,
"highalch": 11520,
"icon": "Silver locks.png",
"name": "Silver locks"
},
{
"examine": "This needs refining.",
"id": 442,
"members": false,
"lowalch": 30,
"limit": 13000,
"value": 75,
"highalch": 45,
"icon": "Silver ore.png",
"name": "Silver ore"
},
{
"examine": "It's a silver sickle.",
"id": 2961,
"members": true,
"lowalch": 70,
"limit": 15,
"value": 175,
"highalch": 105,
"icon": "Silver sickle.png",
"name": "Silver sickle"
},
{
"examine": "I can use this to make a crossbow string.",
"id": 9436,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Sinew.png",
"name": "Sinew"
},
{
"examine": "You get a sense of dread from this key.",
"id": 993,
"members": true,
"lowalch": 32,
"limit": 11000,
"value": 80,
"highalch": 48,
"icon": "Sinister key.png",
"name": "Sinister key"
},
{
"examine": "Some finely crafted Fremennik boots, made from the bones of a wallasalki.",
"id": 6147,
"members": true,
"lowalch": 260,
"limit": 125,
"value": 650,
"highalch": 390,
"icon": "Skeletal boots.png",
"name": "Skeletal boots"
},
{
"examine": "A superior set of strengthened slacks for any self respecting seer.",
"id": 6141,
"members": true,
"lowalch": 16000,
"limit": 70,
"value": 40000,
"highalch": 24000,
"icon": "Skeletal bottoms.png",
"name": "Skeletal bottoms"
},
{
"examine": "Fremennik gloves stitched together from wallasalki bones fragments.",
"id": 6153,
"members": true,
"lowalch": 260,
"limit": 125,
"value": 650,
"highalch": 390,
"icon": "Skeletal gloves.png",
"name": "Skeletal gloves"
},
{
"examine": "Make your foes cower by wearing a skull as a helmet!",
"id": 6137,
"members": true,
"lowalch": 4000,
"limit": 70,
"value": 10000,
"highalch": 6000,
"icon": "Skeletal helm.png",
"name": "Skeletal helm"
},
{
"examine": "The bones in this armour seem to vibrate with a magical quality...",
"id": 6139,
"members": true,
"lowalch": 18000,
"limit": 70,
"value": 45000,
"highalch": 27000,
"icon": "Skeletal top.png",
"name": "Skeletal top"
},
{
"examine": "It looks like this could be attached to a shield somehow.",
"id": 22006,
"members": true,
"lowalch": 600000,
"limit": 5,
"value": 1500000,
"highalch": 900000,
"icon": "Skeletal visage.png",
"name": "Skeletal visage"
},
{
"examine": "Generally used for impaling fresh meat.",
"id": 7443,
"members": true,
"lowalch": 1280,
"limit": 50,
"value": 3200,
"highalch": 1920,
"icon": "Skewer.png",
"name": "Skewer"
},
{
"examine": "A sharp pointed stick, quite resistant to fire.",
"id": 6305,
"members": true,
"lowalch": 4,
"limit": 13000,
"value": 10,
"highalch": 6,
"icon": "Skewer stick.png",
"name": "Skewer stick"
},
{
"examine": "You will need to recharge the necklace at the Legends Guild.",
"id": 11113,
"members": true,
"lowalch": 8080,
"limit": 10000,
"value": 20200,
"highalch": 12120,
"icon": "Skills necklace.png",
"name": "Skills necklace"
},
{
"examine": "This will help me travel.",
"id": 11105,
"members": true,
"lowalch": 8080,
"limit": 10000,
"value": 20200,
"highalch": 12120,
"icon": "Skills necklace(4).png",
"name": "Skills necklace(4)"
},
{
"examine": "This will help me travel.",
"id": 11968,
"members": true,
"lowalch": 8080,
"limit": 10000,
"value": 20200,
"highalch": 12120,
"icon": "Skills necklace(6).png",
"name": "Skills necklace(6)"
},
{
"examine": "A blue skirt.",
"id": 5052,
"members": true,
"lowalch": 250,
"limit": 150,
"value": 625,
"highalch": 375,
"icon": "Skirt (blue).png",
"name": "Skirt (blue)"
},
{
"examine": "A brown skirt. Size small!",
"id": 5048,
"members": true,
"lowalch": 140,
"limit": 150,
"value": 350,
"highalch": 210,
"icon": "Skirt (brown).png",
"name": "Skirt (brown)"
},
{
"examine": "Lilac skirt.",
"id": 5050,
"members": true,
"lowalch": 220,
"limit": 150,
"value": 550,
"highalch": 330,
"icon": "Skirt (lilac).png",
"name": "Skirt (lilac)"
},
{
"examine": "The skull of an oathbound warrior. Severed from its body, it still maintains some power.",
"id": 27673,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 1,
"highalch": 1,
"icon": "Skull of vet'ion.png",
"name": "Skull of vet'ion"
},
{
"examine": "A fearsome looking skull.",
"id": 6163,
"members": true,
"lowalch": 24,
"limit": 11000,
"value": 60,
"highalch": 36,
"icon": "Skull piece.png",
"name": "Skull piece"
},
{
"examine": "Don't make anyone jump when you ring this!",
"id": 10952,
"members": true,
"lowalch": 60,
"limit": 40,
"value": 150,
"highalch": 90,
"icon": "Slayer bell.png",
"name": "Slayer bell"
},
{
"examine": "The parchment contains a spell invented by Slayer Masters in ancient times.",
"id": 21257,
"members": true,
"lowalch": 480,
"limit": 500,
"value": 1200,
"highalch": 720,
"icon": "Slayer's enchantment.png",
"name": "Slayer's enchantment"
},
{
"examine": "Ale with bite.",
"id": 5759,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 2,
"highalch": 1,
"icon": "Slayer's respite.png",
"name": "Slayer's respite"
},
{
"examine": "This keg contains 4 pints of Slayer's Respite.",
"id": 5841,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Slayer's respite(4).png",
"name": "Slayer's respite(4)"
},
{
"examine": "This looks a good deal stronger than normal Slayer's Respite.",
"id": 5761,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 2,
"highalch": 1,
"icon": "Slayer's respite(m).png",
"name": "Slayer's respite(m)"
},
{
"examine": "This keg contains 4 pints of mature Slayer's Respite.",
"id": 5921,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Slayer's respite(m4).png",
"name": "Slayer's respite(m4)"
},
{
"examine": "An old and magical staff.",
"id": 4170,
"members": true,
"lowalch": 8400,
"limit": 70,
"value": 21000,
"highalch": 12600,
"icon": "Slayer's staff.png",
"name": "Slayer's staff"
},
{
"examine": "A cap for wearing whilzzzzzzzzzz.",
"id": 10398,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "Sleeping cap.png",
"name": "Sleeping cap"
},
{
"examine": "You swear you had more than three slices before.",
"id": 3162,
"members": false,
"lowalch": 1,
"limit": 15,
"value": 2,
"highalch": 1,
"icon": "Sliced banana.png",
"name": "Sliced banana"
},
{
"examine": "A bowl of sliced Bittercap mushrooms.",
"id": 7080,
"members": true,
"lowalch": 16,
"limit": 13000,
"value": 42,
"highalch": 25,
"icon": "Sliced mushrooms.png",
"name": "Sliced mushrooms"
},
{
"examine": "You can plant this in your garden.",
"id": 8441,
"members": true,
"lowalch": 6000,
"limit": 10000,
"value": 15000,
"highalch": 9000,
"icon": "Small box hedge (bagged).png",
"name": "Small box hedge (bagged)"
},
{
"examine": "Useful for catching small fish.",
"id": 303,
"members": false,
"lowalch": 2,
"limit": 40,
"value": 5,
"highalch": 3,
"icon": "Small fishing net.png",
"name": "Small fishing net"
},
{
"examine": "It gives a 10% accuracy and damage bonus for spells on the standard spellbook.",
"id": 11998,
"members": true,
"lowalch": 6200,
"limit": 8,
"value": 15500,
"highalch": 9300,
"icon": "Smoke battlestaff.png",
"name": "Smoke battlestaff"
},
{
"examine": "A combined Air and Fire Rune.",
"id": 4697,
"members": true,
"lowalch": 8,
"limit": 18000,
"value": 20,
"highalch": 12,
"icon": "Smoke rune.png",
"name": "Smoke rune"
},
{
"examine": "A smouldering stone from the depths of Hell.",
"id": 13233,
"members": true,
"lowalch": 18000,
"limit": 5,
"value": 45000,
"highalch": 27000,
"icon": "Smouldering stone.png",
"name": "Smouldering stone"
},
{
"examine": "Scaly but not slimy! It could be a useful material if it were tanned.",
"id": 6287,
"members": true,
"lowalch": 14,
"limit": 13000,
"value": 35,
"highalch": 21,
"icon": "Snake hide.png",
"name": "Snake hide"
},
{
"examine": "Scaly but not slimy!",
"id": 7801,
"members": true,
"lowalch": 14,
"limit": 11000,
"value": 35,
"highalch": 21,
"icon": "Snake hide (swamp).png",
"name": "Snake hide (swamp)"
},
{
"examine": "Nicely tanned skin from a snake.",
"id": 6289,
"members": true,
"lowalch": 20,
"limit": 13000,
"value": 50,
"highalch": 30,
"icon": "Snakeskin.png",
"name": "Snakeskin"
},
{
"examine": "Lightweight head protection.",
"id": 6326,
"members": true,
"lowalch": 120,
"limit": 125,
"value": 300,
"highalch": 180,
"icon": "Snakeskin bandana.png",
"name": "Snakeskin bandana"
},
{
"examine": "Made from 100% real snakeskin.",
"id": 6322,
"members": true,
"lowalch": 500,
"limit": 125,
"value": 1250,
"highalch": 750,
"icon": "Snakeskin body.png",
"name": "Snakeskin body"
},
{
"examine": "Made from snakes.",
"id": 6328,
"members": true,
"lowalch": 100,
"limit": 125,
"value": 250,
"highalch": 150,
"icon": "Snakeskin boots.png",
"name": "Snakeskin boots"
},
{
"examine": "Made from 100% real snake.",
"id": 6324,
"members": true,
"lowalch": 470,
"limit": 125,
"value": 1175,
"highalch": 705,
"icon": "Snakeskin chaps.png",
"name": "Snakeskin chaps"
},
{
"examine": "A solid willow shield covered in snakeskin leather.",
"id": 22272,
"members": true,
"lowalch": 368,
"limit": 125,
"value": 920,
"highalch": 552,
"icon": "Snakeskin shield.png",
"name": "Snakeskin shield"
},
{
"examine": "Made from 100% real snake.",
"id": 6330,
"members": true,
"lowalch": 173,
"limit": 125,
"value": 434,
"highalch": 260,
"icon": "Snakeskin vambraces.png",
"name": "Snakeskin vambraces"
},
{
"examine": "A powerful herb.",
"id": 3000,
"members": true,
"lowalch": 23,
"limit": 11000,
"value": 59,
"highalch": 35,
"icon": "Snapdragon.png",
"name": "Snapdragon"
},
{
"examine": "I need another ingredient to finish this Snapdragon potion.",
"id": 3004,
"members": true,
"lowalch": 23,
"limit": 10000,
"value": 59,
"highalch": 35,
"icon": "Snapdragon potion (unf).png",
"name": "Snapdragon potion (unf)"
},
{
"examine": "A snapdragon seed - plant in a herb patch.",
"id": 5300,
"members": true,
"lowalch": 24,
"limit": 200,
"value": 60,
"highalch": 36,
"icon": "Snapdragon seed 5.png",
"name": "Snapdragon seed"
},
{
"examine": "Strange spiky grass.",
"id": 231,
"members": true,
"lowalch": 4,
"limit": 13000,
"value": 10,
"highalch": 6,
"icon": "Snape grass.png",
"name": "Snape grass"
},
{
"examine": "A snape grass seed - plant in an allotment.",
"id": 22879,
"members": true,
"lowalch": 37,
"limit": 200,
"value": 94,
"highalch": 56,
"icon": "Snape grass seed 5.png",
"name": "Snape grass seed"
},
{
"examine": "There's a snowy knight butterfly in here.",
"id": 10016,
"members": true,
"lowalch": 20,
"limit": 125,
"value": 50,
"highalch": 30,
"icon": "Snowy knight (item).png",
"name": "Snowy knight (item)"
},
{
"highalch": 30,
"members": true,
"name": "Snowy knight mix (1)",
"examine": "There's a snowy knight butterfly in here.",
"id": 29201,
"value": 50,
"icon": "Snowy knight mix (1).png",
"lowalch": 20
},
{
"highalch": 30,
"members": true,
"name": "Snowy knight mix (2)",
"examine": "There's a snowy knight butterfly in here.",
"id": 29183,
"value": 50,
"icon": "Snowy knight mix (2).png",
"lowalch": 20
},
{
"examine": "It feels clammy, like second-hand toilet paper.",
"id": 25578,
"members": true,
"lowalch": 68,
"limit": 11000,
"value": 170,
"highalch": 102,
"icon": "Soaked page.png",
"name": "Soaked page"
},
{
"examine": "One of the ingredients for making glass.",
"id": 1781,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Soda ash.png",
"name": "Soda ash"
},
{
"examine": "Clay soft enough to mould.",
"id": 1761,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Soft clay.png",
"name": "Soft clay"
},
{
"examine": "Used for high level curse spells.",
"id": 566,
"members": true,
"lowalch": 120,
"limit": 25000,
"value": 300,
"highalch": 180,
"icon": "Soul rune.png",
"name": "Soul rune"
},
{
"highalch": 1,
"members": true,
"name": "Soulreaper axe",
"examine": "An axe that manipulates the very lifeforce of those who touch it.",
"id": 28338,
"value": 1,
"icon": "Soulreaper axe.png",
"lowalch": 1
},
{
"examine": "A slightly muddy spade.",
"id": 952,
"members": false,
"lowalch": 1,
"limit": 40,
"value": 3,
"highalch": 1,
"icon": "Spade.png",
"name": "Spade"
},
{
"examine": "A large spatula... of doom!",
"id": 7439,
"members": true,
"lowalch": 768,
"limit": 50,
"value": 1920,
"highalch": 1152,
"icon": "Spatula.png",
"name": "Spatula"
},
{
"examine": "A sigil in the shape of a spectral symbol.",
"id": 12823,
"members": true,
"lowalch": 300000,
"limit": 5,
"value": 750000,
"highalch": 450000,
"icon": "Spectral sigil.png",
"name": "Spectral sigil"
},
{
"examine": "An ethereal shield with a spectral sigil attached to it.",
"id": 12821,
"members": true,
"lowalch": 800000,
"limit": 8,
"value": 2000000,
"highalch": 1200000,
"icon": "Spectral spirit shield.png",
"name": "Spectral spirit shield"
},
{
"examine": "This could liven up an otherwise bland stew.",
"id": 2007,
"members": true,
"lowalch": 92,
"limit": 11000,
"value": 230,
"highalch": 138,
"icon": "Spice.png",
"name": "Spice"
},
{
"examine": "Yum... smells spicy.",
"id": 2213,
"members": true,
"lowalch": 1,
"limit": 6000,
"value": 2,
"highalch": 1,
"icon": "Spicy crunchies.png",
"name": "Spicy crunchies"
},
{
"examine": "A bowl of fiery minced meat.",
"id": 9996,
"members": true,
"lowalch": 3,
"limit": 8000,
"value": 8,
"highalch": 4,
"icon": "Spicy minced meat.png",
"name": "Spicy minced meat"
},
{
"examine": "A bowl of spicy sauce.",
"id": 7072,
"members": true,
"lowalch": 3,
"limit": 13000,
"value": 9,
"highalch": 5,
"icon": "Spicy sauce.png",
"name": "Spicy sauce"
},
{
"examine": "A bowl of chopped tomatoes with an extra kick.",
"id": 9994,
"members": true,
"lowalch": 1,
"limit": 8000,
"value": 3,
"highalch": 1,
"icon": "Spicy tomato.png",
"name": "Spicy tomato"
},
{
"examine": "Its creeping days are over!",
"id": 6291,
"members": true,
"lowalch": 6,
"limit": 13000,
"value": 15,
"highalch": 9,
"icon": "Spider carcass.png",
"name": "Spider carcass"
},
{
"examine": "A nicely roasted spider threaded onto an arrow shaft.",
"id": 6299,
"members": true,
"lowalch": 16,
"limit": 6000,
"value": 40,
"highalch": 24,
"icon": "Spider on shaft.png",
"name": "Spider on shaft"
},
{
"examine": "A nicely roasted spider threaded onto a skewer stick.",
"id": 6297,
"members": true,
"lowalch": 20,
"limit": 6000,
"value": 50,
"highalch": 30,
"icon": "Spider on stick.png",
"name": "Spider on stick"
},
{
"examine": "Climbing boots with spikes.",
"id": 3107,
"members": true,
"lowalch": 12,
"limit": 15,
"value": 30,
"highalch": 18,
"icon": "Spiked boots.png",
"name": "Spiked boots"
},
{
"examine": "Some very spikey metal bands, better make sure I don't cut myself while walking.",
"id": 23389,
"members": true,
"lowalch": 1000,
"limit": 8,
"value": 2500,
"highalch": 1500,
"icon": "Spiked manacles.png",
"name": "Spiked manacles"
},
{
"examine": "Attack at your own risk.",
"id": 10077,
"members": true,
"lowalch": 64,
"limit": 125,
"value": 160,
"highalch": 96,
"icon": "Spiky vambraces.png",
"name": "Spiky vambraces"
},
{
"examine": "A home made spinach thing.",
"id": 1969,
"members": false,
"lowalch": 1,
"limit": 50,
"value": 1,
"highalch": 1,
"icon": "Spinach roll.png",
"name": "Spinach roll"
},
{
"examine": "A constant reminder that I'm above a Dagannoth in the food chain.",
"id": 6133,
"members": true,
"lowalch": 3120,
"limit": 70,
"value": 7800,
"highalch": 4680,
"icon": "Spined body.png",
"name": "Spined body"
},
{
"examine": "Some finely crafted Fremennik boots, made from spined dagannoth hide.",
"id": 6143,
"members": true,
"lowalch": 260,
"limit": 125,
"value": 650,
"highalch": 390,
"icon": "Spined boots.png",
"name": "Spined boots"
},
{
"examine": "Stylish leg armour for rangers with a lingering smell of raw fish...",
"id": 6135,
"members": true,
"lowalch": 1560,
"limit": 70,
"value": 3900,
"highalch": 2340,
"icon": "Spined chaps.png",
"name": "Spined chaps"
},
{
"examine": "Fremennik gloves stitched together from spined dagannoth hide.",
"id": 6149,
"members": true,
"lowalch": 260,
"limit": 125,
"value": 650,
"highalch": 390,
"icon": "Spined gloves.png",
"name": "Spined gloves"
},
{
"examine": "A helm fit for any Fremennik ranger.",
"id": 6131,
"members": true,
"lowalch": 24000,
"limit": 70,
"value": 60000,
"highalch": 36000,
"icon": "Spined helm.png",
"name": "Spined helm"
},
{
"examine": "You don't want to wear it inside-out.",
"id": 4551,
"members": true,
"lowalch": 260,
"limit": 125,
"value": 650,
"highalch": 390,
"icon": "Spiny helmet.png",
"name": "Spiny helmet"
},
{
"examine": "An ethereal shield.",
"id": 12829,
"members": true,
"lowalch": 28000,
"limit": 8,
"value": 70000,
"highalch": 42000,
"icon": "Spirit shield.png",
"name": "Spirit shield"
},
{
"examine": "Used to repair bridges.",
"id": 10812,
"members": true,
"lowalch": 36,
"limit": 11000,
"value": 90,
"highalch": 54,
"icon": "Split log.png",
"name": "Split log"
},
{
"examine": "Provides good protection.",
"id": 3387,
"members": true,
"lowalch": 18000,
"limit": 70,
"value": 45000,
"highalch": 27000,
"icon": "Splitbark body.png",
"name": "Splitbark body"
},
{
"examine": "Wooden foot protection.",
"id": 3393,
"members": true,
"lowalch": 2000,
"limit": 70,
"value": 5000,
"highalch": 3000,
"icon": "Splitbark boots.png",
"name": "Splitbark boots"
},
{
"examine": "These should keep my hands safe.",
"id": 3391,
"members": true,
"lowalch": 2000,
"limit": 70,
"value": 5000,
"highalch": 3000,
"icon": "Splitbark gauntlets.png",
"name": "Splitbark gauntlets"
},
{
"examine": "A wooden helmet.",
"id": 3385,
"members": true,
"lowalch": 4000,
"limit": 70,
"value": 10000,
"highalch": 6000,
"icon": "Splitbark helm.png",
"name": "Splitbark helm"
},
{
"examine": "These should protect my legs.",
"id": 3389,
"members": true,
"lowalch": 16000,
"limit": 70,
"value": 40000,
"highalch": 24000,
"icon": "Splitbark legs.png",
"name": "Splitbark legs"
},
{
"examine": "Use the spork.",
"id": 7437,
"members": true,
"lowalch": 130,
"limit": 50,
"value": 325,
"highalch": 195,
"icon": "Spork.png",
"name": "Spork"
},
{
"examine": "A surprisingly aerodynamic cape.",
"id": 10069,
"members": true,
"lowalch": 160,
"limit": 125,
"value": 400,
"highalch": 240,
"icon": "Spotted cape.png",
"name": "Spotted cape"
},
{
"examine": "Maybe this is why people think furry dice make you go faster.",
"id": 10125,
"members": true,
"lowalch": 60,
"limit": 10000,
"value": 150,
"highalch": 90,
"icon": "Spotted kebbit fur.png",
"name": "Spotted kebbit fur"
},
{
"examine": "A really surprisingly aerodynamic cape.",
"id": 10071,
"members": true,
"lowalch": 320,
"limit": 125,
"value": 800,
"highalch": 480,
"icon": "Spottier cape.png",
"name": "Spottier cape"
},
{
"examine": "It's a slightly magical stick.",
"id": 1379,
"members": false,
"lowalch": 6,
"limit": 125,
"value": 15,
"highalch": 9,
"icon": "Staff.png",
"name": "Staff"
},
{
"examine": "A Magical staff.",
"id": 1381,
"members": false,
"lowalch": 600,
"limit": 125,
"value": 1500,
"highalch": 900,
"icon": "Staff of air.png",
"name": "Staff of air"
},
{
"highalch": 1,
"members": true,
"name": "Staff of balance",
"examine": "A ghastly weapon with evil origins combined with an icon of balance.",
"id": 24144,
"value": 1,
"icon": "Staff of balance.png",
"lowalch": 1
},
{
"examine": "A staff styled after the elusive cat.",
"id": 23363,
"members": true,
"lowalch": 600,
"limit": 8,
"value": 1500,
"highalch": 900,
"icon": "Staff of bob the cat.png",
"name": "Staff of bob the cat"
},
{
"examine": "A Magical staff.",
"id": 1385,
"members": false,
"lowalch": 600,
"limit": 125,
"value": 1500,
"highalch": 900,
"icon": "Staff of earth.png",
"name": "Staff of earth"
},
{
"examine": "A Magical staff.",
"id": 1387,
"members": false,
"lowalch": 600,
"limit": 125,
"value": 1500,
"highalch": 900,
"icon": "Staff of fire.png",
"name": "Staff of fire"
},
{
"examine": "A ghastly weapon with evil origins combined with a holy light.",
"id": 22296,
"members": true,
"lowalch": 400002,
"limit": 8,
"value": 1000005,
"highalch": 600003,
"icon": "Staff of light.png",
"name": "Staff of light"
},
{
"examine": "A ghastly weapon with evil origins.",
"id": 11791,
"members": true,
"lowalch": 400002,
"limit": 8,
"value": 1000005,
"highalch": 600003,
"icon": "Staff of the dead.png",
"name": "Staff of the dead"
},
{
"examine": "A Magical staff.",
"id": 1383,
"members": false,
"lowalch": 600,
"limit": 125,
"value": 1500,
"highalch": 900,
"icon": "Staff of water.png",
"name": "Staff of water"
},
{
"examine": "Baguette ready for pain.",
"id": 20590,
"members": false,
"lowalch": 90,
"limit": 5,
"value": 225,
"highalch": 135,
"icon": "Stale baguette.png",
"name": "Stale baguette"
},
{
"examine": "One dose of fishy stamina potion.",
"id": 12635,
"members": true,
"lowalch": 36,
"limit": 2000,
"value": 90,
"highalch": 54,
"icon": "Stamina mix(1).png",
"name": "Stamina mix(1)"
},
{
"examine": "Two doses of fishy stamina potion.",
"id": 12633,
"members": true,
"lowalch": 64,
"limit": 2000,
"value": 160,
"highalch": 96,
"icon": "Stamina mix(2).png",
"name": "Stamina mix(2)"
},
{
"examine": "1 dose of stamina potion.",
"id": 12631,
"members": true,
"lowalch": 40,
"limit": 2000,
"value": 100,
"highalch": 60,
"icon": "Stamina potion(1).png",
"name": "Stamina potion(1)"
},
{
"examine": "2 doses of stamina potion.",
"id": 12629,
"members": true,
"lowalch": 80,
"limit": 2000,
"value": 200,
"highalch": 120,
"icon": "Stamina potion(2).png",
"name": "Stamina potion(2)"
},
{
"examine": "3 doses of stamina potion.",
"id": 12627,
"members": true,
"lowalch": 120,
"limit": 2000,
"value": 300,
"highalch": 180,
"icon": "Stamina potion(3).png",
"name": "Stamina potion(3)"
},
{
"examine": "4 doses of stamina potion.",
"id": 12625,
"members": true,
"lowalch": 160,
"limit": 2000,
"value": 400,
"highalch": 240,
"icon": "Stamina potion(4).png",
"name": "Stamina potion(4)"
},
{
"examine": "A powerful warhammer.",
"id": 22622,
"members": true,
"lowalch": 120000,
"limit": 10,
"value": 300000,
"highalch": 180000,
"icon": "Statius's warhammer.png",
"name": "Statius's warhammer"
},
{
"examine": "It's a slightly magical stick.",
"id": 11787,
"members": true,
"lowalch": 6800,
"limit": 8,
"value": 17000,
"highalch": 10200,
"icon": "Steam battlestaff.png",
"name": "Steam battlestaff"
},
{
"examine": "A combined Water and Fire Rune.",
"id": 4694,
"members": true,
"lowalch": 8,
"limit": 18000,
"value": 20,
"highalch": 12,
"icon": "Steam rune.png",
"name": "Steam rune"
},
{
"examine": "Makes a steam battlestaff or mystic steam staff more beautiful.",
"id": 12798,
"members": true,
"lowalch": 600,
"limit": 50,
"value": 1500,
"highalch": 900,
"icon": "Steam staff upgrade kit.png",
"name": "Steam staff upgrade kit"
},
{
"highalch": 4,
"members": true,
"name": "Steamforge brew",
"examine": "A dwarven delight from Cam Torum.",
"id": 29412,
"value": 8,
"icon": "Steamforge brew.png",
"lowalch": 3
},
{
"examine": "A two handed sword.",
"id": 1311,
"members": false,
"lowalch": 400,
"limit": 125,
"value": 1000,
"highalch": 600,
"icon": "Steel 2h sword.png",
"name": "Steel 2h sword"
},
{
"examine": "Arrows with steel heads.",
"id": 886,
"members": false,
"lowalch": 4,
"limit": 7000,
"value": 12,
"highalch": 7,
"icon": "Steel arrow 5.png",
"name": "Steel arrow"
},
{
"examine": "Venomous-looking arrows.",
"id": 887,
"members": true,
"lowalch": 4,
"limit": 7000,
"value": 12,
"highalch": 7,
"icon": "Steel arrow(p) 5.png",
"name": "Steel arrow(p)"
},
{
"examine": "Venomous-looking arrows.",
"id": 5618,
"members": true,
"lowalch": 4,
"limit": 7000,
"value": 12,
"highalch": 7,
"icon": "Steel arrow(p+) 5.png",
"name": "Steel arrow(p+)"
},
{
"examine": "Venomous-looking arrows.",
"id": 5624,
"members": true,
"lowalch": 4,
"limit": 7000,
"value": 12,
"highalch": 7,
"icon": "Steel arrow(p++) 5.png",
"name": "Steel arrow(p++)"
},
{
"examine": "I can make some arrows with these.",
"id": 41,
"members": true,
"lowalch": 2,
"limit": 10000,
"value": 6,
"highalch": 3,
"icon": "Steel arrowtips.png",
"name": "Steel arrowtips"
},
{
"examine": "A woodcutter's axe.",
"id": 1353,
"members": false,
"lowalch": 80,
"limit": 40,
"value": 200,
"highalch": 120,
"icon": "Steel axe.png",
"name": "Steel axe"
},
{
"examine": "It's a bar of steel.",
"id": 2353,
"members": false,
"lowalch": 40,
"limit": 10000,
"value": 100,
"highalch": 60,
"icon": "Steel bar.png",
"name": "Steel bar"
},
{
"examine": "A vicious looking axe.",
"id": 1365,
"members": false,
"lowalch": 260,
"limit": 125,
"value": 650,
"highalch": 390,
"icon": "Steel battleaxe.png",
"name": "Steel battleaxe"
},
{
"examine": "Steel crossbow bolts.",
"id": 9141,
"members": true,
"lowalch": 3,
"limit": 7000,
"value": 8,
"highalch": 4,
"icon": "Steel bolts 5.png",
"name": "Steel bolts"
},
{
"examine": "Some poisoned steel bolts.",
"id": 9288,
"members": true,
"lowalch": 3,
"limit": 11000,
"value": 8,
"highalch": 4,
"icon": "Steel bolts (p) 5.png",
"name": "Steel bolts (p)"
},
{
"examine": "Some poisoned steel bolts.",
"id": 9295,
"members": true,
"lowalch": 3,
"limit": 11000,
"value": 8,
"highalch": 4,
"icon": "Steel bolts (p+) 5.png",
"name": "Steel bolts (p+)"
},
{
"examine": "Super poisoned steel bolts.",
"id": 9302,
"members": true,
"lowalch": 3,
"limit": 11000,
"value": 8,
"highalch": 4,
"icon": "Steel bolts (p++) 5.png",
"name": "Steel bolts (p++)"
},
{
"examine": "Unfeathered steel crossbow bolts.",
"id": 9378,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Steel bolts (unf) 5.png",
"name": "Steel bolts (unf)"
},
{
"examine": "These will protect my feet.",
"id": 4123,
"members": true,
"lowalch": 120,
"limit": 125,
"value": 300,
"highalch": 180,
"icon": "Steel boots.png",
"name": "Steel boots"
},
{
"examine": "Blunt steel arrow... ouch.",
"id": 4783,
"members": true,
"lowalch": 8,
"limit": 11000,
"value": 20,
"highalch": 12,
"icon": "Steel brutal 5.png",
"name": "Steel brutal"
},
{
"examine": "A series of connected metal rings.",
"id": 1105,
"members": false,
"lowalch": 300,
"limit": 125,
"value": 750,
"highalch": 450,
"icon": "Steel chainbody.png",
"name": "Steel chainbody"
},
{
"examine": "A set of fighting claws.",
"id": 3097,
"members": true,
"lowalch": 70,
"limit": 125,
"value": 175,
"highalch": 105,
"icon": "Steel claws.png",
"name": "Steel claws"
},
{
"examine": "A steel crossbow.",
"id": 9179,
"members": true,
"lowalch": 144,
"limit": 125,
"value": 360,
"highalch": 216,
"icon": "Steel crossbow.png",
"name": "Steel crossbow"
},
{
"examine": "An unstrung steel crossbow.",
"id": 9459,
"members": true,
"lowalch": 130,
"limit": 10000,
"value": 327,
"highalch": 196,
"icon": "Steel crossbow (u).png",
"name": "Steel crossbow (u)"
},
{
"examine": "Short but pointy.",
"id": 1207,
"members": false,
"lowalch": 50,
"limit": 125,
"value": 125,
"highalch": 75,
"icon": "Steel dagger.png",
"name": "Steel dagger"
},
{
"examine": "The blade has been poisoned.",
"id": 1223,
"members": true,
"lowalch": 50,
"limit": 125,
"value": 125,
"highalch": 75,
"icon": "Steel dagger(p).png",
"name": "Steel dagger(p)"
},
{
"examine": "The blade has been poisoned.",
"id": 5672,
"members": true,
"lowalch": 50,
"limit": 125,
"value": 125,
"highalch": 75,
"icon": "Steel dagger(p+).png",
"name": "Steel dagger(p+)"
},
{
"examine": "The blade has been poisoned.",
"id": 5690,
"members": true,
"lowalch": 50,
"limit": 125,
"value": 125,
"highalch": 75,
"icon": "Steel dagger(p++).png",
"name": "Steel dagger(p++)"
},
{
"examine": "A deadly throwing dart with a steel tip.",
"id": 808,
"members": true,
"lowalch": 4,
"limit": 7000,
"value": 10,
"highalch": 6,
"icon": "Steel dart.png",
"name": "Steel dart"
},
{
"examine": "A deadly-looking dart tip made of steel - needs feathers for flight.",
"id": 821,
"members": true,
"lowalch": 2,
"limit": 13000,
"value": 5,
"highalch": 3,
"icon": "Steel dart tip.png",
"name": "Steel dart tip"
},
{
"examine": "A deadly poisoned dart with a steel tip.",
"id": 814,
"members": true,
"lowalch": 4,
"limit": 7000,
"value": 10,
"highalch": 6,
"icon": "Steel dart(p).png",
"name": "Steel dart(p)"
},
{
"examine": "A deadly poisoned dart with a steel tip.",
"id": 5630,
"members": true,
"lowalch": 4,
"limit": 7000,
"value": 10,
"highalch": 6,
"icon": "Steel dart(p+).png",
"name": "Steel dart(p+)"
},
{
"examine": "A deadly poisoned dart with a steel tip.",
"id": 5637,
"members": true,
"lowalch": 4,
"limit": 7000,
"value": 10,
"highalch": 6,
"icon": "Steel dart(p++).png",
"name": "Steel dart(p++)"
},
{
"examine": "Do I look scary?",
"id": 12367,
"members": true,
"lowalch": 4000,
"limit": 4,
"value": 10000,
"highalch": 6000,
"icon": "Steel dragon mask.png",
"name": "Steel dragon mask"
},
{
"highalch": 120,
"members": true,
"name": "Steel felling axe",
"examine": "A woodcutter's axe.",
"id": 28202,
"value": 200,
"icon": "Steel felling axe.png",
"lowalch": 80
},
{
"examine": "A full face helmet.",
"id": 1157,
"members": false,
"lowalch": 220,
"limit": 125,
"value": 550,
"highalch": 330,
"icon": "Steel full helm.png",
"name": "Steel full helm"
},
{
"examine": "A steel full helmet with gold trim.",
"id": 20178,
"members": false,
"lowalch": 220,
"limit": 125,
"value": 550,
"highalch": 330,
"icon": "Steel full helm (g).png",
"name": "Steel full helm (g)"
},
{
"examine": "A steel full helmet with trim.",
"id": 20193,
"members": false,
"lowalch": 220,
"limit": 125,
"value": 550,
"highalch": 330,
"icon": "Steel full helm (t).png",
"name": "Steel full helm (t)"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 20382,
"members": false,
"lowalch": 360,
"limit": 8,
"value": 900,
"highalch": 540,
"icon": "Steel gold-trimmed set (lg).png",
"name": "Steel gold-trimmed set (lg)"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 20385,
"members": false,
"lowalch": 360,
"limit": 8,
"value": 900,
"highalch": 540,
"icon": "Steel gold-trimmed set (sk).png",
"name": "Steel gold-trimmed set (sk)"
},
{
"examine": "A steel halberd.",
"id": 3194,
"members": true,
"lowalch": 400,
"limit": 125,
"value": 1000,
"highalch": 600,
"icon": "Steel halberd.png",
"name": "Steel halberd"
},
{
"examine": "A steel-tipped, one-handed hasta.",
"id": 11371,
"members": true,
"lowalch": 130,
"limit": 125,
"value": 325,
"highalch": 195,
"icon": "Steel hasta.png",
"name": "Steel hasta"
},
{
"examine": "A poison-tipped, one-handed steel hasta.",
"id": 11393,
"members": true,
"lowalch": 130,
"limit": 125,
"value": 325,
"highalch": 195,
"icon": "Steel hasta(p).png",
"name": "Steel hasta(p)"
},
{
"examine": "A poison-tipped, one-handed steel hasta.",
"id": 11396,
"members": true,
"lowalch": 130,
"limit": 125,
"value": 325,
"highalch": 195,
"icon": "Steel hasta(p+).png",
"name": "Steel hasta(p+)"
},
{
"examine": "A poison-tipped, one-handed steel hasta.",
"id": 11398,
"members": true,
"lowalch": 130,
"limit": 125,
"value": 325,
"highalch": 195,
"icon": "Steel hasta(p++).png",
"name": "Steel hasta(p++)"
},
{
"examine": "A steel tipped javelin.",
"id": 827,
"members": true,
"lowalch": 9,
"limit": 7000,
"value": 24,
"highalch": 14,
"icon": "Steel javelin.png",
"name": "Steel javelin"
},
{
"examine": "Needs a shaft.",
"id": 19574,
"members": true,
"lowalch": 36,
"limit": 10000,
"value": 90,
"highalch": 54,
"icon": "Steel javelin heads 5.png",
"name": "Steel javelin heads"
},
{
"examine": "A steel tipped javelin.",
"id": 833,
"members": true,
"lowalch": 9,
"limit": 7000,
"value": 24,
"highalch": 14,
"icon": "Steel javelin(p).png",
"name": "Steel javelin(p)"
},
{
"examine": "A steel tipped javelin.",
"id": 5644,
"members": true,
"lowalch": 9,
"limit": 7000,
"value": 24,
"highalch": 14,
"icon": "Steel javelin(p+).png",
"name": "Steel javelin(p+)"
},
{
"examine": "A steel tipped javelin.",
"id": 5650,
"members": true,
"lowalch": 9,
"limit": 7000,
"value": 24,
"highalch": 14,
"icon": "Steel javelin(p++).png",
"name": "Steel javelin(p++)"
},
{
"examine": "A large metal shield.",
"id": 1193,
"members": false,
"lowalch": 340,
"limit": 125,
"value": 850,
"highalch": 510,
"icon": "Steel kiteshield.png",
"name": "Steel kiteshield"
},
{
"examine": "Steel kiteshield with gold trim.",
"id": 20181,
"members": false,
"lowalch": 340,
"limit": 125,
"value": 850,
"highalch": 510,
"icon": "Steel kiteshield (g).png",
"name": "Steel kiteshield (g)"
},
{
"examine": "Steel kiteshield with trim.",
"id": 20196,
"members": false,
"lowalch": 340,
"limit": 125,
"value": 850,
"highalch": 510,
"icon": "Steel kiteshield (t).png",
"name": "Steel kiteshield (t)"
},
{
"examine": "A finely balanced throwing knife.",
"id": 865,
"members": true,
"lowalch": 4,
"limit": 7000,
"value": 11,
"highalch": 6,
"icon": "Steel knife.png",
"name": "Steel knife"
},
{
"examine": "A finely balanced throwing knife.",
"id": 872,
"members": true,
"lowalch": 4,
"limit": 7000,
"value": 10,
"highalch": 6,
"icon": "Steel knife(p).png",
"name": "Steel knife(p)"
},
{
"examine": "A finely balanced throwing knife.",
"id": 5656,
"members": true,
"lowalch": 4,
"limit": 7000,
"value": 10,
"highalch": 6,
"icon": "Steel knife(p+).png",
"name": "Steel knife(p+)"
},
{
"examine": "A finely balanced throwing knife.",
"id": 5663,
"members": true,
"lowalch": 4,
"limit": 7000,
"value": 10,
"highalch": 6,
"icon": "Steel knife(p++).png",
"name": "Steel knife(p++)"
},
{
"examine": "A pair of steel crossbow limbs.",
"id": 9425,
"members": true,
"lowalch": 100,
"limit": 10000,
"value": 250,
"highalch": 150,
"icon": "Steel limbs.png",
"name": "Steel limbs"
},
{
"examine": "Perhaps someone can do something with this.",
"id": 25445,
"members": true,
"lowalch": 640,
"limit": 8,
"value": 1600,
"highalch": 960,
"icon": "Steel locks.png",
"name": "Steel locks"
},
{
"examine": "A razor sharp longsword.",
"id": 1295,
"members": false,
"lowalch": 200,
"limit": 125,
"value": 500,
"highalch": 300,
"icon": "Steel longsword.png",
"name": "Steel longsword"
},
{
"examine": "A spiky mace.",
"id": 1424,
"members": false,
"lowalch": 90,
"limit": 125,
"value": 225,
"highalch": 135,
"icon": "Steel mace.png",
"name": "Steel mace"
},
{
"examine": "A medium sized helmet.",
"id": 1141,
"members": false,
"lowalch": 120,
"limit": 125,
"value": 300,
"highalch": 180,
"icon": "Steel med helm.png",
"name": "Steel med helm"
},
{
"examine": "Keeps things in place fairly permanently.",
"id": 1539,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 3,
"highalch": 1,
"icon": "Steel nails.png",
"name": "Steel nails"
},
{
"examine": "Used for mining.",
"id": 1269,
"members": false,
"lowalch": 200,
"limit": 40,
"value": 500,
"highalch": 300,
"icon": "Steel pickaxe.png",
"name": "Steel pickaxe"
},
{
"examine": "Provides excellent protection.",
"id": 1119,
"members": false,
"lowalch": 800,
"limit": 125,
"value": 2000,
"highalch": 1200,
"icon": "Steel platebody.png",
"name": "Steel platebody"
},
{
"examine": "Steel platebody with a gold trim.",
"id": 20169,
"members": false,
"lowalch": 800,
"limit": 125,
"value": 2000,
"highalch": 1200,
"icon": "Steel platebody (g).png",
"name": "Steel platebody (g)"
},
{
"examine": "Steel platebody with a trim.",
"id": 20184,
"members": false,
"lowalch": 800,
"limit": 125,
"value": 2000,
"highalch": 1200,
"icon": "Steel platebody (t).png",
"name": "Steel platebody (t)"
},
{
"examine": "These look pretty heavy.",
"id": 1069,
"members": false,
"lowalch": 400,
"limit": 125,
"value": 1000,
"highalch": 600,
"icon": "Steel platelegs.png",
"name": "Steel platelegs"
},
{
"examine": "Steel platelegs with a gold trim.",
"id": 20172,
"members": false,
"lowalch": 400,
"limit": 125,
"value": 1000,
"highalch": 600,
"icon": "Steel platelegs (g).png",
"name": "Steel platelegs (g)"
},
{
"examine": "Steel platelegs with a trim.",
"id": 20187,
"members": false,
"lowalch": 400,
"limit": 125,
"value": 1000,
"highalch": 600,
"icon": "Steel platelegs (t).png",
"name": "Steel platelegs (t)"
},
{
"examine": "Designer leg protection.",
"id": 1083,
"members": false,
"lowalch": 400,
"limit": 125,
"value": 1000,
"highalch": 600,
"icon": "Steel plateskirt.png",
"name": "Steel plateskirt"
},
{
"examine": "Steel plateskirt with a gold trim.",
"id": 20175,
"members": false,
"lowalch": 400,
"limit": 125,
"value": 1000,
"highalch": 600,
"icon": "Steel plateskirt (g).png",
"name": "Steel plateskirt (g)"
},
{
"examine": "Steel plateskirt with a trim.",
"id": 20190,
"members": false,
"lowalch": 400,
"limit": 125,
"value": 1000,
"highalch": 600,
"icon": "Steel plateskirt (t).png",
"name": "Steel plateskirt (t)"
},
{
"examine": "A vicious, curved sword.",
"id": 1325,
"members": false,
"lowalch": 160,
"limit": 125,
"value": 400,
"highalch": 240,
"icon": "Steel scimitar.png",
"name": "Steel scimitar"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 12984,
"members": false,
"lowalch": 560,
"limit": 8,
"value": 1400,
"highalch": 840,
"icon": "Steel set (lg).png",
"name": "Steel set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 12986,
"members": false,
"lowalch": 560,
"limit": 8,
"value": 1400,
"highalch": 840,
"icon": "Steel set (sk).png",
"name": "Steel set (sk)"
},
{
"examine": "A steel tipped spear.",
"id": 1241,
"members": true,
"lowalch": 130,
"limit": 125,
"value": 325,
"highalch": 195,
"icon": "Steel spear.png",
"name": "Steel spear"
},
{
"examine": "A poisoned steel tipped spear.",
"id": 1255,
"members": true,
"lowalch": 130,
"limit": 125,
"value": 325,
"highalch": 195,
"icon": "Steel spear(p).png",
"name": "Steel spear(p)"
},
{
"examine": "A poisoned steel tipped spear.",
"id": 5708,
"members": true,
"lowalch": 130,
"limit": 125,
"value": 325,
"highalch": 195,
"icon": "Steel spear(p+).png",
"name": "Steel spear(p+)"
},
{
"examine": "A poisoned steel tipped spear.",
"id": 5722,
"members": true,
"lowalch": 130,
"limit": 125,
"value": 325,
"highalch": 195,
"icon": "Steel spear(p++).png",
"name": "Steel spear(p++)"
},
{
"examine": "A medium square shield.",
"id": 1177,
"members": false,
"lowalch": 240,
"limit": 125,
"value": 600,
"highalch": 360,
"icon": "Steel sq shield.png",
"name": "Steel sq shield"
},
{
"examine": "A set of studs for leather armour.",
"id": 2370,
"members": true,
"lowalch": 60,
"limit": 13000,
"value": 150,
"highalch": 90,
"icon": "Steel studs.png",
"name": "Steel studs"
},
{
"examine": "A razor sharp sword.",
"id": 1281,
"members": false,
"lowalch": 130,
"limit": 125,
"value": 325,
"highalch": 195,
"icon": "Steel sword.png",
"name": "Steel sword"
},
{
"examine": "A finely balanced throwing axe.",
"id": 802,
"members": true,
"lowalch": 10,
"limit": 7000,
"value": 26,
"highalch": 15,
"icon": "Steel thrownaxe.png",
"name": "Steel thrownaxe"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 20376,
"members": false,
"lowalch": 360,
"limit": 8,
"value": 900,
"highalch": 540,
"icon": "Steel trimmed set (lg).png",
"name": "Steel trimmed set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 20379,
"members": false,
"lowalch": 360,
"limit": 8,
"value": 900,
"highalch": 540,
"icon": "Steel trimmed set (sk).png",
"name": "Steel trimmed set (sk)"
},
{
"examine": "I don't think it's intended for joinery.",
"id": 1339,
"members": false,
"lowalch": 256,
"limit": 125,
"value": 640,
"highalch": 384,
"icon": "Steel warhammer.png",
"name": "Steel warhammer"
},
{
"examine": "It's a meat and potato stew.",
"id": 2003,
"members": false,
"lowalch": 8,
"limit": 10000,
"value": 20,
"highalch": 12,
"icon": "Stew.png",
"name": "Stew"
},
{
"examine": "Little ornament in the shape of a scarab.",
"id": 9030,
"members": true,
"lowalch": 8,
"limit": 13000,
"value": 20,
"highalch": 12,
"icon": "Stone scarab.png",
"name": "Stone scarab"
},
{
"examine": "A seal. Made out of stone obviously.",
"id": 9042,
"members": true,
"lowalch": 8,
"limit": 13000,
"value": 20,
"highalch": 12,
"icon": "Stone seal.png",
"name": "Stone seal"
},
{
"examine": "A small statuette.",
"id": 9038,
"members": true,
"lowalch": 8,
"limit": 13000,
"value": 20,
"highalch": 12,
"icon": "Stone statuette.png",
"name": "Stone statuette"
},
{
"examine": "The crushed rock seems to glow and is warm to the touch.",
"id": 22601,
"members": true,
"lowalch": 36,
"limit": 10000,
"value": 90,
"highalch": 54,
"icon": "Stony basalt.png",
"name": "Stony basalt"
},
{
"examine": "I wonder what this tastes like?",
"id": 464,
"members": true,
"lowalch": 1,
"limit": 2000,
"value": 1,
"highalch": 1,
"icon": "Strange fruit.png",
"name": "Strange fruit"
},
{
"examine": "A pristine set of lockpicks engraved with six names.",
"id": 24740,
"members": true,
"lowalch": 1,
"limit": 50,
"value": 1,
"highalch": 1,
"icon": "Strange old lockpick (full).png",
"name": "Strange old lockpick (full)"
},
{
"examine": "A fruit basket filled with strawberries.",
"id": 5406,
"members": true,
"lowalch": 1,
"limit": 200,
"value": 1,
"highalch": 1,
"icon": "Strawberries(5).png",
"name": "Strawberries(5)"
},
{
"examine": "A freshly picked strawberry.",
"id": 5504,
"members": true,
"lowalch": 6,
"limit": 11000,
"value": 17,
"highalch": 10,
"icon": "Strawberry.png",
"name": "Strawberry"
},
{
"examine": "A strawberry seed - plant in an allotment.",
"id": 5323,
"members": true,
"lowalch": 7,
"limit": 600,
"value": 18,
"highalch": 10,
"icon": "Strawberry seed 5.png",
"name": "Strawberry seed"
},
{
"examine": "An enchanted ruby amulet.",
"id": 10364,
"members": false,
"lowalch": 810,
"limit": 5,
"value": 2025,
"highalch": 1215,
"icon": "Strength amulet (t).png",
"name": "Strength amulet (t)"
},
{
"examine": "One dose of fishy strength potion.",
"id": 11441,
"members": true,
"lowalch": 4,
"limit": 2000,
"value": 11,
"highalch": 6,
"icon": "Strength mix(1).png",
"name": "Strength mix(1)"
},
{
"examine": "Two doses of fishy strength potion.",
"id": 11443,
"members": true,
"lowalch": 5,
"limit": 2000,
"value": 13,
"highalch": 7,
"icon": "Strength mix(2).png",
"name": "Strength mix(2)"
},
{
"examine": "1 dose of Strength potion.",
"id": 119,
"members": false,
"lowalch": 4,
"limit": 2000,
"value": 11,
"highalch": 6,
"icon": "Strength potion(1).png",
"name": "Strength potion(1)"
},
{
"examine": "2 doses of Strength potion.",
"id": 117,
"members": false,
"lowalch": 5,
"limit": 2000,
"value": 13,
"highalch": 7,
"icon": "Strength potion(2).png",
"name": "Strength potion(2)"
},
{
"examine": "3 doses of Strength potion.",
"id": 115,
"members": false,
"lowalch": 5,
"limit": 2000,
"value": 13,
"highalch": 7,
"icon": "Strength potion(3).png",
"name": "Strength potion(3)"
},
{
"examine": "4 doses of Strength potion.",
"id": 113,
"members": false,
"lowalch": 5,
"limit": 2000,
"value": 14,
"highalch": 8,
"icon": "Strength potion(4).png",
"name": "Strength potion(4)"
},
{
"examine": "A weathered chunk of dagannoth hide.",
"id": 6173,
"members": true,
"lowalch": 24,
"limit": 11000,
"value": 60,
"highalch": 36,
"icon": "Stretched hide.png",
"name": "Stretched hide"
},
{
"examine": "Attractive to other birds and hunters alike.",
"id": 10087,
"members": true,
"lowalch": 8,
"limit": 8000,
"value": 20,
"highalch": 12,
"icon": "Stripy feather.png",
"name": "Stripy feather"
},
{
"examine": "A sea worthy shirt.",
"id": 7110,
"members": true,
"lowalch": 120,
"limit": 150,
"value": 300,
"highalch": 180,
"icon": "Stripy pirate shirt (beige).png",
"name": "Stripy pirate shirt (beige)"
},
{
"examine": "A sea worthy shirt.",
"id": 7128,
"members": true,
"lowalch": 120,
"limit": 150,
"value": 300,
"highalch": 180,
"icon": "Stripy pirate shirt (blue).png",
"name": "Stripy pirate shirt (blue)"
},
{
"examine": "A sea worthy shirt.",
"id": 7134,
"members": true,
"lowalch": 120,
"limit": 150,
"value": 300,
"highalch": 180,
"icon": "Stripy pirate shirt (brown).png",
"name": "Stripy pirate shirt (brown)"
},
{
"examine": "A sea worthy shirt.",
"id": 7122,
"members": true,
"lowalch": 120,
"limit": 150,
"value": 300,
"highalch": 180,
"icon": "Stripy pirate shirt (red).png",
"name": "Stripy pirate shirt (red)"
},
{
"examine": "Information regarding the Stronghold of Security.",
"id": 9004,
"members": false,
"lowalch": 1,
"limit": 15,
"value": 2,
"highalch": 1,
"icon": "Stronghold notes.png",
"name": "Stronghold notes"
},
{
"examine": "Not so lucky for the rabbit.",
"id": 10132,
"members": true,
"lowalch": 12,
"limit": 150,
"value": 30,
"highalch": 18,
"icon": "Strung rabbit foot.png",
"name": "Strung rabbit foot"
},
{
"examine": "Those studs should provide a bit more protection.",
"id": 1133,
"members": false,
"lowalch": 340,
"limit": 125,
"value": 850,
"highalch": 510,
"icon": "Studded body.png",
"name": "Studded body"
},
{
"examine": "Those studs should provide a bit more protection. Nice trim too!",
"id": 7362,
"members": false,
"lowalch": 340,
"limit": 8,
"value": 850,
"highalch": 510,
"icon": "Studded body (g).png",
"name": "Studded body (g)"
},
{
"examine": "Those studs should provide a bit more protection. Nice trim too!",
"id": 7364,
"members": false,
"lowalch": 340,
"limit": 8,
"value": 850,
"highalch": 510,
"icon": "Studded body (t).png",
"name": "Studded body (t)"
},
{
"examine": "Those studs should provide a bit more protection.",
"id": 1097,
"members": false,
"lowalch": 300,
"limit": 125,
"value": 750,
"highalch": 450,
"icon": "Studded chaps.png",
"name": "Studded chaps"
},
{
"examine": "Those studs should provide a bit more protection. Nice trim too!",
"id": 7366,
"members": false,
"lowalch": 300,
"limit": 8,
"value": 750,
"highalch": 450,
"icon": "Studded chaps (g).png",
"name": "Studded chaps (g)"
},
{
"examine": "Those studs should provide a bit more protection. Nice trim too!",
"id": 7368,
"members": false,
"lowalch": 300,
"limit": 8,
"value": 750,
"highalch": 450,
"icon": "Studded chaps (t).png",
"name": "Studded chaps (t)"
},
{
"highalch": 1,
"members": true,
"name": "Sturdy harness",
"examine": "I can use this to combine the forestry kit and log basket into one tool.",
"id": 28149,
"value": 1,
"icon": "Sturdy harness.png",
"lowalch": 1
},
{
"examine": "A Sulliuscep mushroom cap. Edible? Probably...",
"id": 21626,
"members": true,
"lowalch": 1,
"limit": 100,
"value": 3,
"highalch": 1,
"icon": "Sulliuscep cap.png",
"name": "Sulliuscep cap"
},
{
"highalch": 18000,
"members": true,
"name": "Sulphur blades",
"examine": "Are these explosive?",
"id": 29084,
"value": 30000,
"icon": "Sulphur blades.png",
"lowalch": 12000
},
{
"examine": "All the fruits of a very small forest.",
"id": 7218,
"members": true,
"lowalch": 56,
"limit": 10000,
"value": 140,
"highalch": 84,
"icon": "Summer pie.png",
"name": "Summer pie"
},
{
"highalch": 60,
"members": true,
"name": "Sun-shine",
"examine": "A highly desirable liquor.",
"id": 29421,
"value": 100,
"icon": "Sun-shine.png",
"lowalch": 40
},
{
"highalch": 4,
"members": true,
"name": "Sunbeam ale",
"examine": "Just the thing you need for a day in the sun.",
"id": 29409,
"value": 8,
"icon": "Sunbeam ale.png",
"lowalch": 3
},
{
"highalch": 33000,
"members": true,
"name": "Sunfire fanatic armour set",
"examine": "A set containing a Sunfire Fanatic Helm, Sunfire Fanatic Platebody and Sunfire Fanatic Platelegs.",
"id": 29424,
"value": 55000,
"icon": "Sunfire fanatic armour set.png",
"lowalch": 22000
},
{
"highalch": 12000,
"members": true,
"name": "Sunfire fanatic chausses",
"examine": "Chausses forged from sunfire.",
"id": 28939,
"value": 20000,
"icon": "Sunfire fanatic chausses.png",
"lowalch": 8000
},
{
"highalch": 12000,
"members": true,
"name": "Sunfire fanatic cuirass",
"examine": "A cuirass forged from sunfire.",
"id": 28936,
"value": 20000,
"icon": "Sunfire fanatic cuirass.png",
"lowalch": 8000
},
{
"highalch": 9000,
"members": true,
"name": "Sunfire fanatic helm",
"examine": "A helmet forged from sunfire.",
"id": 28933,
"value": 15000,
"icon": "Sunfire fanatic helm.png",
"lowalch": 6000
},
{
"examine": "A fire rune empowered by the sun.",
"id": 28929,
"members": true,
"lowalch": 8,
"limit": 18000,
"value": 20,
"highalch": 12,
"icon": "Sunfire rune.png",
"name": "Sunfire rune"
},
{
"examine": "Vaporised material from the sun.",
"id": 28924,
"members": true,
"lowalch": 40,
"limit": 30000,
"value": 100,
"highalch": 60,
"icon": "Sunfire splinters.png",
"name": "Sunfire splinters"
},
{
"highalch": 1,
"members": true,
"name": "Sunlight antelope antler",
"examine": "I don't think I can stick it back on.",
"id": 29168,
"value": 1,
"icon": "Sunlight antelope antler.png",
"lowalch": 1
},
{
"highalch": 6,
"members": true,
"name": "Sunlight antelope fur",
"examine": "I can make something out of this.",
"id": 29177,
"value": 10,
"icon": "Sunlight antelope fur.png",
"lowalch": 4
},
{
"highalch": 18,
"members": true,
"name": "Sunlight antler bolts",
"examine": "Bolts made from the antlers of a sunlight antelope.",
"id": 28872,
"value": 31,
"icon": "Sunlight antler bolts.png",
"lowalch": 12
},
{
"highalch": 1,
"members": true,
"name": "Sunlight moth (item)",
"examine": "There's a sunlight moth in here.",
"id": 28890,
"value": 1,
"icon": "Sunlight moth (item).png",
"lowalch": 1
},
{
"highalch": 18,
"members": true,
"name": "Sunlight moth mix (1)",
"examine": "There's a sunlight moth in here.",
"id": 29210,
"value": 30,
"icon": "Sunlight moth mix (1).png",
"lowalch": 12
},
{
"highalch": 18,
"members": true,
"name": "Sunlight moth mix (2)",
"examine": "There's a sunlight moth in here.",
"id": 29192,
"value": 30,
"icon": "Sunlight moth mix (2).png",
"lowalch": 12
},
{
"examine": "One dose of fishy super anti-firebreath potion.",
"id": 21997,
"members": true,
"lowalch": 52,
"limit": 2000,
"value": 132,
"highalch": 79,
"icon": "Super antifire mix(1).png",
"name": "Super antifire mix(1)"
},
{
"examine": "Two doses of fishy super anti-firebreath potion.",
"id": 21994,
"members": true,
"lowalch": 79,
"limit": 2000,
"value": 198,
"highalch": 118,
"icon": "Super antifire mix(2).png",
"name": "Super antifire mix(2)"
},
{
"examine": "1 dose of super anti-firebreath potion.",
"id": 21987,
"members": true,
"lowalch": 272,
"limit": 2000,
"value": 680,
"highalch": 408,
"icon": "Super antifire potion(1).png",
"name": "Super antifire potion(1)"
},
{
"examine": "2 doses of super anti-firebreath potion.",
"id": 21984,
"members": true,
"lowalch": 288,
"limit": 2000,
"value": 720,
"highalch": 432,
"icon": "Super antifire potion(2).png",
"name": "Super antifire potion(2)"
},
{
"examine": "3 doses of super anti-firebreath potion.",
"id": 21981,
"members": true,
"lowalch": 304,
"limit": 2000,
"value": 760,
"highalch": 456,
"icon": "Super antifire potion(3).png",
"name": "Super antifire potion(3)"
},
{
"examine": "4 doses of super anti-firebreath potion.",
"id": 21978,
"members": true,
"lowalch": 320,
"limit": 2000,
"value": 800,
"highalch": 480,
"icon": "Super antifire potion(4).png",
"name": "Super antifire potion(4)"
},
{
"examine": "1 dose of super Attack potion.",
"id": 149,
"members": true,
"lowalch": 36,
"limit": 2000,
"value": 90,
"highalch": 54,
"icon": "Super attack(1).png",
"name": "Super attack(1)"
},
{
"examine": "2 doses of super Attack potion.",
"id": 147,
"members": true,
"lowalch": 54,
"limit": 2000,
"value": 135,
"highalch": 81,
"icon": "Super attack(2).png",
"name": "Super attack(2)"
},
{
"examine": "3 doses of super Attack potion.",
"id": 145,
"members": true,
"lowalch": 72,
"limit": 2000,
"value": 180,
"highalch": 108,
"icon": "Super attack(3).png",
"name": "Super attack(3)"
},
{
"examine": "4 doses of super Attack potion.",
"id": 2436,
"members": true,
"lowalch": 90,
"limit": 2000,
"value": 225,
"highalch": 135,
"icon": "Super attack(4).png",
"name": "Super attack(4)"
},
{
"examine": "1 dose of super combat potion.",
"id": 12701,
"members": true,
"lowalch": 16,
"limit": 2000,
"value": 40,
"highalch": 24,
"icon": "Super combat potion(1).png",
"name": "Super combat potion(1)"
},
{
"examine": "2 doses of super combat potion.",
"id": 12699,
"members": true,
"lowalch": 36,
"limit": 2000,
"value": 90,
"highalch": 54,
"icon": "Super combat potion(2).png",
"name": "Super combat potion(2)"
},
{
"examine": "3 doses of super combat potion.",
"id": 12697,
"members": true,
"lowalch": 68,
"limit": 2000,
"value": 170,
"highalch": 102,
"icon": "Super combat potion(3).png",
"name": "Super combat potion(3)"
},
{
"examine": "4 doses of super combat potion.",
"id": 12695,
"members": true,
"lowalch": 100,
"limit": 2000,
"value": 250,
"highalch": 150,
"icon": "Super combat potion(4).png",
"name": "Super combat potion(4)"
},
{
"examine": "One dose of fishy super Defence potion.",
"id": 11499,
"members": true,
"lowalch": 52,
"limit": 2000,
"value": 132,
"highalch": 79,
"icon": "Super def. mix(1).png",
"name": "Super def. mix(1)"
},
{
"examine": "Two doses of fishy super Defence potion.",
"id": 11497,
"members": true,
"lowalch": 79,
"limit": 2000,
"value": 198,
"highalch": 118,
"icon": "Super def. mix(2).png",
"name": "Super def. mix(2)"
},
{
"examine": "1 dose of super Defence potion.",
"id": 167,
"members": true,
"lowalch": 52,
"limit": 2000,
"value": 132,
"highalch": 79,
"icon": "Super defence(1).png",
"name": "Super defence(1)"
},
{
"examine": "2 doses of super Defence potion.",
"id": 165,
"members": true,
"lowalch": 79,
"limit": 2000,
"value": 198,
"highalch": 118,
"icon": "Super defence(2).png",
"name": "Super defence(2)"
},
{
"examine": "3 doses of super Defence potion.",
"id": 163,
"members": true,
"lowalch": 105,
"limit": 2000,
"value": 264,
"highalch": 158,
"icon": "Super defence(3).png",
"name": "Super defence(3)"
},
{
"examine": "4 doses of super Defence potion.",
"id": 2442,
"members": true,
"lowalch": 132,
"limit": 2000,
"value": 330,
"highalch": 198,
"icon": "Super defence(4).png",
"name": "Super defence(4)"
},
{
"examine": "One dose of fishy super energy potion.",
"id": 11483,
"members": true,
"lowalch": 36,
"limit": 2000,
"value": 90,
"highalch": 54,
"icon": "Super energy mix(1).png",
"name": "Super energy mix(1)"
},
{
"examine": "Two doses of fishy super energy potion.",
"id": 11481,
"members": true,
"lowalch": 64,
"limit": 2000,
"value": 160,
"highalch": 96,
"icon": "Super energy mix(2).png",
"name": "Super energy mix(2)"
},
{
"examine": "1 dose of super energy potion.",
"id": 3022,
"members": true,
"lowalch": 36,
"limit": 2000,
"value": 90,
"highalch": 54,
"icon": "Super energy(1).png",
"name": "Super energy(1)"
},
{
"examine": "2 doses of super energy potion.",
"id": 3020,
"members": true,
"lowalch": 64,
"limit": 2000,
"value": 160,
"highalch": 96,
"icon": "Super energy(2).png",
"name": "Super energy(2)"
},
{
"examine": "3 doses of super energy potion.",
"id": 3018,
"members": true,
"lowalch": 92,
"limit": 2000,
"value": 230,
"highalch": 138,
"icon": "Super energy(3).png",
"name": "Super energy(3)"
},
{
"examine": "4 doses of super energy potion.",
"id": 3016,
"members": true,
"lowalch": 120,
"limit": 2000,
"value": 300,
"highalch": 180,
"icon": "Super energy(4).png",
"name": "Super energy(4)"
},
{
"examine": "A meaty and very hot kebab.",
"id": 4608,
"members": true,
"lowalch": 1,
"limit": 15,
"value": 3,
"highalch": 1,
"icon": "Super kebab.png",
"name": "Super kebab"
},
{
"examine": "A set containing 4-dose vials of Super Attack, Super Strength and Super Defence potions.",
"id": 13066,
"members": true,
"lowalch": 40,
"limit": 2000,
"value": 100,
"highalch": 60,
"icon": "Super potion set.png",
"name": "Super potion set"
},
{
"examine": "One dose of fishy super restore potion.",
"id": 11495,
"members": true,
"lowalch": 48,
"limit": 2000,
"value": 120,
"highalch": 72,
"icon": "Super restore mix(1).png",
"name": "Super restore mix(1)"
},
{
"examine": "Two doses of fishy super restore potion.",
"id": 11493,
"members": true,
"lowalch": 72,
"limit": 2000,
"value": 180,
"highalch": 108,
"icon": "Super restore mix(2).png",
"name": "Super restore mix(2)"
},
{
"examine": "1 dose of super restore potion.",
"id": 3030,
"members": true,
"lowalch": 48,
"limit": 2000,
"value": 120,
"highalch": 72,
"icon": "Super restore(1).png",
"name": "Super restore(1)"
},
{
"examine": "2 doses of super restore potion.",
"id": 3028,
"members": true,
"lowalch": 72,
"limit": 2000,
"value": 180,
"highalch": 108,
"icon": "Super restore(2).png",
"name": "Super restore(2)"
},
{
"examine": "3 doses of super restore potion.",
"id": 3026,
"members": true,
"lowalch": 96,
"limit": 2000,
"value": 240,
"highalch": 144,
"icon": "Super restore(3).png",
"name": "Super restore(3)"
},
{
"examine": "4 doses of super restore potion.",
"id": 3024,
"members": true,
"lowalch": 120,
"limit": 2000,
"value": 300,
"highalch": 180,
"icon": "Super restore(4).png",
"name": "Super restore(4)"
},
{
"examine": "One dose of fishy super Strength potion.",
"id": 11487,
"members": true,
"lowalch": 44,
"limit": 2000,
"value": 110,
"highalch": 66,
"icon": "Super str. mix(1).png",
"name": "Super str. mix(1)"
},
{
"examine": "Two doses of fishy super Strength potion.",
"id": 11485,
"members": true,
"lowalch": 66,
"limit": 2000,
"value": 165,
"highalch": 99,
"icon": "Super str. mix(2).png",
"name": "Super str. mix(2)"
},
{
"examine": "1 dose of super Strength potion.",
"id": 161,
"members": true,
"lowalch": 44,
"limit": 2000,
"value": 110,
"highalch": 66,
"icon": "Super strength(1).png",
"name": "Super strength(1)"
},
{
"examine": "2 doses of super Strength potion.",
"id": 159,
"members": true,
"lowalch": 66,
"limit": 2000,
"value": 165,
"highalch": 99,
"icon": "Super strength(2).png",
"name": "Super strength(2)"
},
{
"examine": "3 doses of super Strength potion.",
"id": 157,
"members": true,
"lowalch": 88,
"limit": 2000,
"value": 220,
"highalch": 132,
"icon": "Super strength(3).png",
"name": "Super strength(3)"
},
{
"examine": "4 doses of super Strength potion.",
"id": 2440,
"members": true,
"lowalch": 110,
"limit": 2000,
"value": 275,
"highalch": 165,
"icon": "Super strength(4).png",
"name": "Super strength(4)"
},
{
"examine": "1 dose of super antipoison potion.",
"id": 185,
"members": true,
"lowalch": 57,
"limit": 2000,
"value": 144,
"highalch": 86,
"icon": "Superantipoison(1).png",
"name": "Superantipoison(1)"
},
{
"examine": "2 doses of super antipoison potion.",
"id": 183,
"members": true,
"lowalch": 86,
"limit": 2000,
"value": 216,
"highalch": 129,
"icon": "Superantipoison(2).png",
"name": "Superantipoison(2)"
},
{
"examine": "3 doses of super antipoison potion.",
"id": 181,
"members": true,
"lowalch": 115,
"limit": 2000,
"value": 288,
"highalch": 172,
"icon": "Superantipoison(3).png",
"name": "Superantipoison(3)"
},
{
"examine": "4 doses of super antipoison potion.",
"id": 2448,
"members": true,
"lowalch": 144,
"limit": 2000,
"value": 360,
"highalch": 216,
"icon": "Superantipoison(4).png",
"name": "Superantipoison(4)"
},
{
"examine": "One dose of fishy super Attack potion.",
"id": 11471,
"members": true,
"lowalch": 36,
"limit": 2000,
"value": 90,
"highalch": 54,
"icon": "Superattack mix(1).png",
"name": "Superattack mix(1)"
},
{
"examine": "Two doses of fishy super Attack potion.",
"id": 11469,
"members": true,
"lowalch": 54,
"limit": 2000,
"value": 135,
"highalch": 81,
"icon": "Superattack mix(2).png",
"name": "Superattack mix(2)"
},
{
"examine": "Super-good for the smallest or largest of plants.",
"id": 6034,
"members": true,
"lowalch": 34,
"limit": 2000,
"value": 85,
"highalch": 51,
"icon": "Supercompost.png",
"name": "Supercompost"
},
{
"examine": "There's something unnatural about these bones.",
"id": 22124,
"members": true,
"lowalch": 64,
"limit": 7500,
"value": 160,
"highalch": 96,
"icon": "Superior dragon bones.png",
"name": "Superior dragon bones"
},
{
"examine": "A very slimy and generally disgusting green lizard.",
"id": 10149,
"members": true,
"lowalch": 20,
"limit": 125,
"value": 50,
"highalch": 30,
"icon": "Swamp lizard.png",
"name": "Swamp lizard"
},
{
"examine": "A tar-like substance mixed with flour and warmed.",
"id": 1941,
"members": true,
"lowalch": 12,
"limit": 13000,
"value": 30,
"highalch": 18,
"icon": "Swamp paste.png",
"name": "Swamp paste"
},
{
"examine": "A foul smelling thick tar-like substance.",
"id": 1939,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Swamp tar.png",
"name": "Swamp tar"
},
{
"examine": "A slippery little blighter.",
"id": 2150,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Swamp toad (item).png",
"name": "Swamp toad (item)"
},
{
"examine": "Swamp weed found in the caves near Dorgesh-Kaan.",
"id": 10978,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Swamp weed.png",
"name": "Swamp weed"
},
{
"highalch": 1,
"members": true,
"name": "Swampbark body",
"examine": "Keeps my chest protected.",
"id": 25389,
"value": 1,
"icon": "Swampbark body.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Swampbark boots",
"examine": "Great foot protection.",
"id": 25395,
"value": 1,
"icon": "Swampbark boots.png",
"lowalch": 1
},
{
"highalch": 6000,
"members": true,
"name": "Swampbark gauntlets",
"examine": "These should protect my hands.",
"id": 25392,
"value": 10000,
"icon": "Swampbark gauntlets.png",
"lowalch": 4000
},
{
"highalch": 1,
"members": true,
"name": "Swampbark helm",
"examine": "A rather interesting smelling helmet.",
"id": 25398,
"value": 1,
"icon": "Swampbark helm.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Swampbark legs",
"examine": "Strong leg protection.",
"id": 25401,
"value": 1,
"icon": "Swampbark legs.png",
"lowalch": 1
},
{
"examine": "Raw sweetcorn.",
"id": 5986,
"members": true,
"lowalch": 3,
"limit": 11000,
"value": 9,
"highalch": 5,
"icon": "Sweetcorn.png",
"name": "Sweetcorn"
},
{
"examine": "A bowl of cooked sweetcorn.",
"id": 7088,
"members": true,
"lowalch": 5,
"limit": 13000,
"value": 13,
"highalch": 7,
"icon": "Sweetcorn (bowl).png",
"name": "Sweetcorn (bowl)"
},
{
"examine": "A sweetcorn seed - plant in an allotment.",
"id": 5320,
"members": true,
"lowalch": 3,
"limit": 600,
"value": 8,
"highalch": 4,
"icon": "Sweetcorn seed 5.png",
"name": "Sweetcorn seed"
},
{
"highalch": 1,
"members": false,
"name": "Swift blade",
"examine": "A dull blade that moves at incredible speed.",
"id": 24219,
"value": 1,
"icon": "Swift blade.png",
"lowalch": 1
},
{
"examine": "I'd better be careful eating this!",
"id": 373,
"members": false,
"lowalch": 32,
"limit": 6000,
"value": 80,
"highalch": 48,
"icon": "Swordfish.png",
"name": "Swordfish"
},
{
"examine": "Teleports you to Tai bwo wannai.",
"id": 12409,
"members": true,
"lowalch": 4,
"limit": 10000,
"value": 10,
"highalch": 6,
"icon": "Tai bwo wannai teleport.png",
"name": "Tai bwo wannai teleport"
},
{
"examine": "You can plant this in your garden.",
"id": 8449,
"members": true,
"lowalch": 40000,
"limit": 10000,
"value": 100000,
"highalch": 60000,
"icon": "Tall box hedge (bagged).png",
"name": "Tall box hedge (bagged)"
},
{
"examine": "You can plant this in your garden.",
"id": 8447,
"members": true,
"lowalch": 20000,
"limit": 10000,
"value": 50000,
"highalch": 30000,
"icon": "Tall fancy hedge (bagged).png",
"name": "Tall fancy hedge (bagged)"
},
{
"examine": "All for one and one for all!",
"id": 2639,
"members": true,
"lowalch": 80,
"limit": 4,
"value": 200,
"highalch": 120,
"icon": "Tan cavalier.png",
"name": "Tan cavalier"
},
{
"examine": "It actually smells quite good.",
"id": 2187,
"members": true,
"lowalch": 1,
"limit": 6000,
"value": 2,
"highalch": 1,
"icon": "Tangled toad's legs.png",
"name": "Tangled toad's legs"
},
{
"examine": "Maybe you could use a chisel to fletch this into a blowpipe.",
"id": 12922,
"members": true,
"lowalch": 44000,
"limit": 5,
"value": 110000,
"highalch": 66000,
"icon": "Tanzanite fang.png",
"name": "Tanzanite fang"
},
{
"highalch": 1,
"members": true,
"name": "Target teleport",
"examine": "A teleport to your Bounty Hunter target.",
"id": 24336,
"value": 1,
"icon": "Target teleport.png",
"lowalch": 1
},
{
"examine": "Unlocks the level 85 Target Teleport spell.",
"id": 12846,
"members": true,
"lowalch": 12000,
"limit": 5,
"value": 30000,
"highalch": 18000,
"icon": "Target teleport scroll.png",
"name": "Target teleport scroll"
},
{
"examine": "A useful herb.",
"id": 253,
"members": true,
"lowalch": 4,
"limit": 13000,
"value": 11,
"highalch": 6,
"icon": "Tarromin.png",
"name": "Tarromin"
},
{
"examine": "I need another ingredient to finish this Tarromin potion.",
"id": 95,
"members": true,
"lowalch": 4,
"limit": 10000,
"value": 11,
"highalch": 6,
"icon": "Tarromin potion (unf).png",
"name": "Tarromin potion (unf)"
},
{
"examine": "A tarromin seed - plant in a herb patch.",
"id": 5293,
"members": true,
"lowalch": 1,
"limit": 600,
"value": 3,
"highalch": 1,
"icon": "Tarromin seed 5.png",
"name": "Tarromin seed"
},
{
"examine": "A thick, foul-smelling, tar-like substance with a red tinge.",
"id": 10144,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 1,
"highalch": 1,
"icon": "Tarromin tar.png",
"name": "Tarromin tar"
},
{
"examine": "It's a shabby-looking graahk fur.",
"id": 10097,
"members": true,
"lowalch": 36,
"limit": 10000,
"value": 90,
"highalch": 54,
"icon": "Tatty graahk fur.png",
"name": "Tatty graahk fur"
},
{
"examine": "It's a shabby-looking kyatt fur.",
"id": 10101,
"members": true,
"lowalch": 48,
"limit": 10000,
"value": 120,
"highalch": 72,
"icon": "Tatty kyatt fur.png",
"name": "Tatty kyatt fur"
},
{
"examine": "It's a shabby-looking Larupia fur.",
"id": 10093,
"members": true,
"lowalch": 24,
"limit": 10000,
"value": 60,
"highalch": 36,
"icon": "Tatty larupia fur.png",
"name": "Tatty larupia fur"
},
{
"examine": "A red salt, that when burned with other salts provides various benefits.",
"id": 22593,
"members": true,
"lowalch": 4,
"limit": 10000,
"value": 10,
"highalch": 6,
"icon": "Te salt.png",
"name": "Te salt"
},
{
"examine": "A teacher level wand.",
"id": 6912,
"members": true,
"lowalch": 800,
"limit": 70,
"value": 2000,
"highalch": 1200,
"icon": "Teacher wand.png",
"name": "Teacher wand"
},
{
"examine": "How does it all fit in there?",
"id": 8506,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Teak armchair (flatpack).png",
"name": "Teak armchair (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 9860,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Teak armour case (flatpack).png",
"name": "Teak armour case (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8582,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Teak bed (flatpack).png",
"name": "Teak bed (flatpack)"
},
{
"examine": "Feed and catch the birds by placing this in the right spot.",
"id": 21521,
"members": true,
"lowalch": 2,
"limit": 250,
"value": 5,
"highalch": 3,
"icon": "Teak bird house.png",
"name": "Teak bird house"
},
{
"examine": "How does it all fit in there?",
"id": 9844,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Teak cape rack (flatpack).png",
"name": "Teak cape rack (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8592,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Teak clock (flatpack).png",
"name": "Teak clock (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8568,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Teak dining bench (flatpack).png",
"name": "Teak dining bench (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8616,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Teak drawers (flatpack).png",
"name": "Teak drawers (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8602,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Teak dresser (flatpack).png",
"name": "Teak dresser (flatpack)"
},
{
"examine": "Teak fancy dress box.",
"id": 9866,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Teak fancy dress box (flatpack).png",
"name": "Teak fancy dress box (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8532,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Teak kitchen table (flatpack).png",
"name": "Teak kitchen table (flatpack)"
},
{
"examine": "Some well-cut teak logs.",
"id": 6333,
"members": true,
"lowalch": 12,
"limit": 13000,
"value": 30,
"highalch": 18,
"icon": "Teak logs.png",
"name": "Teak logs"
},
{
"examine": "Teak magic wardrobe.",
"id": 9854,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Teak magic wardrobe (flatpack).png",
"name": "Teak magic wardrobe (flatpack)"
},
{
"examine": "A plank of fine teak.",
"id": 8780,
"members": true,
"lowalch": 200,
"limit": 13000,
"value": 500,
"highalch": 300,
"icon": "Teak plank.png",
"name": "Teak plank"
},
{
"examine": "Teak logs prepared with sacred oil for a funeral pyre.",
"id": 6211,
"members": true,
"lowalch": 40,
"limit": 11000,
"value": 100,
"highalch": 60,
"icon": "Teak pyre logs.png",
"name": "Teak pyre logs"
},
{
"examine": "This sapling is ready to be replanted in a hardwood tree patch.",
"id": 21477,
"members": true,
"lowalch": 1,
"limit": 200,
"value": 1,
"highalch": 1,
"icon": "Teak sapling.png",
"name": "Teak sapling"
},
{
"examine": "Plant this in a plantpot of soil to grow a sapling.",
"id": 21486,
"members": true,
"lowalch": 19,
"limit": 200,
"value": 48,
"highalch": 28,
"icon": "Teak seed 5.png",
"name": "Teak seed"
},
{
"examine": "A teak crossbow stock.",
"id": 9446,
"members": true,
"lowalch": 30,
"limit": 10000,
"value": 77,
"highalch": 46,
"icon": "Teak stock.png",
"name": "Teak stock"
},
{
"examine": "How does it all fit in there?",
"id": 8554,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Teak table (flatpack).png",
"name": "Teak table (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 9850,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Teak toy box (flatpack).png",
"name": "Teak toy box (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 9863,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Teak treasure chest (flatpack).png",
"name": "Teak treasure chest (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8618,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Teak wardrobe (flatpack).png",
"name": "Teak wardrobe (flatpack)"
},
{
"examine": "Very stylish!",
"id": 2924,
"members": false,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Teal boots.png",
"name": "Teal boots"
},
{
"examine": "These will keep my hands warm!",
"id": 2932,
"members": false,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Teal gloves.png",
"name": "Teal gloves"
},
{
"examine": "A silly pointed hat.",
"id": 2930,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Teal hat.png",
"name": "Teal hat"
},
{
"examine": "Made by werewolves for werewolves.",
"id": 2928,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Teal robe bottoms.png",
"name": "Teal robe bottoms"
},
{
"examine": "Some fine werewolf clothing.",
"id": 2926,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Teal robe top.png",
"name": "Teal robe top"
},
{
"examine": "Ooohhh look at the dirty colours...",
"id": 20217,
"members": false,
"lowalch": 20,
"limit": 4,
"value": 50,
"highalch": 30,
"icon": "Team cape i.png",
"name": "Team cape i"
},
{
"examine": "Ooohhh look at the dirty colours...",
"id": 20214,
"members": false,
"lowalch": 20,
"limit": 4,
"value": 50,
"highalch": 30,
"icon": "Team cape x.png",
"name": "Team cape x"
},
{
"examine": "Ooohhh look at the dirty colours...",
"id": 20211,
"members": false,
"lowalch": 20,
"limit": 4,
"value": 50,
"highalch": 30,
"icon": "Team cape zero.png",
"name": "Team cape zero"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4315,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-1 cape.png",
"name": "Team-1 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4333,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-10 cape.png",
"name": "Team-10 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4335,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-11 cape.png",
"name": "Team-11 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4337,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-12 cape.png",
"name": "Team-12 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4339,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-13 cape.png",
"name": "Team-13 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4341,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-14 cape.png",
"name": "Team-14 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4343,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-15 cape.png",
"name": "Team-15 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4345,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-16 cape.png",
"name": "Team-16 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4347,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-17 cape.png",
"name": "Team-17 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4349,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-18 cape.png",
"name": "Team-18 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4351,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-19 cape.png",
"name": "Team-19 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4317,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-2 cape.png",
"name": "Team-2 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4353,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-20 cape.png",
"name": "Team-20 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4355,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-21 cape.png",
"name": "Team-21 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4357,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-22 cape.png",
"name": "Team-22 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4359,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-23 cape.png",
"name": "Team-23 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4361,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-24 cape.png",
"name": "Team-24 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4363,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-25 cape.png",
"name": "Team-25 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4365,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-26 cape.png",
"name": "Team-26 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4367,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-27 cape.png",
"name": "Team-27 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4369,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-28 cape.png",
"name": "Team-28 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4371,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-29 cape.png",
"name": "Team-29 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4319,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-3 cape.png",
"name": "Team-3 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4373,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-30 cape.png",
"name": "Team-30 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4375,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-31 cape.png",
"name": "Team-31 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4377,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-32 cape.png",
"name": "Team-32 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4379,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-33 cape.png",
"name": "Team-33 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4381,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-34 cape.png",
"name": "Team-34 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4383,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-35 cape.png",
"name": "Team-35 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4385,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-36 cape.png",
"name": "Team-36 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4387,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-37 cape.png",
"name": "Team-37 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4389,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-38 cape.png",
"name": "Team-38 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4391,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-39 cape.png",
"name": "Team-39 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4321,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-4 cape.png",
"name": "Team-4 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4393,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-40 cape.png",
"name": "Team-40 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4395,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-41 cape.png",
"name": "Team-41 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4397,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-42 cape.png",
"name": "Team-42 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4399,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-43 cape.png",
"name": "Team-43 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4401,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-44 cape.png",
"name": "Team-44 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4403,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-45 cape.png",
"name": "Team-45 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4405,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-46 cape.png",
"name": "Team-46 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4407,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-47 cape.png",
"name": "Team-47 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4409,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-48 cape.png",
"name": "Team-48 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4411,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-49 cape.png",
"name": "Team-49 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4323,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-5 cape.png",
"name": "Team-5 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4413,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-50 cape.png",
"name": "Team-50 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4325,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-6 cape.png",
"name": "Team-6 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4327,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-7 cape.png",
"name": "Team-7 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4329,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-8 cape.png",
"name": "Team-8 cape"
},
{
"examine": "Ooohhh look at the pretty colours...",
"id": 4331,
"members": false,
"lowalch": 20,
"limit": 150,
"value": 50,
"highalch": 30,
"icon": "Team-9 cape.png",
"name": "Team-9 cape"
},
{
"examine": "Reduces the risk of hand severage when poking large, vicious carnivores.",
"id": 10029,
"members": true,
"lowalch": 20,
"limit": 40,
"value": 50,
"highalch": 30,
"icon": "Teasing stick.png",
"name": "Teasing stick"
},
{
"highalch": 120,
"members": true,
"name": "Tecu salamander",
"examine": "I thought this would be slimier.",
"id": 28834,
"value": 200,
"icon": "Tecu salamander.png",
"lowalch": 80
},
{
"highalch": 1,
"members": true,
"name": "Teleport anchoring scroll",
"examine": "A formidable magic spell which prevents unwanted teleportation.",
"id": 29455,
"value": 1,
"icon": "Teleport anchoring scroll.png",
"lowalch": 1
},
{
"members": false,
"name": "Teleport card",
"examine": "A card which has magical properties.",
"id": 13658,
"value": 100,
"icon": "Teleport card.png",
"limit": 500
},
{
"examine": "A teleport to one's own house.",
"id": 8013,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Teleport to house (tablet).png",
"name": "Teleport to house (tablet)"
},
{
"examine": "A mighty sceptre used in long forgotten battles.",
"id": 27785,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 1,
"highalch": 1,
"icon": "Thammaron's sceptre (au).png",
"name": "Thammaron's sceptre (au)"
},
{
"examine": "A mighty sceptre used in long forgotten battles.",
"id": 22552,
"members": true,
"lowalch": 48000,
"limit": 8,
"value": 120000,
"highalch": 72000,
"icon": "Thammaron's sceptre (u).png",
"name": "Thammaron's sceptre (u)"
},
{
"examine": "A wooden pole for use in primitive construction.",
"id": 6285,
"members": true,
"lowalch": 8,
"limit": 100,
"value": 20,
"highalch": 12,
"icon": "Thatch spar dense.png",
"name": "Thatch spar dense"
},
{
"examine": "A wooden pole for use in primitive construction.",
"id": 6281,
"members": true,
"lowalch": 4,
"limit": 100,
"value": 10,
"highalch": 6,
"icon": "Thatch spar light.png",
"name": "Thatch spar light"
},
{
"examine": "A wooden pole for use in primitive construction.",
"id": 6283,
"members": true,
"lowalch": 6,
"limit": 100,
"value": 15,
"highalch": 9,
"icon": "Thatch spar med.png",
"name": "Thatch spar med"
},
{
"examine": "A good place to store your stolen goods.",
"id": 23224,
"members": true,
"lowalch": 400,
"limit": 8,
"value": 1000,
"highalch": 600,
"icon": "Thieving bag.png",
"name": "Thieving bag"
},
{
"examine": "The thin, slimy corpse of a deceased giant snail.",
"id": 3363,
"members": true,
"lowalch": 2,
"limit": 13000,
"value": 5,
"highalch": 3,
"icon": "Thin snail.png",
"name": "Thin snail"
},
{
"examine": "A succulently slimy slice of sumptuous snail.",
"id": 3369,
"members": true,
"lowalch": 4,
"limit": 6000,
"value": 10,
"highalch": 6,
"icon": "Thin snail meat.png",
"name": "Thin snail meat"
},
{
"examine": "You can plant this in your garden.",
"id": 8437,
"members": true,
"lowalch": 2000,
"limit": 10000,
"value": 5000,
"highalch": 3000,
"icon": "Thorny hedge (bagged).png",
"name": "Thorny hedge (bagged)"
},
{
"examine": "Used with a needle to make clothes.",
"id": 1734,
"members": false,
"lowalch": 1,
"limit": 18000,
"value": 1,
"highalch": 1,
"icon": "Thread.png",
"name": "Thread"
},
{
"examine": "Makes me feel like a Princess.",
"id": 5525,
"members": false,
"lowalch": 40,
"limit": 10000,
"value": 100,
"highalch": 60,
"icon": "Tiara.png",
"name": "Tiara"
},
{
"examine": "A mould for tiaras.",
"id": 5523,
"members": false,
"lowalch": 40,
"limit": 40,
"value": 100,
"highalch": 60,
"icon": "Tiara mould.png",
"name": "Tiara mould"
},
{
"examine": "A hefty beam of timber, perfect for building temples.",
"id": 8837,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Timber beam.png",
"name": "Timber beam"
},
{
"examine": "This needs refining.",
"id": 438,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 3,
"highalch": 1,
"icon": "Tin ore.png",
"name": "Tin ore"
},
{
"examine": "Useful for lighting a fire.",
"id": 590,
"members": false,
"lowalch": 1,
"limit": 40,
"value": 1,
"highalch": 1,
"icon": "Tinderbox.png",
"name": "Tinderbox"
},
{
"examine": "It actually smells quite good.",
"id": 2255,
"members": true,
"lowalch": 1,
"limit": 6000,
"value": 2,
"highalch": 1,
"icon": "Toad batta.png",
"name": "Toad batta"
},
{
"examine": "It actually smells quite good.",
"id": 2217,
"members": true,
"lowalch": 1,
"limit": 6000,
"value": 2,
"highalch": 1,
"icon": "Toad crunchies.png",
"name": "Toad crunchies"
},
{
"examine": "They're a gnome delicacy apparently.",
"id": 2152,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Toad's legs.png",
"name": "Toad's legs"
},
{
"examine": "A useful herb.",
"id": 2998,
"members": true,
"lowalch": 19,
"limit": 13000,
"value": 48,
"highalch": 28,
"icon": "Toadflax.png",
"name": "Toadflax"
},
{
"examine": "I need another ingredient to finish this Toadflax potion.",
"id": 3002,
"members": true,
"lowalch": 19,
"limit": 10000,
"value": 48,
"highalch": 28,
"icon": "Toadflax potion (unf).png",
"name": "Toadflax potion (unf)"
},
{
"examine": "A toadflax seed - plant in a herb patch.",
"id": 5296,
"members": true,
"lowalch": 4,
"limit": 600,
"value": 10,
"highalch": 6,
"icon": "Toadflax seed 5.png",
"name": "Toadflax seed"
},
{
"examine": "A spiked shield of obsidian.",
"id": 6524,
"members": true,
"lowalch": 18000,
"limit": 70,
"value": 45000,
"highalch": 27000,
"icon": "Toktz-ket-xil.png",
"name": "Toktz-ket-xil"
},
{
"examine": "A staff of obsidian.",
"id": 6526,
"members": true,
"lowalch": 14000,
"limit": 70,
"value": 35000,
"highalch": 21000,
"icon": "Toktz-mej-tal.png",
"name": "Toktz-mej-tal"
},
{
"examine": "A razor sharp sword of obsidian.",
"id": 6523,
"members": true,
"lowalch": 16000,
"limit": 70,
"value": 40000,
"highalch": 24000,
"icon": "Toktz-xil-ak.png",
"name": "Toktz-xil-ak"
},
{
"examine": "A large knife of obsidian.",
"id": 6525,
"members": true,
"lowalch": 10000,
"limit": 70,
"value": 25000,
"highalch": 15000,
"icon": "Toktz-xil-ek.png",
"name": "Toktz-xil-ek"
},
{
"examine": "A razor sharp ring of obsidian.",
"id": 6522,
"members": true,
"lowalch": 100,
"limit": 11000,
"value": 250,
"highalch": 150,
"icon": "Toktz-xil-ul.png",
"name": "Toktz-xil-ul"
},
{
"examine": "This would make good ketchup.",
"id": 1982,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 4,
"highalch": 2,
"icon": "Tomato.png",
"name": "Tomato"
},
{
"examine": "A tomato seed - plant in an allotment.",
"id": 5322,
"members": true,
"lowalch": 1,
"limit": 600,
"value": 4,
"highalch": 2,
"icon": "Tomato seed 5.png",
"name": "Tomato seed"
},
{
"examine": "A fruit basket filled with tomatoes.",
"id": 5968,
"members": true,
"lowalch": 1,
"limit": 600,
"value": 1,
"highalch": 1,
"icon": "Tomatoes(5).png",
"name": "Tomatoes(5)"
},
{
"examine": "An ancient tome containing the secrets of fire magic.",
"id": 20716,
"members": true,
"lowalch": 8000,
"limit": 15,
"value": 20000,
"highalch": 12000,
"icon": "Tome of fire (empty).png",
"name": "Tome of fire (empty)"
},
{
"highalch": 11700,
"members": true,
"name": "Tome of water (empty)",
"examine": "An ancient tome containing the secrets of water magic.",
"id": 25576,
"value": 19500,
"icon": "Tome of water (empty).png",
"lowalch": 7800
},
{
"highalch": 90000,
"members": true,
"name": "Tonalztics of ralos (uncharged)",
"examine": "A rebounding throwing weapon of devastating proportions.",
"id": 28919,
"value": 150000,
"icon": "Tonalztics of ralos (uncharged).png",
"lowalch": 60000
},
{
"examine": "The tooth end of the mysterious Crystal Key. Can you find the other half?",
"id": 985,
"members": true,
"lowalch": 40,
"limit": 11000,
"value": 100,
"highalch": 60,
"icon": "Tooth half of key.png",
"name": "Tooth half of key"
},
{
"examine": "Aint no rabbits in this hat.",
"id": 12432,
"members": true,
"lowalch": 720,
"limit": 4,
"value": 1800,
"highalch": 1080,
"icon": "Top hat.png",
"name": "Top hat"
},
{
"examine": "Like a sir.",
"id": 12434,
"members": true,
"lowalch": 1600,
"limit": 4,
"value": 4000,
"highalch": 2400,
"icon": "Top hat & monocle.png",
"name": "Top hat & monocle"
},
{
"examine": "I wonder if I can get this enchanted.",
"id": 21114,
"members": true,
"lowalch": 500,
"limit": 10000,
"value": 1250,
"highalch": 750,
"icon": "Topaz amulet.png",
"name": "Topaz amulet"
},
{
"examine": "It needs a string so I can wear it.",
"id": 21105,
"members": true,
"lowalch": 510,
"limit": 10000,
"value": 1275,
"highalch": 765,
"icon": "Topaz amulet (u).png",
"name": "Topaz amulet (u)"
},
{
"examine": "Red Topaz bolt tips.",
"id": 9188,
"members": true,
"lowalch": 5,
"limit": 11000,
"value": 13,
"highalch": 7,
"icon": "Topaz bolt tips 5.png",
"name": "Topaz bolt tips"
},
{
"examine": "Topaz tipped Steel crossbow bolts.",
"id": 9336,
"members": true,
"lowalch": 8,
"limit": 11000,
"value": 22,
"highalch": 13,
"icon": "Topaz bolts 5.png",
"name": "Topaz bolts"
},
{
"examine": "Enchanted Red Topaz tipped Steel Crossbow Bolts.",
"id": 9239,
"members": true,
"lowalch": 9,
"limit": 11000,
"value": 24,
"highalch": 14,
"icon": "Topaz bolts (e) 5.png",
"name": "Topaz bolts (e)"
},
{
"examine": "I wonder if this is valuable.",
"id": 21123,
"members": true,
"lowalch": 600,
"limit": 10000,
"value": 1500,
"highalch": 900,
"icon": "Topaz bracelet.png",
"name": "Topaz bracelet"
},
{
"examine": "Dragon crossbow bolts, tipped with red topaz.",
"id": 21961,
"members": true,
"lowalch": 280,
"limit": 11000,
"value": 700,
"highalch": 420,
"icon": "Topaz dragon bolts 5.png",
"name": "Topaz dragon bolts"
},
{
"examine": "Enchanted Dragon crossbow bolts, tipped with red topaz.",
"id": 21938,
"members": true,
"lowalch": 292,
"limit": 11000,
"value": 730,
"highalch": 438,
"icon": "Topaz dragon bolts (e) 5.png",
"name": "Topaz dragon bolts (e)"
},
{
"examine": "I wonder if this is valuable.",
"id": 21096,
"members": true,
"lowalch": 570,
"limit": 10000,
"value": 1425,
"highalch": 855,
"icon": "Topaz necklace.png",
"name": "Topaz necklace"
},
{
"examine": "A valuable ring.",
"id": 21087,
"members": true,
"lowalch": 570,
"limit": 10000,
"value": 1425,
"highalch": 855,
"icon": "Topaz ring.png",
"name": "Topaz ring"
},
{
"examine": "You can plant this in your garden.",
"id": 8443,
"members": true,
"lowalch": 8000,
"limit": 10000,
"value": 20000,
"highalch": 12000,
"icon": "Topiary hedge (bagged).png",
"name": "Topiary hedge (bagged)"
},
{
"examine": "A set containing a Torag's helm, platelegs, platebody and hammers.",
"id": 12879,
"members": true,
"lowalch": 160000,
"limit": 8,
"value": 400000,
"highalch": 240000,
"icon": "Torag's armour set.png",
"name": "Torag's armour set"
},
{
"examine": "Torag the Corrupted's twin hammers.",
"id": 4747,
"members": true,
"lowalch": 64000,
"limit": 15,
"value": 160000,
"highalch": 96000,
"icon": "Torag's hammers.png",
"name": "Torag's hammers"
},
{
"examine": "Torag the Corrupted's twin hammers.",
"id": 4962,
"members": true,
"lowalch": 64000,
"limit": 15,
"value": 160000,
"highalch": 96000,
"icon": "Torag's hammers 0.png",
"name": "Torag's hammers 0"
},
{
"examine": "Torag the Corrupted's helm.",
"id": 4745,
"members": true,
"lowalch": 41200,
"limit": 15,
"value": 103000,
"highalch": 61800,
"icon": "Torag's helm.png",
"name": "Torag's helm"
},
{
"examine": "Torag the Corrupted's helm.",
"id": 4956,
"members": true,
"lowalch": 41200,
"limit": 15,
"value": 103000,
"highalch": 61800,
"icon": "Torag's helm 0.png",
"name": "Torag's helm 0"
},
{
"examine": "Torag the Corrupted's platebody armour.",
"id": 4749,
"members": true,
"lowalch": 112000,
"limit": 15,
"value": 280000,
"highalch": 168000,
"icon": "Torag's platebody.png",
"name": "Torag's platebody"
},
{
"examine": "Torag the Corrupted's platebody armour.",
"id": 4968,
"members": true,
"lowalch": 112000,
"limit": 15,
"value": 280000,
"highalch": 168000,
"icon": "Torag's platebody 0.png",
"name": "Torag's platebody 0"
},
{
"examine": "Torag the Corrupted's plate leg armour.",
"id": 4751,
"members": true,
"lowalch": 110000,
"limit": 15,
"value": 275000,
"highalch": 165000,
"icon": "Torag's platelegs.png",
"name": "Torag's platelegs"
},
{
"examine": "Torag the Corrupted's plate leg armour.",
"id": 4974,
"members": true,
"lowalch": 110000,
"limit": 15,
"value": 275000,
"highalch": 165000,
"icon": "Torag's platelegs 0.png",
"name": "Torag's platelegs 0"
},
{
"examine": "A deep sense of torment burns within this powerful bracelet.",
"id": 19544,
"members": true,
"lowalch": 80400,
"limit": 8,
"value": 201000,
"highalch": 120600,
"icon": "Tormented bracelet.png",
"name": "Tormented bracelet"
},
{
"examine": "Use on a tormented bracelet to make it look fancier!",
"id": 23348,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Tormented ornament kit.png",
"name": "Tormented ornament kit"
},
{
"examine": "Scrawled words that can invoke the power of the gods.",
"id": 21047,
"members": true,
"lowalch": 32000,
"limit": 5,
"value": 80000,
"highalch": 48000,
"icon": "Torn prayer scroll.png",
"name": "Torn prayer scroll"
},
{
"examine": "A powerful herb.",
"id": 269,
"members": true,
"lowalch": 30,
"limit": 11000,
"value": 75,
"highalch": 45,
"icon": "Torstol.png",
"name": "Torstol"
},
{
"examine": "I need another ingredient to finish this Torstol potion.",
"id": 111,
"members": true,
"lowalch": 10,
"limit": 10000,
"value": 25,
"highalch": 15,
"icon": "Torstol potion (unf).png",
"name": "Torstol potion (unf)"
},
{
"examine": "A torstol seed - plant in a herb patch.",
"id": 5304,
"members": true,
"lowalch": 28,
"limit": 200,
"value": 70,
"highalch": 42,
"icon": "Torstol seed 5.png",
"name": "Torstol seed"
},
{
"examine": "A word in your shell-like.",
"id": 7939,
"members": true,
"lowalch": 400,
"limit": 7500,
"value": 1000,
"highalch": 600,
"icon": "Tortoise shell.png",
"name": "Tortoise shell"
},
{
"examine": "Use on an amulet of torture to make it look fancier!",
"id": 20062,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Torture ornament kit.png",
"name": "Torture ornament kit"
},
{
"examine": "An ancient warrior's full helm.",
"id": 26382,
"members": true,
"lowalch": 80000,
"limit": 8,
"value": 200000,
"highalch": 120000,
"icon": "Torva full helm.png",
"name": "Torva full helm"
},
{
"examine": "An ancient warrior's full helm.",
"id": 26376,
"members": true,
"lowalch": 80000,
"limit": 8,
"value": 200000,
"highalch": 120000,
"icon": "Torva full helm (damaged).png",
"name": "Torva full helm (damaged)"
},
{
"examine": "An ancient warrior's platebody.",
"id": 26384,
"members": true,
"lowalch": 240000,
"limit": 8,
"value": 600000,
"highalch": 360000,
"icon": "Torva platebody.png",
"name": "Torva platebody"
},
{
"examine": "An ancient warrior's platebody.",
"id": 26378,
"members": true,
"lowalch": 240000,
"limit": 8,
"value": 600000,
"highalch": 360000,
"icon": "Torva platebody (damaged).png",
"name": "Torva platebody (damaged)"
},
{
"examine": "An ancient warrior's platelegs.",
"id": 26386,
"members": true,
"lowalch": 160000,
"limit": 8,
"value": 400000,
"highalch": 240000,
"icon": "Torva platelegs.png",
"name": "Torva platelegs"
},
{
"examine": "An ancient warrior's platelegs.",
"id": 26380,
"members": true,
"lowalch": 160000,
"limit": 8,
"value": 400000,
"highalch": 240000,
"icon": "Torva platelegs (damaged).png",
"name": "Torva platelegs (damaged)"
},
{
"examine": "Fires darts while coating them with venom.",
"id": 12924,
"members": true,
"lowalch": 48000,
"limit": 8,
"value": 120000,
"highalch": 72000,
"icon": "Toxic blowpipe (empty).png",
"name": "Toxic blowpipe (empty)"
},
{
"examine": "It needs to be charged with Zulrah's scales.",
"id": 12902,
"members": true,
"lowalch": 400002,
"limit": 8,
"value": 1000006,
"highalch": 600003,
"icon": "Toxic staff (uncharged).png",
"name": "Toxic staff (uncharged)"
},
{
"examine": "Nice bit of crafting!",
"id": 7771,
"members": true,
"lowalch": 4,
"limit": 150,
"value": 10,
"highalch": 6,
"icon": "Toy cat.png",
"name": "Toy cat"
},
{
"examine": "Nice bit of crafting!",
"id": 7763,
"members": true,
"lowalch": 4,
"limit": 150,
"value": 10,
"highalch": 6,
"icon": "Toy doll.png",
"name": "Toy doll"
},
{
"examine": "Nice bit of crafting!",
"id": 7765,
"members": true,
"lowalch": 4,
"limit": 150,
"value": 10,
"highalch": 6,
"icon": "Toy doll.png",
"name": "Toy doll (wound)"
},
{
"examine": "Nice bit of crafting!",
"id": 7767,
"members": true,
"lowalch": 4,
"limit": 150,
"value": 10,
"highalch": 6,
"icon": "Toy mouse.png",
"name": "Toy mouse"
},
{
"examine": "Nice bit of crafting!",
"id": 7769,
"members": true,
"lowalch": 4,
"limit": 150,
"value": 10,
"highalch": 6,
"icon": "Toy mouse.png",
"name": "Toy mouse (wound)"
},
{
"examine": "Nice bit of crafting!",
"id": 7759,
"members": true,
"lowalch": 4,
"limit": 150,
"value": 10,
"highalch": 6,
"icon": "Toy soldier.png",
"name": "Toy soldier"
},
{
"examine": "Nice bit of crafting!",
"id": 7761,
"members": true,
"lowalch": 4,
"limit": 150,
"value": 10,
"highalch": 6,
"icon": "Toy soldier.png",
"name": "Toy soldier (wound)"
},
{
"examine": "Karamja currency.",
"id": 6306,
"members": true,
"lowalch": 1,
"limit": 18000,
"value": 1,
"highalch": 1,
"icon": "Trading sticks 10000.png",
"name": "Trading sticks"
},
{
"examine": "A banner showcasing the colours of Leagues II - Trailblazer.",
"id": 25056,
"members": true,
"lowalch": 400,
"limit": 5,
"value": 1000,
"highalch": 600,
"icon": "Trailblazer banner.png",
"name": "Trailblazer banner"
},
{
"examine": "The boots of a trailblazer relic hunter.",
"id": 25037,
"members": true,
"lowalch": 6000,
"limit": 5,
"value": 15000,
"highalch": 9000,
"icon": "Trailblazer boots (t1).png",
"name": "Trailblazer boots (t1)"
},
{
"examine": "The boots of a trailblazer relic hunter.",
"id": 25025,
"members": true,
"lowalch": 6000,
"limit": 5,
"value": 15000,
"highalch": 9000,
"icon": "Trailblazer boots (t2).png",
"name": "Trailblazer boots (t2)"
},
{
"examine": "The boots of a trailblazer relic hunter.",
"id": 25010,
"members": true,
"lowalch": 60000,
"limit": 5,
"value": 150000,
"highalch": 90000,
"icon": "Trailblazer boots (t3).png",
"name": "Trailblazer boots (t3)"
},
{
"examine": "A cane of the trailblazer relic hunter.",
"id": 25013,
"members": true,
"lowalch": 60000,
"limit": 5,
"value": 150000,
"highalch": 90000,
"icon": "Trailblazer cane.png",
"name": "Trailblazer cane"
},
{
"examine": "A globe which can be built into a statue in a PoH hotspot in your League Trophy Room.",
"id": 25093,
"members": true,
"lowalch": 16000,
"limit": 5,
"value": 40000,
"highalch": 24000,
"icon": "Trailblazer globe.png",
"name": "Trailblazer globe"
},
{
"examine": "A dye which can be used to recolour a piece of graceful clothing.",
"id": 25099,
"members": true,
"lowalch": 10000,
"limit": 6,
"value": 25000,
"highalch": 15000,
"icon": "Trailblazer graceful ornament kit.png",
"name": "Trailblazer graceful ornament kit"
},
{
"examine": "The headgear of a trailblazer relic hunter.",
"id": 25028,
"members": true,
"lowalch": 6000,
"limit": 5,
"value": 15000,
"highalch": 9000,
"icon": "Trailblazer hood (t1).png",
"name": "Trailblazer hood (t1)"
},
{
"examine": "The headgear of a trailblazer relic hunter.",
"id": 25016,
"members": true,
"lowalch": 6000,
"limit": 5,
"value": 15000,
"highalch": 9000,
"icon": "Trailblazer hood (t2).png",
"name": "Trailblazer hood (t2)"
},
{
"examine": "The headgear of a trailblazer relic hunter.",
"id": 25001,
"members": true,
"lowalch": 60000,
"limit": 5,
"value": 150000,
"highalch": 90000,
"icon": "Trailblazer hood (t3).png",
"name": "Trailblazer hood (t3)"
},
{
"highalch": 30000,
"members": true,
"name": "Trailblazer relic hunter (t1) armour set",
"examine": "A set containing Trailblazer boots (t1), Trailblazer trousers (t1), Trailblazer top (t1) and Trailblazer hood (t1).",
"id": 25380,
"value": 50000,
"icon": "Trailblazer relic hunter (t1) armour set.png",
"lowalch": 20000
},
{
"highalch": 30000,
"members": true,
"name": "Trailblazer relic hunter (t2) armour set",
"examine": "A set containing Trailblazer boots (t2), Trailblazer trousers (t2), Trailblazer top (t2) and Trailblazer hood (t2).",
"id": 25383,
"value": 50000,
"icon": "Trailblazer relic hunter (t2) armour set.png",
"lowalch": 20000
},
{
"highalch": 30000,
"members": true,
"name": "Trailblazer relic hunter (t3) armour set",
"examine": "A set containing Trailblazer boots (t3), Trailblazer trousers (t3), Trailblazer top (t3) and Trailblazer hood (t3).",
"id": 25386,
"value": 50000,
"icon": "Trailblazer relic hunter (t3) armour set.png",
"lowalch": 20000
},
{
"highalch": 15000,
"members": true,
"name": "Trailblazer reloaded alchemy scroll",
"examine": "A scroll which unlocks the Trailblazer Reloaded Alchemy animation.",
"id": 28693,
"value": 25000,
"icon": "Trailblazer reloaded alchemy scroll.png",
"lowalch": 10000
},
{
"highalch": 600,
"members": true,
"name": "Trailblazer reloaded banner",
"examine": "A banner showcasing the colours of Leagues IV: Trailblazer Reloaded.",
"id": 28702,
"value": 1000,
"icon": "Trailblazer reloaded banner.png",
"lowalch": 400
},
{
"highalch": 3000,
"members": true,
"name": "Trailblazer reloaded blowpipe ornament kit",
"examine": "Used on an uncharged Toxic Blowpipe to give it the a theme from Leagues IV: Trailblazer Reloaded.",
"id": 28690,
"value": 5000,
"icon": "Trailblazer reloaded blowpipe ornament kit.png",
"lowalch": 2000
},
{
"highalch": 9000,
"members": true,
"name": "Trailblazer reloaded boots (t1)",
"examine": "The boots of a Trailblazer Reloaded Relic Hunter.",
"id": 28721,
"value": 15000,
"icon": "Trailblazer reloaded boots (t1).png",
"lowalch": 6000
},
{
"highalch": 9000,
"members": true,
"name": "Trailblazer reloaded boots (t2)",
"examine": "The boots of a Trailblazer Reloaded Relic Hunter.",
"id": 28733,
"value": 15000,
"icon": "Trailblazer reloaded boots (t2).png",
"lowalch": 6000
},
{
"highalch": 9000,
"members": true,
"name": "Trailblazer reloaded boots (t3)",
"examine": "The boots of a Trailblazer Reloaded Relic Hunter.",
"id": 28745,
"value": 15000,
"icon": "Trailblazer reloaded boots (t3).png",
"lowalch": 6000
},
{
"highalch": 3000,
"members": true,
"name": "Trailblazer reloaded bulwark ornament kit",
"examine": "Used on the Dinh's Bulwark to give it a theme from Leagues IV: Trailblazer Reloaded.",
"id": 28684,
"value": 5000,
"icon": "Trailblazer reloaded bulwark ornament kit.png",
"lowalch": 2000
},
{
"examine": "A scroll which unlocks the Trailblazer Reloaded death and respawn animation.",
"id": 28699,
"members": true,
"lowalch": 10000,
"limit": 5,
"value": 25000,
"highalch": 15000,
"icon": "Trailblazer reloaded death scroll.png",
"name": "Trailblazer reloaded death scroll"
},
{
"highalch": 9000,
"members": true,
"name": "Trailblazer reloaded headband (t1)",
"examine": "The headgear of a Trailblazer Reloaded Relic Hunter.",
"id": 28712,
"value": 15000,
"icon": "Trailblazer reloaded headband (t1).png",
"lowalch": 6000
},
{
"highalch": 9000,
"members": true,
"name": "Trailblazer reloaded headband (t2)",
"examine": "The headgear of a Trailblazer Reloaded Relic Hunter.",
"id": 28724,
"value": 15000,
"icon": "Trailblazer reloaded headband (t2).png",
"lowalch": 6000
},
{
"highalch": 9000,
"members": true,
"name": "Trailblazer reloaded headband (t3)",
"examine": "The headgear of a Trailblazer Reloaded Relic Hunter.",
"id": 28736,
"value": 15000,
"icon": "Trailblazer reloaded headband (t3).png",
"lowalch": 6000
},
{
"examine": "A scroll which unlocks the Trailblazer Reloaded Home Teleport animation.",
"id": 28705,
"members": true,
"lowalch": 10000,
"limit": 5,
"value": 25000,
"highalch": 15000,
"icon": "Trailblazer reloaded home teleport scroll.png",
"name": "Trailblazer reloaded home teleport scroll"
},
{
"highalch": 15000,
"members": true,
"name": "Trailblazer reloaded rejuvenation pool scroll",
"examine": "Used on an Ornate rejuvination pool inside your Player owned house to give it a theme from Leagues IV: Trailblazer Reloaded.",
"id": 28708,
"value": 25000,
"icon": "Trailblazer reloaded rejuvenation pool scroll.png",
"lowalch": 10000
},
{
"highalch": 30000,
"members": true,
"name": "Trailblazer reloaded relic hunter (t1) armour set",
"examine": "A set containing Trailblazer Reloaded boots (t1), Trailblazer Reloaded trousers (t1), Trailblazer Reloaded top (t1) and Trailblazer Reloaded hood (t1).",
"id": 28777,
"value": 50000,
"icon": "Trailblazer reloaded relic hunter (t1) armour set.png",
"lowalch": 20000
},
{
"highalch": 30000,
"members": true,
"name": "Trailblazer reloaded relic hunter (t2) armour set",
"examine": "A set containing Trailblazer Reloaded boots (t2), Trailblazer Reloaded trousers (t2), Trailblazer Reloaded top (t2) and Trailblazer Reloaded hood (t2).",
"id": 28780,
"value": 50000,
"icon": "Trailblazer reloaded relic hunter (t2) armour set.png",
"lowalch": 20000
},
{
"highalch": 30000,
"members": true,
"name": "Trailblazer reloaded relic hunter (t3) armour set",
"examine": "A set containing Trailblazer Reloaded boots (t3), Trailblazer Reloaded trousers (t3), Trailblazer Reloaded top (t3) and Trailblazer Reloaded hood (t3).",
"id": 28783,
"value": 50000,
"icon": "Trailblazer reloaded relic hunter (t3) armour set.png",
"lowalch": 20000
},
{
"highalch": 9000,
"members": true,
"name": "Trailblazer reloaded top (t1)",
"examine": "The shirt of a Trailblazer Reloaded Relic Hunter.",
"id": 28715,
"value": 15000,
"icon": "Trailblazer reloaded top (t1).png",
"lowalch": 6000
},
{
"highalch": 9000,
"members": true,
"name": "Trailblazer reloaded top (t2)",
"examine": "The shirt of a Trailblazer Reloaded Relic Hunter.",
"id": 28727,
"value": 15000,
"icon": "Trailblazer reloaded top (t2).png",
"lowalch": 6000
},
{
"highalch": 9000,
"members": true,
"name": "Trailblazer reloaded top (t3)",
"examine": "The shirt of a Trailblazer Reloaded Relic Hunter.",
"id": 28739,
"value": 15000,
"icon": "Trailblazer reloaded top (t3).png",
"lowalch": 6000
},
{
"highalch": 9000,
"members": true,
"name": "Trailblazer reloaded torch",
"examine": "The torch of a Trailblazer Reloaded Relic Hunter.",
"id": 28748,
"value": 15000,
"icon": "Trailblazer reloaded torch.png",
"lowalch": 6000
},
{
"highalch": 9000,
"members": true,
"name": "Trailblazer reloaded trousers (t1)",
"examine": "The trousers of a Trailblazer Reloaded Relic Hunter.",
"id": 28718,
"value": 15000,
"icon": "Trailblazer reloaded trousers (t1).png",
"lowalch": 6000
},
{
"highalch": 9000,
"members": true,
"name": "Trailblazer reloaded trousers (t2)",
"examine": "The trousers of a Trailblazer Reloaded Relic Hunter.",
"id": 28730,
"value": 15000,
"icon": "Trailblazer reloaded trousers (t2).png",
"lowalch": 6000
},
{
"highalch": 9000,
"members": true,
"name": "Trailblazer reloaded trousers (t3)",
"examine": "The trousers of a Trailblazer Reloaded Relic Hunter.",
"id": 28742,
"value": 15000,
"icon": "Trailblazer reloaded trousers (t3).png",
"lowalch": 6000
},
{
"highalch": 15000,
"members": true,
"name": "Trailblazer reloaded vengeance scroll",
"examine": "A scroll which unlocks the Trailblazer Reloaded Vengeance animation.",
"id": 28696,
"value": 25000,
"icon": "Trailblazer reloaded vengeance scroll.png",
"lowalch": 10000
},
{
"examine": "A rug which can be built into a rug hotspot in your PoH League Trophy Room.",
"id": 25096,
"members": true,
"lowalch": 20000,
"limit": 5,
"value": 50000,
"highalch": 30000,
"icon": "Trailblazer rug.png",
"name": "Trailblazer rug"
},
{
"examine": "A scroll which unlocks the Leagues II - Trailblazer home teleport animation.",
"id": 25087,
"members": true,
"lowalch": 10000,
"limit": 5,
"value": 25000,
"highalch": 15000,
"icon": "Trailblazer teleport scroll.png",
"name": "Trailblazer teleport scroll"
},
{
"examine": "A kit which can be attached to any Dragon or Infernal axe, pickaxe or harpoon to recolour it.",
"id": 25090,
"members": true,
"lowalch": 10000,
"limit": 5,
"value": 25000,
"highalch": 15000,
"icon": "Trailblazer tool ornament kit.png",
"name": "Trailblazer tool ornament kit"
},
{
"examine": "The coat of a trailblazer relic hunter.",
"id": 25031,
"members": true,
"lowalch": 6000,
"limit": 5,
"value": 15000,
"highalch": 9000,
"icon": "Trailblazer top (t1).png",
"name": "Trailblazer top (t1)"
},
{
"examine": "The coat of a trailblazer relic hunter.",
"id": 25019,
"members": true,
"lowalch": 6000,
"limit": 5,
"value": 15000,
"highalch": 9000,
"icon": "Trailblazer top (t2).png",
"name": "Trailblazer top (t2)"
},
{
"examine": "The coat of a trailblazer relic hunter.",
"id": 25004,
"members": true,
"lowalch": 60000,
"limit": 5,
"value": 150000,
"highalch": 90000,
"icon": "Trailblazer top (t3).png",
"name": "Trailblazer top (t3)"
},
{
"examine": "The trousers of a trailblazer relic hunter.",
"id": 25034,
"members": true,
"lowalch": 6000,
"limit": 5,
"value": 15000,
"highalch": 9000,
"icon": "Trailblazer trousers (t1).png",
"name": "Trailblazer trousers (t1)"
},
{
"examine": "The trousers of a trailblazer relic hunter.",
"id": 25022,
"members": true,
"lowalch": 6000,
"limit": 5,
"value": 15000,
"highalch": 9000,
"icon": "Trailblazer trousers (t2).png",
"name": "Trailblazer trousers (t2)"
},
{
"examine": "The trousers of a trailblazer relic hunter.",
"id": 25007,
"members": true,
"lowalch": 60000,
"limit": 5,
"value": 150000,
"highalch": 90000,
"icon": "Trailblazer trousers (t3).png",
"name": "Trailblazer trousers (t3)"
},
{
"highalch": 1,
"members": true,
"name": "Trapper's tipple",
"examine": "A glass of frothy ale.",
"id": 29277,
"value": 2,
"icon": "Trapper's tipple.png",
"lowalch": 1
},
{
"examine": "A razor sharp ring.",
"id": 12605,
"members": true,
"lowalch": 20000,
"limit": 8,
"value": 50000,
"highalch": 30000,
"icon": "Treasonous ring.png",
"name": "Treasonous ring"
},
{
"examine": "A freshly made triangle sandwich.",
"id": 6962,
"members": false,
"lowalch": 10,
"limit": 6000,
"value": 25,
"highalch": 15,
"icon": "Triangle sandwich.png",
"name": "Triangle sandwich"
},
{
"examine": "A ceremonial wooden mask.",
"id": 6339,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Tribal mask (combat).png",
"name": "Tribal mask (combat)"
},
{
"examine": "A ceremonial wooden mask.",
"id": 6337,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Tribal mask (disease).png",
"name": "Tribal mask (disease)"
},
{
"examine": "A ceremonial wooden mask.",
"id": 6335,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Tribal mask (poison).png",
"name": "Tribal mask (poison)"
},
{
"examine": "Local dress.",
"id": 6351,
"members": true,
"lowalch": 120,
"limit": 150,
"value": 300,
"highalch": 180,
"icon": "Tribal top (blue).png",
"name": "Tribal top (blue)"
},
{
"examine": "Local dress.",
"id": 6341,
"members": true,
"lowalch": 120,
"limit": 150,
"value": 300,
"highalch": 180,
"icon": "Tribal top (brown).png",
"name": "Tribal top (brown)"
},
{
"examine": "Local dress.",
"id": 6371,
"members": true,
"lowalch": 120,
"limit": 150,
"value": 300,
"highalch": 180,
"icon": "Tribal top (pink).png",
"name": "Tribal top (pink)"
},
{
"examine": "Local dress.",
"id": 6361,
"members": true,
"lowalch": 120,
"limit": 150,
"value": 300,
"highalch": 180,
"icon": "Tribal top (yellow).png",
"name": "Tribal top (yellow)"
},
{
"examine": "A weapon from the deep.",
"id": 11905,
"members": true,
"lowalch": 27600,
"limit": 8,
"value": 69000,
"highalch": 41400,
"icon": "Trident of the seas (full).png",
"name": "Trident of the seas (full)"
},
{
"highalch": 1,
"members": true,
"name": "Trinket of advanced weaponry",
"examine": "A magic rock! This one gives you a random blighted weapon instead.",
"id": 28567,
"value": 1,
"icon": "Trinket of advanced weaponry.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Trinket of fairies",
"examine": "A magic rock! This one smells funny and lets you teleport.",
"id": 28564,
"value": 1,
"icon": "Trinket of fairies.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Trinket of undead",
"examine": "A magic rock! This one gives you random thralls.",
"id": 28570,
"value": 1,
"icon": "Trinket of undead.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Trinket of vengeance",
"examine": "A magic rock! This one casts the Vengeance spell.",
"id": 28561,
"value": 1,
"icon": "Trinket of vengeance.png",
"lowalch": 1
},
{
"examine": "A pair of long dwarven trousers... long for dwarves, of course.",
"id": 5040,
"members": true,
"lowalch": 300,
"limit": 150,
"value": 750,
"highalch": 450,
"icon": "Trousers (blue).png",
"name": "Trousers (blue)"
},
{
"examine": "A pair of long dwarven trousers... long for dwarves, of course.",
"id": 5036,
"members": true,
"lowalch": 220,
"limit": 150,
"value": 550,
"highalch": 330,
"icon": "Trousers (brown).png",
"name": "Trousers (brown)"
},
{
"examine": "A pair of long dwarven trousers... long for dwarves, of course.",
"id": 5038,
"members": true,
"lowalch": 280,
"limit": 150,
"value": 700,
"highalch": 420,
"icon": "Trousers (lilac).png",
"name": "Trousers (lilac)"
},
{
"examine": "Some nicely cooked trout.",
"id": 333,
"members": false,
"lowalch": 4,
"limit": 6000,
"value": 10,
"highalch": 6,
"icon": "Trout.png",
"name": "Trout"
},
{
"highalch": 1,
"members": true,
"name": "Trouver parchment",
"examine": "Take it to Perdu to help you avoid losing certain items.",
"id": 24187,
"value": 1,
"icon": "Trouver parchment.png",
"lowalch": 1
},
{
"examine": "An ancient staff created using a higher power.",
"id": 27277,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 1,
"highalch": 1,
"icon": "Tumeken's shadow (uncharged).png",
"name": "Tumeken's shadow (uncharged)"
},
{
"examine": "Wow, this is a big fish.",
"id": 361,
"members": false,
"lowalch": 16,
"limit": 6000,
"value": 40,
"highalch": 24,
"icon": "Tuna.png",
"name": "Tuna"
},
{
"examine": "A bowl of cooked tuna and sweetcorn.",
"id": 7068,
"members": true,
"lowalch": 45,
"limit": 13000,
"value": 113,
"highalch": 67,
"icon": "Tuna and corn.png",
"name": "Tuna and corn"
},
{
"examine": "A baked potato with tuna and sweetcorn.",
"id": 7060,
"members": true,
"lowalch": 45,
"limit": 13000,
"value": 113,
"highalch": 67,
"icon": "Tuna potato.png",
"name": "Tuna potato"
},
{
"examine": "They're soft, silky and turquoise.",
"id": 634,
"members": true,
"lowalch": 80,
"limit": 150,
"value": 200,
"highalch": 120,
"icon": "Turquoise boots.png",
"name": "Turquoise boots"
},
{
"examine": "A silly turquoise pointed hat.",
"id": 664,
"members": true,
"lowalch": 64,
"limit": 150,
"value": 160,
"highalch": 96,
"icon": "Turquoise hat.png",
"name": "Turquoise hat"
},
{
"examine": "Made by Tree Gnomes with a thing for turquoise.",
"id": 654,
"members": true,
"lowalch": 72,
"limit": 150,
"value": 180,
"highalch": 108,
"icon": "Turquoise robe bottoms.png",
"name": "Turquoise robe bottoms"
},
{
"examine": "The ultimate in gnome design, now in turquoise!",
"id": 644,
"members": true,
"lowalch": 72,
"limit": 150,
"value": 180,
"highalch": 108,
"icon": "Turquoise robe top.png",
"name": "Turquoise robe top"
},
{
"examine": "A banner showcasing the colours of the Twisted League.",
"id": 24413,
"members": true,
"lowalch": 400,
"limit": 5,
"value": 1000,
"highalch": 600,
"icon": "Twisted banner.png",
"name": "Twisted banner"
},
{
"examine": "A scroll which unlocks the twisted player-owned house wall-kit.",
"id": 24463,
"members": true,
"lowalch": 10000,
"limit": 5,
"value": 25000,
"highalch": 15000,
"icon": "Twisted blueprints.png",
"name": "Twisted blueprints"
},
{
"examine": "The boots of a twisted relic hunter.",
"id": 24411,
"members": true,
"lowalch": 400,
"limit": 5,
"value": 1000,
"highalch": 600,
"icon": "Twisted boots (t1).png",
"name": "Twisted boots (t1)"
},
{
"examine": "The boots of a twisted relic hunter.",
"id": 24403,
"members": true,
"lowalch": 4000,
"limit": 5,
"value": 10000,
"highalch": 6000,
"icon": "Twisted boots (t2).png",
"name": "Twisted boots (t2)"
},
{
"examine": "The boots of a twisted relic hunter.",
"id": 24393,
"members": true,
"lowalch": 40000,
"limit": 5,
"value": 100000,
"highalch": 60000,
"icon": "Twisted boots (t3).png",
"name": "Twisted boots (t3)"
},
{
"examine": "A mystical bow carved from the twisted remains of the Great Olm.",
"id": 20997,
"members": true,
"lowalch": 1600000,
"limit": 8,
"value": 4000000,
"highalch": 2400000,
"icon": "Twisted bow.png",
"name": "Twisted bow"
},
{
"examine": "A buckler carved from the twisted remains of the Great Olm.",
"id": 21000,
"members": true,
"lowalch": 36000,
"limit": 8,
"value": 90000,
"highalch": 54000,
"icon": "Twisted buckler.png",
"name": "Twisted buckler"
},
{
"examine": "A cane of the twisted relic hunter.",
"id": 24395,
"members": true,
"lowalch": 400,
"limit": 10,
"value": 1000,
"highalch": 600,
"icon": "Twisted cane.png",
"name": "Twisted cane"
},
{
"examine": "The coat of a twisted relic hunter.",
"id": 24407,
"members": true,
"lowalch": 400,
"limit": 5,
"value": 1000,
"highalch": 600,
"icon": "Twisted coat (t1).png",
"name": "Twisted coat (t1)"
},
{
"examine": "The coat of a twisted relic hunter.",
"id": 24399,
"members": true,
"lowalch": 4000,
"limit": 5,
"value": 10000,
"highalch": 6000,
"icon": "Twisted coat (t2).png",
"name": "Twisted coat (t2)"
},
{
"examine": "The coat of a twisted relic hunter.",
"id": 24389,
"members": true,
"lowalch": 40000,
"limit": 5,
"value": 100000,
"highalch": 60000,
"icon": "Twisted coat (t3).png",
"name": "Twisted coat (t3)"
},
{
"examine": "The headgear of a twisted relic hunter.",
"id": 24405,
"members": true,
"lowalch": 400,
"limit": 5,
"value": 1000,
"highalch": 600,
"icon": "Twisted hat (t1).png",
"name": "Twisted hat (t1)"
},
{
"examine": "The headgear of a twisted relic hunter.",
"id": 24397,
"members": true,
"lowalch": 4000,
"limit": 5,
"value": 10000,
"highalch": 6000,
"icon": "Twisted hat (t2).png",
"name": "Twisted hat (t2)"
},
{
"examine": "The headgear of a twisted relic hunter.",
"id": 24387,
"members": true,
"lowalch": 40000,
"limit": 5,
"value": 100000,
"highalch": 60000,
"icon": "Twisted hat (t3).png",
"name": "Twisted hat (t3)"
},
{
"examine": "These look like they can be attached to a slayer helmet.",
"id": 24466,
"members": true,
"lowalch": 40000,
"limit": 5,
"value": 100000,
"highalch": 60000,
"icon": "Twisted horns.png",
"name": "Twisted horns"
},
{
"examine": "A set containing Twisted boots (t1), Twisted trousers (t1), Twisted coat (t1) and Twisted hat (t1).",
"id": 24469,
"members": true,
"lowalch": 20000,
"limit": 5,
"value": 50000,
"highalch": 30000,
"icon": "Twisted relic hunter (t1) armour set.png",
"name": "Twisted relic hunter (t1) armour set"
},
{
"examine": "A set containing Twisted boots (t2), Twisted trousers (t2), Twisted coat (t2) and Twisted hat (t2).",
"id": 24472,
"members": true,
"lowalch": 20000,
"limit": 5,
"value": 50000,
"highalch": 30000,
"icon": "Twisted relic hunter (t2) armour set.png",
"name": "Twisted relic hunter (t2) armour set"
},
{
"examine": "A set containing Twisted boots (t3), Twisted trousers (t3), Twisted coat (t3) and Twisted hat (t3).",
"id": 24475,
"members": true,
"lowalch": 20000,
"limit": 5,
"value": 50000,
"highalch": 30000,
"icon": "Twisted relic hunter (t3) armour set.png",
"name": "Twisted relic hunter (t3) armour set"
},
{
"examine": "A scroll which unlocks the twisted home teleport animation.",
"id": 24460,
"members": true,
"lowalch": 10000,
"limit": 5,
"value": 25000,
"highalch": 15000,
"icon": "Twisted teleport scroll.png",
"name": "Twisted teleport scroll"
},
{
"examine": "The trousers of a twisted relic hunter.",
"id": 24409,
"members": true,
"lowalch": 400,
"limit": 5,
"value": 1000,
"highalch": 600,
"icon": "Twisted trousers (t1).png",
"name": "Twisted trousers (t1)"
},
{
"examine": "The trousers of a twisted relic hunter.",
"id": 24401,
"members": true,
"lowalch": 4000,
"limit": 5,
"value": 10000,
"highalch": 6000,
"icon": "Twisted trousers (t2).png",
"name": "Twisted trousers (t2)"
},
{
"examine": "The trousers of a twisted relic hunter.",
"id": 24391,
"members": true,
"lowalch": 40000,
"limit": 5,
"value": 100000,
"highalch": 60000,
"icon": "Twisted trousers (t3).png",
"name": "Twisted trousers (t3)"
},
{
"examine": "An incredibly heavy ring.",
"id": 12603,
"members": true,
"lowalch": 20000,
"limit": 8,
"value": 50000,
"highalch": 30000,
"icon": "Tyrannical ring.png",
"name": "Tyrannical ring"
},
{
"examine": "As used by King Tyras' personal guard.",
"id": 9629,
"members": true,
"lowalch": 220,
"limit": 150,
"value": 550,
"highalch": 330,
"icon": "Tyras helm.png",
"name": "Tyras helm"
},
{
"examine": "A mace of obsidian.",
"id": 6527,
"members": true,
"lowalch": 12000,
"limit": 70,
"value": 30000,
"highalch": 18000,
"icon": "Tzhaar-ket-em.png",
"name": "Tzhaar-ket-em"
},
{
"examine": "A maul of obsidian.",
"id": 6528,
"members": true,
"lowalch": 20000,
"limit": 70,
"value": 50001,
"highalch": 30000,
"icon": "Tzhaar-ket-om.png",
"name": "Tzhaar-ket-om"
},
{
"examine": "Use on the TzHaar-ket-om to trim and spike it.",
"id": 23232,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Tzhaar-ket-om ornament kit.png",
"name": "Tzhaar-ket-om ornament kit"
},
{
"examine": "A mixture of chopped onions and ugthanki meat in a bowl.",
"id": 1877,
"members": true,
"lowalch": 2,
"limit": 11000,
"value": 7,
"highalch": 4,
"icon": "Ugthanki & onion.png",
"name": "Ugthanki & onion"
},
{
"examine": "A mixture of chopped tomatoes and ugthanki meat in a bowl.",
"id": 1879,
"members": true,
"lowalch": 2,
"limit": 11000,
"value": 7,
"highalch": 4,
"icon": "Ugthanki & tomato.png",
"name": "Ugthanki & tomato"
},
{
"examine": "A fresh kebab made from ugthanki meat.",
"id": 1885,
"members": true,
"lowalch": 8,
"limit": 10000,
"value": 20,
"highalch": 12,
"icon": "Ugthanki kebab.png",
"name": "Ugthanki kebab"
},
{
"examine": "Freshly cooked ugthanki meat.",
"id": 1861,
"members": true,
"lowalch": 2,
"limit": 11000,
"value": 5,
"highalch": 3,
"icon": "Ugthanki meat.png",
"name": "Ugthanki meat"
},
{
"highalch": 1,
"members": true,
"name": "Ultor ring",
"examine": "An occult ring that grants immense physical power.",
"id": 28307,
"value": 1,
"icon": "Ultor ring.png",
"lowalch": 1
},
{
"examine": "Ultra-good for the smallest or largest of plants.",
"id": 21483,
"members": true,
"lowalch": 34,
"limit": 2000,
"value": 85,
"highalch": 51,
"icon": "Ultracompost.png",
"name": "Ultracompost"
},
{
"examine": "A symbol of Saradomin.",
"id": 1716,
"members": false,
"lowalch": 80,
"limit": 10000,
"value": 200,
"highalch": 120,
"icon": "Unblessed symbol.png",
"name": "Unblessed symbol"
},
{
"examine": "It needs to be charged with death runes, chaos runes, fire runes and Zulrah's scales.",
"id": 12900,
"members": true,
"lowalch": 31200,
"limit": 8,
"value": 78000,
"highalch": 46800,
"icon": "Uncharged toxic trident.png",
"name": "Uncharged toxic trident"
},
{
"examine": "It needs to be charged with death runes, chaos runes, fire runes and Zulrah's scales.",
"id": 22294,
"members": true,
"lowalch": 43200,
"limit": 8,
"value": 108000,
"highalch": 64800,
"icon": "Uncharged toxic trident (e).png",
"name": "Uncharged toxic trident (e)"
},
{
"examine": "It needs to be charged with death runes, chaos runes, fire runes and coins.",
"id": 11908,
"members": true,
"lowalch": 27200,
"limit": 8,
"value": 68000,
"highalch": 40800,
"icon": "Uncharged trident.png",
"name": "Uncharged trident"
},
{
"examine": "It needs to be charged with death runes, chaos runes, fire runes and coins.",
"id": 22290,
"members": true,
"lowalch": 39200,
"limit": 8,
"value": 98000,
"highalch": 58800,
"icon": "Uncharged trident (e).png",
"name": "Uncharged trident (e)"
},
{
"examine": "This would be much tastier cooked.",
"id": 2317,
"members": false,
"lowalch": 6,
"limit": 13000,
"value": 16,
"highalch": 9,
"icon": "Uncooked apple pie.png",
"name": "Uncooked apple pie"
},
{
"examine": "This would be much more appetising cooked.",
"id": 2321,
"members": false,
"lowalch": 2,
"limit": 13000,
"value": 6,
"highalch": 3,
"icon": "Uncooked berry pie.png",
"name": "Uncooked berry pie"
},
{
"examine": "This would be much tastier cooked.",
"id": 19656,
"members": true,
"lowalch": 6,
"limit": 13000,
"value": 16,
"highalch": 9,
"icon": "Uncooked botanical pie.png",
"name": "Uncooked botanical pie"
},
{
"examine": "This would be much tastier cooked.",
"id": 22789,
"members": true,
"lowalch": 16,
"limit": 10000,
"value": 40,
"highalch": 24,
"icon": "Uncooked dragonfruit pie.png",
"name": "Uncooked dragonfruit pie"
},
{
"examine": "A bowl of uncooked egg.",
"id": 7076,
"members": true,
"lowalch": 3,
"limit": 13000,
"value": 8,
"highalch": 4,
"icon": "Uncooked egg.png",
"name": "Uncooked egg"
},
{
"examine": "This would be much healthier cooked.",
"id": 2319,
"members": false,
"lowalch": 3,
"limit": 13000,
"value": 8,
"highalch": 4,
"icon": "Uncooked meat pie.png",
"name": "Uncooked meat pie"
},
{
"examine": "This would be much tastier cooked.",
"id": 21684,
"members": true,
"lowalch": 6,
"limit": 13000,
"value": 16,
"highalch": 9,
"icon": "Uncooked mushroom pie.png",
"name": "Uncooked mushroom pie"
},
{
"examine": "This would be worth more cut.",
"id": 1617,
"members": false,
"lowalch": 80,
"limit": 10000,
"value": 200,
"highalch": 120,
"icon": "Uncut diamond.png",
"name": "Uncut diamond"
},
{
"examine": "This would be worth more cut.",
"id": 1631,
"members": true,
"lowalch": 400,
"limit": 10000,
"value": 1000,
"highalch": 600,
"icon": "Uncut dragonstone.png",
"name": "Uncut dragonstone"
},
{
"examine": "This would be worth more cut.",
"id": 1621,
"members": false,
"lowalch": 20,
"limit": 10000,
"value": 50,
"highalch": 30,
"icon": "Uncut emerald.png",
"name": "Uncut emerald"
},
{
"examine": "This would be worth more cut.",
"id": 1627,
"members": true,
"lowalch": 12,
"limit": 10000,
"value": 30,
"highalch": 18,
"icon": "Uncut jade.png",
"name": "Uncut jade"
},
{
"examine": "This would be worth more cut.",
"id": 6571,
"members": true,
"lowalch": 80000,
"limit": 11000,
"value": 200000,
"highalch": 120000,
"icon": "Uncut onyx.png",
"name": "Uncut onyx"
},
{
"examine": "This would be worth more cut.",
"id": 1625,
"members": true,
"lowalch": 8,
"limit": 10000,
"value": 20,
"highalch": 12,
"icon": "Uncut opal.png",
"name": "Uncut opal"
},
{
"examine": "This would be worth more cut.",
"id": 1629,
"members": true,
"lowalch": 16,
"limit": 10000,
"value": 40,
"highalch": 24,
"icon": "Uncut red topaz.png",
"name": "Uncut red topaz"
},
{
"examine": "This would be worth more cut.",
"id": 1619,
"members": false,
"lowalch": 40,
"limit": 10000,
"value": 100,
"highalch": 60,
"icon": "Uncut ruby.png",
"name": "Uncut ruby"
},
{
"examine": "This would be worth more cut.",
"id": 1623,
"members": false,
"lowalch": 10,
"limit": 10000,
"value": 25,
"highalch": 15,
"icon": "Uncut sapphire.png",
"name": "Uncut sapphire"
},
{
"examine": "This would be worth more cut.",
"id": 19496,
"members": true,
"lowalch": 30000,
"limit": 10000,
"value": 75000,
"highalch": 45000,
"icon": "Uncut zenyte.png",
"name": "Uncut zenyte"
},
{
"examine": "Add feathers to make broad-tipped crossbow bolts.",
"id": 11876,
"members": true,
"lowalch": 22,
"limit": 7000,
"value": 55,
"highalch": 33,
"icon": "Unfinished broad bolts 5.png",
"name": "Unfinished broad bolts"
},
{
"examine": "I need another ingredient to finish this potion.",
"id": 3406,
"members": true,
"lowalch": 4,
"limit": 10000,
"value": 11,
"highalch": 6,
"icon": "Unfinished potion.png",
"name": "Unfinished potion"
},
{
"examine": "I need to put this in a pottery oven.",
"id": 1791,
"members": false,
"lowalch": 1,
"limit": 10000,
"value": 2,
"highalch": 1,
"icon": "Unfired bowl.png",
"name": "Unfired bowl"
},
{
"highalch": 1,
"members": true,
"name": "Unfired cup",
"examine": "I need to put this in a pottery oven.",
"id": 28193,
"value": 1,
"icon": "Unfired cup.png",
"lowalch": 1
},
{
"examine": "I need to put this in a pottery oven.",
"id": 1789,
"members": false,
"lowalch": 1,
"limit": 10000,
"value": 3,
"highalch": 1,
"icon": "Unfired pie dish.png",
"name": "Unfired pie dish"
},
{
"examine": "An unfired plant pot.",
"id": 5352,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Unfired plant pot.png",
"name": "Unfired plant pot"
},
{
"examine": "I need to put this in a pottery oven.",
"id": 1787,
"members": false,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Unfired pot.png",
"name": "Unfired pot"
},
{
"examine": "This needs firing, then it should fit on a normal-sized pot.",
"id": 4438,
"members": true,
"lowalch": 4,
"limit": 100,
"value": 10,
"highalch": 6,
"icon": "Unfired pot lid.png",
"name": "Unfired pot lid"
},
{
"examine": "The Dark Lord will rise again with his servant's aid, greater and more terrible than ever he was.",
"id": 20223,
"members": true,
"lowalch": 32,
"limit": 5,
"value": 80,
"highalch": 48,
"icon": "Unholy blessing.png",
"name": "Unholy blessing"
},
{
"examine": "A set containing the four pages of Zamorak's Unholy Book.",
"id": 13151,
"members": true,
"lowalch": 2800,
"limit": 5,
"value": 7000,
"highalch": 4200,
"icon": "Unholy book page set.png",
"name": "Unholy book page set"
},
{
"examine": "An unholy symbol of Zamorak.",
"id": 1724,
"members": true,
"lowalch": 80,
"limit": 125,
"value": 200,
"highalch": 120,
"icon": "Unholy symbol.png",
"name": "Unholy symbol"
},
{
"examine": "This horn has restorative properties.",
"id": 237,
"members": true,
"lowalch": 8,
"limit": 13000,
"value": 20,
"highalch": 12,
"icon": "Unicorn horn.png",
"name": "Unicorn horn"
},
{
"examine": "Finely ground horn of Unicorn.",
"id": 235,
"members": true,
"lowalch": 8,
"limit": 13000,
"value": 20,
"highalch": 12,
"icon": "Unicorn horn dust.png",
"name": "Unicorn horn dust"
},
{
"examine": "A lantern to aid attacking Harpie bugs.",
"id": 7051,
"members": true,
"lowalch": 52,
"limit": 40,
"value": 130,
"highalch": 78,
"icon": "Unlit bug lantern.png",
"name": "Unlit bug lantern"
},
{
"examine": "An unlit home-made torch.",
"id": 596,
"members": true,
"lowalch": 1,
"limit": 40,
"value": 4,
"highalch": 2,
"icon": "Unlit torch.png",
"name": "Unlit torch"
},
{
"examine": "I'd prefer it if it was powered.",
"id": 567,
"members": true,
"lowalch": 40,
"limit": 10000,
"value": 100,
"highalch": 60,
"icon": "Unpowered orb.png",
"name": "Unpowered orb"
},
{
"examine": "An unblessed symbol of Zamorak.",
"id": 1722,
"members": true,
"lowalch": 80,
"limit": 10000,
"value": 200,
"highalch": 120,
"icon": "Unpowered symbol.png",
"name": "Unpowered symbol"
},
{
"examine": "An unstrung composite ogre bow.",
"id": 4825,
"members": true,
"lowalch": 36,
"limit": 10000,
"value": 90,
"highalch": 54,
"icon": "Unstrung comp bow.png",
"name": "Unstrung comp bow"
},
{
"examine": "It needs a string so I can wear it.",
"id": 1720,
"members": true,
"lowalch": 80,
"limit": 10000,
"value": 200,
"highalch": 120,
"icon": "Unstrung emblem.png",
"name": "Unstrung emblem"
},
{
"examine": "Needs a suitably simian rope.",
"id": 19607,
"members": true,
"lowalch": 20000,
"limit": 8,
"value": 50000,
"highalch": 30000,
"icon": "Unstrung heavy ballista.png",
"name": "Unstrung heavy ballista"
},
{
"examine": "Needs a suitably simian rope.",
"id": 19604,
"members": true,
"lowalch": 20000,
"limit": 8,
"value": 50000,
"highalch": 30000,
"icon": "Unstrung light ballista.png",
"name": "Unstrung light ballista"
},
{
"examine": "It needs a string so I can wear it.",
"id": 1714,
"members": false,
"lowalch": 80,
"limit": 10000,
"value": 200,
"highalch": 120,
"icon": "Unstrung symbol.png",
"name": "Unstrung symbol"
},
{
"examine": "This is top secret comrade...",
"id": 23255,
"members": true,
"lowalch": 400,
"limit": 4,
"value": 1000,
"highalch": 600,
"icon": "Uri's hat.png",
"name": "Uri's hat"
},
{
"examine": "The remains of a deadly shade.",
"id": 25419,
"members": true,
"lowalch": 1,
"limit": 7500,
"value": 1,
"highalch": 1,
"icon": "Urium remains.png",
"name": "Urium remains"
},
{
"examine": "An ancient and peculiar chainmace, corrupted by the power of Callisto.",
"id": 27657,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 1,
"highalch": 1,
"icon": "Ursine chainmace (u).png",
"name": "Ursine chainmace (u)"
},
{
"examine": "A green salt that, when burned with other salts provides various benefits.",
"id": 22597,
"members": true,
"lowalch": 4,
"limit": 10000,
"value": 10,
"highalch": 6,
"icon": "Urt salt.png",
"name": "Urt salt"
},
{
"examine": "That used to be a vampyre!",
"id": 3325,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 2,
"highalch": 1,
"icon": "Vampyre dust.png",
"name": "Vampyre dust"
},
{
"examine": "A teleport to Varrock.",
"id": 8007,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Varrock teleport (tablet).png",
"name": "Varrock teleport (tablet)"
},
{
"examine": "This looks pretty healthy.",
"id": 2195,
"members": true,
"lowalch": 1,
"limit": 6000,
"value": 2,
"highalch": 1,
"icon": "Veg ball.png",
"name": "Veg ball"
},
{
"examine": "Well... it looks healthy.",
"id": 2281,
"members": true,
"lowalch": 1,
"limit": 6000,
"value": 2,
"highalch": 1,
"icon": "Vegetable batta.png",
"name": "Vegetable batta"
},
{
"highalch": 1,
"members": true,
"name": "Venator bow (uncharged)",
"examine": "A bow used by hunters from a forgotten empire.",
"id": 27612,
"value": 1,
"icon": "Venator bow (uncharged).png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Venator ring",
"examine": "An occult ring that grants immense dexterity.",
"id": 28310,
"value": 1,
"icon": "Venator ring.png",
"lowalch": 1
},
{
"examine": "A broken shard of an ancient weapon.",
"id": 27614,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 1,
"highalch": 1,
"icon": "Venator shard.png",
"name": "Venator shard"
},
{
"examine": "A set containing a Verac's helm, plateskirt, brassard and flail.",
"id": 12875,
"members": true,
"lowalch": 160000,
"limit": 8,
"value": 400000,
"highalch": 240000,
"icon": "Verac's armour set.png",
"name": "Verac's armour set"
},
{
"examine": "Verac the Defiled's brassard.",
"id": 4757,
"members": true,
"lowalch": 112000,
"limit": 15,
"value": 280000,
"highalch": 168000,
"icon": "Verac's brassard.png",
"name": "Verac's brassard"
},
{
"examine": "Verac the Defiled's brassard.",
"id": 4992,
"members": true,
"lowalch": 112000,
"limit": 15,
"value": 280000,
"highalch": 168000,
"icon": "Verac's brassard 0.png",
"name": "Verac's brassard 0"
},
{
"examine": "Verac the Defiled's flail.",
"id": 4755,
"members": true,
"lowalch": 64000,
"limit": 15,
"value": 160000,
"highalch": 96000,
"icon": "Verac's flail.png",
"name": "Verac's flail"
},
{
"examine": "Verac the Defiled's flail.",
"id": 4986,
"members": true,
"lowalch": 64000,
"limit": 15,
"value": 160000,
"highalch": 96000,
"icon": "Verac's flail 0.png",
"name": "Verac's flail 0"
},
{
"examine": "Verac the Defiled's helm.",
"id": 4753,
"members": true,
"lowalch": 41200,
"limit": 15,
"value": 103000,
"highalch": 61800,
"icon": "Verac's helm.png",
"name": "Verac's helm"
},
{
"examine": "Verac the Defiled's helm.",
"id": 4980,
"members": true,
"lowalch": 41200,
"limit": 15,
"value": 103000,
"highalch": 61800,
"icon": "Verac's helm 0.png",
"name": "Verac's helm 0"
},
{
"examine": "Verac the Defiled's plate skirt.",
"id": 4759,
"members": true,
"lowalch": 110000,
"limit": 15,
"value": 275000,
"highalch": 165000,
"icon": "Verac's plateskirt.png",
"name": "Verac's plateskirt"
},
{
"examine": "Verac the Defiled's plate skirt.",
"id": 4998,
"members": true,
"lowalch": 110000,
"limit": 15,
"value": 275000,
"highalch": 165000,
"icon": "Verac's plateskirt 0.png",
"name": "Verac's plateskirt 0"
},
{
"examine": "A powerful longsword.",
"id": 22613,
"members": true,
"lowalch": 120000,
"limit": 10,
"value": 300000,
"highalch": 180000,
"icon": "Vesta's longsword.png",
"name": "Vesta's longsword"
},
{
"examine": "A powerful spear.",
"id": 22610,
"members": true,
"lowalch": 120000,
"limit": 10,
"value": 300000,
"highalch": 180000,
"icon": "Vesta's spear.png",
"name": "Vesta's spear"
},
{
"examine": "An empty glass vial.",
"id": 229,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Vial.png",
"name": "Vial"
},
{
"examine": "A glass vial containing blood.",
"id": 22446,
"members": true,
"lowalch": 40,
"limit": 13000,
"value": 100,
"highalch": 60,
"icon": "Vial of blood.png",
"name": "Vial of blood"
},
{
"examine": "A glass vial containing water.",
"id": 227,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 2,
"highalch": 1,
"icon": "Vial of water.png",
"name": "Vial of water"
},
{
"examine": "An ancient chainmace with a peculiar mechanism that allows for varying attack styles.",
"id": 22542,
"members": true,
"lowalch": 48000,
"limit": 8,
"value": 120000,
"highalch": 72000,
"icon": "Viggora's chainmace (u).png",
"name": "Viggora's chainmace (u)"
},
{
"examine": "A heap of ashes.",
"id": 25769,
"members": false,
"lowalch": 1,
"limit": 3000,
"value": 1,
"highalch": 1,
"icon": "Vile ashes.png",
"name": "Vile ashes"
},
{
"examine": "A light blue armband, as worn by the Tai Bwo Wannai locals.",
"id": 6359,
"members": true,
"lowalch": 60,
"limit": 150,
"value": 150,
"highalch": 90,
"icon": "Villager armband (blue).png",
"name": "Villager armband (blue)"
},
{
"examine": "A brown armband, as worn by the Tai Bwo Wannai locals.",
"id": 6347,
"members": true,
"lowalch": 60,
"limit": 150,
"value": 150,
"highalch": 90,
"icon": "Villager armband (brown).png",
"name": "Villager armband (brown)"
},
{
"examine": "A white armband, as worn by the Tai Bwo Wannai locals.",
"id": 6379,
"members": true,
"lowalch": 60,
"limit": 150,
"value": 150,
"highalch": 90,
"icon": "Villager armband (pink).png",
"name": "Villager armband (pink)"
},
{
"examine": "A dark blue armband, as worn by the Tai Bwo Wannai locals.",
"id": 6369,
"members": true,
"lowalch": 60,
"limit": 150,
"value": 150,
"highalch": 90,
"icon": "Villager armband (yellow).png",
"name": "Villager armband (yellow)"
},
{
"examine": "A brightly coloured hat prized by the Tai Bwo Wannai peoples.",
"id": 6355,
"members": true,
"lowalch": 80,
"limit": 150,
"value": 200,
"highalch": 120,
"icon": "Villager hat (blue).png",
"name": "Villager hat (blue)"
},
{
"examine": "A brightly coloured hat prized by the Tai Bwo Wannai peoples.",
"id": 6345,
"members": true,
"lowalch": 80,
"limit": 150,
"value": 200,
"highalch": 120,
"icon": "Villager hat (brown).png",
"name": "Villager hat (brown)"
},
{
"examine": "A brightly coloured hat prized by the Tai Bwo Wannai peoples.",
"id": 6375,
"members": true,
"lowalch": 80,
"limit": 150,
"value": 200,
"highalch": 120,
"icon": "Villager hat (pink).png",
"name": "Villager hat (pink)"
},
{
"examine": "A brightly coloured hat prized by the Tai Bwo Wannai peoples.",
"id": 6365,
"members": true,
"lowalch": 80,
"limit": 150,
"value": 200,
"highalch": 120,
"icon": "Villager hat (yellow).png",
"name": "Villager hat (yellow)"
},
{
"examine": "A brightly coloured robe prized by the Tai Bwo Wannai peoples.",
"id": 6353,
"members": true,
"lowalch": 100,
"limit": 150,
"value": 250,
"highalch": 150,
"icon": "Villager robe (blue).png",
"name": "Villager robe (blue)"
},
{
"examine": "A brightly coloured robe prized by the Tai Bwo Wannai peoples.",
"id": 6343,
"members": true,
"lowalch": 100,
"limit": 150,
"value": 250,
"highalch": 150,
"icon": "Villager robe (brown).png",
"name": "Villager robe (brown)"
},
{
"examine": "A brightly coloured robe prized by the Tai Bwo Wannai peoples.",
"id": 6373,
"members": true,
"lowalch": 100,
"limit": 150,
"value": 250,
"highalch": 150,
"icon": "Villager robe (pink).png",
"name": "Villager robe (pink)"
},
{
"examine": "A brightly coloured robe prized by the Tai Bwo Wannai peoples.",
"id": 6363,
"members": true,
"lowalch": 100,
"limit": 150,
"value": 250,
"highalch": 150,
"icon": "Villager robe (yellow).png",
"name": "Villager robe (yellow)"
},
{
"examine": "A brightly coloured pair of local sandals.",
"id": 6357,
"members": true,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Villager sandals (blue).png",
"name": "Villager sandals (blue)"
},
{
"examine": "A brightly coloured pair of local sandals.",
"id": 6349,
"members": true,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Villager sandals (brown).png",
"name": "Villager sandals (brown)"
},
{
"examine": "A brightly coloured pair of local sandals.",
"id": 6377,
"members": true,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Villager sandals (pink).png",
"name": "Villager sandals (pink)"
},
{
"examine": "A brightly coloured pair of local sandals.",
"id": 6367,
"members": true,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Villager sandals (yellow).png",
"name": "Villager sandals (yellow)"
},
{
"examine": "An ancient mage's mask.",
"id": 26241,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 1,
"highalch": 1,
"icon": "Virtus mask.png",
"name": "Virtus mask"
},
{
"highalch": 1,
"members": true,
"name": "Virtus robe bottom",
"examine": "An ancient mage's robe bottom.",
"id": 26245,
"value": 1,
"icon": "Virtus robe bottom.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Virtus robe top",
"examine": "An ancient mage's robe top.",
"id": 26243,
"value": 1,
"icon": "Virtus robe top.png",
"lowalch": 1
},
{
"examine": "An absolutely clear spirit.",
"id": 2015,
"members": true,
"lowalch": 2,
"limit": 13000,
"value": 5,
"highalch": 3,
"icon": "Vodka.png",
"name": "Vodka"
},
{
"examine": "A truly fearsome weapon.",
"id": 27690,
"members": true,
"lowalch": 1,
"limit": 70,
"value": 1,
"highalch": 1,
"icon": "Voidwaker.png",
"name": "Voidwaker"
},
{
"highalch": 1,
"members": true,
"name": "Voidwaker blade",
"examine": "The blade of a broken sword.",
"id": 27684,
"value": 1,
"icon": "Voidwaker blade.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Voidwaker gem",
"examine": "The gem of a broken sword.",
"id": 27687,
"value": 1,
"icon": "Voidwaker gem.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Voidwaker hilt",
"examine": "The hilt of a broken sword.",
"id": 27681,
"value": 1,
"icon": "Voidwaker hilt.png",
"lowalch": 1
},
{
"examine": "An ancient magical orb, corrupted by darkness.",
"id": 24514,
"members": true,
"lowalch": 1,
"limit": 8,
"value": 1,
"highalch": 1,
"icon": "Volatile orb.png",
"name": "Volatile orb"
},
{
"examine": "One of the ingredients for making ultra compost.",
"id": 21622,
"members": true,
"lowalch": 80,
"limit": 13000,
"value": 200,
"highalch": 120,
"icon": "Volcanic ash.png",
"name": "Volcanic ash"
},
{
"examine": "How has lava been stored like this...?",
"id": 12771,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Volcanic whip mix.png",
"name": "Volcanic whip mix"
},
{
"examine": "Dress like the powerful vyrewatch!",
"id": 9636,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Vyrewatch legs.png",
"name": "Vyrewatch legs"
},
{
"examine": "Dress like the powerful vyrewatch!",
"id": 9638,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Vyrewatch shoes.png",
"name": "Vyrewatch shoes"
},
{
"examine": "Dress like the powerful vyrewatch!",
"id": 9634,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Vyrewatch top.png",
"name": "Vyrewatch top"
},
{
"examine": "The blood of the soldier makes the glory of the general.",
"id": 20232,
"members": true,
"lowalch": 32,
"limit": 5,
"value": 80,
"highalch": 48,
"icon": "War blessing.png",
"name": "War blessing"
},
{
"examine": "Makes a malediction or odium ward more beautiful.",
"id": 12802,
"members": true,
"lowalch": 600,
"limit": 50,
"value": 1500,
"highalch": 900,
"icon": "Ward upgrade kit.png",
"name": "Ward upgrade kit"
},
{
"highalch": 1,
"members": true,
"name": "Warped sceptre",
"examine": "A magical warped weapon.",
"id": 28585,
"value": 1,
"icon": "Warped sceptre.png",
"lowalch": 1
},
{
"highalch": 1,
"members": true,
"name": "Warped sceptre (uncharged)",
"examine": "A magical warped weapon.",
"id": 28583,
"value": 1,
"icon": "Warped sceptre (uncharged).png",
"lowalch": 1
},
{
"examine": "This helmet is worn by warriors.",
"id": 3753,
"members": true,
"lowalch": 24000,
"limit": 70,
"value": 60000,
"highalch": 36000,
"icon": "Warrior helm.png",
"name": "Warrior helm"
},
{
"highalch": 1,
"members": true,
"name": "Warrior icon",
"examine": "The icon of a warrior.",
"id": 28301,
"value": 1,
"icon": "Warrior icon.png",
"lowalch": 1
},
{
"examine": "A legendary ring once worn by Fremennik warriors.",
"id": 6735,
"members": true,
"lowalch": 4000,
"limit": 8,
"value": 10000,
"highalch": 6000,
"icon": "Warrior ring.png",
"name": "Warrior ring"
},
{
"examine": "A teleport to the Yanille Watchtower.",
"id": 8012,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Watchtower teleport (tablet).png",
"name": "Watchtower teleport (tablet)"
},
{
"examine": "It's a slightly magical stick.",
"id": 1395,
"members": true,
"lowalch": 6200,
"limit": 18000,
"value": 15500,
"highalch": 9300,
"icon": "Water battlestaff.png",
"name": "Water battlestaff"
},
{
"examine": "A magic glowing orb.",
"id": 571,
"members": true,
"lowalch": 120,
"limit": 11000,
"value": 300,
"highalch": 180,
"icon": "Water orb.png",
"name": "Water orb"
},
{
"examine": "One of the 4 basic elemental Runes.",
"id": 555,
"members": false,
"lowalch": 1,
"limit": 50000,
"value": 4,
"highalch": 2,
"icon": "Water rune.png",
"name": "Water rune"
},
{
"examine": "A mysterious power emanates from the talisman...",
"id": 1444,
"members": false,
"lowalch": 1,
"limit": 11000,
"value": 4,
"highalch": 2,
"icon": "Water talisman.png",
"name": "Water talisman"
},
{
"examine": "A tiara infused with the properties of water.",
"id": 5531,
"members": false,
"lowalch": 40,
"limit": 40,
"value": 100,
"highalch": 60,
"icon": "Water tiara.png",
"name": "Water tiara"
},
{
"examine": "A teleport to Waterbirth Island.",
"id": 24953,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "Waterbirth teleport (tablet).png",
"name": "Waterbirth teleport (tablet)"
},
{
"examine": "This watering can is empty.",
"id": 5331,
"members": true,
"lowalch": 3,
"limit": 40,
"value": 8,
"highalch": 4,
"icon": "Watering can.png",
"name": "Watering can"
},
{
"examine": "A juicy watermelon.",
"id": 5982,
"members": true,
"lowalch": 19,
"limit": 11000,
"value": 48,
"highalch": 28,
"icon": "Watermelon.png",
"name": "Watermelon"
},
{
"examine": "A watermelon seed - plant in an allotment.",
"id": 5321,
"members": true,
"lowalch": 22,
"limit": 200,
"value": 56,
"highalch": 33,
"icon": "Watermelon seed 5.png",
"name": "Watermelon seed"
},
{
"examine": "A slice of watermelon.",
"id": 5984,
"members": true,
"lowalch": 6,
"limit": 11000,
"value": 16,
"highalch": 9,
"icon": "Watermelon slice.png",
"name": "Watermelon slice"
},
{
"examine": "A completely empty waterskin - you'll need to fill it up.",
"id": 1831,
"members": true,
"lowalch": 6,
"limit": 10000,
"value": 15,
"highalch": 9,
"icon": "Waterskin(0).png",
"name": "Waterskin(0)"
},
{
"examine": "A full waterskin with four portions of water.",
"id": 1823,
"members": true,
"lowalch": 12,
"limit": 2000,
"value": 30,
"highalch": 18,
"icon": "Waterskin(4).png",
"name": "Waterskin(4)"
},
{
"examine": "Teleports you to Watson's house in Great Kourend.",
"id": 23387,
"members": true,
"lowalch": 4,
"limit": 10000,
"value": 10,
"highalch": 6,
"icon": "Watson teleport.png",
"name": "Watson teleport"
},
{
"examine": "For use on daggers and projectiles.",
"id": 187,
"members": true,
"lowalch": 57,
"limit": 2000,
"value": 144,
"highalch": 86,
"icon": "Weapon poison.png",
"name": "Weapon poison"
},
{
"examine": "A vial of extra strong weapon poison, for spears and daggers.",
"id": 5937,
"members": true,
"lowalch": 115,
"limit": 2000,
"value": 288,
"highalch": 172,
"icon": "Weapon poison(+).png",
"name": "Weapon poison(+)"
},
{
"examine": "A vial of super strong weapon poison, for spears and daggers.",
"id": 5940,
"members": true,
"lowalch": 172,
"limit": 2000,
"value": 432,
"highalch": 259,
"icon": "Weapon poison(++).png",
"name": "Weapon poison(++)"
},
{
"highalch": 1,
"members": true,
"name": "Webweaver bow (u)",
"examine": "A bow of a follower of Armadyl, corrupted by the power of Venenatis.",
"id": 27652,
"value": 1,
"icon": "Webweaver bow (u).png",
"lowalch": 1
},
{
"examine": "A handful of weeds.",
"id": 6055,
"members": true,
"lowalch": 1,
"limit": 600,
"value": 1,
"highalch": 1,
"icon": "Weeds.png",
"name": "Weeds"
},
{
"examine": "A teleport to West Ardougne.",
"id": 19623,
"members": true,
"lowalch": 1,
"limit": 10000,
"value": 1,
"highalch": 1,
"icon": "West ardougne teleport (tablet).png",
"name": "West ardougne teleport (tablet)"
},
{
"examine": "A bottle of Draynor Malt.",
"id": 2017,
"members": true,
"lowalch": 2,
"limit": 13000,
"value": 5,
"highalch": 3,
"icon": "Whisky.png",
"name": "Whisky"
},
{
"examine": "A two handed sword.",
"id": 6609,
"members": true,
"lowalch": 768,
"limit": 125,
"value": 1920,
"highalch": 1152,
"icon": "White 2h sword.png",
"name": "White 2h sword"
},
{
"examine": "A mostly clean apron.",
"id": 1005,
"members": false,
"lowalch": 1,
"limit": 150,
"value": 2,
"highalch": 1,
"icon": "White apron.png",
"name": "White apron"
},
{
"examine": "A vicious looking axe.",
"id": 6589,
"members": true,
"lowalch": 499,
"limit": 125,
"value": 1248,
"highalch": 748,
"icon": "White battleaxe.png",
"name": "White battleaxe"
},
{
"examine": "A small round white bead.",
"id": 1476,
"members": false,
"lowalch": 1,
"limit": 11000,
"value": 4,
"highalch": 2,
"icon": "White bead.png",
"name": "White bead"
},
{
"examine": "Parlez-vous francais?",
"id": 2637,
"members": true,
"lowalch": 32,
"limit": 4,
"value": 80,
"highalch": 48,
"icon": "White beret.png",
"name": "White beret"
},
{
"examine": "Sour berries, used in potions.",
"id": 239,
"members": true,
"lowalch": 4,
"limit": 13000,
"value": 10,
"highalch": 6,
"icon": "White berries.png",
"name": "White berries"
},
{
"examine": "Stylish!",
"id": 12313,
"members": true,
"lowalch": 90,
"limit": 4,
"value": 225,
"highalch": 135,
"icon": "White boater.png",
"name": "White boater"
},
{
"examine": "These will protect my feet.",
"id": 6619,
"members": true,
"lowalch": 230,
"limit": 125,
"value": 576,
"highalch": 345,
"icon": "White boots.png",
"name": "White boots"
},
{
"examine": "All for one and one for all!",
"id": 12321,
"members": true,
"lowalch": 80,
"limit": 4,
"value": 200,
"highalch": 120,
"icon": "White cavalier.png",
"name": "White cavalier"
},
{
"examine": "A series of connected metal rings.",
"id": 6615,
"members": true,
"lowalch": 576,
"limit": 125,
"value": 1440,
"highalch": 864,
"icon": "White chainbody.png",
"name": "White chainbody"
},
{
"examine": "A set of fighting claws.",
"id": 6587,
"members": true,
"lowalch": 144,
"limit": 125,
"value": 360,
"highalch": 216,
"icon": "White claws.png",
"name": "White claws"
},
{
"examine": "A vicious white dagger.",
"id": 6591,
"members": true,
"lowalch": 96,
"limit": 125,
"value": 240,
"highalch": 144,
"icon": "White dagger.png",
"name": "White dagger"
},
{
"examine": "This dagger is poisoned.",
"id": 6593,
"members": true,
"lowalch": 96,
"limit": 125,
"value": 240,
"highalch": 144,
"icon": "White dagger(p).png",
"name": "White dagger(p)"
},
{
"examine": "This dagger is poisoned.",
"id": 6595,
"members": true,
"lowalch": 96,
"limit": 125,
"value": 240,
"highalch": 144,
"icon": "White dagger(p+).png",
"name": "White dagger(p+)"
},
{
"examine": "This dagger is poisoned.",
"id": 6597,
"members": true,
"lowalch": 96,
"limit": 125,
"value": 240,
"highalch": 144,
"icon": "White dagger(p++).png",
"name": "White dagger(p++)"
},
{
"examine": "Paints things white!",
"id": 12763,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "White dark bow paint.png",
"name": "White dark bow paint"
},
{
"examine": "A well made elegant ladies' white blouse.",
"id": 10420,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "White elegant blouse.png",
"name": "White elegant blouse"
},
{
"examine": "A rather elegant white skirt.",
"id": 10422,
"members": true,
"lowalch": 800,
"limit": 4,
"value": 2000,
"highalch": 1200,
"icon": "White elegant skirt.png",
"name": "White elegant skirt"
},
{
"examine": "Makes firelighting a lot easier.",
"id": 10327,
"members": true,
"lowalch": 6,
"limit": 250,
"value": 15,
"highalch": 9,
"icon": "White firelighter.png",
"name": "White firelighter"
},
{
"examine": "A posy of flowers.",
"id": 2474,
"members": true,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "White flowers.png",
"name": "White flowers"
},
{
"examine": "A full face helmet.",
"id": 6623,
"members": true,
"lowalch": 422,
"limit": 125,
"value": 1056,
"highalch": 633,
"icon": "White full helm.png",
"name": "White full helm"
},
{
"examine": "These will keep my hands warm!",
"id": 6629,
"members": true,
"lowalch": 2,
"limit": 125,
"value": 6,
"highalch": 3,
"icon": "White gloves.png",
"name": "White gloves"
},
{
"examine": "A white halberd.",
"id": 6599,
"members": true,
"lowalch": 768,
"limit": 125,
"value": 1920,
"highalch": 1152,
"icon": "White halberd.png",
"name": "White halberd"
},
{
"examine": "A minimalist's hat.",
"id": 12299,
"members": true,
"lowalch": 16,
"limit": 4,
"value": 40,
"highalch": 24,
"icon": "White headband.png",
"name": "White headband"
},
{
"examine": "A large metal shield.",
"id": 6633,
"members": true,
"lowalch": 652,
"limit": 125,
"value": 1632,
"highalch": 979,
"icon": "White kiteshield.png",
"name": "White kiteshield"
},
{
"examine": "A fully bloomed White lily.",
"id": 22932,
"members": true,
"lowalch": 2,
"limit": 600,
"value": 5,
"highalch": 3,
"icon": "White lily.png",
"name": "White lily"
},
{
"examine": "A white lily seed - plant in a flower patch.",
"id": 22887,
"members": true,
"lowalch": 120,
"limit": 600,
"value": 300,
"highalch": 180,
"icon": "White lily seed 5.png",
"name": "White lily seed"
},
{
"examine": "A razor sharp longsword.",
"id": 6607,
"members": true,
"lowalch": 384,
"limit": 125,
"value": 960,
"highalch": 576,
"icon": "White longsword.png",
"name": "White longsword"
},
{
"examine": "A spiky mace.",
"id": 6601,
"members": true,
"lowalch": 172,
"limit": 125,
"value": 432,
"highalch": 259,
"icon": "White mace.png",
"name": "White mace"
},
{
"examine": "A Magical staff.",
"id": 6603,
"members": true,
"lowalch": 80,
"limit": 125,
"value": 200,
"highalch": 120,
"icon": "White magic staff.png",
"name": "White magic staff"
},
{
"examine": "A medium sized helmet.",
"id": 6621,
"members": true,
"lowalch": 230,
"limit": 125,
"value": 576,
"highalch": 345,
"icon": "White med helm.png",
"name": "White med helm"
},
{
"examine": "A nice hat from a cracker.",
"id": 1048,
"members": false,
"lowalch": 1,
"limit": 5,
"value": 1,
"highalch": 1,
"icon": "White partyhat.png",
"name": "White partyhat"
},
{
"examine": "Provides excellent protection.",
"id": 6617,
"members": true,
"lowalch": 1536,
"limit": 125,
"value": 3840,
"highalch": 2304,
"icon": "White platebody.png",
"name": "White platebody"
},
{
"examine": "Big, White and heavy looking.",
"id": 6625,
"members": true,
"lowalch": 768,
"limit": 125,
"value": 1920,
"highalch": 1152,
"icon": "White platelegs.png",
"name": "White platelegs"
},
{
"examine": "Big, White and heavy looking.",
"id": 6627,
"members": true,
"lowalch": 768,
"limit": 125,
"value": 1920,
"highalch": 1152,
"icon": "White plateskirt.png",
"name": "White plateskirt"
},
{
"examine": "A vicious, curved sword.",
"id": 6611,
"members": true,
"lowalch": 307,
"limit": 125,
"value": 768,
"highalch": 460,
"icon": "White scimitar.png",
"name": "White scimitar"
},
{
"examine": "A medium square shield.",
"id": 6631,
"members": true,
"lowalch": 460,
"limit": 125,
"value": 1152,
"highalch": 691,
"icon": "White sq shield.png",
"name": "White sq shield"
},
{
"examine": "A razor sharp sword.",
"id": 6605,
"members": true,
"lowalch": 249,
"limit": 125,
"value": 624,
"highalch": 374,
"icon": "White sword.png",
"name": "White sword"
},
{
"examine": "A white toy horse.",
"id": 2522,
"members": false,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "White toy horsey.png",
"name": "White toy horsey"
},
{
"examine": "Pointy rainbows.",
"id": 20269,
"members": true,
"lowalch": 400,
"limit": 4,
"value": 1000,
"highalch": 600,
"icon": "White unicorn mask.png",
"name": "White unicorn mask"
},
{
"examine": "I don't think it's intended for joinery.",
"id": 6613,
"members": true,
"lowalch": 392,
"limit": 125,
"value": 980,
"highalch": 588,
"icon": "White warhammer.png",
"name": "White warhammer"
},
{
"examine": "A whiteberry bush seed - plant in a bush patch.",
"id": 5105,
"members": true,
"lowalch": 53,
"limit": 200,
"value": 133,
"highalch": 79,
"icon": "Whiteberry seed 5.png",
"name": "Whiteberry seed"
},
{
"examine": "A triumph of man over nature.",
"id": 7208,
"members": true,
"lowalch": 72,
"limit": 10000,
"value": 182,
"highalch": 109,
"icon": "Wild pie.png",
"name": "Wild pie"
},
{
"examine": "A handful of Wildblood Hops.",
"id": 6002,
"members": true,
"lowalch": 6,
"limit": 11000,
"value": 15,
"highalch": 9,
"icon": "Wildblood hops.png",
"name": "Wildblood hops"
},
{
"examine": "A Wildblood hop seed - plant in a hops patch.",
"id": 5311,
"members": true,
"lowalch": 5,
"limit": 2000,
"value": 14,
"highalch": 8,
"icon": "Wildblood seed 5.png",
"name": "Wildblood seed"
},
{
"highalch": 37,
"members": true,
"name": "Wilderness crabs teleport",
"examine": "A teleport to near the dark crab fishing spots, in level 33 Wilderness.",
"id": 24251,
"value": 62,
"icon": "Wilderness crabs teleport.png",
"lowalch": 24
},
{
"examine": "Feed and catch the birds by placing this in the right spot.",
"id": 21518,
"members": true,
"lowalch": 2,
"limit": 250,
"value": 5,
"highalch": 3,
"icon": "Willow bird house.png",
"name": "Willow bird house"
},
{
"examine": "A handy little club made out of willow.",
"id": 4600,
"members": true,
"lowalch": 240,
"limit": 125,
"value": 600,
"highalch": 360,
"icon": "Willow blackjack.png",
"name": "Willow blackjack"
},
{
"examine": "A defensive blackjack.",
"id": 6414,
"members": true,
"lowalch": 320,
"limit": 125,
"value": 800,
"highalch": 480,
"icon": "Willow blackjack(d).png",
"name": "Willow blackjack(d)"
},
{
"examine": "An offensive blackjack.",
"id": 6412,
"members": true,
"lowalch": 320,
"limit": 125,
"value": 800,
"highalch": 480,
"icon": "Willow blackjack(o).png",
"name": "Willow blackjack(o)"
},
{
"examine": "A branch from a willow tree.",
"id": 5933,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 2,
"highalch": 1,
"icon": "Willow branch.png",
"name": "Willow branch"
},
{
"examine": "A powerful bow made from willow wood.",
"id": 10280,
"members": false,
"lowalch": 120,
"limit": 8,
"value": 300,
"highalch": 180,
"icon": "Willow comp bow.png",
"name": "Willow comp bow"
},
{
"highalch": 1,
"members": true,
"name": "Willow leaves",
"examine": "A pile of Willow tree leaves.",
"id": 6024,
"value": 1,
"icon": "Willow leaves.png",
"lowalch": 1
},
{
"examine": "Logs cut from a willow tree.",
"id": 1519,
"members": false,
"lowalch": 16,
"limit": 15000,
"value": 40,
"highalch": 24,
"icon": "Willow logs.png",
"name": "Willow logs"
},
{
"examine": "A nice sturdy bow made out of willow.",
"id": 847,
"members": false,
"lowalch": 128,
"limit": 18000,
"value": 320,
"highalch": 192,
"icon": "Willow longbow.png",
"name": "Willow longbow"
},
{
"examine": "An unstrung willow longbow; I need a bowstring for this.",
"id": 58,
"members": true,
"lowalch": 64,
"limit": 10000,
"value": 160,
"highalch": 96,
"icon": "Willow longbow (u).png",
"name": "Willow longbow (u)"
},
{
"examine": "Willow logs prepared with sacred oil for a funeral pyre.",
"id": 3442,
"members": true,
"lowalch": 32,
"limit": 11000,
"value": 80,
"highalch": 48,
"icon": "Willow pyre logs.png",
"name": "Willow pyre logs"
},
{
"examine": "The roots of the Willow tree.",
"id": 6045,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 1,
"highalch": 1,
"icon": "Willow roots.png",
"name": "Willow roots"
},
{
"examine": "This sapling is ready to be replanted in a tree patch.",
"id": 5371,
"members": true,
"lowalch": 1,
"limit": 200,
"value": 1,
"highalch": 1,
"icon": "Willow sapling.png",
"name": "Willow sapling"
},
{
"examine": "Plant this in a plantpot of soil to grow a sapling.",
"id": 5313,
"members": true,
"lowalch": 6,
"limit": 200,
"value": 16,
"highalch": 9,
"icon": "Willow seed 5.png",
"name": "Willow seed"
},
{
"examine": "A solid willow shield.",
"id": 22254,
"members": true,
"lowalch": 64,
"limit": 125,
"value": 160,
"highalch": 96,
"icon": "Willow shield.png",
"name": "Willow shield"
},
{
"examine": "A shortbow made out of willow, still effective.",
"id": 849,
"members": false,
"lowalch": 80,
"limit": 18000,
"value": 200,
"highalch": 120,
"icon": "Willow shortbow.png",
"name": "Willow shortbow"
},
{
"examine": "An unstrung willow shortbow; I need a bowstring for this.",
"id": 60,
"members": true,
"lowalch": 40,
"limit": 10000,
"value": 100,
"highalch": 60,
"icon": "Willow shortbow (u).png",
"name": "Willow shortbow (u)"
},
{
"examine": "A willow crossbow stock.",
"id": 9444,
"members": true,
"lowalch": 21,
"limit": 10000,
"value": 53,
"highalch": 31,
"icon": "Willow stock.png",
"name": "Willow stock"
},
{
"examine": "An evil wine for an evil god.",
"id": 245,
"members": false,
"lowalch": 1,
"limit": 11000,
"value": 1,
"highalch": 1,
"icon": "Wine of zamorak.png",
"name": "Wine of zamorak"
},
{
"examine": "This looks like a strange mix.",
"id": 2054,
"members": true,
"lowalch": 12,
"limit": 2000,
"value": 30,
"highalch": 18,
"icon": "Wizard blizzard.png",
"name": "Wizard blizzard"
},
{
"examine": "Slightly magical boots.",
"id": 2579,
"members": true,
"lowalch": 80,
"limit": 8,
"value": 200,
"highalch": 120,
"icon": "Wizard boots.png",
"name": "Wizard boots"
},
{
"examine": "A silly pointed hat.",
"id": 1017,
"members": false,
"lowalch": 1,
"limit": 125,
"value": 2,
"highalch": 1,
"icon": "Wizard hat.png",
"name": "Wizard hat"
},
{
"examine": "It's got strange bubbles in it.",
"id": 1907,
"members": false,
"lowalch": 1,
"limit": 2000,
"value": 2,
"highalch": 1,
"icon": "Wizard's mind bomb.png",
"name": "Wizard's mind bomb"
},
{
"examine": "A slightly bluish leaf.",
"id": 1793,
"members": false,
"lowalch": 1,
"limit": 11000,
"value": 1,
"highalch": 1,
"icon": "Woad leaf 5.png",
"name": "Woad leaf"
},
{
"examine": "A woad seed - plant in a flower patch.",
"id": 5099,
"members": true,
"lowalch": 4,
"limit": 600,
"value": 11,
"highalch": 6,
"icon": "Woad seed 5.png",
"name": "Woad seed"
},
{
"examine": "Bones of a recently slain wolf.",
"id": 2859,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Wolf bones.png",
"name": "Wolf bones"
},
{
"examine": "A very warm wolf cloak.",
"id": 23410,
"members": false,
"lowalch": 400,
"limit": 4,
"value": 1000,
"highalch": 600,
"icon": "Wolf cloak.png",
"name": "Wolf cloak"
},
{
"examine": "Howwwallll!",
"id": 23407,
"members": true,
"lowalch": 960,
"limit": 4,
"value": 2400,
"highalch": 1440,
"icon": "Wolf mask.png",
"name": "Wolf mask"
},
{
"examine": "I can make an ogre arrow with these.",
"id": 2861,
"members": true,
"lowalch": 1,
"limit": 7000,
"value": 3,
"highalch": 1,
"icon": "Wolfbone arrowtips.png",
"name": "Wolfbone arrowtips"
},
{
"highalch": 12,
"members": true,
"name": "Wood camo legs",
"examine": "These should make me harder to spot in wooded areas.",
"id": 10055,
"value": 20,
"icon": "Wood camo legs.png",
"lowalch": 8
},
{
"highalch": 12,
"members": true,
"name": "Wood camo legs (equipped)",
"examine": "These should make me harder to spot in wooded areas.",
"id": 28842,
"value": 20,
"icon": "Wood camo legs.png",
"lowalch": 8
},
{
"highalch": 12,
"members": true,
"name": "Wood camo top",
"examine": "This should make me harder to spot in wooded areas.",
"id": 10053,
"value": 20,
"icon": "Wood camo top.png",
"lowalch": 8
},
{
"highalch": 12,
"members": true,
"name": "Wood camo top (equipped)",
"examine": "This should make me harder to spot in wooded areas.",
"id": 28839,
"value": 20,
"icon": "Wood camo top.png",
"lowalch": 8
},
{
"examine": "How does it all fit in there?",
"id": 8548,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Wood dining table (flatpack).png",
"name": "Wood dining table (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8576,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Wooden bed (flatpack).png",
"name": "Wooden bed (flatpack)"
},
{
"examine": "How does it all fit in there?",
"id": 8562,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Wooden bench (flatpack).png",
"name": "Wooden bench (flatpack)"
},
{
"examine": "A wooden cat toy.",
"id": 10891,
"members": true,
"lowalch": 1,
"limit": 15,
"value": 1,
"highalch": 1,
"icon": "Wooden cat.png",
"name": "Wooden cat"
},
{
"examine": "How does it all fit in there?",
"id": 8498,
"members": true,
"lowalch": 4,
"limit": 500,
"value": 10,
"highalch": 6,
"icon": "Wooden chair (flatpack).png",
"name": "Wooden chair (flatpack)"
},
{
"examine": "A solid wooden shield.",
"id": 1171,
"members": false,
"lowalch": 8,
"limit": 125,
"value": 20,
"highalch": 12,
"icon": "Wooden shield.png",
"name": "Wooden shield"
},
{
"examine": "An epic wooden shield with a golden trim.",
"id": 20166,
"members": false,
"lowalch": 8,
"limit": 125,
"value": 20,
"highalch": 12,
"icon": "Wooden shield (g).png",
"name": "Wooden shield (g)"
},
{
"examine": "Spoooooon!",
"id": 7433,
"members": true,
"lowalch": 14,
"limit": 50,
"value": 35,
"highalch": 21,
"icon": "Wooden spoon.png",
"name": "Wooden spoon"
},
{
"examine": "A wooden crossbow stock.",
"id": 9440,
"members": true,
"lowalch": 8,
"limit": 10000,
"value": 20,
"highalch": 12,
"icon": "Wooden stock.png",
"name": "Wooden stock"
},
{
"examine": "I think this came from a sheep.",
"id": 1737,
"members": false,
"lowalch": 1,
"limit": 13000,
"value": 1,
"highalch": 1,
"icon": "Wool.png",
"name": "Wool"
},
{
"examine": "It actually smells quite good.",
"id": 2253,
"members": true,
"lowalch": 1,
"limit": 6000,
"value": 1,
"highalch": 1,
"icon": "Worm batta.png",
"name": "Worm batta"
},
{
"examine": "It actually smells quite good.",
"id": 2205,
"members": true,
"lowalch": 1,
"limit": 6000,
"value": 2,
"highalch": 1,
"icon": "Worm crunchies.png",
"name": "Worm crunchies"
},
{
"examine": "It actually smells quite good.",
"id": 2191,
"members": true,
"lowalch": 1,
"limit": 6000,
"value": 2,
"highalch": 1,
"icon": "Worm hole.png",
"name": "Worm hole"
},
{
"examine": "Blue top, very tiny.",
"id": 5028,
"members": true,
"lowalch": 260,
"limit": 150,
"value": 650,
"highalch": 390,
"icon": "Woven top (blue).png",
"name": "Woven top (blue)"
},
{
"examine": "Far too small to wear.",
"id": 5024,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Woven top (brown).png",
"name": "Woven top (brown)"
},
{
"examine": "Yellow top, too small for me.",
"id": 5026,
"members": true,
"lowalch": 250,
"limit": 150,
"value": 625,
"highalch": 375,
"icon": "Woven top (yellow).png",
"name": "Woven top (yellow)"
},
{
"examine": "Oomlie meat in a palm leaf pouch. It just needs to be cooked.",
"id": 2341,
"members": true,
"lowalch": 6,
"limit": 15,
"value": 16,
"highalch": 9,
"icon": "Wrapped oomlie.png",
"name": "Wrapped oomlie"
},
{
"examine": "Used for very high level missile spells.",
"id": 21880,
"members": true,
"lowalch": 200,
"limit": 25000,
"value": 500,
"highalch": 300,
"icon": "Wrath rune.png",
"name": "Wrath rune"
},
{
"examine": "A mysterious power emanates from the talisman...",
"id": 22118,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 4,
"highalch": 2,
"icon": "Wrath talisman.png",
"name": "Wrath talisman"
},
{
"examine": "A tiara infused with the properties of wrath.",
"id": 22121,
"members": true,
"lowalch": 200,
"limit": 40,
"value": 500,
"highalch": 300,
"icon": "Wrath tiara.png",
"name": "Wrath tiara"
},
{
"examine": "I wonder how Wyrms have bones...",
"id": 22780,
"members": true,
"lowalch": 64,
"limit": 7500,
"value": 160,
"highalch": 96,
"icon": "Wyrm bones.png",
"name": "Wyrm bones"
},
{
"examine": "Ew it's a pile of bones.",
"id": 28899,
"members": true,
"lowalch": 1,
"limit": 3000,
"value": 1,
"highalch": 1,
"icon": "Wyrmling bones.png",
"name": "Wyrmling bones"
},
{
"examine": "Bones of a large flying creature!",
"id": 6812,
"members": true,
"lowalch": 64,
"limit": 7500,
"value": 160,
"highalch": 96,
"icon": "Wyvern bones.png",
"name": "Wyvern bones"
},
{
"examine": "It looks like this could be attached to a shield somehow.",
"id": 21637,
"members": true,
"lowalch": 300000,
"limit": 5,
"value": 750000,
"highalch": 450000,
"icon": "Wyvern visage.png",
"name": "Wyvern visage"
},
{
"examine": "An old scrap of cloth, containing remnants of Xeric's forgotten magicks.",
"id": 13383,
"members": true,
"lowalch": 1,
"limit": 13000,
"value": 3,
"highalch": 1,
"icon": "Xerician fabric.png",
"name": "Xerician fabric"
},
{
"examine": "The forgotten magicks of Xeric imbue this garment with a little power.",
"id": 13385,
"members": true,
"lowalch": 24,
"limit": 125,
"value": 60,
"highalch": 36,
"icon": "Xerician hat.png",
"name": "Xerician hat"
},
{
"examine": "The forgotten magicks of Xeric imbue this garment with a little power.",
"id": 13389,
"members": true,
"lowalch": 28,
"limit": 125,
"value": 70,
"highalch": 42,
"icon": "Xerician robe.png",
"name": "Xerician robe"
},
{
"examine": "The forgotten magicks of Xeric imbue this garment with a little power.",
"id": 13387,
"members": true,
"lowalch": 32,
"limit": 125,
"value": 80,
"highalch": 48,
"icon": "Xerician top.png",
"name": "Xerician top"
},
{
"examine": "Thakkrad, of Neitiznot, can cure this.",
"id": 10818,
"members": true,
"lowalch": 20,
"limit": 13000,
"value": 50,
"highalch": 30,
"icon": "Yak-hide.png",
"name": "Yak-hide"
},
{
"examine": "Leg protection, made from smelly yak-hide.",
"id": 10824,
"members": true,
"lowalch": 200,
"limit": 70,
"value": 500,
"highalch": 300,
"icon": "Yak-hide armour (legs).png",
"name": "Yak-hide armour (legs)"
},
{
"examine": "Smelly yak body armour.",
"id": 10822,
"members": true,
"lowalch": 200,
"limit": 70,
"value": 500,
"highalch": 300,
"icon": "Yak-hide armour (top).png",
"name": "Yak-hide armour (top)"
},
{
"examine": "A handful of Yanillian Hops.",
"id": 5998,
"members": true,
"lowalch": 2,
"limit": 11000,
"value": 7,
"highalch": 4,
"icon": "Yanillian hops.png",
"name": "Yanillian hops"
},
{
"examine": "A Yanillian hop seed - plant in a hops patch.",
"id": 5309,
"members": true,
"lowalch": 2,
"limit": 2000,
"value": 6,
"highalch": 3,
"icon": "Yanillian seed 5.png",
"name": "Yanillian seed"
},
{
"examine": "A small round yellow bead.",
"id": 1472,
"members": false,
"lowalch": 1,
"limit": 11000,
"value": 4,
"highalch": 2,
"icon": "Yellow bead.png",
"name": "Yellow bead"
},
{
"examine": "Very stylish!",
"id": 2914,
"members": false,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Yellow boots.png",
"name": "Yellow boots"
},
{
"examine": "A thick yellow cape.",
"id": 1023,
"members": false,
"lowalch": 12,
"limit": 150,
"value": 32,
"highalch": 19,
"icon": "Yellow cape.png",
"name": "Yellow cape"
},
{
"examine": "Paints things yellow!",
"id": 12761,
"members": true,
"lowalch": 1,
"limit": 4,
"value": 1,
"highalch": 1,
"icon": "Yellow dark bow paint.png",
"name": "Yellow dark bow paint"
},
{
"examine": "A little bottle of yellow dye.",
"id": 1765,
"members": false,
"lowalch": 2,
"limit": 150,
"value": 5,
"highalch": 3,
"icon": "Yellow dye.png",
"name": "Yellow dye"
},
{
"examine": "A bright yellow feather.",
"id": 10090,
"members": true,
"lowalch": 5,
"limit": 8000,
"value": 13,
"highalch": 7,
"icon": "Yellow feather.png",
"name": "Yellow feather"
},
{
"examine": "A posy of flowers.",
"id": 2466,
"members": true,
"lowalch": 40,
"limit": 150,
"value": 100,
"highalch": 60,
"icon": "Yellow flowers.png",
"name": "Yellow flowers"
},
{
"examine": "These will keep my hands warm!",
"id": 2922,
"members": false,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Yellow gloves.png",
"name": "Yellow gloves"
},
{
"examine": "A silly pointed hat.",
"id": 2920,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Yellow hat.png",
"name": "Yellow hat"
},
{
"examine": "A nice hat from a cracker.",
"id": 1040,
"members": false,
"lowalch": 1,
"limit": 5,
"value": 1,
"highalch": 1,
"icon": "Yellow partyhat.png",
"name": "Yellow partyhat"
},
{
"examine": "Made by werewolves for werewolves.",
"id": 2918,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Yellow robe bottoms.png",
"name": "Yellow robe bottoms"
},
{
"examine": "Some fine werewolf clothing.",
"id": 2916,
"members": true,
"lowalch": 200,
"limit": 150,
"value": 500,
"highalch": 300,
"icon": "Yellow robe top.png",
"name": "Yellow robe top"
},
{
"examine": "Feed and catch the birds by placing this in the right spot.",
"id": 22198,
"members": true,
"lowalch": 2,
"limit": 250,
"value": 5,
"highalch": 3,
"icon": "Yew bird house.png",
"name": "Yew bird house"
},
{
"examine": "A powerful bow made from yew wood.",
"id": 10282,
"members": true,
"lowalch": 720,
"limit": 8,
"value": 1800,
"highalch": 1080,
"icon": "Yew comp bow.png",
"name": "Yew comp bow"
},
{
"highalch": 1,
"members": true,
"name": "Yew leaves",
"examine": "A pile of Yew tree leaves.",
"id": 6026,
"value": 1,
"icon": "Yew leaves.png",
"lowalch": 1
},
{
"examine": "Logs cut from a yew tree.",
"id": 1515,
"members": false,
"lowalch": 64,
"limit": 12000,
"value": 160,
"highalch": 96,
"icon": "Yew logs.png",
"name": "Yew logs"
},
{
"examine": "A nice sturdy bow made out of yew.",
"id": 855,
"members": true,
"lowalch": 512,
"limit": 18000,
"value": 1280,
"highalch": 768,
"icon": "Yew longbow.png",
"name": "Yew longbow"
},
{
"examine": "An unstrung yew longbow; I need a bowstring for this.",
"id": 66,
"members": true,
"lowalch": 256,
"limit": 10000,
"value": 640,
"highalch": 384,
"icon": "Yew longbow (u).png",
"name": "Yew longbow (u)"
},
{
"examine": "Yew logs prepared with sacred oil for a funeral pyre.",
"id": 3446,
"members": true,
"lowalch": 128,
"limit": 11000,
"value": 320,
"highalch": 192,
"icon": "Yew pyre logs.png",
"name": "Yew pyre logs"
},
{
"examine": "The roots of the Yew tree.",
"id": 6049,
"members": true,
"lowalch": 1,
"limit": 11000,
"value": 1,
"highalch": 1,
"icon": "Yew roots.png",
"name": "Yew roots"
},
{
"examine": "This sapling is ready to be replanted in a tree patch.",
"id": 5373,
"members": true,
"lowalch": 1,
"limit": 200,
"value": 1,
"highalch": 1,
"icon": "Yew sapling.png",
"name": "Yew sapling"
},
{
"examine": "Plant this in a plantpot of soil to grow a sapling.",
"id": 5315,
"members": true,
"lowalch": 57,
"limit": 200,
"value": 143,
"highalch": 85,
"icon": "Yew seed 5.png",
"name": "Yew seed"
},
{
"examine": "A solid yew shield.",
"id": 22260,
"members": true,
"lowalch": 256,
"limit": 125,
"value": 640,
"highalch": 384,
"icon": "Yew shield.png",
"name": "Yew shield"
},
{
"examine": "A shortbow made out of yew, still effective.",
"id": 857,
"members": true,
"lowalch": 320,
"limit": 18000,
"value": 800,
"highalch": 480,
"icon": "Yew shortbow.png",
"name": "Yew shortbow"
},
{
"examine": "An unstrung yew shortbow; I need a bowstring for this.",
"id": 68,
"members": true,
"lowalch": 160,
"limit": 10000,
"value": 400,
"highalch": 240,
"icon": "Yew shortbow (u).png",
"name": "Yew shortbow (u)"
},
{
"examine": "A yew crossbow stock.",
"id": 9452,
"members": true,
"lowalch": 66,
"limit": 10000,
"value": 167,
"highalch": 100,
"icon": "Yew stock.png",
"name": "Yew stock"
},
{
"examine": "A young impling in a jar. Don't trap me, man.",
"id": 11240,
"members": true,
"lowalch": 20,
"limit": 18000,
"value": 50,
"highalch": 30,
"icon": "Young impling jar.png",
"name": "Young impling jar"
},
{
"examine": "Makes a dragon pickaxe more beautiful.",
"id": 23908,
"members": true,
"lowalch": 640,
"limit": 50,
"value": 1600,
"highalch": 960,
"icon": "Zalcano shard.png",
"name": "Zalcano shard"
},
{
"examine": "A set containing a full helm, platebody, legs and kiteshield.",
"id": 13044,
"members": false,
"lowalch": 32000,
"limit": 8,
"value": 80000,
"highalch": 48000,
"icon": "Zamorak armour set (lg).png",
"name": "Zamorak armour set (lg)"
},
{
"examine": "A set containing a full helm, platebody, skirt and kiteshield.",
"id": 13046,
"members": false,
"lowalch": 32000,
"limit": 8,
"value": 80000,
"highalch": 48000,
"icon": "Zamorak armour set (sk).png",
"name": "Zamorak armour set (sk)"
},
{
"examine": "Zamorak blessed dragonhide vambraces.",
"id": 10368,
"members": true,
"lowalch": 1600,
"limit": 8,
"value": 4000,
"highalch": 2400,
"icon": "Zamorak bracers.png",
"name": "Zamorak bracers"
},
{
"examine": "1 dose of Zamorak brew.",
"id": 193,
"members": true,
"lowalch": 50,
"limit": 2000,
"value": 125,
"highalch": 75,
"icon": "Zamorak brew(1).png",
"name": "Zamorak brew(1)"
},
{
"examine": "2 doses of Zamorak brew.",
"id": 191,
"members": true,
"lowalch": 60,
"limit": 2000,
"value": 150,
"highalch": 90,
"icon": "Zamorak brew(2).png",
"name": "Zamorak brew(2)"
},
{
"examine": "3 doses of Zamorak brew.",
"id": 189,
"members": true,
"lowalch": 70,
"limit": 2000,
"value": 175,
"highalch": 105,
"icon": "Zamorak brew(3).png",
"name": "Zamorak brew(3)"
},
{
"examine": "4 doses of Zamorak brew.",
"id": 2450,
"members": true,
"lowalch": 80,
"limit": 2000,
"value": 200,
"highalch": 120,
"icon": "Zamorak brew(4).png",
"name": "Zamorak brew(4)"
},
{
"examine": "Zamorak blessed dragonhide chaps.",
"id": 10372,
"members": true,
"lowalch": 2400,
"limit": 8,
"value": 6000,
"highalch": 3600,
"icon": "Zamorak chaps.png",
"name": "Zamorak chaps"
},
{
"examine": "A Zamorak cloak.",
"id": 10450,
"members": true,
"lowalch": 800,
"limit": 8,
"value": 2000,
"highalch": 1200,
"icon": "Zamorak cloak.png",
"name": "Zamorak cloak"
},
{
"examine": "Zamorak blessed dragonhide coif.",
"id": 10374,
"members": true,
"lowalch": 800,
"limit": 8,
"value": 2000,
"highalch": 1200,
"icon": "Zamorak coif.png",
"name": "Zamorak coif"
},
{
"examine": "A Zamorak crozier.",
"id": 10444,
"members": true,
"lowalch": 2000,
"limit": 8,
"value": 5000,
"highalch": 3000,
"icon": "Zamorak crozier.png",
"name": "Zamorak crozier"
},
{
"examine": "Zamorak blessed dragonhide body armour.",
"id": 10370,
"members": true,
"lowalch": 5200,
"limit": 8,
"value": 13000,
"highalch": 7800,
"icon": "Zamorak d'hide body.png",
"name": "Zamorak d'hide body"
},
{
"examine": "Zamorak blessed dragonhide boots.",
"id": 19936,
"members": true,
"lowalch": 3720,
"limit": 8,
"value": 9300,
"highalch": 5580,
"icon": "Zamorak d'hide boots.png",
"name": "Zamorak d'hide boots"
},
{
"examine": "Zamorak blessed wooden shield covered in dragon leather.",
"id": 23194,
"members": true,
"lowalch": 11333,
"limit": 8,
"value": 28334,
"highalch": 17000,
"icon": "Zamorak d'hide shield.png",
"name": "Zamorak d'hide shield"
},
{
"examine": "A set containing a Zamorak dragonhide coif, body, chaps and vambraces.",
"id": 13161,
"members": true,
"lowalch": 3600,
"limit": 8,
"value": 9000,
"highalch": 5400,
"icon": "Zamorak dragonhide set.png",
"name": "Zamorak dragonhide set"
},
{
"examine": "Rune full helmet in the colours of Zamorak.",
"id": 2657,
"members": false,
"lowalch": 14080,
"limit": 8,
"value": 35200,
"highalch": 21120,
"icon": "Zamorak full helm.png",
"name": "Zamorak full helm"
},
{
"examine": "A terrifying, heavy sword.",
"id": 11808,
"members": true,
"lowalch": 500000,
"limit": 8,
"value": 1250000,
"highalch": 750000,
"icon": "Zamorak godsword.png",
"name": "Zamorak godsword"
},
{
"examine": "Use on a Zamorak godsword to make it look fancier!",
"id": 20077,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Zamorak godsword ornament kit.png",
"name": "Zamorak godsword ornament kit"
},
{
"examine": "Brimming with potential.",
"id": 11816,
"members": true,
"lowalch": 200000,
"limit": 10000,
"value": 500000,
"highalch": 300000,
"icon": "Zamorak hilt.png",
"name": "Zamorak hilt"
},
{
"examine": "Rune kiteshield in the colours of Zamorak.",
"id": 2659,
"members": false,
"lowalch": 21760,
"limit": 8,
"value": 54400,
"highalch": 32640,
"icon": "Zamorak kiteshield.png",
"name": "Zamorak kiteshield"
},
{
"examine": "A Zamorak mitre.",
"id": 10456,
"members": true,
"lowalch": 2000,
"limit": 8,
"value": 5000,
"highalch": 3000,
"icon": "Zamorak mitre.png",
"name": "Zamorak mitre"
},
{
"examine": "One dose of fishy Zamorak brew.",
"id": 11523,
"members": true,
"lowalch": 50,
"limit": 2000,
"value": 125,
"highalch": 75,
"icon": "Zamorak mix(1).png",
"name": "Zamorak mix(1)"
},
{
"examine": "Two doses of fishy Zamorak brew.",
"id": 11521,
"members": true,
"lowalch": 60,
"limit": 2000,
"value": 150,
"highalch": 90,
"icon": "Zamorak mix(2).png",
"name": "Zamorak mix(2)"
},
{
"examine": "A Zamorak Mjolnir.",
"id": 6764,
"members": true,
"lowalch": 250,
"limit": 8,
"value": 625,
"highalch": 375,
"icon": "Zamorak mjolnir.png",
"name": "Zamorak mjolnir"
},
{
"examine": "A robe worn by worshippers of Zamorak.",
"id": 1033,
"members": false,
"lowalch": 12,
"limit": 15,
"value": 30,
"highalch": 18,
"icon": "Zamorak monk bottom.png",
"name": "Zamorak monk bottom"
},
{
"examine": "A robe worn by worshippers of Zamorak.",
"id": 1035,
"members": false,
"lowalch": 16,
"limit": 15,
"value": 40,
"highalch": 24,
"icon": "Zamorak monk top.png",
"name": "Zamorak monk top"
},
{
"examine": "This seems to have been torn from a book...",
"id": 3831,
"members": true,
"lowalch": 80,
"limit": 5,
"value": 200,
"highalch": 120,
"icon": "Zamorak page 1.png",
"name": "Zamorak page 1"
},
{
"examine": "This seems to have been torn from a book...",
"id": 3832,
"members": true,
"lowalch": 80,
"limit": 5,
"value": 200,
"highalch": 120,
"icon": "Zamorak page 2.png",
"name": "Zamorak page 2"
},
{
"examine": "This seems to have been torn from a book...",
"id": 3833,
"members": true,
"lowalch": 80,
"limit": 5,
"value": 200,
"highalch": 120,
"icon": "Zamorak page 3.png",
"name": "Zamorak page 3"
},
{
"examine": "This seems to have been torn from a book...",
"id": 3834,
"members": true,
"lowalch": 80,
"limit": 5,
"value": 200,
"highalch": 120,
"icon": "Zamorak page 4.png",
"name": "Zamorak page 4"
},
{
"examine": "Rune platebody in the colours of Zamorak.",
"id": 2653,
"members": false,
"lowalch": 26000,
"limit": 8,
"value": 65000,
"highalch": 39000,
"icon": "Zamorak platebody.png",
"name": "Zamorak platebody"
},
{
"examine": "Rune platelegs in the colours of Zamorak.",
"id": 2655,
"members": false,
"lowalch": 25600,
"limit": 8,
"value": 64000,
"highalch": 38400,
"icon": "Zamorak platelegs.png",
"name": "Zamorak platelegs"
},
{
"examine": "Rune plateskirt in the colours of Zamorak.",
"id": 3478,
"members": false,
"lowalch": 25600,
"limit": 8,
"value": 64000,
"highalch": 38400,
"icon": "Zamorak plateskirt.png",
"name": "Zamorak plateskirt"
},
{
"examine": "Leggings from the Zamorak Vestments.",
"id": 10468,
"members": true,
"lowalch": 2800,
"limit": 8,
"value": 7000,
"highalch": 4200,
"icon": "Zamorak robe legs.png",
"name": "Zamorak robe legs"
},
{
"examine": "Zamorak Vestments.",
"id": 10460,
"members": true,
"lowalch": 2800,
"limit": 8,
"value": 7000,
"highalch": 4200,
"icon": "Zamorak robe top.png",
"name": "Zamorak robe top"
},
{
"examine": "A Zamorak stole.",
"id": 10474,
"members": true,
"lowalch": 1000,
"limit": 8,
"value": 2500,
"highalch": 1500,
"icon": "Zamorak stole.png",
"name": "Zamorak stole"
},
{
"examine": "The Grapes of Wrath.",
"id": 20749,
"members": true,
"lowalch": 20,
"limit": 11000,
"value": 50,
"highalch": 30,
"icon": "Zamorak's grapes.png",
"name": "Zamorak's grapes"
},
{
"examine": "An evil one-handed spear.",
"id": 11889,
"members": true,
"lowalch": 120000,
"limit": 8,
"value": 300000,
"highalch": 180000,
"icon": "Zamorakian hasta.png",
"name": "Zamorakian hasta"
},
{
"examine": "An evil spear.",
"id": 11824,
"members": true,
"lowalch": 40002,
"limit": 8,
"value": 100005,
"highalch": 60003,
"icon": "Zamorakian spear.png",
"name": "Zamorakian spear"
},
{
"highalch": 594000,
"members": true,
"name": "Zaryte crossbow",
"examine": "A weapon born out of conflict.",
"id": 26374,
"value": 990000,
"icon": "Zaryte crossbow.png",
"lowalch": 396000
},
{
"examine": "A pair of ancient vambraces.",
"id": 26235,
"members": true,
"lowalch": 80000,
"limit": 7,
"value": 200000,
"highalch": 120000,
"icon": "Zaryte vambraces.png",
"name": "Zaryte vambraces"
},
{
"examine": "This looks valuable.",
"id": 19493,
"members": true,
"lowalch": 30000,
"limit": 11000,
"value": 75000,
"highalch": 45000,
"icon": "Zenyte.png",
"name": "Zenyte"
},
{
"examine": "A fiery glow emanates from this amulet.",
"id": 19541,
"members": true,
"lowalch": 80800,
"limit": 8,
"value": 202000,
"highalch": 121200,
"icon": "Zenyte amulet.png",
"name": "Zenyte amulet"
},
{
"examine": "It needs a string so I can wear it.",
"id": 19501,
"members": true,
"lowalch": 30000,
"limit": 10000,
"value": 75000,
"highalch": 45000,
"icon": "Zenyte amulet (u).png",
"name": "Zenyte amulet (u)"
},
{
"examine": "A fiery glow emanates from this bracelet.",
"id": 19532,
"members": true,
"lowalch": 80400,
"limit": 8,
"value": 201000,
"highalch": 120600,
"icon": "Zenyte bracelet.png",
"name": "Zenyte bracelet"
},
{
"examine": "A fiery glow emanates from this necklace.",
"id": 19535,
"members": true,
"lowalch": 80800,
"limit": 8,
"value": 202000,
"highalch": 121200,
"icon": "Zenyte necklace.png",
"name": "Zenyte necklace"
},
{
"examine": "A fiery glow emanates from this ring.",
"id": 19538,
"members": true,
"lowalch": 80400,
"limit": 8,
"value": 201000,
"highalch": 120600,
"icon": "Zenyte ring.png",
"name": "Zenyte ring"
},
{
"examine": "A shard of unstable zenyte.",
"id": 19529,
"members": true,
"lowalch": 30000,
"limit": 11000,
"value": 75000,
"highalch": 45000,
"icon": "Zenyte shard.png",
"name": "Zenyte shard"
},
{
"examine": "A pile of Zombie Ogre bones.",
"id": 4812,
"members": true,
"lowalch": 1,
"limit": 3000,
"value": 1,
"highalch": 1,
"icon": "Zogre bones.png",
"name": "Zogre bones"
},
{
"highalch": 1,
"members": true,
"name": "Zombie axe",
"examine": "A rusty yet powerful axe.",
"id": 28810,
"value": 1,
"icon": "Zombie axe.png",
"lowalch": 1
},
{
"examine": "Get a head in life.",
"id": 19912,
"members": true,
"lowalch": 2000,
"limit": 4,
"value": 5000,
"highalch": 3000,
"icon": "Zombie head (Treasure Trails).png",
"name": "Zombie head (Treasure Trails)"
},
{
"examine": "The key to a zombie pirate's locker.",
"id": 29449,
"members": true,
"lowalch": 1,
"limit": 250,
"value": 1,
"highalch": 1,
"icon": "Zombie pirate key 5.png",
"name": "Zombie pirate key"
},
{
"examine": "Teleports you to Zul-Andra.",
"id": 12938,
"members": true,
"lowalch": 4,
"limit": 10000,
"value": 10,
"highalch": 6,
"icon": "Zul-andra teleport.png",
"name": "Zul-andra teleport"
},
{
"examine": "Flakes of toxic snakeskin.",
"id": 12934,
"members": true,
"lowalch": 8,
"limit": 30000,
"value": 20,
"highalch": 12,
"icon": "Zulrah's scales 5.png",
"name": "Zulrah's scales"
},
{
"examine": "A powerful staff.",
"id": 22647,
"members": true,
"lowalch": 120000,
"limit": 10,
"value": 300000,
"highalch": 180000,
"icon": "Zuriel's staff.png",
"name": "Zuriel's staff"
}
]

const keyedMapper = {}

export default mapper
