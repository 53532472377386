import Item from './item'
import FilterSort from './filterSort'

const Items = ({ items, getItemData, filtersAndSorters }) => {
    return (
        
        <div>
            <FilterSort filtersAndSorters={filtersAndSorters}></FilterSort>

            <ul className='item-display-container '>
                {items.map((item)=>{
                    return (
                    <li key={`item-${item.id}`}>
                        <Item item={item} getItemData={getItemData}></Item>
                    </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default Items